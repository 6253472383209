import React from 'react';
import propTypes from 'prop-types';
import colors from 'config/colors';
import spacing from 'config/spacing';
import WarningBar from 'components/WarningBar';

const styles = {
  bubbleContainer: {
    borderRadius: spacing.tiny,
    boxShadow: '0px 2px 4px 0px hsla(100, 4%, 15%, 0.5)',
    marginBottom: '24px',
  },
  bubbleHeader: {
    height: 50,
    width: '100%',
    backgroundColor: colors.black,
    color: colors.white,
    fontSize: 'large',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: spacing.tiny,
    borderTopRightRadius: spacing.tiny,
  },
  bubbleContentContainer: {
    maxWidth: '100%',
    margin: 25,
    paddingBottom: 25,
    borderRadius: 2,
    color: colors.grays.mediumLight,
  },
  editButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '250px',
  },
  editButtonContainer: {
    marginRight: '10px',
  },
  editButton: {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '38px',
    paddingRight: '38px',
    backgroundColor: 'transparent',
    border: `1px solid ${colors.white}`,
  },
  saveEditChanges: {
    backgroundColor: colors.positive,
    width: '120px',
  },
  cancelEditMode: {
    backgroundColor: colors.negative,
    width: '120px',
  },
};

const CandidateProfileBubble = props => (
  <div style={styles.bubbleContainer}>
    <div style={styles.bubbleHeader}>
      <p style={{ paddingLeft: '10px' }}>{props.header}</p>
      {props.buttons}
    </div>
    {props.warning && (<WarningBar warning={props.warning} />)}
    <div style={styles.bubbleContentContainer}>{props.children}</div>
  </div>
);

const { string, oneOfType, arrayOf, node } = propTypes;
CandidateProfileBubble.propTypes = {
  header: string.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  warning: string,
  buttons: oneOfType([
    arrayOf(node),
    node,
  ]),
};

CandidateProfileBubble.defaultProps = {
  warning: null,
  buttons: null,
};

export default CandidateProfileBubble;
