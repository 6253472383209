import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Template } from 'types/ShiftTypes';
import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface TemplateFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  template: { id: string, name: string } | null,
}

function TemplateField(props: TemplateFieldProps) {

  const templates = useSelector(state => state.templates.templateList);
  const templateList = useMemo(() => (templates ?? []).map((template: Template) => ({ value: template.key, label: template.name })), [templates]);
  return (
    <FieldLabel label="Template">
      <JobsSelectBox
        name="templates"
        value={props.template}
        placeholder="Template *"
        data={templateList}
        onChange={props.onChange}
        mandatory
      />
    </FieldLabel>
  );
}

export default React.memo(TemplateField);
