import React from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardArrowDown } from 'react-icons/md';
import './DatePickerArrowBox.css';

const styles = {
  datePickerArrowDown: {
    fontSize: '23px',
    color: 'hsl(0,0%,80%)',
    marginLeft: '8px',
    marginRight: '7px',
    marginTop: '1px',
  },
};

const DatePickerArrowBox = props => (
  <div
    onClick={() => props.onClick()}
    className="icons-container"
    role="button"
    tabIndex={0}
  >
    <div className="date-picker-line" />
    <div style={{ ...styles.datePickerArrowDown, color: props.datePickerFocused ? 'hsl(0,0%,20%)' : 'hsl(0,0%,80%)' }}>
      <MdKeyboardArrowDown />
    </div>
  </div>
);

DatePickerArrowBox.propTypes = {
  datePickerFocused: PropTypes.bool.isRequired,
};

export default DatePickerArrowBox;
