import moment from 'moment-timezone';
import { LOGGING_ENABLED } from '../config/settings';
import { log, logRaw } from './logging';

const STORAGE_KEY_QUEUE = 'tracking_queue';
const STORAGE_KEY_GLOBAL_PROPS = 'tracking_global_props';
const STORAGE_KEY_SESSION_ID = 'tracking_session_id';
const STORAGE_KEY_SESSION_START = 'tracking_session_start';
let globalProps = {};
let queue = [];
let startInterval = false;
let initialised = null;

function minuteDelta(past) {
  try {
    // Note: not using moment as this is used for
    // error tracking, so must mitigate risks.
    const diff = (Date.now() - new Date(past).getTime()) / 60000;
    return Math.abs(Math.round(diff));
  } catch (e) {
    return 0;
  }
}

function safeParse(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log('tracking: failed to parse');
    return null;
  }
}

async function safeStorageGet(key) {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    console.log('tracking: Failed to get storage');
    return null;
  }
}

async function safeStorageSet(key, value) {
  try {
    return window.localStorage.setItem(key, value);
  } catch (e) {
    console.log('tracking: Failed to set storage');
    return null;
  }
}

async function deserialize(key, expectedType, defaultValue) {
  const json = await safeStorageGet(key);

  if (json) {
    const obj = safeParse(json);

    if (obj && Object.prototype.toString.call(obj) === `[object ${expectedType}]`) {
      return obj;
    }
  }

  return defaultValue;
}

async function initQueue() {
  queue = await deserialize(STORAGE_KEY_QUEUE, 'Array', []);
}

async function initGlobalProps() {
  globalProps = await deserialize(STORAGE_KEY_GLOBAL_PROPS, 'Object', {});

  await setDefaultGlobalProps();
  storeGlobalProps();
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

function guid() {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`;
}

function setNewSessionId() {
  globalProps.sessionId = guid();
  safeStorageSet(STORAGE_KEY_SESSION_ID, globalProps.sessionId);
  safeStorageSet(STORAGE_KEY_SESSION_START, moment.utc().toISOString());
}

async function setSessionId() {
  const sessionId = await safeStorageGet(STORAGE_KEY_SESSION_ID);

  if (sessionId) {
    const sessionStartTime = await safeStorageGet(STORAGE_KEY_SESSION_START);

    if (sessionStartTime) {
      const minutesSinceSessionCreated = minuteDelta(sessionStartTime);

      if (minutesSinceSessionCreated > 20) {
        setNewSessionId();
      } else {
        globalProps.sessionId = sessionId;
      }
    } else {
      setNewSessionId();
    }
  } else {
    setNewSessionId();
  }
}

async function setDefaultGlobalProps() {
  try {
    globalProps.userType = 'employer';
    globalProps.navigator = window.navigator.userAgent;
    await setSessionId();
  } catch (e) {
    console.log(e);
    console.warn('tracking: Failing to get default global props');
  }
}

function init() {
  try {
    if (!initialised) {
      initialised = new Promise(async (resolve) => {
        await initQueue();
        await initGlobalProps();
        setTimeout(() => trackEvent('openedWebApp'), 1000);
        resolve();
      });
    }
  } catch (e) {
    console.warn('tracking: Failing to init tracking');
  }
}

function safeStore(key, obj) {
  try {
    window.localStorage.setItem(key, JSON.stringify(obj));
  } catch (e) {
    console.warn('Failed to store logging data');
  }
}

function pushToQueue(message) {
  queue.push(message);
  safeStore(STORAGE_KEY_QUEUE, queue);
}

function clearQueue() {
  queue = [];
  safeStore(STORAGE_KEY_QUEUE, queue);
}

function storeGlobalProps() {
  safeStore(STORAGE_KEY_GLOBAL_PROPS, globalProps);
}

async function dispatchQueue() {
  if (queue && queue.length > 0) {
    let payload = '';

    queue.forEach((message) => {
      if (!message) return;

      if (payload !== '') {
        payload += `\n${JSON.stringify(message)}`;
      } else {
        payload = JSON.stringify(message);
      }
    });

    const successfullyRecorded = await logRaw(payload);

    if (successfullyRecorded) {
      clearQueue();
    }
  }
}

export function getCurrentQueue() {
  return queue;
}

export function getGlobalProps() {
  return globalProps;
}

export function getBootingState() {
  return initialised;
}

export function setUser(anonymous, userId, email, name) {
  if (Object.prototype.hasOwnProperty.call(globalProps, 'anonymous')) delete globalProps.anonymous;

  if (anonymous) {
    if (globalProps.userId) delete globalProps.userId;
    if (globalProps.email) delete globalProps.email;
    if (globalProps.name) delete globalProps.name;
    globalProps.anonymous = true;
  } else {
    globalProps.userId = userId;
    globalProps.email = email;
    globalProps.name = name;
  }

  storeGlobalProps();
}

export function trackEvent(eventName) {
  track({ eventName });
}

export function track(eventData, trackNow) {
  if (typeof eventData !== 'object') {
    console.log('tracking.track: eventData must be an object, current is:', eventData);
    return;
  }

  if (LOGGING_ENABLED) {
    const message = {
      timestamp: moment.utc().toISOString(),
      collection: 'marketplace-website',
      ...eventData,
      ...globalProps,
    };

    if (trackNow) {
      log(message);
    } else {
      pushToQueue(message);
    }

    if (!startInterval) {
      setInterval(dispatchQueue, 5000);
      startInterval = true;
    }
  }
}

export function leaveBreadcrumb(screenName) {
  track({ eventName: 'screenChanged', newScreen: screenName });
}

init();
