import React from 'react';

import { EvidenceCategory, Submission } from 'types/ComplianceTypes';
import ComplianceSubmission from './ComplianceSubmission';
import './ComplianceTab.scss';

interface ComplianceEvidenceCategoryProps {
  itemKey: string,
  candidateKey: string,
  category: EvidenceCategory,
  adminHasWriteAccess: boolean,
  fetchItemsAndUpdateListView: () => void,
}

export default function ComplianceEvidenceCategory(props: ComplianceEvidenceCategoryProps): React.ReactElement {
  const { title, submissions, engineKey, expiryTimeDays } = props.category;
  return (
    <div>
      <h4 className="evidence-category__header">{title}</h4>

      {submissions.map((submission: Submission) => {
        return (
          <ComplianceSubmission
            key={submission.submissionKey}
            itemKey={props.itemKey}
            candidateKey={props.candidateKey}
            engineKey={engineKey}
            submission={submission}
            adminHasWriteAccess={props.adminHasWriteAccess}
            fetchItemsAndUpdateListView={props.fetchItemsAndUpdateListView}
            expiryTimeDays={expiryTimeDays}
          />
        );
      })}

      {submissions.length === 0 && <p className="compliance-item__text">No submissions</p>}
    </div>
  );
}
