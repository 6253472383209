import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';
import { SharedInboxMembership } from 'types/Messaging';
import { watchConversations } from 'thunks/messaging';
import DropDown from 'components/DropDown';
import Loading from 'components/Loading';
import ConversationListItem from './ConversationListItem';
import './styles.scss';

interface ConversationListPanelProps {
  selectedConversationKey: 'new' | string | undefined;
  goToConversation: (key: string) => void;
}

const ConversationListPanel: React.FC<ConversationListPanelProps> = (props: ConversationListPanelProps) => {

  const dispatch = useAppDispatch();

  const conversations = useSelector(state => state.messaging.conversations);
  const isFetchingConversations = useSelector(state => state.messaging.isFetchingConversations);
  const sharedInboxes : SharedInboxMembership[] | null = useSelector(state => state.messaging.sharedInboxMembership);
  const currentUserKey : string | null = useSelector(state => state.user.userId);

  const inboxOptions = useMemo(() => [
    { value: '__ALL__', label: 'All Conversations' },
    { value: '__DIRECT__', label: 'Direct Messages' },
    ...(sharedInboxes ?? []).map(inbox => ({ value: inbox.key, label: inbox.name })),
  ], [sharedInboxes]);

  const [selectedInbox, setSelectedInbox] = useState<string>('__ALL__');

  useEffect(() => {
    const userKey = selectedInbox === '__DIRECT__' ? (currentUserKey ?? undefined) : undefined;
    const sharedInboxKey = !['__ALL__', '__DIRECT__'].includes(selectedInbox) ? selectedInbox : undefined;
    const subscription = dispatch(watchConversations({ userKey, sharedInboxKey }));

    return () => subscription?.unsubscribe();
  }, [selectedInbox]);

  return (
    <div className="messagingListPanelContainer">
      <div className="messagingListPanelHeader">
        <DropDown
          options={inboxOptions}
          value={inboxOptions.find(opt => opt.value === selectedInbox)}
          onChange={(opt) => setSelectedInbox(opt.value)}
        />
      </div>
      {isFetchingConversations && <Loading flex />}
      {!isFetchingConversations && (
        <div className="messagingListItemScroll">
          {props.selectedConversationKey === 'new' && (<NewConversationListItem />)}
          {conversations?.map(conversation => (
            <ConversationListItem
              sharedInboxes={sharedInboxes}
              key={conversation.conversationKey}
              conversation={conversation}
              selected={props.selectedConversationKey === conversation.conversationKey}
              onSelect={() => props.goToConversation(conversation.conversationKey)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ConversationListPanel;

const NewConversationListItem: React.FC = () => {
  return (
    <div className="messagingListItemContainer messagingListItemNotSeen messagingListItemSelected">
      <div className="messagingListItemNewMessage">
        New Message
      </div>
    </div>
  );
};
