import { useMemo, useReducer, useCallback } from 'react';
import useSafeDispatch from './useSafeDispatch';

type AsyncStatus = 'pending' | 'refetching' | 'resolved' | 'rejected' | 'idle';
type AsyncError = string | null;
type RunFunc<Data> = (promise: Promise<Data>, options?: { refetch: boolean }) => Promise<void>;

interface ReducerState<Data> {
  status: AsyncStatus,
  data: Data | null,
  error: AsyncError,
}

type Action<Data> = { type: 'refetching' } | { type: 'pending' } | { type: 'rejected', error: string } | { type: 'resolved', data: Data } | { type: 'idle' };

const createAsyncReducer = <Data>() => (state: ReducerState<Data>, action: Action<Data>): ReducerState<Data> => {
  switch (action.type) {
    case 'pending': {
      return { status: 'pending', data: null, error: null };
    }
    case 'refetching': {
      return { status: 'refetching', data: null, error: null };
    }
    case 'resolved': {
      return { status: 'resolved', data: action.data, error: null };
    }
    case 'rejected': {
      return { status: 'rejected', data: null, error: action.error };
    }
    case 'idle': {
      return { status: 'idle', data: state.data, error: null };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

interface UseAsyncReturn<Data> {
  error: AsyncError,
  status: AsyncStatus,
  data: Data | null,
  run: RunFunc<Data>,
  clearError: () => void,
  isLoading: boolean,
}

export default function useAsync<Data>(): UseAsyncReturn<Data> {

  const asyncReducer = createAsyncReducer<Data>();

  const [state, unsafeDispatch] = useReducer(asyncReducer, {
    status: 'idle',
    data: null,
    error: null,
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const run = useCallback(async (promise: Promise<Data>, options = { refetch: false }) => {

    dispatch({ type: options.refetch ? 'refetching' : 'pending' });

    try {
      const response: Data = await promise;
      dispatch({ type: 'resolved', data: response });
    } catch (e) {
      if (e instanceof Error) dispatch({ type: 'rejected', error: e.message });
    }
  }, [dispatch]);

  const clearError = useCallback(() => dispatch({ type: 'idle' }), [dispatch]);
  const isLoading = (status === 'pending' || status === 'refetching');

  return useMemo(() => ({ error, status, data, run, clearError, isLoading }), [error, clearError, status, data, run]);
}
