import React from 'react';
import PropTypes from 'prop-types';

const SidePanelTabs = props => (
  <div className="shiftDetailsTabsContainer">
    {props.tabs && props.tabs.map(tab => (
      <button
        type="button"
        onClick={() => props.onTabClick(tab.id)}
        className={`shiftDetailsTabButtons${props.selectedTab === tab.id ? 'Active' : ''}`}
      >
        {tab.label}
      </button>
    ))}
  </div>
);


const { func, string, arrayOf, shape } = PropTypes;

SidePanelTabs.propTypes = {
  onTabClick: func.isRequired,
  selectedTab: string.isRequired,
  tabs: arrayOf(shape({
    label: string.isRequired,
    id: string.isRequired,
  })).isRequired,
};

export default SidePanelTabs;
