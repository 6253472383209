import moment from 'moment';
import * as reportingReducer from '../reducers/reporting';
import { reports } from '../routes/Reporting/Reports';
import * as api from '../lib/api';
import { capitalize } from '../lib/helpers';

export function changeDate(range) {
  return async (dispatch) => {
    const durationBetweenDates = moment(range.to).diff(moment(range.from), 'months');
    dispatch(reportingReducer.changeDate(range, durationBetweenDates, reports));
  };
}

export function setSelectedSite(site) {
  return async (dispatch) => {
    dispatch(reportingReducer.setSelectedSite(site));
  };
}

export function fetchReport(report, groupBy = null) {
  return async (dispatch, getState) => {
    const { reporting } = getState();

    const payload = { from: reporting.selectedRange.from, to: reporting.selectedRange.to };

    // Add filter or groupBy parameters if specified
    if (groupBy) payload.groupBy = groupBy;
    if (reporting.selectedSite.key) payload.siteFilter = reporting.selectedSite.key;
    if (reporting.selectedRole.key) payload.roleFilter = reporting.selectedRole.key;
    if (reporting.selectedSpeciality.key) payload.specialityFilter = reporting.selectedSpeciality.key;

    // Concatinate string to use as prop name in Redux depending on group by parameter
    const reportName = `${report}${groupBy === 'role' ? `By${capitalize(groupBy)}` : ''}`;
    dispatch(reportingReducer.reportLoading(reportName));

    const response = await api.get(`reports/${report}/`, payload);

    if (response.status > 200) {
      // Try to gracfully handle issue - load old report if you have it
      if (reporting.reports[reportName]) {
        dispatch(reportingReducer.reportLoaded(reportName, reporting.reports[reportName]));
      } else {
        dispatch(reportingReducer.reportFetchingError(reportName, 'Something went wrong. Please try loading the reports again.'));
      }
    } else {
      dispatch(reportingReducer.reportLoaded(reportName, response.body[report]));
    }
  };
}

export function fetchReportGroupedBySpeciality(report) {
  return async (dispatch, getState) => {
    const { reporting, global, rgs } = getState();

    let roleKey;
    if (reporting.selectedRole.key) {
      roleKey = reporting.selectedRole.key;
    } else {
      // Pick a role from orgConfig if no role is set. This can happen if speciality breakdown is accessed by manually entering the URL
      const defaultRole = rgs.roles[0];
      roleKey = defaultRole.key;
      dispatch(reportingReducer.setSelectedRole({ key: defaultRole.key, name: defaultRole.name }));
    }

    const payload = {
      from: reporting.selectedRange.from,
      to: reporting.selectedRange.to,
      groupBy: 'speciality',
      roleFilter: roleKey,
    };

    // Add site filter if set
    if (reporting.selectedSite.key) payload.siteFilter = reporting.selectedSite.key;

    const reportName = `${report}BySpeciality`;
    dispatch(reportingReducer.reportLoading(reportName));

    const response = await api.get(`reports/${report}/`, payload);

    if (response.status > 200) {
      // Try to gracfully handle issue - load old report if you have it
      if (reporting.reports[reportName]) {
        dispatch(reportingReducer.reportLoaded(reportName, reporting.reports[reportName]));
      } else {
        dispatch(reportingReducer.reportFetchingError(reportName, 'Something went wrong. Please try loading the reports again.'));
      }
    } else {
      dispatch(reportingReducer.reportLoaded(reportName, response.body[report]));
    }
  };
}
