import includes from 'lodash-es/includes';

//----------------------------------------------------------------
// PRIVATE
//----------------------------------------------------------------

function traverseToTargetAndWrite(obj, path, value) {
  const currentKey = path[0];
  if (path.length === 1) {
    // write the value
    /* eslint no-param-reassign:"off" */
    // NOTE: ESLint doesn't like this reassignment, but it's using a copy. Can we disable for this file?
    obj[currentKey] = value;
    return true;
  }
  obj[currentKey] = obj[currentKey] || {};
  return traverseToTargetAndWrite(obj[currentKey], path.slice(1), value);
}

//----------------------------------------------------------------
// EXPORTS
//----------------------------------------------------------------

export function isObject(input) {
  return !!input &&
         (typeof input === 'object' && !Array.isArray(input));
}

export function addDeepProperty(originalObj, inputName, value) {

  // NOTE: this allows us to use form field names (e.g. on FormInput) with dot notation
  // NOTE: means we can write to a previously non-existent deeply nested property without
  // causing Reference errors because they are undefined
  // e.g. if o = { prop1: 1 } we can do o.prop1.prop2.prop3 = 42; without blowing things up

  if (typeof inputName === 'string' && isObject(originalObj)) {
    const copiedObj = { ...originalObj };
    const pathway = inputName.split('.');
    if (pathway.length === 1) {
      copiedObj[pathway[0]] = value;
      return copiedObj;
    }
    traverseToTargetAndWrite(copiedObj, pathway, value);
    return copiedObj;
  }
  return originalObj;
}

export function findInObject(obj, valueToSearch) {
  const found = includes(obj, valueToSearch);
  return found;
}

export function convertToArray(obj) {
  return Object.keys(obj).map(id => obj[id]);
}

export function stringifyValues(object) {
  return Object.values(object || {}).join(',');
}
