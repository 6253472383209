import React from 'react';
import PropTypes from 'prop-types';
import spacing from 'config/spacing';
import ShiftsListItem from './ShiftsListItem';

const styles = {
  tableColumn: {
    width: '25%',
    paddingBottom: '2px',
    paddingTop: '2px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  shiftTableContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing.xxxlarge,
    marginTop: spacing.xxlarge,
  },
  shiftTable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
  },
  headerText: {
    fontWeight: 600,
  },
  shiftsRemovalMessageContainer: {
    marginBottom: spacing.xxlarge,
    marginTop: spacing.xxlarge,
  },
};

const ShiftsList = (props) => {
  return (
    <React.Fragment>
      <div style={styles.shiftsRemovalMessageContainer}>
        <p>{props.shiftMessage}</p>
      </div>
      <div style={styles.shiftTableContainer}>
        <div style={styles.shiftTable}>
          <div style={styles.headerContainer}>
            <div style={styles.tableColumn}>
              <p style={styles.headerText}>Date</p>
            </div>
            <div style={styles.tableColumn}>
              <p style={styles.headerText}>Site</p>
            </div>
            <div style={styles.tableColumn}>
              <p style={styles.headerText}>Speciality</p>
            </div>
            <div style={styles.tableColumn}>
              <p style={styles.headerText}>Ref Number</p>
            </div>
          </div>
          {props.shifts.map(shift => <ShiftsListItem shift={shift} goToShift={props.goToShift} key={shift.shiftNumber} />)}
        </div>
      </div>
    </React.Fragment>
  );
};

const { string, object, func, arrayOf } = PropTypes;

ShiftsList.propTypes = {
  shiftMessage: string.isRequired,
  shifts: arrayOf(object),
  goToShift: func.isRequired,
};

ShiftsList.defaultProps = {
  shifts: [],
};


export default ShiftsList;
