import React, { Component } from 'react';
import PropTypes from 'prop-types';
import colors from 'config/colors';
import classNames from 'classnames';

import './Tabs.scss';

const { string, arrayOf, shape, func, bool } = PropTypes;

const styles = {
  mainContainer: {
    width: '100%',
  },
  tabsScrollContainer: {
    overflowX: 'auto',
    // flex: 1,
  },
  tabsContainer: {
    display: 'flex',
    position: 'relative',
    whiteSpace: 'nowrap',
    // width: 'fit-content',
    // minWidth: '100%',
    flex: 1,
  },
  contentContainer: {
    backgroundColor: colors.white,
  },
  tab: {
    // display: 'inline-block',
    // borderRight: '1px solid #eaeaea',
    // padding: '12px 24px 0px 24px',
    color: colors.text,
    fontSize: '0.9rem',
    fontWeight: 500,
    cursor: 'pointer',
    padding: '18px 24px',
    margin: '0px 1px',
    // userSelect: 'none',
    // margin: '0px 3px',
    height: '100%',
    // flex: 1,
  },
  inactiveTab: {
    // backgroundColor: colors.cavalry.backgroundLight2,
    // borderTop: '1px solid #eaeaea',
  },
  activeTab: {
    position: 'relative',
    // marginBottom: -5,
    // borderTop: '4px solid #005e86',
    // backgroundColor: 'white',
    // borderBottom: '5px solid #FFF',
    // fontWeight: 'bold',
  },
};


//----------------------------------------------------------------
// TAB
//----------------------------------------------------------------

function Tab(props) {

  return (
    <div
      onClick={() => props.onClick(props.id)}
      style={{ ...styles.tab, ...(props.isActive ? styles.activeTab : styles.inactiveTab) }}
      className={classNames('tab-button', {
        'tab-button--active': !!props.isActive,
      })}
    >
      {props.title}
    </div>
  );
}

Tab.propTypes = {
  isActive: bool.isRequired,
  title: string.isRequired,
  onClick: func,
};

Tab.defaultProps = {
  onClick: () => {},
};


//----------------------------------------------------------------
// TAB BAR
//----------------------------------------------------------------

export const TabBar = (props) => {
  return (
    <>
      <div style={styles.tabsScrollContainer}>
        <div style={styles.tabsContainer}>
          {
            props.tabs.map((tabConfig) => {
              return (
                <Tab
                  isActive={tabConfig.key === props.selectedTabKey}
                  key={tabConfig.key}
                  onClick={() => props.onTabClick(tabConfig.key)}
                  title={tabConfig.title}
                />
              );
            })
          }
        </div>
      </div>
      <div style={{}}>
        {props.buttons}
      </div>
    </>
  );
};

export default class Tabs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
    };
  }

  render() {
    const tabBarTabs = this.props.tabs.map((tabConfig, index) => ({ key: index, title: tabConfig.tabTitle }));
    return (
      <div style={styles.mainContainer}>
        <TabBar
          tabs={tabBarTabs}
          selectedTabKey={this.state.selectedTabIndex}
          onTabClick={tabIndex => this.setState({ selectedTabIndex: tabIndex })}
        />
        <div style={styles.contentContainer}>
          {
            this.props.tabs[this.state.selectedTabIndex].renderTabContent()
          }
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  activeTabColor: string,
  inactiveTabColor: string,
  tabs: arrayOf(shape({
    tabTitle: string.isRequired,
    renderTabContent: func.isRequired,
  })).isRequired,
};

Tabs.defaultProps = {
  activeTabColor: colors.white,
  inactiveTabColor: colors.positive,
};
