import React, { CSSProperties } from 'react';

import colors from 'config/colors';
import { MdPushPinOutline } from 'components/Icons';


const styles = {
  container: {
    flex: '0 0 400px',
    width: '400px',
    backgroundColor: '#FFF',
    borderLeft: '1px solid #EAEAEA',
    padding: 12,
    boxSizing: 'border-box' as CSSProperties['boxSizing'],
    display: 'flex',
    flexDirection: 'column' as CSSProperties['flexDirection'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  explanatoryText: {
    color: colors.text,
    textAlign: 'center' as CSSProperties['textAlign'],
    lineHeight: 1.45,
    maxWidth: '100%',
  },
};

const ShiftsSupplementaryPanel = () => {
  return (
    <div style={styles.container}>
      <p style={styles.explanatoryText}>
        This space is reserved so that the Shift Details Panel doesn&apos;t cover the calendar.
        Use the <MdPushPinOutline /> button above to close it if you want more space.
      </p>
    </div>
  );
};

export default ShiftsSupplementaryPanel;
