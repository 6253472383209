import { defaultFeatureFlags, FeatureFlag } from 'config/featureFlags';
import { permissionDetails, Permission } from 'config/permissions';

type FeatureFlagMap = { [key in FeatureFlag]: boolean };
type PermissionMap = { [key in Permission]: boolean };

function isFeatureFlagOn(featureFlag: FeatureFlag, employerFeatures: FeatureFlagMap, orgFeatures: FeatureFlagMap) : boolean {
  if (employerFeatures && typeof employerFeatures[featureFlag] !== 'undefined') {
    return !!employerFeatures[featureFlag];
  }

  if (orgFeatures && typeof orgFeatures[featureFlag] !== 'undefined') {
    return !!orgFeatures[featureFlag];
  }

  return defaultFeatureFlags[featureFlag];
}

function isPermissionOn(permissionRequired: Permission, employerPermissions: PermissionMap) : boolean {
  if (employerPermissions && typeof employerPermissions[permissionRequired] !== 'undefined') {
    return !!employerPermissions[permissionRequired];
  }

  return permissionDetails[permissionRequired].default;
}

export function isFeatureOn(featureFlag: FeatureFlag | null, permissionRequired: Permission | null, userState: any, globalState: any) : boolean {
  if (!featureFlag && !permissionRequired) {
    return true;
  }

  if (!globalState || !userState) return false;
  const { userId } = userState;
  const { employerAuthorisedOrgs, orgConfig } = globalState;

  if (!userId || !employerAuthorisedOrgs || !orgConfig) return false;

  const orgId = Object.keys(employerAuthorisedOrgs)[0];
  const employerAuthorisedOrg = employerAuthorisedOrgs[orgId];

  if (!employerAuthorisedOrg) return false;

  const employerPermissions = employerAuthorisedOrg.permissions;
  const employerFeatures = employerAuthorisedOrg.features;
  const orgFeatures = orgConfig.features;

  if (featureFlag && !permissionRequired) {
    return isFeatureFlagOn(featureFlag, employerFeatures, orgFeatures);
  }

  if (!featureFlag && permissionRequired) {
    return isPermissionOn(permissionRequired, employerPermissions);
  }

  if (featureFlag && permissionRequired) {
    return isFeatureFlagOn(featureFlag, employerFeatures, orgFeatures) && isPermissionOn(permissionRequired, employerPermissions);
  }

  return false;
}
