import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../../components/Button';

const CreateDraftsEventComponent = ({ event, date, shiftHasTargetedCandidate, incrementCount, decrementCount }) => {

  // Show decrement button if day has some shifts marked for creation
  const showDecrementButton = event.count > 0;

  // if shift has targeted candidate, limit number of drafts per day to 1
  const incrementCountDisabled = shiftHasTargetedCandidate && event.count > 0;

  return (
    <div
      className={classNames('drafts-event__container', { 'drafts-event__container--disabled': incrementCountDisabled })}
      onClick={incrementCountDisabled ? null : (() => incrementCount(date))}
    >
      <div className={classNames({ 'drafts-event__count': event.count, 'drafts-event__count-placeholder': !event.count })}>
        {event.count || '+'}
      </div>
      {showDecrementButton ?
        <div className="drafts-event__decrement-button">
          <Button small negative onClick={(e) => { e.stopPropagation(); decrementCount(date); }}>-</Button>
        </div>
        : null
      }
    </div>
  );
};

const { num, bool, func, shape, object } = PropTypes;

CreateDraftsEventComponent.propTypes = {
  date: object.isRequired,
  event: shape({ count: num }),
  decrementCount: func.isRequired,
  incrementCount: func.isRequired,
  shiftHasTargetedCandidate: bool.isRequired,
};

CreateDraftsEventComponent.defaultProps = {
  event: { count: 0 },
};

export default CreateDraftsEventComponent;
