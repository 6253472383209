import React from 'react';
import { Link } from 'react-router-dom';
import colors from 'config/colors';

const headerStyles = {
  display: 'inline-block',
  lineHeight: 1,
  marginTop: '12px',
  marginLeft: '12px',
  fontSize: '1.5rem',
  color: colors.text,
};

const PageHeader = (props) => {
  const header = <h1 style={{ ...headerStyles, ...props.style }} className={props.className}>{props.title}</h1>;
  return props.linkTo ? <Link to={props.linkTo}>{header}</Link> : header;
};

export default PageHeader;
