import React from 'react';
import PropTypes from 'prop-types';
import ErrorModal from 'components/ErrorModal';
import NoReportData from 'components/NoReportData';
import ByBarChart from './ByBarChart';
import { capitalize } from '../../../lib/helpers';
import { reports, headerFormatter } from './index'; // eslint-disable-line import/no-cycle

const hasAnyData = (report) => {
  let hasData = false;
  if (report && report.length > 0) {
    report.forEach((item) => {
      if (item.categoryKey) {
        hasData = true;
      }
    });
  }
  return hasData;
};

export default function ByRoleReport(props) {

  // Find metadata and methods from report config
  const { roleBreakdown, labels, specialitiesBreakdown, dataFormatter } = reports.find(report => report.name === props.reportName);

  // Create formatted payload for chart
  const byRoleReport = roleBreakdown.generatePayloadForChart(props.report);

  return (
    <>
      {/* Render error modal if network error occurs when loading byRoleReport */}
      {props.reportError && (
        <ErrorModal header={`${capitalize(props.reportName)} By Role Report Error`} errorMessage={props.reportError} onCancel={() => props.clearReportingError(props.byRoleReportName)} />
      )}

      {!props.isloadingReports && hasAnyData(byRoleReport) ?
        <div className="byRole">
          <ByBarChart
            trendPositive={roleBreakdown.trendPositive}
            report={byRoleReport}
            tooltipFormatter={roleBreakdown.tooltipFormatter}
            totalFormatter={roleBreakdown.totalFormatter}
            yAxisFormatter={roleBreakdown.yAxisFormatter}
            title={roleBreakdown.title}
            onBarClick={props.onBarClick}
            by="roleName"
            groupBy="role"
            showTotal={roleBreakdown.showTotal}
            dataMax={specialitiesBreakdown.dataMax}
            labels={labels}
          />

          {props.drillDownComponent && props.drillDownComponent({
            labels,
            report: byRoleReport,
            numberFormatter: dataFormatter,
            headerFormatter,
            groupBy: 'categoryName',
          })}
        </div>
        : <NoReportData title={props.header} />}
    </>
  );
}

const { object, array, shape, bool, oneOfType, node, element, func, string } = PropTypes;

ByRoleReport.propTypes = {
  report: shape({
    result: array.isRequired,
    siteFilter: string,
    roleFilter: string,
    groupBy: string,
  }),
  isloadingReports: bool.isRequired,
  reportError: string,
  drillDownComponent: oneOfType([node, element, object, func]),
  clearReportingError: func.isRequired,
  onBarClick: func.isRequired,
  reportName: string.isRequired,
  byRoleReportName: string.isRequired,
  header: string.isRequired,
};

ByRoleReport.defaultProps = {
  report: null,
  drillDownComponent: null,
  reportError: '',
};
