import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { MdErrorOutline } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import { getHumanConversationAge } from 'lib/helpers-time';
import Loading from 'components/Loading';
import { resendMessage } from 'thunks/messaging';
import colors from 'config/colors';
import { ConversationMember, Message as MessageType } from '../../types/Messaging';

interface MessageListProps {
  messages: MessageType[] | null;
  conversationUsers: ConversationMember[] | undefined;
}

const MessageList: React.FC<MessageListProps> = (props: MessageListProps) => {
  const dispatch = useDispatch();
  const userKey = useSelector(state => state.user.userId);
  const localTimezone = moment.tz.guess();

  return (
    <>
      {props.messages?.map((message, index) => {
        const isLoggedInUser = message.sentByKey === userKey;
        const lastMessage = props.messages?.[index - 1];
        const showConversationDate = !lastMessage || !moment.utc(message.sentAt).isSame(moment.utc(lastMessage.sentAt), 'hour');
        const readByUsers = props.conversationUsers?.filter(({ lastViewedAt }) => lastViewedAt && message.sentAt < lastViewedAt).map(({ userName }) => userName) ?? [];
        const showReadBy = isLoggedInUser && readByUsers?.length > 0;

        return (
          <div key={message.key}>
            {showConversationDate && <ConversationDate localTimezone={localTimezone} date={message.sentAt} />}
            <div className="messagingMessageBubbleContainer">
              {!isLoggedInUser && <MessageLabel text={message.sentByName} left />}

              <Message
                isLoggedInUser={isLoggedInUser}
                message={message.textContent}
              />
              {showReadBy && <MessageLabel text={`Seen ${readByUsers.length > 1 ? `by ${readByUsers.join(', ')}` : ''}`} right />}
              {message.isSending && <div className="messagingMessageStatus"><Loading size={30} /></div>}
              {message.error && (
                <>
                  <div
                    className="messagingMessageStatus"
                    data-tip={message.error}
                    onClick={() => {
                      if (message.conversationKey) dispatch(resendMessage(message.conversationKey, { type: message.type, textContent: message.textContent }, message.key));
                    }}
                  >
                    <MdErrorOutline color={colors.cavalry.error} size={30} />
                  </div>
                  <ReactTooltip type="error" />
                </>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MessageList;

interface MessageProps {
  message: string;
  isLoggedInUser: boolean;
}

const Message: React.FC<MessageProps> = (props: MessageProps) => {
  let bubbleWidth = '40%';
  if (props.message.length > 600) {
    bubbleWidth = '60%';
  } else if (props.message.length > 300) {
    bubbleWidth = '40%';
  }
  return (
    <div style={{ maxWidth: bubbleWidth }} className={`messagingMessageBubble ${props.isLoggedInUser ? 'messageRight' : 'messageLeft'}`}>
      <p>{props.message}</p>
    </div>
  );
};

interface MessageLabelText {
  text: string;
}

interface MessageLabelRight extends MessageLabelText {
  right: boolean;
  left?: boolean;
}

interface MessageLabelLeft extends MessageLabelText {
  left: boolean;
  right?: boolean;
}

type MessageLabelProps = MessageLabelRight | MessageLabelLeft;

const MessageLabel: React.FC<MessageLabelProps> = (props: MessageLabelProps) => {
  return (
    <div className={`messagingMessageLabel ${props?.left ? 'messagingMessageLabelLeft' : ''} ${props?.right ? 'messagingMessageLabelRight' : ''}`}>
      <p>{props.text}</p>
    </div>
  );
};

interface ConversationDateProps {
  date: string;
  localTimezone: string,
}

const ConversationDate: React.FC<ConversationDateProps> = (props: ConversationDateProps) => {
  const formattedDate = getHumanConversationAge(props.date, props.localTimezone);
  return (
    <div className="messagingConversationDate">
      <p>{formattedDate}</p>
    </div>
  );
};
