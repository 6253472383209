import * as api from './api';

export async function sendUserInvite(inviteData) {
  const response = await api.post('user/invite', inviteData);
  if (response.status !== 200) {
    const err = new Error('Error inviting admin');
    err.humanReadableErrorMessage = response.body?.details?.humanReadableErrorMessage;
    throw err;
  }
  return response.body;
}

export async function getUserInvite(userId, inviteCode) {
  const response = await api.post('user/getInvite', { userId, inviteCode });
  return (response.status === 200) ? response.body : false;
}

export async function registerUser(options) {
  const response = await api.post('user/register', options);
  if (response.status !== 200) {
    const err = new Error('Error registering user');
    err.humanReadableErrorMessage = response.body?.details?.humanReadableErrorMessage;
    throw err;
  }
  return response.body;
}
