import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import Loading from '../Loading';
import colors from '../../config/colors';
import { DataTableColumnDefinition, RowDefinitionConstraint } from './DataTableTypes';

import './DataTable.scss';

interface DataTableProps<RowDefinition, AdditionalProps = undefined> {
  columns: DataTableColumnDefinition<RowDefinition, AdditionalProps>[],
  isLoading?: boolean,
  showRowsOnly?: boolean,
  showHeadersOnly?: boolean,
  fixedLayout?: boolean,
  noTextWrap?: boolean,
  multiHeader?: boolean,
  multiHeaderRepeatColumnHeaders?: boolean,
  rows: (RowDefinition & RowDefinitionConstraint)[] | null,
  idColumn?: keyof RowDefinition,
  onRowClick?: (id?: string, row?: RowDefinition) => void,
  additionalProps?: AdditionalProps,
  style?: CSSProperties,
  cellStyle?: CSSProperties,
  className?: string,
  overrideHeadingStyles?: CSSProperties,
  multiHeaderStyle?: CSSProperties,
  sortBy?: keyof RowDefinition,
  sortDataByHeader?: (columnName: string) => void,
  sortInTheRightOrder?: boolean,
}

const DataTable = <RowDefinition, AdditionalProps>(props : PropsWithChildren<DataTableProps<RowDefinition, AdditionalProps>>) : React.ReactElement => {

  if (props.isLoading) {
    return <div className="dataTableLoadingView"><Loading size={60} /></div>;
  }

  return (
    <table style={props.style} className={classNames([props.className, { dataTable: true, 'dataTable--fixed': props.fixedLayout, 'dataTable--nowrap': props.noTextWrap }])} cellPadding="0" cellSpacing="0">
      {props.showRowsOnly || <thead><Headers {...props} /></thead>}
      {props.showHeadersOnly || <Rows {...props} />}
    </table>
  );
};

DataTable.defaultProps = {
  isLoading: false,
  showRowsOnly: false,
  showHeadersOnly: false,
  overrideHeadingStyles: {},
  multiHeaderStyle: {},
  fixedLayout: false,
  noTextWrap: false,
  multiHeader: false,
  idColumn: undefined,
};

const Headers = <RowDefinition, AdditionalProps>(props : PropsWithChildren<DataTableProps<RowDefinition, AdditionalProps>>) : React.ReactElement => {

  const determineSortingIcon = (columnName: string) => {
    if (props.sortDataByHeader) {
      let sortArrowIcon = <MdArrowDropUp size={20} style={{ visibility: 'hidden' }} />;

      if (columnName === props.sortBy) {
        sortArrowIcon = props.sortInTheRightOrder ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />;
      }

      return sortArrowIcon;
    }

    return null;
  };

  return (
    <tr className="data-table-column-header-row">
      {props.columns.map(col => (
        <td
          key={col.name as string}
          className={classNames([props.sortDataByHeader ? 'data-table-column-header' : null, col.className?.()])}
          onClick={(event) => {
            event.preventDefault();
            if (props.sortDataByHeader) {
              props.sortDataByHeader(col.name as string);
            }
          }}
          role="presentation"
          style={{ width: col?.width, ...props.overrideHeadingStyles }}
        >
          {col.icon}
          {col.header || '\u00a0'}
          {col.name ? determineSortingIcon(col.name as string) : null}
        </td>
      ))}
    </tr>
  );
};

Headers.defaultProps = {
  sortDataByHeader: undefined,
  sortBy: undefined,
  sortInTheRightOrder: false,
  overrideHeadingStyles: {},
  isLoading: false,
  showRowsOnly: false,
  showHeadersOnly: false,
  fixedLayout: false,
  noTextWrap: false,
  multiHeader: false,
  idColumn: undefined,
};

const Rows = <RowDefinition, AdditionalProps>(props : PropsWithChildren<DataTableProps<RowDefinition, AdditionalProps>>) : React.ReactElement => {

  if (props.multiHeader) {
    return (
      <tbody>
        {(props.rows || []).map((row, index) => {
          const id = props.idColumn ? row[props.idColumn] : (row.key || row.id);
          if (row.type === 'header') {
            return (
              <>
                <tr key={id as string}>
                  <td key={row.title} colSpan={props.columns.length} style={{ backgroundColor: colors.black, color: colors.white, fontSize: '0.9rem', ...props.multiHeaderStyle }}>
                    {row.title}
                  </td>
                </tr>
                {!!props.multiHeaderRepeatColumnHeaders && <Headers {...props} />}
              </>
            );
          }
          return (
            <tr
              key={id as string}
              onClick={() => props.onRowClick?.(id as string, row)}
              className={`rollover data-table-row test-id-data-table-row-${index} ${row.className}`}
              style={{ display: row.isCollapsed ? 'none' : 'table-row' }}
            >
              {props.columns.map((col) => (
                <td
                  key={col.name as string}
                  className={classNames([
                    'data-table-data-column',
                    col.className?.(),
                  ])}
                  style={{ ...props.cellStyle, width: col?.width }}
                >
                  {col.formatter
                    ? col.formatter(
                      row[col.name as keyof RowDefinition] ?? (row as any),
                      row,
                      props.additionalProps as AdditionalProps,
                    )
                    : row[col.name as keyof RowDefinition]}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    );
  }
  return (
    <tbody>
      {(props.rows || []).map((row, index) => {
        const id = props.idColumn ? row[props.idColumn] : (row.key || row.id);
        return (
          <tr
            key={id as string}
            onClick={() => props.onRowClick?.(id as string, row)}
            className={`rollover data-table-row test-id-data-table-row-${index} ${row.className}`}
            style={{ display: row.isCollapsed ? 'none' : 'table-row' }}
          >
            {props.columns.map((col) => (
              <td
                key={col.name as string}
                className={classNames([
                  'data-table-data-column',
                  col.className?.(),
                ])}
                style={{ ...props.cellStyle, width: col?.width }}
              >
                {col.formatter
                  ? col.formatter(
                    row[col.name as keyof RowDefinition] ?? (row as any),
                    row,
                    props.additionalProps as AdditionalProps,
                  )
                  : row[col.name as keyof RowDefinition]}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

Rows.defaultProps = {
  multiHeader: false,
  idColumn: undefined,
};


export default DataTable;
