import { useMemo } from 'react';
import { firstBy } from 'thenby';
import { SwitchListHookObject, SwitchListState, useSwitchList } from 'components/SwitchList';

interface PermissionWithState {
  key: string,
  name: string,
  isEnabled: boolean,
}

export function useAdminManagementPermissionsList(combinedList: Array<PermissionWithState> | undefined) : SwitchListHookObject {

  const { items, initialState } = useMemo(() => {
    return {
      items: (combinedList ?? []).map(item => ({ key: item.key, label: item.name })).sort(firstBy('label')),
      initialState: (combinedList ?? []).reduce((memo, item) => ({ ...memo, [item.key]: !!item.isEnabled }), {}) as SwitchListState,
    };
  }, [combinedList]);

  return useSwitchList(items, initialState);
}
