const CLEAR_CANDIDATE_DATA = 'CLEAR_CANDIDATE_DATA';

const IS_FETCHING_CANDIDATE_BANKS = 'IS_FETCHING_CANDIDATE_BANKS';
const FETCH_CANDIDATE_BANKS_ERROR = 'FETCH_CANDIDATE_BANKS_ERROR';
const FETCH_CANDIDATE_BANKS_SUCCESS = 'FETCH_CANDIDATE_BANKS_SUCCESS';

const FETCH_CANDIDATE_PROFILE_IN_PROGRESS = 'FETCH_CANDIDATE_PROFILE_IN_PROGRESS';
const FETCH_CANDIDATE_PROFILE_SUCCESS = 'FETCH_CANDIDATE_PROFILE_SUCCESS';
const FETCH_CANDIDATE_PROFILE_ERROR = 'FETCH_CANDIDATE_PROFILE_ERROR';
const CLEAR_CANDIDATE_PROFILE = 'CLEAR_CANDIDATE_PROFILE';

const IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP = 'IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP';
const IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_SUCCESS = 'IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_SUCCESS';
const IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_ERROR = 'IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_ERROR';

const IS_FETCHING_CANDIDATE_SHIFTS = 'IS_FETCHING_CANDIDATE_SHIFTS';
const IS_FETCHING_CANDIDATE_SHIFTS_SUCCESS = 'IS_FETCHING_CANDIDATE_SHIFTS_SUCCESS';
const IS_FETCHING_CANDIDATE_SHIFTS_ERROR = 'IS_FETCHING_CANDIDATE_SHIFTS_ERROR';
const UPDATE_CANDIDATE_COMPLIANCE_STATUS = 'UPDATE_CANDIDATE_COMPLIANCE_STATUS';

const SET_IS_UPDATING_CANDIDATE_PROFILE = 'SET_IS_UPDATING_CANDIDATE_PROFILE';
const UPDATE_CANDIDATE_PROFILE = 'UPDATE_CANDIDATE_PROFILE';
const UPDATE_CANDIDATE_PROFILE_ERROR = 'UPDATE_CANDIDATE_PROFILE_ERROR';
const CLEAR_UPDATE_CANDIDATE_PROFILE_ERROR = 'CLEAR_UPDATE_CANDIDATE_PROFILE_ERROR';

export const isFetchingCandidateBanks = () => ({ type: IS_FETCHING_CANDIDATE_BANKS });
export const fetchCandidateBanksError = error => ({ type: FETCH_CANDIDATE_BANKS_ERROR, error });
export const fetchCandidateBanksSuccess = ({ banks, candidateOrgKey, ratingsFeatureEnabled }) => ({ type: FETCH_CANDIDATE_BANKS_SUCCESS, banks, candidateOrgKey, ratingsFeatureEnabled });
export const clearCandidateData = () => ({ type: CLEAR_CANDIDATE_DATA });

export const isFetchingCandidateProfile = () => ({ type: FETCH_CANDIDATE_PROFILE_IN_PROGRESS });
export const fetchCandidateProfileSuccess = (candidateId, candidate) => ({ type: FETCH_CANDIDATE_PROFILE_SUCCESS, candidate, candidateId });
export const fetchCandidateProfileError = error => ({ type: FETCH_CANDIDATE_PROFILE_ERROR, error });
export const clearCandidateProfile = () => ({ type: CLEAR_CANDIDATE_PROFILE });

export const setIsUpdatingCandidateProfile = () => ({ type: SET_IS_UPDATING_CANDIDATE_PROFILE });
export const updateCandidateProfileSuccess = (candidateId, profile) => ({ type: UPDATE_CANDIDATE_PROFILE, candidateId, profile });
export const updateCandidateProfileError = error => ({ type: UPDATE_CANDIDATE_PROFILE_ERROR, error });
export const clearUpdateCandidateProfileError = () => ({ type: CLEAR_UPDATE_CANDIDATE_PROFILE_ERROR });

export const isFetchingCandidateOrgRelationship = () => ({ type: IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP });
export const isFetchingCandidateOrgRelationshipSuccess = relationship => ({ type: IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_SUCCESS, relationship });
export const isFetchingCandidateOrgRelationshipError = error => ({ type: IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_ERROR, error });

export const isFetchingCandidateShifts = () => ({ type: IS_FETCHING_CANDIDATE_SHIFTS });
export const isFetchingCandidateShiftsSuccess = shifts => ({ type: IS_FETCHING_CANDIDATE_SHIFTS_SUCCESS, shifts });
export const isFetchingCandidateShiftsError = error => ({ type: IS_FETCHING_CANDIDATE_SHIFTS_ERROR, error });

export const updateCandidateComplianceStatus = profile => ({ type: UPDATE_CANDIDATE_COMPLIANCE_STATUS, profile });

const initialState = {
  error: null,

  banks: [],
  candidateOrgKey: null,
  ratingsFeatureEnabled: false,
  fetchingCandidateBanks: false,

  profile: null,
  isFetchingCandidateProfile: false,

  candidateOrgRelationship: null,
  isFetchingCandidateOrgRelationship: false,

  candidateShifts: null,
  candidateShiftsError: null,
  isFetchingCandidateShifts: false,

  isUpdatingCandidateProfile: false,
  updateCandidateProfileError: null,

  contract: {},
};

export default function candidateReducer(state = initialState, action) {
  switch (action.type) {
    case IS_FETCHING_CANDIDATE_BANKS: return { ...state, fetchingCandidateBanks: true, error: null };
    case FETCH_CANDIDATE_BANKS_ERROR: return { ...state, fetchingCandidateBanks: false, error: action.error };
    case FETCH_CANDIDATE_BANKS_SUCCESS: return { ...state, fetchingCandidateBanks: false, banks: action.banks, candidateOrgKey: action.candidateOrgKey, ratingsFeatureEnabled: action.ratingsFeatureEnabled };
    case CLEAR_CANDIDATE_DATA: return { ...state, banks: [], candidateOrgKey: null, ratingsFeatureEnabled: false, error: null };

    case FETCH_CANDIDATE_PROFILE_IN_PROGRESS: return { ...state, isFetchingCandidateProfile: true, updateCandidateProfileError: null };
    case FETCH_CANDIDATE_PROFILE_SUCCESS: return { ...state, isFetchingCandidateProfile: false, error: null, profile: action.candidate, candidateId: action.candidateId };
    case FETCH_CANDIDATE_PROFILE_ERROR: return { ...state, isFetchingCandidateProfile: false, error: action.error };
    case CLEAR_CANDIDATE_PROFILE: return { ...state, candidateProfileData: null, candidateShifts: null };

    case IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP: return { ...state, isFetchingCandidateOrgRelationship: true };
    case IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_SUCCESS: return { ...state, isFetchingCandidateOrgRelationship: false, error: null, candidateOrgRelationship: action.relationship };
    case IS_FETCHING_CANDIDATE_ORG_RELATIONSHIP_ERROR: return { ...state, isFetchingCandidateOrgRelationship: false, error: action.error };

    case IS_FETCHING_CANDIDATE_SHIFTS: return { ...state, isFetchingCandidateShifts: true };
    case IS_FETCHING_CANDIDATE_SHIFTS_SUCCESS: return { ...state, isFetchingCandidateShifts: false, error: null, candidateShifts: action.shifts };
    case IS_FETCHING_CANDIDATE_SHIFTS_ERROR: return { ...state, isFetchingCandidateShifts: false, error: action.error };

    case UPDATE_CANDIDATE_COMPLIANCE_STATUS: return { ...state, profile: action.profile };

    case SET_IS_UPDATING_CANDIDATE_PROFILE: return { ...state, isUpdatingCandidateProfile: true, updateCandidateProfileError: null };
    case UPDATE_CANDIDATE_PROFILE: return { ...state, isUpdatingCandidateProfile: false, profile: action.profile, candidateId: action.candidateId };
    case UPDATE_CANDIDATE_PROFILE_ERROR: return { ...state, isUpdatingCandidateProfile: false, updateCandidateProfileError: action.error };
    case CLEAR_UPDATE_CANDIDATE_PROFILE_ERROR: return { ...state, updateCandidateProfileError: null };

    case IS_FETCHING_STAFF_CONTRACT: return { ...state, contract: contractReducer(state.contract, action) };
    case FETCH_STAFF_CONTRACT_SUCCESS: return { ...state, contract: contractReducer(state.contract, action) };
    case FETCH_STAFF_CONTRACT_ERROR: return { ...state, contract: contractReducer(state.contract, action) };

    case IS_CREATING_NEW_CONTRACT: return { ...state, contract: contractReducer(state.contract, action) };

    case IS_UPDATING_STAFF_CONTRACT: return { ...state, contract: contractReducer(state.contract, action) };
    case UPDATE_STAFF_CONTRACT_SUCCESS: return { ...state, contract: contractReducer(state.contract, action) };
    case UPDATE_STAFF_CONTRACT_ERROR: return { ...state, contract: contractReducer(state.contract, action) };
    case CLEAR_UPDATE_STAFF_CONTRACT_ERROR: return { ...state, contract: contractReducer(state.contract, action) };

    default: return state;
  }
}

const IS_FETCHING_STAFF_CONTRACT = 'IS_FETCHING_STAFF_CONTRACT';
const FETCH_STAFF_CONTRACT_SUCCESS = 'FETCH_STAFF_CONTRACT_SUCCESS';
const FETCH_STAFF_CONTRACT_ERROR = 'FETCH_STAFF_CONTRACT_ERROR';
const CLEAR_STAFF_CONTRACT_ERROR = 'CLEAR_STAFF_CONTRACT_ERROR';

const IS_CREATING_NEW_CONTRACT = 'IS_CREATING_NEW_CONTRACT';
const SET_HAS_SAVED_CONTRACT = 'SET_HAS_SAVED_CONTRACT';

const IS_UPDATING_STAFF_CONTRACT = 'IS_UPDATING_STAFF_CONTRACT';
const UPDATE_STAFF_CONTRACT_SUCCESS = 'UPDATE_STAFF_CONTRACT_SUCCESS';
const UPDATE_STAFF_CONTRACT_ERROR = 'UPDATE_STAFF_CONTRACT_ERROR';
const CLEAR_UPDATE_STAFF_CONTRACT_ERROR = 'CLEAR_UPDATE_STAFF_CONTRACT_ERROR';

export const isFetchingStaffContract = () => ({ type: IS_FETCHING_STAFF_CONTRACT });
export const fetchStaffContractSuccess = contract => ({ type: FETCH_STAFF_CONTRACT_SUCCESS, contract });
export const fetchStaffContractError = error => ({ type: FETCH_STAFF_CONTRACT_ERROR, error });
export const clearStaffContractError = () => ({ type: CLEAR_STAFF_CONTRACT_ERROR });

export const setIsCreatingNewContract = (isCreating) => ({ type: IS_CREATING_NEW_CONTRACT, isCreating });
export const setHasSavedContract = (hasSaved) => ({ type: SET_HAS_SAVED_CONTRACT, hasSaved });

export const isUpdatingStaffContract = () => ({ type: IS_UPDATING_STAFF_CONTRACT });
export const updateStaffContractSuccess = contract => ({ type: UPDATE_STAFF_CONTRACT_SUCCESS, contract });
export const updateStaffContractError = error => ({ type: UPDATE_STAFF_CONTRACT_ERROR, error });
export const clearUpdateStaffContractError = () => ({ type: CLEAR_UPDATE_STAFF_CONTRACT_ERROR });

const initialContractState = {
  contract: null,
  isFetchingStaffContract: false,
  fetchStaffContractError: null,

  isUpdatingStaffContract: false,
  hasSavedContract: false,
  isCreatingNewContract: false,
  updateStaffContractError: null,
};

function contractReducer(state = initialContractState, action) {
  switch (action.type) {

    case IS_FETCHING_STAFF_CONTRACT: return { ...state, isFetchingStaffContract: true, fetchStaffContractError: null, hasSavedContract: false };
    case FETCH_STAFF_CONTRACT_SUCCESS: return { ...state, isFetchingStaffContract: false, contract: action.contract };
    case FETCH_STAFF_CONTRACT_ERROR: return { ...state, isFetchingStaffContract: false, fetchStaffContractError: action.error };
    case CLEAR_STAFF_CONTRACT_ERROR: return { ...state, fetchStaffContractError: null };

    case IS_CREATING_NEW_CONTRACT: return { ...state, isCreatingNewContract: action.isCreating };
    case SET_HAS_SAVED_CONTRACT: return { ...state, hasSavedContract: action.hasSaved };

    case IS_UPDATING_STAFF_CONTRACT: return { ...state, isUpdatingStaffContract: true, updateStaffContractError: null, hasSavedContract: false };
    case UPDATE_STAFF_CONTRACT_SUCCESS: return { ...state, isUpdatingStaffContract: false, contract: action.contract, isCreatingNewContract: false, hasSavedContract: true };
    case UPDATE_STAFF_CONTRACT_ERROR: return { ...state, isUpdatingStaffContract: false, updateStaffContractError: action.error, isCreatingNewContract: false };
    case CLEAR_UPDATE_STAFF_CONTRACT_ERROR: return { ...state, updateStaffContractError: null };

    default: return state;
  }
}
