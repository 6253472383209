import React, { useState } from 'react';
import DropDown from 'components/DropDown';
import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import Button from 'components/Button';
import fonts from 'config/fonts';
import spacing from 'config/spacing';
import colors from 'config/colors';
import { browserOnBlur } from 'lib/browser-type';
import { customFilter } from 'lib/helpers-select';

// Filter config setting for select input
const filterConfig = {
  matchFrom: 'any',
};

const styles = {
  content: {
    width: 400,
    textAlign: 'center',
    lineHeight: 2,
    padding: '0px 12px',
  },
  header: {
    color: colors.text,
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
    // margin: spacing.base,
  },
  modalContent: {
    overflow: 'visible',
  },
};

export const TimesheetRejectModal = (props) => {
  const [selectedRejectReason, selectRejectReason] = useState(props.rejectReasons[0]);

  const rejectTimesheet = () => {
    props.rejectTimesheet(selectedRejectReason);
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.modalOpen}
      onRequestClose={props.onClose}
      contentStyle={styles.modalContent}
    >
      <CloseButton handleClose={props.onClose} top={21} right={15} />
      <div style={styles.content} className="space-children-12--bottom">
        <h1 style={styles.header}>
          Select Reason For Timesheet Rejection
        </h1>
        <DropDown
          name="label"
          defaultValue={selectedRejectReason}
          options={props.rejectReasons}
          onChange={reason => selectRejectReason(reason)}
          isClearable={false}
          filterOption={customFilter(filterConfig)}
          onBlur={browserOnBlur()}
        />
        <Button onClick={() => rejectTimesheet()} large>Reject Timesheet</Button>
      </div>
    </Modal>
  );
};
