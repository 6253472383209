import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Legend, Line, CartesianGrid, Surface, Symbols } from 'recharts';
import ErrorModal from 'components/ErrorModal';
import Page from 'components/Page';
import Loading from 'components/Loading';
import NoReportData from 'components/NoReportData';
import DashboardHeader from '../Dashboard/DashboardHeader';
import { setSelectedRole, clearReportingError } from '../../../reducers/reporting';
import { changeDate, fetchReport } from '../../../thunks/reporting';
import XAxisAtAngle from './XAxisAtAngle';
import Trend from './Trend';
import formatter from './NumberFormatter';
import { reports, formatDate } from './index'; // eslint-disable-line import/no-cycle

import './styles.scss';

const { object, func, bool, objectOf, oneOfType, string } = PropTypes;

class ActivityDrilldown extends Component {
  static propTypes = {
    loading: oneOfType([
      object,
      bool,
    ]),
    reports: oneOfType([
      object,
      string,
    ]),
    history: oneOfType([
      object,
      string,
    ]),
    changeDate: func.isRequired,
    setSelectedRole: func.isRequired,
    selectedRange: oneOfType([
      object,
      string,
    ]).isRequired,
    payload: objectOf(object),
  }

  static defaultProps = {
    loading: false,
    reports: null,
    history: null,
    payload: {},
  }

  constructor(props) {
    super(props);
    this.state = { disabled: {} };
  }

  async componentDidMount() {
    await this.props.fetchReport('activity');
  }

  async componentDidUpdate(prevProps) {
    const siteHasChanged = prevProps.selectedSite.key !== this.props.selectedSite.key;
    const dateHasChanged = (prevProps.selectedRange.from !== this.props.selectedRange.from) || (prevProps.selectedRange.to !== this.props.selectedRange.to);

    // If site or date has changed, re-fetch reports
    if (siteHasChanged || dateHasChanged) {
      if (!this.props.loading.activity) {
        await this.props.fetchReport('activity');
      }
    }
  }


  handleClick = (dataKey) => {
    const disabled = { ...this.state.disabled };
    if (disabled[dataKey]) {
      delete disabled[dataKey];
      this.setState({ disabled });
    } else {
      disabled[dataKey] = true;
      this.setState({ disabled });
    }
  }


  getColorByEventName = (eventName) => {
    if (this.props.report && this.props.report.labels) {
      let i = 0;
      const { labels } = this.props.report;
      const len = labels.length;
      let item = null;
      for (; i < len; i += 1) {
        item = labels[i];
        if (eventName.toLowerCase() === item.name) {
          return `${item.color} !important`;
        }
      }
    }

    return null;
  }

  renderLegend = (props) => {
    const { disabled } = this.state;
    const { payload } = props;
    return (
      <div className="toggleLegend">
        <div><h3>Candidate Activity</h3></div>
        <ul className="toggleLegendList">
          {
            payload.map((entry) => {
              const { dataKey, color } = entry;
              const active = !disabled[dataKey];
              const style = {
                marginRight: 10,
                color: active ? '#666' : '#aaa',
              };

              return (
                <span
                  id={dataKey}
                  key={dataKey}
                  className="legend-item"
                  onClick={() => this.handleClick(dataKey)}
                  onKeyPress={() => { }}
                  role="button"
                  style={style}
                  tabIndex="0"
                >

                  <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                    {!active && (
                      <Symbols
                        cx={5}
                        cy={5}
                        type="circle"
                        size={25}
                        fill="#FFF"
                      />
                    )}
                  </Surface>
                  <span>{entry.value}</span>
                </span>
              );
            }, this)
          }
        </ul>
      </div>
    );
  }

  hasAnyData = (report) => {
    let hasData = false;
    if (report.length > 0) {
      report.forEach((item) => {
        if (item.applies || item.withdrawals || item.cancellations || item.shiftViews) {
          hasData = true;
        }
      });
    }
    return hasData;
  }

  render() {
    const { disabled } = this.state;
    const reportMetadata = reports.find(report => report.name === 'events');
    return (
      <Page
        title="Reporting"
        subTitle="Activity"
        loading={this.props.loading}
      >
        <DashboardHeader />

        {this.props.reportError && (
          <ErrorModal header="Candidate Activity Report Error" errorMessage={this.props.reportError} onCancel={() => this.props.clearReportingError('activity')} />
        )}

        {this.props.loading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90%' }}
          >
            <Loading />
          </div>
        ) : (
          <div className="test-id-reports-activity">
            {!this.props.loading &&
              this.props.report?.result && this.hasAnyData(this.props.report.result) ?
                <div className="chartWrapper customLegend">
                  {this.props.report.delta ?
                    <Trend
                      positive="down"
                      delta={this.props.report.delta}
                      deltaPeriod={this.props.report.deltaPeriod}
                      deltaPeriodUnit={this.props.report.deltaPeriodUnit}
                    /> : null}
                  <ResponsiveContainer width="100%" height={600}>
                    <LineChart
                      data={this.props.report.result.map(rec => ({ ...rec, date: formatDate(rec.date, this.props.durationBetweenDates) }))}
                      margin={{ bottom: 20 }}
                      onClick={this.props.clickChart}
                    >
                      <XAxis dataKey={this.props.report.groupBy} tick={<XAxisAtAngle groupedBy={this.props.report.groupBy} />} />
                      <YAxis allowDecimals={false} tickFormatter={formatter} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip formatter={formatter} />
                      <Legend
                        payload={reportMetadata.labels.map(label => ({ dataKey: label.name, color: label.color, value: label.title }))}
                        content={this.renderLegend}
                        verticalAlign="top"
                        align="right"
                      />
                      {reportMetadata.labels
                        .filter(item => !disabled[item.name])
                        .map(label => (
                          <Line
                            key={label.name}
                            type="monotone"
                            id={label.name}
                            dataKey={label.name}
                            name={label.title}
                            stroke={label.color}
                            fillOpacity={0.1}
                            fill={label.color}
                            activeDot={{ r: 4 }}
                          />))}
                    </LineChart>
                  </ResponsiveContainer>
                </div> : <NoReportData title="Candidate Activity" />}
          </div>
        )}
      </Page>
    );
  }
}

const mapStateToProps = ({ reporting }) => {
  return {
    report: reporting.reports.activity,
    loading: reporting.loading.activity,
    reports: reporting.reports,
    selectedRange: reporting.selectedRange,
    durationBetweenDates: reporting.durationBetweenDates,
    selectedSite: reporting.selectedSite,
    reportError: reporting.reportError.activity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeDate: range => dispatch(changeDate(range)),
    setSelectedRole: role => dispatch(setSelectedRole(role)),
    fetchReport: (reportName, groupBy) => dispatch(fetchReport(reportName, groupBy)),
    clearReportingError: reportName => dispatch(clearReportingError(reportName)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityDrilldown));
