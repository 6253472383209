import React from 'react';
import colors from 'config/colors';
import fonts from 'config/fonts';
import spacing from 'config/spacing';

import Button from 'components/Button';

import AcceptanceDocuments from './AcceptanceDocuments';
import { fieldStyles } from './field-styles';

const styles = {
  button: {
    border: '0px solid black',
    color: colors.white,
    padding: spacing.base,
    borderRadius: spacing.tiny,
    width: '100%',
    fontSize: fonts.sub.size,
  },
  resetButton: {
    border: '0px solid black',
    color: colors.white,
    padding: spacing.base,
    borderRadius: spacing.tiny,
    width: '35%',
    fontSize: fonts.sub.size,
    margin: 10,
  },
  forgotButton: {
    border: 'none',
    color: colors.text,
    backgroundColor: colors.white,
    fontSize: '0.9rem',
    fontWeight: 'normal',
  },
  message: {
    textAlign: 'center',
    padding: 12,
    paddingTop: 0,
  },
};

const stopSubmit = (cb) => {
  return (ev) => {
    ev.preventDefault();
    cb();
    return false;
  };
};

const LoginForm = (props) => {

  const hasUnacceptedDocuments = props.acceptanceDocuments?.some(doc => !doc.isAccepted);
  const disabled = !props.formValues.email || !props.formValues.password || hasUnacceptedDocuments;

  const login = () => {
    if (!disabled && props.login) props.login();
  };

  return (
    <form method="post" style={styles.loginContainer} onSubmit={stopSubmit(login)}>
      {/* {props.message ? <p style={styles.message}>{props.message}</p> : null} */}
      <div>
        <label htmlFor="login-email" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
          Email Address
        </label>
        <input
          id="login-email"
          type="email"
          autoComplete="email"
          disabled={!!props.emailDisabled}
          style={{ ...fieldStyles.input, marginTop: 6, marginBottom: 24 }}
          placeholder="Email Address"
          value={props.formValues.email}
          onChange={event => props.setFormValue('email', event.target.value)}
        />
        <label htmlFor="login-password" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
          Password
        </label>
        <input
          id="login-password"
          type="password"
          autoComplete="current-password"
          style={{ ...fieldStyles.input, marginTop: 6, marginBottom: 24 }}
          placeholder="Password"
          value={props.formValues.password}
          onChange={event => props.setFormValue('password', event.target.value)}
        />
      </div>

      {!!props.acceptanceDocuments?.length && (
        <div style={{ paddingTop: 6, paddingBottom: 6 }}>
          <AcceptanceDocuments displayAcceptanceDocument={props.displayAcceptanceDocument} acceptanceDocuments={props.acceptanceDocuments} />
        </div>
      )}

      <Button black huge shadow={false} fullWidth submit disabled={disabled} style={{ fontSize: 16, marginBottom: 6 }}>
        Login
      </Button>

      {!!props.forgotPassword && (
        <div style={{ textAlign: 'center' }}>
          <Button small shadow={false} style={styles.forgotButton} onClick={props.forgotPassword}>
            Forgot your password?
          </Button>
        </div>
      )}
    </form>
  );
};

export default LoginForm;
