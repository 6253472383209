import React, { useState } from 'react';
import TextAreaAutosize from 'react-autosize-textarea';
import RatingStars from 'components/RatingStars';
import Button from 'components/Button';
import Loading from 'components/Loading';
import colors from 'config/colors';

const textAreaStyles = {
  backgroundColor: 'transparent',
  border: `1px solid ${colors.cavalry.line}`,
  borderRadius: 2,
  padding: 12,
  resize: 'none',
  overflowX: 'scroll',
  overflowY: 'none',
  outline: 'none',
  minHeight: '50%',
  width: '100%',
  fontSize: '0.9rem',
  // color: colors.grays.medium,
};

export const CandidateRatingForm = (props) => {

  const generateInitialRatingsFormState = () => {
    const initalRatingsFormState = {};
    props.candidateRatingCategories.forEach((rating) => {
      initalRatingsFormState[rating.categoryKey] = 0;
    });
    return initalRatingsFormState;
  };

  // Restore state from submitted ratings or prefill with default ratings
  const [filledRatings, setRatings] = useState(props.submittedRatings || generateInitialRatingsFormState());
  const [hoveredRatings, hoverRatings] = useState({});
  const [candidateReviewInputText, changeCandidateReviewInputText] = useState('');

  const setRating = (value, categoryKey) => {
    // Toggle rating of clicked star
    const updatedValue = value === filledRatings[categoryKey] ? value - 1 : value;
    setRatings({ ...filledRatings, [categoryKey]: updatedValue || updatedValue + 1 });
  };

  const createRatingsPayload = () => {
    const candidateRatingsPayload = [];
    Object.entries(filledRatings).forEach(([categoryKey, rating]) => {
      candidateRatingsPayload.push({ key: categoryKey, rating });
    });
    props.submitCandidateRatings({ ratings: candidateRatingsPayload, comment: candidateReviewInputText });
  };

  const confirmButtonDisabled = Object.values(filledRatings).some(rating => !rating);

  return (
    <div className="candidate-rating">
      <h4 className="timesheet__header">Candidate Rating</h4>
      {!props.adminIsInSameOrgAsShift && !props.submittedRatings ?
        <div className="candidate-review">
          <p className="candidate-review__info-text">A candidate rating has not yet been submitted.</p>
        </div>
        :
        <>
          <div className="rating-table">
            {props.candidateRatingCategories.map(({ categoryKey, categoryName, categoryDescription, categoryValues }) => {
              // Find description if rating is being hovered, or has been selected
              let ratingDescription = null;
              const ratingValue = hoveredRatings[categoryKey] || filledRatings[categoryKey];
              if (ratingValue) {
                ratingDescription = categoryValues.find(item => item.value === ratingValue).description;
              }

              return (
                <div className="rating-table__row">
                  <div className="rating-table__category">
                    <p className="rating-table__category--name">{categoryName}</p>
                    <p className="rating-table__category--description">{categoryDescription}</p>
                  </div>
                  <div className="rating-table__rating">
                    <RatingStars
                      onClick={value => setRating(value, categoryKey)}
                      numberOfStars={5}
                      rating={ratingValue}
                      disabled={!!props.submittedRatings}
                      onMouseOver={value => hoverRatings({ [categoryKey]: value })}
                      onMouseOut={() => hoverRatings({})}
                      onFocus={() => undefined}
                      onBlur={() => undefined}
                    />
                  </div>
                  <div className="rating-table__description">
                    {ratingDescription && <p>{ratingDescription}</p>}
                  </div>
                </div>
              );
            })}
          </div>
          {props.submittedReview && (
            <div className="candidate-review">
              <p className="rating-table__category--name">Review - </p>
              <p className="candidate-review__info-text">{props.submittedReview}</p>
            </div>
          )}
          {!props.submittedRatings && (
            <div className="candidate-review">
              <p className="candidate-review__info-text">Submit a public review (optional)</p>
              <TextAreaAutosize
                style={textAreaStyles}
                value={candidateReviewInputText}
                onChange={e => changeCandidateReviewInputText(e?.target?.value)}
              />
            </div>
          )}
          {!props.submittedRatings && (
            <div className="candidate-rating__buttons">
              {props.submittingCandidateRatings && <Loading size={30} />}
              <Button disabled={confirmButtonDisabled} onClick={() => createRatingsPayload()} large>Submit</Button>
            </div>
          )}
        </>
    }
    </div>
  );
};
