import React from 'react';
import { MdMoreVert } from 'react-icons/md';

import useDropdownMenu from 'hooks/useDropdownMenu';
import './more-button.scss';

interface Props {
  menuItems: { key: string, label: string, onClick: () => void, color?: string }[],
  openMenuDirection?: 'left' | 'right'
}

export default function MoreButton(props: Props): React.ReactElement {

  const { isOpen, setIsOpen, containerEl } = useDropdownMenu();

  return (
    <div ref={containerEl} className="more-button">
      <div role="button" tabIndex={0} onClick={() => setIsOpen(true)}>
        <MdMoreVert size={24} color="#666" />
      </div>
      {isOpen && (
        <div className={`more-button__menu more-button__menu--${props.openMenuDirection}`}>
          {props.menuItems.map((button) => {
            return (
              <div
                key={button.key}
                className="more-button__sub-button"
                style={{ color: button.color ?? '#333' }}
                role="button"
                tabIndex={0}
                onClick={button.onClick}
              >
                {button.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

MoreButton.defaultProps = { openMenuDirection: 'right' };
