import React, { useEffect, useState } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Loading from 'components/Loading';

import spacing from 'config/spacing';
import colors from 'config/colors';

import { PublishedShiftSummary, TemplateSuccessfulResponse, TemplateUnsuccessfulResponse } from 'types/ShiftTypes';

import * as globalThunks from 'thunks/global';
import { saveToTemplate } from 'thunks/templates';

import { clearCreateTemplateError } from 'reducers/templates';

const styles = {
  list: { listStyleType: 'none', lineHeight: 1.4 },
  listItem: { color: colors.text },
  label: {
    margin: '3px 0px 6px 0px',
    color: colors.text,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 3,
  },
  scrollContainer: {
    flex: '1',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    fontSize: '0.9rem',
    color: colors.text,
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 2,
    padding: 12,
    outline: 'none',
    margin: '3px 0px 6px 0px',
  },
  buttonContainer: {
    paddingTop: 6,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 18,
    borderTop: '1px solid #EAEAEA',
  },
};

interface SaveToTemplateModalProps {
  selectedShifts: Array<PublishedShiftSummary>,
  onClose: () => void,
  unselectSpecifiedShifts: (shiftKeys: Array<string>) => void,
  replaceToTemplateEditView: (templateKey: string) => void,
}

function isSuccessfulResponse(response: TemplateSuccessfulResponse | TemplateUnsuccessfulResponse | null): response is TemplateSuccessfulResponse {
  return !!(response as TemplateSuccessfulResponse)?.success;
}

function SaveToTemplateModal(props: SaveToTemplateModalProps): React.ReactElement {

  const dispatch = useAppDispatch();
  const shiftCreateMetadata = useSelector(({ global }) => global.shiftCreateMetadata);
  const fetchShiftCreationMetadataError = useSelector(({ global }) => global.fetchShiftCreationMetadataError);
  const isFetchingShiftCreationMetadata = useSelector(({ global }) => global.isFetchingShiftCreationMetadata);
  const isCreatingTemplate = useSelector(({ templates }) => templates.isCreatingTemplate);
  const createTemplateError = useSelector(({ templates }) => templates.createTemplateError);

  const [templateName, setTemplateName] = useState('');
  const [saveBookedCandidatesIsChecked, setSaveBookedCandidatesIsChecked] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearCreateTemplateError());
    };
  }, []);

  const saveShiftsToTemplate = async () => {
    // Fetch shiftCreateMetadata to search applicable rateCards
    if (!shiftCreateMetadata) await dispatch(globalThunks.fetchShiftCreateMetadata());
    const response = await dispatch(saveToTemplate(props.selectedShifts, templateName, saveBookedCandidatesIsChecked));

    // If success, nav user to edit template view
    if (isSuccessfulResponse(response)) {
      const selectedShiftKeys = props.selectedShifts.map(shift => shift.key);
      props.unselectSpecifiedShifts(selectedShiftKeys);
      props.replaceToTemplateEditView(response.key);
    }

    // If unsuccessful, but an error was not thrown, then endpoint has failed with shift validation errors. In this case, nav user to blank template view with shift errors
    if (!isSuccessfulResponse(response) && !(response instanceof Error)) {
      const selectedShiftKeys = props.selectedShifts.map(shift => shift.key);
      props.unselectSpecifiedShifts(selectedShiftKeys);
      props.replaceToTemplateEditView('new');
    }
  };

  if (isFetchingShiftCreationMetadata) {
    return (
      <Modal
        isOpen
        onRequestClose={props.onClose}
        header="Save To Template"
        contentStyle={{ justifyContent: 'space-between', width: 400 }}
        bodyStyle={{ padding: spacing.base }}
        vflex
      >
        <Loading flex />
      </Modal>
    );
  }

  if (fetchShiftCreationMetadataError) {
    return (
      <Modal
        isOpen
        onRequestClose={props.onClose}
        header="Save To Template"
        contentStyle={{ justifyContent: 'space-between', width: 400 }}
        bodyStyle={{ padding: spacing.base }}
        vflex
      >
        <p style={{ color: colors.red }}>{fetchShiftCreationMetadataError}</p>
        <div style={{ marginTop: spacing.base }}>
          <Button outline>Retry</Button>
        </div>
      </Modal>
    );
  }


  const selectedShiftsCount = props.selectedShifts.length;
  return (
    <Modal
      isOpen
      onRequestClose={props.onClose}
      header="Save To Template"
      contentStyle={{ justifyContent: 'space-between', width: 500 }}
      bodyStyle={{ padding: spacing.base }}
      vflex
    >
      <p style={styles.label}>{`${selectedShiftsCount} shift${selectedShiftsCount > 1 ? 's' : ''} selected`}</p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label style={{ lineHeight: 1, color: '#999', fontSize: '0.9rem', marginRight: 12, width: '30%' }}>Template Name</label>
        <input
          style={styles.input}
          placeholder="Template Name"
          value={templateName}
          onChange={event => setTemplateName(event.target.value)}
        />
      </div>
      <div style={styles.checkboxContainer}>
        <Checkbox
          checked={saveBookedCandidatesIsChecked}
          onChange={() => setSaveBookedCandidatesIsChecked(!saveBookedCandidatesIsChecked)}
        />
        <p style={{ color: colors.text, marginLeft: 12 }}>Save booked candidates to template</p>
      </div>
      <div style={styles.buttonContainer} className="space-children-6">
        {isCreatingTemplate && <Loading size={32} />}
        <Button
          white
          outline
          disabled={isCreatingTemplate}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={() => saveShiftsToTemplate()}
          disabled={!templateName || isCreatingTemplate}
        >
          Save
        </Button>
      </div>
      {createTemplateError && <p style={{ color: colors.red, fontSize: 14, marginTop: 6 }}>{createTemplateError}</p>}
    </Modal>
  );
}

export default SaveToTemplateModal;
