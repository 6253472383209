import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchComplianceItems } from 'lib/api/compliance';
import { fetchCandidates } from 'thunks/bank';
import { reportError } from 'lib/error-reporting';
import colors from 'config/colors';
import './ComplianceTab.scss';
import Loading from 'components/Loading';
import { Category, ItemCategory, CategoryItem, SelectedItem } from 'types/ComplianceTypes';
import ComplianceItem from './ComplianceItem';
import { ComplianceItemsList } from './ComplianceItemsList';

const createItemCategoriesList = (itemCategories: Array<Category>, items: Array<CategoryItem>) => {
  return itemCategories.map((cat) => {
    const categoryItems = items.filter(({ itemCategoryKey, candidateHasItem }) => itemCategoryKey === cat.key && candidateHasItem);
    return { ...cat, items: categoryItems };
  }).filter(cat => cat.items.length);
};

interface ComplianceTabProps { candidateKey: string, adminHasWriteAccess: boolean }

const styles = {
  centreContainer: {
    display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center',
  },
};

const ComplianceTab : React.FC<ComplianceTabProps> = (props: ComplianceTabProps) => {

  const [itemCategories, setItemCategories] = useState([] as ItemCategory[]);
  const [selectedItem, setSelectedItem] = useState(null as SelectedItem | null);
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [apiError, setApiError] = useState(null as string | null);
  const dispatch = useDispatch();

  const fetchItems = async (firstRender = false) => {
    try {
      // Fetch compliance items from API
      if (firstRender) setIsFetchingItems(true);
      const { complianceItemCategories, complianceItems } = await fetchComplianceItems(props.candidateKey);

      // Create categories array with nested items. Pick default selected item
      const itemCategoriesList = createItemCategoriesList(complianceItemCategories, complianceItems);
      setItemCategories(itemCategoriesList);

      if (!selectedItem) {
        const defaultSelectedItem = itemCategoriesList?.[0]?.items?.[0] ?? null;
        setSelectedItem(defaultSelectedItem);
      }

      setIsFetchingItems(false);
    } catch (error) {
      setApiError(error.message);
      setIsFetchingItems(false);
      reportError(error);
    }
  };

  useEffect(() => {
    fetchItems(true);
  }, [props.candidateKey]);

  if (apiError) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.red }}>Oops! Something went wrong. Please try again. If the problem persists, please contact customer support.</p>
      </div>
    );
  }

  if (isFetchingItems) {
    return (
      <div style={styles.centreContainer}>
        <Loading />
      </div>
    );
  }

  if (!isFetchingItems && itemCategories.length === 0) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.text, fontSize: '0.9rem' }}>There is no compliance information to view.</p>
      </div>
    );
  }

  const fetchItemsAndUpdateListView = async () => {
    fetchItems();

    // Update list view
    dispatch(fetchCandidates());
  };

  return (
    <div className="compliance-tab">
      <main className="compliance-tab-main">
        <ComplianceItemsList
          itemCategories={itemCategories}
          selectedItemKey={selectedItem?.key ?? null}
          setSelectedItem={setSelectedItem}
        />
        <div className="compliance-item">
          {selectedItem && (
            <>
              <h4 className="compliance-item__header">{selectedItem.name}</h4>
              <ComplianceItem fetchItemsAndUpdateListView={fetchItemsAndUpdateListView} itemKey={selectedItem.key} candidateKey={props.candidateKey} adminHasWriteAccess={props.adminHasWriteAccess} />
            </>
          )}
        </div>
      </main>
    </div>
  );
};

ComplianceTab.propTypes = { candidateKey: PropTypes.string.isRequired, adminHasWriteAccess: PropTypes.bool.isRequired };

export default ComplianceTab;
