import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { MdPerson, MdReportProblem, MdContentCopy, MdDelete } from 'react-icons/md';
import { IoIosConstruct } from 'react-icons/io';

import { PublishedShiftSummary, TemplateShift } from 'types/ShiftTypes';
import colors from 'config/colors';
import Checkbox from 'components/Checkbox';
import Loading from 'components/Loading';
import './ShiftCard.scss';

const styles = {
  actionButton: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: colors.text,
    color: colors.white,
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow: '0px 1px 2px 1px rgb(0 0 0 / 40%)',
  },
};


function formatRgs(shift: PublishedShiftSummary) {

  const hasRole = !!shift.roleKey;
  const hasGrade = !!shift.gradeKey;
  const hasSpeciality = !!shift.specialityKey;

  if (!hasRole && !hasGrade && !hasSpeciality) return 'Any';
  if (!hasRole && !hasGrade) return shift.specialityName;
  if (!hasGrade || shift.gradeName === shift.roleName || shift.gradeName === 'All') return shift.roleName;
  if (!hasRole) return shift.gradeName;
  return `${shift.roleName} (${shift.gradeName})`;
}

function getBackgroundColor(shift: PublishedShiftSummary) {
  const slotsFilled = shift.bookedCandidates.length;
  if (shift.isDraft) return colors.cavalry.invalid;
  if (slotsFilled === shift.slotsRequired) return colors.cavalry.active;
  if (slotsFilled > 0) return colors.cavalry.pending;
  return colors.cavalry.error;
}

function getBorderColor(shiftIsSelected: boolean, shift: PublishedShiftSummary) {
  if (shiftIsSelected) return '#000';
  if (shift.isDraft && !shift.isValid) return colors.cavalry.error;
  return getBackgroundColor(shift);
}

const DraftShiftDecal = ({ shift } : { shift: PublishedShiftSummary }) : JSX.Element => {
  if (shift.isCreating) return <Loading size={12} />;
  if (shift.isValid) return <IoIosConstruct className="calendarToolbarIcon icon" />;
  if (shift.response) {
    return (
      <div
        className="messagingMessageStatus"
        data-tip={shift.response}
      >
        <MdReportProblem size={14} className="calendarToolbarIcon icon" style={{ color: colors.text }} />
        <ReactTooltip />
      </div>
    );
  }
  return <MdReportProblem size={14} className="calendarToolbarIcon icon" style={{ color: colors.text }} />;
};


interface ShiftCardProps {
  shift: PublishedShiftSummary;
  openShiftTab: (shiftKey: string, isDraft?: boolean, tab?: string) => void;
  selectedShift?: string;
  selectedShiftKeys: Array<string>;
  setShiftIsSelected: (shiftKey: string, isSelected: boolean) => void;
  zIndex?: number;
  formatTime: (shift: PublishedShiftSummary | TemplateShift) => string,
  removeShift: (key: string) => void,
  copyShift: (key: string) => void,
}

const ShiftCard = ({ shift, openShiftTab, selectedShift, selectedShiftKeys, setShiftIsSelected, formatTime, removeShift, copyShift, zIndex }: ShiftCardProps) : JSX.Element => {

  const shiftIsSelectedOne = selectedShift === shift.key;
  const shiftIsSelectedViaCheckbox = selectedShiftKeys?.includes(shift.key);
  const shiftIsSelected = shiftIsSelectedOne || shiftIsSelectedViaCheckbox;
  const applicantCount = shift.bankApplicantCount + shift.supplierApplicantCount;

  const isSelecting = !!selectedShiftKeys.length;
  const toggleIsSelected = () => setShiftIsSelected(shift.key, !shiftIsSelectedViaCheckbox);

  const browserIsIE11 = window?.navigator?.userAgent.indexOf('Trident') !== -1;
  const slotsFilled = shift.bookedCandidates.length;
  const allSlotsFilled = slotsFilled === shift.slotsRequired;
  const singleSlotFilled = allSlotsFilled && slotsFilled === 1;
  const { bookedCandidates } = shift;
  return (
    <div
      className={classNames({
        shiftCard: true,
        [`shiftCard--${shift.key}`]: true,
        'shiftCard--is-draft': shift.isDraft,
      })}
      style={{
        textAlign: 'left',
        padding: 6,
        margin: 3,
        lineHeight: 0,
        backgroundColor: getBackgroundColor(shift),
        borderRadius: 2,
        position: 'relative',
        cursor: shift.isTemplateDraft ? 'auto' : 'pointer',
        boxSizing: 'border-box',
        borderWidth: shift.isTemplateDraft && !browserIsIE11 ? 0 : 1,
        borderStyle: shift.isTemplateDraft && browserIsIE11 ? 'dashed' : 'solid',
        borderColor: shift.isTemplateDraft && browserIsIE11 ? '#333' : getBorderColor(shiftIsSelected, shift),
        zIndex,
      }}
      onClick={() => {
        if (shift.isTemplateDraft) return null;
        if (isSelecting) return toggleIsSelected();
        if (shift.isDraft) return openShiftTab(shift.key, !!shift.isDraft, 'edit');
        if (!allSlotsFilled && applicantCount) return openShiftTab(shift.key, !!shift.isDraft, 'bookings');
        return openShiftTab(shift.key, !!shift.isDraft, 'details');
      }}
      role="button"
      tabIndex={0}
    >

      {/* Add marching ants border if shift is a template draft */}
      {shift.isTemplateDraft && !browserIsIE11 && (
        <svg preserveAspectRatio="none" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <rect className="shiftCard__marching-ants" />
        </svg>
      )}

      {/* Role / Booked Candidate */}
      <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 1.3, verticalAlign: 'middle', fontSize: '0.9rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div
            style={{ color: colors.white, fontWeight: singleSlotFilled ? 700 : 400 }}
            role="button"
            tabIndex={0}
            onClick={isSelecting ? toggleIsSelected : (e: MouseEvent) => {
              if (shift.isTemplateDraft) return null;
              e.stopPropagation();
              return openShiftTab(shift.key, !!shift.isDraft, shift.isDraft ? 'edit' : 'bookings');
            }}
          >
            {singleSlotFilled ? `${bookedCandidates[0].candidateName}` : `${slotsFilled}/${shift.slotsRequired} filled`}
          </div>
          {shift.isDraft && (
            <span style={{ flex: '0 0 auto', color: 'white', marginTop: -6 }}>
              <DraftShiftDecal shift={shift} />
            </span>
          )}
          {!shift.isDraft && !!applicantCount && (
            <span
              className="h-underline"
              role="button"
              tabIndex={0}
              style={{ flex: '0 0 auto', color: 'white', marginTop: -6 }}
              onClick={isSelecting ? toggleIsSelected : (e: MouseEvent) => {
                e.stopPropagation();
                openShiftTab(shift.key, !!shift.isDraft, 'bookings');
              }}
            >
              <MdPerson className="calendarToolbarIcon icon" style={{ color: colors.white }} />{applicantCount}
            </span>
          )}
        </div>

        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: colors.white, fontWeight: 100, flex: 1 }}>
          {formatRgs(shift)}
        </div>
      </div>

      {/* Shift Times */}
      <div
        style={{
          fontSize: '0.9rem',
          fontWeight: 400,
          color: colors.white,
          lineHeight: 1,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        {formatTime(shift)}
      </div>

      {/* Checkbox */}
      {!shift.isTemplateDraft && (
        <>
          <div
            className={classNames('shiftCardOverlay', { shiftCardOverlayAlwaysShow: selectedShiftKeys.length })}
            style={{ position: 'absolute', right: 2, bottom: 0 }}
          >
            <Checkbox
              size={16}
              checked={shiftIsSelectedViaCheckbox}
              onChange={checked => setShiftIsSelected && setShiftIsSelected(shift.key, checked)}
            />
          </div>

          <div
            className={classNames('shiftCardOverlay')}
            style={{ position: 'absolute', left: '50%', marginLeft: shift.isDraft ? -21 : -9, bottom: -9 }}
          >
            <div
              title="Copy Shift"
              className="rise"
              style={styles.actionButton}
              onClick={(e) => { e.stopPropagation(); copyShift(shift.key); }}
            >
              <MdContentCopy size={10} />
            </div>
            {shift.isDraft &&
              <div
                title="Delete Draft"
                className="rise"
                style={{ ...styles.actionButton, marginLeft: 6 }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeShift(shift.key);
                }}
              >
                <MdDelete size={10} />
              </div>
            }
          </div>
        </>
      )}

      {/* Site */}
      {/* <div style={{ lineHeight: 1.3, verticalAlign: 'middle', color: '#666' }}> */}
      {/*   <span style={{ fontSize: '0.9rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{(shift.siteAbbr && shift.areaName) ? `${shift.siteAbbr} (${shift.areaName})` : shift.siteName}</span> */}
      {/* </div> */}

    </div>
  );
};

ShiftCard.defaultProps = {
  selectedShift: null,
  zIndex: 'auto',
};

export default ShiftCard;
