import React from 'react';
import PropTypes, { bool } from 'prop-types';

import { DEFAULT_PAGINATION_LIMIT } from 'reducers/timesheets';

import DataTable from './DataTable';
import PaginationControls from './PaginationControls';

const { objectOf, oneOfType, string, func, number } = PropTypes;
const ROW_COUNT_OPTIONS = [50, 100, 200, 500];

const styles = {
  scrollContainer: {
    flex: '1',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
};

class PaginatedDataTable extends React.Component {

  static propTypes = {
    ...DataTable.propTypes,
    updatePagination: func,
    filters: objectOf(oneOfType([string, number])),
    style: objectOf(oneOfType([number, string])),
    moreRowsAvailable: bool.isRequired,
    totalRowCount: number.isRequired,
    showTotalRowCount: bool,
  }

  static defaultProps = {
    ...DataTable.defaultProps,
    updatePagination: () => {},
    style: {},
    filters: null,
    showTotalRowCount: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      limit: DEFAULT_PAGINATION_LIMIT,
      offset: 0,
      page: 1,
    };
  }

  componentDidUpdate(prevProps) {
    // If filters change, reset back to first page
    if (this.props.filters && this.props.filters !== prevProps.filters) {
      this.setState({ page: 1 });
      this.props.updatePagination(0, this.state.limit);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.rows !== prevState.rows) ? { offset: 0 } : null;
  }

  updateRowsPerPage = async (newLimit) => {
    const limit = parseInt(newLimit, 10);
    this.setState({ limit, page: 1 });
    this.props.updatePagination(0, limit);
  }

  updatePaginationState = (newState) => {
    let page;
    let offset;
    if (newState.direction === 'next') {
      page = this.state.page + 1;
      offset = (page - 1) * this.state.limit;
    } else if (newState.direction === 'previous') {
      page = this.state.page - 1;
      offset = (page - 1) * this.state.limit;
    } else if (newState.direction === 'first') {
      page = 1;
      offset = 0;
    } else {
      page = Math.ceil(this.props.totalRowCount / this.state.limit);
      offset = (page - 1) * this.state.limit;
    }
    this.setState({ offset, page });
    this.props.updatePagination(offset, this.state.limit);
  }

  render() {

    const { totalRowCount } = this.props;

    const rowsOnCurrentPage = this.props.rows.slice(this.state.offset, this.state.offset + this.state.limit);
    const moreRowsAvailable = this.props.moreRowsAvailable ?? this.props.rows.length > rowsOnCurrentPage.length;

    const startOfRange = ((this.state.page - 1) * this.state.limit) + 1;
    const projectedEndOfRange = ((this.state.page - 1) * this.state.limit) + this.state.limit;
    const endOfRange = Math.min(projectedEndOfRange, totalRowCount);

    const pages = Math.ceil(totalRowCount / this.state.limit);

    return (
      <div className="paginatedDataTable" style={{ ...styles.scrollContainer, ...this.props.style }}>
        <div style={{ flex: 1, overflowX: 'auto', overflowY: 'auto' }}>
          <DataTable {...this.props} rows={rowsOnCurrentPage} style={this.props.tableStyle} />
        </div>
        <PaginationControls
          isLoading={this.props.isLoading}
          rows={this.props.rows}
          totalRowCount={totalRowCount}
          rowsPerPageOptions={ROW_COUNT_OPTIONS}
          selectedRowsPerPage={this.state.limit}
          onPageChange={this.updatePaginationState}
          updateRowsPerPage={this.updateRowsPerPage}
          moreRowsAvailable={moreRowsAvailable}
          page={this.state.page}
          pages={pages}
          startOfRange={startOfRange}
          endOfRange={endOfRange}
          showTotalRowCount={this.props.showTotalRowCount}
        />
      </div>
    );
  }
}

export default PaginatedDataTable;
