import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

import spacing from 'config/spacing';
import colors from 'config/colors';

import MainMenu from '../MainMenu';
import { useMenuItems } from '../MainMenu/menu-items';
import NotificationBell from '../NotificationBell';

import Logo from './Logo';
import UserBar from './UserBar';

const styles = {
  topMenuBar: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
    height: 48,
    boxSizing: 'border-box',
    backgroundColor: colors.white,
    color: colors.text,
  },
  topMenuBarHeader: {
    fontSize: 28,
    lineHeight: 1,
    fontWeight: 'normal',
    marginTop: 3,
    marginLeft: 12,
    whiteSpace: 'nowrap',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'elipsis',
  },
  openCloseButton: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '12px 12px 12px 6px',
  },
  overlayMenu: {
    position: 'fixed',
    zIndex: 99999,
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
    padding: `${spacing.base}px 0`,
    backgroundColor: colors.cavalry.backgroundLight6,
    fontSize: 20,
  },
};

const TopBar = (props) => {

  const menuItems = useMenuItems();
  const pathName = window.location.pathname;
  const activeMenuItem = menuItems.find(item => pathName.startsWith(item.activeUrl || item.url));
  const topMenuTitle = activeMenuItem?.title ?? 'Dagny';

  const [topMenuOpen, setTopMenuOpen] = useState(false);
  const toggleMenu = () => setTopMenuOpen(!topMenuOpen);

  return (
    <>
      {/* Top-bar */}
      <div style={styles.topMenuBar}>

        {/* Page title */}
        <div onClick={toggleMenu}>
          <h1 style={styles.topMenuBarHeader}>{topMenuTitle}</h1>
        </div>

        <div style={{ flex: 1 }} />

        {/* Buttons */}
        {!topMenuOpen && props.topBarButtons ? props.topBarButtons : null}

        {/* Notification Bell */}
        {!topMenuOpen ?
          <NotificationBell onClick={props.openNotificationCentre} />
          : null
        }

        {/* Overlay menu open/close button */}
        <div style={styles.openCloseButton} onClick={toggleMenu}>
          {!topMenuOpen ? <FaBars color={colors.text} size={20} /> : <FaTimes color={colors.text} size={20} />}
        </div>
      </div>

      {/* Full-Screen overlay menu */}
      {!!topMenuOpen && (
        <div style={styles.overlayMenu}>
          <MainMenu isOpen expandAll />
          <UserBar isOpen />
          <div style={{ position: 'absolute', right: 12, bottom: 12 }}><Logo showText /></div>
        </div>
      )}
    </>
  );
};

export default TopBar;

