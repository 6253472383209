import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import spacing from '../../config/spacing';

const styles = {
  filterBarButton: {
    marginRight: spacing.base,
    marginTop: '6px',
    marginBottom: '6px',
  },
};

const FilterBarButton = props => (
  <Button {...props} style={{ ...styles.filterBarButton, ...(props.style) }} />
);

const { objectOf, oneOfType, string, number } = PropTypes;
FilterBarButton.propTypes = {
  style: objectOf(oneOfType([string, number])),
};
FilterBarButton.defaultProps = {
  style: {},
};

export default FilterBarButton;
