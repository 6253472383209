import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import ErrorModal from 'components/ErrorModal';
import colors from '../../../config/colors';
import CreamFormatter from './CreamFormatter';
import NumberFormatter from './NumberFormatter';
import XAxisAtAngle from './XAxisAtAngle';
import Trend from './Trend';
import NoReportData from '../../../components/NoReportData';
import { reports, headerFormatter, formatDate } from './index'; // eslint-disable-line import/no-cycle

import './styles.scss';

const formatter = value => `£${NumberFormatter(value)}`;

function getReportTotal(report, labels) {
  let reportTotal = 0;
  if (report) {
    report.forEach((data) => {
      labels.forEach((label) => {
        reportTotal += data[label];
      });
    });
  }
  return reportTotal;
}

const generatePayloadForChart = ({ report, labels, durationBetweenDates }) => {
  if (!report) return null;
  return report.result.map((data) => {
    const payload = {};
    labels.forEach((label) => {
      payload[label] = data[label];
      payload.date = formatDate(data.date, durationBetweenDates);
    });
    return payload;
  });
};


export default function SpendReport(props) {

  // Find metadata and methods from report config
  const { labels, dataFormatter } = reports.find(report => report.name === 'spend');

  // Create formatted payload for chart
  const reportPayloadForChart = generatePayloadForChart({ report: props.report, labels, durationBetweenDates: props.durationBetweenDates });

  return (
    <>
      {props.reportError && (
        <ErrorModal header="Spend Report Error" errorMessage={props.reportError} onCancel={() => props.clearReportingError('spend')} />
      )}
      <div className="test-id-reports-spend">
        {!props.loading && reportPayloadForChart ?
          <div className="chartWrapper">
            {props.report.delta ?
              <Trend
                positive="down"
                delta={props.report.delta}
                deltaPeriod={props.report.deltaPeriod}
                deltaPeriodUnit={props.report.deltaPeriodUnit}
              /> : null}
            <h3 style={{ color: colors.text, fontSize: '1.2rem', textTransform: 'none', fontWeight: 600 }}>
              Temporary Staffing Spend
            </h3>
            <p className="report-explanation">Temporary staffing spend based on ESR pay rate; calculated at the time of timesheet approval.</p>
            <h4 className="report-total">{`Total Spend: ${formatter(getReportTotal(reportPayloadForChart, labels))}`}</h4>

            <div style={{ marginBottom: 48 }}>
              <ResponsiveContainer width="99%" height={300}>
                <BarChart data={reportPayloadForChart} margin={{ bottom: 20 }} onClick={props.onClickChart}>
                  <XAxis height={40} dataKey="date" tick={<XAxisAtAngle groupedBy="date" />} />
                  <YAxis allowDecimals={false} tickFormatter={CreamFormatter} />
                  <Tooltip formatter={formatter} />
                  <Legend verticalAlign="top" />
                  <Bar dataKey="bank" name="Bank" stackId="a" fill={colors.cavalry.bankFilled} />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {props.drillDownComponent && props.drillDownComponent({
              labels,
              report: reportPayloadForChart,
              numberFormatter: dataFormatter,
              headerFormatter,
              groupBy: props.report.groupBy,
            })}

          </div> : <NoReportData title="Temporary Staffing Spend" />}
      </div>
    </>
  );
}

const { object, array, shape, bool, oneOfType, number, node, element, func, string } = PropTypes;

SpendReport.propTypes = {
  report: shape({
    result: array.isRequired,
    siteFilter: string,
    roleFilter: string,
    groupBy: string,
  }),
  loading: bool.isRequired,
  reportError: string,
  drillDownComponent: oneOfType([node, element, object, func]),
  clearReportingError: func.isRequired,
  durationBetweenDates: number.isRequired,
  onClickChart: func,
};

SpendReport.defaultProps = {
  report: null,
  drillDownComponent: null,
  reportError: '',
  onClickChart: () => {},
};
