import React, { CSSProperties, useState } from 'react';
import colors from 'config/colors';
import fonts from 'config/fonts';
import spacing from 'config/spacing';
import Loading from 'components/Loading';
import Logo from 'components/Page/Logo';

import './AuthPage.scss';

const styles : Record<string, CSSProperties> = {
  background: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.cavalry.backgroundLight6,
    position: 'relative',
  },
  contentContainer: {
    borderRadius: 9,
    width: 320,
    height: 'auto',
    margin: '48px',
    textAlign: 'left',
    lineHeight: 1.3,
    color: colors.text,
    backgroundColor: colors.white,
    padding: '48px',
    boxShadow: '0 12px 18px 0 rgba(50,50,93,.2), 0 3px 6px 0 rgba(0,0,0,.05)',
  },
  loadingContainer: {
    borderRadius: spacing.tiny,
    margin: '0 auto',
    textAlign: 'center',
    padding: spacing.base,
    marginTop: spacing.base,
    backgroundColor: colors.white,
    lineHeight: 0.1,
  },
  header: {
    textAlign: 'center',
    marginBottom: spacing.base,
    color: colors.text,
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
  },
};

interface AuthPageProps {
  children: JSX.Element | JSX.Element[] | null,
  header?: string,
  loading?: boolean,
}

const AuthPage = (props: AuthPageProps) : JSX.Element => {

  if (props.loading) {
    return (
      <div style={styles.background}>
        <div style={styles.loadingContainer}>
          <Loading size={50} />
        </div>
      </div>
    );
  }

  return (
    <div style={styles.background}>
      <div style={{}}>
        <Logo size={200} />
      </div>
      <div style={styles.contentContainer}>
        {props.header && (
          <h2 style={styles.header}>
            {props.header}
          </h2>
        )}
        {props.children}
      </div>
    </div>
  );
};

AuthPage.defaultProps = {
  header: null,
  loading: false,
};

export default AuthPage;
