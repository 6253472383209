import React, { CSSProperties } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { Moment } from 'moment';

import { useSelector, useAppDispatch } from 'hooks/redux';
import { Period, ShiftDetailsTab } from 'types/ShiftTypes';

import spacing from 'config/spacing';
import { getShiftListExport } from 'thunks/jobs';
import { fetchShiftListExportError } from 'reducers/jobs';
import { createDraftShift } from 'thunks/createShifts';
import { setShiftDetailsIsPinned } from 'reducers/userInterface';

import { MdPushPinOutline, MdPushPinFilled } from 'components/Icons';
import Button from 'components/Button';
import Feature from 'components/Feature';
import Loading from 'components/Loading';
import ErrorModal from 'components/ErrorModal';


interface CreateShiftButtonProps {
  period: Period;
  goToShiftTab: (date: Moment | undefined, shiftKey: string, isDraft: boolean, tab: ShiftDetailsTab) => void;
  label?: string;
  style?: CSSProperties;
}

export const CreateShiftButton = (props: CreateShiftButtonProps) : JSX.Element => {
  // console.log('props: ', props);
  const dispatch = useAppDispatch();
  const isMobile = useSelector(state => state.screen.isMobile);

  const createNewDraftShift = async () => {
    const draftId = await dispatch(createDraftShift(props.period.startDate));
    props.goToShiftTab(undefined, draftId, true, 'edit');
  };

  return (
    <Feature featureFlag="shiftsCreation" permissionRequired="jobsCanCreate">
      <Button
        shadow={false}
        black
        onClick={createNewDraftShift}
        style={props.style}
      >
        <span style={{ marginRight: 6 }}>
          {!isMobile ? (props.label ?? 'Shift') : '+'}
        </span>
        +
      </Button>
    </Feature>
  );
};

CreateShiftButton.defaultProps = {
  label: undefined,
  style: undefined,
};


interface ExportShiftsButtonProps {
  period: Period;
}

export const ExportShiftsButton = (props: ExportShiftsButtonProps) : JSX.Element => {

  const dispatch = useAppDispatch();

  const isMobile = useSelector(state => state.screen.isMobile);
  const isFetchingShiftListExport = useSelector(state => state.jobs.isFetchingShiftListExport);
  const exportError = useSelector(state => state.jobs.exportError);

  return (
    <>
      {isFetchingShiftListExport ?
        <div style={{ marginRight: spacing.base, marginTop: spacing.tiny }}><Loading size={30} /></div>
        :
        <Button
          white
          rise
          shadow={false}
          onClick={() => dispatch(getShiftListExport(props.period.startDate, props.period.endDate.clone().endOf('day')))}
        >
          <span style={{ paddingRight: 3 }}>{!isMobile ? 'Export' : null}</span>  <MdFileDownload />
        </Button>
      }
      <ErrorModal errorMessage={exportError} onCancel={() => dispatch(fetchShiftListExportError(null))} header="Export Error" />
    </>
  );
};


export const PinShiftsPanelButton = () : JSX.Element => {

  const dispatch = useAppDispatch();
  const isPinned = useSelector(state => state.userInterface.shiftDetailsIsPinned);

  return (
    <>
      <Button negative outline onClick={() => dispatch(setShiftDetailsIsPinned(!isPinned))}>
        {isPinned ? <MdPushPinFilled /> : <MdPushPinOutline />}
      </Button>
    </>
  );
};

