import { AdminGroup, PermissionsMetadata, AdminGroupFromList, AdminGroupPermissions } from 'types/AdminGroup';

import * as api from '.';

interface AdminGroupDetailsAPIResponse {
  adminGroupDetails: AdminGroup,
  permissionsMetadata: PermissionsMetadata
}

export async function fetchAdminGroupList(): Promise<AdminGroupFromList[]> {
  const response = await api.get('admin-group/list');

  if (response.body?.adminGroups) return response.body.adminGroups;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to fetch admin groups. If the problem persists, please contact technical support.';
  throw new Error(error);
}


export async function fetchAdminGroupDetails(adminGroupKey:string): Promise<AdminGroupDetailsAPIResponse> {
  const response = await api.get('admin-group/details', { adminGroupKey });

  if (response.body?.adminGroupDetails && response.body.permissionsMetadata) {
    return response.body;
  }

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to fetch admin group details. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function addAdminGroupMembers(adminGroupKeys: Array<string>, adminKeys: Array<string>, permissions: AdminGroupPermissions): Promise<void> {
  const response = await api.post('admin-group/add-members', { adminGroupKeys, adminKeys, permissions });

  if (response.body?.success) return;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to add admin group members. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function removeAdminGroupMembers(adminGroupKeys: Array<string>, adminKeys: Array<string>): Promise<void> {
  const response = await api.post('admin-group/remove-members', { adminGroupKeys, adminKeys });

  if (response.body?.success) return;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to remove admin group members. If the problem persists, please contact technical support.';
  throw new Error(error);
}


export async function updateAdminGroupMembers(adminGroupKeys: Array<string>, adminKeys: Array<string>, permissions: AdminGroupPermissions): Promise<void> {
  const response = await api.post('admin-group/update-members', { adminGroupKeys, adminKeys, permissions });

  if (response.body?.success) return;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to update admin group members. If the problem persists, please contact technical support.';
  throw new Error(error);
}

