import React from 'react';
import PropTypes from 'prop-types';

import colors from 'config/colors';
import spacing from 'config/spacing';

const logoWidth = 35;

const styles = {
  container: {
    backgroundColor: colors.backgroundColor,
    color: colors.grays.medium,
  },
  logo: {
    height: 78,
    padding: spacing.xlarge,
  },
  logoImage: {
    width: logoWidth,
    height: logoWidth,
    marginRight: spacing.base,
    verticalAlign: 'middle',
  },
  logoText: {
    fontWeight: 400,
    verticalAlign: 'middle',
    fontSize: '1em',
    fontFamily: 'Oxygen',
  },
  maintenanceText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    width: 400,
    textAlign: 'center',
  },
};

const { number } = PropTypes;

export default function MaintenanceMode(props) {
  return (
    <div style={{ ...styles.container,
      width: props.screenWidth,
      height: props.screenHeight,
    }}
    >

      <div style={styles.logo}>
        <img src={require('images/cw-logo.png')} alt="" style={styles.logoImage} />
        <span style={styles.logoText}>circular wave</span>
      </div>

      <div style={styles.maintenanceText}>
        <p style={{ fontSize: '1.5em' }}>Offline for maintenance</p>
        <p>This app is undergoing maintenance right now.</p>
        <p>Please check back later.</p>
      </div>

    </div>
  );
}

MaintenanceMode.propTypes = {
  screenWidth: number,
  screenHeight: number,
};

MaintenanceMode.defaultProps = {
  screenWidth: window.screen.width,
  screenHeight: window.screen.height,
};
