import React from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { getHumanConversationAge } from 'lib/helpers-time';
import colors from 'config/colors';
import { Conversation, SharedInboxMembership } from '../../types/Messaging';

interface ConversationListItemProps {
  conversation: Conversation;
  selected: boolean;
  onSelect: () => void;
  sharedInboxes: SharedInboxMembership[] | null
}

const ConversationListItem: React.FC<ConversationListItemProps> = (props: ConversationListItemProps) => {
  const { conversation, selected } = props;

  // Check if user is a member, or belongs to a shared inbox
  const userIsConversationMember = !!conversation.conversationMembers.find(member => member.userKey === conversation.userKey);
  const userSharedInboxKeys = (props.sharedInboxes ?? []).map(shared => shared.key);
  const fromSharedInbox = !userIsConversationMember && conversation.conversationSharedInboxes.find(shared => userSharedInboxKeys.includes(shared.sharedInboxKey));

  // For conversation header, render names of:
  //    Conversation members (excluding logged in user)
  //    Shared inboxes that user is part of if user is also an individual member of conversation
  //    Shared inboxes that user is not part of

  const sharedInboxNames = conversation.conversationSharedInboxes.filter(shared => !userSharedInboxKeys.includes(shared.sharedInboxKey) || userIsConversationMember).map(({ sharedInboxName }) => sharedInboxName);
  const conversationMemberNames = conversation.conversationMembers.filter(({ userKey }) => userKey !== conversation.userKey).map(({ userName }) => userName);
  const allMembers = [...sharedInboxNames, ...conversationMemberNames];
  const membersLength = allMembers.length;
  const title = `${allMembers[0]}${membersLength > 1 ? ` + ${membersLength - 1} more` : ''}`;

  // Check if user has seen latest message
  const seen = conversation && (conversation.latestMessageSentByKey === conversation.userKey || (conversation?.lastViewedAt && conversation.latestMessageSentAt < conversation.lastViewedAt));

  // Set timezone to user's local timezone
  const localTimezone = moment.tz.guess();
  const date = getHumanConversationAge(conversation.latestMessageSentAt, localTimezone);

  return (
    <div
      className={classNames({ messagingListItemContainer: true, messagingListItemNotSeen: !seen, messagingListItemSelected: !!selected })}
      onClick={props.onSelect}
    >
      <div className="messagingListItemTitle">
        <div>
          {fromSharedInbox && <p style={{ fontSize: '0.9rem', color: '#999', lineHeight: 1, marginBottom: 3 }}>{`Inbox: ${fromSharedInbox.sharedInboxName}`}</p>}
          {!fromSharedInbox && <p style={{ fontSize: '0.9rem', color: '#999', lineHeight: 1, marginBottom: 3 }}>Direct message</p>}
        </div>
        <p style={{ color: '#999', fontSize: '0.8rem', lineHeight: 1 }}>{date}</p>
      </div>
      <h4 style={{ fontSize: '1rem', fontWeight: !seen ? 700 : 500, color: colors.text, lineHeight: 1, marginBottom: 3 }}>{title}</h4>
      <div className="messagingListItemMessage">
        <p>{conversation.latestMessage}</p>
      </div>
    </div>
  );
};

export default ConversationListItem;
