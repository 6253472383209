import NumberFormatter from './NumberFormatter';

export default function format(value, full) {
  if (value < 1000 || full) {
    return NumberFormatter(value);
  } else if (value < 1000000) {
    return `${parseFloat(value / 1000, 10)}K`;
  } else if (value < 1000000000) {
    return `${parseFloat(value / 1000000, 10)}M`;
  }

  return `${parseFloat(value / 1000000000, 10)}B`;
}
