import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { firstBy } from 'thenby';
import './ratingsTab.scss';

export default function RatingsListItem(props) {
  const {
    siteName,
    specialityName,
    overallRating,
    submittedOn,
    comment,
    candidateRatings,
  } = props;

  const date = moment(submittedOn).format('DD/MM/YY');
  return (
    <div className="rating-list-item">
      <div className="rating-list-item-title">
        <h3 className="ratings-list-item-header">{siteName}{specialityName ? ` - ${specialityName}` : ''}</h3>
        <p>{date}</p>
      </div>
      <div className="comment">
        <div>
          <p>{comment ? `${comment}` : 'No comment'}</p>
        </div>
        <p className="rating">{overallRating}</p>
      </div>
      <div className="breakdown-container">
        {candidateRatings?.sort(firstBy('categoryKey')).map(({ title, rating }) => (<p>{title}: {rating}</p>))}
      </div>
    </div>
  );
}

const { string, arrayOf, shape, number } = PropTypes;
RatingsListItem.propTypes = {
  comment: string,
  overallRating: string.isRequired,
  siteName: string.isRequired,
  specialityName: string.isRequired,
  submittedOn: string.isRequired,
  candidateRatings: arrayOf(shape({
    categoryKey: string,
    rating: number,
    title: string,
  })).isRequired,
};

RatingsListItem.defaultProps = {
  comment: null,
};
