import React from 'react';
import { useHistory } from 'react-router';
import { MdMessage } from 'react-icons/md';

import Button from 'components/Button';

import './MessageButton.scss';

interface MessageButtonProps {
  candidateKey: string,
  small?: boolean,
  messageIconSize?: number
}

function MessageButton(props: MessageButtonProps) : React.ReactElement {

  const history = useHistory();

  if (props.small) {
    return (
      <div
        role="button"
        tabIndex={0}
        className="small-message-button"
        onClick={(e) => {
          e.stopPropagation(); history.push(`/messaging/inbox/new?to=${props.candidateKey}`);
        }}
      >
        <MdMessage size={props.messageIconSize} />
      </div>
    );
  }

  return (
    <Button
      white
      outline
      shadow={false}
      onClick={(e) => {
        e.stopPropagation(); history.push(`/messaging/inbox/new?to=${props.candidateKey}`);
      }}
      // style={{ fontSize: '0.9rem' }}
    >
      Message
    </Button>
  );
}

MessageButton.defaultProps = { small: false, messageIconSize: 26 };

export default MessageButton;
