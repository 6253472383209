import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';
import { keyBy } from 'lodash-es';

import { convertToSelectArray, convertToSelect } from 'lib/helpers';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface SiteObject {
  [key: string] : Site
}

interface Site {
  key: string,
  name: string,
  abbr: string,
  orgKey: string,
  timezone: string,
  orgName: string,
  canCreateShifts: boolean,
  canViewShifts: boolean,
  canManageShifts: boolean,
  enabled: boolean,
}

interface SiteFieldProps {
  site: { id: string, name: string },
  serviceKey: string | null
  onChange: (val: { value: string, label: string }) => void,
  disabled?: boolean
  mandatory?: boolean
}

function SiteField(props: SiteFieldProps) {

  const sites = useSelector(({ user }) => user.sites) as SiteObject;
  const services = useSelector(({ global }) => global.services);

  // Filter sites by service sites
  // If no serviceKey, default to all admin sites
  const siteOptions = useMemo(() => {
    const servicesKeyed = keyBy(services, 'key');
    const serviceSites = props.serviceKey ? (servicesKeyed[props.serviceKey]?.sites ?? null) : null;

    if (serviceSites) {
      return serviceSites.map(convertToSelect).sort(firstBy('label'));
    }

    return convertToSelectArray(sites, 'name', true);

  }, [props.serviceKey, services, sites]);

  return (
    <FieldLabel label="Site">
      <JobsSelectBox
        name="site"
        value={props.site}
        placeholder="Site *"
        data={siteOptions}
        onChange={props.onChange}
        mandatory={props.mandatory}
        isDisabled={props.disabled}
      />
    </FieldLabel>
  );
}

SiteField.defaultProps = { disabled: false, mandatory: false };

export default React.memo(SiteField);
