const TICKET_FETCH = 'TICKET_FETCH';
const TICKET_FETCH_SUCCESS = 'TICKET_FETCH_SUCCESS';
const TICKET_FETCH_ERROR = 'TICKET_FETCH_ERROR';
const TICKET_FETCH_NOT_FOUND = 'TICKET_FETCH_NOT_FOUND';
const TICKET_RESET_STATE = 'TICKET_RESET_STATE';
const TICKET_ADD_REJECTED_FIELD = 'TICKET_ADD_REJECTED_FIELD';
const TICKET_ADD_REJECTED_REASON = 'TICKET_ADD_REJECTED_REASON';
const TICKET_ADD_CUSTOM_REASON = 'TICKET_ADD_CUSTOM_REASON';

export function fetch() {
  return {
    type: TICKET_FETCH,
  };
}

export function success(complianceCategory, candidateKey, displayName, reasons, metadata, candidateData, warnings) {
  return {
    type: TICKET_FETCH_SUCCESS,
    complianceCategory,
    candidateKey,
    displayName,
    reasons,
    metadata,
    candidateData,
    warnings,
  };
}

export const fetchTicketError = error => ({ type: TICKET_FETCH_ERROR, error });

export function notFound() {
  return {
    type: TICKET_FETCH_NOT_FOUND,
  };
}

export function reset() {
  return {
    type: TICKET_RESET_STATE,
  };
}

export function addRejectedField(field) {
  return {
    type: TICKET_ADD_REJECTED_FIELD,
    field,
  };
}

export function addRejectedReason(reason) {
  return {
    type: TICKET_ADD_REJECTED_REASON,
    reason,
  };
}

export function addCustomReason(reason) {
  return {
    type: TICKET_ADD_CUSTOM_REASON,
    reason,
  };
}

const initialState = {
  isFetching: false,
};

export default function tickets(state = initialState, action) {
  switch (action.type) {
    case TICKET_FETCH:
      return {
        isFetching: true,
      };

    case TICKET_FETCH_SUCCESS:
      return {
        isFetching: false,
        complianceCategory: action.complianceCategory,
        candidateKey: action.candidateKey,
        reasons: action.reasons,
        metadata: action.metadata,
        candidateData: action.candidateData,
        warnings: action.warnings,
        displayName: action.displayName,
      };

    case TICKET_FETCH_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case TICKET_FETCH_NOT_FOUND:
      return {
        isFetching: false,
        notFound: true,
      };
    case TICKET_RESET_STATE:
      return {
        ...initialState,
      };

    case TICKET_ADD_REJECTED_FIELD:
      return {
        ...state,
        rejectedField: action.field,
      };

    case TICKET_ADD_REJECTED_REASON:
      return {
        ...state,
        rejectedReason: action.reason,
      };

    case TICKET_ADD_CUSTOM_REASON:
      return {
        ...state,
        customReason: action.reason,
      };
    default:
      return state;
  }
}
