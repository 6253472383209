import React, { useEffect, useState, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import colors from 'config/colors';
import fonts from 'config/fonts';
import spacing from 'config/spacing';

import Button from 'components/Button';
import SectionedList from 'components/SectionedList';
import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox/Checkbox';
import Loading from 'components/Loading/Loading';
import InfoBanner from 'components/InfoBanner';

import { PublishedShiftSummary } from 'types/ShiftTypes';
import { Supplier } from 'types/Supplier';

import { startWatchingSuppliers, stopWatchingSuppliers } from 'thunks/suppliers';

import './manage-banks-agencies.scss';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  modalContent: {
    width: '40%',
  },
  buttonContainer: {
    borderTop: '1px solid #EAEAEA',
    padding: spacing.base,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
  },
  agenciesContainer: {
    // padding: spacing.base,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 300,
    maxHeight: 300,
    marginTop: 24,
  },
  agencyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',
  },
  headerStyles: {
    backgroundColor: colors.white,
    color: colors.text,
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  companyName: {
    color: '#333',
  },
  centre: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  explainerText: {
    color: colors.text,
    lineHeight: 1.4,
    fontSize: fonts.textMain.size,
  },
  errorText: { paddingTop: spacing.small, color: colors.red, fontSize: 14, lineHeight: 1.4 },
};

interface AgencyReleaseModalProps {
  shifts: Array<PublishedShiftSummary>,
  selectedDraftShiftCount?: number,
  isLoading: boolean,
  apiError: string | null,
  onClose: () => void,
  releaseToAgency: (supplierKeys: Array<string>) => void,
}

function AgencyReleaseModal(props: AgencyReleaseModalProps): React.ReactElement {

  const dispatch = useDispatch();

  const [selectedSuppliers, setSelectedSuppliers] = useState<{ [key: string]: boolean }>({});

  const currentOrgKey = useSelector(state => state.global.currentOrgKey);
  const isFetchingSuppliers = useSelector(state => state.suppliers.watchingSuppliers);
  const suppliers : Array<Supplier> = useSelector(state => state.suppliers.suppliersData);

  useEffect(() => {

    dispatch(startWatchingSuppliers(currentOrgKey));

    // Unsubscribe when unmounting component
    return () => {
      dispatch(stopWatchingSuppliers());
    };
  }, []);

  const releaseToAgency = () => {
    const supplierKeys = Object.entries(selectedSuppliers).filter(([, checked]) => checked).map(([supplierKey]) => supplierKey);
    props.releaseToAgency(supplierKeys);
  };

  const selectAllOfTier = (tier: string) => {
    const selected : { [key: string]: boolean } = {};
    Object.entries(suppliers).forEach(([key, supplier]) => {
      if (supplier.tier === tier) selected[key] = true;
    });
    setSelectedSuppliers(prevState => ({ ...prevState, ...selected }));
  };

  const renderSectionHeader = (sectionHeaderText: string) => {
    const arraySec = sectionHeaderText.split(' ');
    return (
      <div style={styles.headerContainer}>
        <p style={styles.headerStyles}>{sectionHeaderText}</p>
        <Button outline small white shadow={false} style={{ flex: '0 0 auto' }} onClick={() => selectAllOfTier(arraySec[1])}>
          Select all
        </Button>
      </div>
    );
  };

  const renderRow = (rowData: Supplier) => {

    const supplierKey = rowData.supplierKey;

    if (!supplierKey) return null;
    let alreadyReleased : boolean | null = false;

    // Check if some shifts have been released to this agency (intermediate check)
    if (props.shifts.some(shift => shift.suppliers?.[supplierKey])) alreadyReleased = null;
    // Check if all shifts have been released to this agency
    if (props.shifts.every(shift => shift.suppliers?.[supplierKey])) alreadyReleased = true;

    const checked = selectedSuppliers[supplierKey] !== undefined ? selectedSuppliers[supplierKey] : alreadyReleased;

    // TODO: Make each list collapsable and a select all/deslect all
    if (rowData.alwaysSelect) return null;

    return (
      <div key={supplierKey} style={styles.agencyContainer}>
        <p style={styles.companyName}>{rowData.companyName}</p>
        <Checkbox
          checked={checked}
          disabled={alreadyReleased}
          onChange={(value) => setSelectedSuppliers(prevState => ({ ...prevState, [supplierKey]: value }))}
        />
      </div>
    );
  };

  if (isFetchingSuppliers) {
    <Modal
      isOpen
      header="Release to Agency"
      onRequestClose={props.onClose}
      vflex
    >
      <Loading />
    </Modal>;
  }

  // Disabled release button if loading or no agencies in local state are checked
  const releaseButtonDisabled = props.isLoading || Object.values(selectedSuppliers).every(checked => !checked);

  return (
    <Modal
      isOpen
      header="Release to Agency"
      onRequestClose={props.onClose}
      contentStyle={styles.modalContent}
    >
      <div className="manage-modal__content-container">
      {!!props.selectedDraftShiftCount && (
        <InfoBanner warning message={`You have selected ${props.selectedDraftShiftCount} draft ${props.selectedDraftShiftCount === 1 ? 'shift' : 'shifts'}. ${props.selectedDraftShiftCount === 1 ? 'This shift' : 'These shifts'} will not be released as only published shifts can be released.`} />
      )}
      <p style={styles.explainerText}>{`You have selected ${props.shifts.length} shift${props.shifts.length !== 1 ? 's' : ''}. Please select which agencies to release to:`}</p>
      <div style={styles.agenciesContainer}>
        <SectionedList
          data={suppliers ?? {}}
          getSectionHeaderText={(value: string) => `Tier ${value}`}
          renderRow={renderRow}
          renderSectionHeader={renderSectionHeader}
          sectionSortProperty="tier"
          sectionSortOrder="ascending"
          rowSortProperty="companyName"
          rowSortOrder="ascending"
        />
      </div>

      {props.apiError && <p style={styles.errorText}>{props.apiError}</p>}
      </div>

      <div className="space-children-12" style={styles.buttonContainer}>
        {props.isLoading && <Loading size={36} />}
        <Button large white outline onClick={props.onClose} disabled={props.isLoading}>
          Cancel
        </Button>
        <Button large onClick={releaseToAgency} disabled={releaseButtonDisabled}>
          Release
        </Button>
      </div>
    </Modal>
  );
}

export default AgencyReleaseModal;
