import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import colors from 'config/colors';
import './BreadCrumbsHeader.scss';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  header: {
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '1.5rem',
    fontWeight: 600,
    color: colors.text,
  },
};

interface BreadCrumbsHeaderProps {
  breadCrumbs: { label: string, linkTo?: string }[],
  style: CSSProperties
}

const BreadCrumbsHeader : React.FC<BreadCrumbsHeaderProps> = (props: BreadCrumbsHeaderProps) => {
  return (
    <h1 style={{ ...styles.header, ...props.style }}>
      {props.breadCrumbs.map((crumb, index) => {
        return (
          <span key={crumb.label}>
            {index === 0 ? null : ' > '}
            {crumb.linkTo ? <Link style={{ ...styles.header, ...props.style }} className="breadcrumbs-header__crumb" to={crumb.linkTo}>{crumb.label}</Link> : crumb.label}
          </span>
        );
      })}
    </h1>
  );
};

export default BreadCrumbsHeader;
