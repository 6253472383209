import React, { useState, CSSProperties } from 'react';
import TextAreaAutosize from 'react-autosize-textarea';
import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import Button from 'components/Button';
import fonts from 'config/fonts';
import spacing from 'config/spacing';
import colors from 'config/colors';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  content: {
    width: 400,
    textAlign: 'center',
    lineHeight: 2,
  },
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    margin: spacing.base,
  },
  modalContent: {
    overflow: 'visible',
  },
  textAreaStyles: {
    backgroundColor: 'transparent',
    border: '1px solid #d9d9d9',
    borderRadius: 2,
    padding: 0,
    resize: 'none',
    overflowX: 'scroll',
    overflowY: 'hidden',
    outline: 'none',
    minHeight: 50,
    width: '100%',
    fontSize: '16px',
    color: colors.grays.medium,
  },
};

interface SubmissionRejectModalProps {
  modalOpen: boolean,
  rejectSubmission: (reason: string) => void,
  onClose: () => void,
}

export const SubmissionRejectModal : React.FC<SubmissionRejectModalProps> = (props : SubmissionRejectModalProps) => {
  const [rejectReason, setRejectReason] = useState('');

  const rejectSubmission = () => {
    props.rejectSubmission(rejectReason);
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.modalOpen}
      onRequestClose={props.onClose}
    >
      <CloseButton handleClose={props.onClose} top={15} right={15} />
      <div style={styles.content}>
        <h1 style={styles.header}>
          Reason For Rejection
        </h1>
        <TextAreaAutosize
          style={styles.textAreaStyles}
          value={rejectReason}
          onChange={e => setRejectReason((e?.target as HTMLTextAreaElement)?.value)}
        />
        <Button disabled={!rejectReason} onClick={() => rejectSubmission()} large style={{ backgroundColor: colors.red, marginTop: spacing.base }}>Reject</Button>
      </div>
    </Modal>
  );
};
