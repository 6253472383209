import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdOutlineAccountCircle } from 'react-icons/md';
import * as userThunks from 'thunks/user';
import colors from 'config/colors';

import './styles.scss';

const styles = {
  container: {
    flex: '0 0 auto',
    position: 'relative',
  },
};

class UserBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMenuOpen: false,
    };

    this.setPopoverWrapperRef = this.setPopoverWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setAccountCircleWrapperRef = this.setAccountCircleWrapperRef.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setPopoverWrapperRef(node) {
    this.popoverWrapperRef = node;
  }

  setAccountCircleWrapperRef(node) {
    this.accountCircleWrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.popoverWrapperRef && !this.popoverWrapperRef.contains(event.target) && this.accountCircleWrapperRef && !this.accountCircleWrapperRef.contains(event.target)) {
      if (this.state.userMenuOpen) {
        this.closeUserMenu();
      }
    }
  }

  toggleUserMenuOpen = () => {
    this.setState({
      userMenuOpen: !this.state.userMenuOpen,
    });
  }

  closeUserMenu = () => {
    this.setState({
      userMenuOpen: false,
    });
  }

  /* eslint jsx-a11y/anchor-has-content:"off" */
  render() {
    return (
      <div ref={this.setAccountCircleWrapperRef} style={styles.container} className="">
        <a className="sideMenuItem" onClick={this.toggleUserMenuOpen} style={{ textAlign: 'center' }}>
          <MdOutlineAccountCircle
            style={{ color: colors.cavalry.primary, fontSize: 26, cursor: 'pointer', paddingRight: 18 }}
            id="test-id-user-bar-btn"
            className="nav-icon"
          />
          <div style={{ textAlign: 'center' }}>Account</div>
        </a>
        <div ref={this.setPopoverWrapperRef} className={this.state.userMenuOpen ? 'popover active' : 'popover'}>
          <div className="popoverPointer" />
          <div className="popoverInner">
            <div className="userDeets">
              <div className="email">{this.props.userEmail}</div>
            </div>
            <div className="links">
              <a className="link" id="test-id-user-bar-logout-link" onClick={this.props.logout}>Logout</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { string, func, bool } = PropTypes;
UserBar.propTypes = {
  userEmail: string.isRequired,
  logout: func.isRequired,
  isOpen: bool,
};

UserBar.defaultProps = {
  isOpen: true,
};

function mapStateToProps({ user }) {
  return {
    userEmail: user.userData ? user.userData.email : '',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userThunks.logout(true)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBar);
