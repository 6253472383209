import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import ErrorModal from 'components/ErrorModal';
import NoReportData from 'components/NoReportData';
import Trend from './Trend';
import colors from '../../../config/colors';
import { capitalize } from '../../../lib/helpers';

import { reports } from './index'; // eslint-disable-line import/no-cycle
import './styles.scss';

const barColors = {
  bank: colors.cavalry.bankFilled,
  agency: colors.cavalry.agencyFilled,
  unfilled: colors.accent,
};

const tooltipFormatter = (value, name, props) => {
  return `${props.payload.percentage.toFixed()}%`;
};

function hasAnyData(data) {
  let hasData = false;
  if (data && data.length && data.length > 0) {
    data.forEach((item) => {
      if (item.value) {
        hasData = true;
      }
    });
  }
  return hasData;
}

const getTotalFillRate = (report) => {
  return report ? Math.round(report.reduce((acc, curr) => {
    // Accumulate both bank and agency filled percentage rates for total
    if (curr.name !== 'Unfilled') return curr.percentage + acc;
    return 0 + acc;
  }, 0)) : 0;
};

const generatePayloadForChart = ({ report, labels }) => {
  if (!report) return null;
  const reportValues = Object.values(report.result)[0];
  return labels.map((origin) => {
    const reportValue = reportValues[origin];
    return { name: capitalize(origin), value: reportValue, percentage: Math.round(((reportValue / reportValues.total) * 100)) };
  });
};

export default function FillRateReport(props) {

  // Find report config
  const reportConfig = reports.find(report => report.name === 'fillRate');

  // Create formatted payload for chart
  const reportPayloadForChart = generatePayloadForChart({ report: props.report, labels: reportConfig.labels, durationBetweenDates: props.durationBetweenDates });

  // Calculate report total
  const reportTotal = getTotalFillRate(reportPayloadForChart);

  return (
    <>
      {props.reportError && (
        <ErrorModal header="Fill Rate Report Error" errorMessage={props.reportError} onCancel={() => props.clearReportingError('fillRate')} />
      )}
      <div style={{ width: props.width }} className=" test-id-reports-fill">
        {!props.loading && hasAnyData(reportPayloadForChart) ?
          <div className="chartWrapper">
            {props.report.delta ?
              <Trend
                positive="up"
                delta={props.report.delta}
                deltaPeriod={props.report.deltaPeriod}
                deltaPeriodUnit={props.report.deltaPeriodUnit}
              /> : null}
            <h3 style={{ color: colors.text, fontSize: '1.2rem', textTransform: 'none', fontWeight: 600 }}>Shift Fill Rate</h3>
            <p className="report-explanation">Percentage of shifts filled by bank and agency</p>
            <h4 className="report-total">{`Total Fill Rate: ${reportTotal}%`}</h4>
            <ResponsiveContainer width="99%" height={300}>
              <PieChart onClick={props.onClickChart}>
                <Legend verticalAlign="top" />
                <Tooltip wrapperStyle={{ width: 'fit-content', height: 'fit-content' }} formatter={tooltipFormatter} />
                <Pie
                  data={reportPayloadForChart}
                  dataKey="value"
                  nameKey="name"
                  innerRadius={50}
                  startAngle={-90}
                >
                  {reportConfig.labels.map((entry, index) => <Cell key={index.toString()} fill={barColors[entry]} />)}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div> : <NoReportData title="Fill Rate" />}
      </div>
    </>
  );
}


const { array, shape, bool, number, func, string } = PropTypes;

FillRateReport.propTypes = {
  report: shape({
    result: array.isRequired,
    siteFilter: string,
    roleFilter: string,
    groupBy: string,
  }),
  loading: bool.isRequired,
  reportError: string,
  clearReportingError: func.isRequired,
  durationBetweenDates: number.isRequired,
  onClickChart: func,
  width: string,
};

FillRateReport.defaultProps = {
  report: null,
  onClickChart: () => {},
  width: '100%',
  reportError: '',
};
