/* eslint-disable no-else-return */
import { Moment } from 'moment';

const TWELVE_HOURS = 12 * 60;

function determineAMorPM(hours: number, minutes: number, isPM: boolean, isAM: boolean, nextAfter?: Moment) {
  const newHours = hours === 24 ? 0 : hours;

  // If hours are specified in 24 hour time then do not adjust them
  if (newHours > 12) return { hours: newHours, minutes };

  // If one of AM or PM are specified (but not both), then use that half of the day
  if ((isAM || isPM) && !(isAM && isPM)) {
    return { hours: (newHours % 12) + (isPM ? 12 : 0), minutes };
  }

  // If there is no nextAfter time, then default to AM unless PM is specified
  if (!nextAfter) {
    return { hours: newHours, minutes };
  }

  // If there is a nextAfter time, then find the first time after the nextAfter time
  const totalMinutes = (hours * 60) + minutes;
  const nextAfterTotalMinutes = (nextAfter.hours() * 60) + nextAfter.minutes();

  if (
    (totalMinutes > nextAfterTotalMinutes && totalMinutes - nextAfterTotalMinutes <= TWELVE_HOURS) ||
    (totalMinutes < nextAfterTotalMinutes && nextAfterTotalMinutes - totalMinutes >= TWELVE_HOURS)
  ) {
    return { hours: newHours, minutes };
  } else {
    return { hours: (newHours + 12) % 24, minutes };
  }

}

export interface ParsedTime {
  hours: number,
  minutes: number,
}

export function parseTime(input: string, nextAfter?: Moment) : ParsedTime | null {
  const isPM = /pm/i.test(input);
  const isAM = /am/i.test(input);
  const digits = input.replace(/\D/g, '').substr(0, 4);

  switch (digits.length) {

    // Assume h or hh format
    case 1:
    case 2: {
      const hours = parseInt(digits, 10);
      if (hours > 24) return null;
      return determineAMorPM(hours, 0, isPM, isAM, nextAfter);
    }
    case 3: {

      // First try h:mm format
      const minutes = parseInt(digits.substr(1), 10);
      if (minutes < 59) {
        const hours = parseInt(digits[0], 10);
        return determineAMorPM(hours, minutes, isPM, isAM, nextAfter);
      }

      // If this fails then try hh:m format
      const hours = parseInt(digits.substr(0, 2), 10);
      if (hours > 24) return null;
      return determineAMorPM(hours, parseInt(digits[2], 10), isPM, isAM, nextAfter);
    }

    // Assume hh:mm format
    case 4: {

      const hours = parseInt(digits.substr(0, 2), 10);
      const minutes = parseInt(digits.substr(2, 2), 10);
      if (hours > 24 || minutes > 59) return null;

      return determineAMorPM(hours, minutes, isPM, isAM, nextAfter);
    }

    default:
      return null; // Can't happen
  }
}
