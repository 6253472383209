import colors from '../../config/colors';

const DAYS_FOR_OVERDUE = 3;

const EVENTS = {
  APPROVE: 'approve',
  CREATE: 'create',
  MESSAGE: 'message',
  REJECT: 'reject',
  SUBMIT: 'submit',
};

const EVENT_COLORS = {
  approve: colors.green,
  reject: colors.red,
  submit: colors.positive,
};

const STATUSES = {
  APPROVED: 'approved',
  CREATED: 'created',
  PENDING_APPROVAL: 'pending-approval',
  REJECTED: 'rejected',
  PAID: 'paid',
};


const STATUS_CONFIGS = {
  approved: {
    bgColor: colors.green,
    text: 'approved',
  },
  default: {
    bgColor: null,
    text: null,
  },
  due: {
    bgColor: colors.understated,
    text: 'please submit',
  },
  overdue: {
    bgColor: colors.warning,
    text: 'overdue',
  },
  pendingApproval: {
    bgColor: colors.blues.light,
    text: 'pending approval',
  },
  rejected: {
    bgColor: colors.red,
    text: 'rejected',
  },
};

const COLLECTION_BEHAVIOURS = {
  START_AND_END_TIMES: 'start-and-end-times',
  TOTAL_TIMES: 'total-times',
};
//
const POLICIES = {
  LATE_FINISHES: 'lateFinishes',
  EARLY_STARTS: 'earlyStarts',
  TIMESHEET_ROUNDING: 'timesheetRounding',
  BREAKS: 'breaks',
};

export {
  DAYS_FOR_OVERDUE,
  EVENTS,
  EVENT_COLORS,
  STATUSES,
  STATUS_CONFIGS,
  COLLECTION_BEHAVIOURS,
  POLICIES,
};
