import React from 'react';
import { useSelector } from 'react-redux';
import { MdInsertChartOutlined, MdOutlineAccessTime, MdOutlineCalendarToday, MdPeopleOutline, MdOutlineHelpOutline, MdOutlineSettings, MdOutlineChatBubbleOutline } from 'react-icons/md';
import { isFeatureOn } from 'lib/features';
import { featureFlags } from 'config/featureFlags';
import { permissions } from 'config/permissions';

// GoCalendar

function getMenuItems(state) {
  const { user, global } = state;
  const customTerminology = state.global.orgConfig?.customTerminology;

  function routeIsEnabled(route) {
    if (!route.featureFlag && !route.permissionRequired) return true;
    if (Array.isArray(route.permissionRequired)) {
      return route.permissionRequired.some((permission) => isFeatureOn(route.featureFlag, permission, user, global));
    }
    return isFeatureOn(route.featureFlag, route.permissionRequired, user, global);
  }

  if (isFeatureOn(featureFlags.SUPPLIER, null, user, global)) {

    const supplierRoutes = [
      {
        title: 'Shifts',
        url: '/shifts',
        featureFlag: featureFlags.SUPPLIER,
        id: 'side-menu-link-supplier-jobs',
      },
    ];

    return supplierRoutes.filter((route) => routeIsEnabled(route));
  }

  const routes = [
    {
      title: 'Shifts',
      url: '/shifts',
      activeUrl: '/shifts',
      featureFlag: featureFlags.JOBS,
      icon: <MdOutlineCalendarToday size={25} className="nav-icon" />,
      id: 'side-menu-link-jobs',
    },
    {
      title: 'Time & Pay',
      url: '/time-and-pay/timesheets',
      featureFlag: featureFlags.TIMESHEETS_ADMIN,
      permissionRequired: [permissions.TIMESHEETS_CAN_VIEW, permissions.PAY_RUN_CAN_VIEW],
      activeUrl: '/time-and-pay',
      id: 'side-menu-link-pay',
      icon: <MdOutlineAccessTime size={25} className="nav-icon" />,
    },
    {
      title: customTerminology?.bank ?? 'Bank',
      url: '/bank/all',
      activeUrl: '/bank',
      permissionRequired: permissions.BANK_CAN_VIEW,
      id: 'side-menu-link-manage-bank',
      icon: <MdPeopleOutline size={25} className="nav-icon" />,
    },
    {
      title: 'Reporting',
      url: '/reporting/dashboard',
      featureFlag: featureFlags.REPORTING,
      permissionRequired: permissions.REPORTING_CAN_VIEW,
      activeUrl: '/reporting',
      id: 'side-menu-link-reporting',
      icon: <MdInsertChartOutlined size={25} className="nav-icon" />,
    },
  ];

  const settingsRoutes = [
    {
      title: 'Admins',
      url: '/settings/admins',
      permissionRequired: permissions.USERS_CAN_VIEW,
      id: 'side-menu-link-settings-users',
    },
    {
      title: 'Suppliers',
      url: '/settings/suppliers',
      activeUrl: '/settings/suppliers',
      permissionRequired: permissions.VMS_CAN_MANAGE,
      id: 'side-menu-link-settings-suppliers',
    },
    {
      title: 'Sites',
      url: '/settings/sites',
      activeUrl: '/settings/sites',
      id: 'side-menu-link-settings-sites',
      featureFlag: featureFlags.DETAILS,
    },
    {
      title: 'RGS',
      url: '/settings/rgs',
      activeUrl: '/settings/rgs',
      id: 'side-menu-link-settings-rgs',
      featureFlag: featureFlags.DETAILS,
    },
  ].filter((route) => routeIsEnabled(route));

  routes.push({
    title: 'Messaging',
    url: '/messaging/inbox',
    activeUrl: '/messaging',
    featureFlag: featureFlags.MESSAGING,
    id: 'side-menu-link-messaging',
    icon: <MdOutlineChatBubbleOutline size={25} className="nav-icon" />,
    badgeCount: state.messaging?.unreadConversationsCount,
  });

  // TODO - reinstate help page once content has been updated
  // routes.push(
  //   {
  //     title: 'Help',
  //     url: '/help',
  //     id: 'side-menu-help',
  //     featureFlag: featureFlags.HELP_CENTRE,
  //     icon: <MdOutlineHelpOutline size={25} className="nav-icon" />,
  //   },
  // );

  if (settingsRoutes.length) {
    routes.push({
      title: 'Settings',
      url: settingsRoutes[0].url,
      // routes: settingsRoutes,
      activeUrl: '/settings',
      id: 'side-menu-link-settings',
      icon: <MdOutlineSettings size={25} className="nav-icon" />,
    });
  }

  return routes.filter((route) => routeIsEnabled(route));
}

function useMenuItems() {
  const state = useSelector(s => s);
  return getMenuItems(state);
}

export {
  getMenuItems,
  useMenuItems,
};
