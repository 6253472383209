import React from 'react';
import { firstBy } from 'thenby';
import AuditTrail from 'components/AuditTrail';
import { PaymentRunAuditType } from '../../../types/PaymentRun';

interface AuditTabProps {
  auditTrail: PaymentRunAuditType[],
  orgTimezone: string,
  userId: string,
}

const AuditTab : React.FC<AuditTabProps> = (props : AuditTabProps) => {

  return (
    <div style={{ flex: 1, overflowY: 'scroll' }}>
      {props.auditTrail.length ?
        <AuditTrail
          orgTimezone={props.orgTimezone}
          items={props.auditTrail.sort(firstBy('createdAt')).map((message) : { title: string | null, message: string, when: string } => {
            return {
              title: props.userId === message.fromUserKey ? 'You' : message.displayName,
              message: message.message,
              when: message.createdAt,
            };
          })}
        />
        : null
      }
    </div>
  );
};

export default AuditTab;
