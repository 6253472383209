import moment from 'moment';

const REPORTING_CHANGE_DATE = 'REPORTING_CHANGE_DATE';
const REPORTING_REPORT_LOADED = 'REPORTING_REPORT_LOADED';
const REPORTING_SET_SELECTED_ROLE = 'REPORTING_SET_SELECTED_ROLE';
const REPORTING_SET_SELECTED_SITE = 'REPORTING_SET_SELECTED_SITE';
const REPORTING_SET_SELECTED_SPECIALITY = 'REPORTING_SET_SELECTED_SPECIALITY';
const REPORTING_REPORT_LOADING = 'REPORTING_REPORT_LOADING';
const REPORT_FETCHING_ERROR = 'REPORT_FETCHING_ERROR';
const CLEAR_REPORTING_ERROR = 'CLEAR_REPORTING_ERROR';

const getDefaultRange = () => ({ from: moment().subtract(30, 'days').startOf('day').toISOString(), to: moment().endOf('day').toISOString() });

export const setSelectedRole = selectedRole => ({ type: REPORTING_SET_SELECTED_ROLE, selectedRole });

export const setSelectedSite = selectedSite => ({ type: REPORTING_SET_SELECTED_SITE, selectedSite });

export const setSelectedSpeciality = selectedSpeciality => ({ type: REPORTING_SET_SELECTED_SPECIALITY, selectedSpeciality });

export const changeDate = (selectedRange, durationBetweenDates, reportsLoading) => ({ type: REPORTING_CHANGE_DATE, selectedRange, durationBetweenDates, reportsLoading });

export const reportLoading = report => ({ report, type: REPORTING_REPORT_LOADING });

export const reportLoaded = (report, data) => ({ type: REPORTING_REPORT_LOADED, report, data });

export const reportFetchingError = (report, errorMessage) => ({ type: REPORT_FETCHING_ERROR, report, errorMessage });

export const clearReportingError = reportName => ({ type: CLEAR_REPORTING_ERROR, reportName });

const initialState = {
  loading: {
    fillRate: false,
    fillRateByRole: false,
    fillRateBySpeciality: false,
    requests: false,
    requestsByRole: false,
    requestsBySpeciality: false,
    spend: false,
    spendByRole: false,
    spendBySpeciality: false,
    activity: false,
  },
  selectedRange: getDefaultRange(),
  durationBetweenDates: 0,
  reports: {},
  selectedRole: {},
  selectedSite: {},
  selectedSpeciality: {},
  reportError: {
    fillRate: '',
    fillRateByRole: '',
    fillRateBySpeciality: '',
    requests: '',
    requestsByRole: '',
    requestsBySpeciality: '',
    spend: '',
    spendByRole: '',
    spendBySpeciality: '',
    activity: '',
  },
};

export default function reporting(state = initialState, action) {
  switch (action.type) {
    case REPORTING_CHANGE_DATE: {
      return {
        ...state,
        selectedRange: action.selectedRange || state.selectedRange,
        durationBetweenDates: action.durationBetweenDates,
      };
    }

    case REPORTING_REPORT_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.report]: true,
        },
      };
    }

    case REPORTING_REPORT_LOADED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.report]: false,
        },
        reports: {
          ...state.reports,
          [action.report]: action.data,
        },
      };

    case REPORT_FETCHING_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.report]: false,
        },
        reportError: {
          ...state.reportError,
          [action.report]: action.errorMessage,
        },
      };

    case CLEAR_REPORTING_ERROR:
      return {
        ...state,
        reportError: {
          ...state.reportError,
          [action.reportName]: '',
        },
      };

    case REPORTING_SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.selectedRole,
      };

    case REPORTING_SET_SELECTED_SITE:
      return {
        ...state,
        selectedSite: action.selectedSite,
      };

    case REPORTING_SET_SELECTED_SPECIALITY:
      return {
        ...state,
        selectedSpeciality: action.selectedSpeciality,
      };

    default:
      return state;
  }
}
