import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import PasswordExpiryContainer from '../Auth/PasswordExpiryContainer';

const ProtectedRoute = ({ component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={(routeProps) => {
      if (!rest.isLoggedIn) {
        return <Redirect to={{ pathname: '/login', state: { redirectUrl: routeProps.location } }} />;
      } else if (rest.passwordExpiredOrExpiringSoon) {
        return <PasswordExpiryContainer />;
      } else if (Component) {
        return <Component {...routeProps} />;
      } else if (render) {
        return render(routeProps);
      }
      return null;
    }}
  />
);

ProtectedRoute.propTypes = {
  component: propTypes.elementType,
  render: propTypes.func,
};

ProtectedRoute.defaultProps = {
  component: null,
  render: null,
};

function mapStateToProps({ user, global }) {
  const passwordExpiry = global.passwordExpiry;
  return {
    isLoggedIn: !!user.userId,
    passwordExpiry,
    passwordExpiredOrExpiringSoon: passwordExpiry?.passwordExpired || (passwordExpiry?.passwordExpiringSoon && !passwordExpiry?.hasSkippedReset),
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
