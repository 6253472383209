import { convertFromUtcToTimezone } from '../../lib/helpers-time';

const APPLICATION = 'application';
const TIMESHEET = 'timesheet';
const CANCELLATION = 'cancellation';
const STATUS_CHANGE = 'status-change';
const STATUS_CHANGE_WITHDRAWL = 'status-change-withdrawl';


function urlMapper(data) {
  switch (data.type) {
    case APPLICATION:
    case CANCELLATION: {
      const { jobId, jobStartTime, timezone } = data;
      const date = convertFromUtcToTimezone(jobStartTime ?? '2021-01', timezone || 'Europe/London').format('YYYY/MM/DD');
      return `/shifts/${date}/${jobId}/details`;
    }
    case STATUS_CHANGE:
      return `/bank/all/candidate/${data.candidate.id}/compliance`;
    case STATUS_CHANGE_WITHDRAWL: {
      return `/bank/all/candidate/${data.candidate.id}`;
    }
    case TIMESHEET:
      // TODO: open specific timesheet
      return `/pay/timesheets/pending/ts=${data.timesheetKey}`;
    default:
      return '';
  }
}

export {
  urlMapper,
};
