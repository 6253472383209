import React from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import TextSectionHeader from '../../components/TextSectionHeader';

const styles = {
  formContainer: {
    overflowY: 'scroll',
    boxSizing: 'border-box',
    height: '90%',
    width: '90%',
    margin: '0 auto',
  },
  pane: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    paddingLeft: spacing.tiny,
    paddingRight: spacing.tiny,
  },
  paneLeft: {
    width: '50%',
  },
  paneRight: {
    width: '50%',
  },
  labelContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    height: spacing.xxxlarge,
    width: '30%',
  },
  label: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: spacing.small,
    color: colors.grays.light,
  },
  valueContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    height: spacing.xxxlarge,
    width: '70%',
  },
  value: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: spacing.small,
    color: colors.text,
    fontSize: '0.9rem',
  },
  logoContainer: {
    display: 'inline-block',
    height: '100%',
    width: '70%',
    boxSizing: 'border-box',
    padding: spacing.tiny,
    verticalAlign: 'top',
    textAlign: 'center',
  },
  logo: {
    height: 150,
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
};

export default function SupplierBasicInformation(props) {
  const { supplierData } = props;
  const logoStyles = {
    ...styles.logo,
    backgroundImage: `url(${supplierData.logoUrl})`,
  };
  return (
    <div style={styles.formContainer}>
      <TextSectionHeader text="Basic Information" />
      <div style={{ ...styles.pane, ...styles.paneLeft }}>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Company Name</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.companyName}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Address 1</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.address1 || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Address 2</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.address2 || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>City</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.city || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>County</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.county || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Postcode</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.postcode || ''}</div>
          </div>
        </div>

      </div>
      <div style={{ ...styles.pane, ...styles.paneRight }}>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Phone</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.mainTelephone || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Tier</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.tier}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Company Number</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.companyNumber || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>VAT Number</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.value}>{supplierData.vatNumber || ''}</div>
          </div>
        </div>

        <div>
          <div style={styles.labelContainer}>
            <div style={styles.label}>Logo</div>
          </div>
          <div style={styles.logoContainer}>
            {
              supplierData.logoUrl ?
                <div style={logoStyles} /> :
                ''
            }
          </div>
        </div>

      </div>
    </div>
  );
}

const { object } = PropTypes;

SupplierBasicInformation.propTypes = {
  supplierData: object,
};

SupplierBasicInformation.defaultProps = {
  supplierData: {},
};
