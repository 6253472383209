import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { MdError, MdLocationOn } from 'react-icons/md';
import { convertToSelect } from 'lib/helpers';
import { browserOnBlur } from '../../../lib/browser-type';
import { customFilter } from '../../../lib/helpers-select';
import colors from '../../../config/colors';
import spacing from '../../../config/spacing';
import './TimesAndAreas.scss';
import '../../../index.scss';

const CustomOption = (oProps) => {

  const labelColor = oProps.isSelected ? colors.white : colors.text;
  const warningColor = oProps.isSelected ? colors.white : colors.warning;
  const subLabelColor = oProps.isSelected ? colors.white : '#999';

  return (
    <components.Option {...oProps}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p style={{ lineHeight: 1.4, color: labelColor }}>{oProps.data.label}</p>
        {oProps.data.warning && (
          <div
            style={{ marginRight: spacing.tiny }}
            data-tip="This user has a professional registration status of warning"
          >
            <MdError
              color={warningColor}
              size={20}
            />
            <ReactTooltip />
          </div>
        )}
      </div>
      {/* {!!oProps.data.hours !== undefined && <p style={{ lineHeight: 1.4, color: subLabelColor, paddingLeft: 1 }}>{oProps.data.hours}</p>} */}
      {!!oProps.data.subLabel && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: -3 }}>
          <div style={{ marginBottom: 3 }}>
            <MdLocationOn
              color={subLabelColor}
              size={16}
            />
          </div>
          <p style={{ lineHeight: 1.4, color: subLabelColor, paddingLeft: 1 }}>{oProps.data.subLabel}</p>
        </div>
      )}
    </components.Option>
  );
};

const CustomSingle = (oProps) => {
  if (oProps.data && oProps.data.warning) {
    return (
      <div style={{ width: '95%' }}>
        <components.SingleValue {...oProps} />
        <div style={{ float: 'right' }} data-tip="This user has a professional regitration status of warning">
          <MdError color={colors.warning} size={20} />
          <ReactTooltip />
        </div>
      </div>
    );
  }

  return <components.SingleValue {...oProps} />;
};

// Filter config setting for select input
const filterConfig = {
  matchFrom: 'any',
};

export default function JobsSelectBox(props) {

  const heightAndFontSize = {
    height: 30,
    minHeight: 30,
    fontSize: '0.9rem',
    boxSizing: 'content-box',
  };

  const customStyles = {
    control: (base, state) => {
      const { hasValue, isFocused } = state;
      const styles = {
        ...base,
        ...heightAndFontSize,
        fontSize: '0.9rem',
        border: state.isFocused ? `1px solid ${colors.cavalry.primary}` : `1px solid ${colors.cavalry.line}`,
        boxShadow: 'none',
        borderRadius: 4,
        ':hover': {
          border: state.isFocused ? `1px solid ${colors.cavalry.primary}` : `1px solid ${colors.cavalry.line}`,
        },
      };

      // styles.border = isFocused ? `1px solid ${colors.cavalry.primary}` : `1px solid ${colors.cavalry.line}`;

      if (props.mandatory) {
        styles.border = hasValue ? (base.border ?? null) : `1px solid ${colors.cavalry.error}`;
        styles.color = colors.cavalry.error;
      }

      styles.boxShadow = 'none';
      return styles;
    },
    option: (base, { data, isSelected }) => {
      return {
        ...base,
        fontSize: '0.9rem',
        height: 36,
        display: 'flex',
        alignItems: 'center',
        ...data.optionStyles,
        color: isSelected ? 'white !important' : colors.text,
        backgroundColor: isSelected ? colors.cavalry.primary : colors.white,
        ':hover': {
          backgroundColor: isSelected ? colors.cavalry.primary : colors.cavalry.backgroundFocussed,
        },
      };
    },
    valueContainer: base => ({ ...base, heightAndFontSize, padding: '0 5px', paddingTop: 0, paddingBottom: 0 }),
    dropdownIndicator: base => ({ ...base, padding: '0 8px' }),
    placeholder: base => ({ ...base, color: colors.text }),
    clearIndicator: base => ({ ...base, padding: '0 8px' }),
    menuPortal: base => ({ ...base, zIndex: 1000000 }),
    singleValue: provided => ({
      ...provided,
      color: colors.text,
    }),
  };

  const theme = base => ({
    ...base,
    borderRadius: 2,
    colors: {
      ...base.colors,
      // primary25: '#DEEBFD',
      // primary50: 'rgba(0, 94, 134, 0.5)',
      primary: colors.cavalry.primary,
    },
  });

  // Allow passing the selected value by key/id rather than the whole option object
  const valueByKey = props.data && props.data.find(opt => opt.value === props.value);
  const value = valueByKey ?? props.value;

  return (
    <Select
      name={props.name}
      placeholder={props.placeholder}
      value={convertToSelect(value)} // defaultValue in react-select is only for initial load. To change the value from state, use value.
      options={props.data}
      onChange={val => props.onChange(val)}
      isClearable={props.clearable}
      className={props.className ? props.className : null}
      autoFocus={props.autofocus ? props.autofocus : false}
      classNamePrefix={`${props.customClassPrefix}react-select`}
      styles={props.styles || customStyles}
      theme={theme}
      components={{ Option: CustomOption, SingleValue: CustomSingle }}
      onBlur={browserOnBlur()}
      filterOption={customFilter(filterConfig)}
      isLoading={props.loading}
      isDisabled={props.isDisabled}
      menuPlacement={props.menuPlacement || 'auto'}
      menuPosition="fixed"
      menuPortalTarget={document.body}
      portalPlacement="bottom"
    />
  );

}

const { string, func, arrayOf, object, bool, objectOf, oneOfType, number } = PropTypes;
JobsSelectBox.propTypes = {
  name: string.isRequired,
  value: object,
  placeholder: string.isRequired,
  data: arrayOf(object),
  onChange: func.isRequired,
  clearable: bool,
  className: string,
  autofocus: bool,
  customClassPrefix: string,
  loading: bool,
  mandatory: bool,
  isDisabled: bool,
  styles: objectOf(func),
  menuPlacement: string,
};

JobsSelectBox.defaultProps = {
  value: null,
  data: [],
  clearable: false,
  className: null,
  autofocus: false,
  customClassPrefix: '',
  loading: false,
  mandatory: false,
  isDisabled: false,
  styles: null,
  menuPlacement: '',
};
