import { BASE_URL } from '../../config/settings';
import { getToken } from '../auth';

let store;
function init(newStore) { store = newStore; }

let reportError = (error, opts) => console.error(error, opts);
function setErrorHandler(errorHandler) { reportError = errorHandler; }

let logout = () => ({});
function setAuthErrorHandler(authErrorHandler) { logout = authErrorHandler; }

let setPasswordExpiry = () => ({});
function setPasswordExpiryHandler(passwordExpiryHandler) { setPasswordExpiry = passwordExpiryHandler; }

// If response includes an attachment, the content-disposition header follows the pattern "attachment;filename=XYZ.csv". Need to extract filename=XYZ.csv
function extractFilenameFromContentDispostionHeader(string) {
  // If first parameter === attachment, then extract filename. Remove ; at end of filename if it occurs
  const fileName = string?.split('filename=')?.[1].split(';')[0];

  // Replace any quotes from filename string
  const fileNameWithQuotesRemoved = fileName?.replaceAll('"', '');

  return fileNameWithQuotesRemoved ?? null;
}

async function send(methodInAnyCase, path, payload) {
  const start = Date.now();
  try {
    const token = await getToken();
    const state = store && store.getState();
    const orgKey = state.global?.currentOrgKey;

    const method = methodInAnyCase.toUpperCase();
    const headers = {
      'Content-Type': 'application/json',
      'x-cayman-client-name': 'website',
      'x-cayman-auth-mode': 'adminOrSupplier',
    };
    if (token) headers.Authorization = `Bearer: ${token}`;
    if (orgKey) headers['x-cayman-org-key'] = orgKey;

    let uri = `${BASE_URL}/api/${path}`;
    const options = { method, headers };
    if (payload) {
      if (method === 'GET' || method === 'HEAD') {
        const params = [];
        Object.entries(payload).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach(v => v !== undefined && params.push(`${key}[]=${encodeURIComponent(v)}`));
          } else if (value !== undefined) {
            params.push(`${key}=${encodeURIComponent(value)}`);
          }
        });
        if (params.length > 0) uri += `?${params.join('&')}`;
      } else {
        options.body = JSON.stringify(payload);
      }
    }

    const response = await fetch(uri, options);

    const duration = Date.now() - start;
    console.debug(`${method} ${path} took ${duration} ms`);

    // Get mimeType from content-type header
    const contentType = response.headers.get('content-type')?.toLowerCase();
    const mimeType = contentType?.split(';')[0];

    // Get filename from content-disposition header
    const contentDisposition = response.headers.get('content-disposition');
    const filename = extractFilenameFromContentDispostionHeader(contentDisposition);

    // Parse response body depending on content-type
    let body;
    if (!contentType) body = await response.text();
    else if (contentType.includes('json')) body = await response.clone().json().catch(() => response.text());
    else if (contentType.startsWith('text')) body = await response.text();
    else body = await response.blob();

    if (response.status === 401) {
      if (body?.details?.passwordExpired) {
        if (store && setPasswordExpiry) store.dispatch(setPasswordExpiry({ passwordExpired: true, passwordExpiresAt: body?.details?.passwordExpiresAt, email: body?.details?.email }));
      } else {
        if (store && logout) store.dispatch(logout());
      }
    }

    return { status: response.status, body, full: response, mimeType, filename };
  } catch (e) {
    console.log('Network error:', e);
    reportError(e, {
      metaData: {
        context: {
          message: 'Api send failed',
        },
      },
    });
    return { status: 500, error: e };
  }
}

const get = (path, payload) => send('GET', path, payload);
const post = (path, payload) => send('POST', path, payload);

const Api = class {

  constructor(basePath) {
    this.basePath = basePath;
  }

  send = (method, path, payload) => send(method, `${this.basePath}/${path}`, payload);

  get = (path, payload) => get(`${this.basePath}/${path}`, payload);

  post = (path, payload) => post(`${this.basePath}/${path}`, payload);
};

export {
  init,
  setErrorHandler,
  setAuthErrorHandler,
  setPasswordExpiryHandler,

  send,
  get,
  post,

  Api,
};
