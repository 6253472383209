import React, { useState } from 'react';
import { MdZoomIn } from 'react-icons/md';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import colors from 'config/colors';
import downloadFile from 'lib/helpers-download-file';

import { downloadAllCertificateFiles } from 'lib/api/compliance';

import { Submission, SubmissionCheck, DetailGroupDetail, SubmissionField, SubmissionFormFieldType, SubmissionFieldType } from 'types/ComplianceTypes';

import PdfView from 'components/PdfView';
import Button from 'components/Button';
import Loading from 'components/Loading';
import FullSizeImageModal from './FullSizeImageModal';
import './ComplianceTab.scss';

interface SubmissionDetailsProps {
  submission: Submission,
  setValidFromDate: (date: string) => void,
  setExpiresAt: (date: string) => void,
}

export default function SubmissionDetails(props: SubmissionDetailsProps): React.ReactElement {

  const { submissionKey, validFrom, expiresAt, detailsGroups, checks, multipleFileSummaryDetails, needsReview, adminCanApprove, title } = props.submission;

  const [selectedImageFile, setSelectedImageFile] = useState<SubmissionField | null>(null);
  const [selectedPdfFile, setSelectedPdfFile] = useState<SubmissionField | null>(null);
  const [allFilesAreDownloading, setAllFilesAreDownloading] = useState(false);
  const [allFilesDownloadError, setAllFilesDownloadError] = useState<string | null>(null);

  const summaryDetailsDocuments = multipleFileSummaryDetails.filter(detail => detail.type === 'image' || detail.type === 'pdf');
  const summaryDetailsTextContent = multipleFileSummaryDetails.filter(detail => detail.type === 'text');
  const showDownloadAllButton = summaryDetailsDocuments.length > 1 && summaryDetailsDocuments.every(doc => doc.status === 'uploaded');

  const onClickFile = (detail: SubmissionField) => {
    if (detail.type === 'image') setSelectedImageFile(detail);
    if (detail.type === 'pdf') setSelectedPdfFile(detail);
  };

  const downloadAllFiles = async () => {
    try {
      if (allFilesDownloadError) setAllFilesDownloadError(null);
      setAllFilesAreDownloading(true);

      const response = await downloadAllCertificateFiles(submissionKey);
      downloadFile(response.filename, response.mimeType, response.body);

    } catch (error) {
      if (error instanceof Error) setAllFilesDownloadError(error.message);
    }
    setAllFilesAreDownloading(false);
  };

  return (
    <div className="compliance-submission">

      <div className="compliance-submission-section">
        <h4 className="compliance-submission-section__header">Check Status</h4>
        {checks.map(check => <Check key={check.label} check={check} />)}
      </div>

      <div className="compliance-submission-section">
        <h4 className="compliance-submission-section__header">Submission</h4>
        <span style={{ display: 'flex' }}>
          {summaryDetailsDocuments.length > 0 && <p className="certificate-submission__label">Document{summaryDetailsDocuments.length > 1 ? 's' : ''}</p>}
          <span style={{ display: 'flex', flexWrap: 'wrap' }} className="space-children-12">
            {summaryDetailsDocuments.map(detail => (
              <SubmissionImage
                key={detail.label}
                label={detail.label}
                value={detail.value}
                onClickImage={() => { if (detail.fullSizeValue) onClickFile(detail); }}
                canMagnify={!!detail.fullSizeValue}
              />
            ))}
          </span>
        </span>
        {showDownloadAllButton && (
          <div style={{ display: 'flex', paddingTop: '6px', paddingBottom: '12px' }}>
            <div style={{ flex: '0 0 36%' }} />
            <Button
              onClick={() => downloadAllFiles()}
              small
              black
              shadow={false}
              disabled={allFilesAreDownloading}
            >
              Download all files
            </Button>
            {allFilesAreDownloading && <Loading size={24} />}
            {allFilesDownloadError && (
              <ErrorMessage>
                <p className="submission-header-bottom__permission-message" style={{ marginLeft: 6, fontSize: 14 }}>{allFilesDownloadError}</p>
              </ErrorMessage>
            )}
          </div>
        )}

        {summaryDetailsTextContent.map((detail) => {

          const editableField = detail.editableByAdmin && needsReview && adminCanApprove;
          let value;
          let setValue;
          if (detail.key === 'validFrom' && validFrom && editableField) {
            value = validFrom;
            setValue = props.setValidFromDate;
          } else if (detail.key === 'expiresAt' && expiresAt && editableField) {
            value = expiresAt;
            setValue = props.setExpiresAt;
          } else {
            value = detail.value;
          }

          return (
            <InlineItem
              key={detail.label}
              label={detail.label}
              value={value}
              formFieldType={detail.formFieldType}
              editableField={editableField}
              setValue={setValue}
            />
          );
        })}
      </div>

      {detailsGroups.map((group) => {
        return (
          <div key={group.label} className="compliance-submission-section">
            <h4 className="compliance-submission-section__header">{group.label}</h4>
            <div>
              {group.details.map(detail => <Detail key={detail.label} detail={detail} />)}
            </div>
          </div>
        );
      })}

      {selectedImageFile && (
        <FullSizeImageModal
          image={selectedImageFile.fullSizeValue}
          fileKey={selectedImageFile.fileKey}
          submissionKey={submissionKey}
          onRequestClose={() => setSelectedImageFile(null)}
        />
      )}

      {selectedPdfFile && (
        <PdfView
          source={selectedPdfFile.fullSizeValue}
          name={title}
          onRequestClose={() => setSelectedPdfFile(null)}
        />
      )}
    </div>
  );
}

interface CheckProps {
  check: SubmissionCheck,
}

function Check({ check }: CheckProps): React.ReactElement {
  return (
    <div className="certificate-field">
      <p className="certificate-submission__label">{check.label}</p>
      <div>
        <p className="certificate-submission__value" style={{ color: check.title.color, fontWeight: 500, lineHeight: 1.4 }}>{check.title.text}</p>
        {check.message && (
          <p className="certificate-submission__value" style={{ color: colors.lightText, lineHeight: 1, fontSize: '0.8rem' }}>{check.message.text}</p>
        )}
      </div>
    </div>
  );
}

interface DetailProps {
  detail: DetailGroupDetail | SubmissionField,
}

function Detail(props: DetailProps): React.ReactElement | null {
  const { label, value, type } = props.detail;

  if (type === 'text' && typeof value === 'string') {
    return <InlineItem label={label} value={value} />;
  }

  if (type === 'group' && Array.isArray(value)) {
    return (
      <div style={{ marginTop: 6 }}>
        <h4 className="compliance-submission-section__header">{label}</h4>
        {value.map((val: DetailGroupDetail) => (
          typeof val.value === 'string' ? <InlineItem key={val.label} label={val.label} value={val.value} /> : null
        ))}
      </div>
    );
  }

  return null;
}

interface InlineItemProps {
  label: string,
  value: string,
  editableField?: boolean,
  formFieldType?: SubmissionFormFieldType,
  setValue?: (date: string) => void,
}

function InlineItem(props: InlineItemProps): React.ReactElement {

  const [datePickerIsFocussed, toggleDatePickerFocus] = useState(false as boolean);

  return (
    <div className="certificate-field">
      <p className="certificate-submission__label">{props.label}</p>
      {props.editableField && props.formFieldType === 'date' ?
        <div className="createShiftDatePicker" style={{ marginBottom: 6, flexBasis: '36%', flexGrow: 0 }}>
          <SingleDatePicker
            displayFormat="ll"
            date={moment(props.value)}
            onDateChange={(date) => {
              if (props.setValue) props.setValue(moment(date).toISOString());
            }}
            focused={datePickerIsFocussed}
            onFocusChange={({ focused } : { focused: boolean }) => toggleDatePickerFocus(focused)}
            openDirection="up"
            numberOfMonths={1}
            firstDayOfWeek={1}
            showDefaultInputIcon
            id="id"
            inputIconPosition="after"
            isOutsideRange={() => false}
          />
        </div>
        : <p className="certificate-submission__value">{props.value}</p>
      }
    </div>
  );
}

InlineItem.defaultProps = {
  editableField: false,
  formFieldType: undefined,
  setValue: undefined,
};

interface SubmissionImageProps {
  label: string
  value: string,
  canMagnify: boolean,
  onClickImage: () => void
}

function SubmissionImage(props: SubmissionImageProps): React.ReactElement {
  return (
    <div className="certificate-field" style={{ marginBottom: 6 }}>
      <div
        role="button"
        tabIndex={0}
        className="submission-evidence"
        style={{ cursor: props.canMagnify ? 'pointer' : 'default' }}
        onClick={props.onClickImage}
      >
        <img
          className="submission-image"
          src={props.value}
          alt="Submission Evidence"
          style={{ maxWidth: '120px', maxHeight: '120px' }}
        />
        {props.canMagnify && (
          <div className="submission-magnify">
            <MdZoomIn size={36} color={colors.white} />
          </div>
        )}
      </div>
    </div>
  );
}

function ErrorMessage(props: { children: React.ReactNode }): React.ReactElement {
  return (
    <div className="submission-header-bottom">
      {props.children}
    </div>
  );
}
