import React from 'react';
import PropTypes from 'prop-types';

import './OptionList.scss';

const { string, func, bool, arrayOf, shape } = PropTypes;

const OptionList = (props) => {
  const { items } = props;
  return (
    <div className="optionList">
      {items.map(item => (
        <div
          key={item.key}
          className={`item ${item.selected ? 'selected' : ''}`}
          onClick={() => props.onItemClick(item.key)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

OptionList.propTypes = {
  items: arrayOf(shape({
    key: string,
    selected: bool,
    name: string,
  })).isRequired,
  onItemClick: func.isRequired,
};

export default OptionList;
