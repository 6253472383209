/* THIS FILE IS DUPLICATED IN THE SDK. PLEASE UPDATE THERE TOO IF YOU UPDATE THIS FILE. */

export const permissions = {
  BANK_CAN_INVITE: 'bankCanInvite',
  BANK_CAN_SUSPEND: 'bankCanSuspend',
  BANK_CAN_VIEW: 'bankCanView',
  BANK_CAN_VIEW_REMOVED: 'bankCanViewRemoved',
  BANK_CAN_REMOVE: 'bankCanRemove',
  BANK_CAN_EDIT_ORG_SPECIFIC_METADATA: 'bankCanEditOrgSpecificMetadata',
  BANK_CAN_EDIT_RGS: 'bankCaneditRGS',
  BANK_CAN_RELEASE: 'bankCanRelease',
  BANK_CAN_RETRACT: 'bankCanRetract',
  BANK_CAN_EDIT_BASIC_DETAILS: 'bankCanEditBasicDetails',
  COMPLIANCE_CAN_APPROVE_AND_REJECT: 'complianceCanApproveAndReject',
  COMPLIANCE_CAN_APPROVE: 'complianceCanApprove',
  COMPLIANCE_CAN_REJECT: 'complianceCanReject',
  COMPLIANCE_CAN_VIEW_DETAILS: 'complianceCanViewDetails',
  JOBS_CAN_BOOK: 'jobsCanBook',
  JOBS_CAN_CREATE: 'jobsCanCreate',
  JOBS_CAN_DELETE_JOBS: 'jobsCanDeleteJobs',
  JOBS_CAN_EDIT_SLOTS: 'jobsCanEditSlots',
  ORG_CAN_EDIT_DETAILS: 'orgCanEditDetails',
  ORG_CAN_EDIT_POLICIES: 'orgCanEditPolicies',
  ORG_CAN_VIEW_POLICIES: 'orgCanViewPolicies',
  PAY_RUN_CAN_MANAGE: 'payRunCanManage',
  PAY_RUN_CAN_VIEW: 'payRunCanView',
  RATE_CARD_CAN_OVERRIDE: 'rateCardCanOverride',
  REPORTING_CAN_VIEW: 'reportingCanView',
  SERVICES_CAN_MANAGE: 'servicesCanManage',
  SITES_CAN_CREATE: 'sitesCanCreate',
  SITES_CAN_EDIT_DETAILS: 'sitesCanEditDetails',
  SITES_CAN_EDIT_POLICIES: 'sitesCanEditPolicies',
  SITES_CAN_VIEW_POLICIES: 'sitesCanViewPolicies',
  TIMESHEETS_CAN_APPROVE: 'timesheetsCanApprove',
  TIMESHEETS_CAN_REJECT: 'timesheetsCanReject',
  TIMESHEETS_CAN_VIEW: 'timesheetsCanView',
  TIMESHEETS_CAN_MANAGE_LOCKED_TIMESHEETS: 'timesheetsCanManageLockedTimesheets',
  USERS_CAN_EDIT_PERMISSIONS: 'usersCanEditPermissions',
  USERS_CAN_EDIT_SITE_PERMISSIONS: 'usersCanEditSitePermissions',
  USERS_CAN_MANAGE_HIDDEN_PERMISSIONS: 'usersCanManageHiddenPermissions',
  USERS_CAN_INVITE: 'usersCanInvite',
  USERS_CAN_VIEW: 'usersCanView',
  VMS_CAN_APPROVE_AGENCY_REQUESTS: 'vmsCanApproveAgencyRequests',
  VMS_CAN_BOOK_AGENCY_CANDIDATE: 'vmsCanBookAgencyCandidate',
  VMS_CAN_MANAGE: 'vmsCanManage',
  VMS_CAN_SEND_AGENCY_REQUESTS: 'vmsCanSendAgencyRequests',
  VMS_CAN_VIEW_AGENCY_REQUESTS: 'vmsCanViewAgencyRequests',
  VMS_CAN_SUBMIT_AGENCY_CANDIDATES: 'vmsCanSubmitAgencyCandidates',
  ADMINS_CAN_MANAGE: 'adminsCanManage',
  ABSENCE_CAN_VIEW: 'absenceCanView',
  ABSENCE_CAN_MANAGE: 'absenceCanManage',
};

export const permissionDetails : { [key: string]: { name: string, default: boolean, isHidden: boolean } } = {
  bankCanInvite: { name: 'Bank:  Invite', default: false, isHidden: false },
  bankCanSuspend: { name: 'Bank: Suspend', default: false, isHidden: false },
  bankCanView: { name: 'Bank: View', default: true, isHidden: false },
  bankCanViewRemoved: { name: 'Bank: View Removed', default: false, isHidden: false },
  bankCanRemove: { name: 'Bank: Can Remove Candidates', default: false, isHidden: false },
  bankCaneditRGS: { name: 'Bank: Edit Grades and Specialities', default: false, isHidden: false },
  bankCanEditOrgSpecificMetadata: { name: 'Bank: Edit Custom Candidate Fields', default: false, isHidden: false },
  bankCanRelease: { name: 'Bank: Can Release to External Staff Banks', default: false, isHidden: false },
  bankCanRetract: { name: 'Bank: Can Retract External Staff Bank Requests', default: false, isHidden: false },
  bankCanEditBasicDetails: { name: 'Bank: Can Edit Basic Details', default: false, isHidden: true },
  complianceCanApproveAndReject: { name: 'Compliance: Approve and Reject', default: false, isHidden: false },
  complianceCanApprove: { name: 'Compliance: Approve', default: false, isHidden: false },
  complianceCanReject: { name: 'Compliance: Reject', default: false, isHidden: false },
  complianceCanViewDetails: { name: 'Compliance: View', default: true, isHidden: false },
  jobsCanBook: { name: 'Jobs: Book', default: false, isHidden: false },
  jobsCanCreate: { name: 'Jobs: Create', default: false, isHidden: false },
  jobsCanDeleteJobs: { name: 'Jobs: Delete', default: false, isHidden: false },
  jobsCanEditSlots: { name: 'Jobs: Edit', default: false, isHidden: false },
  orgCanEditDetails: { name: 'Organisation: Edit Details', default: false, isHidden: false },
  orgCanEditPolicies: { name: 'Organisation: Edit Policies', default: false, isHidden: false },
  orgCanViewPolicies: { name: 'Organisation: View Policies', default: true, isHidden: false },
  payRunCanView: { name: 'Payment Runs: View', default: false, isHidden: false },
  payRunCanManage: { name: 'Payment Runs: Manage', default: false, isHidden: false },
  rateCardCanOverride: { name: 'Rate Card: Can Override', default: false, isHidden: false },
  reportingCanView: { name: 'Reporting: View', default: false, isHidden: false },
  servicesCanManage: { name: 'Services: Manage', default: false, isHidden: false },
  sitesCanCreate: { name: 'Sites: Create', default: false, isHidden: false },
  sitesCanEditDetails: { name: 'Sites: Edit Details', default: false, isHidden: false },
  sitesCanEditPolicies: { name: 'Sites: Edit Policies', default: false, isHidden: false },
  sitesCanViewPolicies: { name: 'Sites: View Policies', default: true, isHidden: false },
  timesheetsCanApprove: { name: 'Timesheets: Approve', default: false, isHidden: false },
  timesheetsCanReject: { name: 'Timesheets: Reject', default: false, isHidden: false },
  timesheetsCanView: { name: 'Timesheets: View', default: false, isHidden: false },
  timesheetsCanManageLockedTimesheets: { name: 'Timesheets: Can Manage Locked Timesheets', default: false, isHidden: false },
  usersCanEditPermissions: { name: 'Admins: Edit Permissions', default: false, isHidden: false },
  usersCanEditSitePermissions: { name: 'Admins: Edit Site Permissions', default: false, isHidden: false },
  usersCanInvite: { name: 'Admins: Invite', default: false, isHidden: false },
  usersCanManageHiddenPermissions: { name: 'Admins: Can Manage Hidden Permissions', default: false, isHidden: true },
  usersCanView: { name: 'Admins: View', default: false, isHidden: false },
  vmsCanApproveAgencyRequests: { name: 'VMS: Approve Requests', default: false, isHidden: false },
  vmsCanBookAgencyCandidate: { name: 'VMS: Book Agency Candidate', default: false, isHidden: false },
  vmsCanManage: { name: 'VMS: Manage Suppliers', default: false, isHidden: false },
  vmsCanSendAgencyRequests: { name: 'VMS: Send Requests', default: false, isHidden: false },
  vmsCanViewAgencyRequests: { name: 'VMS: View Requests', default: true, isHidden: false },
  vmsCanSubmitAgencyCandidates: { name: 'VMS: Can Submit Candidates', default: false, isHidden: false },
  adminsCanManage: { name: 'Admins: Manage', default: false, isHidden: false },
  absenceCanView: { name: 'Absence: View', default: false, isHidden: false },
  absenceCanManage: { name: 'Absence: Manage', default: false, isHidden: false },
};

export type Permission = keyof typeof permissionDetails;

export function getDefaultPermissions() {
  const defaultPermissions : { [key in Permission]: boolean } = {};
  Object.entries(permissionDetails).forEach(([key, permission]) => {
    if (!permission.isHidden) defaultPermissions[key] = permission.default;
  });
  return defaultPermissions;
}

export function getDefaultGeneralPermissions() {
  const defaultPermissions : { key: string, name: string, isEnabled: boolean }[] = [];
  Object.entries(permissionDetails).forEach(([key, permission]) => {
    if (!permission.isHidden) {
      defaultPermissions.push({ 
        key,
        name: permission.name,
        isEnabled: permission.default,
      });
    }
  });
  return defaultPermissions;
}

export default {
  permissions,
  permissionDetails,
};
