import { firstBy } from 'thenby';
import colors from 'config/colors';
import * as api from '.';
import { reportError } from '../error-reporting';

export function requestAgency(jobId, orgKey) {
  return api.post('jobs/agency-request', { jobId, orgKey });
}

export function approveAgency(jobId) {
  return api.post('jobs/agency-approve', { jobId });
}

function reportAgencyReleaseError(response, shiftKeys, supplierKeys) {

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to release to agency.');
  reportError(error, {
    api: {
      path: response?.full?.path ?? 'jobs/agency-release',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stacktrace ?? null,
    },
    payload: { shiftKeys, supplierKeys },
  });
  throw new Error(response?.body.humanReadableErrorMessage ?? 'Unable to release shift(s) to agency. If the problem persists, please contact technical support.');
}

export async function releaseToAgency(shiftKey, supplierKeys) {

  const response = await api.post('jobs/agency-release', { shiftKeys: [shiftKey], supplierKeys });

  if (response?.body.success && response.body.updatedShiftFromSql) return response.body;

  return reportAgencyReleaseError(response, [shiftKey], supplierKeys);
}

export async function batchReleaseToAgency(shiftKeys, supplierKeys) {

  const response = await api.post('jobs/agency-release', { shiftKeys, supplierKeys });

  if (response?.body.success && Array.isArray(response.body.releasedShifts) && Array.isArray(response.body.failedShifts)) {
    return response.body;
  }

  return reportAgencyReleaseError(response, shiftKeys, supplierKeys);
}

export function retractAgencyRequest(jobId) {
  return api.post('jobs/agency-retract', { jobId });
}

export function overrideRateCard(jobId, rateCard, values, newJob = false) {
  return api.post('jobs/overrideRateCard', { jobId, rateCard, values, newJob });
}

export function submitSupplierCandidate(jobId, supplierKey, data, book) {
  const candidateData = { ...data, name: data.firstName && data.surname ? `${data.firstName} ${data.surname}` : data.ref };
  return api.post('jobs/submit-supplier-candidate', { jobId, supplierKey, book, candidateData });
}

export async function fetchShiftDetail(shiftKey, candidateKey = null) {
  const payload = { shiftKey };
  if (candidateKey) payload.candidateKey = candidateKey;
  const result = await api.get('jobs/shift-detail/', payload);

  if (result.status >= 400) throw new Error(result.body.error);

  return result.body;
}

export async function fetchShiftAuditTrail(shiftKey) {
  const result = await api.get('jobs/audit-trail', { shiftKey });

  if (result.status >= 400) throw new Error(result.body.error);

  return result.body;
}

export async function updateBanksAndAgencies(payload) {
  const result = await api.post('jobs/update-banks-and-agencies', payload);

  if (result.status >= 400) throw new Error(result.body.error);

  return result.body;
}

export async function updateShift(shiftKey, fields) {
  const response = await api.post('jobs/edit-shift', { shiftKey, fields });

  if (response.status >= 400) {

    if (response.status === 409 && response.body.clashes && response.body.clashingShifts) {
      return response.body;
    }

    const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to update shift.');
    reportError(error, {
      api: {
        path: response?.full?.path ?? 'jobs/edit-shift',
        status: response.status,
        error,
        details: response?.body?.details ?? null,
        stacktrace: response?.body?.stacktrace ?? null,
      },
      payload: { shiftKey, fields },
    });
    throw new Error(response?.body.humanReadableErrorMessage ?? 'Unable to update shift. Please try again. If the problem persists, please contact technical support.');
  }

  return response.body;
}

export const searchForShift = shiftNumber => api.get('jobs/search', { shiftNumber });

export const deleteShifts = async (shiftKeys) => {
  const response = await api.post('jobs/cancel', { shiftKeys });

  if (response.status >= 400) {
    const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to delete shifts.');
    reportError(error, {
      api: {
        path: response?.full?.path ?? 'jobs/cancel',
        status: response.status,
        error,
        details: response?.body?.details ?? null,
        stacktrace: response?.body?.stacktrace ?? null,
      },
      payload: { shiftKeys },
    });
    throw new Error(response?.body.humanReadableErrorMessage ?? 'Unable to delete published shifts. If the problem persists, please contact technical support.');
  }
  return response.body;
};

export async function fetchApplicableCandidates({ startTime, endTime, siteKey, roleKey, specialityKey, gradeKey, serviceKey }) {

  const response = await api.post('jobs/candidates/search', {
    startTime,
    endTime,
    siteKey,
    roleKey,
    gradeKey,
    specialityKey,
    serviceKey,
  });

  return response?.body?.candidates ?? [];
}

function createApiPayloadFromDraft(draftKey, shift) {

  // Map from old-style ratecards to new-style ratecards in case there are old drafts
  let timesheetTypeKey = shift.rateCard?.timesheetTypeKey;
  if (!timesheetTypeKey && shift.timesheetSetting?.id) {
    const onSiteOffSiteRatecardIds = ['0O5snNmIW5sT8Ij0lN46', 'PRg5lCWQF7hJykT0AkBG', 'W5ORSlCHv8utEpBcfTq7', 'gWIvs0bwXiBGPhD0P1lK', 'wWGRsyKa6fDhre7msmub', 'wh46ZlOKHZD8kMDQWsdY'];
    timesheetTypeKey = onSiteOffSiteRatecardIds.includes(shift.timesheetSetting?.id) ? 'on-site-off-site' : 'start-end';
  }

  return {
    draftKey,
    viewKey: shift.viewKey,
    roleKey: shift?.role?.id ?? null,
    gradeKey: shift?.grade?.id ?? null,
    specialityKey: shift?.speciality?.id ?? null,
    siteKey: shift.site.id,
    areaKey: shift.area?.id,
    startTime: shift.startTime,
    endTime: shift.endTime,
    reasonKey: shift.reason?.id,
    subReasonKey: shift.reason2?.id,
    rateCardKey: shift?.rateCard?.key ?? null,
    costCentreKey: shift.costCentre?.id,
    preferredGenderKey: shift.preferredGender?.id,
    publicDescription: shift.publicDescription,
    privateNotes: shift.privateNotes,
    bankKeys: shift.bankKeys,
    serviceKey: shift.service?.id ?? null,
    customHourlyRate: shift?.rateCard?.requireCustomHourlyRate && shift.customHourlyRate ? shift.customHourlyRate : null,
    slotsRequired: shift.slotsRequired,
    targetedCandidateKeys: shift.candidates.map(candidate => candidate.id),
  };
}

export async function createShifts(draftShifts) {

  const shifts = Object.entries(draftShifts).map(([draftKey, draftShift]) => createApiPayloadFromDraft(draftKey, draftShift));

  const response = await api.post('jobs/create-shift', { shifts });

  if (response?.body?.success && response?.body?.shiftSummaries) return response.body;
  if (response?.body?.details?.validatedShifts && response?.body?.details?.failedShifts && response?.body?.details?.humanReadableErrorMessage) return response.body.details;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to create shifts(s).');
  reportError(error, {
    api: {
      path: 'jobs/create-shift',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
      payload: { shifts },
    },
  });

  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to create shifts.');
}

export async function rejectApplicant(payload) {

  const response = await api.post('applications/reject', payload);

  if (response?.body?.success && response?.body?.shiftAppsAndBookingsFromSql) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to reject application.');
  reportError(error, {
    api: {
      path: 'applications/reject',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
      payload,
    },
  });

  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to reject application.');
}
