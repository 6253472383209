import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import colors from 'config/colors';
import { fetchCandidateRatings, fetchMoreCandidateRatings } from 'thunks/ratings';
import { clearCandidateRatingsData, updateRatingsOffset } from 'reducers/ratings';
import Loading from 'components/Loading';
import RatingDisplayStars from 'components/RatingDisplayStars';
import ScrollView from 'components/ScrollView';
import RatingsListItem from './RatingsListItem';
import './ratingsTab.scss';

function parseRating(rating) {
  return parseFloat(rating, 10).toFixed(1);
}

const { string, func, bool, arrayOf, shape, number } = PropTypes;
class RatingsTab extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    candidateKey: string.isRequired,
    loading: bool.isRequired,
    loadingMore: bool.isRequired,
    summary: arrayOf(shape({
      title: string,
      description: string,
      rating: string,
      categoryKey: string,
    })),
    detail: arrayOf(shape({
      comment: string,
      gradeName: string,
      overallRating: string,
      shiftKey: string,
      siteName: string,
      specialityName: string,
      submittedBy: string,
      submittedOn: string,
    })),
    overallRating: string,
    totalCount: number,
  }

  static defaultProps = {
    summary: null,
    detail: null,
    overallRating: null,
    totalCount: 0,
  }

  componentDidMount() {
    this.props.dispatch(fetchCandidateRatings(this.props.candidateKey));
  }

  componentWillUnmount() {
    this.props.dispatch(clearCandidateRatingsData());
  }

  render() {
    if (this.props.loading) {
      return (
        <Loading flex />
      );
    }

    if ((!this.props.detail || !this.props.detail.length) || (!this.props.summary || !this.props.summary.length)) {
      return (
        <div className="ratings-tab-container">
          <p style={{ color: colors.text }}>There are currently no ratings for this candidate.</p>
        </div>
      );
    }

    return (
      <div className="ratings-tab-container">
        <div className="rating-summary-container">
          <h3 className="ratings-section-header">Overall</h3>

          {this.props.summary?.map(({ rating, description, title, categoryKey }) => {
            const roundedRating = parseRating(rating);
            return (
              <div className="rating-category" key={categoryKey}>
                <div className="rating-category-text">
                  <strong>{title}</strong>
                  {description && <><br />({description})</>}
                </div>
                <div className="rating-category-ratings">
                  <RatingDisplayStars
                    numberOfStars={5}
                    rating={+roundedRating}
                  />
                  <p>{roundedRating}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="rating-detail-container">
          <h3 className="ratings-section-header">Ratings ({this.props.totalCount})</h3>
          {this.props.detail && this.props.detail.length ? (
            <ScrollView
              className="ratings-tab-scroll-view-container"
              containerStyle={{ height: '100%' }}
              loadMore={() => {
                if (!this.props.loading && !this.props.loadingMore && this.props.totalCount > this.props.detail?.length) {
                  this.props.dispatch(updateRatingsOffset());
                  this.props.dispatch(fetchMoreCandidateRatings(this.props.candidateKey));
                }
              }}
            >
              {this.props.detail.map(({ shiftKey, overallRating, ...details }) => {
                return (
                  <RatingsListItem
                    key={shiftKey}
                    overallRating={parseRating(overallRating)}
                    {...details}
                  />
                );
              })}
            </ScrollView>
          ) : (
            <p style={{ color: colors.text }}>There are currently no ratings for this candidate.</p>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ ratings }) {
  return {
    loading: ratings.fetchingCandidateRatings,
    summary: ratings.candidateRatingSummary,
    detail: ratings.candidateRatingDetail,
    totalCount: ratings.totalCount,
    loadingMore: ratings.fetchingMoreCandidateRatings,
  };
}
export default connect(mapStateToProps)(RatingsTab);
