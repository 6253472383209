import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ShiftsList.css';


const styles = {
  tableColumn: {
    width: '25%',
    paddingBottom: '2px',
    paddingTop: '2px',
    textAlign: 'center',
    justifyContent: 'center',
  },
};

const ShiftsListItem = props => (
  <div className="row-container" key={props.shift.jobId}>
    <div style={styles.tableColumn}><p>{moment(props.shift.startTime).format('Do MMMM YYYY')}</p></div>
    <div style={styles.tableColumn}><p>{props.shift.siteName}</p></div>
    <div style={styles.tableColumn}><p>{props.shift.specialityName}</p></div>
    {props.shift.shiftNumber && (
      <div
        style={styles.tableColumn}
        role="button"
        onClick={() => props.goToShift(props.shift.key, props.shift.startTime)}
        tabIndex={0}
      >
        <p className="ref-link">{props.shift.shiftNumber}</p>
      </div>
    )}
  </div>
);

const { string, object, func, number, array, bool, objectOf, oneOfType } = PropTypes;

ShiftsListItem.propTypes = {
  shift: objectOf(oneOfType([
    string,
    object,
    number,
    bool,
    array,
  ]),
  ),
  goToShift: func.isRequired,
};

ShiftsListItem.defaultProps = {
  shift: {},
};

export default ShiftsListItem;
