import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { MdPushPinFilled, MdPushPinOutline } from 'components/Icons';
import CloseButton from 'components/CloseButton';
import './styles.scss';


const SidePanel = (props) => {
  return (
    <div
      className={classNames({
        sidePanelContainer: true,
        sidePanelContainerOpen: props.isOpen,
        sidePanelContainerLeft: props.side === 'left',
        sidePanelContainerRight: props.side === 'right',
      })}
    >
      <div className="sidePanelTitleBar">
        <div className="sidePanelTitleTextContainer">
          <p className="sidePanelTitle">{props.title}</p>
           {props.isPinned !== undefined && (
              <div className="sidePanelPinButton" onClick={() => props.setIsPinned(!props.isPinned)}>
                {props.isPinned ? <MdPushPinFilled /> : <MdPushPinOutline />}
              </div>
           )}
          {/* <div className="sidePanelCloseButton" onClick={props.closeSidePanel}><MdClose /></div> */}
          <CloseButton top={15} right={15} handleClose={props.closeSidePanel} />
        </div>
      </div>
      <div className={classNames({ sidePanelContentContainer: true, sidePanelContentContainerVerticalFlex: props.vflex })}>
        {props.children}
      </div>
    </div>
  );
};

const { bool, func, string, oneOfType, arrayOf, node, oneOf } = PropTypes;
SidePanel.propTypes = {
  isOpen: bool.isRequired,
  closeSidePanel: func.isRequired,
  side: oneOf(['left', 'right']),
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  title: string.isRequired,
  vflex: bool,
  isPinned: bool,
  setIsPinned: func,
};

SidePanel.defaultProps = {
  vflex: false,
  side: 'right',
  isPinned: undefined,
  setIsPinned: () => {},
};

export default SidePanel;
