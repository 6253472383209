import React, { useState, useEffect } from 'react';

import { fetchSharedInboxDetails } from 'lib/api/messaging';

import spacing from 'config/spacing';
import colors from 'config/colors';

import { SharedInboxDetails } from 'types/Messaging';

import Modal from 'components/Modal';
import Loading from 'components/Loading';

interface SharedInboxMembersModalProps {
  sharedInboxKey: string,
  onClose: () => void,
}

function SharedInboxMembersModal(props: SharedInboxMembersModalProps): React.ReactElement {

  const [sharedInboxDetails, setSharedInboxDetails] = useState<SharedInboxDetails | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [isFetchingSharedInboxDetails, setIsFetchingSharedInboxDetails] = useState(false);

  useEffect(() => {
    const getSharedInboxDetails = async () => {
      try {
        setIsFetchingSharedInboxDetails(true);
        const response = await fetchSharedInboxDetails(props.sharedInboxKey);
        setSharedInboxDetails(response.sharedInbox);
        setIsFetchingSharedInboxDetails(false);
      } catch (error) {
        if (error instanceof Error) setApiError(error.message);
        setIsFetchingSharedInboxDetails(false);
      }
    };
    getSharedInboxDetails();
  }, []);

  const sharedInboxName = sharedInboxDetails?.name;
  const header = `${sharedInboxName ? `${sharedInboxName} ` : ''}Shared Inbox Members`;

  if (apiError) {
    return (
      <Modal
        isOpen
        onRequestClose={props.onClose}
        header="Shared Inbox Members"
        contentStyle={{ minWidth: 500, maxHeight: '80vh' }}
        bodyStyle={{ padding: spacing.base, alignItems: 'center' }}
        vflex
      >
        <p style={{ color: colors.red }}>{apiError}</p>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      onRequestClose={props.onClose}
      header={header}
      contentStyle={{ minWidth: 500, maxHeight: '80vh' }}
      bodyStyle={{ padding: spacing.base, alignItems: 'center' }}
      vflex
    >
      {isFetchingSharedInboxDetails ?
        <Loading />
        :
        <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
          {(sharedInboxDetails?.sharedInboxMembers ?? []).map(member => <p key={member.key} style={{ color: '#666' }}>{member.name}</p>)}
        </div>
      }
    </Modal>
  );
}

export default SharedInboxMembersModal;
