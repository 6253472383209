const FETCH_RATE_CARDS = 'FETCH_RATE_CARDS';
const FETCH_RATE_CARDS_SUCCESS = 'FETCH_RATE_CARDS_SUCCESS';
const FETCH_ORG_RATE_CARDS = 'FETCH_ORG_RATE_CARDS';
const FETCH_ORG_RATE_CARDS_SUCCESS = 'FETCH_ORG_RATE_CARDS_SUCCESS';
const FETCH_SITE_RATE_CARDS = 'FETCH_SITE_RATE_CARDS';
const FETCH_SITE_RATE_CARDS_SUCCESS = 'FETCH_SITE_RATE_CARDS_SUCCESS';
const DELETE_RATE_CARD = 'DELETE_RATE_CARD';
const UPDATE_RATE_CARD = 'UPDATE_RATE_CARD';
const ADD_LABELS = 'ADD_LABELS';
const FETCH_LABELS = 'FETCH_LABELS';
const RESET_RATE_CARDS = 'RESET_RATE_CARDS';

export function fetchRateCards() {
  return {
    type: FETCH_RATE_CARDS,
  };
}

export function fetchRateCardsSuccess(rateCards) {
  return {
    type: FETCH_RATE_CARDS_SUCCESS,
    rateCards,
  };
}

export function fetchOrgRateCards() {
  return {
    type: FETCH_ORG_RATE_CARDS,
  };
}

export function fetchOrgRateCardsSuccess(rateCards) {
  return {
    type: FETCH_ORG_RATE_CARDS_SUCCESS,
    rateCards,
  };
}

export function fetchSiteRateCards() {
  return {
    type: FETCH_SITE_RATE_CARDS,
  };
}

export function fetchSiteRateCardsSuccess(rateCards) {
  return {
    type: FETCH_SITE_RATE_CARDS_SUCCESS,
    rateCards,
  };
}

export function deleteRateCard(policiesType, rateCardKey) {
  return {
    type: DELETE_RATE_CARD,
    policiesType,
    rateCardKey,
  };
}

export function updateRateCard(policiesType, rateCardKey, rates, name) {
  return {
    type: UPDATE_RATE_CARD,
    policiesType,
    rateCardKey,
    rates,
    name,
  };
}

export function addLabels(labels) {
  return {
    type: ADD_LABELS,
    labels,
  };
}

export function fetchLabels() {
  return {
    type: FETCH_LABELS,
  };
}

export function resetRateCards() {
  return {
    type: RESET_RATE_CARDS,
  };
}

const initialState = {
  isFetchingRateCards: false,
  isFetchingOrgRateCards: false,
  isfetchingSiteRateCards: false,
  isFetchingLabels: false,
  rateCards: [],
  orgRateCards: [],
  siteRateCards: [],
  labels: {},
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case FETCH_RATE_CARDS:
      return {
        ...state,
        isFetchingRateCards: true,
      };
    case FETCH_RATE_CARDS_SUCCESS: {
      const newRateCards = [];
      Object.keys(action.rateCards).forEach((rateCard) => {
        const rateCardObject = action.rateCards[rateCard];
        rateCardObject.key = rateCard;
        newRateCards.push(rateCardObject);
      });
      return {
        ...state,
        rateCards: newRateCards,
        isFetchingRateCards: false,
      };
    }
    case FETCH_ORG_RATE_CARDS:
      return {
        ...state,
        isFetchingOrgRateCards: true,
      };
    case FETCH_ORG_RATE_CARDS_SUCCESS:
      return {
        ...state,
        orgRateCards: action.rateCards,
        isFetchingOrgRateCards: false,
      };
    case FETCH_SITE_RATE_CARDS:
      return {
        ...state,
        isFetchingSiteRateCards: true,
      };
    case FETCH_SITE_RATE_CARDS_SUCCESS:
      return {
        ...state,
        siteRateCards: action.rateCards,
        isFetchingSiteRateCards: false,
      };
    case DELETE_RATE_CARD:
      if (action.policiesType === 'org') {
        const newOrgRateCards = state.orgRateCards.filter((rateCard) => {
          return rateCard.key !== action.rateCardKey;
        });
        return {
          ...state,
          orgRateCards: newOrgRateCards,
        };
      } else if (action.policiesType === 'site') {
        const newSiteRateCards = state.siteRateCards.filter((rateCard) => {
          return rateCard.key !== action.rateCardKey;
        });
        return {
          ...state,
          siteRateCards: newSiteRateCards,
        };
      }
      return state;
    case UPDATE_RATE_CARD: {
      let editedRateCards;
      if (action.policiesType === 'org') {
        editedRateCards = state.orgRateCards;
        editedRateCards.forEach((rateCard) => {
          if (rateCard.key === action.rateCardKey) {
            Object.keys(rateCard.items).forEach((itemKey) => {
              if (action.rates[itemKey]) {
                rateCard.items[itemKey].rate = +action.rates[itemKey]; // eslint-disable-line
              }
            });
            if (action.name) {
              rateCard.name = action.name; // eslint-disable-line
            }
          }
        });
        return {
          ...state,
          orgRateCards: editedRateCards,
        };
      } else if (action.policiesType === 'site') {
        editedRateCards = state.siteRateCards;
        editedRateCards.forEach((rateCard) => {
          if (rateCard.key === action.rateCardKey) {
            Object.keys(rateCard.items).forEach((itemKey) => {
              if (action.rates[itemKey]) {
                rateCard.items[itemKey].rate = +action.rates[itemKey]; // eslint-disable-line
              }
            });
            if (action.name) {
              rateCard.name = action.name; // eslint-disable-line
            }
          }
        });
        return {
          ...state,
          siteRateCards: editedRateCards,
        };
      }

      return state;
    }
    case FETCH_LABELS:
      return {
        ...state,
        isFetchingLabels: true,
      };
    case ADD_LABELS:
      return {
        ...state,
        labels: action.labels,
        isFetchingLabels: false,
      };
    case RESET_RATE_CARDS:
      return {
        ...state,
        rateCards: [],
      };
    default:
      return state;
  }
}
