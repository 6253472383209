
// Action types
type OpenSideNav = { type: 'SIDE_NAV_OPEN' };
type CloseSideNav = { type: 'SIDE_NAV_CLOSE' };
type SetShiftDetailsIsPinned = { type: 'SET_SHIFT_DETAILS_IS_PINNED', isPinned: boolean };

type UserInterfaceAction = OpenSideNav | CloseSideNav | SetShiftDetailsIsPinned;


// Actions
export const openSideNav = () : OpenSideNav => ({ type: 'SIDE_NAV_OPEN' });
export const closeSideNav = () : CloseSideNav => ({ type: 'SIDE_NAV_CLOSE' });

export const setShiftDetailsIsPinned = (isPinned: boolean) : SetShiftDetailsIsPinned => ({ type: 'SET_SHIFT_DETAILS_IS_PINNED', isPinned });


// State
export interface UserInterfaceState {
  sideNavOpen: boolean,
  shiftDetailsIsPinned: boolean,
}

const initialState : UserInterfaceState = {
  sideNavOpen: true,
  shiftDetailsIsPinned: false,
};


// Reducer
export const userInterface = (state: UserInterfaceState = initialState, action: UserInterfaceAction) : UserInterfaceState => {
  switch (action.type) {
    case 'SIDE_NAV_OPEN': return { ...state, sideNavOpen: true };
    case 'SIDE_NAV_CLOSE': return { ...state, sideNavOpen: false };
    case 'SET_SHIFT_DETAILS_IS_PINNED': return { ...state, shiftDetailsIsPinned: action.isPinned };

    default:
      return state;
  }
};
