import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Loading from 'components/Loading';

import spacing from 'config/spacing';
import colors from 'config/colors';

import { PublishedShiftSummary, FailedShift } from 'types/ShiftTypes';

import { deleteShifts as deletePublishedShifts } from 'lib/api/jobs';

import { removeDraftShift } from 'reducers/createShifts';

import FailedShiftsList from './FailedShiftsList';

const styles = {
  list: { listStyleType: 'none', lineHeight: 1.4 },
  listItem: { color: colors.text, fontSize: '0.9rem' },
  button: {
    marginRight: spacing.small,
  },
  scrollContainer: {
    flex: '1',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
};

interface ConfirmDeleteModalProps {
  selectedDraftShifts: Array<PublishedShiftSummary>,
  selectedNonDraftShifts: Array<PublishedShiftSummary>,
  onClose: () => void,
  unselectSpecifiedShifts: (shiftKeys: Array<string>) => void,
  refreshShiftList: () => void,
}

function composeMessage(shiftCount: number, shiftType: 'draft' | 'published', addConfirmMessage: boolean) {
  const confirmString = 'Please confirm you wish to delete ';
  const shiftString = `${shiftCount} ${shiftType} shift${shiftCount > 1 ? 's' : ''}`;
  return `${addConfirmMessage ? confirmString : ''}${shiftString}`;
}

interface FailedShiftFromApi { shiftKey: string, reason: string }

function ConfirmDeleteModal(props: ConfirmDeleteModalProps): React.ReactElement {

  const [draftShiftsChecked, setDraftShiftsAsChecked] = useState(true);
  const [publishedShiftsChecked, setPublishedShiftsChecked] = useState(true);
  const [isDeletingShifts, setIsDeletingShifts] = useState(false);
  const [failedShifts, setFailedShifts] = useState<Array<FailedShiftFromApi> | null>(null);
  const [deletedDraftShiftsCount, setDeletedDraftShiftsCount] = useState<number | null>(null);
  const [deletedPublishedShiftsCount, setDeletedPublishedShiftsCount] = useState<number | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const deleteShifts = async () => {

    setIsDeletingShifts(true);

    if (draftShiftsSelected && draftShiftsChecked) {
      const draftShiftKeys = props.selectedDraftShifts.map(shift => shift.key);
      draftShiftKeys.forEach(key => dispatch(removeDraftShift(key)));
      props.unselectSpecifiedShifts(draftShiftKeys);
      setDeletedDraftShiftsCount(draftShiftKeys.length);
      if (!publishedShiftsSelected || !publishedShiftsChecked) props.onClose();
    }

    // If there are published shift to delete
    if (publishedShiftsSelected && publishedShiftsChecked) {
      const publishedShiftKeys = props.selectedNonDraftShifts.map(shift => shift.key);

      try {
        const response = await deletePublishedShifts(publishedShiftKeys);

        // If success and contains expected props
        if (response.success && Array.isArray(response.deletedShifts) && Array.isArray(response.failedShifts)) {

          // Set amount of deleted shifts
          setDeletedPublishedShiftsCount(response.deletedShifts.length);

          // If any shifts were deleted, then unselect specified shifts and refresh list view
          if (response.deletedShifts.length > 0) {
            await props.refreshShiftList();
            props.unselectSpecifiedShifts(response.deletedShifts);
          }

          // Set array of failed shifts, otherwise close modal
          if (response.failedShifts.length) {
            setFailedShifts(response.failedShifts);
            setIsDeletingShifts(false);
          } else {
            props.onClose();
          }

        } else {
          setApiError('Unable to delete published shifts. If the problem persists, please contact technical support.');
          setIsDeletingShifts(false);
        }

      } catch (error) {
        if (error instanceof Error) {
          setApiError(error.message);
          setIsDeletingShifts(false);
        }
      }
    }
  };

  const draftShiftsSelected = props.selectedDraftShifts.length > 0;
  const publishedShiftsSelected = props.selectedNonDraftShifts.length > 0;
  const showCheckboxes = draftShiftsSelected && publishedShiftsSelected;
  const deleteButtonDisabled = !draftShiftsChecked && !publishedShiftsChecked;

  return (
    <Modal
      isOpen
      onRequestClose={props.onClose}
      header="Confirm Delete"
      contentStyle={{ justifyContent: 'space-between', width: 400 }}
      bodyStyle={{ padding: spacing.base }}
      vflex
    >
      {failedShifts ?
        <FailedDeletedShifts
          onClose={props.onClose}
          deletedDraftShiftsCount={deletedDraftShiftsCount}
          deletedPublishedShiftsCount={deletedPublishedShiftsCount}
          failedShifts={failedShifts.map((shift) => {
            const shiftFromListView = props.selectedNonDraftShifts.find(s => s.key === shift.shiftKey);
            if (shiftFromListView) return { ...shiftFromListView, failureReason: shift.reason };
            return undefined;
          }).filter(Boolean) as Array<FailedShift>}
        />
        :
        <ul style={styles.list}>

          {props.selectedDraftShifts.length > 0 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {showCheckboxes && (
                <div style={{ marginRight: spacing.small }}>
                  <Checkbox checked={draftShiftsChecked} onChange={() => setDraftShiftsAsChecked(!draftShiftsChecked)} />
                </div>
              )}
              <li style={styles.listItem}>{composeMessage(props.selectedDraftShifts.length, 'draft', !showCheckboxes)}</li>
            </div>
          )}

          {props.selectedNonDraftShifts.length > 0 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {showCheckboxes && (
                <div style={{ marginRight: spacing.small }}>
                  <Checkbox checked={publishedShiftsChecked} onChange={() => setPublishedShiftsChecked(!publishedShiftsChecked)} />
                </div>
              )}
              <li style={styles.listItem}>{composeMessage(props.selectedNonDraftShifts.length, 'published', !showCheckboxes)}</li>
            </div>
          )}
        </ul>
    }

      {apiError && <p style={{ paddingTop: spacing.small, color: colors.red, fontSize: 14, lineHeight: 1.4 }}>{apiError}</p>}

      {!failedShifts && (
        <div style={{ marginTop: 18 }}>
          <div className="space-children-12" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative' }}>
            {isDeletingShifts && <Loading size={36} />}
            <Button large white outline onClick={props.onClose} disabled={isDeletingShifts}>Cancel</Button>
            <Button large onClick={deleteShifts} disabled={isDeletingShifts || deleteButtonDisabled}>Delete</Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

const composeDeletedShiftsMessage = (deletedShifts: number | null, shiftType: 'draft' | 'published') => {
  let deletedShiftsText;
  if (typeof deletedShifts === 'number' && deletedShifts > 0) {
    deletedShiftsText = `${deletedShifts} ${shiftType} shift${deletedShifts > 1 ? 's' : ''} successfully deleted.`;
  }
  return deletedShiftsText;
};


interface FailedDeletedShiftsProps {
  failedShifts: Array<FailedShift>,
  deletedPublishedShiftsCount: number | null,
  deletedDraftShiftsCount: number | null,
  onClose: () => void,
}

function FailedDeletedShifts(props: FailedDeletedShiftsProps) {

  const deletedDraftShiftsMessage = composeDeletedShiftsMessage(props.deletedDraftShiftsCount, 'draft');
  const deletedPublishedShiftsMessage = composeDeletedShiftsMessage(props.deletedPublishedShiftsCount, 'published');
  return (
    <>
      {deletedDraftShiftsMessage && <p style={{ color: '#666', lineHeight: 1.4 }}>{deletedDraftShiftsMessage}</p>}
      {deletedPublishedShiftsMessage && <p style={{ color: '#666', lineHeight: 1.4 }}>{deletedPublishedShiftsMessage}</p>}
      <p style={{ color: colors.red, lineHeight: 1.4, marginBottom: spacing.base }}>The following shifts failed to delete:</p>

      <FailedShiftsList
        onClose={props.onClose}
        failedShifts={props.failedShifts}
      />
    </>
  );
}

export default ConfirmDeleteModal;
