import React, { useState } from 'react';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import spacing from 'config/spacing';

import TimePicker from 'components/TimePicker';
import CloseButton from 'components/CloseButton';

interface DateAndTimePickerProps {
  date: string | null,
  timezone: string,
  defaultDate: string,
  onChange: (date: string) => void,
  clearValue?: () => void,
  disabled: boolean,
  isOutsideRange: (day: moment.Moment) => boolean,
  startOfRange?: string,
  endOfRange?: string,
  openDirection: 'up' | 'down'
  clearable?: boolean,
}

const DateAndTimePicker: React.FC<DateAndTimePickerProps> = (props: DateAndTimePickerProps) => {

  const [datePickerFocused, setDatePickerFocused] = useState(false);

  const timezoneDate = convertFromUtcToTimezone(props.date ?? props.defaultDate, props.timezone);

  const updateDate = (date: moment.Moment) => {
    const newTimezoneDate = convertFromUtcToTimezone(date.toISOString(), props.timezone);

    const timezoneHour = timezoneDate.hour();
    const timezoneMinute = timezoneDate.minute();

    // Set new date and time
    const updatedDateAndTime = newTimezoneDate.hours(timezoneHour).minutes(timezoneMinute).seconds(0).milliseconds(0)
      .toISOString();

    props.onChange(updatedDateAndTime);
  };

  const updateTime = (time: { hour: number, minute: number }) => {
    // Set new date and time
    const updatedDateAndTime = timezoneDate.hours(time.hour).minutes(time.minute).seconds(0).milliseconds(0)
      .toISOString();

    props.onChange(updatedDateAndTime);
  };

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div className="createShiftDatePicker" style={{ marginRight: spacing.tiny, flexBasis: '50%' }}>
        <SingleDatePicker
          displayFormat="ll"
          placeholder="Select Date"
          date={props.date ? timezoneDate : null}
          onDateChange={(newDate) => { if (newDate) updateDate(newDate); }}
          focused={datePickerFocused}
          onFocusChange={({ focused }) => setDatePickerFocused(focused)}
          openDirection={props.openDirection}
          numberOfMonths={1}
          firstDayOfWeek={1}
          showDefaultInputIcon
          inputIconPosition="after"
          id="id"
          disabled={props.disabled}
          isOutsideRange={props.isOutsideRange}
        />
      </div>
      <div style={{ flexBasis: '40%' }}>
        <TimePicker
          placeholder="Select Time"
          startOfRange={props.startOfRange ? convertFromUtcToTimezone(props.startOfRange, props.timezone) : timezoneDate.clone().startOf('day')}
          endOfRange={props.endOfRange ? convertFromUtcToTimezone(props.endOfRange, props.timezone) : null}
          value={props.date}
          onTimeChange={(time: { hour: number, minute: number }) => updateTime(time)}
          timezone={props.timezone}
          isDisabled={props.disabled}
        />
      </div>
      {/* Clear Button */}
      {props.clearable && (
        <div style={{ flexBasis: '10%' }}>
          {!props.disabled && !!props.clearable && <CloseButton handleClose={() => props.clearValue && props.clearValue()} top={8} />}
        </div>
      )}
    </div>
  );
};

DateAndTimePicker.defaultProps = { startOfRange: undefined, endOfRange: undefined, clearable: undefined, clearValue: undefined };

export default DateAndTimePicker;
