import React, { useCallback, useReducer, useMemo, useEffect } from 'react';
import { useSelector } from 'hooks/redux';
import { Contract } from 'types/Contract';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import moment from 'moment';

export interface EditContractState {
  startDate: string | null,
  endDate: string | null,
  contractedHours: number,
  contractedHoursPeriodType: { value: string, label: string },
  workingTimeDirectiveApplies: boolean,
}

interface ResetStateAction { type: 'RESET_STATE' }
interface SetStartDateAction { type: 'SET_START_DATE', startDate: string | null }
interface SetEndDateAction { type: 'SET_END_DATE', endDate: string | null }
interface SetContractedHoursAction { type: 'SET_CONTRACTED_HOURS', contractedHours: number }
interface SetContractedHoursPeriodTypeAction { type: 'SET_CONTRACTED_HOURS_PERIOD_TYPE', contractedHoursPeriodType: { value: string, label: string } }
interface SetWorkingTimeDirectiveAppliesAction { type: 'SET_WORKING_TIME_DIRECTIVE_APPLIES', workingTimeDirectiveApplies: boolean }

type EditContractAction = ResetStateAction | SetStartDateAction | SetEndDateAction | SetContractedHoursAction | SetContractedHoursPeriodTypeAction | SetWorkingTimeDirectiveAppliesAction;

interface UseFormReducerReturnTypes {
  fields: EditContractState,
  handleFieldChange: React.Dispatch<EditContractAction>,
}

export const useFormReducer = (initialFormState: EditContractState): UseFormReducerReturnTypes => {
  const [fields, handleFieldChange]: [EditContractState, React.Dispatch<EditContractAction>] = useReducer((state: EditContractState, action: EditContractAction) => {

    if (action.type === 'RESET_STATE') return { ...initialFormState };
    if (action.type === 'SET_START_DATE') return { ...state, startDate: action.startDate };
    if (action.type === 'SET_END_DATE') return { ...state, endDate: action.endDate };
    if (action.type === 'SET_CONTRACTED_HOURS') return { ...state, contractedHours: action.contractedHours };
    if (action.type === 'SET_CONTRACTED_HOURS_PERIOD_TYPE') return { ...state, contractedHoursPeriodType: action.contractedHoursPeriodType };
    if (action.type === 'SET_WORKING_TIME_DIRECTIVE_APPLIES') return { ...state, workingTimeDirectiveApplies: action.workingTimeDirectiveApplies };

    throw new Error('action.type is required');

  }, initialFormState);

  return { fields, handleFieldChange };
};

export const contractedHoursPeriodTypeOptions = {
  week: { value: 'week', label: 'Week' },
  month: { value: 'month', label: 'Month' },
};

interface UseFormFieldsReturnTypes {
  fields: EditContractState,
  onChangeStartDate: (date: moment.Moment | null) => void,
  onChangeEndDate: (date: moment.Moment | null) => void,
  onChangeContractedHours: (hours: string) => void,
  onChangeContractedHoursPeriodType: (periodType: { value: string, label: string }) => void,
  onChangeWorkingTimeDirective: (checked: boolean) => void,
  resetState: () => void,
  timezoneStartDate: moment.Moment | null,
  timezoneEndDate: moment.Moment | null,
  changesMade: boolean,
  startDateIsOutsideRange: (date: moment.Moment) => boolean,
  endDateIsOutsideRange: (date: moment.Moment) => boolean,
}

function compareChangesToInitialState(fields: EditContractState, initialState: EditContractState, timezone: string): boolean {

  const startDateTz = fields.startDate ? convertFromUtcToTimezone(fields.startDate, timezone) : null;
  const initialStartDateTz = initialState.startDate ? convertFromUtcToTimezone(initialState.startDate, timezone) : null;
  const endDateTz = fields.endDate ? convertFromUtcToTimezone(fields.endDate, timezone) : null;
  const initialEndDateTz = initialState.endDate ? convertFromUtcToTimezone(initialState.endDate, timezone) : null;

  if ((startDateTz && !initialStartDateTz) || (initialStartDateTz && !startDateTz)) return true;
  if ((endDateTz && !initialEndDateTz) || (initialEndDateTz && !endDateTz)) return true;
  if (startDateTz && initialStartDateTz && !startDateTz.isSame(initialStartDateTz, 'day')) return true;
  if (endDateTz && initialEndDateTz && !endDateTz.isSame(initialEndDateTz, 'day')) return true;
  if (fields.contractedHours !== initialState.contractedHours) return true;
  if (fields.contractedHoursPeriodType.value !== initialState.contractedHoursPeriodType.value) return true;
  if (fields.workingTimeDirectiveApplies !== initialState.workingTimeDirectiveApplies) return true;
  return false;
}

export const useFormFields = (contract?: Contract): UseFormFieldsReturnTypes => {

  const timezone = useSelector(state => state.global.orgConfig?.timezone ?? 'Europe/London');

  const initialFormState = {
    startDate: contract?.startDate ?? null,
    endDate: contract?.endDate ?? null,
    contractedHours: contract?.contractedHours ? parseFloat(contract.contractedHours) : 40,
    contractedHoursPeriodType: contract?.contractedHoursPeriodType ? contractedHoursPeriodTypeOptions[contract.contractedHoursPeriodType] : contractedHoursPeriodTypeOptions.week,
    workingTimeDirectiveApplies: contract?.workingTimeDirectiveApplies !== undefined ? contract.workingTimeDirectiveApplies : true,
  };

  const { fields, handleFieldChange } = useFormReducer(initialFormState);

  // Update form state if contract from Redux updates
  useEffect(() => {
    handleFieldChange({ type: 'RESET_STATE' });
  }, [contract]);

  const onChangeStartDate = useCallback((date: moment.Moment | null) => {
    const timezoneStartDate = date ? convertFromUtcToTimezone(date.toISOString(), timezone) : null;
    handleFieldChange({ type: 'SET_START_DATE', startDate: timezoneStartDate?.toISOString() ?? null });
  }, [timezone]);

  const onChangeEndDate = useCallback((date: moment.Moment | null) => {
    const timezoneEndDate = date ? convertFromUtcToTimezone(date.toISOString(), timezone) : null;
    handleFieldChange({ type: 'SET_END_DATE', endDate: timezoneEndDate?.toISOString() ?? null });
  }, [timezone]);

  const onChangeContractedHours = useCallback((hours) => handleFieldChange({ type: 'SET_CONTRACTED_HOURS', contractedHours: parseFloat(hours) }), []);
  const onChangeContractedHoursPeriodType = useCallback((contractedHoursPeriodType) => handleFieldChange({ type: 'SET_CONTRACTED_HOURS_PERIOD_TYPE', contractedHoursPeriodType }), []);
  const onChangeWorkingTimeDirective = useCallback((workingTimeDirectiveApplies) => handleFieldChange({ type: 'SET_WORKING_TIME_DIRECTIVE_APPLIES', workingTimeDirectiveApplies }), []);

  const timezoneStartDate = fields.startDate ? convertFromUtcToTimezone(fields.startDate, timezone) : null;
  const timezoneEndDate = fields.endDate ? convertFromUtcToTimezone(fields.endDate, timezone) : null;
  const resetState = useCallback(() => handleFieldChange({ type: 'RESET_STATE' }), []);

  const changesMade = useMemo(() => compareChangesToInitialState(fields, initialFormState, timezone), [fields, contract]);

  const startDateIsOutsideRange = useCallback((date: moment.Moment) => {
    if (!fields.endDate) return false;
    const tzStartDate = convertFromUtcToTimezone(date.toISOString(), timezone);
    const tzEndDate = convertFromUtcToTimezone(fields.endDate, timezone);
    return tzStartDate.isAfter(tzEndDate);
  }, [fields.startDate, fields.endDate, timezone]);

  const endDateIsOutsideRange = useCallback((date: moment.Moment) => {
    if (!fields.startDate) return false;
    const tzEndDate = convertFromUtcToTimezone(date.toISOString(), timezone);
    const tzStartDate = convertFromUtcToTimezone(fields.startDate, timezone);
    return tzEndDate.isBefore(tzStartDate);
  }, [fields.startDate, fields.endDate, timezone]);

  return {
    fields,
    onChangeStartDate,
    onChangeEndDate,
    onChangeContractedHours,
    onChangeContractedHoursPeriodType,
    onChangeWorkingTimeDirective,
    resetState,
    timezoneStartDate,
    timezoneEndDate,
    changesMade,
    startDateIsOutsideRange,
    endDateIsOutsideRange,
  };
};
