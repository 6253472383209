import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const NotFound = (props) => {
  if (props.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { redirectUrl: props.location },
      }}
    />
  );
};

function mapStateToProps({ user }) {
  return {
    isLoggedIn: user.userId !== null,
  };
}

export default connect(mapStateToProps)(NotFound);
