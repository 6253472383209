import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';
import { fetchCandidateAuditTrail } from 'lib/api/accounts';
import { reportError } from 'lib/error-reporting';
import AuditTrail from 'components/AuditTrail';
import Loading from 'components/Loading';
import colors from 'config/colors';

const styles = {
  centreContainer: {
    display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center',
  },
};

interface AuditTrailProps {
  candidateKey: string,
}

const CandidateAuditTrail : React.FC<AuditTrailProps> = (props: AuditTrailProps) => {

  const [auditTrail, setAuditTrail] = useState([]);
  const [isFetchingAuditTrail, setIsFetching] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAuditTrail = async () => {
      try {
        setIsFetching(true);
        const response = await fetchCandidateAuditTrail(props.candidateKey);
        setAuditTrail(response.auditTrail);
        setIsFetching(false);
      } catch (error) {
        if (error instanceof Error) setApiError(error.message);
        setIsFetching(false);
        reportError(error);
      }
    };
    fetchAuditTrail();
  }, [props.candidateKey]);

  if (isFetchingAuditTrail) {
    return <div style={styles.centreContainer}><Loading /></div>;
  }

  if (apiError) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.red }}>Oops! Something went wrong. Please try again. If the problem persists, please contact customer support.</p>
      </div>
    );
  }

  if (auditTrail?.length === 0) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.text }}>There are no audit trail messages to view yet.</p>
      </div>
    );
  }

  return (
    <div style={{ overflowY: 'auto' }}>
      <AuditTrail
        items={auditTrail.sort(firstBy('createdAt')).map(({ title, message, createdAt, adminName, adminKey }) => {
          return {
            title,
            message,
            when: createdAt,
            userId: adminKey,
            userName: adminName,
          };
        }).reverse()}
      />
    </div>
  );
};

CandidateAuditTrail.propTypes = { candidateKey: PropTypes.string.isRequired };

export default CandidateAuditTrail;
