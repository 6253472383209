import React from 'react';
import PropTypes from 'prop-types';
import { MdCheck } from 'react-icons/md';
import colors from '../../config/colors';

const styles = {
  boxClassic: {
    boxSizing: 'border-box',
    display: 'inline-block',
    overflow: 'hidden',
    cursor: 'pointer',
    fontSize: 0,
    color: colors.white,
    backgroundColor: colors.white,
    borderColor: colors.text,
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  checkClassic: {
    boxSizing: 'border-box',
    color: colors.brand,
    width: '100%',
    height: '100%',
    position: 'relative',
    verticalAlign: 'top',
  },
  boxAlternate: {
    boxSizing: 'border-box',
    backgroundColor: colors.white,
    color: colors.white,
    borderColor: colors.text,
    borderWidth: '1px',
    borderStyle: 'solid',
    userSelect: 'none',
    marginBottom: '2px',
    borderRadius: 2,
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  checkAlternate: {
    verticalAlign: 'top',
    color: colors.text,
    fontSize: '15px',
    position: 'relative',
  },
  disabled: {
    backgroundColor: colors.disabled,
    opacity: 0.5,
    cursor: 'default',
  },
};

export default function Checkbox(props) {
  const boxSizeStyles = { width: props.size, height: props.size, minWidth: props.size, minHeight: props.size };
  return (
    <div
      role="button"
      tabIndex={0}
      style={{ ...styles.boxAlternate, ...props.boxStyle, ...boxSizeStyles, ...props.disabled ? styles.disabled : null, position: 'relative' }}
      onClick={(e) => {
        e.stopPropagation();
        if (!props.disabled) props.onChange(!props.checked);
      }}
    >
      {/* Intermediate check */}
      {props.checked === null && <span style={{ top: '46%', left: '15%', position: 'absolute', width: '70%', height: '8%', backgroundColor: colors.brand }} />}

      {/* Check */}
      {props.checked && <MdCheck style={{ ...styles.checkAlternate, ...props.checkStyle, fontSize: props.size - 1 }} />}
    </div>
  );
}

const { bool, func, objectOf, oneOfType, string, number } = PropTypes;
Checkbox.propTypes = {
  checked: oneOfType([bool, null]).isRequired,
  onChange: func,
  disabled: bool,
  size: number,
  boxStyle: objectOf(oneOfType([
    string,
    number,
  ])),
  checkStyle: objectOf(oneOfType([
    string,
    number,
  ])),
};

Checkbox.defaultProps = {
  boxStyle: {},
  checkStyle: {},
  disabled: false,
  size: 24,
  onChange: () => {},
};
