import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firstBy } from 'thenby';

import { setSelectedSpeciality } from 'reducers/reporting';
import DropDownFilter from 'components/DropDownFilter';

const customSelectBoxStyles = {
  valueContainer: { fontSize: '14px' },
  menuList: { width: '260px' },
  menu: { width: '260px' },
  option: { fontSize: '14px' },
};

const { objectOf, func, string, shape } = PropTypes;
class SpecialityFilter extends Component {

  static propTypes = {
    specialities: objectOf(shape({
      key: string,
      name: string,
    })).isRequired,
    setSelectedSpeciality: func.isRequired,
  }

getOptionsForSpecialityFilters = () => { // eslint-disable-line
  const options = Object.entries(this.props.specialities || {})
    .map(([key, { name }]) => ({ label: name, value: key }))
    .sort(firstBy('label'));
  return [{ label: 'All specialities', value: null }, ...options];
}

render() {
  const options = this.getOptionsForSpecialityFilters();

  return (
    <DropDownFilter
      title="Speciality:"
      placeholder="Filter by speciality..."
      onChange={inputValue => this.props.setSelectedSpeciality({ key: inputValue.value, name: inputValue.label })}
      options={options}
      clearable={false}
      customStyles={customSelectBoxStyles}
    />
  );
}
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedSpeciality: site => dispatch(setSelectedSpeciality(site)),
  };
}

function mapStateToProps({ reporting, rgs }) {
  const orgSpecialities = {};
  Object.entries(rgs.rgsMetadata).forEach(([, { specialities }]) => {
    Object.entries(specialities).forEach(([key, { name }]) => {
      orgSpecialities[key] = { key, name };
    });
  });

  return {
    specialities: orgSpecialities,
    selectedSpeciality: reporting.selectedSite,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialityFilter);
