import * as bankReducer from '../reducers/bank';
import * as api from '../lib/api';
import { getCategories } from '../lib/api/compliance';

export function fetchCandidates(payload) {
  return async (dispatch) => {
    dispatch(bankReducer.fetchCandidates());
    const response = await api.post('ocr/get', payload);

    if (response.status >= 400) {
      dispatch(bankReducer.fetchCandidatesSuccess({}));
    } else {
      dispatch(bankReducer.fetchCandidatesSuccess(response.body?.candidates));
    }
  };
}

export function fetchTickets() {
  return async (dispatch, getState) => {
    dispatch(bankReducer.fetchTickets());
    const authorisedOrgs = getState().global.employerAuthorisedOrgs;
    const complianceCategories = await getCategories(Object.keys(authorisedOrgs)[0], true, null, true);

    dispatch(bankReducer.fetchTicketsSuccess(complianceCategories));
  };
}

