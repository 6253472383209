export interface ShiftMappings {
  [key: string]: { key: string, name: string }
}

const shiftFieldMappings: ShiftMappings = {
  startTime: { key: 'startTime', name: 'Start Time' },
  endTime: { key: 'endTime', name: 'End Time' },
  site: { key: 'site', name: 'Site' },
  area: { key: 'area', name: 'Area' },
  role: { key: 'role', name: 'Role' },
  grade: { key: 'grade', name: 'Grade' },
  speciality: { key: 'speciality', name: 'Speciality' },
  costCentre: { key: 'costCentre', name: 'Cost Centre' },
  rateCard: { key: 'rateCard', name: 'Rate Card' },
  reason: { key: 'reason', name: 'Reason' },
  bank: { key: 'bankKeys', name: 'Bank' },
  startTimeOfDay: { key: 'startTimeOfDay', name: 'Start Time Of Day' },
  endTimeOfDay: { key: 'endTimeOfDay', name: 'End Time Of Day' },
  dayOfWeek: { key: 'dayOfWeek', name: 'Day Of Week' },
  service: { key: 'service', name: 'Service' },
  customHourlyRate: { key: 'customHourlyRate', name: 'Rate (per hour)' },
  candidates: { key: 'candidates', name: 'Targeted Candidate(s)' }
};

export { shiftFieldMappings };
