import { firstBy } from 'thenby';
import { RateCard, CostCentre } from 'types/Metadata';

// Find rate cards that match shift specification
export function findApplicableRateCards(orgRateCards: RateCard[], roleKey: string | null, specialityKey: string | null, gradeKey: string | null, siteKey: string, areaKey: string | null, reasonKey: string | null, subReasonKey: string | null, serviceKey: string | null, orgKey: string) : RateCard[] {

  return (orgRateCards || [])
    .filter(rateCard => (
      ((!rateCard.siteKeys || (siteKey && rateCard.siteKeys.includes(siteKey))) && (!rateCard.excludedSiteKeys || (!siteKey || !rateCard.excludedSiteKeys.includes(siteKey)))) &&
      ((!rateCard.areaKeys || (areaKey && rateCard.areaKeys.includes(areaKey))) && (!rateCard.excludedAreaKeys || (!areaKey || !rateCard.excludedAreaKeys.includes(areaKey)))) &&
      ((!rateCard.roleKeys || (roleKey && rateCard.roleKeys.includes(roleKey))) && (!rateCard.excludedRoleKeys || (!roleKey || !rateCard.excludedRoleKeys.includes(roleKey)))) &&
      ((!rateCard.gradeKeys || (gradeKey && rateCard.gradeKeys.includes(gradeKey))) && (!rateCard.excludedGradeKeys || (!gradeKey || !rateCard.excludedGradeKeys.includes(gradeKey)))) &&
      ((!rateCard.specialityKeys || (specialityKey && rateCard.specialityKeys.includes(specialityKey))) && (!rateCard.excludedSpecialityKeys || (!specialityKey || !rateCard.excludedSpecialityKeys.includes(specialityKey)))) &&
      ((!rateCard.reasonKeys || (reasonKey && rateCard.reasonKeys.includes(reasonKey))) && (!rateCard.excludedReasonKeys || (!reasonKey || !rateCard.excludedReasonKeys.includes(reasonKey)))) &&
      ((!rateCard.subReasonKeys || (subReasonKey && rateCard.subReasonKeys.includes(subReasonKey))) && (!rateCard.excludedSubReasonKeys || (!subReasonKey || !rateCard.excludedSubReasonKeys.includes(subReasonKey)))) &&
      ((!rateCard.serviceKeys || (serviceKey && rateCard.serviceKeys.includes(serviceKey))) && (!rateCard.excludedServiceKeys || (!serviceKey || !rateCard.excludedServiceKeys.includes(serviceKey)))) &&
      (!rateCard.orgKey || (rateCard.orgKey === orgKey))
    )).sort(firstBy('precedence'));
}


export function fetchMatchingCostCentre(costCentres: CostCentre[], siteKey: string | undefined, areaKey: string | undefined, roleKey: string | undefined, gradeKey: string | undefined, specialityKey: string | undefined, reasonKey: string | undefined, serviceKey: string | undefined) : CostCentre | null {
  return costCentres
    .filter(costCentre => (
      (!costCentre.siteKey || costCentre.siteKey === siteKey) &&
      (!costCentre.areaKey || costCentre.areaKey === areaKey) &&
      (!costCentre.roleKey || costCentre.roleKey === roleKey) &&
      (!costCentre.gradeKey || costCentre.gradeKey === gradeKey) &&
      (!costCentre.specialityKey || costCentre.specialityKey === specialityKey) &&
      (!costCentre.reasonKey || costCentre.reasonKey === reasonKey) &&
      (!costCentre.serviceKey || costCentre.serviceKey === serviceKey)
    ))[0] ?? null;
}
