import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import { clearReportingError } from '../../../reducers/reporting';
import { changeDate, fetchReport } from '../../../thunks/reporting';
import { reports } from '../Reports';

const { object, number, func, string, bool, shape, objectOf } = PropTypes;

class DashboardContainer extends Component {

  static propTypes = {
    loading: shape({
      fillRate: bool.isRequired,
      fillRateByRole: bool.isRequired,
      fillRateBySpeciality: bool.isRequired,
      requests: bool.isRequired,
      requestsByRole: bool.isRequired,
      requestsBySpeciality: bool.isRequired,
      spend: bool.isRequired,
      spendByRole: bool.isRequired,
      spendBySpeciality: bool.isRequired,
      activity: bool.isRequired,
    }).isRequired,
    reports: objectOf(object).isRequired,
    fetchReport: func.isRequired,
    selectedSite: shape({ key: string, name: string }),
    selectedRange: shape({
      from: string.isRequired,
      to: string.isRequired,
    }).isRequired,
    history: shape({
      push: func.isRequired,
      replace: func.isRequired,
      createHref: func.isRequired,
    }).isRequired,
    reportError: shape({
      fillRate: string,
      fillRateByRole: string,
      fillRateBySpeciality: string,
      requests: string,
      requestsByRole: string,
      requestsBySpeciality: string,
      spend: string,
      spendByRole: string,
      spendBySpeciality: string,
      activity: string,
    }).isRequired,
    clearReportingError: func.isRequired,
    durationBetweenDates: number.isRequired,
  }

  static defaultProps = { selectedSite: {} };

  async componentDidMount() {
    // Fetch all dashboard reports
    const reportConfig = reports.find(report => report.name === 'dashboard');
    await Promise.all(reportConfig.reportsToLoad.map(async (report) => {
      // If report is fillRate, do not add a groupBy parameter
      if (report === 'fillRate') {
        await this.props.fetchReport(report);
      } else {
        await this.props.fetchReport(report, 'date');
      }
    }));
  }

  async componentDidUpdate(prevProps) {

    const siteHasChanged = prevProps.selectedSite.key !== this.props.selectedSite.key;
    const dateHasChanged = (prevProps.selectedRange.from !== this.props.selectedRange.from) || (prevProps.selectedRange.to !== this.props.selectedRange.to);

    // Refetch reports if site or date range changes
    if (siteHasChanged || dateHasChanged) {
      await Promise.all(['fillRate', 'requests', 'spend'].map(async (report) => {
        // If report is fillRate, do not add a groupBy parameter
        if (report === 'fillRate') {
          await this.props.fetchReport(report);
        } else {
          await this.props.fetchReport(report, 'date');
        }
      }));
    }

  }

  // Navigate to full drilldown  when clicking on a dashboard report
  onClickChart = (report) => {
    const result = reports.find(r => r.name === report);
    this.props.history.push(result.url);
  }

  render() {
    return (
      <Dashboard
        reports={this.props.reports}
        isLoadingReports={this.props.loading.spend || this.props.loading.fillRate || this.props.loading.requests}
        onClickChart={this.onClickChart}
        reportError={this.props.reportError}
        clearReportingError={this.props.clearReportingError}
        durationBetweenDates={this.props.durationBetweenDates}
      />
    );
  }
}

const mapStateToProps = ({ reporting }) => {
  return {
    loading: reporting.loading,
    reports: reporting.reports,
    durationBetweenDates: reporting.durationBetweenDates,
    selectedRange: reporting.selectedRange,
    selectedSite: reporting.selectedSite,
    reportError: reporting.reportError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReport: (reportName, groupBy) => dispatch(fetchReport(reportName, groupBy)),
    changeDate: range => dispatch(changeDate(range)),
    clearReportingError: reportName => dispatch(clearReportingError(reportName)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardContainer));
