import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import ButtonContainer from '../../components/ButtonContainer';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import SupplierContacts from './SupplierContacts';
import SupplierContact from './SupplierContact';
import SupplierContactForm from './SupplierContactForm';
import {
  createSupplierContact,
  updateSupplierContact,
  deleteSupplierContact,
  startWatchingSupplierContacts,
  stopWatchingSupplierContacts,
} from '../../thunks/suppliers';
import { setContactKey } from '../../reducers/suppliers';

const styles = {
  mainContainer: {
    boxSizing: 'border-box',
    padding: '6px 12px',
    height: 470,
  },
  button: {
    marginLeft: spacing.small,
    paddingRight: spacing.small,
    paddingLeft: spacing.small,
    color: colors.white,
    width: 150,
  },
  new: {
    backgroundColor: colors.text,
  },
  back: {
    backgroundColor: colors.white,
    color: colors.text,
    border: `1px solid ${colors.text}`,
  },
  edit: {
    backgroundColor: colors.text,
  },
  delete: {
    backgroundColor: colors.red,
  },
  discard: {
    backgroundColor: colors.grays.light,
    color: colors.white,
  },
  save: {
    backgroundColor: colors.text,
  },
  centre: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 470,
  },
};

const { string, bool, number, object, shape, func } = PropTypes;

const NEW = 'NEW';
const EDIT = 'EDIT';
const VIEW = 'VIEW';
const VIEW_ALL = 'VIEW_ALL';

class SupplierContactsContainer extends Component {

  static propTypes = {
    dispatch: func.isRequired,
    screen: shape({
      width: number.isRequired,
      height: number.isRequired,
      size: string.isRequired,
    }).isRequired,
    contactsData: object,
    userId: string,
    supplierKey: string,
    contactKey: string,
    isLoading: bool,
  }

  static defaultProps = {
    contactsData: {},
    userId: null,
    supplierKey: null,
    contactKey: null,
    isLoading: false,
  }

  constructor(props) {
    super(props);
    this.buttonStates = {
      [NEW]: {
        alignButtons: 'right',
        buttons: [
          {
            buttonText: 'Cancel',
            extraStyles: styles.discard,
            handleButtonClicked: this.cancelNew,
          },
          {
            buttonText: 'Invite',
            extraStyles: styles.edit,
            handleButtonClicked: this.save,
          },
        ],
      },
      [EDIT]: {
        alignButtons: 'right',
        buttons: [
          {
            buttonText: 'Disable Contact',
            extraStyles: styles.delete,
            handleButtonClicked: this.requestDelete,
          },
          {
            buttonText: 'Cancel',
            extraStyles: styles.discard,
            handleButtonClicked: this.cancelEdit,
          },
          {
            buttonText: 'Save',
            extraStyles: styles.save,
            handleButtonClicked: this.save,
          },
        ],
      },
      [VIEW]: {
        alignButtons: 'right',
        buttons: [
          {
            buttonText: 'All Contacts',
            extraStyles: styles.back,
            handleButtonClicked: this.viewAll,
          },
          {
            buttonText: 'Edit',
            extraStyles: styles.new,
            handleButtonClicked: this.edit,
          },
        ],
      },
      [VIEW_ALL]: {
        alignButtons: 'right',
        buttons: [
          {
            buttonText: 'New Contact',
            extraStyles: styles.new,
            handleButtonClicked: this.newContact,
          },
        ],
      },
    };
    this.state = {
      mode: VIEW_ALL,
      submitting: false,
      confirmationModalIsOpen: false,
      infoModalIsOpen: false,
      infoModalText: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(startWatchingSupplierContacts(this.props.supplierKey));
  }

  componentWillUnmount() {
    this.props.dispatch(stopWatchingSupplierContacts());
  }

  newContact = () => {
    this.props.dispatch(setContactKey(null));
    this.setState({ mode: NEW });
  }

  cancelNew = () => {
    this.setState({ mode: VIEW_ALL });
  }

  save = () => {
    this.setState({
      submitting: true,
    });
  }

  selectContact = (contactKey) => {
    this.props.dispatch(setContactKey(contactKey));
    this.setState({ mode: VIEW });
  }

  viewAll = () => {
    this.setState({ mode: VIEW_ALL });
  }

  edit = () => {
    this.setState({ mode: EDIT });
  }

  requestDelete = async () => {
    this.setState({ confirmationModalIsOpen: true });
  }

  closeConfirmationModal = () => {
    this.setState({ confirmationModalIsOpen: false });
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
      infoModalText: '',
    });
  }

  delete = async () => {
    await this.props.dispatch(deleteSupplierContact(this.props.contactKey, this.props.supplierKey, 'disable only'));
    this.setState({
      mode: VIEW_ALL,
      confirmationModalIsOpen: false,
    });
  }

  cancelEdit = () => {
    this.setState({ mode: VIEW });
  }

  saveData = async (formData) => {
    const { contactKey, supplierKey, userId } = this.props;
    this.setState({ submitting: false });
    if (contactKey) {
      await this.props.dispatch(updateSupplierContact(supplierKey, contactKey, formData, userId));
      this.setState({ mode: VIEW });
    } else {
      await this.props.dispatch(createSupplierContact(supplierKey, formData, userId));
      this.setState({
        infoModalIsOpen: true,
        infoModalText: 'Invite sent',
        mode: VIEW_ALL,
      });
    }
  }

  renderContents() {
    switch (this.state.mode) {
      case NEW:
        return (
          <SupplierContactForm
            onSubmit={this.saveData}
            triggerSubmit={this.state.submitting}
            screen={this.props.screen}
          />
        );
      case EDIT:
        return (
          <SupplierContactForm
            initialValues={this.props.contactsData[this.props.contactKey]}
            onSubmit={this.saveData}
            triggerSubmit={this.state.submitting}
            screen={this.props.screen}
          />
        );
      case VIEW:
        return (
          <SupplierContact
            contactData={this.props.contactsData[this.props.contactKey]}
            screen={this.props.screen}
            isLoading={this.props.isLoading}
          />
        );
      case VIEW_ALL:
        return (
          <SupplierContacts
            contacts={this.props.contactsData}
            handleContactClicked={this.selectContact}
            screen={this.props.screen}
            isLoading={this.props.isLoading}
          />
        );
      default:
        return (
          <SupplierContacts
            contacts={this.props.contactsData}
            screen={this.props.screen}
          />
        );
    }
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        {this.props.isLoading ?
          <div style={styles.centre}>
            <Loading />
          </div>
          :
          <div>
            <ButtonContainer
              buttonStyles={styles.button}
              currentState={this.state.mode}
              states={this.buttonStates}
            />
            { this.renderContents() }
            <Modal
              isOpen={this.state.confirmationModalIsOpen}
              contentLabel="Confirm Delete Supplier"
              onRequestClose={this.closeConfirmationModal}
            >
              <div>
                <h2 style={styles.header}>Disable contact?</h2>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Button onClick={this.closeConfirmationModal}>
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={this.delete}
                >
                  Disable
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.infoModalIsOpen}
              contentLabel="Info"
              onRequestClose={this.closeInfoModal}
            >
              <div>
                <h2 style={styles.header}>{this.state.infoModalText}</h2>
              </div>
            </Modal>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps({ screen, suppliers, user }) {
  const { supplierKey, contactKey, contactsData, isLoading } = suppliers;
  const { userId } = user;
  return {
    screen,
    supplierKey,
    contactKey,
    userId,
    isLoading,
    contactsData,
  };
}

export default connect(mapStateToProps)(SupplierContactsContainer);
