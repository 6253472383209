import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SingleDatePicker } from 'react-dates';
import TextAreaAutosize from 'react-autosize-textarea';
import moment from 'moment';
import PropTypes from 'prop-types';
import { customFilter } from 'lib/helpers-select';
import fonts from 'config/fonts';
import colors from 'config/colors';
import spacing from 'config/spacing';
import { isBeforeToday } from 'lib/helpers-date-picker';
import { fetchCandidateShifts } from 'thunks/candidate';
import { removeCandidateFromBank, cancelRemoveCandidateFromBank, reinstateCandidate } from 'thunks/compliance';
import { dismissCandidateRemovalError } from 'reducers/compliance';
import Button from 'components/Button';
import Loading from 'components/Loading';
import DatePickerArrowBox from './DatePickerArrowBox';
import ShiftsList from './ShiftsList';
import 'config/singleDatePicker.scss';

const styles = {
  inputContainer: {
    marginTop: spacing.xxlarge,
    display: 'flex',
    flexDirection: 'column',
  },
  selectBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '90%',
    alignContent: 'center',
    marginBottom: spacing.xxlarge,
    marginLeft: '15px',
  },
  labelContainer: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
  },
  selectBox: {
    width: '100%',
  },
  labelText: {
    fontWeight: 600,
    color: colors.understated,
    fontSize: '0.9rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirm: {
    marginLeft: spacing.small,
    marginTop: spacing.base,
  },
  removeFromBankMessageContainer: {
    marginTop: '10px',
    marginBottom: spacing.xlarge,
    fontSize: '0.9rem',
    color: colors.text,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    marginTop: '30px',
  },
  datePickerArrowDown: {
    fontSize: '23px',
    color: 'hsl(0,0%,80%)',
    marginLeft: '8px',
    marginRight: '7px',
    marginTop: '1px',
  },
  nextButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '92%',
  },
  textarea: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 4,
    padding: 6,
    resize: 'none',
    overflowX: 'scroll',
    overflowY: 'none',
    outline: 'none',
    minHeight: 100,
    width: '100%',
    fontSize: '0.9rem',
    color: colors.text,
  },
};

const filterConfig = {
  matchFrom: 'any',
};

const { arrayOf, oneOfType, string, shape, func, object, bool } = PropTypes;

class RemoveCandidate extends Component {
  static propTypes = {
    candidateKey: string.isRequired,
    candidateName: string.isRequired,
    removalReasons: arrayOf(string).isRequired,
    cancellationReasons: arrayOf(string).isRequired,
    isFetchingCandidateWorkHistory: bool,
    candidateWorkHistory: arrayOf(object),
    isRemovingCandidate: bool.isRequired,
    isCancellingRemoval: bool.isRequired,
    removeCandidateFromBank: func.isRequired,
    cancelRemoveCandidateFromBank: func.isRequired,
    candidateRemovalError: bool,
    dismissCandidateRemovalError: func.isRequired,
    datePickerOpenDirection: string.isRequired,
    cancelRemoveFromBank: bool,
    reinstateCandidate: func.isRequired,
  }

  static defaultProps = {
    candidateWorkHistory: [],
    isFetchingCandidateWorkHistory: false,
    candidateRemovalError: null,
    cancelRemoveFromBank: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentScreen: 'removeFromBankScreenOne',
      removalReason: '',
      cancellationReason: '',
      removalDate: '',
      datePickerFocused: false,
    };
  }

  componentDidMount() {
    this.props.fetchCandidateShifts(this.props.candidateKey);
  }

  componentWillUnmount() {
    // Clear network error
    if (this.props.candidateRemovalError) this.props.dismissCandidateRemovalError();
  }

  goToShift = (jobId, startTime) => {
    const shiftDate = moment(startTime).format('DD-MM-YYYY');
    // Open shift in new browser tab
    window.open(`/jobs/bank/${shiftDate}?${jobId}`, '_blank');
  }

  getShiftsAfterRemovalDate = (shifts) => {
    return Object.values(shifts).filter(shift => shift.startTime && moment.utc(shift.startTime, moment.ISO_8601).isAfter(this.state.removalDate))
      .sort((a, b) => moment(a.startTime) - moment(b.startTime));
  }

  removeFromBank = async () => {
    const { candidateKey, candidateRemovalReasonTypeIsList } = this.props;
    await this.props.removeCandidateFromBank(
      candidateKey,
      candidateRemovalReasonTypeIsList ? this.state.removalReason.label : this.state.removalReason,
      this.state.removalDate.toISOString(),
    );
    this.setState({ currentScreen: 'removeFromBankScreenOne', removalReason: '', removalDate: '' });
  }

  cancelRemoveFromBank = async () => {
    this.props.cancelRemoveCandidateFromBank(this.props.candidateKey, this.state.cancellationReason.label);
    this.setState({ cancellationReason: '' });
  }

  changeReason = (data) => {
    const removalReason = data.target ? data.target.value : '';
    this.setState({ removalReason });
  }

  render() {
    const { candidateName, candidateWorkHistory, removedAt } = this.props;
    const applications = candidateWorkHistory ? this.getShiftsAfterRemovalDate(candidateWorkHistory).filter(shift => shift.isApplication) : [];
    const bookings = candidateWorkHistory ? this.getShiftsAfterRemovalDate(candidateWorkHistory).filter(shift => !shift.isApplication) : [];
    const candidateHasBeenRemoved = removedAt && moment(removedAt) < moment();
    const candidateWillBeRemoved = removedAt && !candidateHasBeenRemoved;

    return (
      <>
        {!removedAt && this.state.currentScreen === 'removeFromBankScreenOne' && (
          <RemoveFromBankScreenOne
            removalReasons={this.props.removalReasons}
            setRemovalReason={removalReason => this.setState({ removalReason })}
            setDate={removalDate => this.setState({ removalDate })}
            removalReason={this.state.removalReason}
            removalDate={this.state.removalDate}
            datePickerFocused={this.state.datePickerFocused}
            changeFocus={focused => this.setState({ datePickerFocused: focused })}
            toggleFocus={() => this.setState(prevState => ({ datePickerFocused: !prevState.datePickerFocused }))}
            changeScreen={currentScreen => this.setState({ currentScreen })}
            datePickerOpenDirection={this.props.datePickerOpenDirection}
            changeReason={reason => this.changeReason(reason)}
            candidateRemovalReasonTypeIsList={this.props.candidateRemovalReasonTypeIsList}
          />
        )}
        {!removedAt && this.state.currentScreen === 'removeFromBankScreenTwo' && (
          <RemoveFromBankScreenTwo
            isFetchingCandidateWorkHistory={this.props.isFetchingCandidateWorkHistory}
            isRemovingCandidate={this.props.isRemovingCandidate}
            candidateName={candidateName}
            removalDate={this.state.removalDate}
            removalReason={this.state.removalReason}
            bookings={bookings}
            applications={applications}
            goToShift={this.goToShift}
            clearForm={currentScreen => this.setState({ currentScreen, removalReason: '', removalDate: '' })}
            removeFromBank={this.removeFromBank}
            candidateRemovalReasonTypeIsList={this.props.candidateRemovalReasonTypeIsList}
          />
        )}
        {candidateWillBeRemoved && (
          <CancelRemoveFromBankScreen
            isCancellingRemoval={this.props.isCancellingRemoval}
            candidateName={candidateName}
            cancellationReasons={this.props.cancellationReasons}
            setCancellationReason={cancellationReason => this.setState({ cancellationReason })}
            cancellationReason={this.state.cancellationReason}
            clearForm={() => this.setState({ cancellationReason: '' })}
            cancelRemoveFromBank={this.cancelRemoveFromBank}
            removedAt={removedAt}
          />
        )}
        {candidateHasBeenRemoved && (
          <div style={{ marginTop: '10px' }}>
            <p>{`${candidateName} has been removed from the staff bank.`}</p>
            <Button
              style={{ ...styles.confirmButtons, backgroundColor: colors.positive }}
              onClick={() => this.props.reinstateCandidate(this.props.candidateKey)}
              disabled={this.props.isRemovingCandidate}
            >
              Reinstate Candidate
            </Button>
          </div>
        )}
      </>
    );
  }
}

const RemoveFromBankScreenOne = props => (
  <>
    <div style={{ marginTop: '10px' }}>
      <p style={{ fontSize: '0.9rem', color: colors.text }}>Remove candidate from bank. Prevent candidate applying for available shifts and unassign from all booked shifts beyond a specified date.</p>
    </div>
    <div style={styles.inputContainer}>
      <div style={styles.selectBoxContainer}>
        <div style={styles.labelContainer}>
          <p style={styles.labelText}>Reason</p>
        </div>
        <div style={styles.selectBox}>
          {props.candidateRemovalReasonTypeIsList ?
            <Select
              filterOption={customFilter(filterConfig)}
              placeholder="Select Reason..."
              options={(props.removalReasons).map(option => ({ label: option, value: option }))}
              onChange={removalReason => props.setRemovalReason(removalReason)}
              value={props.removalReason}
            />
            :
            <TextAreaAutosize
              style={styles.textarea}
              value={props.removalReason || ''}
              onChange={val => props.changeReason(val)}
              placeholder="Reason for removal (required)"
            />
        }
        </div>
      </div>
      <div style={styles.selectBoxContainer}>
        <div style={styles.labelContainer}>
          <p style={styles.labelText}>Date of removal</p>
        </div>
        <div className="remove_account_select">
          <SingleDatePicker
            placeholder="Select Date..."
            date={props.removalDate ? moment(props.removalDate) : null}
            onDateChange={removalDate => props.setDate(moment(removalDate))}
            focused={props.datePickerFocused}
            onFocusChange={({ focused }) => props.changeFocus(focused)}
            numberOfMonths={2}
            firstDayOfWeek={1}
            isOutsideRange={isBeforeToday}
            openDirection={props.datePickerOpenDirection}
            id="id"
            disabled={!props.removalReason}
            displayFormat="Do MMMM YYYY"
            hideKeyboardShortcutsPanel
          />
          <DatePickerArrowBox
            onClick={() => props.removalReason && props.toggleFocus()}
            datePickerFocused={props.datePickerFocused}
          />
        </div>
      </div>
    </div>
    <div style={styles.nextButtonContainer}>
      <Button
        black
        shadow={false}
        onClick={() => props.changeScreen('removeFromBankScreenTwo')}
        disabled={!props.removalReason || !props.removalDate}
      >
        Next
      </Button>
    </div>
  </>
);

RemoveFromBankScreenOne.propTypes = {
  removalReasons: arrayOf(string).isRequired,
  setRemovalReason: func.isRequired,
  removalReason: oneOfType([
    string,
    shape({ label: string, value: string }),
  ]).isRequired,
  removalDate: oneOfType([
    object,
    string,
  ]).isRequired,
  setDate: func.isRequired,
  datePickerFocused: bool.isRequired,
  changeFocus: func.isRequired,
  toggleFocus: func.isRequired,
  changeScreen: func.isRequired,
  datePickerOpenDirection: string.isRequired,
};

const RemoveFromBankScreenTwo = props => (
  <>
    {props.isFetchingCandidateWorkHistory ?
      <div style={{ textAlign: 'center' }}>
        <Loading />
      </div>
      :
      <>
        {props.isRemovingCandidate && (
          <div style={{ textAlign: 'center' }}>
            <Loading />
          </div>
        )}
        {!props.isRemovingCandidate && (
          <>
            <div className="space-children-6--bottom" style={styles.removeFromBankMessageContainer}>
              <p>{`${props.candidateName} will be removed from the staff bank on ${moment(props.removalDate).format('Do MMMM YYYY')}.`}</p>
              <p> {`The reason you have given is: ${props.candidateRemovalReasonTypeIsList ? props.removalReason.label : props.removalReason}`}.</p>
            </div>
            {props.bookings.length > 0 && (
              <ShiftsList
                shifts={props.bookings}
                goToShift={props.goToShift}
                shiftMessage="The candidate will be cancelled from the following bookings:"
              />
            )}
            {props.applications.length > 0 && (
              <ShiftsList
                shifts={props.applications}
                goToShift={props.goToShift}
                shiftMessage="The candidate will be withdrawn from the following applications:"
              />
            )}
          </>
        )}
        <div style={styles.footerContainer}>
            <Button
              style={{ marginRight: spacing.base }}
              onClick={() => props.clearForm('removeFromBankScreenOne')}
              disabled={props.isRemovingCandidate}
              white
              outline
              shadow={false}
            >
              Cancel
            </Button>
            <Button
              black
              shadow={false}
              onClick={() => props.removeFromBank()}
              disabled={props.isRemovingCandidate}
            >
              {props.isRemovingCandidate ? 'Removing From Bank' : 'Remove From Bank'}
            </Button>
        </div>
      </>
    }
  </>
);

RemoveFromBankScreenTwo.propTypes = {
  isFetchingCandidateWorkHistory: bool.isRequired,
  isRemovingCandidate: bool.isRequired,
  candidateName: string.isRequired,
  removalReason: shape({ label: string, value: string }),
  bookings: arrayOf(object),
  applications: arrayOf(object),
  goToShift: func.isRequired,
  clearForm: func.isRequired,
  removeFromBank: func.isRequired,
  removalDate: oneOfType([
    object,
    string,
  ]).isRequired,
};

RemoveFromBankScreenTwo.defaultProps = {
  removalReason: '',
  bookings: [],
  applications: [],
};

const CancelRemoveFromBankScreen = props => (
  <>
    {props.isCancellingRemoval && (
      <div style={{ textAlign: 'center' }}>
        <Loading />
      </div>
    )}
    {!props.isCancellingRemoval && (
      <>
        <div className="space-children-6--bottom" style={styles.removeFromBankMessageContainer}>
          <p>{props.removedAt < moment.utc().toISOString() ? `${props.candidateName} was removed from the staff bank on ${moment(props.removedAt).format('Do MMMM YYYY')}.` : `${props.candidateName} is currently scheduled for removal from the staff bank on ${moment(props.removedAt).format('Do MMMM YYYY')}.`}</p>
          <p>{props.removedAt > moment.utc().toISOString() ? `Click confirm to cancel the removal and add ${props.candidateName} back to the bank.` : null}</p>
        </div>
      </>
    )}
    <div style={styles.nextButtonContainer}>
      <Button
        black
        shadow={false}
        onClick={() => props.cancelRemoveFromBank()}
        disabled={props.isCancellingRemoval}
      >
        {props.isCancellingRemoval ? 'Confirming...' : 'Confirm'}
      </Button>
    </div>
  </>
);

CancelRemoveFromBankScreen.propTypes = {
  isCancellingRemoval: bool.isRequired,
  candidateName: string.isRequired,
  cancellationReasons: arrayOf(string).isRequired,
  setCancellationReason: func.isRequired,
  cancellationReason: oneOfType([
    string,
    shape({ label: string, value: string }),
  ]).isRequired,
  clearForm: func.isRequired,
  cancelRemoveFromBank: func.isRequired,
};

function mapStateToProps({ global, candidate, compliance }) {
  const candidateRemovalReasonTypeIsList = global.orgConfig.candidateRemovalReasonType?.type === 'list';
  const mainOrg = candidate.profile?.orgs?.[candidate.profile?.mainOrgKey];
  return {
    removalReasons: candidateRemovalReasonTypeIsList ? global.orgConfig.candidateRemovalReasonType?.options : [],
    candidateKey: candidate.profile?.key,
    candidateName: candidate.profile?.name ?? 'The candidate',
    cancellationReasons: global.orgConfig.candidateCancelRemovalReasons?.options || [],
    isRemovingCandidate: compliance.isRemovingCandidate,
    isCancellingRemoval: compliance.isCancellingRemoval,
    candidateWorkHistory: candidate.candidateShifts,
    isFetchingCandidateWorkHistory: candidate.isFetchingCandidateShifts,
    candidateRemovalError: compliance.candidateRemovalError,
    candidateRemovalReasonTypeIsList,
    removedAt: mainOrg.removedAt,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCandidateShifts: candidateKey => dispatch(fetchCandidateShifts(candidateKey)),
    removeCandidateFromBank: (candidateId, removalReason, removalDate) => dispatch(removeCandidateFromBank(candidateId, removalReason, removalDate)),
    cancelRemoveCandidateFromBank: (candidateId, cancellationReason) => dispatch(cancelRemoveCandidateFromBank(candidateId, cancellationReason)),
    dismissCandidateRemovalError: () => dispatch(dismissCandidateRemovalError()),
    reinstateCandidate: candidateKey => dispatch(reinstateCandidate(candidateKey)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveCandidate);
