import { v4 as uuidv4 } from 'uuid';
import * as orgsAndSitesReducer from '../reducers/orgsAndSites';
import * as db from '../lib/db';

export function uploadProfileImage(file) {
  return async (dispatch) => {
    dispatch(orgsAndSitesReducer.uploadProfilePic());
    const fileName = uuidv4();
    const storageRef = db.storage().ref(`/sitePhotos/${fileName}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(`Upload is ${progress}% done`);
      dispatch(orgsAndSitesReducer.updateProfilePicUploadProgress(progress));
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
        default:
          break;
      }
    }, (error) => {
      dispatch(orgsAndSitesReducer.uploadProfilePicError(error));
    }, () => {
      const { downloadURL } = uploadTask.snapshot;
      dispatch(orgsAndSitesReducer.uploadProfilePicSuccess(downloadURL, fileName));
      setTimeout(() => {
        dispatch(orgsAndSitesReducer.updateProfilePicUploadProgress(0));
      }, 500);
    });

  };
}

export function deleteProfileImage(fileName) {
  return async (dispatch) => {
    dispatch(orgsAndSitesReducer.DeleteProfilePic);
    const storageRef = db.storage().ref(`/sitePhotos/${fileName}`);
    storageRef.delete().then(() => {
      dispatch(orgsAndSitesReducer.DeleteProfilePicSuccess());
    }).catch((error) => {
      dispatch(orgsAndSitesReducer.DeleteProfilePicError(error));
    });
  };
}
