import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import SidePanel from 'components/SidePanel';
import SidePanelTabs from 'components/SidePanelTabs';
import Loading from 'components/Loading';

import { fetchPaymentRunDetails } from 'lib/api/timesheets';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { useFeatureFlag } from 'hooks/feature';
import colors from 'config/colors';
import spacing from 'config/spacing';
import { PayRunListViewType, PaymentRunDetailsShape } from '../../../types/PaymentRun';

import DetailsTab from './DetailsTab';
import ReportsTab from './ReportsTab';
import AuditTab from './AuditTab';
import EditPaymentRun from './EditPaymentRun';

const paymentRunTabs = [
  {
    label: 'Details',
    id: 'details',
  },
  {
    label: 'Reports',
    id: 'reports',
  },
  {
    label: 'Audit Trail',
    id: 'audit',
  },
];

const usePaymentRunDetails = (paymentRunKey: string) => {
  const [paymentRunDetails, setPaymentRunDetails] = useState(null as PaymentRunDetailsShape | null);
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [apiError, setApiError] = useState(null as string | null);

  const listViewPaymentRuns = useSelector(state => state.timesheets.paymentRuns);
  const listViewPaymentRunDetails = (listViewPaymentRuns ?? []).find((paymentRun: PayRunListViewType) => paymentRun.key === paymentRunKey);

  useEffect(() => {

    const fetchDetails = async () => {
      try {
        setIsFetchingDetails(true);
        const { paymentRun, audit, reports } = await fetchPaymentRunDetails(paymentRunKey);
        setPaymentRunDetails({ paymentRun, audit, reports });
        setIsFetchingDetails(false);

      } catch (error) {
        setApiError(error.message);
        setIsFetchingDetails(false);
      }
    };

    // Convert timestamp returned from Hasura query into same format as timestamp returned from Knex query
    const reduxUpdatedAt = (listViewPaymentRunDetails?.updatedAt && moment.utc(listViewPaymentRunDetails.updatedAt).format()) ?? null;
    const detailsUpdatedAt = paymentRunDetails?.paymentRun.updatedAt ?? null;
    const refetchFromHasura = reduxUpdatedAt && detailsUpdatedAt && reduxUpdatedAt > detailsUpdatedAt;
    const paymentRunKeyHasChanged = paymentRunDetails?.paymentRun.key && paymentRunDetails.paymentRun.key !== paymentRunKey;

    // Fetch details if running for first time, the payment run key as changed, or there is a more recent update from Hasura
    if (!paymentRunDetails || paymentRunKeyHasChanged || refetchFromHasura) {
      fetchDetails();
    }
  }, [paymentRunKey, listViewPaymentRunDetails]);

  return { paymentRunDetails, isFetchingDetails, apiError, setPaymentRunDetails };
};

interface PaymentRunDetailsProps {
  paymentRunKey: string,
  goToPaymentRunListView: () => void,
  goToPaymentRunEditForm: (paymentRunKey: string) => void,
  sidePanelTab: 'details' | 'reports' | 'audit' | 'edit',
  onTabClick: (tab: 'details' | 'reports' | 'audit') => void,
  goToPaymentRunDetailsView: (paymentRunKey: string) => void,
}

const PaymentRunDetails : React.FC<PaymentRunDetailsProps> = (props : PaymentRunDetailsProps) => {

  const customFieldsMetadata = useSelector(state => state.global.customFieldsMetadata);
  const timezone = useSelector(state => state.global.orgConfig?.timezone ?? 'Europe/London');
  const userId = useSelector(state => state.user.userId);

  // Call usePaymentRunDetails hook to fetch payment run details
  const { paymentRunDetails, isFetchingDetails, apiError, setPaymentRunDetails } = usePaymentRunDetails(props.paymentRunKey);

  const adminCanManagePaymentRuns = useFeatureFlag(null, 'payRunCanManage');

  if (isFetchingDetails) {
    return (
      <SidePanel
        vflex
        isOpen={!!props.paymentRunKey}
        closeSidePanel={props.goToPaymentRunListView}
        title="Payment Run Details"
      >
        <SidePanelTabs tabs={paymentRunTabs} selectedTab={props.sidePanelTab} onTabClick={tab => props.onTabClick(tab)} />
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>
      </SidePanel>
    );
  }

  if (apiError) {
    return (
      <SidePanel
        vflex
        isOpen={!!props.paymentRunKey}
        closeSidePanel={props.goToPaymentRunListView}
        title="Payment Run Details"
      >
        <SidePanelTabs tabs={paymentRunTabs} selectedTab={props.sidePanelTab} onTabClick={tab => props.onTabClick(tab)} />
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: spacing.small }}>
          <p style={{ color: colors.red }}>{apiError}</p>
        </div>
      </SidePanel>
    );
  }

  const paymentRunSendTime = paymentRunDetails?.paymentRun?.sentAt ?? paymentRunDetails?.paymentRun?.scheduledSendTime ?? null;

  return (
    <SidePanel
      vflex
      isOpen={!!props.paymentRunKey}
      closeSidePanel={props.goToPaymentRunListView}
      title={`Payment Run Details${paymentRunSendTime ? ` ${convertFromUtcToTimezone(paymentRunSendTime, timezone).format('DD/MM/YYYY')}` : ''}`}
    >
      {props.sidePanelTab === 'edit' && paymentRunDetails?.paymentRun ?
        <EditPaymentRun
          paymentRun={paymentRunDetails.paymentRun}
          onClose={() => props.goToPaymentRunDetailsView(paymentRunDetails.paymentRun.key)}
          setPaymentRunDetails={setPaymentRunDetails}
        />
        :
        <>
          <SidePanelTabs tabs={paymentRunTabs} selectedTab={props.sidePanelTab} onTabClick={tab => props.onTabClick(tab)} />

          {props.sidePanelTab === 'details' && paymentRunDetails?.paymentRun && (
            <DetailsTab
              paymentRun={paymentRunDetails.paymentRun}
              orgTimezone={timezone}
              customFieldsMetadata={customFieldsMetadata}
              adminCanManagePaymentRuns={adminCanManagePaymentRuns}
              setPaymentRunDetails={setPaymentRunDetails}
              goToPaymentRunEditForm={props.goToPaymentRunEditForm}
              goToPaymentRunListView={props.goToPaymentRunListView}
            />
          )}

          {props.sidePanelTab === 'reports' && paymentRunDetails?.reports && (
            <ReportsTab
              reports={paymentRunDetails.reports}
              orgTimezone={timezone}
            />
          )}

          {props.sidePanelTab === 'audit' && paymentRunDetails?.audit && (
            <AuditTab
              auditTrail={paymentRunDetails.audit}
              orgTimezone={timezone}
              userId={userId}
            />
          )}
        </>
      }
    </SidePanel>
  );
};

export default PaymentRunDetails;
