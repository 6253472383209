import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useFeatureFlag } from 'hooks/feature';

import AdminManagementRoutes from '../AdminManagement/AdminManagementRoutes';
import SuppliersContainer from '../Suppliers/SuppliersContainer';
import SitesContainer from '../OrgAndSitesDetails/SitesContainer';
import RGSManageContainer from '../OrgAndSitesDetails/RGSManageContainer';
import OrgPoliciesContainer from '../Policies/OrgPoliciesContainer';
import SitesPoliciesContainer from '../Policies/SitesPoliciesContainer';
import PaymentRatesContainer from './PaymentRates/PaymentRatesContainer';
import AdminGroupList from './AdminGroups/AdminGroupList';
import AdminGroupDetails from './AdminGroups/AdminGroupDetails/AdminGroupDetails';

const SettingsRoutes = ({ match, history }) => {

  const settingsRouteUrl = match.path;
  const paymentRatesFeatureIsOn = useFeatureFlag('paymentRuns', 'payRunCanView');
  const showAdminGroups = useFeatureFlag(null, 'usersCanView');

  return (
    <Switch>

      <Route path={`${settingsRouteUrl}/admins`} component={AdminManagementRoutes} />
      <Route path={`${settingsRouteUrl}/suppliers`} component={SuppliersContainer} />
      <Route path={`${settingsRouteUrl}/rgs`} component={RGSManageContainer} />

      <Route path={`${settingsRouteUrl}/sites`} component={SitesContainer} />
      <Route path={`${settingsRouteUrl}/sites`} component={SitesContainer} />

      <Route path={`${settingsRouteUrl}/policies/org`} component={OrgPoliciesContainer} />
      <Route path={`${settingsRouteUrl}/policies/sites`} component={SitesPoliciesContainer} />

      {paymentRatesFeatureIsOn && (
        <Route path={`${settingsRouteUrl}/payment-rates`} component={PaymentRatesContainer} />
      )}

      {showAdminGroups && (
        <Route
          path={`${settingsRouteUrl}/admin-groups/:adminGroupKey?`}
          render={(routeParams) => {
            const { adminGroupKey } = routeParams.match.params;

            return (
              <>
                <AdminGroupList
                  goToDetailsView={(groupKey) => history.push(`/settings/admin-groups/${groupKey}`)}
                />
                {adminGroupKey && (
                  <AdminGroupDetails
                    adminGroupKey={adminGroupKey}
                    onClose={() => history.replace('/settings/admin-groups')}
                  />
                )}
              </>
            );
          }}
        />
      )}
    </Switch>
  );
};

export default SettingsRoutes;
