const FETCH_USER = 'FETCH_USER';
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR';
const ADD_EMPLOYER_DATA = 'ADD_CANDIDATE_DATA';
const LOGOUT = 'LOGOUT';
const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
const CLEAR_ERROR = 'CLEAR_ERROR';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const CANCEL_FORGOT_PASSWORD = 'CANCEL_FORGOT_PASSWORD';
const PASSWORD_RESET_DETAILS = 'PASSWORD_RESET_DETAILS';
const PASSWORD_IS_RESET = 'PASSWORD_IS_RESET';
const LAND_FROM_MOBILE = 'LAND_FROM_MOBILE';
const SET_LAST_ACTIVE = 'SET_LAST_ACTIVE';
const SET_USER_ID = 'SET_USER_ID';
const ADD_ADMIN_GROUPS = 'ADD_ADMIN_GROUPS';
const ADD_ADMIN_SITES = 'ADD_ADMIN_SITES';
const UPDATE_ADMIN_SITES = 'UPDATE_ADMIN_SITES';

// Array of action type to trigger persisting to localstorage
export const PERSIST_ACTIONS = [
  SET_USER_ID,
  FETCH_USER_SUCCESS,
  LOGOUT,
];

export const addAdminGroups = adminGroups => ({ type: ADD_ADMIN_GROUPS, adminGroups });
export const addEmployerData = (data) => ({ type: ADD_EMPLOYER_DATA, data });

export const setUserId = id => ({ type: SET_USER_ID, id });

export const fetchUser = () => ({ type: FETCH_USER });
export const fetchUserSuccess = (id) => ({ type: FETCH_USER_SUCCESS, id });
export const fetchUserError = (error) => ({ type: FETCH_USER_ERROR, error });

export const setRedirectUrl = (redirectUrl) => ({ type: SET_REDIRECT_URL, redirectUrl });
export const logout = () => ({ type: LOGOUT });

export const clearError = () => ({ type: CLEAR_ERROR });

export const forgotPassword = () => ({ type: FORGOT_PASSWORD });
export const cancelForgotPassword = () => ({ type: CANCEL_FORGOT_PASSWORD });

export const verifyTokenError = (error) => ({ type: VERIFY_TOKEN_ERROR, error });
export const passwordResetDetails = (email, requirements) => ({ type: PASSWORD_RESET_DETAILS, email, requirements });
export const passwordIsReset = (isReset = true) => ({ type: PASSWORD_IS_RESET, isReset });
export const landFromMobile = () => ({ type: LAND_FROM_MOBILE });

export const setLastActive = (when) => ({ type: SET_LAST_ACTIVE, when });

export const addAdminSites = sites => ({ type: ADD_ADMIN_SITES, sites });
export const updateAdminSites = sites => ({ type: UPDATE_ADMIN_SITES, sites });

const initialState = {
  isFetchingUser: false,
  userId: null,
  userData: null,
  error: null,
  features: null,
  knowsPassword: true,
  passwordResetEmail: null,
  passwordResetRequirements: null,
  passwordIsReset: false,
  landFromMobile: false,
  lastActive: null,
  adminGroups: null,
  sites: null,
};

export default function candidate(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.id,
      };
    case FETCH_USER:
      return {
        ...state,
        isFetchingUser: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        userId: action.id,
        error: null,
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        isFetchingUser: false,
        error: action.error,
      };
    case ADD_EMPLOYER_DATA:
      return {
        ...state,
        userData: action.data,
        error: null,
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.redirectUrl,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        knowsPassword: false,
      };
    case CANCEL_FORGOT_PASSWORD:
      return {
        ...state,
        knowsPassword: true,
      };
    case VERIFY_TOKEN_ERROR:
      return { ...state, verifyTokenError: action.error };
    case PASSWORD_RESET_DETAILS:
      return {
        ...state,
        passwordResetEmail: action.email,
        passwordResetRequirements: action.requirements,
      };
    case PASSWORD_IS_RESET:
      return {
        ...state,
        passwordIsReset: action.isReset,
      };
    case LAND_FROM_MOBILE:
      return {
        ...state,
        landFromMobile: true,
      };
    case SET_LAST_ACTIVE:
      return {
        ...state,
        lastActive: action.when,
      };
    case ADD_ADMIN_GROUPS:
      return { ...state, adminGroups: action.adminGroups };
    case ADD_ADMIN_SITES:
      return { ...state, sites: action.sites };
    case UPDATE_ADMIN_SITES: {
      const updatedSites = {};
      Object.entries(action.sites).forEach(([siteKey, siteDetails]) => {
        if (siteDetails.enabled) {
          updatedSites[siteKey] = siteDetails;
        }
      });
      return { ...state, sites: updatedSites };
    }
    default:
      return state;
  }
}
