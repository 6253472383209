
// Allows typescript to understand that we are filtering out falsy values from an array
export function isTruthy<T>(input: T | null | undefined | false | 0 | ''): input is T {
  return !!input;
}

// Allows typescript to understand that we are filtering out falsy values from an array
export function isNonNullish<T>(input: T | null | undefined): input is T {
  return input !== null && input !== undefined;
}
