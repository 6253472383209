import React from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import { capitalise } from '../../lib/helpers-text';
import colors from '../../config/colors';
import Button from '../Button';

//----------------------------------------------------------------
// EXPORT
//----------------------------------------------------------------

const defaultStyles = {
  mainContainer: {
    marginBottom: spacing.tiny,
    paddingBottom: spacing.small,
    borderBottom: `1px solid ${colors.grays.ultraLight}`,
    textAlign: 'right',
  },
  button: {
    marginLeft: spacing.small,
    paddingRight: spacing.small,
    paddingLeft: spacing.small,
    backgroundColor: colors.positive,
    color: colors.white,
  },
};

export default function ButtonContainer(props) {

  const { buttonStyles, currentState, states } = props;
  const stateObj = states[currentState];
  return (
    <div style={{ ...defaultStyles.mainContainer, textAlign: stateObj.alignButtons }}>
      {
        stateObj.buttons.map((buttonConfig, idx) => {
          const buttonKey = `${props.containerID}.button${idx}`;
          const buttonText = props.uppercase ?
            buttonConfig.buttonText.toUpperCase() :
            capitalise(buttonConfig.buttonText);
          const extraButtonStyles = buttonConfig.extraStyles || {};
          return (
            <Button
              key={buttonKey}
              onClick={() => buttonConfig.handleButtonClicked()}
            >
              {buttonText}
            </Button>
          );
        })
      }
    </div>
  );
}

const { bool, string, object, objectOf, arrayOf, shape, func } = PropTypes;


// NOTE: containerId only needed if > 1 on page

ButtonContainer.propTypes = {
  buttonStyles: object,
  containerId: string,
  currentState: string.isRequired,
  states: objectOf(shape({
    alignButtons: string,
    buttonStyles: object,
    buttons: arrayOf(shape({
      buttonText: string.isRequired,
      extraStyles: object,
      handleButtonClicked: func.isRequired,
    })).isRequired,
  })).isRequired,
  rise: bool,
  small: bool,
  uppercase: bool,
  alignButtons: string,
};

ButtonContainer.defaultProps = {
  alignButtons: 'right',
  containerId: 'buttonContainer',
  buttonStyles: defaultStyles.button,
  rise: true,
  small: false,
  uppercase: true,
};
