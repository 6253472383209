import { useState, useRef, useCallback, useLayoutEffect, RefObject } from 'react';

interface ReturnProps {
  containerEl: RefObject<HTMLDivElement>,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function useDropdownMenu(): ReturnProps {

  const [isOpen, setIsOpen] = useState(false);

  // Global click handler (close menu if you click outside of it)
  const containerEl = useRef<HTMLDivElement>(null);
  const handleGlobalClick = useCallback((event) => {
    // Ignore clicks on this component
    let node = event.target;
    while (node) {
      if (node === containerEl.current) return;
      node = node.parentNode;
    }
    setIsOpen(false);
  }, [containerEl.current]);

  useLayoutEffect(() => {
    document.addEventListener('click', handleGlobalClick, false);
    return () => document.removeEventListener('click', handleGlobalClick);
  });

  return { isOpen, setIsOpen, containerEl };
}
