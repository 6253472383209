import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../../components/Page';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Loading from '../../../components/Loading';
import BySpecialityReport from '../Reports/BySpecialityReport';
import DrillDownTable from '../DrillDown/DrillDownTable';
import ReportingHeader from '../ReportingHeader';

const getHeader = (reportName) => {
  switch (reportName) {
    case 'spend':
      return 'Temporary Staffing Spend By Role';
    case 'requests':
      return 'Requests By Role';
    case 'fillRate':
      return 'Fill Rate By Role';
    default:
      return '';
  }
};

export default function Specialities(props) {

  const bySpecialityReportName = `${props.reportName}BySpeciality`;

  const bySpecialityReport = props.reports[bySpecialityReportName];

  const isloadingReport = props.loading[bySpecialityReportName];

  return (
    <Page
      title="By Speciality"
      loading={isloadingReport}
    >
      <ReportingHeader selectedTabKey={props.reportName} />
      <DashboardHeader />

      {isloadingReport && (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90%' }}
        >
          <Loading />
        </div>
      )}

      {!isloadingReport && props.specialitiesBreakdown && (
        <BySpecialityReport
          isloadingReport={isloadingReport}
          report={bySpecialityReport}
          reportName={props.reportName}
          bySpecialityReportName={bySpecialityReportName}
          reportError={props.reportError[bySpecialityReportName]}
          clearReportingError={props.clearReportingError}
          selectedRoleName={props.selectedRole.name}
          header={getHeader(props.reportName)}
          drillDownComponent={(reportProps) => {
            return (
              <DrillDownTable {...reportProps} />
            );
          }}
        />
      )}
    </Page>
  );
}

const { object, objectOf, shape, bool, func, string } = PropTypes;

Specialities.propTypes = {
  reports: objectOf(object).isRequired,
  loading: shape({
    fillRate: bool.isRequired,
    fillRateByRole: bool.isRequired,
    fillRateBySpeciality: bool.isRequired,
    requests: bool.isRequired,
    requestsByRole: bool.isRequired,
    requestsBySpeciality: bool.isRequired,
    spend: bool.isRequired,
    spendByRole: bool.isRequired,
    spendBySpeciality: bool.isRequired,
    activity: bool.isRequired,
  }).isRequired,
  reportName: string.isRequired,
  specialitiesBreakdown: bool.isRequired,
  reportError: shape({
    fillRate: string,
    fillRateByRole: string,
    fillRateBySpeciality: string,
    requests: string,
    requestsByRole: string,
    requestsBySpeciality: string,
    spend: string,
    spendByRole: string,
    spendBySpeciality: string,
    activity: string,
  }).isRequired,
  clearReportingError: func.isRequired,
  selectedRole: shape({ key: string, name: string }),
};

Specialities.defaultProps = {
  selectedRole: {},
};
