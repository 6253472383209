import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import AuditTrail from '../AuditTrail';
import ScrollView from '../ScrollView';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import './AuditTrailModal.css';

const styles = {
  auditTrailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
  },
  auditTrailText: {
    padding: spacing.base,
  },
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    margin: spacing.base,
    textAlign: 'center',
  },
  userContainer: {
    padding: spacing.base,
  },
  timestampContainer: {
    padding: spacing.base,
  },
};

export default function AuditTrailModal(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentLabel={props.contentLabel}
      contentStyle={{ overflow: 'hidden' }}
    >
      <div style={{ maxHeight: 600 }}>
        <div
          style={{ textAlign: 'center' }}
        >
          <h2 style={styles.header}>Audit Trail</h2>
        </div>
        <ScrollView className="contents" fadeAtEnd>
          <AuditTrail
            items={props.messages.map((message) => {
              return {
                title: props.userId === message.fromUserId ? 'You' : message.fromUserName,
                message: message.text,
                when: message.when,
                imageUrl: message.imageUrl || message.profileImageUrl,
              };
            })}
          />
        </ScrollView>
      </div>
    </Modal>
  );
}

const { bool, func, string, arrayOf, object } = PropTypes;
AuditTrailModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  contentLabel: string.isRequired,
  messages: arrayOf(object).isRequired,
  userId: string,
};

AuditTrailModal.defaultProps = {
  userId: null,
};
