import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import colors from 'config/colors';
import spacing from 'config/spacing';

import * as userThunks from 'thunks/user';
import * as userReducer from 'reducers/user';

import AuthPage from './ui/AuthPage';
import LoginForm from './ui/LoginForm';
import RequestPasswordReset from './RequestPasswordReset';


const styles = {
  messageContainer: {
    borderRadius: 2,
    textAlign: 'center',
    color: colors.text,
    fontSize: '0.9rem',
    paddingTop: 6,
  },
};

const { string, func, bool } = PropTypes;
class LoginContainer extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    loginError: string,
    knowsPassword: bool.isRequired,
    forgotPassword: func,
    cancelForgotPassword: func,
    sendResetPasswordEmail: func,
    isFetchingUser: bool,
  }

  static defaultProps = {
    loginError: null,
    forgotPassword: null,
    cancelForgotPassword: null,
    sendResetPasswordEmail: null,
    isFetchingUser: null,
  }

  constructor() {
    super();
    this.state = {
      formValues: {},
    };
  }

  async componentDidMount() {
    if (this.props.isLoggedIn) {
      try {
        await this.logout(false);
      } catch (e) {
        console.warn('Logout failed', e);
      }
    }
  }

  setFormValue = (key, value) => {
    this.setState(state => ({
      ...state,
      formValues: { ...state.formValues, [key]: value },
    }));
  }

  login = async () => {

    setTimeout(() => {
      this.props.dispatch(userReducer.clearError());
    }, 5000);

    let success = false;

    // Be super careful with async/await
    try {
      success = await this.props.dispatch(userThunks.signIn(this.state.formValues.email, this.state.formValues.password));
    } catch (e) {
      success = false;
    }

    if (success) {
      const { history, location } = this.props;
      const redirectUrlState = location?.state?.redirectUrl ?? null;
      const redirectUrl = redirectUrl?.state?.redirectUrl ?? redirectUrl?.pathname ?? '/';
      history.push(redirectUrl);
    }

    return false; // To stop browser from actually submitting form
  }

  logout = (redirect = true) => {
    this.props.dispatch(userThunks.logout(redirect));
  }

  forgotPassword = () => {
    this.props.dispatch(userReducer.forgotPassword());
  }

  cancelForgotPassword = () => {
    this.props.dispatch(userReducer.cancelForgotPassword());
  }

  sendResetPasswordEmail = () => {
    this.props.dispatch(userReducer.clearError());
    this.props.dispatch(userThunks.sendResetPasswordEmail(this.state.formValues.email, 'web-app'));
  }

  render() {
    const loading = !!(this.props.knowsPassword && this.props.isFetchingUser);
    const header = this.props.knowsPassword ? 'Management Console' : 'Password Recovery';
    const loginMessage = this.props.location?.state?.loginMessage ?? '';

    return (
      <AuthPage loading={loading}>
        {this.props.knowsPassword && (
          <LoginForm
            message={loginMessage}
            login={this.login}
            isFetchingUser={this.props.isFetchingUser}
            forgotPassword={this.forgotPassword}
            formValues={this.state.formValues}
            setFormValue={this.setFormValue}
          />
        )}
        {!this.props.knowsPassword && (
          <RequestPasswordReset
            disabled={!this.state.formValues?.email}
            sendResetPasswordEmail={this.sendResetPasswordEmail}
            cancelForgotPassword={this.cancelForgotPassword}
            formValues={this.state.formValues}
            setFormValue={this.setFormValue}
          />
        )}
        {!!this.props.loginError && (
          <div style={styles.messageContainer}>
            <p>{this.props.loginError}</p>
          </div>
        )}
      </AuthPage>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    loginError: user.error,
    knowsPassword: user.knowsPassword,
    isLoggedIn: !!user.userId,
    isFetchingUser: user.isFetchingUser,
  };
}

export default withRouter(connect(mapStateToProps)(LoginContainer));
