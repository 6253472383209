import { CreateTemplateShift, TemplateDraftShift } from 'types/ShiftTypes';
import { reportError } from '../error-reporting';
import * as api from '.';

interface CreateTemplateArgs {
  templateDrafts: TemplateDraftShift[],
  name: string,
  periodType: 'day' | 'week' | 'month',
}

function convertDraftToTemplateApiFormat(shift: TemplateDraftShift) : CreateTemplateShift {
  return {
    key: shift.key,
    siteKey: shift.site?.id ?? null,
    areaKey: shift.area?.id ?? null,
    roleKey: shift.role?.id ?? null,
    gradeKey: shift.grade?.id ?? null,
    specialityKey: shift.speciality?.id ?? null,
    reasonKey: shift.reason?.id ?? null,
    subReasonKey: shift.reason2?.id ?? null,
    preferredGenderKey: shift.preferredGender?.id ?? null,
    publicDescription: shift.publicDescription,
    privateNotes: shift.privateNotes,
    startTimeOfDay: shift.startTimeOfDay,
    endTimeOfDay: shift.endTimeOfDay,
    dayOfWeek: shift.dayOfWeek?.id ?? null,
    targetedCandidateKeys: shift.candidates.map(candidate => candidate.id),
    rateCardKey: shift.rateCard?.key ?? null,
    customHourlyRate: shift.rateCard?.requireCustomHourlyRate && shift.customHourlyRate ? shift.customHourlyRate : null,
    bankKeys: shift.bankKeys,
    serviceKey: shift.service?.id ?? null,
    slotsRequired: shift.slotsRequired,
  };
}

export async function createTemplate({ templateDrafts, name, periodType }: CreateTemplateArgs) {

  // Convert template drafts into format required in api
  const shiftsForApiPayload = templateDrafts.map(shift => convertDraftToTemplateApiFormat(shift));

  const response = await api.post('templates/create', { shifts: shiftsForApiPayload, name, periodType });

  const EXPECTED_RESPONSE_PROPS = ['key', 'name', 'periodType', 'shifts'];
  const EXPECTED_FAILED_RESPONSE_PROPS = ['validatedShifts', 'failedShifts', 'humanReadableErrorMessage'];

  // If response successful and contains all expected props, return body
  if (response?.body?.success && EXPECTED_RESPONSE_PROPS.every(prop => response?.body?.[prop] !== undefined)) return response.body;
  if (EXPECTED_FAILED_RESPONSE_PROPS.every(prop => response?.body?.details?.[prop] !== undefined)) return response.body.details;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to save template.');
  reportError(error, {
    api: {
      path: 'templates/create',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
      payload: { shifts: shiftsForApiPayload, name, periodType },
    },
  });
  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to create template.');
}

export async function saveTemplate({ key, name, templateDrafts, periodType }: CreateTemplateArgs & { key: string }) {

  // Convert template drafts into format required in api
  const shiftsForApiPayload = templateDrafts.map(shift => convertDraftToTemplateApiFormat(shift));

  const response = await api.post('templates/update', { key, shifts: shiftsForApiPayload, name, periodType });

  const EXPECTED_SUCCESS_RESPONSE_PROPS = ['key', 'name', 'periodType', 'shifts'];
  const EXPECTED_FAILED_RESPONSE_PROPS = ['validatedShifts', 'failedShifts', 'humanReadableErrorMessage'];

  // If response successful and contains all expected props, return body
  if (response?.body?.success && EXPECTED_SUCCESS_RESPONSE_PROPS.every(prop => response?.body?.[prop] !== undefined)) return response.body;
  if (EXPECTED_FAILED_RESPONSE_PROPS.every(prop => response?.body?.details?.[prop] !== undefined)) return response.body.details;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to save template.');
  reportError(error, {
    api: {
      path: 'templates/update',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
      payload: { key, shifts: shiftsForApiPayload, name, periodType },
    },
  });

  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to save template.');
}

export async function fetchTemplate(templateKey: string) {

  const response = await api.get('templates/get', { templateKey });

  if (response?.body?.success && response?.body?.template) return response.body;
  if (response?.body?.templateIncludesDisabledSites && response?.body?.humanReadableErrorMessage) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to fetch template.');
  reportError(error, {
    api: {
      path: 'templates/get',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
      payload: { templateKey },
    },
  });
  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to fetch template details. If the problem persists, please contact customer support.');
}

export async function fetchTemplateList() {

  const response = await api.get('templates/list');

  if (response?.body?.success && response?.body?.templates) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to fetch templates.');
  reportError(error, {
    api: {
      path: 'templates/list',
      status: response?.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
    },
  });
  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to fetch templates. If the problem persists, please contact customer support.');
}

export async function deleteTemplate(templateKey: string) {

  const response = await api.post('templates/delete', { templateKey });

  if (response?.body?.success) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to delete template.');
  reportError(error, {
    api: {
      path: 'templates/delete',
      status: response?.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stackTrace ?? null,
      payload: { templateKey },
    },
  });
  throw new Error(response?.body?.details?.humanReadableErrorMessage ?? 'Failed to delete template. If the problem persists, please contact customer support.');
}
