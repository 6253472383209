import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import NumberFormatter from './NumberFormatter';
import UnitsFormatter from './UnitsFormatter';
import XAxisAtAngle from './XAxisAtAngle';
import XAxisVertical from './XAxisVertical';
import Trend from './Trend';
import colors from '../../../config/colors';
import { capitalize } from '../../../lib/helpers';

import './styles.scss';

const { object, bool, func, string, number, oneOf } = PropTypes;

export default function ByBarChart(props) {
  const yAxisProps = {};

  if (props.dataMax) {
    yAxisProps.domain = [0, 'dataMax'];
  }

  return (
    <div>
      {props.report ?

        <div className="chartWrapper">
          {props.report.delta ?
            <Trend
              positive={props.trendPositive}
              delta={props.report.delta}
              deltaPeriod={props.report.deltaPeriod}
              deltaPeriodUnit={props.report.deltaPeriodUnit}
            /> : null}
          <h3>
            {props.title}
          </h3>
          <ResponsiveContainer width="99%" height={props.containerHeight}>
            <BarChart data={props.report} margin={{ bottom: props.xAxisVertical ? 130 : 20 }}>
              <XAxis
                height={props.xAxisHeight}
                interval={props.xAxisVertical ? 0 : undefined}
                dataKey="categoryName"
                tick={props.xAxisVertical ?
                  <XAxisVertical groupedBy={props.by} /> :
                  <XAxisAtAngle groupedBy={props.by} />}
              />
              <YAxis tickFormatter={props.yAxisFormatter} {...yAxisProps} />
              <Tooltip formatter={props.tooltipFormatter} />
              <Legend verticalAlign="top" />
              {props.labels.map(label => (<Bar
                key={label}
                dataKey={label}
                name={capitalize(label)}
                stackId="a"
                fill={colors[label]}
                onMouseOver={props.onBarMouseOver}
                onMouseOut={props.onBarMouseOut}
                onClick={props.onBarClick}
              />))}
            </BarChart>
          </ResponsiveContainer>
        </div> : null}
    </div>
  );
}

ByBarChart.propTypes = {
  trendPositive: oneOf(['up', 'down']),
  report: arrayOf(object).isRequired,
  labels: arrayOf(string).isRequired,
  loading: bool,
  onBarMouseOver: func,
  onBarMouseOut: func,
  onBarClick: func,
  tooltipFormatter: func,
  totalFormatter: func,
  yAxisFormatter: func,
  title: string,
  by: string.isRequired,
  showTotal: bool,
  xAxisVertical: bool,
  dataMax: bool,
  xAxisHeight: number,
  containerHeight: number,
};

ByBarChart.defaultProps = {
  trendPositive: 'down',
  loading: false,
  onBarMouseOver: () => {},
  onBarMouseOut: () => {},
  onBarClick: () => {},
  tooltipFormatter: NumberFormatter,
  totalFormatter: NumberFormatter,
  yAxisFormatter: UnitsFormatter,
  title: null,
  showTotal: true,
  xAxisVertical: false,
  dataMax: false,
  xAxisHeight: 60,
  containerHeight: 320,
};
