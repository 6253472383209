import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const styles = {
  zIndex: 1000,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: '0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07)',
};

function FullSizeModal(props) {

  const leftWithSidebar = props.offsetLeft ?? (props.sideNavOpen ? 195 : 75);
  const left = (props.screenWidth <= 768) ? 0 : leftWithSidebar;

  const rightWithSidebar = props.offsetRight ?? 0;
  const right = (props.screenWidth <= 768) ? 0 : rightWithSidebar;

  const modalStyles = {
    ...styles,
    left,
    right,
    ...props.style,
  };

  return (
    props.isOpen && (
      <div
        style={{ ...modalStyles, ...props.style }}
      >
        {props.children}
      </div>
    )
  );
}

const { oneOfType, arrayOf, node, func, bool, string, objectOf, number } = PropTypes;
FullSizeModal.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  contentLabel: string,
  contentStyle: objectOf(oneOfType([
    string,
    number,
  ])),
  overlayStyle: objectOf(oneOfType([
    string,
    number,
  ])),
};

FullSizeModal.defaultProps = {
  contentLabel: 'Modal',
  contentStyle: {},
  overlayStyle: {},
};

function mapStateToProps({ userInterface, screen }) {
  return {
    sideNavOpen: userInterface.sideNavOpen,
    screenWidth: screen.width,
  };
}

export default connect(mapStateToProps)(FullSizeModal);
