import ga from 'react-ga';
import * as trackingInfra from './tracking';

let tracking = false;

if (process.env.NODE_ENV === 'production') {
  ga.initialize('UA-69512284-2');
  tracking = true;
}

export default function track(isLoggedIn) {
  if (tracking) {
    ga.set({ page: window.location.pathname });
    ga.pageview(window.location.pathname);
  } else {
    console.log('Not tracing GA ', window.location.pathname);
  }

  if (isLoggedIn) {
    trackingInfra.track({ eventName: 'pageChanged', pageUrl: window.location.pathname });
  }
}

track();
