/* eslint-disable import/first */

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import 'react-app-polyfill/ie11';
import smoothscroll from 'smoothscroll-polyfill';

// Needs to be called before react is loaded
smoothscroll.polyfill();

import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createPlugin from 'bugsnag-react';

import store from 'lib/store';
import { getPageSource, hashPageScriptTags } from 'lib/update-checking';
import * as errorReporting from 'lib/error-reporting';
import * as api from 'lib/api';
import * as auth from 'lib/auth';
import * as db from 'lib/db';
import * as orm from 'lib/orm';
import * as apollo from 'lib/api/apollo';
import { logout, logoutIfLoggedIn } from 'thunks/user';
import { setPasswordExpiry } from 'reducers/global';
import AppContainer from 'routes/App/AppContainer';
import './index.scss';


//----------------------------------------------------------------
// INITIALISE / SETUP HELPER MODULES
//----------------------------------------------------------------

// Ensure that console methods are defined in IE. Normally they are only defined when
// the console is opened, which causes the app to crash when any console methods are called,
// except when you are trying to debug the issue at which point it works fine.
const fixIE = () => {
  const noop = () => {};
  if (window && window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf('MSIE') !== -1) {
    showBugsnag = false;
    const funcs = ['log', 'info', 'warn', 'debug', 'error'];
    funcs.forEach((methodName) => {
      console[methodName] = noop;
    });
  }
};
fixIE();


// Configure moment.js
moment.locale('en', {
  week: {
    dow: 1,
  },
});

// Initialise Redux
errorReporting.setStore(store);
api.init(store);
apollo.init(store);

// Initialise Error Handling
// Use Bugsnag in production and dev environments, else fallback to console.error
let showBugsnag = process.env.REACT_APP_PRODUCTION === 'y' || process.env.REACT_APP_DEVELOPMENT === 'y';
let BugsnagErrorBoundary = React.Fragment;
if (showBugsnag) {
  const bugsnagClient = errorReporting.initializeBugsnag();
  BugsnagErrorBoundary = bugsnagClient.use(createPlugin(React));
}
auth.setErrorHandler(errorReporting.reportError);
db.setErrorHandler(errorReporting.reportError);
orm.setErrorHandler(errorReporting.reportError);
api.setErrorHandler(errorReporting.reportError);
apollo.setErrorHandler(errorReporting.reportError);

// Pass the db module a reference to the logout thunk. Used when `db` encounters a PERMISSION_DENIED error.
// Passing it in this way avoids having a circular dependency.
db.setLogoutHandler(logout);
api.setAuthErrorHandler(logoutIfLoggedIn);
api.setPasswordExpiryHandler(setPasswordExpiry);

// Get the original HTML source of the page and hash the filenames of the script tags.
// This this is used later to check for updates by fetching the latest index.html and running
// it through the same hashing function. If the hashes don't match, then an update is available.
const pageSource = getPageSource();
const currentVersionHash = hashPageScriptTags(pageSource);


//----------------------------------------------------------------
// RENDER APP
//----------------------------------------------------------------

// Top-level render implementation. Called immeditately, and also if the
// app needs to rerender due to new components being hot-reloaded.
const rootRender = (AppComponent) => {
  ReactDOM.render(
    <BugsnagErrorBoundary>
      <Provider store={store}>
        <AppComponent currentVersionHash={currentVersionHash} reportError={errorReporting.reportError} />
      </Provider>
    </BugsnagErrorBoundary>,
    window.document.getElementById('root'),
  );
};

// Initial render
rootRender(AppContainer);

// Hot module reloading rerenders
if (module.hot) {
  module.hot.accept(
    './routes/App/AppContainer',
    () => {
      const NewAppContainer = require('./routes/App/AppContainer').default; // eslint-disable-line @typescript-eslint/no-var-requires
      rootRender(NewAppContainer);
    },
    err => console.error(err),
  );
}
