import React from 'react';

import { DayOfWeek } from 'types/ShiftTypes';
import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface DayOfWeekFieldProps {
  onChange: (value: { value: string, label: string }) => void,
  dayOfWeek: { id: DayOfWeek, name: string } | null,
}

const dayOfWeekMapping = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

function DayOfWeekField(props: DayOfWeekFieldProps) {

  const daysOfWeek = Object.entries(dayOfWeekMapping).map(([value, label]) => ({ value, label }));

  return (
    <FieldLabel label="Day Of Week">
      <JobsSelectBox
        name="dayOfWeek"
        value={props.dayOfWeek}
        placeholder="Day Of Week"
        data={daysOfWeek}
        onChange={props.onChange}
        mandatory
      />
    </FieldLabel>
  );
}

export default React.memo(DayOfWeekField);
