import React, { useState } from 'react';

import { PublishedShiftSummary, FailedShift } from 'types/ShiftTypes';
import { batchReleaseToAgency } from 'lib/api/jobs';

import colors from 'config/colors';
import spacing from 'config/spacing';

import Modal from 'components/Modal';

import AgencyReleaseModal from '../ShiftDetails/BookingsTab/AgencyReleaseModal';
import FailedShiftsList from './FailedShiftsList';

interface BulkAgencyReleaseProps {
  shifts: Array<PublishedShiftSummary>,
  selectedDrafts?: Array<PublishedShiftSummary>,
  onClose: () => void,
  refreshShiftList: () => void,
  unselectSpecifiedShifts: (shiftKeys: Array<string>) => void,
}

interface FailedShiftFromApi { shiftKey: string, reason: string }

function BulkAgencyRelease(props: BulkAgencyReleaseProps) : React.ReactElement {

  const [isReleasingShifts, setIsReleasingShifts] = useState(false);
  const [releasedShiftsCount, setReleasedShiftsCount] = useState<number | null>(null);
  const [failedShifts, setFailedShifts] = useState<Array<FailedShiftFromApi> | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);

  const hasSelectedPublishedShifts = !!props.shifts?.length;
  const hasSelectedDrafts = !!props.selectedDrafts?.length;

  const releaseToAgency = async (supplierKeys: Array<string>) => {

    const shiftKeys = props.shifts.map(shift => shift.key);
    setIsReleasingShifts(true);

    try {

      const response = await batchReleaseToAgency(shiftKeys, supplierKeys);

      // Set amount of released shifts
      setReleasedShiftsCount(response.releasedShifts.length);

      // If any shifts were released, then unselect specified shifts and refresh list view
      if (response.releasedShifts.length > 0) {
        await props.refreshShiftList();
        if (props.unselectSpecifiedShifts) props.unselectSpecifiedShifts(response.releasedShifts);
      }

      // Set array of failed shifts, otherwise close modal
      if (response.failedShifts.length) {
        setFailedShifts(response.failedShifts);
        setIsReleasingShifts(false);
      } else {
        props.onClose();
      }

    } catch (error) {
      if (error instanceof Error) {
        setApiError(error.message);
        setIsReleasingShifts(false);
      }
    }
  };

  if (!hasSelectedPublishedShifts) {
    return (
      <Modal
        isOpen
        onRequestClose={props.onClose}
        header="Release to Agency"
        contentStyle={{ justifyContent: 'space-between' }}
        bodyStyle={{ padding: spacing.base, maxWidth: 400 }}
        vflex
      >
        <p style={{ color: colors.red, lineHeight: 1.4, marginBottom: spacing.base }}>
          {hasSelectedDrafts && 'You have selected draft shifts. Draft shifts must be published before they can be released to agency.'}
          {!hasSelectedDrafts && 'No shifts selected'}
        </p>
      </Modal>
    );
  }

  if (failedShifts) {
    return (
      <Modal
        isOpen
        onRequestClose={props.onClose}
        header="Release to Agency"
        contentStyle={{ justifyContent: 'space-between' }}
        bodyStyle={{ padding: spacing.base }}
        vflex
      >
        <FailedReleasedShifts
          onClose={props.onClose}
          releasedShiftsCount={releasedShiftsCount}
          failedShifts={failedShifts.map((shift) => {
            const shiftFromListView = props.shifts.find(s => s.key === shift.shiftKey);
            if (shiftFromListView) return { ...shiftFromListView, failureReason: shift.reason };
            return undefined;
          }).filter(Boolean) as Array<FailedShift>}
        />
      </Modal>
    );
  }

  return (
    <AgencyReleaseModal
      shifts={props.shifts}
      selectedDraftShiftCount={props.selectedDrafts?.length}
      onClose={() => props.onClose()}
      isLoading={isReleasingShifts}
      releaseToAgency={releaseToAgency}
      apiError={apiError}
    />
  );
}

interface FailedDeletedShiftsProps {
  failedShifts: Array<FailedShift>,
  releasedShiftsCount: number | null,
  onClose: () => void,
}

function FailedReleasedShifts(props: FailedDeletedShiftsProps) {

  const { releasedShiftsCount } = props;

  let releasedShiftsMessage;
  if (typeof releasedShiftsCount === 'number' && releasedShiftsCount > 0) {
    releasedShiftsMessage = `${releasedShiftsCount} shift${releasedShiftsCount > 1 ? 's' : ''} successfully released.`;
  }

  return (
    <>
      {releasedShiftsMessage && <p style={{ color: '#666', lineHeight: 1.4 }}>{releasedShiftsMessage}</p>}
      <p style={{ color: colors.red, lineHeight: 1.4, marginBottom: spacing.base }}>The following shifts failed to release:</p>

      <FailedShiftsList
        onClose={props.onClose}
        failedShifts={props.failedShifts}
      />
    </>
  );
}

export default BulkAgencyRelease;
