import React, { CSSProperties } from 'react';
import { Moment } from 'moment';

import colors from 'config/colors';

import SingleTimePicker from './SingleTimePicker';

const styles = {
  container: {
    display: 'flex',
    borderRadius: 2,
  },
  untilLabel: {
    borderTop: `1px solid ${colors.cavalry.line}`,
    borderBottom: `1px solid ${colors.cavalry.line}`,
    padding: '9px 12px',
    fontSize: '0.9rem',
    color: colors.text,
  },
};

interface StartEndTimePickerProps {
  startTime: Moment;
  onStartTimeChange: (hours: number | null, minutes: number | null) => void,
  endTime: Moment;
  onEndTimeChange: (hours: number | null, minutes: number | null) => void,
  style?: CSSProperties;
  disabled?: boolean
}

function StartEndTimePicker(props: StartEndTimePickerProps) : JSX.Element {
  return (
    <div style={{ ...styles.container, ...props.style }}>
      <SingleTimePicker
        placeholder="start"
        time={props.startTime}
        onTimeChange={props.onStartTimeChange}
        style={{ borderTopLeftRadius: 2, borderBottomLeftRadius: 2 }}
        disabled={props.disabled}
      />
      <div style={styles.untilLabel}>until</div>
      <SingleTimePicker
        placeholder="end"
        time={props.endTime}
        onTimeChange={props.onEndTimeChange}
        style={{ borderTopRightRadius: 2, borderBottomRightRadius: 2 }}
        disabled={props.disabled}
        // nextAfter={props.startTime}
      />
    </div>
  );
}

StartEndTimePicker.defaultProps = {
  style: undefined,
  disabled: false,
};

export default StartEndTimePicker;
