import React from 'react';
import Pill from 'components/Pill';
import colors from 'config/colors';


const TimesheetStatusPill = ({ timesheet }) => {
  const { paidAt: paid } = timesheet;
  if (timesheet.status === 'approved' && !paid) { return <Pill style={{ width: 150 }} label="Approved" type="positive" />; }
  if (timesheet.status === 'rejected') { return <Pill style={{ width: 150 }} label="Rejected" type="danger" />; }
  if (timesheet.status === 'approved' && paid) { return <Pill style={{ width: 150 }} label="Paid" type="warning" />; }
  if (timesheet.timesheetIsLocked) { return <Pill style={{ width: 150 }} label="Locked" type="danger" />; }
  if (timesheet.status === 'pending-approval') { return <Pill style={{ width: 150 }} label="Pending Approval" type="positive" outline />; }
  return <Pill label="Awaiting Submission" style={{ backgroundColor: colors.cavalry.backgroundLight4, color: colors.white, width: 150 }} outline />;
};

export default TimesheetStatusPill;
