import React, { useEffect } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';

import * as candidateThunks from 'thunks/candidate';
import { setIsCreatingNewContract, setHasSavedContract, clearStaffContractError, clearUpdateStaffContractError } from 'reducers/candidate';

import Button from 'components/Button';
import Loading from 'components/Loading';
import ErrorScreen from 'components/ErrorScreen';
import ContractForm from './ContractForm';
import './ContractTab.scss';

interface ContractTabProps {
  adminHasWriteAccess: boolean,
  candidateKey: string,
}

function ContractTab(props: ContractTabProps) : React.ReactElement {

  const dispatch = useAppDispatch();

  const isFetchingStaffContract = useSelector(({ candidate }) => candidate.contract.isFetchingStaffContract);
  const isUpdatingStaffContract = useSelector(({ candidate }) => candidate.contract.isUpdatingStaffContract);
  const isCreatingNewContract = useSelector(({ candidate }) => candidate.contract.isCreatingNewContract);
  const fetchStaffContractError = useSelector(({ candidate }) => candidate.contract.fetchStaffContractError);
  const updateStaffContractError = useSelector(({ candidate }) => candidate.contract.updateStaffContractError);
  const hasSavedContract = useSelector(({ candidate }) => candidate.contract.hasSavedContract);
  const contract = useSelector(({ candidate }) => candidate.contract.contract);

  useEffect(() => {
    if (props.adminHasWriteAccess) dispatch(candidateThunks.fetchStaffContract(props.candidateKey));

    return () => {
      if (fetchStaffContractError) dispatch(clearStaffContractError());
      if (updateStaffContractError) dispatch(clearUpdateStaffContractError());
      dispatch(setHasSavedContract(false));
    };
  }, []);

  if (!props.adminHasWriteAccess) {
    return (
      <div className="contract-tab__missing-contract">
        <p className="contract-tab__missing-contract-text">You do not have permission to manage staff contracts.</p>
      </div>
    );
  }

  if (isFetchingStaffContract) {
    return (
      <div className="contract-tab">
        <Loading flex />
      </div>
    );
  }

  if (fetchStaffContractError) {
    return (
      <div className="contract-tab">
        <ErrorScreen
          message={fetchStaffContractError}
          retry={() => dispatch(candidateThunks.fetchStaffContract(props.candidateKey))}
        />
      </div>
    );
  }

  return (
    <div className="contract-tab">

      {!contract && !isFetchingStaffContract && !isCreatingNewContract && <MissingContract addContract={() => dispatch(setIsCreatingNewContract(true))} />}

      {isCreatingNewContract && (
        <ContractForm
          saveChanges={(fields) => dispatch(candidateThunks.createStaffContract(props.candidateKey, fields))}
          isUpdating={isUpdatingStaffContract}
          error={updateStaffContractError}
          cancel={() => dispatch(setIsCreatingNewContract(false))}
          hasSavedContract={hasSavedContract}
        />
      )}

      {contract && (
        <ContractForm
          saveChanges={(fields) => dispatch(candidateThunks.updateStaffContract(contract.key, fields))}
          contract={contract}
          isUpdating={isUpdatingStaffContract}
          error={updateStaffContractError}
          hasSavedContract={hasSavedContract}
        />
      )}

    </div>
  );
}

interface MissingContractProps {
  addContract: () => void,
}

function MissingContract(props: MissingContractProps) : React.ReactElement {
  return (
    <div className="contract-tab__missing-contract">
      <p className="contract-tab__missing-contract-text">There is no contract associated with this member of staff on Dagny.</p>
      <Button onClick={props.addContract}>Add Contract</Button>
    </div>
  );
}

export default ContractTab;
