import React, { CSSProperties } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

interface Styles {
  [key: string]: CSSProperties
}

const styles: Styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 12,
    borderTop: '1px solid #EAEAEA',
  },
  contractText: {
    color: '#666',
    lineHeight: 1.4,
    paddingTop: 3,
    paddingBottom: 3,
  },
};

interface ContractedHoursWarningModalProps {
  onClose: () => void,
  book: () => void,
  contract: {
    contractedHours: number,
    hoursPlusShiftDuration: number,
    contractedHoursPeriodType: 'week' | 'month',
    hoursExceedsContractedHours: boolean,
    hoursExceedWorkingTimeDirective: boolean,
  }
}

export default function ContractedHoursWarningModal(props: ContractedHoursWarningModalProps): React.ReactElement {

  const { contract, onClose, book } = props;

  const contractedHoursSummary = `If booked into this shift, the staff member will have worked a total of ${contract.hoursPlusShiftDuration} hours this ${contract.contractedHoursPeriodType}.`;
  const contractHoursExceededText = `This will exceed their contracted hours limit. Their contracted hours are ${contract.contractedHours} per ${contract.contractedHoursPeriodType}.`;
  const workingTimeDirectiveExceededText = `The staff member will exceed the working time directive (${contract.contractedHoursPeriodType === 'week' ? '48' : '196'} hours limit).`;

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      contentLabel="contractedHoursWarning"
      header="Contracted Hours Warning"
      contentStyle={{ width: 600 }}
    >
      <div style={{ padding: 12 }}>
        <p style={styles.contractText}>{contractedHoursSummary}</p>
        {contract.hoursExceedsContractedHours && <p style={styles.contractText}>{contractHoursExceededText}</p>}
        {contract.hoursExceedWorkingTimeDirective && <p style={styles.contractText}>{workingTimeDirectiveExceededText}</p>}
      </div>
      <div className="space-children-12" style={styles.buttonContainer}>
        <Button onClick={onClose} outline>Cancel</Button>
        <Button onClick={book}>Confirm Booking</Button>
      </div>
    </Modal>
  );
}
