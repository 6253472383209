import React from 'react';
import Modal from '../../../components/Modal';
import colors from '../../../config/colors';
import spacing from '../../../config/spacing';
import fonts from '../../../config/fonts';

const styles = {
  header: {
    color: colors.text,
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
    margin: spacing.base,
  },
};

export default function WarningModal(props) {

  const { isOpen, contentLabel, onRequestClose, warningModalText } = props;
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose}
    >
      <div>
        <h2 style={styles.header}>{warningModalText}</h2>
      </div>
    </Modal>
  );

}
