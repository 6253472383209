import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';

import colors from 'config/colors';

import * as globalReducers from 'reducers/global';

import NotificationCentre from '../NotificationCentre';
import UpdateMessageBar from '../UpdateMessageBar';
import TopBar from './TopBar';
import SideBar from './SideBar';

import './loading.scss';

const styles = {
  container: {
    backgroundColor: colors.backgroundColor,
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  contentWrapper: {
    flex: '1 1 0px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'hidden',
    overflowX: 'hidden',
    backgroundColor: 'white',
    opacity: 0.9999,
    zIndex: 2,
  },
  scrollContainer: {
    height: '100%',
  },
};

const { string, arrayOf, oneOfType, node, bool } = PropTypes;
class Page extends Component {
  static propTypes = {
    title: string,
    subTitle: string,
    children: oneOfType([
      arrayOf(node),
      node,
    ]).isRequired,
    loading: bool,
    globalLoading: bool,
    disableScroll: bool,
    vflex: bool,
    hflex: bool,
    isMobile: bool.isRequired,
    contentClassName: string,
    supplementaryPanel: node,
  }

  static defaultProps = {
    title: null,
    subTitle: null,
    loading: false,
    globalLoading: false,
    disableScroll: false,
    vflex: false,
    hflex: false,
    contentClassName: '',
    supplementaryPanel: null,
  }


  constructor(props) {
    super(props);
    this.state = {
      notificationCentreOpen: false,
    };
  }

  render() {

    const contentWrapperStyles = { ...styles.contentWrapper };
    const scrollContainerStyles = { ...styles.scrollContainer };

    if (this.props.vflex || this.props.hflex || this.props.disableScroll) {
      contentWrapperStyles.overflowY = 'hidden';
      scrollContainerStyles.overflowY = 'visible';
    } else {
      scrollContainerStyles.overflowY = 'scroll';
    }

    if (this.props.vflex) {
      scrollContainerStyles.display = 'flex';
      scrollContainerStyles.flexDirection = 'column';
    }

    if (this.props.hflex) {
      scrollContainerStyles.display = 'flex';
      scrollContainerStyles.flexDirection = 'row';
    }

    if (this.props.isMobile) {
      contentWrapperStyles.height = '0px';
    } else {
      contentWrapperStyles.width = '0px';
    }

    if (this.props.backgroundColor) {
      contentWrapperStyles.backgroundColor = this.props.backgroundColor;
    }

    const containerStyles = { ...styles.container, flexDirection: this.props.isMobile ? 'column' : 'row' };

    return (
      <DocumentTitle title={`${this.props.title} | Dagny`}>
        <>

          {/* Page content */}
          <div className="pageContainer" style={containerStyles}>

            {/* SideBar (Non-Mobile) / TopBar (Mobile) */}
            {this.props.isMobile ?
              <TopBar openNotificationCentre={() => this.setState({ notificationCentreOpen: true })} />
              :
              <SideBar openNotificationCentre={() => this.setState({ notificationCentreOpen: true })} />
            }

            {/* Main Content */}
            <div className={classNames('pageContent', this.props.contentClassName)} style={contentWrapperStyles} id="__cw_rightWrapper">
              <div
                className={classNames('pageContentScrollContainer', { scrollingDisabled: this.props.disableScroll })}
                style={scrollContainerStyles}
              >
                {this.props.children}
              </div>
            </div>

            {this.props.supplementaryPanel ?? null}

          </div>


          {/* Loading throbber (thin line at top of page) */}
          {this.props.loading || this.props.globalLoading ? <div className="loading-bar" /> : null}


          {/* Message bar ("new version of CW is available" ) */}
          <UpdateMessageBar
            startTimeout={this.startTimeout}
            messageFadeOut={this.state.messageFadeOut}
            removeUpdatedMessage={this.removeUpdatedMessage}
          />


          {/* Notification center (pop-over side panel) */}
          <NotificationCentre
            isOpen={this.state.notificationCentreOpen}
            closeNotificationCentre={() => this.setState({ notificationCentreOpen: false })}
          />
        </>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ loading, user, global, notifications, screen }) => {
  return {
    globalLoading: loading.loading,
    userId: user.userId,
    userData: user.userData,
    orgName: global.orgConfig ? global.orgConfig.name : null,
    notifications: notifications.notifications,
    updateRequired: global.updateRequired,
    updateCompleted: global.updateCompleted,
    isMobile: screen.isMobile,
  };
};


function mapDispatchToProps(dispatch) {
  return {
    updateComplete: () => dispatch(globalReducers.updateComplete()),
    updateMessageDisplayed: () => dispatch(globalReducers.updateMessageDisplayed()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
