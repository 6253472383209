const TOGGLE_NOTIFICATION_SPECIFICITY = 'TOGGLE_NOTIFICATION_SPECIFICITY';
const ADD_NOTIFICATION_DATA = 'ADD_NOTIFICATION_DATA';
const SET_WATCHING = 'SET_WATCHING';
const UNSET_WATCHING = 'UNSET_WATCHING';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const toggleNotificationSpecificity = () => ({ type: TOGGLE_NOTIFICATION_SPECIFICITY });
export const setWatching = () => ({ type: SET_WATCHING });
export const unsetWatching = () => ({ type: UNSET_WATCHING });

export const addNotifications = (data) => ({ type: ADD_NOTIFICATION_DATA, data });
export const removeNotification = (id) => ({ type: REMOVE_NOTIFICATION, id });
export const markNotificationAsRead = (id) => ({ type: MARK_NOTIFICATION_AS_READ, id });
export const clearNotifications = () => ({ type: CLEAR_NOTIFICATIONS });

const initialState = {
  notificationSpecificity: 1,
  notificationQueries: [],
  notifications: [],
  watching: false,
  loading: true,
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_NOTIFICATION_SPECIFICITY:
      return {
        ...state,
        notificationSpecificity: state.notificationSpecificity === 1 ? 2 : 1,
      };
    case SET_WATCHING:
      return {
        ...state,
        watching: true,
        loading: false,
      };
    case UNSET_WATCHING:
      return {
        ...state,
        watching: false,
        loading: true,
      };
    case ADD_NOTIFICATION_DATA:
      return {
        ...state,
        notifications: action.data,
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.id !== action.id),
      };
    case MARK_NOTIFICATION_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map(notification => (notification.id === action.id ? { ...notification, read: true } : notification)),
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
