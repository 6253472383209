import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdAssessment, MdWork } from 'react-icons/md';
import * as rateCardThunks from '../../thunks/rateCards';
import Button from '../../components/Button';
import EmptyCard from '../../components/EmptyCard';
import DataTable from '../../components/DataTable';
import NewRateCardModal from './NewRateCardModal';

const styles = {
  newCardContainer: {
    position: 'absolute',
    right: 0,
  },
};

const COLUMNS = [
  {
    name: 'name',
    icon: <MdAssessment />,
    header: 'Name',
  },
  {
    name: 'roleName',
    icon: <MdWork />,
    header: 'Role',
  },
  {
    name: 'gradeName',
    icon: <MdWork />,
    header: 'Grade',
  },
  {
    name: 'specialityName',
    icon: <MdWork />,
    header: 'Speciality',
  },
];

const { func, bool } = PropTypes;
class RateCardPolicies extends Component {
  static propTypes = {
    openCreateModal: func.isRequired,
    createModalOpen: bool.isRequired,
    closeCreateModal: func.isRequired,
  };

  render() {
    return (

      <div>
        <div style={{ position: 'relative' }}>
          <div style={styles.newCardContainer}>
            <Button
              small
              onClick={this.props.openCreateModal}
              disabled={!this.props.canEdit}
            >
              New Rate Card
            </Button>
          </div>
          {(this.props.policiesType === 'org' && (!this.props.orgRateCards || this.props.orgRateCards.length < 1)) ||
          (this.props.policiesType === 'site' && (!this.props.siteRateCards || this.props.siteRateCards.length < 1))
            ?
              <EmptyCard message="No rate cards" dimensions={{ height: 100 }} />
            :
              <DataTable
                columns={COLUMNS}
                rows={this.props.policiesType === 'org' ? this.props.orgRateCards : this.props.siteRateCards}
                onRowClick={this.props.onRateCardClick}
                idColumn="key"
              />
          }
        </div>
        {this.props.createModalOpen ?
          <NewRateCardModal
            isOpen={this.props.createModalOpen}
            onRequestClose={this.props.closeCreateModal}
          />
          : null
        }
      </div>
    );
  }
}

function mapStateToProps({ rateCards, policies }) {
  return {
    orgRateCards: rateCards.orgRateCards,
    siteRateCards: rateCards.siteRateCards,
    isfetchingOrgRateCards: rateCards.isfetchingOrgRateCards,
    policiesType: policies.policiesType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgSiteRateCards: () => dispatch(rateCardThunks.fetchOrgSiteRateCards()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RateCardPolicies);
