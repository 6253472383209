import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DrillDown from './DrillDown';
import { setSelectedRole, clearReportingError } from '../../../reducers/reporting';
import { fetchReport } from '../../../thunks/reporting';
import { reports } from '../Reports';

const { objectOf, object, func, string, number, bool, shape, oneOfType } = PropTypes;

class DrillDownContainer extends Component {
  static propTypes = {
    loading: shape({
      fillRate: bool.isRequired,
      fillRateByRole: bool.isRequired,
      fillRateBySpeciality: bool.isRequired,
      requests: bool.isRequired,
      requestsByRole: bool.isRequired,
      requestsBySpeciality: bool.isRequired,
      spend: bool.isRequired,
      spendByRole: bool.isRequired,
      spendBySpeciality: bool.isRequired,
      activity: bool.isRequired,
    }).isRequired,
    reports: objectOf(object).isRequired,
    history: shape({
      push: func.isRequired,
      replace: func.isRequired,
      createHref: func.isRequired,
    }).isRequired,
    setSelectedRole: func.isRequired,
    selectedRange: shape({
      from: string.isRequired,
      to: string.isRequired,
    }).isRequired,
    selectedSite: shape({ key: string, name: string }),
    fetchReport: func.isRequired,
    location: objectOf(oneOfType([
      string,
      object,
    ])).isRequired,
    durationBetweenDates: number.isRequired,
    reportError: shape({
      fillRate: string,
      fillRateByRole: string,
      fillRateBySpeciality: string,
      requests: string,
      requestsByRole: string,
      requestsBySpeciality: string,
      spend: string,
      spendByRole: string,
      spendBySpeciality: string,
      activity: string,
    }).isRequired,
    clearReportingError: func.isRequired,
  }

  static defaultProps = {
    selectedSite: {},
  }

  async componentDidMount() {
    const reportConfig = this.getReportConfig();
    // Fetch all relevant reports
    await Promise.all(reportConfig.groupByOptions.map(async (groupBy) => {
      if (!groupBy) {
        await this.props.fetchReport(reportConfig.name);
      } else {
        await this.props.fetchReport(reportConfig.name, groupBy);
      }
    }));
  }

  async componentDidUpdate(prevProps) {
    const reportConfig = this.getReportConfig();
    const urlHasChanged = prevProps.location.pathname !== this.props.location.pathname;
    const siteHasChanged = prevProps.selectedSite.key !== this.props.selectedSite.key;
    const dateHasChanged = (prevProps.selectedRange.from !== this.props.selectedRange.from) || (prevProps.selectedRange.to !== this.props.selectedRange.to);

    // If URL, site or date has changed, re-fetch reports
    if (urlHasChanged || siteHasChanged || dateHasChanged) {
      if (!this.props.loading[reportConfig.name]) {
        await Promise.all(reportConfig.groupByOptions.map(async (groupBy) => {
          if (!groupBy) {
            await this.props.fetchReport(reportConfig.name);
          } else {
            await this.props.fetchReport(reportConfig.name, groupBy);
          }
        }));
      }
    }
  }

  // Find report config
  getReportConfig = () => reports.find(r => r.url === this.props.location.pathname.split('/')[2]);

  // Load group by specialities report page
  roleBreakdownBarClick = (item) => {
    const reportConfig = this.getReportConfig();
    this.props.setSelectedRole({ key: item.categoryKey, name: item.categoryName });
    this.props.history.push(`/reporting/${reportConfig.url}/specialities`);
  }

  render() {
    const reportConfig = this.getReportConfig();
    return (
      <DrillDown
        reports={this.props.reports}
        ReportingComponent={reportConfig.component}
        reportName={reportConfig.name}
        reportTitle={reportConfig.title}
        roleBreakdown={!!reportConfig.roleBreakdown}
        loading={this.props.loading}
        roleBreakdownBarClick={this.roleBreakdownBarClick}
        durationBetweenDates={this.props.durationBetweenDates}
        reportError={this.props.reportError}
        clearReportingError={this.props.clearReportingError}
      />
    );
  }
}

const mapStateToProps = ({ reporting }) => {
  return {
    loading: reporting.loading,
    reports: reporting.reports,
    selectedRange: reporting.selectedRange,
    durationBetweenDates: reporting.durationBetweenDates,
    selectedSite: reporting.selectedSite,
    reportError: reporting.reportError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReport: (reportName, groupBy) => dispatch(fetchReport(reportName, groupBy)),
    setSelectedRole: role => dispatch(setSelectedRole(role)),
    clearReportingError: reportName => dispatch(clearReportingError(reportName)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrillDownContainer));
