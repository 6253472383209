import { isNil } from 'lodash-es';

import * as filterReducer from '../reducers/filter';
import * as userReducer from '../reducers/user';
import * as createShiftsReducer from '../reducers/createShifts';
import * as createShiftThunks from '../thunks/createShifts';

const LOGIN = 'LOGIN';

const USER_SCHEMA_VERSION = 1;
const FILTER_SCHEMA_VERSION = 2;
const CREATE_SHIFTS_SCHEMA_VERSION = 2; // v2 uses new-style ratecards

function mapV1FiltersToV2Filters(orgConfig, filters) {

  const NULL_FILTER_KEYS = ['allSitesFilter', 'allRolesFilter', 'allSpecialitiesFilter'];

  const newToOldFilterKeyMap = {
    sites: 'site',
    roles: 'role',
    grades: 'grade',
    specialities: 'speciality',
  };

  function getV1FilterValues(namespace, filterKey) {
    if (!Array.isArray(filters[namespace])) return null;

    return filters[namespace]
      .filter(filter => filter.type === filterKey)
      .map(filter => filter?.data?.id)
      .map(key => (NULL_FILTER_KEYS.includes(key) ? null : key))
      .filter(Boolean);
  }

  const bank = {};
  Object.keys(newToOldFilterKeyMap).forEach((key) => {
    const oldFilterKey = newToOldFilterKeyMap[key];
    bank[key] = oldFilterKey ? getV1FilterValues('bank', oldFilterKey) : [];
  });

  const shifts = {};
  Object.keys(newToOldFilterKeyMap).forEach((key) => {
    const oldFilterKey = newToOldFilterKeyMap[key];
    shifts[key] = oldFilterKey ? getV1FilterValues('jobs', oldFilterKey) : [];
  });

  return { bank, shifts };
}

export function persistMiddleware({ dispatch, getState }) {
  return next => async (action) => {

    next(action);

    // Fetch filter data and draft shifts from localstorage for the logged in user.
    // If data exists for logged in user, then persist filters and draftshifts.
    if (action.type === LOGIN) {
      try {
        const filterDataFromStorage = JSON.parse(window.localStorage.getItem(`cw:filters:${action.id}`));
        if (filterDataFromStorage) {
          switch (filterDataFromStorage._schemaVersion) { // eslint-disable-line no-underscore-dangle
            case 1: {
              const newFilterData = mapV1FiltersToV2Filters(getState().global.orgConfig, filterDataFromStorage);
              dispatch(filterReducer.v2SetAllSelectedItems(newFilterData));
              break;
            }
            default:
              delete filterDataFromStorage._schemaVersion; // eslint-disable-line no-underscore-dangle
              dispatch(filterReducer.v2SetAllSelectedItems(filterDataFromStorage));
          }
        }
      } catch (e) {
        console.warn('Failed to retrieve data from localstorage', e);
      }
      restoreCreateShiftData(action.id, dispatch);
      // If drafts from storage still contain old costCentreId prop, replace with new costCentre object
      // This will prevent app from crashing.
      await checkDraftShiftCostCentreData(dispatch, getState);
    }

    // Save user state to localstorage
    if (userReducer.PERSIST_ACTIONS.includes(action.type)) {
      const { user } = getState();
      if (user) {
        const state = { id: user.userId, _schemaVersion: USER_SCHEMA_VERSION };
        window.localStorage.setItem('cw:user', JSON.stringify(state));
      }
    }

    // Save filter state to localstorage
    if (filterReducer.PERSIST_ACTIONS.includes(action.type)) {
      const { user, filter } = getState();
      if (user.userId && filter.v2SelectedItems) {
        const state = { ...filter.v2SelectedItems, _schemaVersion: FILTER_SCHEMA_VERSION };
        window.localStorage.setItem(`cw:filters:${user.userId}`, JSON.stringify(state));
      }
    }

    // Save createShifts state to localStorage
    if (createShiftsReducer.PERSIST_ACTIONS.includes(action.type)) {
      const { user, createShifts } = getState();
      const state = { ...createShifts.draftShifts, _schemaVersion: CREATE_SHIFTS_SCHEMA_VERSION };
      window.localStorage.setItem(`cw:createShifts:${user.userId}`, JSON.stringify(state));
    }
  };
}

export function rehydrateUserState(store) {
  try {
    const userState = JSON.parse(window.localStorage.getItem('cw:user'));
    if (userState && userState.id) {
      store.dispatch(userReducer.setUserId(userState.id));
    }
  } catch (e) {
    console.warn('Error retrieving user state from localstorage', e);
  }
}

function restoreCreateShiftData(userId, dispatch) {
  try {
    const createShiftDataFromStorage = JSON.parse(window.localStorage.getItem(`cw:createShifts:${userId}`));
    if (createShiftDataFromStorage) {
      delete createShiftDataFromStorage._schemaVersion; // eslint-disable-line no-underscore-dangle

      Object.values(createShiftDataFromStorage).forEach(draft => {


        // Some drafts were errorneously created with and `id` prop rather than a `key` prop
        if (draft.rateCard?.id) {
          draft.rateCard.key = draft.rateCard?.id; // eslint-disable-line no-param-reassign
        }

        // Add slotsRequired property if it doesn't already exist on draft
        if (isNil(draft.slotsRequired)) {
          draft.slotsRequired = 1; // eslint-disable-line no-param-reassign
        }

        // If there is no candidates property, then initialise array. Add value from candidate property to array if it exists
        if (isNil(draft.candidates)) {
          draft.candidates = []; // eslint-disable-line no-param-reassign
          if (draft.candidate?.id && draft.candidate?.name) {
            draft.candidates.push({ id: draft.candidate.id, name: draft.candidate.name });
          }
        }

      });

      dispatch(createShiftsReducer.addMultipleDraftShifts(createShiftDataFromStorage));
    }
  } catch (e) {
    console.warn('Failed to retrieve data from localstorage', e);
  }
}

// Remove old costCentreId prop and replace with new costCentre object in draft shifts
async function checkDraftShiftCostCentreData(dispatch, getState) {
  const { createShifts } = getState();
  const drafts = { ...createShifts.draftShifts };
  await Promise.all(Object.keys(drafts).map(async (draftId) => {
    if (drafts[draftId].costCentreId && !drafts[draftId].costCentre) {
      delete drafts[draftId].costCentreId;
      dispatch(createShiftThunks.setCostCentre(draftId, 'draftShifts'));
    }
  }));
}
