import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFeatureFlag } from 'hooks/feature';
import CWShiftFormSelect from 'components/CWSelect/CWShiftFormSelect';

import FieldLabel from './FieldLabel';

interface Bank {
  bankKey: string
  name: string
}

interface BanksFieldProps {
  onChange: (val: string[]) => void,
  bankKeys: Array<string>,
  mandatory: boolean
}

function BanksField(props: BanksFieldProps) {

  const adminBanks = useSelector(({ global }) => global.adminBanks);
  const canReleaseToBank = useFeatureFlag(null, 'bankCanRelease');

  const banksPayload = useMemo(() => {
    return adminBanks.map((bank: Bank) => ({ value: bank.bankKey, label: bank.name }));
  }, [adminBanks]);

  const isValid = !props.mandatory || props.bankKeys?.length > 0;

  return (
    <FieldLabel label="Bank">
      <CWShiftFormSelect
        items={banksPayload}
        selectedItems={props.bankKeys ?? []}
        setSelectedItems={props.onChange}
        placeholder="Release to bank(s)"
        isValid={isValid}
        disabled={!canReleaseToBank}
      />
    </FieldLabel>
  );
}

export default React.memo(BanksField);
