const SUPPLIERS_START_WATCHING = 'SUPPLIERS_START_WATCHING';
const SUPPLIERS_WATCHING = 'SUPPLIERS_WATCHING';
const SUPPLIERS_CONTACTS_START_WATCHING = 'SUPPLIERS_CONTACTS_START_WATCHING';
const SUPPLIERS_CONTACTS_WATCHING = 'SUPPLIERS_CONTACTS_WATCHING';

const SUPPLIERS_START_LOADING = 'SUPPLIERS_START_LOADING';
const SUPPLIERS_STOP_LOADING = 'SUPPLIERS_STOP_LOADING';

const SUPPLIERS_DID_CHANGE = 'SUPPLIERS_DID_CHANGE';
const SUPPLIERS_CONTACTS_DID_CHANGE = 'SUPPLIERS_CONTACTS_DID_CHANGE';

const SUPPLIERS_SET_ORG_KEY = 'SUPPLIERS_SET_ORG_KEY';
const SUPPLIERS_SET_SUPPLIER_KEY = 'SUPPLIERS_SET_SUPPLIER_KEY';
const SUPPLIERS_SET_CONTACT_KEY = 'SUPPLIERS_SET_CONTACT_KEY';

const SUPPLIERS_RESET_STATE = 'SUPPLIERS_RESET_STATE';
const SUPPLIERS_CONTACTS_RESET_STATE = 'SUPPLIERS_CONTACTS_RESET_STATE';

const ADD_SUPPLIER_CANDIDATES = 'ADD_SUPPLIER_CANDIDATES';
const SUPPLIERS_CANDIDATE_RESET_STATE = 'SUPPLIERS_CANDIDATE_RESET_STATE';

export function startWatching() {
  return {
    type: SUPPLIERS_START_WATCHING,
  };
}
export function watching() {
  return {
    type: SUPPLIERS_WATCHING,
  };
}
export function startWatchingContacts() {
  return {
    type: SUPPLIERS_CONTACTS_START_WATCHING,
  };
}
export function watchingContacts() {
  return {
    type: SUPPLIERS_CONTACTS_WATCHING,
  };
}
export function startLoadingSuppliers() {
  return {
    type: SUPPLIERS_START_LOADING,
  };
}
export function stopLoadingSuppliers() {
  return {
    type: SUPPLIERS_STOP_LOADING,
  };
}
export function didChange(data) {
  return {
    type: SUPPLIERS_DID_CHANGE,
    data,
  };
}
export function contactsDidChange(data) {
  return {
    type: SUPPLIERS_CONTACTS_DID_CHANGE,
    data,
  };
}
export function setOrgKey(key) {
  return {
    type: SUPPLIERS_SET_ORG_KEY,
    key,
  };
}
export function setSupplierKey(key) {
  return {
    type: SUPPLIERS_SET_SUPPLIER_KEY,
    key,
  };
}
export function setContactKey(key) {
  return {
    type: SUPPLIERS_SET_CONTACT_KEY,
    key,
  };
}
export function resetState() {
  return {
    type: SUPPLIERS_RESET_STATE,
  };
}
export function resetContactState() {
  return {
    type: SUPPLIERS_CONTACTS_RESET_STATE,
  };
}
export function addSupplierCandidates(data) {
  return {
    type: ADD_SUPPLIER_CANDIDATES,
    data,
  };
}
export function resetCandidateState() {
  return {
    type: SUPPLIERS_CANDIDATE_RESET_STATE,
  };
}

const initialState = {
  watchingSuppliers: false,
  watchingContacts: false,
  isLoading: false,
  suppliersData: null,
  contactsData: null,
  orgKey: null,
  supplierKey: null,
  contactKey: null,
  candidates: null,
};

export default function suppliers(state = initialState, action) {
  switch (action.type) {
    case SUPPLIERS_START_WATCHING: {
      return {
        ...state,
        watchingSuppliers: true,
      };
    }
    case SUPPLIERS_WATCHING: {
      return {
        ...state,
        watchingSuppliers: true,
      };
    }
    case SUPPLIERS_CONTACTS_START_WATCHING: {
      return {
        ...state,
        watchingContacts: false,
      };
    }
    case SUPPLIERS_CONTACTS_WATCHING: {
      return {
        ...state,
        watchingContacts: true,
      };
    }
    case SUPPLIERS_START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUPPLIERS_STOP_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SUPPLIERS_DID_CHANGE: {
      return {
        ...state,
        suppliersData: action.data,
        watchingSuppliers: false,
      };
    }
    case SUPPLIERS_CONTACTS_DID_CHANGE: {
      return {
        ...state,
        contactsData: action.data,
      };
    }
    case SUPPLIERS_SET_ORG_KEY: {
      return {
        ...state,
        orgKey: action.key,
      };
    }
    case SUPPLIERS_SET_SUPPLIER_KEY: {
      return {
        ...state,
        supplierKey: action.key,
      };
    }
    case SUPPLIERS_SET_CONTACT_KEY: {
      return {
        ...state,
        contactKey: action.key,
      };
    }
    case SUPPLIERS_RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case SUPPLIERS_CONTACTS_RESET_STATE: {
      return {
        ...state,
        contactKey: null,
        contactsData: null,
        watchingContacts: false,
      };
    }
    case ADD_SUPPLIER_CANDIDATES: {
      return {
        ...state,
        candidates: action.data,
      };
    }
    case SUPPLIERS_CANDIDATE_RESET_STATE: {
      return {
        ...state,
        candidates: null,
      };
    }
    default: {
      return state;
    }
  }
}
