import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import colors from '../../config/colors';
import spacing from '../../config/spacing';
import fonts from '../../config/fonts';
import '../../index.scss';

//----------------------------------------------------------------
// STYLES
//----------------------------------------------------------------

const styles = {
  container: {
    borderRadius: spacing.base,
    marginBottom: spacing.base,
    padding: spacing.base,
    color: colors.text,
    width: '70%',
    clear: 'both',
    fontSize: '0.9rem',
    fontFamily: fonts.sub.family,
    fontWeight: fonts.sub.weight,
  },
  messageText: {
    fontSize: '0.9rem',
  },
};

const { objectOf, object, oneOfType, string } = PropTypes;

export default function TimesheetAuditTrailItem(props) {
  const { message } = props;
  const utcDate = moment.utc(message.sent_at).format();
  return (
    <div style={message.admin ? { ...styles.container, float: 'left', backgroundColor: 'rgba(163, 185, 195, 0.3)', borderRadius: '12px 12px 12px 0px' } : { ...styles.container, float: 'right', color: colors.text, backgroundColor: 'rgba(199, 187, 182, 0.3)', borderRadius: '12px 12px 0px 12px', borderColor: colors.positive }} className="space-children-6--bottom">

      {message.title !== 'Message sent' && <p style={{ ...styles.messageText, fontWeight: 600, marginBottom: 12 }}>{message.title}</p>}
      <p>
        {message.text && message.text.split('\n').map(line => (
          <span style={styles.messageText} key={line}>
            {line}
            <br />
          </span>
        ))}
      </p>
      {/* eslint-disable-next-line camelcase */}
      <p style={{ fontSize: 12 }}>{`${message?.admin?.display_name || message?.candidate?.name}, ${moment(utcDate).format('HH:mm dddd DD MMMM YYYY')}`}</p>
    </div>
  );


}

TimesheetAuditTrailItem.propTypes = {
  message: objectOf(oneOfType([
    string,
    object,
  ])),
};

TimesheetAuditTrailItem.defaultProps = {
  message: {},
};
