import React, { useCallback } from 'react';
import { Route } from 'react-router-dom';
import Page from 'components/Page';

import SettingsHeader from '../Settings/SettingsHeader';
import AdminListManagementView from './AdminListManagementView';

import AdminInviteModal from './AdminInviteModal';
import AdminManagementModal from './AdminManagementModal';


const AdminManagementRoutes = ({ match, history }) => {
  const adminManagementRootUrl = match.path;

  const openDetailsModal = useCallback((adminKey) => history.push(`${adminManagementRootUrl}/details/${adminKey}`), []);
  const openInviteModal = useCallback(() => history.push(`${adminManagementRootUrl}/invite`), []);
  const goToListView = useCallback(() => history.push(adminManagementRootUrl), []);

  return (
    <Page vflex title="Manage Admins">
      <SettingsHeader selectedTabKey="admins" />
      <AdminListManagementView openUserInviteModal={openInviteModal} openUserManagementModal={openDetailsModal} />
      <Route
        path={`${adminManagementRootUrl}/details/:adminKey`}
        render={(routeParams) => {
          const { adminKey } = routeParams.match.params;
          return (
            <AdminManagementModal
              adminKey={adminKey}
              closeModal={goToListView}
            />
          );
        }}
      />
      <Route
        path={`${adminManagementRootUrl}/invite`}
        render={() => <AdminInviteModal closeModal={goToListView} />}
      />
    </Page>
  );
};

export default AdminManagementRoutes;
