import React from 'react';
import PropTypes from 'prop-types';

import spacing from 'config/spacing';

const logoWidth = 35;

const styles = {
  container: {
    background: 'linear-gradient(to right, rgba(29,132,228,1) 0%, rgba(3,46,130,1) 100%)',
    color: 'white',
    width: '100vw',
    height: '100vh',
  },
  logo: {
    height: 78,
    padding: spacing.xlarge,
  },
  logoImage: {
    width: logoWidth,
    height: logoWidth,
    marginRight: spacing.base,
    verticalAlign: 'middle',
  },
  logoText: {
    fontWeight: 400,
    verticalAlign: 'middle',
    fontSize: '1em',
    fontFamily: 'Oxygen',
  },
  maintenanceText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    width: 400,
    textAlign: 'center',
  },
};

const { number } = PropTypes;

export default function OfflineMode(props) {
  const containerStyles = { ...styles.container };
  const { screenWidth, screenHeight } = props;

  if (screenWidth) containerStyles.width = screenWidth;
  if (screenHeight) containerStyles.height = screenHeight;

  return (
    <div style={containerStyles}>
      <div style={styles.logo}>
        <img src={require('images/cw-logo.png')} alt="" style={styles.logoImage} />
        <span style={styles.logoText}>circular wave</span>
      </div>

      <div style={styles.maintenanceText}>
        <p style={{ fontSize: '1.5em' }}>Your account is currently inactive.</p>
        <p>Please contact customer support.</p>
      </div>
    </div>
  );
}

OfflineMode.propTypes = {
  screenWidth: number,
  screenHeight: number,
};

OfflineMode.defaultProps = {
  screenWidth: null,
  screenHeight: null,
};
