import React from 'react';
import classNames from 'classnames';
import { MdChevronRight } from 'react-icons/md';
import colors from 'config/colors';
import { ItemCategory, CategoryItem } from 'types/ComplianceTypes';

interface ComplianceItemsList {
  itemCategories: Array<ItemCategory>,
  selectedItemKey: string | null,
  setSelectedItem: (item: CategoryItem) => void,
}

const ComplianceItemsList : React.FC<ComplianceItemsList> = (props: ComplianceItemsList) => {

  return (
    <nav className="compliance-items">
      {props.itemCategories.map((category, i) => (
        <div className="compliance-item-category">
          <h4
            className={classNames('compliance-item-category__title', {
              'compliance-item-category__title--first-in-list': i === 0,
            })}
          >
            {category.name}
          </h4>
          <div className="compliance-items-list">
            {(category.items ?? []).map(item => (
              <Item key={item.key} item={item} itemIsSelected={item.key === props.selectedItemKey} setSelectedItem={props.setSelectedItem} />
            ))}
          </div>
        </div>
      ))}
    </nav>
  );
};

interface ItemProps {
  itemIsSelected: boolean,
  item: CategoryItem,
  setSelectedItem: (item: CategoryItem) => void,
}

const itemStyles = {
  statusCircle: { height: 16, width: 16, borderRadius: '50%' },
  statusText: { flexBasis: '20%', fontWeight: 500, fontSize: '1rem' },
  chevron: { flexBasis: '10%', color: colors.text, fontSize: '24px' },
  centreContainer: {
    display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center',
  },
};

const Item : React.FC<ItemProps> = (props: ItemProps) => {
  return (
    <div
      className={classNames('compliance-list-item', {
        'compliance-list-item--selected': props.itemIsSelected,
      })}
      onClick={() => props.setSelectedItem(props.item)}
      role="button"
      tabIndex={0}
      onFocus={() => undefined}
      onBlur={() => undefined}
      key={props?.item?.key}
    >
      <div className="compliance-list-item__status-circle">
        <div style={{ ...itemStyles.statusCircle, backgroundColor: props?.item?.statusColor ?? colors.grays.light }} />
      </div>
      <div className="compliance-list-item__main">
        <p style={{ fontSize: '1rem', fontWeight: 500 }}>{props.item.name}</p>
        {props.item.summary && <p className="compliance-list-item__summary">{props.item.summary}</p>}
      </div>
      <p style={{ ...itemStyles.statusText, color: props.item.statusColor ?? colors.text }}>{props.item.statusLabel ?? 'MISSING'}</p>
      <MdChevronRight style={itemStyles.chevron} />
    </div>
  );
};

export { ComplianceItemsList };
