import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';

const styles = {
  defaultStatus: {
    backgroundColor: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    fontSize: '1rem',
    borderRadius: 4,
    padding: spacing.base,
    paddingTop: spacing.small,
    paddingBottom: spacing.small,
    fontFamily: fonts.button.family,
    fontWeight: fonts.button.weight,
    textAlign: 'center',
    width: 80,
  },
};

const statusMappers = {
  active: {
    title: 'Active',
    borderColor: colors.text,
    color: colors.text,
  },
  suspended: {
    title: 'Suspended',
    borderColor: colors.cavalry.error,
    color: colors.cavalry.error,
  },
  removed: {
    title: 'Removed',
    borderColor: colors.cavalry.error,
    color: colors.cavalry.error,
  },
  pendingRemoval: {
    title: 'Active until',
    borderColor: colors.text,
    color: colors.text,
    paddingTop: 3,
    paddingBottom: 4,
    lineHeight: 1,
  },
};

export default function StatusIndicator(props) {
  const status = statusMappers[props.status];

  return (
    <div
      style={{
        ...styles.defaultStatus,
        ...status,
      }}
    >
      <p>{status.title}</p>
      {props.removedAt ? moment(props.removedAt).format('Do MMM YYYY') : null}
    </div>
  );
}

const { string } = PropTypes;
StatusIndicator.propTypes = {
  status: string,
  removedAt: string,
};

StatusIndicator.defaultProps = {
  status: 'active',
  removedAt: null,
};
