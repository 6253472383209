import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SitesPolicies from './SitesPolicies';
import Page from '../../components/Page';
import policiesTabs from './policiesTabs';
import { updatePolicies } from '../../lib/api/accounts';
import * as policiesThunks from '../../thunks/policies';
import * as policiesReducer from '../../reducers/policies';
import * as rateCardThunks from '../../thunks/rateCards';
import RateCardEditModal from './RateCardEditModal';
import { isFeatureOn } from '../../lib/features';
import { permissions } from '../../config/permissions';

const { func, object, string, array, objectOf } = PropTypes;
class SitesPoliciesContainer extends Component {
  static propTypes = {
    adminSites: object.isRequired,
    stopWatchingOrgSiteRateCards: func.isRequired,
    selectedSite: string,
    fetchOrgSiteRateCards: func.isRequired,
    siteRateCards: array.isRequired,
    startWatchingPolicies: func.isRequired,
    stopWatchingPolicies: func.isRequired,
    policies: objectOf(object),
    type: string.isRequired,
  }

  static defaultProps = {
    selectedSite: null,
    policies: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedSiteKey: props.adminSites && Object.keys(props.adminSites).length > 0 ? Object.keys(props.adminSites)[0] : '',
      modalOpen: false,
      rateCard: null,
      rateCardCreateModalOpen: false,
      rateCardKey: null,
    };
  }

  UNSAFE_componentWillMount = async () => {
    await this.props.setType('site');
    await this.props.addSelectedSite(this.state.selectedSiteKey);
    await this.props.fetchOrgSiteRateCards('site', this.state.selectedSiteKey);
    this.props.startWatchingPolicies();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.siteRateCards !== this.props.siteRateCards) {
      this.props.siteRateCards.forEach((rateCard) => {
        if (rateCard.key === this.state.rateCardKey) {
          this.setState({
            rateCard,
          });
        }
      });
    }

    if (nextProps.selectedSite !== this.props.selectedSite) {
      this.props.stopWatchingPolicies();
      this.props.startWatchingPolicies(nextProps.selectedSite);
    }
  }

  componentWillUnmount() {
    this.props.stopWatchingOrgSiteRateCards('site', this.props.selectedSite);
    this.props.stopWatchingPolicies();
  }

  onSiteClick = async (key) => {
    await this.props.addSelectedSite(key);
    await this.props.fetchOrgSiteRateCards('site', key);
    this.setState({ selectedSiteKey: key });
  }

  onRateCardClick = (rateCardKey, rateCard) => {
    console.log('clicked', rateCardKey, rateCard);
    this.setState({ modalOpen: true, rateCardKey, rateCard });
  }

  formatSites = (sites) => {
    const items = [];
    Object.keys(sites).forEach((siteId) => {
      const item = {
        key: siteId,
        header: sites[siteId].name,
      };
      items.push(item);
    });

    items.sort((a, b) => {
      const aName = a.header;
      const bName = b.header;
      if (aName < bName) return -1;
      if (bName < aName) return 1;
      return 0;
    });

    return items;
  }

  updatePolicies = (type, data) => {
    updatePolicies({
      type,
      data,
      siteId: this.props.selectedSite,
    });
  }

  canEdit = () => {
    return isFeatureOn(null, permissions.SITES_CAN_EDIT_POLICIES, this.props.user, this.props.global);
  }

  render() {
    return (
      <React.Fragment>
        <Page disableScroll title="Policies: Your Sites" tabs={policiesTabs}>
          <SitesPolicies
            onSiteClick={this.onSiteClick}
            selectedSiteKey={this.state.selectedSiteKey}
            loading={this.props.adminSites ? Object.keys(this.props.adminSites).length === 0 : true}
            items={this.formatSites(this.props.adminSites || {})}
            openRateCardCreateModal={() => this.setState({ rateCardCreateModalOpen: true })}
            closeRateCardCreateModal={() => this.setState({ rateCardCreateModalOpen: false })}
            rateCardCreateModalOpen={this.state.rateCardCreateModalOpen}
            onRateCardClick={this.onRateCardClick}
            policies={this.props.policies}
            updatePolicies={this.updatePolicies}
            type={this.props.type}
            canEdit={this.canEdit()}
          />
        </Page>
        <RateCardEditModal
          modalOpen={this.state.modalOpen}
          onClose={() => { this.setState({ modalOpen: false }); }}
          rateCard={this.state.rateCard}
          selectedSite={this.props.selectedSite}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps({ global, policies, rateCards, user }) {
  return {
    adminSites: user.sites || {},
    selectedSite: policies.selectedSite,
    siteRateCards: rateCards.siteRateCards,
    policies: policies.data,
    type: policies.policiesType,
    global,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stopWatchingOrgSiteRateCards: (type, siteKey) => dispatch(rateCardThunks.stopWatchingOrgSiteRateCards(type, siteKey)),
    addSelectedSite: siteKey => dispatch(policiesReducer.addSelectedSite(siteKey)),
    fetchOrgSiteRateCards: (type, siteKey) => dispatch(rateCardThunks.fetchOrgSiteRateCards(type, siteKey)),
    setType: policiesType => dispatch(policiesReducer.setType(policiesType)),
    startWatchingPolicies: () => dispatch(policiesThunks.startWatchingPolicies()),
    stopWatchingPolicies: () => dispatch(policiesThunks.stopWatchingPolicies()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SitesPoliciesContainer);
