import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchShiftDetails } from 'thunks/jobs';
import { incrementShiftRefCount, decrementShiftRefCount } from 'reducers/jobs';
import { isFeatureOn } from 'lib/features';
import { featureFlags } from 'config/featureFlags';
import { permissions } from 'config/permissions';
import FullSizeModal from 'components/FullSizeModal';
import Loading from 'components/Loading';
import { TimesheetDetailsHeader } from './TimesheetDetailsHeader';
import { TimesheetSubmission } from './TimesheetSubmission';
import { CandidateRatingForm } from './CandidateRatingForm';
import TimesheetMessages from './TimesheetMessages';
import { TimesheetRejectModal } from './TimesheetRejectModal';
import * as timesheetsThunks from '../../thunks/timesheets';
import spacing from '../../config/spacing';
import './style.scss';


const { string, func, bool, arrayOf, object, objectOf } = PropTypes;
class TimesheetDetailsContainer extends Component {

  static propTypes = {
    fetchShiftDetails: func.isRequired,
    fetchTimesheetRejectReasons: func.isRequired,
    shiftKey: string.isRequired,
    candidateKey: string.isRequired,
    approveTimesheet: func.isRequired,
    rejectTimesheet: func.isRequired,
    goToTimesheetsListView: func.isRequired,
    adminGroups: arrayOf(object).isRequired,
    isRejectingTimesheets: bool.isRequired,
    isApprovingTimesheets: bool.isRequired,
    admin: objectOf(object),
    global: objectOf(object),
    adminOrgKey: string.isRequired,
  }

  static defaultProps = {
    admin: null,
    global: null,
  }

  state = { showRejectModal: false }

  componentDidMount = async () => {
    this.props.incrementShiftRefCount(this.props.shiftKey);
    this.props.fetchShiftDetails(this.props.shiftKey, this.props.candidateKey);
    this.props.fetchCandidateRatingCategories(this.props.candidateKey);
    this.querySubscription = await this.props.watchMessages(this.props.timesheetKey);
  }

  componentWillUnmount() {
    this.props.decrementShiftRefCount(this.props.shiftKey);
    this.querySubscription.unsubscribe();
  }

  getPermissionsMessage = (shiftAdminGroups, adminIsInCareGroup) => {
    if (!this.props.adminCanApproveTimesheet && !this.props.adminCanRejectTimesheet) return <div style={{ marginTop: spacing.base }}>You do not have the required permissions to approve or reject this timesheet</div>;
    if (!this.props.adminCanApproveTimesheet) return <div style={{ marginTop: spacing.base }}>You do not have the required permissions to approve this timesheet</div>;
    if (!this.props.adminCanRejectTimesheet) return <div style={{ marginTop: spacing.base }}>You do not have the required permissions to reject this timesheet</div>;
    if (shiftAdminGroups && !adminIsInCareGroup) return <div style={{ marginTop: spacing.base }}>You are not an Admin Group which has permissions to approve this timesheet.</div>;
    return null;
  }

  render() {
    const showTimesheetDetails = this.props.shift && this.props.booking && !this.props.timesheetsError && !this.props.fetchingShiftDetails;
    const shiftAdminGroups = this.props?.shift?.adminGroups ?? null;
    const adminIsInCareGroup = (shiftAdminGroups || []).some(adminGroup => this.props.adminGroups.includes(adminGroup.key));
    const timesheetPermissionsMessage = this.getPermissionsMessage(shiftAdminGroups, adminIsInCareGroup);
    const submittedRatings = this.props.booking?.submittedRatings;
    const orgHasRatingCategories = this.props.candidateRatingCategories.length > 0;

    return (
      <FullSizeModal
        isOpen
        vflex
        onRequestClose={this.props.goToTimesheetsListView}
      >
        {this.props.isFetchingShiftDetails && (
          <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Loading />
          </div>
        )}
        {showTimesheetDetails && (
          <div className="timesheet-details">
            <TimesheetDetailsHeader
              onRequestClose={this.props.goToTimesheetsListView}
              shift={this.props.shift}
              booking={this.props.booking}
              ratingsFeatureIsOn={this.props.candidateRatingCategories.length > 0}
              servicesFeatureFlag={this.props.servicesFeatureFlag}
            />
            <div className="timesheet-details__row">
              <div className="timesheet-details__column column-left">
                <TimesheetSubmission
                  shift={this.props.shift}
                  booking={this.props.booking}
                  approveTimesheet={this.props.approveTimesheet}
                  timesheetApprovalDisclaimer={this.props.timesheetApprovalDisclaimer}
                  openRejectModal={() => this.setState({ showRejectModal: true })}
                  isApprovingTimesheets={this.props.isApprovingTimesheets}
                  isRejectingTimesheets={this.props.isRejectingTimesheets}
                  timesheetRejectReasons={this.props.timesheetRejectReasons}
                  timesheetPermissionsMessage={timesheetPermissionsMessage}
                  candidateHasSubmittedTimesheet={!!((this.props.booking.submittedStartTime && this.props.booking.submittedEndTime) || (typeof this.props.booking.submittedOnSiteTime === 'number' && typeof this.props.booking.submittedOffSiteTime === 'number') || this.props.booking.submittedMultiTimes)}
                  timesheetStatus={this.props.booking.status}
                  shiftAdminGroups={shiftAdminGroups}
                  adminIsInCareGroup={adminIsInCareGroup}
                  adminOrgKey={this.props.adminOrgKey}
                  canManageLockedTimesheets={this.props.canManageLockedTimesheets}
                />
                {orgHasRatingCategories && (
                  <CandidateRatingForm
                    candidateRatingCategories={this.props.candidateRatingCategories}
                    submitCandidateRatings={({ ratings, comment }) => this.props.submitCandidateRatings({ ratings, comment, shiftKey: this.props.shiftKey, candidateKey: this.props.candidateKey })}
                    submittedRatings={submittedRatings}
                    submittedReview={this.props.booking.submittedReview}
                    ratingSubmittedBy={this.props.booking.ratingSubmittedBy}
                    ratingSubmittedAt={this.props.booking.ratingSubmittedAt}
                    submittingCandidateRatings={this.props.submittingCandidateRatings}
                    adminIsInSameOrgAsShift={this.props.adminOrgKey === this.props.shift?.orgKey}
                  />
                )}
              </div>
              <div className="timesheet-details__column column-right">
                <TimesheetMessages
                  shiftKey={this.props.shiftKey}
                  candidateKey={this.props.candidateKey}
                  timesheetKey={this.props.timesheetKey}
                />
              </div>
            </div>
          </div>
        )}
        {this.props.timesheetRejectReasons && (
          <TimesheetRejectModal
            rejectReasons={this.props.timesheetRejectReasons.map(reason => ({ value: reason.key, label: reason.name }))}
            modalOpen={this.state.showRejectModal}
            onClose={() => this.setState({ showRejectModal: false })}
            rejectTimesheet={rejectReason => this.props.rejectTimesheet(this.props.timesheetKey, this.props.shiftKey, this.props.candidateKey, { reasonKey: rejectReason.value, reasonText: rejectReason.label })}
          />
        )}
      </FullSizeModal>
    );
  }
}


function mapStateToProps({ user, global, timesheets, jobs }, ownProps) {

  const shift = jobs.details[ownProps.shiftKey];

  return {
    adminGroups: user.adminGroups,
    isRejectingTimesheets: timesheets.isRejectingTimesheets,
    isApprovingTimesheets: timesheets.isApprovingTimesheets,
    adminCanApproveTimesheet: isFeatureOn(null, permissions.TIMESHEETS_CAN_APPROVE, user, global),
    adminCanRejectTimesheet: isFeatureOn(null, permissions.TIMESHEETS_CAN_REJECT, user, global),
    canManageLockedTimesheets: isFeatureOn(null, 'timesheetsCanManageLockedTimesheets', user, global),
    timesheetRejectReasons: timesheets.timesheetRejectReasons,
    shift: shift?.details,
    booking: shift?.bookings?.[0],
    isFetchingShiftDetails: shift?.isFetchingShiftDetails,
    timesheetsError: timesheets.timesheetsError,
    candidateRatingCategories: timesheets.candidateRatingCategories,
    submittingCandidateRatings: timesheets.submittingCandidateRatings,
    adminOrgKey: global.currentOrgKey,
    timesheetApprovalDisclaimer: global.orgConfig?.timesheetApprovalDisclaimer,
    servicesFeatureFlag: isFeatureOn(featureFlags.SERVICES, null, user, global),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    approveTimesheet: (timesheetKey, shiftKey, candidateKey) => dispatch(timesheetsThunks.approveTimesheet(timesheetKey, shiftKey, candidateKey)),
    rejectTimesheet: (timesheetKey, shiftKey, candidateKey, rejectReason) => dispatch(timesheetsThunks.rejectTimesheet(timesheetKey, shiftKey, candidateKey, rejectReason)),
    fetchShiftDetails: (shiftKey, candidateKey) => dispatch(fetchShiftDetails(shiftKey, candidateKey)),
    fetchCandidateRatingCategories: candidateKey => dispatch(timesheetsThunks.fetchCandidateRatingCategories(candidateKey)),
    submitCandidateRatings: ({ ratings, comment, shiftKey, candidateKey }) => dispatch(timesheetsThunks.submitCandidateRatings({ ratings, comment, shiftKey, candidateKey })),
    incrementShiftRefCount: shiftKey => dispatch(incrementShiftRefCount(shiftKey)),
    decrementShiftRefCount: shiftKey => dispatch(decrementShiftRefCount(shiftKey)),
    watchMessages: timesheetKey => dispatch(timesheetsThunks.watchMessages(timesheetKey)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetDetailsContainer);
