import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getUserDisplayName } from '../../lib/helpers-user';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import SectionedList from '../../components/SectionedList';
import EmptyCard from '../../components/EmptyCard';

const { object, func } = PropTypes;

const styles = {
  mainContainer: {
    overflowY: 'scroll',
  },
  header: {
    display: 'inline-block',
    marginTop: 20,
    marginBottom: 20,
    padding: '5px 40px',
    borderRadius: 20,
    border: `1px solid ${colors.text}`,
    backgroundColor: colors.white,
    color: colors.text,
    fontFamily: fonts.main.family,
    fontSize: fonts.main.size,
    fontWeight: fonts.header.weight,
  },
  rowContainer: {
    width: '90%',
    boxSizing: 'border-box',
    margin: '10px auto 10px auto',
    padding: 20,
    borderRadius: 20,
    border: `1px solid ${colors.grays.ultraLight}`,
    boxShadow: `2px 2px 5px 1px ${colors.grays.ultraLight}`,
    cursor: 'pointer',
  },
  contactInfoContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    width: '40%',
  },
  contactData: {
    color: colors.text,
    fontSize: fonts.main.size,
    fontWeight: fonts.main.weight,
  },
  name: {
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    color: colors.text,
  },
  email: {
    color: colors.text,
    fontSize: fonts.sub.size,
  },
  responsibilitiesContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    height: '100%',
    width: '60%',
  },
};

export default class SupplierContacts extends Component {

  static propTypes = {
    contacts: object,
    handleContactClicked: func.isRequired,
  }

  static defaultProps = {
    contacts: {},
  }

  renderSectionHeader = (sectionHeaderText) => {
    return (
      <div style={styles.header}>{sectionHeaderText}</div>
    );
  }

  renderRow = (rowData, rowKey) => {
    const nameForDisplay = getUserDisplayName(rowData);
    const tel = rowData.phone || {};
    const telStr = tel.base || '';
    return (
      <div
        key={rowKey}
        onClick={() => this.props.handleContactClicked(rowData.contactKey)}
        style={styles.rowContainer}
      >
        <div style={styles.contactInfoContainer}>
          <div style={{ ...styles.contactData, ...styles.name }}>{nameForDisplay}</div>
          <div style={{ ...styles.contactData, ...styles.phone }}>{telStr}</div>
          <div style={{ ...styles.contactData, ...styles.email }}>
            <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const contacts = this.props.contacts || {};
    const mainContainerHeight = { height: this.props.screen.height * 0.6 };
    return (
      <div style={{ ...styles.mainContainer, ...mainContainerHeight }}>
        {
          Object.keys(contacts).length > 0 ?
            <SectionedList
              data={contacts}
              getSectionHeaderText={value => value.charAt(0).toUpperCase()}
              handleRowClicked={this.props.handleContactClicked}
              renderRow={this.renderRow}
              renderSectionHeader={this.renderSectionHeader}
              sectionSortProperty="firstName"
              sectionSortOrder="ascending"
              rowSortProperty="displayName"
              rowSortOrder="ascending"
            /> :
            <EmptyCard
              dimensions={{ height: (this.props.screen.height * 0.6) }}
              message="No contacts for this supplier"
            />
        }
      </div>
    );
  }
}
