import moment from 'moment';

import { DAYS_FOR_OVERDUE } from './timesheet-constants';

function isOverdue(jobEndTime) {
  const becomesOverdue = moment(jobEndTime).add(DAYS_FOR_OVERDUE, 'days');
  const now = moment();
  return becomesOverdue.isBefore(now);
}


function getActualStartAndEndTimes(times, time) {
  const { start, end } = times;
  let actualStartTime;
  let actualEndTime;
  if (start) {
    actualStartTime = moment(start.value).format('HH:mm');
  }

  if (end) {
    actualEndTime = moment(end.value).format('HH:mm');
  }
  if (time === 'start') {
    return actualStartTime;
  }
  return actualEndTime;


}

export {
  isOverdue,
  getActualStartAndEndTimes,
};
