import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdEdit, MdDelete } from 'react-icons/md';
import colors from '../../config/colors';
import spacing from '../../config/spacing';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import fonts from '../../config/fonts';
import * as api from '../../lib/api';
import * as rateCardReducer from '../../reducers/rateCards';
import './RateCardEditModal.css';

const styles = {
  container: {
    textAlign: 'center',
    width: '300px',
  },
  content: {
    backgroundColor: colors.white,
  },
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    margin: spacing.base,
  },
  table: {
    width: '100%',
    color: colors.positive,
  },
  editDelete: {
    float: 'right',
  },
  th: {
    fontWeight: fonts.title.weight,
  },
  td: {
    width: '40%',
    textAlign: 'left',
  },
  headerInput: {
    textAlign: 'center',
    color: colors.positive,
    outline: 'none',
    border: 'none',
    fontSize: fonts.header.size,
    lineHeight: 0,
    margin: spacing.base,
  },
  rateInput: {
    outline: 'none',
    border: 'none',
    margin: 0,
    fontSize: fonts.sub.size,
    lineHeight: 0,
    paddingLeft: '2px',
    maxWidth: '100px',
  },
  warning: {
    margin: spacing.base,
    color: colors.red,
  },
  tableLabels: {
    width: '60%',
  },
};

function convertToSortedArray(data) {
  return Object.keys(data).map((key) => {
    return data[key];
  }).sort((a, b) => {
    return a.rate - b.rate;
  });
}

const { bool, func, object } = PropTypes;
class RateCardEditModal extends Component {
  static propTypes = {
    onClose: func.isRequired,
    modalOpen: bool.isRequired,
    rateCard: object,
  }

  static defaultProps = {
    rateCard: null,
  }

  state = {
    mode: 'view',
    rates: {},
    name: '',
  }

  editMode = () => {
    this.setState({ mode: 'edit' });
  }

  deleteMode = () => {
    this.setState({ mode: 'delete' });
  }

  cancelEditing = () => {
    this.setState({ mode: 'view' });
  }

  cancelDelete = () => {
    this.setState({ mode: 'view' });
  }

  confirmDelete = async () => {
    const { policiesType } = this.props;
    const payload = {
      type: policiesType,
      org: policiesType === 'org' ? this.props.selectedOrg : null,
      site: policiesType === 'site' ? this.props.selectedSite : null,
      rateCardKey: this.props.rateCard.key,
      roleKey: this.props.rateCard.role,
      gradeKey: this.props.rateCard.grade,
      specialityKey: this.props.rateCard.speciality ? this.props.rateCard.speciality : null,
    };

    await api.post('rateCard/delete', payload);

    this.props.deleteRateCard(policiesType, this.props.rateCard.key);
    this.setState({ mode: 'view', rates: {} });
    this.props.onClose();
  }

  saveEditing = async () => {
    const newName = this.state.name && this.state.name !== '' && this.state.name !== this.props.rateCard.name ? this.state.name : null;
    const { policiesType } = this.props;
    const { rates } = this.state;
    const payload = {
      type: policiesType,
      org: policiesType === 'org' ? this.props.selectedOrg : null,
      site: policiesType === 'site' ? this.props.selectedSite : null,
      rateCardKey: this.props.rateCard.key,
      rates,
      roleKey: this.props.rateCard.role,
      gradeKey: this.props.rateCard.grade,
      specialityKey: this.props.rateCard.speciality ? this.props.rateCard.speciality : null,
      name: newName,
    };

    await api.post('rateCard/edit', payload);

    this.props.updateRateCard(policiesType, this.props.rateCard.key, rates, newName);

    this.setState({ mode: 'view', rates: {} });
  }

  onChangeRate = (event, itemKey) => {
    const newRates = { ...this.state.rates, ...{ [itemKey]: event.target.value } };
    this.setState({ rates: newRates });
  }

  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  }

  canSaveEdit = () => {
    let canSave = false;
    if (this.state.name && this.state.name !== '' && this.state.name !== this.props.rateCard.name) {
      canSave = true;
    }
    const rateCardItems = Object.keys(this.props.rateCard.items);
    if (rateCardItems) {
      rateCardItems.forEach((rateCardItem) => {
        if (this.state.rates[rateCardItem] &&
          this.state.rates[rateCardItem] !== this.props.rateCard.items[rateCardItem].rate) {
          canSave = true;
        }
      });
    }
    return canSave;
  }

  close = () => {
    this.setState({ mode: 'view', rates: {}, name: '' });
    this.props.onClose();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalOpen}
        onRequestClose={this.close}
        contentLabel="Edit Ratecard"
        contentStyle={styles.container}
      >
        {this.props.rateCard && this.props.rateCard.key && this.props.rateCard.items ?
          <div style={styles.content}>
            {this.state.mode === 'view' ?
              <div style={styles.editDelete}>
                <MdEdit style={{ color: colors.text }} onClick={this.editMode} className="rise highlightIcon" />
                <MdDelete style={{ color: colors.text }} onClick={this.deleteMode} className="rise highlightIcon" />
              </div>
              : null}
            {this.state.mode === 'edit' ?
              <input
                type="text"
                placeholder={this.props.rateCard.name}
                value={this.state.name}
                onChange={event => this.onChangeName(event)}
                style={styles.headerInput}
              />
              :
              <h1 style={styles.header}>{this.props.rateCard.name}</h1>
            }
            <table style={styles.table}>
              <thead style={styles.th}>
                <tr>
                  <td>Hours</td>
                  <td style={{ textAlign: 'left' }}>Rate</td>
                </tr>
              </thead>
              <tbody>
                {convertToSortedArray(this.props.rateCard.items).map(item => (
                  <tr key={item.key}>
                    <td style={styles.tableLabels}>{item.label}</td>
                    {this.state.mode !== 'edit' ?
                      <td style={styles.td}>
£
                        {item.rate.toFixed(2)}
                      </td>
                      :
                      <td style={styles.td}>
£
                        <input
                          type="number"
                          placeholder={item.rate.toFixed(2)}
                          value={this.state.rates[item.key]}
                          onChange={event => this.onChangeRate(event, item.key)}
                          style={styles.rateInput}
                        />
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
            {this.state.mode === 'edit' ?
              <div>
                <Button
                  onClick={this.cancelEditing}
                  style={{ backgroundColor: colors.negative, marginRight: spacing.small, marginTop: spacing.base }}
                >
Cancel
                </Button>
                <Button
                  onClick={this.saveEditing}
                  disabled={!this.canSaveEdit()}
                  style={{ marginLeft: spacing.small, marginTop: spacing.base }}
                >
Save Changes
                </Button>
              </div>
              : null}
            {this.state.mode === 'delete' ?
              <div>
                <p style={styles.warning}>Are you sure you want to delete this rate card?</p>
                <Button
                  onClick={this.cancelDelete}
                  style={{ backgroundColor: colors.negative, marginRight: spacing.small, marginTop: spacing.base }}
                >
Cancel
                </Button>
                <Button
                  onClick={this.confirmDelete}
                  style={{ marginLeft: spacing.small, marginTop: spacing.base }}
                >
Delete
                </Button>
              </div>
              : null}
          </div>
          : <div />}
      </Modal>
    );
  }
}

function mapStateToProps({ global, rateCards, policies }) {
  return {
    authorisedOrgs: global.employerAuthorisedOrgs,
    orgRateCards: rateCards.orgRateCards,
    isfetchingOrgRateCards: rateCards.isfetchingOrgRateCards,
    selectedOrg: policies.selectedOrg,
    selectedSite: policies.selectedSite,
    policiesType: policies.policiesType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteRateCard: (type, rateCardKey) => dispatch(rateCardReducer.deleteRateCard(type, rateCardKey)),
    updateRateCard: (type, rateCardKey, rates, newName) => dispatch(rateCardReducer.updateRateCard(type, rateCardKey, rates, newName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RateCardEditModal);
