import React, { useState } from 'react';
import colors from 'config/colors';
import fonts from 'config/fonts';
import spacing from 'config/spacing';
import Button from 'components/Button';

const styles = {
  header: {
    textAlign: 'center',
    marginBottom: spacing.base,
    lineHeight: 1.45,
  },
  container: {
    borderRadius: spacing.tiny,
    width: '80%',
    maxWidth: 320,
    margin: '0 auto',
    textAlign: 'center',
    // ...fonts.title,
    fontSize: '1rem',
    color: colors.text,
    padding: spacing.base,
    marginTop: 30,
    backgroundColor: colors.white,
  },
  button: {
    border: '0px solid black',
    color: colors.white,
    padding: spacing.base,
    borderRadius: spacing.tiny,
    width: '100%',
    fontSize: fonts.sub.size,
  },
  message: {
    textAlign: 'center',
    padding: '10px',
    paddingBottom: '15px',
    fontSize: '0.9rem',
  },
};

function MobileBlocker(props) {

  const [dismissed, setDismissed] = useState(false);

  if (dismissed) return null;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.cavalry.backgroundLight6,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999999,
      }}
    >
      <div style={styles.container}>
        <div style={styles.header}>
          <h2>Mobile Access Experimental</h2>
        </div>
        <p style={styles.message}>
          Accessing the Dagny Management Portal via mobile browsers is not officially supported and is provided on a best effort basis. It is useable, but bugs should be expected.
          <br />
          <br />
          If you are trying to access as a candidate (submit timesheets, view shifts etc), please use the mobile app.
        </p>
        <Button large onClick={() => setDismissed(true)}>Continue Anyway</Button>
      </div>
    </div>
  );
}

export default MobileBlocker;
