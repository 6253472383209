import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import Checkbox from 'components/Checkbox';
import colors from '../../../../config/colors';
import './manage-banks-agencies.scss';

const styles = {
  modalContent: {
    width: '40%',
    height: '60%',
  },
};

export const ManageBanksAndAgenciesModal = (props) => {

  const [options, toggleOptions] = useState(props?.options ?? []);
  const [showConfirmScreen, setShowConfirmScreens] = useState(false);

  const toggleOption = (i) => {
    const updatedOptions = [...options];
    updatedOptions[i] = { ...updatedOptions[i], checked: !options[i].checked };
    toggleOptions(updatedOptions);
  };

  const compareChangesToPreviousState = () => {
    return (props?.options ?? []).some((option, index) => {
      return option.checked !== options[index].checked;
    });
  };

  const getReleasedAndRetractedDiff = () => {
    const released = [];
    const retracted = [];
    options.forEach((option, index) => {
      if (option.checked && !props.options[index].checked) released.push(option);
      if (!option.checked && props.options[index].checked) retracted.push(option);
    });
    return { released, retracted };
  };

  const createUpdatePayload = () => {
    const banks = {};
    const agencies = {};
    options.forEach((option) => {
      banks[option.key] = !!option.checked;
    });
    // Todo - Add agency payload when we support 'Manage Agencies' feature
    return { banks, agencies };
  };

  const changesComparedToPreviousShiftState = compareChangesToPreviousState();
  const { released, retracted } = getReleasedAndRetractedDiff();
  const cannotReleaseToBank = !props.canRelease && !!released.length;
  const cannotRetractBank = !props.canRetract && !!retracted.length;
  const nextButtonDisabled = !changesComparedToPreviousShiftState || cannotReleaseToBank || cannotRetractBank;
  return (
    <Modal vflex header={props.header} onRequestClose={props.onClose} contentStyle={styles.modalContent} isOpen={props.isOpen}>
      <CloseButton top={15} right={15} handleClose={props.onClose} />
      <div className="manage-modal__container">
        {!showConfirmScreen && (
          <>
            <div className="manage-modal__content-container">
              <p className="manage-modal__about">{`Tick to release to a ${props.type}. Untick to retract a ${props.type}.`}</p>
              <div className="manage-modal__list">
                {options.map((option, index) => (
                  <div className="manage-modal__item">
                    <p className="manage-modal__item-text">{option.name}</p>
                    <div>
                      <Checkbox
                        checked={options[index].checked}
                        disabled={false}
                        onChange={() => toggleOption(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {cannotReleaseToBank && <p>You do not have the correct permissions to release a shift to staff banks.</p>}
            {cannotRetractBank && !!retracted.length && <p>You do not have the correct permissions to retract staff banks.</p>}
            <div className="manage-modal__buttons space-children-12">
              <Button white outline shadow={false} onClick={props.onClose}>Cancel</Button>
              <Button black shadow={false} onClick={() => setShowConfirmScreens(true)} disabled={nextButtonDisabled}>Next</Button>
            </div>
          </>
        )}
        {showConfirmScreen && (
          <>
            <div className="manage-modal__content-container">
              {!!released.length && (
                <div className={`manage-modal__summary${!retracted.length ? '--full-height' : ''}`}>
                  <p className="manage-modal__about">{`The shift will be released to the following ${props.type}s:`}</p>
                  <div className="manage-modal__list">
                    {released.map(item => <p className="manage-modal__item-text">{item.name}</p>)}
                  </div>
                </div>
              )}
              {!!retracted.length && (
                <div className="manage-modal__summary--full-height">
                  <p className="manage-modal__about">{`The following ${props.type}s will be retracted:`}</p>
                  <div className="manage-modal__list">
                    {retracted.map(item => <p className="manage-modal__item-text">{item.name}</p>)}
                  </div>
                </div>
              )}
            </div>
            <div className="manage-modal__buttons space-children-12">
              <Button white outline shadow={false} onClick={() => setShowConfirmScreens(false)}>Go Back</Button>
              <Button black shadow={false} onClick={() => props.submit(createUpdatePayload())}>Confirm Changes</Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const { arrayOf, shape, bool, string, func } = PropTypes;

ManageBanksAndAgenciesModal.propTypes = {
  options: arrayOf(shape({ key: string, name: string, checked: bool })).isRequired,
  onClose: func.isRequired,
  submit: func.isRequired,
  header: string.isRequired,
  type: string.isRequired,
  isOpen: bool.isRequired,
  canRelease: bool.isRequired,
  canRetract: bool.isRequired,
};
