import React from 'react';
import { useSelector } from 'react-redux';

import { OrgBank } from 'types/Banks';
import { useFeatureFlag } from 'hooks/feature';

import colors from 'config/colors';

import DropDown from 'components/DropDown';
import { TabBar } from 'components/Tabs';
import FilterBox from '../../../components/FilterBox';

const styles = {
  bankSelectorContainer: {
    width: 250,
    // marginTop: 3,
    padding: '3px 6px',
    border: '1px solid #EAEAEA',
    backgroundColor: colors.cavalry.backgroundLight6,
  },
};

interface BankTabBarProps {
  selectedTabKey: string;
  setSelectedTab: (newBank: string) => void;
  selectedBankKey: string;
  setSelectedBank: (newTab: string) => void;
  filterString: string;
  setFilterString: (newFilterString: string) => void;
}

interface OrgBankOption {
  name: string;
  bankKey: string;
}

const BankTabBar : React.FC<BankTabBarProps> = (props: BankTabBarProps) => {

  const adminBanks = useSelector(state => state.global.adminBanks);
  const canViewRemoved = useFeatureFlag(null, 'bankCanViewRemoved');
  const isMobile = useSelector(state => state.screen.isMobile);

  const bankOptions = [{ bankKey: 'all', name: 'All Banks' }, ...adminBanks.filter((bank: OrgBank) => bank.canViewStaff)];
  const selectedBankOption = bankOptions.find((option: OrgBankOption) => option.bankKey === props.selectedBankKey);

  const tabs = [
    { title: 'All', key: 'all' },
    { title: 'Signing Up', key: 'signing-up' },
    { title: 'Non Compliant', key: 'non-compliant' },
    { title: 'For Review', key: 'for-review' },
    { title: 'Approved', key: 'approved' },
    { title: 'New', key: 'new' },
    canViewRemoved ? { title: 'Removed', key: 'removed' } : null,
  ].filter(Boolean);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={styles.bankSelectorContainer}>
        <DropDown
          isClearable={false}
          isSearchable={false}
          options={bankOptions}
          value={selectedBankOption}
          getOptionLabel={(bank: OrgBankOption) => bank.name}
          getOptionValue={(bank: OrgBankOption) => bank.bankKey}
          customStyles={{ menuPortal: { zIndex: 10000000 }, option: { height: null } }}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          portalPlacement="bottom"
          onChange={(newSelectedBank: OrgBankOption) => props.setSelectedBank(newSelectedBank.bankKey)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center' }}>
        <TabBar
          tabs={tabs}
          selectedTabKey={props.selectedTabKey}
          onTabClick={props.setSelectedTab}
          buttons={
            !isMobile ? <FilterBox
              style={{ width: 250, padding: '12px', border: `1px solid ${colors.cavalry.line}`, color: colors.text, fontSize: '0.9rem' }}
              filterString={props.filterString}
              setFilterString={props.setFilterString}
              placeholder="Filter candidates by name..."
            /> : null
          }
        />
      </div>
    </div>
  );
};

export default BankTabBar;
