import React from 'react';
import PropTypes from 'prop-types';
import { MdPerson, MdStar, MdBusinessCenter } from 'react-icons/md';
import moment from 'moment-timezone';
import ComplianceStatus from 'components/ComplianceStatus';
import Pill from '../../components/Pill';
import colors from '../../config/colors';
import { PaginatedDataTable } from '../../components/DataTable';

import './BankManagement.css';

const styles = {
  dataTable: {
    maxHeight: '100%',
  },
  tableHeaderIcon: {
    paddingBottom: 3,
  },
};

const BankManagement = (props) => {

  const currentDateString = moment.utc().toISOString();

  const hasUsefulGrades = Object.values(props.rgsMetadata)
    .map(role => Object.keys(role.grades).length)
    .some(roleGradeCount => roleGradeCount > 1);

  const hasUsefulSpecialities = Object.values(props.rgsMetadata)
    .map(role => Object.keys(role.specialities).length)
    .some(roleSpecialityCount => roleSpecialityCount > 1);

  const dataTableColumns = [
    {
      name: 'name',
      header: 'Name',
    },
    {
      name: 'accountStatus',
      header: 'Status',
      formatter: (candidate) => {
        const { accountSuspended, removedAt, isCompliant, profileComplete } = candidate;

        if (removedAt && removedAt < currentDateString) {
          return (<Pill
            size="tiny"
            type="danger"
            label="Removed"
          />);
        } else if (removedAt && removedAt > currentDateString) {
          return `Active until: ${moment(removedAt).format('Do MMM YYYY')}`;
        } else if (accountSuspended) {
          return (<Pill
            size="tiny"
            type="danger"
            label="Suspended"
          />);
        } else if (!profileComplete) {
          return (<Pill
            size="tiny"
            type="negative"
            label="Signing Up"
          />);
        }
        return (<Pill
          size="tiny"
          type="positive"
          label="Active"
        />);
      },
    },
    {
      name: 'compliance',
      header: 'Compliance',
      formatter: (candidate) => {
        const { adminComplianceStatusColor, adminComplianceStatusLabel } = candidate;

        return (
          <div className="bank-compliance-status-column">
            <ComplianceStatus statusColor={adminComplianceStatusColor} statusLabel={adminComplianceStatusLabel} />
          </div>
        );
      },
    },
    {
      name: 'roleAndMaxGrade',
      header: hasUsefulGrades ? 'Role & Grade' : 'Role',
      formatter: (candidate) => {
        const roleName = (candidate.roleKey && props.rgsMetadata?.[candidate.roleKey]?.name) ?? null;
        const maxGradeName = (candidate.roleKey && candidate.maxGradeKey && props.rgsMetadata?.[candidate.roleKey]?.grades?.[candidate.maxGradeKey]?.name) ?? null;

        if (roleName && maxGradeName && roleName !== maxGradeName && maxGradeName !== 'All') return `${roleName} (${maxGradeName})`;
        if (roleName) return `${roleName}`;
        return null;
      },
    },
    hasUsefulSpecialities && {
      name: 'specialityIds',
      header: 'Specialities',
      formatter: (candidateSpecIds, candidate) => {

        const rgsSpecData = (candidate.roleKey && props.rgsMetadata?.[candidate.roleKey]?.specialities) ?? null;
        if (rgsSpecData?.[candidateSpecIds[0]]?.name) {
          return rgsSpecData[candidateSpecIds[0]].name + ((candidateSpecIds.length > 1) ? `, ${candidateSpecIds.length - 1} more` : '');
        }
        return '';
      },
    },
    {
      name: 'bankKeys',
      header: 'Banks',
      formatter: (bankKeys) => {
        const bankNames = bankKeys?.map(bankKey => props.adminBanks.find(bank => bank.bankKey === bankKey)?.name).filter(Boolean);
        if (bankNames.length === 0) return null;
        return `${bankNames[0]}${bankNames.length > 1 ? `, ${bankNames.length} more` : ''}`;
      },
    },
    {
      name: 'joinDate',
      header: 'Date Registered',
      formatter: (joinDate) => {
        return moment.utc(joinDate).format('DD/MM/YYYY');
      },
    },
  ].filter(Boolean);

  if (props.displayCandidateRatingsFeatureFlag) {
    dataTableColumns.push({
      name: 'rating',
      icon: <MdStar style={{ color: colors.ratingYellow }} />,
      header: 'Rating',
      formatter: (rating, candidate) => {
        if (candidate.rating) {
          return (
            <div>
              <span>{parseFloat(rating, 10).toFixed(1)}</span>
              <MdStar style={{ color: colors.ratingYellow }} />
            </div>
          );
        }
        return <p>None</p>;
      },
    });
  }

  return (
    <PaginatedDataTable
      totalRowCount={props.candidates.length}
      isLoading={props.isLoading}
      style={styles.dataTable}
      columns={dataTableColumns}
      rows={props.candidates}
      onRowClick={props.openProfile}
      idColumn="candidateKey"
      rgsMetadata={props.rgsMetadata}
      sortDataByHeader={props.sortDataByHeader}
      sortedBy={props.sortedBy}
      sortInTheRightOrder={props.sortInTheRightOrder}
      overrideHeadingStyles={{
        color: colors.text,
        textTransform: 'none',
        fontSize: '0.9rem',
      }}
    />
  );
};

const { func, arrayOf, object, bool } = PropTypes;
BankManagement.propTypes = {
  isLoading: bool.isRequired,
  candidates: arrayOf(object).isRequired,
  openProfile: func.isRequired,
  displayCandidateRatingsFeatureFlag: bool.isRequired,
};

export default BankManagement;
