/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { groupBy } from 'lodash-es';
import ScrollView from 'components/ScrollView/ScrollView';
import Button from 'components/Button';
import Loading from 'components/Loading';
import DataTable, { DataTableColumnDefinition } from 'components/DataTable';

import { convertFromUtcToTimezone } from 'lib/helpers-time';
import colors from 'config/colors';
import spacing from 'config/spacing';

import { downloadPaymentReport } from 'lib/api/timesheets';
import downloadFile from 'lib/helpers-download-file';

import { PaymentRunReportType } from '../../../types/PaymentRun';

interface DataTableColumnProps {
  downloadReport: (report: PaymentRunReportType) => void,
  reportsLoadingState: { [key: string]: boolean },
}


const dataTableColumns = (props: DataTableColumnProps) : DataTableColumnDefinition<PaymentRunReportType>[] => {
  return [
    {
      header: 'Filename',
      name: 'fileName',
      width: 220,
    },
    {
      header: 'Actions',
      name: 'actions',
      width: 65,
      formatter: (report: PaymentRunReportType) => {
        return (
          props.reportsLoadingState[report.key] ?
            <Loading size={30} />
            :
            <Button small style={{ paddingLeft: spacing.base, paddingRight: spacing.base }} onClick={() => props.downloadReport(report)} white outline shadow={false}>Download</Button>
        );
      },
    },
  ];
};

interface ReportsTabProps {
  reports: PaymentRunReportType[],
  orgTimezone: string,
}

function duplicateArrayItems(originalArray: any[]): any[] {
  const duplicatedArray: any[] = [];

  for (let i = 0; i < originalArray.length; i++) {
    const item = originalArray[i];

    for (let j = 0; j < 50; j++) {
      duplicatedArray.push(item);
    }
  }

  return duplicatedArray;
}

function duplicateItemsInObject(originalObject: Record<string, any[]>): Record<string, any[]> {
  const duplicatedObject: Record<string, any[]> = {};

  for (const key in originalObject) {
    if (Array.isArray(originalObject[key])) {
      const originalArray = originalObject[key];
      const duplicatedArray = duplicateArrayItems(originalArray);

      duplicatedObject[key] = duplicatedArray;
    }
  }

  return duplicatedObject;
}

const ReportsTab : React.FC<ReportsTabProps> = (props : ReportsTabProps) => {

  const initialReportsloadingState : { [key: string]: boolean } = {};
  props.reports.forEach((report: PaymentRunReportType) => { initialReportsloadingState[report.key] = false; });

  const [reportsLoadingState, setReportsLoadingState] = useState(initialReportsloadingState);
  const [apiError, setApiError] = useState(null as string | null);

  const downloadReport = async (report: PaymentRunReportType) => {
    try {
      setReportsLoadingState({ ...reportsLoadingState, [report.key]: true });
      const response = await downloadPaymentReport(report.key);

      downloadFile(response.filename, response.mimeType, response.body);
      setReportsLoadingState({ ...reportsLoadingState, [report.key]: false });
    } catch (error) {
      setApiError(error.message);
      setReportsLoadingState({ ...reportsLoadingState, [report.key]: false });
    }
  };

  const reportsGroupedByDate = groupBy(props.reports, (report) => convertFromUtcToTimezone(report.createdAt, props.orgTimezone).format('DD MMMM YYYY'));

  if (apiError) {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: spacing.small }}>
        <p style={{ color: colors.red }}>{apiError}</p>
      </div>
    );
  }

  const columns = dataTableColumns({ downloadReport, reportsLoadingState });
  let reportCounts = 0;
  Object.entries(reportsGroupedByDate).map(([k, v]) => { reportCounts += v.length; return reportCounts; });

  return (
    <>
      <p style={{ padding: spacing.base, color: colors.text, lineHeight: 1.4, fontSize: '0.9rem' }}>All reports that are generated as part of a payment run are archived here.</p>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto' }}>
        {Object.entries(reportsGroupedByDate).map(([reportDate, reports]) => {
          return (
            <>
              <h4 style={{ paddingLeft: spacing.base, paddingBottom: spacing.large, fontWeight: 500, color: colors.text, fontSize: '1rem' }}>{reportDate}</h4>
              <DataTable
                rows={reports}
                columns={columns}
                fixedLayout
                overrideHeadingStyles={{
                  color: colors.text,
                  textTransform: 'none',
                  fontSize: '0.9rem',
                }}
              />
            </>
          );
        })}
      </div>
      <div style={{ textAlign: 'right' }}>
        <span style={{
          paddingLeft: spacing.base,
          paddingBottom: spacing.large,
          paddingRight: spacing.base,
          fontWeight: 100,
          color: colors.charcoal,
        }}
        >{reportCounts} report{reportCounts > 1 ? 's' : ''} available
        </span>
      </div>
    </>
  );
};

export default ReportsTab;
