import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
// import Loading from 'components/Loading';
// import * as api from 'lib/api';
import * as jobsThunks from 'thunks/jobs';
import JobsSelectBox from '../../Adhoc/JobsSelectBox';
import '../../Adhoc/TimesAndAreas.scss';
import '../styles.scss';

const { string, bool, func } = PropTypes;

class TargetSupplierCandidateForm extends Component {

  static propTypes = {
    suppliers: bool.isRequired,
    bookCandidate: func.isRequired,
    shiftError: string,
  }

  static defaultProps = {
    shiftError: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedSupplier: null,
      candidateReference: '',
    };
  }

  bookSupplierCandidate() {
    this.props.bookSupplierCandidate(this.state.selectedSupplier.value, this.state.candidateReference);
  }

  render() {

    const bookButtonDisabled = this.props.isBookingCandidate || !this.state.selectedSupplier || this.state.candidateReference.length === 0;

    return (
      <>
        <div className="target-bank-candidate-container">
          <h3 className="shiftDetailsSubHeading">Book Agency Candidate</h3>
          <div className="select-box-container">
            <div className="target-candidate-select-box">
              <JobsSelectBox
                name="candidates"
                placeholder="Select Supplier..."
                data={this.props.suppliers.map(s => ({ label: s.companyName, value: s.key }))}
                value={this.state.selectedSupplier}
                onChange={supplier => this.setState({ selectedSupplier: supplier })}
              />
            </div>
          </div>
          <div className="select-box-container">
            <FormInput
              label="Reference"
              value={this.state.candidateReference}
              onChange={event => this.setState({ candidateReference: event.target.value })}
              styles={{ height: 30 }}
            />
              <Button
                className="auto-book-button"
                disabled={this.props.isBookingCandidate}
                onClick={() => this.bookSupplierCandidate()}
                black
                shadow={false}
              >
                Book
              </Button>
          </div>
          {this.props.shiftError ?
            <div className="user-feedback-container">
              <p className="auto-book-error-message">{this.props.shiftError || null}</p>
            </div>
            : null
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    suppliers: state.global.orgSuppliers || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    book: (jobId, candidateId, profRegStatus, isAgency, applicationId, autoBookedAfterReleaseToBank) => dispatch(jobsThunks.book(jobId, candidateId, profRegStatus, isAgency, applicationId, autoBookedAfterReleaseToBank)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetSupplierCandidateForm);
