import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { firstBy } from 'thenby';
import { MdAdd, MdEmail, MdPerson, MdQueryBuilder } from 'react-icons/md';

import colors from 'config/colors';
import spacing from 'config/spacing';
import { permissions } from 'config/permissions';
import { useAppDispatch, useSelector } from 'hooks/redux';
import { fetchAdmins } from 'thunks/admins';

import Feature from 'components/Feature';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import DataTable from 'components/DataTable';
import Pill from 'components/Pill';
import FilterBox from 'components/FilterBox';
import DropDownFilter from 'components/DropDownFilter';

const styles = {
  header: {
    flex: '0 0 auto',
    position: 'relative',
    padding: `${spacing.base}px 0`,
    borderBottom: `1px solid ${colors.cavalry.line}`,
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  filter: {
    marginTop: 3,
    padding: '12px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(164, 194, 244, 0.2)',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    alignItems: 'flex-end',
  },
};


function invertSortDirection(dir) {
  if (dir === 'asc') return 'desc';
  if (dir === 'desc') return 'asc';
  throw new Error('Sort order must always be asc or desc');
}

function filterAdmins(adminsObject, filterString, selectedStatusFilter) {

  const filterStringLowercase = filterString.toLowerCase();

  return Object.values(adminsObject || {})
    .filter((admin) => {
      if (!filterStringLowercase) return true;
      if (admin?.email?.toLowerCase()?.includes(filterStringLowercase)) return true;
      if (admin?.displayName?.toLowerCase()?.includes(filterStringLowercase)) return true;
      return false;
    })
    .filter((admin) => {
      if (!selectedStatusFilter) return true;
      if (selectedStatusFilter === 'active' && admin.enabled) return true;
      if (selectedStatusFilter === 'disabled' && !admin.enabled) return true;
      return false;
    });
}

function sortAdmins(admins, sortBy, sortDirection) {
  if (!sortBy) return admins;
  return admins.sort(firstBy(sortBy, { ignoreCase: true, direction: sortDirection }));
}

const statusOptions = [
  { value: null, label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'disabled', label: 'Disabled' },
];

const { func } = PropTypes;

AdminListManagementView.propTypes = {
  openUserInviteModal: func.isRequired,
  openUserManagementModal: func.isRequired,
};

const COLUMNS = [
  {
    name: 'displayName',
    header: 'Name',
  },
  {
    name: 'email',
    header: 'Email',
  },
  {
    name: 'joinedAt',
    header: 'Join Date',
    formatter: (dateString) => {
      return dateString && typeof dateString === 'string' ? moment(dateString).format('Do MMM Y') : 'Unknown';
    },
  },
  {
    name: 'lastActiveAt',
    header: 'Last Active',
    formatter: (dateString) => {
      return dateString && typeof dateString === 'string' ? `${moment(dateString).fromNow()[0].toUpperCase()}${moment(dateString).fromNow().slice(1)}` : 'Unknown';
    },
  },
  {
    name: 'enabled',
    header: 'status',
    formatter: ((enabled) => (enabled && typeof enabled === 'boolean' ? <Pill label="Active" type="positive" /> : <Pill label="Disabled" type="negative" />)),
  },
];

const customSelectBoxStyles = {
  valueContainer: { fontSize: '14px' },
  menuList: { width: '200px' },
  menu: { width: '200px' },
  option: { fontSize: '14px' },
};

export default function AdminListManagementView(props) {

  const dispatch = useAppDispatch();
  const adminsObject = useSelector(state => state.admins.admins);
  const isFetchingAdmins = useSelector(state => state.admins.isFetchingAdmins);
  const isMobile = useSelector(state => state.screen.isMobile);

  // Sort and filter state
  const [filterString, setFilterString] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('active');
  const [sortColumn, setSortColumn] = useState('displayName');
  const [sortDirection, setSortDirection] = useState('asc');
  const setSortOrder = useCallback((newSortColumn) => {
    setSortDirection(newSortColumn === sortColumn ? invertSortDirection(sortDirection) : 'asc');
    setSortColumn(newSortColumn);
  }, [sortColumn, sortDirection]);

  const resolvedSelectedStatusFilter = filterString ? null : selectedStatusFilter;

  const filteredAndSortedAdmins = useMemo(() => {
    return sortAdmins(
      filterAdmins(adminsObject, filterString, resolvedSelectedStatusFilter),
      sortColumn,
      sortDirection,
    );
  }, [adminsObject, filterString, resolvedSelectedStatusFilter, sortColumn, sortDirection]);

  // Fetch admins on load
  useEffect(() => { dispatch(fetchAdmins()); }, []);

  if (isFetchingAdmins) {
    return (
      <div style={styles.loadingContainer}>
        <Loading size={60} />
      </div>
    );
  }
  return (
    <>
      <div style={{ ...styles.header, ...(isMobile ? { flexDirection: 'column' } : null) }} className="space-children-12">
        {!isMobile ? <PageHeader title="Manage Admins" style={{ float: 'left', fontSize: '1.2rem', marginTop: 0 }} /> : null}
        <div style={{ flex: 1 }} />
        <div style={{ position: 'absolute', top: 8, right: 275 }}>
          <FilterBox style={{ width: 200, height: 26, borderRadius: 4, border: `1px solid ${colors.cavalry.line}`, color: colors.text, fontSize: '0.9rem' }} filterString={filterString} setFilterString={setFilterString} placeholder="Filter admin by name / email ..." />
        </div>
        <div style={{ width: 150, flex: '0 0 auto' }}>
          <DropDownFilter
            // title="Admin Status:"
            placeholder="Filter by admin status..."
            options={statusOptions}
            value={statusOptions.find(option => option.value === resolvedSelectedStatusFilter)}
            onChange={(obj) => setSelectedStatusFilter(obj.value)}
            customStyles={customSelectBoxStyles}
            isDisabled={!!filterString}
            height={40}
          />
        </div>
        <Feature permissionRequired={permissions.USERS_CAN_INVITE}>
          <Button large black shadow={false} onClick={props.openUserInviteModal} style={{ marginRight: spacing.base, marginTop: 3 }} className="test-id-user-invite">
            <MdAdd style={{ color: colors.white, marginRight: spacing.base }} />
            Invite
          </Button>
        </Feature>
      </div>

      <div style={{ flex: '1 1 0px', overflow: 'auto' }}>
        <DataTable
          columns={COLUMNS}
          rows={filteredAndSortedAdmins}
          onRowClick={props.openUserManagementModal}
          idColumn="key"
          style={{ minWidth: 600 }}
          sortDataByHeader={setSortOrder}
          sortInTheRightOrder={sortDirection === 'asc'}
          sortBy={sortColumn}
          overrideHeadingStyles={{
            color: colors.text,
            textTransform: 'none',
            fontSize: '0.9rem',
          }}
        />
      </div>
    </>
  );
}
