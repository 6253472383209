import React from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import classNames from 'classnames';

import useDropdownMenu from 'hooks/useDropdownMenu';
import './SplitButton.scss';

interface SplitButtonProps {
  children: string,
  onClick?: () => void,
  menuItems?: { key: string, label: string, onClick: () => void, disabled?: boolean }[],
  openMenuDirection?: 'left' | 'right',
  disabled?: boolean
  small?: boolean,
  positive?: boolean,
  negative?: boolean,
  warning?: boolean,
  danger?: boolean,
  create?: boolean,
  outline?: boolean,
  white?: boolean,
  black?: boolean,
}

export default function SplitButton(props: SplitButtonProps) : React.ReactElement {

  const { isOpen, setIsOpen, containerEl } = useDropdownMenu();

  const positive = props.positive || (!props.negative && !props.warning && !props.danger && !props.create && !props.black);
  const clickable = !!props.onClick && !props.disabled;
  const hasMenuItems = !!props.menuItems?.length;

  const classes = {
    splitButton: () => classNames({
      'split-button': true,
      'split-button--small': props.small,
    }),
    mainButton: () => classNames({
      'split-button__main-button': true,
      'split-button__main-button--small': props.small,
      'split-button__main-button--disabled': props.disabled,
      'split-button__main-button--positive': positive,
      'split-button__main-button--white': props.white,
      'split-button__main-button--black': props.black,
      'split-button__main-button--negative': props.negative,
      'split-button__main-button--warning': props.warning,
      'split-button__main-button--danger': props.danger,
      'split-button__main-button--create': props.create,
      'split-button__main-button--outline': props.outline,
      'split-button__main-button--clickable': clickable,
      'split-button__main-button--no-menu-items': !hasMenuItems,
    }),
    subButton: (disabled: boolean) => classNames({
      'split-button__sub-button': true,
      'split-button__sub-button--small': props.small,
      'split-button__sub-button--disabled': disabled,
    }),
    chevron: () => classNames({
      'split-button__chevron': true,
      'split-button__chevron--positive': positive,
      'split-button__chevron--negative': props.negative,
      'split-button__chevron--warning': props.warning,
      'split-button__chevron--danger': props.danger,
      'split-button__chevron--create': props.create,
      'split-button__chevron--small': props.small,
      'split-button__chevron--outline': props.outline,
      'split-button__chevron--clickable': hasMenuItems,
      'split-button__chevron--white': props.white,
      'split-button__chevron--black': props.black,
    }),
    divider: () => classNames({
      'split-button__divider': true,
      'split-button__divider--positive': positive,
      'split-button__divider--negative': props.negative,
      'split-button__divider--warning': props.warning,
      'split-button__divider--danger': props.danger,
      'split-button__divider--create': props.create,
      'split-button__divider--outline': props.outline,
    }),
  };

  const chevronIconSize = props.small ? 12 : 18;

  return (
    <div className={classes.splitButton()} ref={containerEl}>
      <button
        className={classes.mainButton()}
        type="button"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
      {hasMenuItems && (
        <div
          role="button"
          tabIndex={0}
          className={classes.chevron()}
          onClick={(e) => {
            e.stopPropagation();
            if (hasMenuItems) setIsOpen(!isOpen);
          }}
        >
          <div style={{ pointerEvents: 'none' }}>
            {isOpen ? <MdExpandLess size={chevronIconSize} /> : <MdExpandMore size={chevronIconSize} />}
          </div>
        </div>
      )}
      {(isOpen && hasMenuItems) && (
        <div className={`split-button__menu split-button__menu--${props.openMenuDirection}`}>
          {props.menuItems?.map((button) => {
            return (
              <div
                key={button.key}
                className={classes.subButton(!!button.disabled)}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  if (button.disabled) return;
                  e.stopPropagation();
                  setIsOpen(false);
                  button.onClick();
                }}
              >
                {button.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

SplitButton.defaultProps = { openMenuDirection: 'right', disabled: false, small: false, positive: false };
