import React, { useState, useRef, useEffect } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import classNames from 'classnames';
import { isNil } from 'lodash-es';

import FieldLabel from './FieldLabel';


interface SlotsFieldProps {
  label: string,
  slotsRequired: number,
  onChange: (val: number) => void,
  mandatory: boolean;
  minimumSlotsRequired?: number
}

function SlotsField(props: SlotsFieldProps): React.ReactElement {

  const [inputValue, setInputValue] = useState(String(props.slotsRequired));
  const [inputIsFocussed, setInputIsFocussed] = useState(false);

  useEffect(() => {
    setInputValue(String(props.slotsRequired));
  }, [props.slotsRequired]);

  const inputRef = useRef<HTMLInputElement>(null);


  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setInputIsFocussed(false);
    const valueConvertedToNumber = parseInt(event.target.value, 10);
    props.onChange(valueConvertedToNumber);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      setInputIsFocussed(false);
      event.preventDefault();
      const target = event.target as Element;
      if (target instanceof HTMLElement) {
        target.blur();
        const valueConvertedToNumber = parseInt(inputValue, 10);
        props.onChange(valueConvertedToNumber);
      }
    }
  };

  return (
    <FieldLabel label={props.label}>
      <div style={{ display: 'flex', height: 30, minHeight: 30 }}>
        <input
          ref={inputRef}
          type="number"
          onFocus={() => { if (!inputIsFocussed) setInputIsFocussed(true); }}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={(event) => setInputValue(event?.target.value)}
          name="customHourlyRate"
          className={classNames({
            'slots-input': true,
            'slots-input--missing': (isNil(props.slotsRequired) || props.slotsRequired > 99),
          })}
          value={inputValue}
        />
        <div
          // role="button"
          // tabIndex={0}
          className={classNames('slots-input__button', {
            'slots-input__button--invalid': (isNil(props.slotsRequired) || props.slotsRequired > 99),
          })}
          style={{ borderLeft: 'none', userSelect: 'none' }}
          onClick={() => {
            if (props.slotsRequired > (props.minimumSlotsRequired ?? 1)) {
              props.onChange(props.slotsRequired - 1);
            }
          }}
        >
          <MdKeyboardArrowDown size={18} />
        </div>

        <div
          // role="button"
          // tabIndex={0}
          className={classNames('slots-input__button', {
            'slots-input__button--invalid': (isNil(props.slotsRequired) || props.slotsRequired > 99),
          })}
          style={{ borderLeft: 'none', borderTopRightRadius: 2, borderBottomRightRadius: 2, userSelect: 'none' }}
          onClick={() => props.onChange(props.slotsRequired + 1)}
        >
          <MdKeyboardArrowUp size={18} />
        </div>
      </div>
    </FieldLabel>
  );
}

SlotsField.defaultProps = { minimumSlotsRequired: 1 };

export default React.memo(SlotsField);
