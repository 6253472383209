import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './buttons.scss';

const computeSize = (props) => {
  if (props.size) return props.size;
  if (props.small) return 'small';
  if (props.large) return 'large';
  if (props.huge) return 'huge';
  return 'medium';
};

const computeColor = (props) => {
  if (props.danger) return 'danger';
  if (props.warning) return 'warning';
  if (props.create) return 'create';
  if (props.black) return 'black';
  if (props.white) return 'white';
  return 'black';
};

export default function Button(props) {

  const size = computeSize(props);
  const color = computeColor(props);

  return (
    <button
      id={props.id}
      style={props.style}
      className={classNames(
        props.className,
        `button button--${size}`,
        `button--${color}`,
        {
          rise: props.rise,
          disabled: props.loading || props.disabled,
          'button--outline': props.outline,
          'button--full-width': props.fullWidth,
          'button--half-width': props.halfWidth,
        },
      )}
      onClick={props.onClick}
      disabled={props.loading || props.disabled}
      type={props.submit ? 'submit' : 'button'}
    >
      {props.icon}
      {props.loading ? props.loadingContent : props.children}
    </button>
  );
}

const { func, oneOfType, node, arrayOf, object, bool, string } = PropTypes;
Button.propTypes = {
  onClick: func,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  style: object,
  disabled: bool,
  rise: bool,
  shadow: bool,
  small: bool,
  medium: bool,
  large: bool,
  fullWidth: bool,
  halfWidth: bool,
  size: string,
  icon: node,
  loading: bool,
  loadingContent: oneOfType([
    arrayOf(node),
    node,
  ]),
  className: string,
  positive: bool,
  negative: bool,
  danger: bool,
  warning: bool,
  outline: bool,
  create: bool,
  white: bool,
  black: bool,
};

Button.defaultProps = {
  onClick: () => {},
  style: {},
  disabled: false,
  rise: false,
  shadow: true,
  small: false,
  medium: false,
  large: false,
  fullWidth: false,
  halfWidth: false,
  size: null,
  icon: null,
  loading: false,
  loadingContent: 'Loading...',
  className: '',
  positive: false,
  negative: false,
  danger: false,
  warning: false,
  outline: false,
  create: false,
  white: false,
  black: false,
};
