import React from 'react';
import PropTypes from 'prop-types';

import colors from 'config/colors';
import spacing from 'config/spacing';

import Button from 'components/Button';
import AuthPage from './ui/AuthPage';
import NewPasswordForm from './ui/NewPasswordForm';

const styles = {
  messageContainer: {
    textAlign: 'center',
    color: colors.text,
    fontSize: '0.9rem',
    paddingTop: 6,
  },
};

const stopSubmit = (cb) => {
  return (ev) => {
    ev.preventDefault();
    cb();
    return false;
  };
};

export function PasswordResetForm(props) {

  return (
    <AuthPage header="Reset Password">
      <div className="space-children-12--bottom">
        <p style={{ textAlign: 'center', fontSize: '0.9rem' }}>Resetting password for: <strong>{props.email}</strong></p>
        <form method="post" onSubmit={stopSubmit(props.updatePassword)}>
          <NewPasswordForm
            formValues={props.formValues}
            setFormValue={props.setFormValue}
            passwordRequirementsWithStatus={props.passwordRequirementsWithStatus}
          />
          <Button huge positive fullWidth submit disabled={props.disabled} style={{ fontSize: 16 }}>
            Reset
          </Button>
        </form>
        {!!props.resetError && (
          <div style={styles.messageContainer}>
            <p>{props.resetError}</p>
          </div>
        )}
      </div>
    </AuthPage>
  );
}


const { string, func, bool } = PropTypes;

PasswordResetForm.propTypes = {
  disabled: bool.isRequired,
  updatePassword: func.isRequired,
  login: func.isRequired,
  resetError: string,
  passwordIsReset: bool.isRequired,
  landFromMobile: bool.isRequired,
  backgroundColor: string,
  color: string,
  heading: string,
};

PasswordResetForm.defaultProps = {
  backgroundColor: colors.white,
  color: colors.charcoal,
  heading: null,
  resetError: null,
};

export function PasswordResetConfirmation(props) {
  return (
    <AuthPage header="Password Reset">
      <p style={{ marginBottom: 12, fontSize: '0.9rem' }}>Your password was successfully reset.</p>
      {props.landFromMobile ?
        <div style={{ textAlign: 'center', lineHeight: 1.4, fontSize: '0.9rem' }}>
          <p>You were directed to this page from our mobile app, please return to the app and log in with your new password.</p>
        </div>
        :
        <Button huge positive fullWidth onClick={props.login}>
          Login With New Password
        </Button>
      }
    </AuthPage>
  );
}

export default PasswordResetForm;
