import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TimesheetDetailsContainer from './TimesheetDetailsContainer';
import TimesheetsContainer from './TimesheetsContainer';
import PaymentRunsList from './PaymentRuns/PaymentRunsList';
import PaymentRunDetails from './PaymentRuns/PaymentRunDetails';
import CreatePaymentRun from './PaymentRuns/CreatePaymentRun';

const TimesheetRoutes = ({ match, history }) => {

  const timesheetsRootUrl = match.path;

  const goToTimesheetsListView = () => history.push(`${timesheetsRootUrl}/timesheets`);
  const goToTimesheetDetailsView = (shiftKey, candidateKey, timesheetKey) => {
    return history.push(`${timesheetsRootUrl}/timesheets/${shiftKey}/${candidateKey}/${timesheetKey}`);
  };

  const goToPaymentRunListView = () => history.push(`${timesheetsRootUrl}/payment-runs`);
  const goToPaymentRunDetailsView = (paymentRunKey, tab = 'details') => {
    return history.push(`${timesheetsRootUrl}/payment-runs/details/${paymentRunKey}/${tab}`);
  };

  const goToPaymentRunCreateForm = () => {
    return history.push(`${timesheetsRootUrl}/payment-runs/create`);
  };

  const goToPaymentRunEditForm = (paymentRunKey) => {
    return history.push(`${timesheetsRootUrl}/payment-runs/details/${paymentRunKey}/edit`);
  };

  return (
    <>
      <Route
        path={`${timesheetsRootUrl}/timesheets`}
        render={routeProps => <TimesheetsContainer {...routeProps} goToPaymentRunListView={goToPaymentRunListView} goToDetailsView={goToTimesheetDetailsView} />}
      />
      <Route
        path={`${timesheetsRootUrl}/timesheets/:shiftKey/:candidateKey/:timesheetKey`}
        render={(routeProps) => {
          return (
            <TimesheetDetailsContainer
              {...routeProps}
              shiftKey={routeProps.match.params.shiftKey}
              candidateKey={routeProps.match.params.candidateKey}
              timesheetKey={routeProps.match.params.timesheetKey}
              goToTimesheetsListView={goToTimesheetsListView}
            />
          );
        }}
      />
      <Route
        path={`${timesheetsRootUrl}/payment-runs/create`}
        render={routeProps => (
          <CreatePaymentRun
            goToPaymentRunDetailsView={goToPaymentRunDetailsView}
            goToPaymentRunListView={goToPaymentRunListView}
          />
        )}
      />
      <Route
        path={`${timesheetsRootUrl}/payment-runs/:view(details)?/:paymentRunKey?`}
        render={(routeProps) => {
          return (
            <PaymentRunsList
              goToPaymentRunCreateForm={goToPaymentRunCreateForm}
              goToTimesheetsListView={goToTimesheetsListView}
              goToPaymentRunDetailsView={goToPaymentRunDetailsView}
              selectedPaymentRunKey={routeProps.match.params.paymentRunKey ?? null}
            />
          );
        }}
      />
      <Route
        path={`${timesheetsRootUrl}/payment-runs/details/:paymentRunKey/:view(details|reports|audit|edit)?`}
        render={(routeProps) => {
          return (
            <PaymentRunDetails
              goToPaymentRunListView={goToPaymentRunListView}
              paymentRunKey={routeProps.match.params.paymentRunKey}
              sidePanelTab={routeProps.match.params.view}
              onTabClick={tab => goToPaymentRunDetailsView(routeProps.match.params.paymentRunKey, tab)}
              goToPaymentRunEditForm={goToPaymentRunEditForm}
              goToPaymentRunDetailsView={goToPaymentRunDetailsView}
            />
          );
        }}
      />
    </>
  );

};


const { objectOf, object, oneOfType, bool, number, string, func } = PropTypes;

TimesheetRoutes.propTypes = {
  match: objectOf(oneOfType([bool, number, string, object])).isRequired,
  history: objectOf(oneOfType([func, number, string, object])).isRequired,
};


const mapStateToProps = (state) => {
  return {
    user: state.user,
    global: state.global,
  };
};

export default connect(mapStateToProps)(TimesheetRoutes);
