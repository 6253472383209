import React from 'react';
import classNames from 'classnames';
import './SplitButtonBar.scss';

interface SplitButtonBarProps {
  buttons: { value: string, label: string }[],
  selectedButton: string,
  onClick: (value: string) => void
}

const SplitButtonBar : React.FC<SplitButtonBarProps> = (props: SplitButtonBarProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      {props.buttons.map((button) => {
        const selectedButton = props.selectedButton === button.value;
        return (
          <button
            onClick={() => props.onClick(button.value)}
            className={classNames('split-bar-button', { 'split-bar-button--selected': selectedButton })}
            type="button"
          >
            {button.label}
          </button>
        );
      })}
    </div>
  );
};

export default SplitButtonBar;
