import React, { CSSProperties } from 'react';
import { firstBy } from 'thenby';

import { convertFromUtcToTimezone } from 'lib/helpers-time';
import colors from 'config/colors';

interface Styles {
  [key: string]: CSSProperties
}

const styles: Styles = {
  text: {
    color: colors.white,
    lineHeight: 1,
    fontSize: '0.9rem',
  },
};

interface Clash {
  startTime: string,
  exact: boolean,
  timezone: string,
  endTime: string,
  site: string,
  shiftNumber: string,
}

interface Applicant {
  clashes: Clash[],
  rejectedAt: string | null,
  rejectedByName: string | null,
  publicRejectionReason: string | null,
  privateRejectionReason: string | null
}

interface ApplicantPopoverProps {
  applicant: Applicant,
  timezone: string,
}

export default function ApplicantPopover({ applicant, timezone }: ApplicantPopoverProps): React.ReactElement {
  return (
    <div className="space-children-24--bottom">
      {applicant.clashes && <Clashes clashes={applicant.clashes} />}
      {applicant.rejectedAt && applicant.rejectedByName && (
        <RejectedApplicant
          rejectedAt={applicant.rejectedAt}
          rejectedByName={applicant.rejectedByName}
          timezone={timezone}
          publicRejectionReason={applicant.publicRejectionReason}
          privateRejectionReason={applicant.privateRejectionReason}
        />
      )}
    </div>
  );
}

function Clashes({ clashes }: { clashes: Clash[] }): React.ReactElement {
  return (
    <div>
      <p>Other shifts the candidate is booked into:</p>
      {(clashes ?? []).sort(firstBy('startTime')).map(clash => (
        <div style={{ color: clash.exact ? colors.red : colors.white }}>
          <span style={{ ...styles.text, fontWeight: 'bold' }}>{convertFromUtcToTimezone(clash.startTime, clash.timezone).format('Do MMM')} {convertFromUtcToTimezone(clash.startTime, clash.timezone).format('HH:mm')} - {convertFromUtcToTimezone(clash.endTime, clash.timezone).format('HH:mm')}</span> at {clash.site} ({clash.shiftNumber}) {clash.exact && 'CLASH'}
        </div>
      ))}
    </div>
  );
}

interface RejectedApplicantProps {
  rejectedByName: string,
  rejectedAt: string,
  publicRejectionReason: string | null,
  privateRejectionReason: string | null,
  timezone: string,
}

function RejectedApplicant(props: RejectedApplicantProps): React.ReactElement {
  const { rejectedByName, rejectedAt, publicRejectionReason, privateRejectionReason, timezone } = props;
  const rejectedAtTz = convertFromUtcToTimezone(rejectedAt, timezone);
  return (
    <div>
      <p style={{ ...styles.text, fontWeight: 500, marginBottom: 12 }}>The application was rejected on {rejectedAtTz.format('Do MMM')} at {rejectedAtTz.format('HH:mm')} by {rejectedByName}.</p>

      {publicRejectionReason && (
        <div style={{ marginBottom: 12 }}>
          <p style={{ ...styles.text, lineHeight: 0.8 }}>The public reason given (displayed to staff member) was:</p>
          <p style={{ ...styles.text, fontWeight: 700, lineHeight: 0.8 }}>{publicRejectionReason}</p>
        </div>
      )}


      {privateRejectionReason && (
        <div>
          <p style={{ ...styles.text, lineHeight: 0.8 }}>The private reason given (only displayed to other admins) was:</p>
          <p style={{ ...styles.text, fontWeight: 700, lineHeight: 0.8 }}>{privateRejectionReason}</p>
        </div>
      )}

    </div>
  );
}
