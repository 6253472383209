import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { MdLocationOn } from 'react-icons/md';
import moment from 'moment';
import ApplicantsStatusPill from 'components/ApplicantsStatusPill';
import Loading from 'components/Loading';
import Popover from 'components/Popover';
import SplitButton from 'components/SplitButton';
import MessageButton from 'components/MessageButton';
import colors from 'config/colors';
import { permissions } from 'config/permissions';
import Feature from 'components/Feature';
import ComplianceStatus from 'components/ComplianceStatus';
import { capitalize } from 'lib/helpers';
import { CandidateRatingStar } from '../../CandidateRatingStar';
import ComplianceItemStatus from './ComplianceItemStatus';
import ApplicantPopover from './ApplicantPopover';
import '../styles.scss';

export default function ApplicationsCard(props) {

  const history = useHistory();

  const [complianceItemStatusPopoverIsOpen, setComplianceItemStatusPopoverIsOpen] = useState(false);
  const [applicantPopoverIsOpen, setApplicantPopopverIsOpen] = useState(false);

  const {
    applicant,
    isTargeted,
    agency,
    userId,
    showWarningPopUp,
    orgKey,
  } = props;

  let applicantDescription = applicant.roleName;
  if (applicant.profRegNumber) applicantDescription += `\n(reg: ${applicant.profRegNumber})`;
  if (applicant.gradeName && applicant.gradeName !== applicant.roleName && applicant.gradeName !== 'All') applicantDescription += ` |  ${applicant.gradeName}`;

  const applicantRef = (applicant.candidateRef ? `${applicant.supplierName} - ${applicant.candidateRef}` : null);
  const candidateBankKeys = Object.keys(applicant?.candidateBanks || {});
  const bankOrSupplierName = applicant.supplierName ?? (candidateBankKeys[0] && (candidateBankKeys.length > 1 ? `${candidateBankKeys.length} banks` : applicant?.candidateBanks[candidateBankKeys[0]]));
  const isExternalCandidate = orgKey !== applicant.mainOrgKey;
  const isSupplierCandidate = !!applicant.supplierKey;
  const clashingShifts = applicant?.clashes?.length > 0;
  const exactClashes = applicant?.clashes?.some(clash => clash.exact);
  const showAsDisabled = exactClashes || applicant.rejectedAt;

  const bookButtonDisabled = props.job.cancelledAt || props.allSlotsFilled || (props.bookingCandidate || props.autoBookingCandidate) || exactClashes;
  const bookApplicant = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    if (applicant?.profRegStatus?.warnings && Object.keys(applicant.profRegStatus.warnings).length > 0 && applicant.candidateKey !== userId) {
      const warnings = Object.values(applicant.profRegStatus.warnings);
      showWarningPopUp(warnings, applicant);
    } else {
      props.bookCandidate({
        candidateId: applicant.candidateKey,
        candidate: applicant,
        profRegStatus: applicant.profRegStatus,
        isAgency: agency,
        applicationsId: applicant.candidateKey,
        autoBookedAfterReleaseToBank: false,
      });
    }
  });

  const distance = props.applicantDistanceFromSite;
  const hours = props.applicantContractHours;

  const distanceAndHoursText = `${distance ?? ''}${hours ? `\u00a0\u00a0\u00a0\u00a0${hours}` : ''}`;

  const splitButtonMenuItems = [
    { key: 'reject-application', label: 'Reject', onClick: () => props.rejectApplicant(applicant), disabled: !!applicant.rejectedAt },
  ];

  return (
    <Popover
      isOpen={applicantPopoverIsOpen}
      setIsOpen={isHovering => setApplicantPopopverIsOpen((!!clashingShifts || !!applicant.rejectedAt) && isHovering)}
      popoverMaxWidth="400px"
      popoverContent={<ApplicantPopover applicant={applicant} timezone={props.shiftTimezone} />}
      position="left"
    >
      <div
        onClick={() => {
          const candidateIsPartOfCollaborativeBank = candidateBankKeys?.some(bankKey => props.adminBanks.includes(bankKey)) ?? false;
          if (candidateIsPartOfCollaborativeBank) props.goToCandidateProfile(applicant.candidateKey);
        }}
        style={{ backgroundColor: showAsDisabled ? colors.greyBackground : null }}
        className="shift-booking-content-container space-children-6--bottom"
      >

        {/* Candidate name, rating star and bank pill */}
        <div className="name-flex-container">
          <div className="applicationsSubHeading">
            {applicant.candidateName ? capitalize(applicant.candidateName) : applicantRef}
          </div>

          <div className="space-children-12" style={{ display: 'flex', alignItems: 'center' }}>
            {applicant.candidateRating && <CandidateRatingStar size={16} rating={applicant.candidateRating} />}
            {isSupplierCandidate ?
              <ApplicantsStatusPill agency={isSupplierCandidate} external={isExternalCandidate} bank={bankOrSupplierName} />
              :
              <Popover
                popoverMaxWidth="180px"
                popoverContent={
                  <div>
                    <p style={{ paddingRight: 48, whiteSpace: 'nowrap', fontWeight: 500 }}>All banks</p>
                    {props.job?.banks && candidateBankKeys?.length && (
                      <div>{candidateBankKeys?.map(bankKey => <p key={bankKey} style={{ margin: 0, fontSize: 12 }}>{props.job.banks[bankKey]}</p>)}</div>
                    )}
                  </div>
                }
                position="top"
              >
                <ApplicantsStatusPill agency={isSupplierCandidate} external={isExternalCandidate} bank={bankOrSupplierName} />
              </Popover>
            }
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Candidate Banks, Role & Grade, and Prof Reg numbers */}
          {!!applicantDescription && (
            <p className="candidateInfoText" style={{ lineHeight: 1, marginBottom: 3 }}>{applicantDescription ?? '\u00A0'}</p>
          )}

          <Popover
            isOpen={complianceItemStatusPopoverIsOpen}
            setIsOpen={setComplianceItemStatusPopoverIsOpen}
            positions={['bottom', 'left']}
            popoverContent={
              <div>
                <p style={{ paddingRight: 48, whiteSpace: 'nowrap', fontWeight: 500 }}>Individual Item Statuses</p>
                <ComplianceItemStatus items={applicant.complianceItems} labelStyle={{ color: 'white' }} />
              </div>
            }
            position="top"
          >
            <ComplianceStatus statusColor={applicant.adminComplianceStatusColor} statusLabel={applicant.adminComplianceStatusLabel} fontSize={14} />
          </Popover>
        </div>

        {!!props.distanceAndHoursText && (
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginLeft: -2 }}>
            <MdLocationOn
              color="#999"
              size={14}
            />
            <p className="candidateInfoText" style={{ lineHeight: 1 }}>{props.distanceAndHoursText}</p>
          </div>
        )}

        <div className="space-children-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          {/* Applied at label */}
          <p className="candidateInfoText" style={{ flex: 1 }}>
            <span className="shiftDetailsBoldHeadings">Applied:</span>{` ${moment(applicant.appliedAt).format('Do MMM HH:mm')}`}
          </p>

          {/* <Button small negative outline> */}
          {/*   Hide */}
          {/* </Button> */}

          {!isSupplierCandidate && (
            <div style={{ marginRight: 4 }}>
              <MessageButton candidateKey={applicant.candidateKey} />
            </div>
          )}

          {/* Book button */}
          <Feature permissionRequired={permissions.JOBS_CAN_BOOK}>
            {props.job.canBookStaff && !props.inPast ?
              <SplitButton
                menuItems={splitButtonMenuItems}
                onClick={bookApplicant}
                openMenuDirection="left"
                disabled={bookButtonDisabled}
                black
              >
                Book
              </SplitButton>
              :
              null
            }
          </Feature>


          {/* Loading spinner */}
          {props.bookingCandidate && props.bookingCandidateId === applicant.candidateKey && (
            <span style={{ position: 'absolute', right: 82, top: -13 }}>
              <Loading size={36} />
            </span>
          )}

        </div>
      </div>
    </Popover>
  );
}

const { string, object, bool, arrayOf, func } = PropTypes;
ApplicationsCard.propTypes = {
  applicant: object.isRequired,
  bookingCandidate: bool.isRequired,
  autoBookingCandidate: bool.isRequired,
  isTargeted: bool.isRequired,
  agency: bool,
  bookCandidate: func.isRequired,
  userId: string.isRequired,
  showWarningPopUp: func.isRequired,
  bookingCandidateId: string,
  goToCandidateProfile: func,
  orgKey: string.isRequired,
  allSlotsFilled: bool.isRequired,
};

ApplicationsCard.defaultProps = {
  agency: false,
  bookingCandidateId: '',
  goToCandidateProfile: () => {},
};

function ApplicantDistanceIcon(props) {
  return (
      <MdLocationOn
        color="#999"
        size={14}
      />
  );
}
