import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markAsRead, sendMessage } from 'thunks/messaging';
import { firstBy } from 'thenby';
import Loading from 'components/Loading';
import { watchConversationMessages as watchUserConversationMessages } from 'lib/api/messaging';
import * as messagingActions from 'reducers/messaging';
import MessageList from './MessageList';
import ComposeMessageBar from './ComposeMessageBar';

interface ConversationContainerProps {
  conversationKey: string;
  onSendMessage?: () => void;
}

function scrollToBottom(ref: HTMLElement | null) {
  if (ref) ref?.scrollTo(0, ref.scrollHeight + 50);
}

const ConversationContainer: React.FC<ConversationContainerProps> = ({ conversationKey, onSendMessage = () => {} }: ConversationContainerProps) => {
  const dispatch = useDispatch();
  const conversationMessages = useSelector(state => state.messaging.conversationMessages);
  const recentlySentMessages = useSelector(state => state.messaging.recentlySentMessages);
  const conversation = useSelector(state => state.messaging.conversations?.find(({ conversationKey: cKey }) => cKey === conversationKey));
  const conversationUsers = conversation?.users?.filter(({ userKey }) => userKey !== conversation.userKey);
  const isFetchingMessages = useSelector(state => state.messaging.isFetchingConversationMessages);
  const messages = [...conversationMessages || [], ...recentlySentMessages].sort(firstBy('sentAt'));
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(messagingActions.fetchConversationMessages());

    const messageSubscription = watchUserConversationMessages(
      (message) => dispatch(messagingActions.fetchConversationMessagesSuccess(message)),
      (error) => dispatch(messagingActions.messageError(error.message)),
      conversationKey,
    );

    return () => messageSubscription.unsubscribe();
  }, [conversationKey]);

  useEffect(() => {
    if (conversationKey !== 'new') dispatch(markAsRead(conversationKey));
  }, [conversationMessages]);

  useEffect(() => scrollToBottom(messageContainerRef.current), [messages]);

  const sendTextMessage = (textContent: string) => {
    dispatch(sendMessage(conversationKey, { textContent, type: 'text' }));
    onSendMessage();
    scrollToBottom(messageContainerRef.current);
  };

  if (isFetchingMessages) {
    return (
      <div className="messagingConversationLoadingContainer">
        <Loading size={40} />
      </div>
    );
  }

  return (
    <>
      <div className="messagingConversationContainer">
        <div className="messagingConversationMessageContainer" ref={messageContainerRef}>
          <MessageList messages={messages} conversationUsers={conversationUsers} />
        </div>
      </div>
      <ComposeMessageBar sendMessage={sendTextMessage} />
    </>
  );
};

export default ConversationContainer;
