import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../components/Tabs';
import RGSEdit from './RGSEdit';
import { updateRGSConfig } from '../../lib/api/accounts';

const { objectOf, oneOfType, string, object, bool, number, func } = PropTypes;
export default class RGSEditContainer extends Component {
  static propTypes = {
    selectedRole: objectOf(oneOfType([
      string,
      object,
      bool,
      number,
    ])),
    orgSelectedRole: objectOf(oneOfType([
      string,
      object,
      number,
      bool,
      number,
    ])),
    grades: objectOf(object),
    specialities: objectOf(object),
    selectedRoleKey: string,
    orgId: string.isRequired,
    closeModal: func.isRequired,
  }

  static defaultProps = {
    selectedRole: {},
    orgSelectedRole: {},
    grades: {},
    specialities: {},
    selectedRoleKey: null,
  }

  state = {
    editedRGS: {
      grades: {},
      specialities: {},
    },
    gradesFilteredAvailable: [],
    specialitiesFilteredAvailable: [],
    gradesFilteredSelected: [],
    specialitiesFilteredSelected: [],
    disableAnimation: false,
  }

  componentWillUnmount() {
    this.setState({
      editedRGS: {
        grades: {},
        specialities: {},
      },
      gradesFilteredAvailable: [],
      specialitiesFilteredAvailable: [],
      gradesFilteredSelected: [],
      specialitiesFilteredSelected: [],
    });
  }

  onSelect = (id, type) => {
    this.setState({
      editedRGS: {
        ...this.state.editedRGS,
        [type]: {
          ...this.state.editedRGS[type],
          [id]: { enabled: true },
        },
      },
    });
  }

  onUnselect = (id, type) => {
    this.setState({
      editedRGS: {
        ...this.state.editedRGS,
        [type]: {
          ...this.state.editedRGS[type],
          [id]: { enabled: false },
        },
      },
    });
  }

  saveChanges = async (type) => {
    await updateRGSConfig({
      type,
      data: this.state.editedRGS[type],
      roleId: this.props.selectedRoleKey,
      orgId: this.props.orgId,
    });

    this.props.closeModal();
  }

  discardChanges = async (type) => {
    this.setState({
      editedRGS: {
        ...this.state.editedRGS,
        [type]: {},
      },
    });

    this.props.closeModal();
  }

  disableAnimation(text) {
    if (text.length < 1) {
      this.setState({
        disableAnimation: false,
      });
    } else {
      this.setState({
        disableAnimation: true,
      });
    }
  }

  filterAvailableResults = (data, input, type) => {
    const i = input.target.value.toLowerCase();
    this.disableAnimation(i);

    const arr = Object.keys(data).map((id) => {
      return { id, ...data[id] };
    }).filter((d) => {
      const name = d.name.toLowerCase();
      return name.search(i) !== -1;
    }).map(d => d.id);

    this.setState({
      [`${type}FilteredAvailable`]: arr,
    });
  }

  filterSelectedResults = (data, input, type) => {
    const i = input.target.value.toLowerCase();
    this.disableAnimation(i);

    const capType = type[0].toUpperCase() + type.slice(1);
    const arr = Object.keys(data).map((id) => {
      return {
        id,
        ...data[id],
        name: this.props.selectedRole[`linked${capType}`][id].name,
        order: this.props.selectedRole[`linked${capType}`][id].order,
      };
    }).filter((d) => {
      const name = d.name.toLowerCase();
      return name.search(i) !== -1;
    }).map(d => d.id);

    this.setState({
      [`${type}FilteredSelected`]: arr,
    });
  }

  render() {
    const selectedGrades = {
      ...this.props.orgSelectedRole.linkedGrades,
      ...this.state.editedRGS.grades,
    } || {};
    const selectedSpecialities = {
      ...this.props.orgSelectedRole.linkedSpecialities,
      ...this.state.editedRGS.specialities,
    } || {};

    return (
      <Tabs
        tabs={[
          {
            tabTitle: 'Associated Grades',
            renderTabContent: () => {
              return (
                <RGSEdit
                  type="grades"
                  available={this.props.selectedRole.linkedGrades || {}}
                  selected={selectedGrades}
                  data={this.props.grades}
                  editedRGS={this.state.editedRGS.grades}
                  onSelect={this.onSelect}
                  onUnselect={this.onUnselect}
                  saveChanges={this.saveChanges}
                  onAvailableSearchChange={event => this.filterAvailableResults(this.props.selectedRole.linkedGrades, event, 'grades')}
                  onSelectedSearchChange={event => this.filterSelectedResults(selectedSpecialities, event, 'grades')}
                  filteredAvailable={this.state.gradesFilteredAvailable}
                  filteredSelected={this.state.gradesFilteredSelected}
                  discardChanges={this.discardChanges}
                  disableAnimation={this.state.disableAnimation}
                />
              );
            },
          },
          {
            tabTitle: 'Associated Specialities',
            renderTabContent: () => {
              return (
                <RGSEdit
                  type="specialities"
                  available={this.props.selectedRole.linkedSpecialities || {}}
                  selected={selectedSpecialities}
                  data={this.props.specialities}
                  editedRGS={this.state.editedRGS.specialities}
                  onSelect={this.onSelect}
                  onUnselect={this.onUnselect}
                  saveChanges={this.saveChanges}
                  onAvailableSearchChange={event => this.filterAvailableResults(this.props.selectedRole.linkedSpecialities, event, 'specialities')}
                  onSelectedSearchChange={event => this.filterSelectedResults(selectedSpecialities, event, 'specialities')}
                  filteredAvailable={this.state.specialitiesFilteredAvailable}
                  filteredSelected={this.state.specialitiesFilteredSelected}
                  discardChanges={this.discardChanges}
                  disableAnimation={this.state.disableAnimation}
                />
              );
            },
          },
        ]}
      />
    );
  }
}
