import React, { useEffect, useReducer, useMemo } from 'react';
import { firstBy } from 'thenby';
import { uniqBy } from 'lodash-es';
import colors from 'config/colors';
import DropDown from 'components/DropDown';
import Checkbox from 'components/Checkbox';
import './styles.scss';

import { Grade, Speciality, NewSpecialitiesByGrade, LegacySpecialitiesByGrade } from 'types/RgsTypes';

interface RowOrColumn {
  key: string;
  name: string;
}
interface AngledHeaderTableProps {
  rows: Array<RowOrColumn>;
  columns: Array<RowOrColumn>;
  renderCell: (rowKey: string, columnKey: string) => JSX.Element | null;
  headerCellContent?: JSX.Element | null,
}
const AngledHeaderTable : React.FC<AngledHeaderTableProps> = (props: AngledHeaderTableProps) => {
  return (
    <table className="table-header-rotated">
      <thead>
        <tr>
          <th>{props.headerCellContent ?? ' '}</th>
          {props.columns.map((col) => <th key={col.key} className="rotate"><div><span>{col.name}</span></div></th>)}
        </tr>
      </thead>
      <tbody>
        {props.rows.map((row) => (
          <tr key={row.key}>
            <th className="row-header">{row.name}</th>
            {props.columns.map(col => props.renderCell(row.key, col.key))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

AngledHeaderTable.defaultProps = {
  headerCellContent: null,
};


interface DisplayGradesAndSpecsProps {
  grades: Array<Grade>;
  specialities: Array<Speciality>;
  specialitiesByGrade: NewSpecialitiesByGrade;
}
export const DisplayGradesAndSpecs : React.FC<DisplayGradesAndSpecsProps> = (props: DisplayGradesAndSpecsProps) => {

  const grades = props.grades.sort(firstBy('rank'));
  const specialities = props.specialities.sort(firstBy('order'));

  const isChecked = (specialityKey: string, gradeKey: string) => {
    if (!props.specialitiesByGrade) return true;
    return !!props.specialitiesByGrade[specialityKey].find(gkey => gkey === gradeKey);
  };

  const renderCell = (specialityKey: string, gradeKey: string) => {
    const checked = isChecked(specialityKey, gradeKey);
    return (
      <td key={gradeKey}>{checked && <Checkbox checked={checked} disabled />}</td>
    );
  };

  return (
    <AngledHeaderTable
      rows={specialities}
      columns={grades}
      renderCell={renderCell}
    />
  );
};

function createCheckboxState(
  grades: Array<Grade>,
  specialities: Array<Speciality>,
  specialitiesByGrade: NewSpecialitiesByGrade,
  existingState?: LegacySpecialitiesByGrade,
) : LegacySpecialitiesByGrade {
  const checkboxState : LegacySpecialitiesByGrade = {};

  specialities.forEach((spec: Speciality) => {

    const specCheckboxState : { [gradeKey:string]: boolean } = {};
    grades.forEach((grade: Grade) => {
      specCheckboxState[grade.key] = existingState?.[spec.key]?.[grade.key] ?? (!specialitiesByGrade || !!specialitiesByGrade[spec.key]?.find(gkey => gkey === grade.key));
    });

    checkboxState[spec.key] = specCheckboxState;
  });

  return checkboxState;
}

function getCellColor(initialState: LegacySpecialitiesByGrade, currentState: LegacySpecialitiesByGrade, specialityKey: string, gradeKey: string) {

  const wasChecked = !!initialState[specialityKey]?.[gradeKey];
  const isChecked = !!currentState[specialityKey]?.[gradeKey];

  if (wasChecked && !isChecked) return colors.cavalry.error;
  if (!wasChecked && isChecked) return colors.cavalry.active;
  return '#FFF';
}

interface EditGradesAndSpecsProps {
  grades: Array<Grade>;
  specialities: Array<Speciality>;
  specialitiesByGrade: NewSpecialitiesByGrade;
  availableGrades: Array<Grade>;
  availableSpecialities: Array<Speciality>;
  onChange?: (specialitiesByGrade: LegacySpecialitiesByGrade) => void;
}
interface EditGradesAndSpecsState {
  grades: Array<Grade>;
  specialities: Array<Speciality>;
  checkboxState: LegacySpecialitiesByGrade;
}
interface SetCheckboxStateAction {
  type: 'SET_CHECKBOX_STATE';
  gradeKey: string;
  specialityKey: string;
  newCheckboxState: boolean;
}
interface AddGrade { type: 'ADD_GRADE'; grade: Grade; }
interface RemoveGrade { type: 'REMOVE_GRADE'; gradeKey: string; }
interface AddSpeciality { type: 'ADD_SPECIALITY'; speciality: Speciality; }
interface RemoveSpeciality { type: 'REMOVE_SPECIALITY'; specialityKey: string; }
type EditGradesAndSpecsAction = SetCheckboxStateAction | AddGrade | RemoveGrade | AddSpeciality | RemoveSpeciality;

function reducer(state: EditGradesAndSpecsState, action: EditGradesAndSpecsAction) {

  switch (action.type) {

    case 'SET_CHECKBOX_STATE': {
      const newSpecState = { ...state.checkboxState[action.specialityKey], [action.gradeKey]: action.newCheckboxState };
      const newCheckboxState = { ...state.checkboxState, [action.specialityKey]: newSpecState };
      return { ...state, checkboxState: newCheckboxState };
    }

    case 'ADD_GRADE':
      return { ...state, grades: uniqBy([...state.grades, action.grade], 'key') };

    case 'ADD_SPECIALITY':
      return { ...state, specialities: uniqBy([...state.specialities, action.speciality], 'key') };

    default:
      console.error(action);
      throw new Error('Unexpected action');
  }
}


export const EditGradesAndSpecs : React.FC<EditGradesAndSpecsProps> = (props: EditGradesAndSpecsProps) => {

  const initialState = useMemo(() => ({
    grades: props.grades.sort(firstBy('rank')),
    specialities: props.specialities.sort(firstBy('order')),
    checkboxState: createCheckboxState(props.grades, props.specialities, props.specialitiesByGrade),
  }), []);
  const [state, dispatch] : [EditGradesAndSpecsState, React.Dispatch<EditGradesAndSpecsAction>] = useReducer(reducer, initialState);

  useEffect(() => {
    if (props.onChange) props.onChange(state.checkboxState);
  }, [state.checkboxState]);


  const specialityKeys = state.specialities.map(spec => spec.key);
  const unusedAvailableSpecialities = props.availableSpecialities.filter(spec => !specialityKeys.includes(spec.key));

  const gradeKeys = state.grades.map(grade => grade.key);
  const unusedAvailableGrades = props.availableGrades.filter(grade => !gradeKeys.includes(grade.key));

  const renderCell = (specialityKey: string, gradeKey: string) => {
    const checked = !!state.checkboxState[specialityKey]?.[gradeKey];
    const backgroundColor = getCellColor(initialState.checkboxState, state.checkboxState, specialityKey, gradeKey);
    return (
      <td key={gradeKey} style={{ backgroundColor }}>
        <Checkbox
          checked={checked}
          onChange={newCheckboxState => dispatch({ type: 'SET_CHECKBOX_STATE', gradeKey, specialityKey, newCheckboxState })}
          boxStyle={{ backgroundColor: colors.white }}
        />
      </td>
    );
  };

  return (
    <AngledHeaderTable
      rows={state.specialities}
      columns={state.grades}
      renderCell={renderCell}
      headerCellContent={
        <div style={{ minWidth: 250 }}>
          <DropDown
            placeholder="Add grade..."
            options={unusedAvailableGrades}
            value={null}
            getOptionValue={opt => opt.key}
            getOptionLabel={opt => opt.name}
            customStyles={{ control: { marginBottom: 12 } }}
            onChange={(grade) => dispatch({ type: 'ADD_GRADE', grade })}
          />
          <DropDown
            placeholder="Add speciality..."
            options={unusedAvailableSpecialities}
            value={null}
            getOptionValue={opt => opt.key}
            getOptionLabel={opt => opt.name}
            onChange={(speciality) => dispatch({ type: 'ADD_SPECIALITY', speciality })}
          />
        </div>
      }
    />
  );
};

EditGradesAndSpecs.defaultProps = {
  onChange: () => {},
};
