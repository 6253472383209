import React from 'react';
import { MdPerson } from 'react-icons/md';
import colors from 'config/colors';
import './MonthCalendarEventComponent.scss';

const MonthCalendarKey = () => (
  <div className="calendarKeyContainer m-hide">
    <span className="keyText">
      Key:
    </span>
    <div className="shiftCalendarPills cal-counts-bar-key shiftCalendarPills--bank">
      <span className="shiftCalendarCountKey shiftCalendarCount--is-filled">Bank filled</span>
      <span className="shiftCalendarCountKey shiftCalendarCount--un-filled">Bank unfilled</span>
    </div>
    <div className="shiftCalendarPills cal-counts-bar-key shiftCalendarPills--agency">
      <span className="shiftCalendarCountKey shiftCalendarCount--is-filled">Agency filled</span>
      <span className="shiftCalendarCountKey shiftCalendarCount--un-filled">Agency unfilled</span>
    </div>
    <span className="keyText" style={{ color: colors.text, marginLeft: 12, fontSize: '0.9rem', fontWeight: 500 }}><MdPerson style={{ marginRight: '4px', marginBottom: '2px' }} />Shift with applicants</span>
  </div>
);

export default MonthCalendarKey;
