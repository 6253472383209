import { ApolloQueryResult, ApolloError, ObservableSubscription } from '@apollo/client';
import { pickBy, mapKeys, camelCase } from 'lodash-es';
import { subscribe, gql } from 'lib/api/apollo';
import { reportError } from '../error-reporting';
import { Conversation, Message } from '../../types/Messaging';
import * as api from '.';

interface ConversationMemberSnakeCase {
  user_key: string,
  user_name: string,
  last_viewed_at?: string;
}

interface ConversationSharedInboxSnakeCase {
  shared_inbox_key: string,
  shared_inbox_name: string,
}

interface ConversationSnakeCase {
  user_key: string
  conversation_key: string
  last_viewed_at: string | null,
  latest_message: string,
  latest_message_sent_at: string,
  latest_message_sent_by_key: string
  latest_message_sent_by_name: string,
  conversation_members: ConversationMemberSnakeCase[]
  users: ConversationMemberSnakeCase[];
  conversation_shared_inboxes: ConversationSharedInboxSnakeCase[]
  shift_keys: string[]
}

interface MessageSnakeCase {
  key: string,
  type: string,
  sent_at: string,
  text_content: string,
  sent_by_key: string,
  sent_by_name: string,
  conversation_key: string,
  likes: { userKey: string, hasLiked: boolean } | null,
}

interface WatchUnreadCountProps {
  callback: (count: number) => void,
  error: (e: ApolloError) => void,
}
export function watchConversationUnreadCount({ callback, error }: WatchUnreadCountProps): ObservableSubscription {

  return subscribe({
    query: gql`
    subscription watchConversationUnreadCounts {
      messaging_v_user_conversations_aggregate(where: {is_read: {_eq: false}, latest_non_batch_message_sent_at: {_is_null: false}}) {
        aggregate {
          count
        }
      }
    }
    `,
    callback,
    error,
    map: (res: ApolloQueryResult<{ messaging_v_user_conversations_aggregate: { aggregate: {count: number} } }>) : number => { // eslint-disable-line camelcase
      return res?.data?.messaging_v_user_conversations_aggregate.aggregate.count;
    },
    variables: {},
  });
}

function joinAndWrap(arr: Array<string | false>, joiner: string, prefix: string, suffix: string) {
  const filteredArr = arr.filter(Boolean);
  if (!filteredArr.length) return '';
  return `${prefix}${filteredArr.join(joiner)}${suffix}`;
}

interface WatchConversationsProps {
  conversationKey?: string,
  userKey?: string,
  sharedInboxKey?: string,
  callback: (conversations: Conversation[]) => void,
  error: (e: ApolloError) => void,
}
export function watchConversations({ conversationKey, userKey, sharedInboxKey, callback, error }: WatchConversationsProps): ObservableSubscription {

  const whereClauses = [
    'latest_non_batch_message_sent_at: {_is_null: false},',
    !!conversationKey && 'conversation_key: {_eq: $conversationKey},',
    !!userKey && 'conversation_members: {_contains: $memberClause}',
    !!sharedInboxKey && 'conversation_shared_inboxes: {_contains: $sharedInboxClause}',
  ];

  const variableDeclarations = [
    !!conversationKey && '$conversationKey: String',
    !!userKey && '$memberClause: jsonb',
    !!sharedInboxKey && '$sharedInboxClause: jsonb',
  ];

  const variables : Record<string, unknown> = {};
  if (conversationKey) variables.conversationKey = conversationKey;
  if (userKey) variables.memberClause = [{ user_key: userKey }]; // eslint-disable-line camelcase
  if (sharedInboxKey) variables.sharedInboxClause = [{ shared_inbox_key: sharedInboxKey }]; // eslint-disable-line camelcase

  return subscribe({
    query: gql`
    subscription watchConversations${joinAndWrap(variableDeclarations, ',', '(', ')')} {
      messaging_v_user_conversations(${joinAndWrap(whereClauses, ',', 'where: {', '},')} order_by: {latest_non_batch_message_sent_at: desc}, limit: 50) {
        conversation_key
        conversation_members
        conversation_shared_inboxes
        last_viewed_at
        latest_message
        latest_message_sent_at
        latest_message_sent_by_key
        latest_message_sent_by_name
        shift_keys
        user_key
        users
      }
    }
    `,
    callback,
    error,
    map: (res: ApolloQueryResult<{ messaging_v_user_conversations: ConversationSnakeCase[] }>) => { // eslint-disable-line camelcase
      return (res?.data?.messaging_v_user_conversations ?? []).map((conversation: ConversationSnakeCase) => {
        const mapped = mapKeys(conversation, (value, key) => camelCase(key));
        const conversationMembers = conversation.conversation_members.map((member: ConversationMemberSnakeCase) => mapKeys(member, (value, key) => camelCase(key)));
        const conversationSharedInboxes = conversation.conversation_shared_inboxes.map((shared: ConversationSharedInboxSnakeCase) => mapKeys(shared, (value, key) => camelCase(key)));
        const users = conversation.users.map((member: ConversationMemberSnakeCase) => mapKeys(member, (value, key) => camelCase(key)));
        const { typename, ...releventProps } = mapped;
        return { ...releventProps, conversationMembers, conversationSharedInboxes, users };
      });
    },
    variables,
  });
}

export function watchConversationMessages(callback: (messages: Message[]) => void, error: (e: ApolloError) => void, conversationKey: string): ObservableSubscription {
  return subscribe({
    query: gql`
    subscription watchConversationMessages($conversationKey: String) {
      messaging_v_conversation_messages(where: {conversation_key: {_eq: $conversationKey}}, order_by: {sent_at: asc}) {
        key
        type
        sent_at
        text_content
        sent_by_key
        sent_by_name
        conversation_key
        likes
      }
    }
    `,
    callback,
    error,
    map: ((res: ApolloQueryResult<{ messaging_v_conversation_messages: MessageSnakeCase[] }>) => {
      return (res?.data?.messaging_v_conversation_messages ?? []).map((message: MessageSnakeCase) => {
        return mapKeys(message, (value, key) => camelCase(key));
      });
    }),
    variables: { conversationKey },
  });
}

export async function fetchConversationsMetadata() {

  const response = await api.get('conversations/metadata');

  if (response?.body.success && response.body.sharedInboxMembership) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to fetch conversations metadata.');
  reportError(error, {
    api: {
      path: 'conversations/metadata',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stacktrace ?? null,
    },
  });
  throw new Error(response?.body.humanReadableErrorMessage ?? 'Unable to fetch messaging metadata. If the problem persists, please contact technical support.');
}

export async function fetchSharedInboxDetails(sharedInboxKey: string) {

  const response = await api.get('shared-inboxes/get', { sharedInboxKey });

  if (response?.body.success && response.body.sharedInbox) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to fetch shared inbox details.');
  reportError(error, {
    api: {
      path: 'shared-inboxes/get',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stacktrace ?? null,
      payload: { sharedInboxKey },
    },
  });
  throw new Error(response?.body.humanReadableErrorMessage ?? 'Unable to fetch shared inbox details. If the problem persists, please contact technical support.');
}

interface MessagePayload {
  textContent: string;
  type: string;
}

interface Filters {
  bankKeys?: string[]
  siteKeys?: string[]
  roleKeys?: string[]
  gradeKeys?: string[]
  specialityKeys?: string[]
}

export async function sendBatchMessage(message: MessagePayload, filters: Filters, sharedInboxKey?: string) {

  const response = await api.post('batch/send', { message, filters, sharedInboxKey });

  if (response?.body.success && response.body.batchSendList && response.body.batchConversationKey && response.body.messages) return response.body;


  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to send batch message.');
  reportError(error, {
    api: {
      path: 'batch/send',
      status: response.status,
      error,
      body: response?.body,
      stackTrace: response?.body?.stackTrace ?? null,
      payload: { message, filters, sharedInboxKey },
    },
  });
  throw new Error(response?.body.humanReadableErrorMessage ?? 'Failed to send broadcast message. If the problem persists, please contact technical support.');
}

export async function fetchBatchMessages(filters: Filters, sharedInboxKey?: string) {

  const response = await api.post('batch/search', { filters, sharedInboxKey });

  if (response?.body.success && response.body.batchConversation !== undefined) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to fetch batch messages.');
  reportError(error, {
    api: {
      path: 'batch/search',
      status: response.status,
      error,
      body: response?.body,
      stackTrace: response?.body?.stackTrace ?? null,
      payload: { filters, sharedInboxKey },
    },
  });
  throw new Error(response?.body.humanReadableErrorMessage ?? 'Failed to fetch previous batch messages.');
}

export async function fetchSendListStaff(filters: Filters) {

  const response = await api.post('batch/get-send-list-staff', { filters });

  if (response?.body.success && response.body.recipients) return response.body;

  const error = new Error(typeof response?.body?.error === 'string' ? response.body.error : 'Failed to fetch staff list.');
  reportError(error, {
    api: {
      path: 'batch/get-send-list-staff',
      status: response.status,
      error,
      body: response?.body,
      stacktrace: response?.body?.stacktrace ?? null,
      payload: { filters },
    },
  });
  throw new Error(response?.body.humanReadableErrorMessage ?? 'Failed to fetch staff list.');
}
