const FETCH_RGS_METADATA = 'FETCH_RGS_METADATA';
const FETCH_RGS_METADATA_SUCCESS = 'FETCH_RGS_METADATA_SUCCESS';
const FETCH_RGS_METADATA_ERROR = 'FETCH_RGS_METADATA_ERROR';
const STORE_ROLES = 'STORE_ROLES';

export function fetchRGSMetadata() {
  return {
    type: FETCH_RGS_METADATA,
  };
}

export function fetchRGSMetadataSuccess(RGS) {
  return {
    type: FETCH_RGS_METADATA_SUCCESS,
    RGS,
  };
}

export function fetchRGSMetadataError(error) {
  return {
    type: FETCH_RGS_METADATA_ERROR,
    error,
  };
}

export function storeRoles(roles) {
  return {
    type: STORE_ROLES,
    roles,
  };
}

const initialState = {
  isFetchingRGSMetadata: false,
  rgsMetadata: null,
  error: null,
};

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case FETCH_RGS_METADATA:
      return {
        ...state,
        isFetchingRGSMetadata: true,
      };
    case FETCH_RGS_METADATA_SUCCESS:
      return {
        ...state,
        rgsMetadata: action.RGS,
        isFetchingRGSMetadata: false,
      };
    case FETCH_RGS_METADATA_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingRGSMetadata: false,
      };
    case STORE_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    default:
      return state;
  }
}
