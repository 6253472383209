import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchPaymentRunSummaries } from 'thunks/timesheets';
import { createPaymentRun as createPayRun } from 'lib/api/timesheets';
import PaymentRunForm from './PaymentRunForm';

import { PaymentRunFormFields } from '../../../types/PaymentRun';

interface CreatePaymentRunProps {
  goToPaymentRunListView: () => void,
  goToPaymentRunDetailsView: (paymentRunKey: string) => void,
}

const CreatePaymentRun : React.FC<CreatePaymentRunProps> = (props : CreatePaymentRunProps) => {

  const dispatch = useDispatch();
  const [isCreatingPaymentRun, setIsCreatingPaymentRun] = useState(false);
  const [apiError, setApiError] = useState(null);

  const createPaymentRun = async (fields: PaymentRunFormFields, scheduled: boolean) => {

    const createPaymentRunPayload = { ...fields };

    if (!scheduled) {
      createPaymentRunPayload.scheduledSendTime = null;
      createPaymentRunPayload.scheduledPaymentTime = null;
    }

    try {
      if (apiError) setApiError(null);
      setIsCreatingPaymentRun(true);
      const res = await createPayRun(createPaymentRunPayload);
      setIsCreatingPaymentRun(false);
      await dispatch(fetchPaymentRunSummaries());
      props.goToPaymentRunDetailsView(res.paymentRunKey);
    } catch (error) {
      setApiError(error.message);
      setIsCreatingPaymentRun(false);
    }
  };

  return (
    <PaymentRunForm
      submit={createPaymentRun}
      onClose={props.goToPaymentRunListView}
      isLoading={isCreatingPaymentRun}
      apiError={apiError}
      title="Create Payment Run"
    />
  );
};

export default CreatePaymentRun;
