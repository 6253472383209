import React, { useState } from 'react';
import TextAreaAutosize from 'react-autosize-textarea';
import { MdSend } from 'react-icons/md';
import colors from 'config/colors';

interface ComposeMessageBarProps {
  sendMessage: (message: string) => void;
  disabled?: boolean;
  autoFocusTextArea?: boolean;
}

const ComposeMessageBar: React.FC<ComposeMessageBarProps> = ({ sendMessage, disabled = false, autoFocusTextArea = true }: ComposeMessageBarProps) => {
  const [messageText, setMessageText] = useState('');
  const sendDisabled = disabled || !messageText;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="messagingComposeMessageContainer">
        <TextAreaAutosize
          className="messagingComposeMessageTextArea"
          value={messageText}
          onChange={event => setMessageText((event.target as HTMLInputElement).value)}
          placeholder="Type a message..."
          autoFocus={autoFocusTextArea}
          maxRows={10}
          onKeyDown={(event) => {
            if (event.keyCode === 13 && !event.shiftKey) {
              event.preventDefault();
              if (!sendDisabled) {
                sendMessage(messageText);
                setMessageText('');
              }
            }
          }}
        />
        <div
          className="messagingComposeMessageSend"
          onClick={() => {
            if (!sendDisabled) {
              sendMessage(messageText);
              setMessageText('');
            }
          }}
        >
          <MdSend size={25} color={sendDisabled ? colors.disabled : colors.text} />
        </div>
      </div>
      <div style={{ fontSize: '0.75rem', color: '#999', padding: '6px 12px' }}>Press Enter to send. Press Shift-Enter for a new line.</div>
    </div>
  );
};

export default ComposeMessageBar;
