import * as api from '.';

interface LoginResponse {
  success: boolean,
  passwordExpired: boolean,
  passwordExpiringSoon: boolean,
  passwordExpiresAt: string,
  isAdmin?: boolean,
  isCandidate?: boolean,
  userKey: string,
  token: string,
}
export async function login(email: string, password: string, options = { validatePasswordOnly: false }) : Promise<LoginResponse> {
  const response = await api.post('account/user/login', { email, password, validatePasswordOnly: options.validatePasswordOnly, userType: 'admin-or-supplier' });

  if (response.status !== 200 && response.status !== 410) {
    const messageOrError = response.error ?? response.body?.humanReadableErrorMessage ?? response.body?.details?.humanReadableErrorMessage ?? response.body?.error?.message ?? response.body?.error ?? 'Logging in failed';
    const error = (messageOrError instanceof Error ? messageOrError : new Error(messageOrError));
    if (typeof messageOrError === 'string') (error as any).humanReadableErrorMessage = messageOrError;
    throw error;
  }

  return response.body;
}

export async function sendPasswordResetEmail(email: string, loginTarget: 'web-app' | 'mobile-app') : Promise<void> {
  const response = await api.post('account/password-reset/send', { email, loginTarget });
  if (response.status >= 400) throw new Error(typeof response.error === 'string' ? response.error : 'An error occured when attemping to request a password reset. If the problem persists, please contact technical support.');
}


export interface RegexRequirement {
  pattern: string;
  flags?: string;
  description: string;
}

export interface PasswordRequirements {
  minLength?: number;
  expiryTimeDays: number;
  regexRequirements?: Array<RegexRequirement>;
}
export async function fetchPasswordRequirements() : Promise<PasswordRequirements> {
  const response = await api.get('account/password-reset/get-requirements');
  if (response.status >= 400) throw new Error(response.body?.details?.humanReadableErrorMessage ?? response.body?.error ?? response.error);
  return response.body;
}


interface TokenLookup {
  email: string;
  isAdmin: string;
  isCandidate: string;
}
export async function lookupPasswordResetToken(token: string) : Promise<TokenLookup> {
  const response = await api.get('account/password-reset/get', { token });
  if (response.status >= 400) throw new Error(response.body?.details?.humanReadableErrorMessage ?? response.body?.error ?? response.error);
  return response.body;
}

export async function resetPasswordWithToken(token: string, newPassword: string) : Promise<TokenLookup> {
  const response = await api.post('account/password-reset/reset', { token, newPassword });
  if (response.status >= 400) throw new Error(response.body?.details?.humanReadableErrorMessage ?? response.body?.error ?? response.error);
  return response.body;
}

export async function resetPasswordWithCurrentPassword(currentPassword: string, newPassword: string) : Promise<TokenLookup> {
  const response = await api.post('account/password-reset/reset', { currentPassword, newPassword });
  if (response.status >= 400) throw new Error(response.body?.details?.humanReadableErrorMessage ?? response.body?.error ?? response.error);
  return response.body;
}
