import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface RoleFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  role: { id: string, name: string } | null,
  mandatoryFields: Array<string>,
  mandatory?: boolean
}

function RoleField(props: RoleFieldProps) {

  const roles = useSelector(({ createDraftShifts }) => createDraftShifts.roles);
  const rolesList = useMemo(() => {
    const rolesCopy = [...(roles ?? [])];
    if (!props.mandatoryFields.includes('role')) rolesCopy.unshift({ value: null, label: 'Any' });

    return rolesCopy;
  }, [roles]);

  return (
    <FieldLabel label="Role">
      <JobsSelectBox
        name="roles"
        value={props.role ?? { id: null, name: 'Any' }}
        placeholder="Role *"
        data={rolesList}
        onChange={props.onChange}
        mandatory={props.mandatory}
      />
    </FieldLabel>
  );
}

RoleField.defaultProps = { mandatory: false };

export default React.memo(RoleField);
