import { useState, useMemo, useCallback, useEffect } from 'react';

export interface SwitchListItem {
  key: string;
  label: string;
}
export type SwitchListState = Record<string, boolean>;
export interface SwitchListHookObject {
  items: Array<SwitchListItem>,
  state: SwitchListState,
  hasChanged: boolean,
  allSelected: boolean,
  setItemState: (itemKey: string, newItemState: boolean) => void,
  setAllItemStates: (state: SwitchListState) => void,
  reset: () => void,
  toggleSelectAll: () => void,
}

export function useSwitchList(items: Array<SwitchListItem>, initialState?: SwitchListState): SwitchListHookObject {

  const actualInitialState = initialState ?? items.reduce((memo, item) => ({ ...memo, [item.key]: false }), {});

  const [initialStateStored, setInitialStateStored] = useState<SwitchListState>(actualInitialState);
  const [state, setAllItemStates] = useState<SwitchListState>(actualInitialState);

  const hasChanged = useMemo(() => items.some(({ key }) => initialStateStored[key] !== state[key]), [items, initialStateStored, state]);
  const allSelected = useMemo(() => items.every(({ key }) => !!state[key]), [items, initialStateStored, state]);
  const reset = useCallback(() => setAllItemStates(initialStateStored), [items, setAllItemStates]);
  const toggleSelectAll = useCallback(() => {
    const newState = items.reduce((memo, item) => ({ ...memo, [item.key]: !allSelected }), {});
    setAllItemStates(newState);
  }, [items, allSelected, setAllItemStates]);
  const setItemState = useCallback((itemKey: string, newItemState: boolean) => {
    const newState = items.reduce((memo, item) => ({ ...memo, [item.key]: !!(item.key === itemKey ? newItemState : state[item.key]) }), {});
    setAllItemStates(newState);
  }, [items, state, setAllItemStates]);

  useEffect(() => {
    const newInitialState = initialState ?? items.reduce((memo, item) => ({ ...memo, [item.key]: false }), {});
    setAllItemStates(newInitialState);
    setInitialStateStored(newInitialState);
  }, [items]);

  return useMemo(() => ({
    items,
    state,
    setAllItemStates,
    hasChanged,
    allSelected,
    reset,
    toggleSelectAll,
    setItemState,
  }), [items, state, setAllItemStates, reset]);
}
