import React from 'react';
import moment from 'moment-timezone';

import { convertFromUtcToTimezone } from 'lib/helpers-time';
import colors from 'config/colors';

import { StartEndTimePicker } from 'components/CustomTimePickers';

import FieldLabel from './FieldLabel';

function formatTimeDiff(startTime: moment.Moment, endTime: moment.Moment) {
  if (!startTime || !endTime) return null;

  const diff = endTime.diff(startTime, 'minutes');
  const minutes = diff % 60;
  const hours = (diff - minutes) / 60;

  if (minutes > 0) return `Duration: ${hours} hours ${minutes} mins`;
  return `Duration: ${hours} hours`;
}

interface TimeFieldProps {
  onChangeStartTime: (hours: number | null, minutes: number | null) => void,
  onChangeEndTime: (hours: number | null, minutes: number | null) => void,
  startTime: string,
  endTime: string,
  timezone: string,
  disabled?: boolean
}

function TimeField(props: TimeFieldProps) {

  const startTimeTimezoneConverted = convertFromUtcToTimezone(props.startTime, props.timezone);
  const endTimeTimezoneConverted = convertFromUtcToTimezone(props.endTime, props.timezone);

  return (
    <FieldLabel label="Time">
      <StartEndTimePicker
        startTime={startTimeTimezoneConverted}
        onStartTimeChange={props.onChangeStartTime}
        endTime={endTimeTimezoneConverted}
        onEndTimeChange={props.onChangeEndTime}
        disabled={props.disabled}
      />
      <p style={{ fontSize: '0.9rem', marginTop: 6, color: colors.text, fontWeight: 500 }}>
        {formatTimeDiff(startTimeTimezoneConverted, endTimeTimezoneConverted)}
      </p>
    </FieldLabel>
  );
}

TimeField.defaultProps = { disabled: false };

export default React.memo(TimeField);
