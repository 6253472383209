import React, { useCallback, CSSProperties } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { isTruthy } from 'lib/helpers-ts';
import { useFeatureFlag } from 'hooks/feature';
import PageHeader from 'components/PageHeader';
import { TabBar } from 'components/Tabs';

import colors from 'config/colors';

const styles : { [key:string]: CSSProperties} = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '12px 12px 24px 12px',
    position: 'relative',
  },
};

type ReportingTabKey = 'dashboard' | 'spend' | 'fill' | 'requests' | 'exports' | 'skills-and-qualifications';
interface ReportingHeaderProps {
  selectedTabKey: ReportingTabKey;
}

const ReportingHeader = (props: ReportingHeaderProps) : JSX.Element => {

  const history = useHistory();

  const showSpend = useFeatureFlag('reportingSpend');
  const showExports = useFeatureFlag('reportingExports');

  const tabs = [
    { key: 'dashboard', title: 'Dashboard', route: '/reporting/dashboard' },
    showSpend && { key: 'spend', title: 'Spend', route: '/reporting/spend' },
    { key: 'fillRate', title: 'Fill Rate', route: '/reporting/fill' },
    { key: 'requests', title: 'Shift Requests', route: '/reporting/requests' },
    { key: 'skills-and-qualifications', title: 'Skills And Qualifications', route: '/reporting/skills-and-qualifications' },
    showExports && { key: 'exports', title: 'Exports', route: '/reporting/exports' },
  ].filter(isTruthy);

  const goToTab = useCallback((tabKey: string) => {
    const tab = tabs.find(t => t.key === tabKey);
    if (tab?.route) history.push(tab?.route);
  }, [tabs, history]);

  return (
    <div style={{ flex: '0 0 auto', backgroundColor: colors.cavalry.backgroundLight6 }}>
      <div style={styles.headerContainer} className="m-hide">
        <PageHeader style={{ marginLeft: 0 }} title="Reporting" />
      </div>
      <TabBar tabs={tabs} selectedTabKey={props.selectedTabKey} onTabClick={goToTab} />
    </div>
  );
};

export default ReportingHeader;
