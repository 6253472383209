import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdInfo } from 'react-icons/md';
import { firstBy } from 'thenby';

import colors from 'config/colors';
import { permissions } from 'config/permissions';
import { isFeatureOn } from 'lib/features';
import { updateGradesAndSpecialities } from 'lib/api/accounts';

import Button from 'components/Button';
import Loading from 'components/Loading';
import { DisplayGradesAndSpecs, EditGradesAndSpecs } from './DisplayGradesAndSpecs';


const localStyles = {
  textStyle: { color: colors.text, fontSize: '16px' },
  infoStyle: { color: colors.white, fontSize: '32px', paddingLeft: 10 },
};

const { objectOf, oneOfType, object, string, number, bool, array, arrayOf, shape } = PropTypes;
class RgsTab extends Component {
  static propTypes = {
    styles: objectOf(object),
    userId: string,
    metadata: objectOf(object),
    candidateId: string,
    orgSpecialitiesList: arrayOf(shape({ key: string, name: string })).isRequired,
    specialitiesMetadata: objectOf(oneOfType([
      object,
    ])),
    canEditRGS: bool.isRequired,
  }

  static defaultProps = {
    styles: {},
    userId: null,
    metadata: {},
    candidateId: '',
    specialitiesMetadata: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.adminHasWriteAccess && props.profile && !(props.profile.grades && props.profile.specialities),
      isSaving: false,
      saveError: false,
      checkboxState: null,
    };
  }

  saveGradesAndSpecialities = async () => {
    if (!this.state.checkboxState) return;

    this.setState({ isSaving: true });

    try {
      await updateGradesAndSpecialities({
        candidateId: this.props.candidateId,
        userId: this.props.userId,
        specialitiesByGrade: this.state.checkboxState,
      });
      await this.props.fetchCandidate();
      this.setState({ isSaving: false, isEditing: false });
    } catch (e) {
      console.log(e);
      this.setState({ isSaving: false, isEditing: true, saveError: 'Something went wrong.' });
    }

  }

  render() {
    const { isEditing, isSaving, saveError } = this.state;
    const { styles, candidateId, profile, orgSpecialitiesList, candidateRoleMetadata, isMobile, adminHasWriteAccess } = this.props;
    const { specialitiesByGrade, roleKey, roleName } = profile;

    if (!roleKey) {
      return (
        <div style={styles.profileMainStyle}>
          <div style={styles.profileHeaderStyle}><MdInfo style={localStyles.infoStyle} /></div>
          <div style={{ ...styles.profileContentContainer, textAlign: 'center' }}>
            <p style={localStyles.textStyle}>This candidate has not provided their Role, Grades or Specialities yet.</p>
            <p style={localStyles.textStyle}>This information will become available as they progress through their registration.</p>
          </div>
        </div>
      );
    }

    if (isSaving) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}><Loading /></div>
      );
    }

    const grades = profile.grades ?? [];
    const specialities = profile.specialities ?? [];
    const maxGradeName = grades.sort(firstBy('rank'))[0]?.name ?? null;
    const roleSpecialities = Object.entries(candidateRoleMetadata.specialities).map(([specKey, specData]) => ({ key: specKey, name: specData.name })).flat();
    const roleGrades = Object.entries(candidateRoleMetadata.grades).map(([gradeKey, gradeData]) => ({ key: gradeKey, name: gradeData.name })).flat();

    return (
      <div className="candidate-rgs-tab">
        <div style={{ flex: 1, display: 'flex', flexDirection: isMobile ? 'column' : 'row', height: '100%' }}>
          <div style={{ flex: isMobile ? '0 0 auto' : '1 1 25%' }}>
            <div style={{ padding: '24px 12px', borderBottom: `1px solid ${colors.cavalry.line}`, backgroundColor: colors.cavalry.backgroundFocussed }}>
              <h2 style={{ fontSize: '1.3rem', fontWeight: 600, color: colors.text, lineHeight: 1.4 }}>{roleName || null}</h2>
              <p style={{ color: colors.text, fontSize: '0.9rem' }}>{maxGradeName}</p>
            </div>
          </div>
          <div style={{ flex: '1 1 75%', height: '100%', borderLeft: '1px solid #CCC', padding: 12, overflow: 'auto' }}>
            <div style={{ display: 'flex' }}>
              <h2 style={{ flex: 1, fontWeight: 600, fontSize: '1.5rem', color: colors.text, paddingTop: 12 }}>Grades & Specialities</h2>
              <div style={{ flex: '0 0 auto' }}>
                {adminHasWriteAccess && !isEditing && <Button white outline shadow={false} disabled={!this.props.canEditRGS} onClick={() => this.setState({ isEditing: true })}>Edit</Button>}
                {isEditing && <Button white outline shadow={false} disabled={isSaving} onClick={() => this.setState({ isEditing: false })}>Cancel</Button>}
                {adminHasWriteAccess && isEditing && <Button black shadow={false} disabled={isSaving} onClick={this.saveGradesAndSpecialities} style={{ marginLeft: 12 }}>Save</Button>}
              </div>
            </div>
            {!isEditing && <DisplayGradesAndSpecs
              grades={grades}
              specialities={specialities}
              specialitiesByGrade={specialitiesByGrade}
            />}
            {isEditing && <EditGradesAndSpecs
              grades={grades}
              specialities={specialities}
              specialitiesByGrade={specialitiesByGrade}
              availableSpecialities={roleSpecialities}
              availableGrades={roleGrades}
              onChange={(checkboxState) => this.setState({ checkboxState })}
            />}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ candidate, user, global, rgs, screen }) {
  const candidateRoleKey = candidate?.profile?.roleKey;

  return {
    userId: user.userId,
    candidateId: candidate.candidateId,
    profile: candidate.profile,
    candidateRoleMetadata: rgs.rgsMetadata[candidateRoleKey],
    canEditRGS: isFeatureOn(null, permissions.BANK_CAN_EDIT_RGS, user, global),
    isMobile: screen.isMobile,
  };
}

export default connect(mapStateToProps)(RgsTab);
