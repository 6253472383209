import React from 'react';
import { useSelector } from 'react-redux';

import { convertToSelectArray, convertFromSelect } from 'lib/helpers';

import JobsSelectBox from '../Adhoc/JobsSelectBox';
import FieldLabel from './FieldLabel';

interface GenderFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  gender: { id: string, name: string } | null,
}

function GenderField(props: GenderFieldProps) {

  const preferredGender = useSelector(({ global }) => global.orgConfig?.preferredGender);
  const defaultGenderId = preferredGender.default.id ?? null;

  let gender;
  let genders;

  if (preferredGender) {
    genders = preferredGender?.genders ?? null;
    if (genders) delete genders[defaultGenderId];
    gender = props.gender && (props.gender.id === defaultGenderId ? null : props.gender);
  }

  return (
    <FieldLabel label="Gender">
      <JobsSelectBox
        name="gender"
        value={gender}
        placeholder="Preferred gender (optional)"
        data={convertToSelectArray(genders, 'name')}
        onChange={data => props.onChange(data ? convertFromSelect(data) : preferredGender.default)}
        clearable
      />
    </FieldLabel>
  );
}

export default React.memo(GenderField);
