import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import colors from 'config/colors';
import { browserOnBlur } from 'lib/browser-type';
import './DropDown.css';

export default function DropDown(props) {
  const customStyles = {
    control: (base, state) => {
      const style = {
        ...base,
        minHeight: '1px',
        height: props.height,
        ...props.customStyles.control,
        fontSize: '0.9rem',
        border: state.isFocused ? `1px solid ${colors.cavalry.primary}` : `1px solid ${colors.cavalry.line}`,
        boxShadow: 'none',
        color: colors.text,
        borderRadius: 4,
        ':hover': {
          border: state.isFocused ? `1px solid ${colors.cavalry.primary}` : `1px solid ${colors.cavalry.line}`,
        },
      };
      return style;
    },
    container: (base) => ({
      ...base,
      width: props.width,
    }),
    valueContainer: (provided) => ({
      ...provided,
      // minHeight: '1px',
      fontSize: '0.9rem',
      paddingTop: '0',
      paddingBottom: '0',
      padding: '0 5px',
      ...props.customStyles.valueContainer,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: '0.9rem',
      ...props.customStyles.menuList,
    }),
    option: (base, state) => ({
      ...base,
      fontSize: '0.9rem',
      height: 36,
      display: 'flex',
      alignItems: 'center',
      color: colors.text,
      backgroundColor: state.isSelected ? colors.cavalry.primary : colors.white,
      ':hover': {
        backgroundColor: state.isSelected ? colors.cavalry.primary : colors.cavalry.backgroundFocussed,
      },
      // ...props.customStyles.option,
    }),
    menu: (base) => ({ ...base, ...props.customStyles.menu }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1000000,
      ...props.customStyles.menuPortal,
    }),
    multiValueLabel: (base) => ({
      ...base,
      ...props.customStyles.multiValueLabel,
    }),
    dropdownIndicator: (base) => ({ ...base, padding: '0 8px' }),
    clearIndicator: (base) => ({ ...base, padding: '0 8px' }),
    singleValue: provided => ({
      ...provided,
      color: colors.text,
    }),
  };

  const theme = base => ({
    ...base,
    borderRadius: props.borderRadius,
    colors: {
      ...base.colors,
      // primary25: 'rgba(0, 94, 134, 0.2)',
      // primary50: 'rgba(0, 94, 134, 0.5)',
      // primary: colors.positive,
    },
  });
  return (
    <Select
      onBlur={browserOnBlur()}
      styles={customStyles}
      theme={theme}
      classNamePrefix="drop-down"
      {...props}
    />
  );
}

const { shape, string, number, objectOf, oneOfType, bool, arrayOf, object, func } = PropTypes;

DropDown.propTypes = {
  options: arrayOf(object).isRequired,
  onChange: func.isRequired,

  customStyles: shape({
    control: objectOf(oneOfType([number, string])),
    valueContainer: objectOf(oneOfType([number, string])),
    menuList: objectOf(oneOfType([number, string])),
    menuPortal: objectOf(oneOfType([number, string])),
    option: objectOf(oneOfType([number, string])),
  }),
  height: oneOfType([number, string]),
  width: oneOfType([number, string]),
  borderColor: string,
  isClearable: bool,
  isSearchable: bool,
  defaultValue: oneOfType(object),
  value: oneOfType(object),
  isDisabled: bool,
  getOptionLabel: func,
  getOptionValue: func,
  menuPosition: string,
  portalPlacement: string,
  menuPortalTarget: oneOfType(object),
  placeholder: string,
  borderRadius: number,
};

DropDown.defaultProps = {
  customStyles: {
    control: {},
    valueContainer: {},
    menuList: {},
    option: {},
    menu: {},
  },
  getOptionLabel: undefined,
  getOptionValue: undefined,
  borderColor: null,
  height: '36px',
  width: undefined,
  value: undefined,
  defaultValue: undefined,
  isClearable: undefined,
  isSearchable: undefined,
  menuPosition: undefined,
  menuPortalTarget: undefined,
  portalPlacement: undefined,
  placeholder: undefined,
  isDisabled: false,
  borderRadius: 2,
};
