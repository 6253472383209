/* eslint  no-underscore-dangle:"off" */
import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from '../reducers';
import { persistMiddleware, rehydrateUserState } from '../middleware/persist';

const customCombineReducers = (newReducers) => {
  const appReducer = combineReducers(newReducers);

  const rootReducer = (state, action) => {

    if (action.type === 'RESET_STATE') {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  return rootReducer;
};

class Store {
  constructor() {
    this.store = null;
  }

  configure() {
    const composeEnhancers = typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      !process.env.REACT_APP_PRODUCTION ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      }) : compose;

    const enhancer = composeEnhancers(
      applyMiddleware(reduxThunk, persistMiddleware),
    );

    this.store = createStore(customCombineReducers(reducers), enhancer);
    rehydrateUserState(this.store);
    return this.store;
  }
}

if (module.hot) {
  module.hot.accept('../reducers', () => {
    const newReducers = require('../reducers').default; // eslint-disable-line @typescript-eslint/no-var-requires
    store.replaceReducer(customCombineReducers(newReducers));
  });
}

const store = new Store().configure();
export default store;
