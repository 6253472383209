function downloadFile(filename, mimeType, content) {

  // Workaround for IE11
  if (navigator.msSaveBlob) {
    const blob = content instanceof Blob ? content : new Blob([content], { type: mimeType });
    navigator.msSaveBlob(blob, filename);

    // Other Browsers...
  } else {

    // Create an <a> tag on the fly
    const link = window.document.createElement('a');
    link.href = content instanceof Blob ? URL.createObjectURL(content) : `data:${mimeType},${encodeURI(content)}`;
    link.download = filename;

    // Firefox requires the link in actual DOM. So we add it, click it, and then remove it again.
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
    }, 5000);
  }
}

export default downloadFile;
