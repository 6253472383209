import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import colors from 'config/colors';

import * as templateThunks from 'thunks/templates';

import { MdPushPinOutline } from 'components/Icons';
import PageHeader from 'components/PageHeader';
import SplitButton from 'components/SplitButton';
import { FilterBarV2 } from 'components/FilterBar';
import { useFeatureFlag } from 'hooks/feature';
import TimePeriodControl from './TimePeriodControl';
import ShiftSearchBox from './ShiftSearchBox';
import { CreateShiftButton, ExportShiftsButton, PinShiftsPanelButton } from './ShiftsHeaderButtons';

const styles = {
  outerContainer: {
    display: 'flex',
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  rightContainer: {
    flex: '0 0 350px',
    // padding: 12,
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-evenly',
  },
  leftContainer: {
    position: 'relative',
    flex: '1 1 0px',
    borderRight: `1px solid ${colors.cavalry.line}`,
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  topLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.cavalry.line}`,
    padding: 12,
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  buttonContainer: {
    flex: '0 0 auto',
  },
};
export default function ShiftsHeader(props) {

  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.screen.isMobile);
  const adminCanCreateShifts = useFeatureFlag(null, 'jobsCanCreate');

  const createBlankTemplate = (periodType) => {
    dispatch(templateThunks.createBlankTemplate(periodType));
    props.replaceToTemplateEditView('new');
  };

  const splitButtonMenuItems = [
    { key: 'view-templates', label: 'View Templates', onClick: props.goToTemplatesListView },
    { key: 'new-day-template', label: 'New Day Template', onClick: () => createBlankTemplate('day') },
    { key: 'new-week-template', label: 'New Week Template', onClick: () => createBlankTemplate('week') },
  ];

  const filterBarButtons = (
    <div className="space-children-6" style={{ display: 'flex' }}>
      <CreateShiftButton period={props.period} goToShiftTab={props.goToShiftTab} />
      {adminCanCreateShifts && (
        <SplitButton
          menuItems={splitButtonMenuItems}
          onClick={() => props.gotToApplyTemplate(props.period.startDate)}
          openMenuDirection="left"
          white
        >
          Apply Template
        </SplitButton>
      )}
    </div>
  );

  return (
    <div className="shiftHeader" style={styles.outerContainer}>
      <div style={{ ...styles.leftContainer, justifyContent: isMobile ? 'space-between' : 'center' }}>
        <div style={styles.topLeftContainer}>

          <div className="m-hide" style={{ marginRight: 24 }}>
            <PageHeader title="Shifts" linkTo="/shifts" style={{ marginTop: 6, marginBottom: 6 }} />
          </div>

          <span style={{ marginRight: 6, flex: '1 1 0px' }}>
            <TimePeriodControl period={props.period} goToDate={props.goToDate} />
          </span>

          <div className="filterBarButtonContainer m-hide" style={styles.buttonContainer}>
            <div className="space-children-6" style={{ display: 'flex' }}>
              <CreateShiftButton period={props.period} goToShiftTab={props.goToShiftTab} />
              {adminCanCreateShifts && (
                <SplitButton
                  menuItems={splitButtonMenuItems}
                  onClick={() => props.gotToApplyTemplate(props.period.startDate)}
                  openMenuDirection="left"
                  white
                >
                  Apply Template
                </SplitButton>
              )}
            </div>
          </div>

        </div>
        <div style={{ padding: 12, backgroundColor: colors.cavalry.backgroundLight6 }}>
          <FilterBarV2 namespace="shifts" filterConfigKey="enabledShiftFilters" buttons={filterBarButtons} />
        </div>
      </div>
      <div style={styles.rightContainer}>

        <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
          {/* Searchbox */}
          <div style={{ flex: '1 1 0px' }}><ShiftSearchBox expand fullWidth /></div>
          {/* Export Button */}
          <div style={{ flex: '0 0 auto', marginLeft: 12 }}><ExportShiftsButton period={props.period} /></div>
        </div>

        {/* Pin button & explanatory text */}
        <div style={{ display: 'flex', padding: 12, alignItems: 'center' }}>
          <PinShiftsPanelButton />
          <p className="m-hide" style={{ flex: 1, color: colors.text, fontSize: '0.75rem', lineHeight: 1.2, paddingLeft: 12 }}>
            Tip: Use the <MdPushPinOutline /> button to prevent the shift details panel from covering the calendar or list view.
          </p>
        </div>

      </div>
    </div>
  );
}

const { func, object } = PropTypes;
ShiftsHeader.propTypes = {
  period: object.isRequired,
  goToDate: func.isRequired,
  goToShiftTab: func.isRequired,
  gotToApplyTemplate: func.isRequired,
  goToTemplatesListView: func.isRequired,
  replaceToTemplateEditView: func.isRequired,
};
