import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrgPolicies from './OrgPolicies';
import Page from '../../components/Page';
import policiesTabs from './policiesTabs';
import { updatePolicies } from '../../lib/api/accounts';
import * as policiesThunks from '../../thunks/policies';
import * as rateCardThunks from '../../thunks/rateCards';
import * as policiesReducer from '../../reducers/policies';
import RateCardEditModal from './RateCardEditModal';
import { isFeatureOn } from '../../lib/features';
import { permissions } from '../../config/permissions';

const { func, object, string, array, objectOf } = PropTypes;
class OrgPoliciesContainer extends Component {
  static propTypes = {
    stopWatchingOrgSiteRateCards: func.isRequired,
    authorisedOrgs: object,
    selectedOrg: string,
    orgRateCards: array.isRequired,
    startWatchingPolicies: func.isRequired,
    stopWatchingPolicies: func.isRequired,
    policies: objectOf(object),
    type: string,
  }

  static defaultProps = {
    selectedOrg: null,
    policies: {},
    authorisedOrgs: null,
    type: 'org',
  }

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      rateCard: null,
      rateCardCreateModalOpen: false,
      rateCardKey: null,
    };
  }

  UNSAFE_componentWillMount = async () => {
    await this.props.fetchOrgSiteRateCards('org');
    await this.props.setType('org');
    await this.props.addSelectedOrg(Object.keys(this.props.authorisedOrgs)[0]);
    this.props.startWatchingPolicies();
    await this.props.addSelectedOrg(Object.keys(this.props.authorisedOrgs)[0]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orgRateCards !== this.props.orgRateCards) {
      this.props.orgRateCards.forEach((rateCard) => {
        if (rateCard.key === this.state.rateCardKey) {
          this.setState({
            rateCard,
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.stopWatchingOrgSiteRateCards('org');
    this.props.stopWatchingPolicies();
  }

  onRateCardClick = (rateCardKey, rateCard) => {
    console.log('clicked', rateCardKey, rateCard);
    this.setState({ modalOpen: true, rateCardKey, rateCard });
  }

  updatePolicies = (type, data) => {
    updatePolicies({
      type,
      data,
      orgId: this.props.selectedOrg,
    });
  }

  canEdit = () => {
    return isFeatureOn(null, permissions.ORG_CAN_EDIT_POLICIES, this.props.user, this.props.global);
  }

  render() {
    return (
      <div>
        <Page title="Policies: Your Org" tabs={policiesTabs}>
          <OrgPolicies
            onRateCardClick={this.onRateCardClick}
            openRateCardCreateModal={() => this.setState({ rateCardCreateModalOpen: true })}
            closeRateCardCreateModal={() => this.setState({ rateCardCreateModalOpen: false })}
            rateCardCreateModalOpen={this.state.rateCardCreateModalOpen}
            policies={this.props.policies}
            updatePolicies={this.updatePolicies}
            type={this.props.type}
            canEdit={this.canEdit()}
          />
        </Page>
        <RateCardEditModal
          modalOpen={this.state.modalOpen}
          onClose={() => { this.setState({ modalOpen: false }); }}
          rateCard={this.state.rateCard}
          selectedOrg={this.props.selectedOrg}
        />
      </div>
    );
  }
}

function mapStateToProps({ global, policies, rateCards, user }) {
  return {
    authorisedOrgs: global.employerAuthorisedOrgs,
    selectedOrg: policies.selectedOrg,
    orgRateCards: rateCards.orgRateCards,
    policies: policies.data,
    type: policies.policiesType,
    global,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgSiteRateCards: type => dispatch(rateCardThunks.fetchOrgSiteRateCards(type)),
    stopWatchingOrgSiteRateCards: type => dispatch(rateCardThunks.stopWatchingOrgSiteRateCards(type)),
    setType: policiesType => dispatch(policiesReducer.setType(policiesType)),
    addSelectedOrg: orgKey => dispatch(policiesReducer.addSelectedOrg(orgKey)),
    startWatchingPolicies: () => dispatch(policiesThunks.startWatchingPolicies()),
    stopWatchingPolicies: () => dispatch(policiesThunks.stopWatchingPolicies()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgPoliciesContainer);
