import React, { CSSProperties, useState } from 'react';
import TextAreaAutosize from 'react-autosize-textarea';

import Modal from 'components/Modal';
import Button from 'components/Button';

import colors from 'config/colors';

interface Styles {
  [key: string]: CSSProperties
}

const styles: Styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 12,
    borderTop: '1px solid #EAEAEA',
  },
  text: {
    color: colors.text,
    lineHeight: 1.4,
    paddingTop: 3,
    paddingBottom: 3,
  },
  textArea: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 4,
    resize: 'none',
    overflowX: 'scroll',
    outline: 'none',
    minHeight: 80,
    width: '80%',
    fontSize: '0.9rem',
    color: colors.text,
    padding: 12,
  },
};

interface RejectApplicantProps {
  candidateKey: string,
  publicRejectionReason: string,
  privateRejectionReason: string
}

interface RejectApplicantModalProps {
  onClose: () => void,
  rejectApplicant: (props: RejectApplicantProps) => void,
  applicant: { candidateKey: string },
}

export default function RejectApplicantModal(props: RejectApplicantModalProps): React.ReactElement {

  const { onClose, rejectApplicant, applicant } = props;

  const [publicRejectionReason, setPublicRejectionReason] = useState('');
  const [privateRejectionReason, setPrivateRejectionReason] = useState('');

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      contentLabel="rejectApplicantConfirm"
      header="Reject Applicant"
      contentStyle={{ width: 600 }}
    >
      <div style={{ padding: '12px 18px' }}>
        <p style={{ ...styles.text, marginBottom: 12 }}>Do you wish to reject this application?</p>

        <div style={{ marginBottom: 12 }}>
          <p style={{ ...styles.text, fontWeight: 600, marginBottom: 6 }}>Public reason (this will be displayed to the staff member)</p>
          <TextAreaAutosize
            style={styles.textArea}
            value={publicRejectionReason}
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              setPublicRejectionReason(target.value);
            }}
            placeholder="Public reason (optional)"
          />
        </div>

        <div style={{ marginBottom: 12 }}>
          <p style={{ ...styles.text, fontWeight: 600, marginBottom: 6 }}>Private reason (this will only be displayed to other admins)</p>
          <TextAreaAutosize
            style={styles.textArea}
            value={privateRejectionReason}
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              setPrivateRejectionReason(target.value);
            }}
            placeholder="Private reason (optional)"
          />
        </div>
      </div>

      <div className="space-children-12" style={styles.buttonContainer}>
        <Button white onClick={onClose} outline>Cancel</Button>
        <Button onClick={() => rejectApplicant({ candidateKey: applicant.candidateKey, publicRejectionReason, privateRejectionReason })}>Reject Applicant</Button>
      </div>
    </Modal>
  );
}
