import React from 'react';
import { MdError } from 'react-icons/md';
import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';

const styles = {
  header: {
    color: colors.text,
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
    padding: 12,
    width: '100%',
  },
  symbol: {
    height: spacing.xlarge,
    width: spacing.xlarge,
    borderRadius: spacing.small,
    textAlign: 'center',
    verticalAlign: 'middle',
    marginRight: spacing.base,
  },
  icon: {
    color: colors.white,
    position: 'relative',
    top: -4,
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  warningBox: {
    marginTop: spacing.base,
    borderRadius: 5,
    // padding: spacing.base,
    // color: 'hsla(11, 89%, 69%, 1)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing.base,
  },
  centre: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing.base,
    marginBottom: spacing.base,
  },
  boxStyle: {
    backgroundColor: colors.white,
    color: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginRight: spacing.base,
    userSelect: 'none',
    borderRadius: 4,
  },
  checkStyle: {
    color: colors.green,
    position: 'relative',
  },
  dualButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.cavalry.line}`,
    paddingTop: 12,
  },
  button: {
    width: '100%',
  },
};

export default function CancelBookingModal(props) {

  const {
    showConfirmBookingPopup,
    contentLabel,
    contentStyle,
    closeModal,
    warnings,
    warningsAccepted,
    warningsAcceptanceChange,
    applicant,
  } = props;

  return (
    <Modal
      isOpen={showConfirmBookingPopup}
      onRequestClose={() => closeModal()}
      // padding={0}
      contentStyle={{ width: '40%' }}
      // contentLabel={contentLabel}
      // contentStyle={contentStyle}
      warningsAccepted={warningsAccepted}
    >
      <div>
        <h2 style={styles.header}>Are you happy to book this candidate with these warnings?</h2>
      </div>
      <div style={styles.centre}>
        {warnings.map((warning) => {
          return (
            <div style={styles.warningContainer} key={warning}>
              <div style={{ ...styles.symbol, ...{ backgroundColor: colors.warning } }}>
                <MdError style={styles.icon} />
              </div>
              <p>{warning}</p>
            </div>
          );
        })}
      </div>
      <div style={styles.warningBox}>
        <Checkbox
          checked={warningsAccepted}
          onChange={() => warningsAcceptanceChange()}
          boxStyle={styles.boxStyle}
          checkStyle={styles.checkStyle}
        />
        <p style={{ fontSize: '0.9rem' }}>I confirm that I am aware of the warnings about this Candidate, and that this will be recorded.</p>
      </div>
      <div className="space-children-6" style={styles.dualButtonContainer}>
        <Button
          white
          outline
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            props.bookCandidate(applicant.candidateKey, { ...applicant.profRegStatus, warnings });
          }}
          disabled={!warningsAccepted}
        >
          Confirm Booking
        </Button>

      </div>
    </Modal>
  );

}
