import { fetchAdminsInProgress, fetchAdminsSuccess, fetchAdminsError } from '../reducers/admins';
import * as employerReducer from '../reducers/employer';
import * as adminReducer from '../reducers/admins';
import * as accountsApi from '../lib/api/accounts';

export function fetchAdmins() {
  return async (dispatch) => {
    dispatch(fetchAdminsInProgress());
    try {
      const response = await accountsApi.fetchAdmins();
      dispatch(fetchAdminsSuccess(response.orgAdmins));
    } catch (e) {
      dispatch(fetchAdminsError('Error fetching admins'));
    }
  };
}

export function fetchAdminGeneralPermissions(adminKey) {
  return async (dispatch, getState) => {
    const currentOrgKey = getState().global.currentOrgKey;
    dispatch(employerReducer.fetchPermissions());
    try {
      const response = await accountsApi.fetchAdminGeneralPermissions(adminKey);
      dispatch(employerReducer.fetchPermissionsSuccess(adminKey, currentOrgKey, response.permissions));
    } catch (e) {
      dispatch(employerReducer.fetchPermissionsError(e.message));
    }
  };
}

export function clearAdminGeneralPermissions() {
  return (dispatch, getState) => {
    const { userId } = getState().employer;
    dispatch(employerReducer.clearPermissions(userId));
  };
}

export function fetchAdminProfile(adminKey) {
  return async (dispatch) => {
    dispatch(adminReducer.fetchAdminProfileInProgress());
    try {
      const response = await accountsApi.fetchAdminProfile(adminKey);
      dispatch(adminReducer.fetchAdminProfileSuccess(response.targetAdmin));
    } catch (e) {
      dispatch(adminReducer.fetchAdminProfileError(e.message));
    }
  };
}
