import * as api from '.';

function markNotificationAsRead(id) {
  return api.post('notifications/mark-as-read', { id });
}

function removeNotification(id) {
  return api.post('notifications/remove-notification', { id });
}

function removeAllNotifications() {
  return api.post('notifications/remove-all-notifications');
}

export default {
  markNotificationAsRead,
  removeNotification,
  removeAllNotifications,
};
