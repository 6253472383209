import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { MdSend } from 'react-icons/md';
import { mapValues, pickBy } from 'lodash-es';


import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';
import { isTruthy } from 'lib/helpers-ts';
import useAsync from 'hooks/useAsync';
import { useAppDispatch, useSelector } from 'hooks/redux';
import { useFeatureFlag } from 'hooks/feature';

import { sendUserInvite } from 'lib/userInvite';
import { fetchAdminProfile } from 'thunks/admins';

import Modal from 'components/Modal';
import ErrorScreen from 'components/ErrorScreen';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { TabBar } from 'components/Tabs';
import SwitchList from 'components/SwitchList';

import { useAdminManagementPermissionsList } from './useAdminManagementPermissionsList';

const styles = {
  contentContainer: {
    flex: '1 1 0px',
    overflow: 'auto',
  },
  profilePic: {
    height: 150,
    width: 150,
    borderRadius: '50%',
    position: 'absolute',
    top: -75,
    left: (250 - 75),
  },
  icon: {
    color: colors.positive,
    paddingBottom: 4,
  },
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    marginTop: spacing.base,
    width: '100%',
    textAlign: 'center',
  },
  footer: {
    flex: '0 0 auto',
    padding: 12,
    paddingTop: 12,
    textAlign: 'right' as const,
    borderTop: '1px solid #EAEAEA',
  },
  input: {
    fontSize: '0.9rem',
    color: colors.text,
    borderWidth: 1,
    borderStyle: 'solid' as const,
    borderColor: colors.understated,
    borderRadius: 2,
    padding: 12,
    outline: 'none',
    width: '100%',
    boxSizing: 'border-box' as const,
  },
};

interface AdminInviteModalProps {
  closeModal: () => void;
}

export default function AdminInviteModal(props: AdminInviteModalProps) {

  const dispatch = useAppDispatch();
  const servicesFeatureEnabled = useFeatureFlag('services');
  const currentAdminKey = useSelector(state => state.user.userId);
  const admin = useSelector(state => state.admins.adminProfile);
  const adminProfileError = useSelector(state => state.admins.adminProfileError);
  const isFetchingAdminProfile = useSelector(state => state.admins.isFetchingAdminProfile);

  const tabs = useMemo(() => {
    return [
      { key: 'general', title: 'Permissions' },
      servicesFeatureEnabled && { key: 'service', title: 'Services' },
      { key: 'site', title: 'Sites' },
    ].filter(isTruthy);
  }, [servicesFeatureEnabled]);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');

  // Only allow admin to choose permissions, sites & services that they have enabled themselves
  const enabledAdminLegacyGeneralPermissions = useMemo(() => {
    return admin?.legacyGeneralPermissions?.filter(permission => permission.isEnabled);
  }, [admin?.legacyGeneralPermissions]);
  const enabledAdminSitePermissions = useMemo(() => {
    return admin?.sitePermissions?.filter(site => site.isEnabled);
  }, [admin?.sitePermissions]);
  const enabledAdminServices = useMemo(() => {
    return admin?.servicePermissions?.filter(service => service.isEnabled);
  }, [admin?.servicePermissions]);

  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].key);
  const generalPermissionsHookObject = useAdminManagementPermissionsList(enabledAdminLegacyGeneralPermissions);
  const sitePermissionsHookObject = useAdminManagementPermissionsList(enabledAdminSitePermissions);
  const servicePermissionsHookObject = useAdminManagementPermissionsList(enabledAdminServices);

  const sendInviteManager = useAsync<void>();
  const sendInvite = useCallback(() => {
    const sendInviteInner = async () => {
      await sendUserInvite({
        email,
        firstName,
        surname,
        permissions: generalPermissionsHookObject.state,
        sites: mapValues(pickBy(sitePermissionsHookObject.state, isEnabled => isEnabled), isEnabled => ({ enabled: isEnabled })),
        serviceKeys: servicesFeatureEnabled
          ? Object.entries(servicePermissionsHookObject.state).filter(([key, isEnabled]) => isEnabled).map(([key]) => key)
          : null,
      });

      setSuccessModalIsOpen(true);
      setCurrentTab(tabs[0].key);
      setEmail('');
      setFirstName('');
      setSurname('');
    };
    return sendInviteManager.run(sendInviteInner());
  }, [email, firstName, surname, generalPermissionsHookObject.state, sitePermissionsHookObject.state, servicePermissionsHookObject.state]);

  // Fetch admin
  const loadAdmin = useCallback(() => dispatch(fetchAdminProfile(currentAdminKey)), [currentAdminKey]);
  useEffect(() => { loadAdmin(); }, [loadAdmin]);

  if (successModalIsOpen) {
    return (
      <Modal isOpen onRequestClose={props.closeModal} header="User Invited">
        <p style={{ textAlign: 'center', padding: '24px', minWidth: 400, fontSize: '0.9rem' }}>
          Invitation email sent successfully
        </p>
        <div className="space-children-12" style={styles.footer}>
          <Button white shadow={false} outline onClick={props.closeModal}>Close</Button>
          <Button black shadow={false} onClick={() => setSuccessModalIsOpen(false)}>Invite Another Admin</Button>
        </div>
      </Modal>
    );
  }

  if (adminProfileError) {
    return (
      <Modal
        isOpen
        padding={0}
        header="Invite an Admin"
        onRequestClose={props.closeModal}
        vflex
        contentStyle={{ height: 800, width: 600, color: colors.text }}
      >
        <div style={{ padding: 24 }}>
          <ErrorScreen
            title="Error loading invite modal"
            message={adminProfileError}
            retry={loadAdmin}
            dismiss={props.closeModal}
          />
        </div>
      </Modal>
    );
  }

  if (isFetchingAdminProfile || (admin && sendInviteManager.isLoading)) {

    let message = '';
    if (isFetchingAdminProfile) message = '';
    else if (sendInviteManager.isLoading) message = 'Sending invite...';

    return (
      <Modal
        isOpen
        padding={0}
        header="Invite an Admin"
        onRequestClose={props.closeModal}
        vflex
        contentStyle={{ height: 800, width: 600, color: colors.text }}
      >
        <Loading flex message={message} />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      vflex
      header="Invite an Admin"
      onRequestClose={props.closeModal}
      contentLabel="Invite Admin"
      contentStyle={{ height: 800, width: 600, color: colors.text }}
    >

      {(sendInviteManager.error) && (
        <div style={{ padding: 12, color: colors.white, backgroundColor: colors.red }}>{sendInviteManager.error}</div>
      )}
      <div style={{ flex: '0 0 auto', display: 'flex', padding: 12 }}>
        <div style={{ flex: 1, marginRight: 12 }}>
          <input style={styles.input} value={firstName} placeholder="First Name" onChange={event => setFirstName(event.target.value)} />
        </div>
        <div style={{ flex: 1 }}>
          <input style={styles.input} value={surname} placeholder="Surname" onChange={event => setSurname(event.target.value)} />
        </div>
      </div>
      <div style={{ flex: '0 0 auto', display: 'flex', padding: 12, paddingTop: 0 }}>
        <input style={styles.input} value={email} placeholder="Email Address" onChange={event => setEmail(event.target.value)} />
      </div>
      <TabBar tabs={tabs} selectedTabKey={currentTab} onTabClick={setCurrentTab} />

      <div style={{ flex: '1 1 0px', borderTop: `1px solid ${colors.cavalry.line}` }}>
        {currentTab === 'general' && <SwitchList showSelectAll showResetButton hookObject={generalPermissionsHookObject} />}
        {currentTab === 'service' && <SwitchList showSelectAll showResetButton hookObject={servicePermissionsHookObject} />}
        {currentTab === 'site' && <SwitchList showSelectAll showResetButton hookObject={sitePermissionsHookObject} />}
      </div>

      <div style={styles.footer}>
        <Button black shadow={false} onClick={sendInvite}>Send Invite<MdSend style={{ color: colors.white, marginLeft: 10 }} /></Button>
      </div>
    </Modal>
  );
}
