import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mapValues, pick } from 'lodash-es';
import moment, { Moment } from 'moment-timezone';

import { useAppDispatch, useSelector } from 'hooks/redux';
import { useFeatureFlag } from 'hooks/feature';
import { featureFlags } from 'config/featureFlags';
import { permissions } from 'config/permissions';
import colors from 'config/colors';
import { SHIFT_GROUPING } from 'lib/shift-grouping';

import { unselectSpecifiedShifts } from 'reducers/jobs';
import { removeMultipleDraftShifts, clearRecentlyCreatedShifts, removeDraftShift as removeDraftShiftUnbound, clearCreateShiftsError } from 'reducers/createShifts';
import { createDraftShift, copyAndCreateDraftShift, createShiftsFromDrafts, refreshRecentlyCreatedShifts } from 'thunks/createShifts';
import * as jobsThunk from 'thunks/jobs';

import Feature from 'components/Feature';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ConfirmModal from 'components/ConfirmModal';
import ShiftsListView from '../DayView/ShiftsListView';
import BulkAgencyRelease from '../ShiftsPage/BulkAgencyRelease';
import { mapDraftShapeToShiftShape } from '../shift-hooks';

const styles = {
  errorBanner: {
    position: 'absolute',
    height: 48,
    width: '100%',
    backgroundColor: colors.red,
    bottom: 54,
    left: 0,
    zIndex: 10000,
    boxShadow: '0px 1px 2px 1px rgb(0 0 0 / 40%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const DraftShiftsModal = (props) => {

  const dispatch = useAppDispatch();

  // Redux state
  const draftShifts = useSelector(state => state.createShifts.draftShifts);
  const selectedShiftKeys = useSelector(state => state.jobs.selectedShiftKeys);
  const recentlyCreatedShifts = useSelector(state => state.createShifts.recentlyCreatedShifts);
  const isCreatingShifts = useSelector(state => state.createShifts.isCreatingShifts);
  const createShiftsError = useSelector(state => state.createShifts.createShiftsError);
  const validationError = useSelector(state => state.createShifts.validationError);
  const shiftDetailsIsPinned = useSelector(state => state.userInterface.shiftDetailsIsPinned);
  const adminSites = useSelector(state => state.user.sites);
  const orgTimezone = useSelector(state => state.global.orgConfig?.timezone);
  const adminCanReleaseToAgency = useFeatureFlag('vms', 'vmsCanApproveAgencyRequests');

  // Local state
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [agencyReleaseModalIsOpen, setAgencyReleaseModalIsOpen] = useState(false);

  // Callbacks
  const createNewDraft = useCallback(async () => {
    const draftId = await dispatch(createDraftShift(props.selectedDate.toISOString()));
    props.nav.goToShiftTab(undefined, draftId, true, 'edit');
  }, [props.selectedDate.valueOf()]);

  const deleteDraftShifts = useCallback(() => {
    const draftKeysToRemove = selectedShiftKeys.length ? selectedShiftKeys : Object.keys(draftShifts);
    dispatch(removeMultipleDraftShifts(draftKeysToRemove));
    dispatch(unselectSpecifiedShifts(draftKeysToRemove));
    setConfirmDeleteModalOpen(false);
  }, [selectedShiftKeys, draftShifts]);

  const removeDraftShift = useCallback((draftKey) => dispatch(removeDraftShiftUnbound(draftKey)));
  const copyDraftShift = useCallback(async (copyShiftKey) => {
    const draftKey = await dispatch(copyAndCreateDraftShift(copyShiftKey));
    props.nav.goToShiftTab(undefined, draftKey, true, 'edit');
  });

  const publishShifts = useCallback(async () => {

    // Get draft keys to create
    const selectedDraftKeys = selectedShiftKeys;
    const allDraftKeys = Object.keys(draftShifts);
    const draftKeysToCreate = selectedDraftKeys.length ? selectedDraftKeys : allDraftKeys;

    await dispatch(createShiftsFromDrafts(draftKeysToCreate));

    const { year, month } = props;
    if (year && month) {
      dispatch(jobsThunk.getDaySummaries({
        startDate: moment({ year, month: month - 1 }).startOf('month').format('YYYY-MM-DD'),
        endDate: moment({ year, month: month - 1 }).endOf('month').format('YYYY-MM-DD'),
      }));
    }

  }, [selectedShiftKeys, draftShifts]);

  // Effects
  useEffect(() => {
    return () => {
      if (clearCreateShiftsError) dispatch(clearCreateShiftsError());
      dispatch(clearRecentlyCreatedShifts());
    };
  }, []);


  // Render
  const draftCount = Object.keys(draftShifts).length;
  const noDraftShifts = draftCount === 0;
  const selectedCount = selectedShiftKeys.length;

  const shifts = Object.values({
    ...recentlyCreatedShifts,
    ...mapValues(draftShifts, (draftShift, draftShiftKey) => mapDraftShapeToShiftShape(draftShiftKey, draftShift, adminSites, orgTimezone)),
  });

  // Return true if there are invalid drafts and no shifts selected, or an invalid draft selected
  const invalidDrafts = useMemo(() => {
    const invalidDraftKeys = Object.entries(draftShifts).filter(([, draftShift]) => !draftShift.isValid).map(([draftKey]) => draftKey);
    return !!(invalidDraftKeys.length && (!selectedShiftKeys.length || invalidDraftKeys.some(draftKey => selectedShiftKeys.includes(draftKey))));
  }, [selectedShiftKeys, draftShifts]);

  const publishButtonDisabled = useMemo(() => {
    const noDraftsToPublish = noDraftShifts || (selectedShiftKeys.length && Object.keys(draftShifts).every(key => !selectedShiftKeys.includes(key)));
    return invalidDrafts || noDraftsToPublish || isCreatingShifts;
  }, [selectedShiftKeys, draftShifts, invalidDrafts, isCreatingShifts]);

  const selectedNonDraftShifts = Object.values(pick(recentlyCreatedShifts, selectedShiftKeys));

  const openConfirmDeleteModal = useCallback(() => setConfirmDeleteModalOpen(true));
  const closeConfirmDeleteModal = useCallback(() => setConfirmDeleteModalOpen(false));

  const contentStyles = {
    width: '90vw',
    height: '90vh',
    padding: 0,
  };

  if (shiftDetailsIsPinned) {
    contentStyles.width = 'calc(90vw - 350px)';
    contentStyles.transform = 'translate(calc(-50% - 175px), -50%)';
  }

  const ALLOWED_DATA_TABLE_COLUMNS = ['checkbox', 'collapseExpand', 'service', 'shiftNumber', 'location', 'shiftTime', 'speciality', 'roleAndGrade', 'bookedCandidate', 'status', 'actions'];

  return (
    <>
      <Modal
        isOpen
        contentLabel="Draft Shifts View"
        vflex
        overlayStyle={{ zIndex: 1000 }}
        contentStyle={contentStyles}
        header="Draft Shifts"
        onRequestClose={() => props.closeDraftShiftsView()}
      >
        {(createShiftsError || validationError) && (
          <div style={styles.errorBanner}>
            <p style={{ color: colors.white }}>
              {`${createShiftsError || validationError} `}
              {createShiftsError && <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => publishShifts()}>Retry</span>}
            </p>
          </div>
        )}
        <ShiftsListView
          shiftList={shifts}
          isLoading={isCreatingShifts}
          selectedShift={props.selectedJob}
          goToShiftTab={shiftKey => props.nav.goToShiftTab(undefined, shiftKey, !!shifts.find(shift => shift.key === shiftKey)?.isDraft)}
          groupBy={SHIFT_GROUPING.date}
          removeShift={removeDraftShift}
          copyShift={copyDraftShift}
          selectedShiftKeys={selectedShiftKeys}
          allowedDataTableColumns={ALLOWED_DATA_TABLE_COLUMNS}
        />
        <div
          className="space-children-12"
          style={{ flex: '0 0 auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 12, borderTop: `1px solid ${colors.cavalry.line}` }}
        >
          {!validationError && invalidDrafts && <p style={{ color: colors.cavalry.error, fontWeight: 500, lineHeight: 1.4 }}>You must review and correct any invalid draft shifts before you can publish.</p>}
          {!props.isSupplier &&
            <Feature featureFlag={featureFlags.SHIFTS_CREATION} permissionRequired={permissions.JOBS_CAN_CREATE}>
              <Button
                white
                outline
                shadow={false}
                onClick={() => createNewDraft()}
                disabled={isCreatingShifts}
              >
                + Shift
              </Button>
            </Feature>
          }
          {adminCanReleaseToAgency && (
            <Button
              white
              outline
              shadow={false}
              disabled={isCreatingShifts || !selectedNonDraftShifts.length}
              onClick={() => setAgencyReleaseModalIsOpen(true)}
            >
              Release To Agency
            </Button>
          )}
          <Button
            danger
            disabled={noDraftShifts || isCreatingShifts}
            onClick={openConfirmDeleteModal}
          >
            {selectedCount > 0 ? 'Delete selected' : 'Delete all'}
          </Button>
          <Button
            black
            shadow={false}
            disabled={publishButtonDisabled}
            onClick={publishShifts}
          >
            {(() => {
              if (selectedCount > 0) return 'Publish selected';
              return 'Publish all';
            })()}
          </Button>
        </div>
      </Modal>
      <ConfirmModal
        isOpen={confirmDeleteModalOpen}
        onClose={closeConfirmDeleteModal}
        header={selectedCount > 0 ? 'Delete selected drafts?' : 'Delete all drafts?'}
        textContent={`Are you sure you wish to ${selectedCount > 0 ? 'delete the selected drafts?' : 'delete all drafts?'}`}
        confirmFunction={deleteDraftShifts}
        cancelFunction={closeConfirmDeleteModal}
      />
      {agencyReleaseModalIsOpen && (
        <BulkAgencyRelease
          refreshShiftList={() => dispatch(refreshRecentlyCreatedShifts())}
          unselectSpecifiedShifts={unselectSpecifiedShifts}
          shifts={selectedNonDraftShifts}
          onClose={() => setAgencyReleaseModalIsOpen(false)}
        />
      )}
    </>
  );
};

const { bool, func, string, objectOf, instanceOf } = PropTypes;
DraftShiftsModal.propTypes = {
  selectedDate: instanceOf(Moment).isRequired,
  closeDraftShiftsView: func.isRequired,
  nav: objectOf(func).isRequired,
  isSupplier: bool.isRequired,
  selectedJob: string,
};

DraftShiftsModal.defaultProps = {
  selectedJob: null,
};

export default DraftShiftsModal;
