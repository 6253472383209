
import React from 'react';
import { fieldStyles } from './field-styles';

function NamesForm(props) {
  return (
    <>
      <label htmlFor="email">
        Email
        <input
          type="email"
          disabled
          style={fieldStyles.input}
          value={props.formValues.email}
        />
      </label>
      <label htmlFor="firstName">
        <span>First name</span>
        <input
          type="text"
          autoComplete="given-name"
          style={fieldStyles.input}
          value={props.formValues.firstName}
          onChange={event => props.setFormValue('firstName', event.target.value)}
        />
      </label>
      <label htmlFor="middleNames">
        Middle names
        <input
          type="text"
          autoComplete="additional-name"
          style={fieldStyles.input}
          value={props.formValues.middleNames}
          onChange={event => props.setFormValue('middleNames', event.target.value)}
        />
      </label>
      <label htmlFor="surname">
        Surname
        <input
          type="text"
          autoComplete="family-name"
          style={fieldStyles.input}
          value={props.formValues.surname}
          onChange={event => props.setFormValue('surname', event.target.value)}
        />
      </label>
    </>
  );
}

export default NamesForm;
