import React, { CSSProperties } from 'react';

import Modal from 'components/Modal';
import Button from 'components/Button';
import colors from 'config/colors';


interface Styles {
  [key: string]: CSSProperties
}

const styles: Styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 12,
    borderTop: '1px solid #EAEAEA',
  },
  contractText: {
    color: '#666',
    lineHeight: 1.4,
    paddingTop: 3,
    paddingBottom: 3,
  },
};

interface AccceptanceDocument {
  key: string
  name: string
  version: string
  versionName: string | null
  createdAt: string
  content: string
}

interface AcceptanceDocumentModalProps {
  document: AccceptanceDocument,
  onClose: () => void,
  onAccept: (key: string) => void,
}

export default function AcceptanceDocumentModal(props: AcceptanceDocumentModalProps): React.ReactElement {
  return (
    <Modal
      isOpen
      onRequestClose={props.onClose}
      contentLabel="acceptanceDocument"
      header={props.document.name}
      contentStyle={{ width: 700 }}
      vflex
    >
      <div style={{ padding: 12, overflowY: 'hidden', display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div style={{ fontSize: '0.9rem', padding: 24, color: colors.text, border: `1px solid ${colors.cavalry.line}`, overflowY: 'auto' }}>
          <div dangerouslySetInnerHTML={{ __html: props.document.content }} />
        </div>
      </div>
      <div className="space-children-12" style={styles.buttonContainer}>
        <Button onClick={props.onClose} white outline>Cancel</Button>
        <Button onClick={() => props.onAccept(props.document.key)}>Accept</Button>
      </div>
    </Modal>
  );
}
