export const featureFlags = {
  WORKFORCE_PLANNING: 'workforcePlanning',
  JOBS: 'jobs',
  JOBS_OVERRIDE_RATE_CARD: 'jobsOverrideRateCard',
  VMS: 'vms',
  TIMESHEETS_ADMIN: 'timesheetsAdmin',
  ADMIN_GROUPS: 'adminGroups',
  ADMIN_GROUP_MEMBER_MANAGEMENT: 'adminGroupMemberManagement',
  TIMESHEETS: 'timesheets',
  REPORTING: 'reporting',
  INTEGRATIONS: 'integrations',
  SUPPLIER: 'supplier',
  HELP_CENTRE: 'helpCentre',
  ADD_HOC_JOBS_V2: 'adhocJobsV2',
  SPECIALITIES_BY_GRADE: 'specialitiesByGrade',
  LTJS: 'ltjs',
  DETAILS: 'orgDetails',
  RATE_CARD: 'rateCard',
  PAYMENT_RUNS: 'paymentRuns',

  SELF_BOOKING_PREVENTION: 'selfBookingPrevention',
  SERVICES: 'services',

  RATINGS_SUBMIT_SITE: 'ratingsSubmitSite',
  RATINGS_SUBMIT_CANDIDATE: 'ratingsSubmitCandidate',
  RATINGS_DISPLAY_SITE: 'ratingsDisplaySite',
  RATINGS_DISPLAY_CANDIDATE: 'ratingsDisplayCandidate',
  REPORTING_SPEND: 'reportingSpend',

  REPORTING_ACTIVITY: 'reportingActivity',

  REPORTING_EXPORTS: 'reportingExports',
  REPORTING_EXPORTS_MGMT_SPEND: 'reportingExportsMgmtSpend',

  SHOW_ADHOC_SHIFT_ACTIVITY_DESCRIPTIONS: 'showAdHocShiftActivityDescriptions',
  SHIFTS_CREATION: 'shiftsCreation',
  COST_CENTRES: 'costCentres',
  SITE_AREAS: 'siteAreas',
  TIMESHEET_WITNESS: 'timesheetWitness',
  MESSAGING: 'messaging',
  SHOW_CANDIDATE_LOCATIONS_TO_ADMINS: 'showCandidateLocationsToAdmins',
  CONTRACTED_HOURS: 'contractedHours',
  CUSTOM_HOURLY_RATE: 'customHourlyRate',
};

export const defaultFeatureFlags = {
  workforcePlanning: false,
  jobs: true,
  services: false,
  adhocJobsV2: false,
  jobsOverrideRateCard: false,
  vms: false,
  timesheets: true,
  paymentRuns: false,
  reporting: true,
  integrations: false,
  supplier: false,
  helpCentre: false,
  ltjs: false,
  orgDetails: false,
  rateCard: true,
  reportingSpend: true,
  adminGroups: false,
  adminGroupMemberManagement: false,

  reportingActivity: false,

  reportingExports: false,
  reportingExportsMgmtSpend: true,

  showAdHocShiftActivityDescriptions: true,
  specialitiesByGrade: false,
  shiftsCreation: false,
  costCentres: false,
  siteAreas: false,
  timesheetWitness: false,
  messaging: true,
  showCandidateLocationsToAdmins: false,
  contractedHours: false,
  customHourlyRate: false,
};

export type FeatureFlag = keyof typeof defaultFeatureFlags;

export default {
  featureFlags,
  defaultFeatureFlags,
};
