import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { capitalize } from 'lib/helpers';

import colors from 'config/colors';
import Button from 'components/Button';
import Popover from 'components/Popover';
import MessageButton from 'components/MessageButton';
import ApplicantsStatusPill from 'components/ApplicantsStatusPill';
import ComplianceStatus from 'components/ComplianceStatus';

import { CandidateRatingStar } from '../../CandidateRatingStar';
import ComplianceItemStatus from './ComplianceItemStatus';
import '../styles.scss';


export default function BookingCard(props) {

  const [complianceItemStatusPopoverIsOpen, setComplianceItemStatusPopoverIsOpen] = useState(false);

  const { shiftKey, booking, hideButtonFunctions, orgKey } = props;
  const bankKeys = Object.keys(booking?.candidateBanks || {});

  let bookingDescription = booking.roleName;
  if (booking.profRegNumber) bookingDescription += `\n(reg: ${booking.profRegNumber})`;
  if (booking.gradeName && booking.gradeName !== booking.roleName && booking.gradeName !== 'All') bookingDescription += ` | ${booking.gradeName}`;

  const candidateBankKeys = Object.keys(booking?.candidateBanks ?? {});
  const bankOrSupplierName = booking.supplierName ?? (candidateBankKeys[0] && (candidateBankKeys.length > 1 ? `${candidateBankKeys.length} banks` : booking?.candidateBanks[candidateBankKeys[0]]));
  const isSupplierCandidate = !!booking.supplierKey;
  const isExternalCandidate = orgKey !== booking.mainOrgKey;

  return (
    <div key={shiftKey} className="bookingsCard">
      {booking.candidateName || booking.candidateRef ?
        <div
          onClick={() => {
            const candidateIsPartOfCollaborativeBank = bankKeys?.some(bankKey => props.adminBanks.includes(bankKey)) ?? false;
            if (candidateIsPartOfCollaborativeBank) props.goToCandidateProfile(booking.candidateKey);
          }}
          className="shift-booking-content-container space-children-6--bottom"
        >
          {/* Name, rating star and bank pill */}
          <div className="name-flex-container">
            <div className="applicationsSubHeading">
              {booking.candidateRef ? booking.candidateRef : <p>{capitalize(booking.candidateName)}</p>}
            </div>

            <div className="space-children-12" style={{ display: 'flex', alignItems: 'center' }}>
              {booking.candidateRating && <CandidateRatingStar size={20} rating={booking.candidateRating} />}
              {isSupplierCandidate ?
                <ApplicantsStatusPill agency={isSupplierCandidate} external={isExternalCandidate} bank={bankOrSupplierName} />
                :
                <Popover
                  popoverMaxWidth="180px"
                  popoverContent={
                    <div>
                      <p style={{ paddingRight: 48, whiteSpace: 'nowrap', fontWeight: 500 }}>All banks</p>
                      {props.job?.banks && candidateBankKeys?.length && (
                        <div>{candidateBankKeys?.map(bankKey => <p key={bankKey} style={{ margin: 0, fontSize: '0.9rem' }}>{props.job.banks[bankKey]}</p>)}</div>
                      )}
                    </div>
                  }
                  position="top"
                >
                  <ApplicantsStatusPill agency={isSupplierCandidate} external={isExternalCandidate} bank={bankOrSupplierName} />
                </Popover>
              }
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Candidate Role, grade, and banks */}
            {!!booking.roleName && <div className="bookingInfoText" style={{ lineHeight: 1.2 }}>{bookingDescription}</div>}
            <Popover
              isOpen={complianceItemStatusPopoverIsOpen}
              setIsOpen={setComplianceItemStatusPopoverIsOpen}
              positions={['top', 'bottom', 'left']}
              popoverContent={
                <div>
                  <p style={{ paddingRight: 48, whiteSpace: 'nowrap', fontWeight: 500 }}>Individual Item Statuses</p>
                  <ComplianceItemStatus items={booking.complianceItems} labelStyle={{ color: 'white' }} />
                </div>
              }
              position="top"
            >
              <ComplianceStatus statusColor={booking.adminComplianceStatusColor} statusLabel={booking.adminComplianceStatusLabel} marginLeft={6} fontSize="0.9rem" />
            </Popover>
          </div>

          <div className="space-children-6" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className="candidateInfoText" style={{ flex: 1, fontSize: '0.8rem' }}>
              Booked:{` ${moment(booking.bookedAt).format('Do MMM HH:mm')}`}
            </p>

            {!hideButtonFunctions && !isSupplierCandidate && (
              <MessageButton candidateKey={booking.candidateKey} />
            )}

            {hideButtonFunctions ?
              null :
              <Button
                danger
                disabled={props.cancellingBooking}
                onClick={(event) => { event.preventDefault(); event.stopPropagation(); props.cancel(booking.bookingKey); }}
                // style={{ fontSize: '0.8rem' }}
                shadow={false}
              >
                Unbook
              </Button>
            }
          </div>
          {/* {!props.isLastItem && (
            <div style={{ width: '100%', height: 1, backgroundColor: colors.cavalry.line }} />
          )} */}
        </div>
        :
        <div className="shift-booking-content-container">
          <p>This shift has been booked to a candidate from another organisation</p>
        </div>
      }
    </div>
  );
}

const { string, object, func, bool } = PropTypes;
BookingCard.propTypes = {
  shiftKey: string.isRequired,
  booking: object.isRequired,
  job: object.isRequired,
  grade: string.isRequired,
  hideButtonFunctions: bool.isRequired,
  cancel: func.isRequired,
  cancellingBooking: bool.isRequired,
  goToCandidateProfile: func,
  canDisplayCandidateRating: bool.isRequired,
  orgKey: string.isRequired,
};

BookingCard.defaultProps = {
  goToCandidateProfile: () => {},
};
