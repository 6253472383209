import React, { Fragment, useMemo, useCallback, useState, useRef, useLayoutEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { firstBy } from 'thenby';
import { uniq, groupBy, mapValues } from 'lodash-es';
import { usePopover, PopoverState } from 'react-tiny-popover';
import './CWSelect.scss';

interface CWSelectItem {
  value: string;
  label: string;
  isSelected?: boolean;
  subLabel?: string;
}

interface ButtonProps {
  title: string;
  onClick: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  inputValue: string;
  setInputValue: (text: string) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  fullSelectedItems: CWSelectItem[];
  deselectItem: (item: CWSelectItem) => void;
  isOpen: boolean,
}

interface MenuItemProps {
  key: string;
  item: CWSelectItem;
  closeMenu: () => void;
  selectSingleItem: (item: CWSelectItem) => void;
  selectItem: (item: CWSelectItem) => void;
  deselectItem: (item: CWSelectItem) => void;
  isFocussed: boolean;
  isMultiSelecting: boolean;
}

interface CWSelectProps {
  items: Array<CWSelectItem>;
  selectedItems: Array<string>;
  setSelectedItems: (items: Array<string>) => unknown
  noneSelectedLabel: string;
  truncateButtonLabel?: boolean;
  button: (props: ButtonProps) => ReactNode;
  menuItem: (props: MenuItemProps) => ReactNode;
  buttonRef: React.MutableRefObject<HTMLElement | undefined>;
  showFilter?: boolean;
  showClear?: boolean;
  backspaceRemovesLastElement?: boolean;
  minWidth?: string | number,
  maxWidth?: string | number,
  disabled?: boolean
}

function computeTitle(selectedItems: Array<CWSelectItem>, noneSelectedLabel: string, truncate: boolean): string {
  const items = uniq(selectedItems.map(item => item.label));
  if (!items?.length) return noneSelectedLabel;
  if (!truncate) return items.join(', ');
  if (items.length === 1) return items[0].length > 30 ? `${items[0].slice(0, 27)}...` : items[0];
  return `${(items[0].length > 17 ? `${items[0].slice(0, 14)}...` : items[0])} + ${items.length - 1} more`;
  // if (selectedItems.length <= 2) return selectedItems.map(item => item.label).join(', ');
  // return `${selectedItems.length} selected`;
}

function ensureElementIsInView(container: Element, element: HTMLElement) : void {
  // Determine container top and bottom
  const cTop = container.scrollTop;
  const cBottom = cTop + container.clientHeight;

  // Determine element top and bottom
  const eTop = element.offsetTop;
  const eBottom = eTop + element.clientHeight;

  // Check if out of view
  if (eTop < cTop) container.scrollTop -= (cTop - eTop); // eslint-disable-line no-param-reassign
  else if (eBottom > cBottom) container.scrollTop += (eBottom - cBottom); // eslint-disable-line no-param-reassign
}

function ensureMenuItemIsInView(container: Element | null, itemKey: string) {
  const menuItem = container?.querySelector(`[data-select-menu-item="${itemKey}"]`);
  if (container && menuItem) ensureElementIsInView(container, menuItem as HTMLElement);
}


const CWSelect : React.FC<CWSelectProps> = (props: CWSelectProps) => {

  // Focussed item state (for keyboard selection)
  const [focussedItemKey, setFocussedItemKey] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Selected items state
  const selectedItems = props.selectedItems ?? [];
  const setSelectedItems = props.setSelectedItems;
  const clearSelectedItems = () => setSelectedItems([]);
  const selectSingleItem = (item: CWSelectItem) => setSelectedItems([item.value]);
  const selectItem = (item: CWSelectItem) => setSelectedItems(uniq([...selectedItems, item.value]));
  const deselectItem = (item: CWSelectItem) => setSelectedItems(selectedItems.filter(i => i !== item.value));

  const focusButton = useCallback(() => props.buttonRef?.current?.focus(), [props.buttonRef?.current]);

  // Text input state
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => { if (isOpen) { inputRef.current?.focus(); } }, [isOpen]);

  // Group, sort and filter
  const fullSelectedItems = useMemo(() => props.items.filter(item => selectedItems.includes(item.value)), [props.items, selectedItems]);
  const filteredItems = useMemo(() => props.items.filter(item => item.label.toLowerCase().includes(inputValue.trim().toLowerCase())), [props.items, inputValue]);
  const filteredItemsWithSelection = useMemo(() => filteredItems.map(item => ({ ...item, isSelected: selectedItems.includes(item.value) })), [filteredItems, selectedItems]);
  const groupedItems = useMemo(() => groupBy(filteredItemsWithSelection, 'group'), [filteredItemsWithSelection]);
  const sortedGroupedItems = useMemo(() => mapValues(groupedItems, group => group.sort(firstBy('order').thenBy('label'))), [groupedItems]);
  const flattenedSortedItemKeys = useMemo(() => {
    return [
      sortedGroupedItems.undefined,
      ...Object.entries(sortedGroupedItems).filter(([group]) => group !== 'undefined').map(([group, items]) => items),
    ].flat().filter(Boolean).map(item => item.value);
  }, [sortedGroupedItems]);

  // Menu open/close actions
  const onPositionPopover = useCallback(
    (popoverState: PopoverState) => { /* do nothing */ },
    [],
  );
  const [positionPopover, popoverRef] = usePopover({
    childRef: props.buttonRef,
    positions: ['bottom', 'top'],
    align: 'start',
    padding: 12,
    reposition: true,
    boundaryInset: 12,
    containerParent: document.body,
    onPositionPopover,
  });

  useLayoutEffect(() => {
    if (isOpen) positionPopover();
  }, [isOpen, selectedItems]);

  const openMenu = () => {
    setIsOpen(true);
    if (flattenedSortedItemKeys.length) setFocussedItemKey(flattenedSortedItemKeys[0]);
  };
  const closeMenu = (shouldFocusButton = true) => {
    setIsOpen(false);
    setInputValue('');
    if (shouldFocusButton) focusButton();
  };

  // Global click handler (close menu if you click outside of it)
  const containerEl = useRef<HTMLDivElement>(null);
  const handleGlobalClick = useCallback((event) => {
    // Ignore clicks on this component
    let node = event.target;
    while (node) {
      if (node === containerEl.current) return;
      node = node.parentNode;
    }
    closeMenu(false);
  }, [containerEl.current]);
  useLayoutEffect(() => {
    document.addEventListener('click', handleGlobalClick, false);
    return () => document.removeEventListener('click', handleGlobalClick);
  });

  // If no focussed item, and the list is not empty, then default to first in the list
  if (flattenedSortedItemKeys.length && (!focussedItemKey || !flattenedSortedItemKeys.includes(focussedItemKey))) {
    setFocussedItemKey(flattenedSortedItemKeys[0]);
  }

  const menuItemsEl = useRef<HTMLDivElement>(null);
  const focussedItemIndex = focussedItemKey ? flattenedSortedItemKeys.indexOf(focussedItemKey) : -1;
  const selectNextFocussedItem = () => {
    if (focussedItemIndex > -1 && focussedItemIndex < flattenedSortedItemKeys.length - 1) {
      const newFocussedItemKey = flattenedSortedItemKeys[focussedItemIndex + 1];
      setFocussedItemKey(newFocussedItemKey);
      ensureMenuItemIsInView(menuItemsEl.current, newFocussedItemKey);
    }
  };
  const selectPreviousFocussedItem = () => {
    if (focussedItemIndex > 0) {
      const newFocussedItemKey = flattenedSortedItemKeys[focussedItemIndex - 1];
      if (newFocussedItemKey) {
        setFocussedItemKey(newFocussedItemKey);
        ensureMenuItemIsInView(menuItemsEl.current, newFocussedItemKey);
      }
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCode = event.which || event.keyCode;

    switch (keyCode) {
      case 13: { // Enter
        const item = filteredItemsWithSelection.find(i => i.value === focussedItemKey);
        if (item) {
          selectItem(item);
        }
        setInputValue('');
        closeMenu();
        break;
      }
      case 32: { // Space
        const item = filteredItemsWithSelection.find(i => i.value === focussedItemKey);
        if (inputValue.trim().length === 0 && item) {
          event.preventDefault();
          event.stopPropagation();
          if (item.isSelected) {
            deselectItem(item);
          } else {
            selectItem(item);
          }
        }
        break;
      }
      default:
        break;
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCode = event.which || event.keyCode;

    switch (keyCode) {
      case 27: // Enter
        event.preventDefault();
        closeMenu();
        break;
      case 40: // Down arrow
        event.preventDefault();
        if (isOpen) selectNextFocussedItem();
        else openMenu();
        break;
      case 38: // Up arrow
        event.preventDefault();
        if (isOpen) selectPreviousFocussedItem();
        break;
      case 8: { // Backspace
        if (props.backspaceRemovesLastElement && inputValue.length < 1 && selectedItems.length >= 1) {
          setSelectedItems(selectedItems.slice(0, selectedItems.length - 1));
        }
        break;
      }
      default:
        if (!isOpen) openMenu();
        break;
    }
  };

  return (
    <div className="cw-select__container" ref={containerEl}>
      {props.button({
        title: computeTitle(fullSelectedItems, props.noneSelectedLabel || 'No items selected', props.truncateButtonLabel ?? false),
        onClick: props.disabled ? () => {} : () => (isOpen ? closeMenu() : openMenu()),
        onKeyDown,
        inputValue,
        setInputValue,
        onKeyPress,
        fullSelectedItems,
        deselectItem,
        isOpen,
      })}
      {isOpen ?
        <CWSelectMenuPortal>
          <div className="cw-select__menu" ref={popoverRef}>
            {props.showFilter && (<input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              placeholder="Filter items..."
              className="cw-select__input"
            />)}
            {props.showClear && (
              <button type="button" className="cw-select__item" onClick={() => { clearSelectedItems(); closeMenu(); }}>
                Clear Selection
              </button>
            )}
            <div className="cw-select__menu-items" ref={menuItemsEl} style={{ maxHeight: 400, minWidth: props.minWidth ?? 250, maxWidth: props.maxWidth ?? 300, width: '100%' }}>
              {sortedGroupedItems.undefined ? sortedGroupedItems.undefined.map((item) => (props.menuItem({ key: item.value, item, closeMenu, selectSingleItem, selectItem, deselectItem, isFocussed: item.value === focussedItemKey, isMultiSelecting: selectedItems.length <= 1 }))) : null}
              {
                    Object.entries(sortedGroupedItems)
                      .filter(([group]) => group !== 'undefined')
                      .map(([group, items]) => {
                        return (
                          <Fragment key={group}>
                            <div
                              key="header"
                              className="cw-select__group-header"
                            >
                              {group}
                            </div>
                            {items.map((item) => props.menuItem({
                              key: item.value,
                              item,
                              closeMenu,
                              selectSingleItem,
                              selectItem,
                              deselectItem,
                              isFocussed: item.value === focussedItemKey,
                              isMultiSelecting: selectedItems.length <= 1,
                            }),
                            )}
                          </Fragment>
                        );
                      })
                  }
            </div>
          </div>
        </CWSelectMenuPortal>
        :
        null
      }
    </div>
  );
};

CWSelect.defaultProps = {
  truncateButtonLabel: false,
  showFilter: true,
  showClear: true,
  backspaceRemovesLastElement: false,
  disabled: false,
};

interface CWSelectMenuPortalProps {
  children: JSX.Element,
}

const selectMenuDomNode = document.getElementById('cw-select-menus') as HTMLElement; // This will never be null as it's defined in index.html

function CWSelectMenuPortal(props: CWSelectMenuPortalProps) {
  return createPortal(props.children, selectMenuDomNode);
}

export default CWSelect;
