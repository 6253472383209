import * as rgsReducer from '../reducers/rgs';
import { convertToSelectArray } from '../lib/helpers';

export function fetchRGSMetadata() {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(rgsReducer.fetchRGSMetadata());
      const { metadata } = state.global;
      const { roles } = metadata;
      const enabledRoles = {};

      Object.keys(roles).forEach((id) => {
        const role = roles[id];

        if (role.enabled) {
          const grades = {};
          const specialities = {};

          if (role.linkedGrades) {
            Object.keys(role.linkedGrades).forEach((gradeId) => {
              const grade = role.linkedGrades[gradeId];
              if (grade.enabled && metadata.grades[gradeId]) {
                grades[gradeId] = {
                  ...grade,
                  key: gradeId,
                  name: metadata.grades[gradeId].name,
                  rank: metadata.grades[gradeId].rank,
                };
              }
            });
          }

          if (role.linkedSpecialities) {
            Object.keys(role.linkedSpecialities).forEach((specialityId) => {
              const speciality = role.linkedSpecialities[specialityId];
              if (speciality.enabled && metadata.specialities[specialityId]) {
                specialities[specialityId] = {
                  ...speciality,
                  key: specialityId,
                  name: metadata.specialities[specialityId].name,
                  order: metadata.specialities[specialityId].order,
                };
              }
            });
          }

          enabledRoles[id] = {
            grades,
            specialities,
            name: metadata.roles[id].name,
          };
        }
      });

      dispatch(rgsReducer.fetchRGSMetadataSuccess(enabledRoles));
      const convertedRoles = convertToSelectArray(enabledRoles, 'name');
      dispatch(rgsReducer.storeRoles(convertedRoles));
    } catch (error) {
      console.log(error);
      dispatch(rgsReducer.fetchRGSMetadataError(error));
    }
  };
}
