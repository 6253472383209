import React from 'react';

import { TemplatePeriod } from 'types/ShiftTypes';
import colors from 'config/colors';
import FieldLabel from './FieldLabel';

interface TemplateFieldProps {
  templatePeriod: TemplatePeriod | null,
}

const templatePeriodMappings = {
  day: 'Daily Template',
  week: 'Weekly Template',
  month: 'Monthly Template',
};

function TemplatePeriodField(props: TemplateFieldProps) {
  return (
    <FieldLabel label="Period">
      {props.templatePeriod && <p style={{ color: colors.text, fontSize: '0.9rem', fontWeight: 500, lineHeight: 1.4, marginTop: 4, marginBottom: 4 }}>{templatePeriodMappings[props.templatePeriod]}</p>}
    </FieldLabel>
  );
}

export default React.memo(TemplatePeriodField);
