import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';


export default function CancelBookingModal(props) {
  const {
    showCancelModal,
    contentLabel,
    closeCancel,
    cancelBooking,

  } = props;

  return (
    <Modal
      isOpen={showCancelModal}
      contentLabel={contentLabel}
      onRequestClose={closeCancel}
    >
      <div style={{ maxWidth: '500px', textAlign: 'center', padding: 12 }} className="space-children-24--bottom">
        <h1 style={{
          color: colors.text,
          fontSize: '1.3rem',
          fontWeight: 600,
        }}
        >
      Do you wish to cancel this booking?
        </h1>
        <p style={{ fontSize: '0.9rem' }}>The candidate will be notified, but they&apos;ll remain as an applicant (unless they withdraw their application for the shift at a later date).</p>
        <div>
          <Button
            white
            shadow={false}
            outline
            onClick={closeCancel}
            style={{ marginRight: spacing.base }}
          >
            Don&apos;t Cancel
          </Button>
          <Button
            black
            shadow={false}
            onClick={cancelBooking}
          >
            Cancel the booking
          </Button>
        </div>
      </div>
    </Modal>
  );
}
