import React, { useRef } from 'react';
import spacing from 'config/spacing';
import CWSelect from './CWSelect';
import FilterItem from '../FilterItem';
import './CWSelect.scss';

interface CWSelectItem {
  value: string;
  label: string;
  isSelected?: boolean;
  subLabel?: string;
}

interface CWTagSelectProps {
  items: Array<CWSelectItem>;
  selectedItems: Array<string>;
  setSelectedItems: (items: Array<string>) => unknown
  placeholder: string,
}

const CWTagSelect: React.FC<CWTagSelectProps> = (props: CWTagSelectProps) => {
  const buttonRef = useRef<HTMLInputElement | undefined>();

  return (
    <CWSelect
      items={props.items}
      selectedItems={props.selectedItems}
      setSelectedItems={props.setSelectedItems}
      noneSelectedLabel="Select recipients"
      showFilter={false}
      showClear={false}
      backspaceRemovesLastElement
      buttonRef={buttonRef}
      button={({ inputValue, setInputValue, onKeyDown, onKeyPress, fullSelectedItems, deselectItem, onClick }) => (
        <div className="tagSelectButtonContainer" onClick={onClick}>
          {fullSelectedItems && fullSelectedItems.length > 0 ? fullSelectedItems.map(item => (
            <div style={{ marginRight: spacing.small }}>
              <FilterItem
                title={item.label}
                showDelete
                deleteItem={() => deselectItem(item)}
              />
            </div>
          )) : null}
          <input
            ref={buttonRef as any}
            className="tagSelectInput"
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            placeholder={props.placeholder ?? ''}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          />
        </div>
      )}
      menuItem={({ item, isFocussed, selectItem, closeMenu }) => {
        if (props.selectedItems.includes(item.value)) return null;

        return (
          <div
            key={item.value}
            data-select-menu-item={item.value}
            className={`tagSelectMenuItemContainer ${isFocussed ? 'tagSelectMenuItemContainerFocused' : ''}`}
            onClick={() => {
              selectItem(item);
              closeMenu();
            }}
          >
            <p style={{ fontSize: '0.9rem', fontWeight: 500, lineHeight: 1.4, marginBottom: 3 }}>{item.label}</p>
            {item.subLabel && <p style={{ fontSize: '0.8rem', color: '#999', fontWeight: 400, lineHeight: 1.4 }}>{item.subLabel}</p>}
          </div>
        );
      }}
    />
  );
};

export default CWTagSelect;
