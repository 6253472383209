import React from 'react';
import PropTypes from 'prop-types';
import ErrorModal from 'components/ErrorModal';
import NoReportData from 'components/NoReportData';
import ByBarChart from './ByBarChart';
import { capitalize } from '../../../lib/helpers';
import { reports, headerFormatter } from './index'; // eslint-disable-line import/no-cycle

export default function BySpecialityReport(props) {

  // Find metadata and methods from report config
  const { labels, specialitiesBreakdown, dataFormatter } = reports.find(report => report.name === props.reportName);

  // Create formatted payload for chart
  const reportPayloadForChart = specialitiesBreakdown.generatePayloadForChart(props.report, labels);

  return (
    <>
      {/* Render error modal if network error occurs when loading byRoleReport */}
      {props.reportError && (
        <ErrorModal header={`${capitalize(props.reportName)} By Speciality Report Error`} errorMessage={props.reportError} onCancel={() => props.clearReportingError(props.bySpecialityReportName)} />
      )}

      {!props.isloadingReport && reportPayloadForChart ?
        <div>
          <ByBarChart
            containerHeight={400}
            xAxisHeight={80}
            trendPositive={specialitiesBreakdown.trendPositive}
            report={reportPayloadForChart}
            tooltipFormatter={specialitiesBreakdown.tooltipFormatter}
            totalFormatter={specialitiesBreakdown.totalFormatter}
            yAxisFormatter={specialitiesBreakdown.yAxisFormatter}
            title={props.selectedRoleName ? specialitiesBreakdown.title.replace('__ROLE__', props.selectedRoleName) : ''}
            by="categoryName"
            groupBy="speciality"
            showTotal={specialitiesBreakdown.showTotal}
            dataMax={specialitiesBreakdown.dataMax}
            labels={labels}
          />

          {props.drillDownComponent && props.drillDownComponent({
            labels,
            report: reportPayloadForChart,
            numberFormatter: dataFormatter,
            headerFormatter,
            groupBy: 'categoryName',
          })}
        </div>
        : <NoReportData title={props.header} />
      }
    </>
  );
}

const { object, array, shape, bool, oneOfType, node, element, func, string } = PropTypes;

BySpecialityReport.propTypes = {
  report: shape({
    result: array.isRequired,
    siteFilter: string,
    roleFilter: string,
    groupBy: string,
  }),
  isloadingReport: bool.isRequired,
  reportError: string,
  drillDownComponent: oneOfType([node, element, object, func]),
  clearReportingError: func.isRequired,
  reportName: string.isRequired,
  bySpecialityReportName: string.isRequired,
  selectedRoleName: string,
  header: string.isRequired,
};

BySpecialityReport.defaultProps = {
  report: null,
  drillDownComponent: null,
  reportError: '',
  selectedRoleName: '',
};
