import moment, { Moment } from 'moment-timezone';

type DateString = string;
const TIME_FORMATS = {
  time24: 'HH:mm',
  abbreviatedDateWithTime: 'DD/MM/YYYY @ HH:mm',
  fullDateWithShortDay: 'ddd Do MMMM YYYY',
  fullDateWithLongDay: 'dddd Do MMMM YYYY',
};

//----------------------------------------------------------------
// EXPORTS
//----------------------------------------------------------------

function getTime24Hour(isoString: DateString): string {
  return moment(isoString).format(TIME_FORMATS.time24);
}

function getAbbreviatedDateWithTime(isoString: DateString): string {
  return moment(isoString).format(TIME_FORMATS.abbreviatedDateWithTime);
}

function getFullDateShortDay(isoString: DateString): string {
  return moment(isoString).format(TIME_FORMATS.fullDateWithShortDay);
}

function getFullDateLongDay(isoString: DateString): string {
  return moment(isoString).format(TIME_FORMATS.fullDateWithLongDay);
}

function minsToHours(minutes: number): string {
  const mins = minutes % 60;
  const hours = Math.floor(minutes / 60);
  if (hours && !mins) return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  if (mins && !hours) return `${mins} ${mins === 1 ? 'minute' : 'minutes'}`;
  return `${hours}h ${mins}m`;
}

function convertFromUtcToTimezone(isoString: DateString, timezone: string): Moment {
  if (!isoString) throw new Error('No date string provided');
  if (!timezone) throw new Error('No timezone provided');

  return moment.utc(isoString).tz(timezone);
}

function getHumanConversationAge(isoString: DateString, timezone: string): string {

  const now = moment().utc();
  const yesterday = now.clone().subtract(1, 'day');
  const momentDate = moment.utc(isoString);

  const timezoneDate = convertFromUtcToTimezone(momentDate.toISOString(), timezone);

  if (now.isSame(momentDate, 'day')) return timezoneDate.format('HH:mm');
  if (yesterday.isSame(momentDate, 'day')) return `Yesterday ${timezoneDate.format('HH:mm')}`;
  return timezoneDate.format('DD MMM HH:mm');
}

export {
  getTime24Hour,
  getAbbreviatedDateWithTime,
  getFullDateShortDay,
  getFullDateLongDay,
  minsToHours,
  convertFromUtcToTimezone,
  getHumanConversationAge,
};
