const trimString = (str: string) : string => str.replace(/^\s+|\s+$/g, ''); // Remove whitespace
const defaultStringify = (options: { label: string }) => `${options?.label}`; // Stringify input

interface CustomFilterConfig<Options> {
  ignoreCase?: boolean;
  stringify?: (options: Options) => string;
  trim?: boolean;
  matchFrom?: 'start' | 'any';
}

export const customFilter = <T extends { label: string }>(config: CustomFilterConfig<T>) => (options: T, rawInput: string) => {
  const { ignoreCase, stringify, trim, matchFrom } : CustomFilterConfig<T> = {
    ignoreCase: true,
    stringify: defaultStringify,
    trim: true,
    matchFrom: 'any',
    ...config,
  };
  let input = trim ? trimString(rawInput) : rawInput; // If trim is true, remove whitespace
  let candidate = trim ? trimString(stringify(options)) : stringify(options); // If trim is true, stringify and remove whitespace
  if (ignoreCase) { // If ignore case is true, convert input & candidate to lowercase
    input = input.toLowerCase();
    candidate = candidate.toLowerCase();
  }

  // If matchFrom is equal to 'start', candidate must equal input exactly
  return matchFrom === 'start'
    ? candidate.substr(0, input.length) === input
    : candidate.indexOf(input) > -1;
};

export const customStyles = {
  valueContainer: <T>(base: T) : T & { padding : number} => ({ ...base, padding: 10 }),
};

// const foo : string = 21;

// console.log(foo);
