import { useLayoutEffect, useCallback, useRef } from 'react';

export default function useSafeDispatch<Data, Args, Ret>(dispatch: (args: Args) => Ret) : (args: Args) => Ret | undefined {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(
    (args: Args) => {
      return (mounted.current ? dispatch(args) : undefined);
    },
    [dispatch, mounted.current],
  );
}
