import React from 'react';
import classNames from 'classnames';
import { MdWarning, MdErrorOutline, MdInfo } from 'react-icons/md';
import './InfoBanner.scss';

interface StyleProps {
  warning?: boolean;
  danger?: boolean;
  positive?: boolean;
}

interface InfoBannerProps {
  message: string;
  warning?: boolean;
  danger?: boolean;
  positive?: boolean;
}

const computeIcon = (props: StyleProps) : JSX.Element => {
  if (props.danger) return <MdErrorOutline />;
  if (props.warning) return <MdWarning />;
  return <MdInfo />;
};

const computeColor = (props: StyleProps) : string => {
  if (props.danger) return 'danger';
  if (props.warning) return 'warning';
  return 'positive';
};


export default function InfoBanner(props: InfoBannerProps) : JSX.Element {

  // Fix unused props lint
  const styleProps = { warning: props.warning, danger: props.danger, postive: props.positive };

  const color = computeColor(styleProps);
  const icon = computeIcon(styleProps);

  return (
    <div className={classNames(['info-banner', `info-banner--${color}`])}>
      {icon}
      <p>{props.message}</p>
    </div>
  );
}

InfoBanner.defaultProps = {
  warning: false,
  danger: false,
  positive: true,
};
