import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { convertToSelectArray } from 'lib/helpers';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface GradeFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  grade: { id: string, name: string } | null,
  role: { id: string, name: string } | null,
  mandatoryFields: Array<string>,
  mandatory?: boolean,
}

function GradeField(props: GradeFieldProps) {

  const grades = useSelector(({ createDraftShifts }) => createDraftShifts.grades);

  const gradesList = useMemo(() => {
    const convertedGrades = convertToSelectArray(grades, 'name');
    if (!props.mandatoryFields.includes('grade')) convertedGrades.unshift({ value: null, label: 'Any' });
    return convertedGrades;
  }, [grades]);


  return (
    <FieldLabel label="Grade">
      <JobsSelectBox
        name="grades"
        value={props.grade ?? { id: null, name: 'Any' }}
        placeholder="Grade *"
        data={gradesList}
        onChange={props.onChange}
        mandatory={props.mandatory}
        isDisabled={!props.role}
      />
    </FieldLabel>
  );
}

GradeField.defaultProps = { mandatory: false };

export default React.memo(GradeField);
