import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxState } from '../reducers';
import { fetchSitesInProgress, fetchSitesSuccess, fetchSitesError, fetchAdminGroupList as fetchAdminGroups, fetchAdminGroupListSuccess, fetchAdminGroupListError } from '../reducers/settings';
import * as accountsApi from '../lib/api/accounts';
import * as adminGroupsApi from '../lib/api/admin-groups';

export function fetchAllOrgSites() : ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(fetchSitesInProgress());

    try {
      const currentUserKey = getState().user.userId;
      const response = await accountsApi.fetchSites({ mine: false, adminKey: currentUserKey });
      dispatch(fetchSitesSuccess(response.orgSites));
    } catch (e) {
      dispatch(fetchSitesError('Error fetching admins'));
    }
  };
}

export function fetchAdminGroupList() : ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(fetchAdminGroups());

    try {
      const adminGroups = await adminGroupsApi.fetchAdminGroupList();
      dispatch(fetchAdminGroupListSuccess(adminGroups));
    } catch (e) {
      dispatch(fetchAdminGroupListError(e.message));
    }
  };
}
