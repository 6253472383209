import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import colors from '../../config/colors';
import './style.css';

const { string, func } = PropTypes;
// Note: written as a class and not statless function due to FlipMove
// Flip Move uses refs to identify and apply styles to children, and stateless functional components cannot be given refs

export default class SelectedItem extends Component {
  static propTypes = {
    title: string.isRequired,
    onClick: func.isRequired,
  }

  render() {
    return (
      <div
        className="selectedContainer"
        onClick={this.props.onClick}
      >
        <div style={{ float: 'left' }}>
          <FaCheckCircle color={colors.green} />
        </div>
        <div style={{ float: 'left', marginLeft: 5 }}>
          {this.props.title}
        </div>
      </div>
    );
  }
}
