import React, { useEffect, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import colors from 'config/colors';
import spacing from 'config/spacing';

import * as globalThunks from 'thunks/global';
import * as templateThunks from 'thunks/templates';
import * as createShiftActions from 'reducers/createShifts';
import * as templateActions from 'reducers/templates';

import Loading from 'components/Loading';
import Button from 'components/Button';

import TemplateWeekCalendar from '../WeekCalendar/TemplateWeekCalendar';
import TemplateDayView from './TemplateDayView';
import TemplatesPage from './TemplatesPage';
import EditTemplateShift from './EditTemplateShift';

interface Styles {
  [key: string]: CSSProperties,
}

const styles: Styles = {
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface EditTemplateProps {
  templateKey: string,
  templateShiftKey?: string,
  goToEditTemplateShift: (templateShiftKey: string) => void
  closeEditTemplateShift: () => void
  goToApplyTemplatePeriodView: () => void,
}

export default function EditTemplate(props: EditTemplateProps) : React.ReactElement {

  const dispatch = useDispatch();
  const shiftCreateMetadata = useSelector(({ global }) => global.shiftCreateMetadata);
  const isFetchingTemplate = useSelector(({ templates }) => templates.isFetchingTemplate);
  const fetchTemplateError = useSelector(({ templates }) => templates.fetchTemplateError);
  const templateDisabledSitesError = useSelector(({ templates }) => templates.templateDisabledSitesError);
  const template = useSelector(({ templates }) => templates.template);

  const fetchTemplate = async () => {
    if (!shiftCreateMetadata) await dispatch(globalThunks.fetchShiftCreateMetadata());
    dispatch(templateThunks.fetchTemplate(props.templateKey));
  };

  useEffect(() => {
    fetchTemplate();
    return () => {
      dispatch(templateActions.clearTemplate());
      dispatch(createShiftActions.clearTemplateShifts());
    };
  }, [props.templateKey]);

  if (isFetchingTemplate) {
    return <TemplatesPage goToEditTemplateShift={props.goToEditTemplateShift} goToApplyTemplatePeriodView={props.goToApplyTemplatePeriodView} saveTemplate={templateThunks.updateTemplate} templateKey={props.templateKey}><Loading flex /></TemplatesPage>;
  }

  if (fetchTemplateError) {
    return (
      <TemplatesPage goToEditTemplateShift={props.goToEditTemplateShift} goToApplyTemplatePeriodView={props.goToApplyTemplatePeriodView} saveTemplate={templateThunks.updateTemplate} templateKey={props.templateKey}>
        <div style={styles.errorContainer}>
          <p style={{ color: colors.red, marginBottom: spacing.base }}>{fetchTemplateError}</p>
          <Button onClick={() => dispatch(templateThunks.fetchTemplate(props.templateKey))} outline>Retry</Button>
        </div>
      </TemplatesPage>
    );
  }

  if (templateDisabledSitesError) {
    return (
      <TemplatesPage goToEditTemplateShift={props.goToEditTemplateShift} goToApplyTemplatePeriodView={props.goToApplyTemplatePeriodView} saveTemplate={templateThunks.updateTemplate} templateKey={props.templateKey}>
        <div style={styles.errorContainer}>
          <p style={{ color: colors.text, marginBottom: spacing.base }}>{templateDisabledSitesError}</p>
        </div>
      </TemplatesPage>
    );
  }

  const templateViewProps = {
    goToEditTemplateShift: props.goToEditTemplateShift,
    selectedShift: props.templateShiftKey,
    goToApplyTemplatePeriodView: props.goToApplyTemplatePeriodView,
    saveTemplate: () => dispatch(templateThunks.updateTemplate()),
    templateKey: props.templateKey,
  };

  return (
    <>
      {template?.periodType === 'week' && <TemplateWeekCalendar {...templateViewProps} />}
      {template?.periodType === 'day' && <TemplateDayView {...templateViewProps} />}
      {props.templateShiftKey && <EditTemplateShift closeSidePanel={props.closeEditTemplateShift} shiftKey={props.templateShiftKey} />}
    </>
  );
}

EditTemplate.defaultProps = { templateShiftKey: undefined };
