import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firstBy } from 'thenby';
import { MdAdd } from 'react-icons/md';

import { isFeatureOn } from 'lib/features';
import { permissions } from 'config/permissions';
import spacing from 'config/spacing';
import colors from 'config/colors';
import { fetchAllOrgSites } from 'thunks/settings';

import Page from 'components/Page';
import Button from 'components/Button';
import SplitView from 'components/SplitView';

import SettingsHeader from '../Settings/SettingsHeader';
import SitesDetails from './SitesDetails';
import detailsTabs from './detailsTabs';
import NewSiteModal from './NewSiteModal';

const styles = {
  icon: {
    color: colors.white,
    marginRight: spacing.base,
  },
};

const { object } = PropTypes;
class SitesDetailsContainer extends Component {
  static propTypes = {
    sites: object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedSiteKey: props.adminSites && Object.keys(props.adminSites).length > 0 ? Object.keys(props.adminSites)[0] : '',
      newSiteModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchAllOrgSites());
  }

  componentDidUpdate() {
    if (this.props.sites && !this.state.selectedSiteKey) {
      this.setState({ selectedSiteKey: this.getSidebarItems()[0]?.key });
    }
  }

  getSidebarItems() {
    return Object.entries(this.props.sites ?? {})
      .map(([key, site]) => ({ key, header: site.name }))
      .sort(firstBy('header'));
  }

  selectSite = (key) => this.setState({ selectedSiteKey: key });
  openNewSiteModal = () => this.setState({ newSiteModalOpen: true });
  closeNewSiteModal = () => this.setState({ newSiteModalOpen: false });

  render() {

    const isLoading = this.props.isFetchingSites || !this.props.sites;
    const sidebarItems = this.getSidebarItems();
    const selectedSite = (this.state.selectedSiteKey && this.props.sites?.[this.state.selectedSiteKey]) ?? null;

    return (
      <Page vflex disableScroll title="Settings: Sites" tabs={detailsTabs}>
        <>
          <SettingsHeader selectedTabKey="sites" />
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 12, borderBottom: '1px solid #EAEAEA' }}>
            <Button positive onClick={this.openNewSiteModal} disabled={!this.props.canCreate} className="test-id-new-site-open-button">
              <MdAdd style={styles.icon} /> New Site
            </Button>
          </div>
          <SplitView
            items={sidebarItems}
            selectedKey={this.state.selectedSiteKey}
            loading={isLoading}
            onItemClick={this.selectSite}
          >
            {selectedSite && (
              <SitesDetails
                site={selectedSite}
                openNewSiteModal={this.openNewSiteModal}
                canEdit={this.props.canEdit}
                canCreate={this.props.canCreate}
              />
            )}
          </SplitView>
          <NewSiteModal
            modalOpen={this.state.newSiteModalOpen}
            onClose={this.closeNewSiteModal}
            contentLabel="New Site"
          />
        </>
      </Page>
    );
  }
}

function mapStateToProps({ global, user, settings }) {
  return {
    sites: settings.sites,
    isFetchingSites: settings.isFetchingSites,
    sitesError: settings.sitesError,
    canEdit: isFeatureOn(null, permissions.SITES_CAN_EDIT_DETAILS, user, global),
    canCreate: isFeatureOn(null, permissions.SITES_CAN_CREATE, user, global),
  };
}

export default connect(mapStateToProps)(SitesDetailsContainer);
