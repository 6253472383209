import React from 'react';
import PropTypes from 'prop-types';
import { MdClear } from 'react-icons/md';
import './CloseButton.scss';

export default function CloseButton(props) {
  return (
    <div
      style={{ top: props.top,
        right: props.right,
        float: props.float,
        position: props.position,
        zIndex: props.zIndex,
        height: props.size,
      }}
      className={props.inverted ? 'inverted' : 'closeButtonContainer'}
      onClick={props.handleClose}
    >
      <MdClear size={props.size} />
    </div>
  );
}

const { string, func, number, oneOfType, bool } = PropTypes;
CloseButton.propTypes = {
  handleClose: func.isRequired,
  right: number,
  top: number,
  float: string,
  position: string,
  inverted: bool,
  zIndex: oneOfType([
    number,
    string,
  ]),
  size: number,
};

CloseButton.defaultProps = {
  right: 0,
  top: 0,
  size: 20,
  float: '',
  position: 'absolute',
  zIndex: 'auto',
  inverted: false,
};
