import sha1 from 'sha1';
import moment from 'moment-timezone';
import * as rateCardReducer from '../reducers/rateCards';
import * as db from '../lib/db';

export function fetchRateCardLabels(siteKey) {
  return async (dispatch, getState) => {
    dispatch(rateCardReducer.fetchLabels());
    const orgKey = Object.keys(getState().global.employerAuthorisedOrgs)[0];

    const paths = [
      `rateCardLabels/${siteKey}`,
      `rateCardLabels/${orgKey}`,
    ];

    let complete = false;
    await Promise.all(paths.map(async (path) => {
      const labels = await db.get(path);
      if (!labels || complete) {
        return;
      }

      if (Object.keys(labels).length > 0) {
        dispatch(rateCardReducer.addLabels(labels));
        complete = true;
      }
    }));
  };
}

/* eslint no-await-in-loop: "off" */
/* eslint no-continue: "off" */
/* eslint no-restricted-syntax: "off" */
/* eslint no-prototype-builtins: "off" */
export function fetchRateCards(siteKey, roleKey, gradeKey, specialityKey) {
  return async (dispatch, getState) => {
    dispatch(rateCardReducer.fetchRateCards());
    const orgKey = Object.keys(getState().global.employerAuthorisedOrgs)[0];

    const paths = [
      `/siteRateCards/${siteKey}/${sha1(`${roleKey}_${gradeKey}_${specialityKey}`)}`,
      `/orgRateCards/${orgKey}/${sha1(`${roleKey}_${gradeKey}_${specialityKey}`)}`,
      `/siteRateCards/${siteKey}/${sha1(`${roleKey}_${gradeKey}`)}`,
      `/orgRateCards/${orgKey}/${sha1(`${roleKey}_${gradeKey}`)}`,
      `/defaultRateCards/${sha1(`${roleKey}_${gradeKey}_${specialityKey}`)}`,
      `/defaultRateCards/${sha1(`${roleKey}_${gradeKey}`)}`,
    ];

    const results = {};

    for (let i = 0; i < paths.length; i += 1) {
      const rateCards = await db.get(paths[i]);
      // if no rate cards returned, try the next path
      if (!rateCards) {
        continue;
      } else {
        /* eslint-disable guard-for-in */
        // loop through every rate card returned
        /* eslint-disable guard-for-in */
        for (const rateCardKey in rateCards) {
          if (!rateCards.hasOwnProperty(rateCardKey)) return;
          if (rateCardKey === 'metadata') continue;
          const rateCard = rateCards[rateCardKey];
          // make sure rate card has a value
          if (!rateCard) {
            continue;
          }
          // if isActive property is set and is set to false, go to next rate card
          else if (typeof rateCard.isActive !== 'undefined' && rateCard.isActive === false) {
            continue;
          }
          // if effectiveDate property is set and is in the future, go to next rate card
          else if (typeof rateCard.effectiveDate !== 'undefined' && moment.utc(rateCard.effectiveDate).isAfter(moment.utc())) {
            continue;
          }
          // if expiryDate property is set and is in the past, go to next rate card
          else if (typeof rateCard.expiryDate !== 'undefined' && moment.utc(rateCard.expiryDate) < moment.utc()) {
            continue;
          } else {
            const arr = Object.keys(rateCard.items).map((key) => {
              return rateCard.items[key];
            }).sort((a, b) => a.rate - b.rate);

            rateCard.items = arr;

            results[rateCardKey] = { ...rateCard };
          }
        }
        // if none of the conditions were met, try the next path
        if (Object.keys(results).length === 0) {
          continue;
        } else {
          // as soon as we find suitable rate cards, dispatch them and exit loop
          dispatch(rateCardReducer.fetchRateCardsSuccess(results));
          break;
        }
      }
    }

  };
}

export function processOrgSiteRateCards(rawOrgSiteRateCards, type) {
  return async (dispatch, getState) => {
    const { rgsMetadata } = getState().rgs;
    const finalOrgSiteRateCards = [];
    if (rawOrgSiteRateCards) {
      Object.keys(rawOrgSiteRateCards).forEach((rgs) => {
        Object.keys(rawOrgSiteRateCards[rgs]).forEach((rateCard) => {
          if (rateCard !== 'metadata') {
            const rateCardObject = rawOrgSiteRateCards[rgs][rateCard]; // should be rgs var rather than string
            const { role, grade } = rawOrgSiteRateCards[rgs].metadata;
            const speciality = rawOrgSiteRateCards[rgs].metadata.speciality ? rawOrgSiteRateCards[rgs].metadata.speciality : 'all';

            if (Object.keys(rgsMetadata).includes(role) &&
              Object.keys(rgsMetadata[role].grades).includes(grade) &&
              (speciality === 'all' || Object.keys(rgsMetadata[role].specialities).includes(speciality))
            ) {
              rateCardObject.role = rawOrgSiteRateCards[rgs].metadata.role;
              rateCardObject.grade = rawOrgSiteRateCards[rgs].metadata.grade;
              rateCardObject.speciality = rawOrgSiteRateCards[rgs].metadata.speciality ? rawOrgSiteRateCards[rgs].metadata.speciality : 'all';
              rateCardObject.roleName = rgsMetadata[rawOrgSiteRateCards[rgs].metadata.role].name;
              rateCardObject.gradeName = rgsMetadata[rawOrgSiteRateCards[rgs].metadata.role].grades[rawOrgSiteRateCards[rgs].metadata.grade].name;
              rateCardObject.specialityName = rawOrgSiteRateCards[rgs].metadata.speciality ? rgsMetadata[rawOrgSiteRateCards[rgs].metadata.role].specialities[rawOrgSiteRateCards[rgs].metadata.speciality].name : 'All';
              rateCardObject.key = rateCard;
              finalOrgSiteRateCards.push(rateCardObject);
            }
          }
        });
      });

      finalOrgSiteRateCards.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      if (type === 'org') {
        dispatch(rateCardReducer.fetchOrgRateCardsSuccess(finalOrgSiteRateCards));
      } else if (type === 'site') {
        dispatch(rateCardReducer.fetchSiteRateCardsSuccess(finalOrgSiteRateCards));
      }
    } else if (type === 'org') {
      dispatch(rateCardReducer.fetchOrgRateCardsSuccess([]));
    } else if (type === 'site') {
      dispatch(rateCardReducer.fetchSiteRateCardsSuccess([]));
    }
  };
}

export function fetchOrgSiteRateCards(type, siteKey) {
  return async (dispatch, getState) => {
    if (type === 'org') {
      dispatch(rateCardReducer.fetchOrgRateCards());
    } else if (type === 'site') {
      dispatch(rateCardReducer.fetchSiteRateCards());
    }
    if (type === 'org') {
      const orgKey = Object.keys(getState().global.employerAuthorisedOrgs)[0];
      await db.on(`orgRateCards/${orgKey}`, (rawOrgSiteRateCards) => {
        dispatch(processOrgSiteRateCards(rawOrgSiteRateCards, type));
        console.log(`Watching orgRateCards/${orgKey}`);
      });
    } else if (type === 'site') {
      await db.on(`siteRateCards/${siteKey}`, (rawOrgSiteRateCards) => {
        dispatch(processOrgSiteRateCards(rawOrgSiteRateCards, type));
        console.log(`Watching siteRateCards/${siteKey}`);
      });
    }
  };
}

export function stopWatchingOrgSiteRateCards(type, siteKey) {
  return (dispatch, getState) => {
    if (type === 'org') {
      const orgKey = getState().global.employerAuthorisedOrgs ? Object.keys(getState().global.employerAuthorisedOrgs)[0] : null;
      if (orgKey) {
        db.off(`orgRateCards/${orgKey}`);
        console.log(`Stopped watching orgRateCards/${orgKey}`);
      }
    } else if (type === 'site') {
      db.off(`siteRateCards/${siteKey}`);
      console.log(`Stopped watching siteRateCards/${siteKey}`);
    }
  };
}
