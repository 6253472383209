import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ReduxState } from 'reducers/index';
import moment from 'moment';
import { ObservableSubscription } from '@apollo/client';
import * as messagingActions from 'reducers/messaging';
import {
  watchConversations as watchUserConversations,
  watchConversationMessages as watchUserConversationMessages,
  fetchConversationsMetadata as fetchMetadata,
  watchConversationUnreadCount as watchUserConversationUnreadCount,
} from 'lib/api/messaging';
import * as api from 'lib/api';
import generateKey from 'lib/generate-key';

interface WatchConversationsProps {
  conversationKey?: string;
  userKey?: string;
  sharedInboxKey?: string;
}

export function watchConversations({ conversationKey, userKey, sharedInboxKey }: WatchConversationsProps): ThunkAction<ObservableSubscription, ReduxState, unknown, AnyAction> {
  return (dispatch) => {
    dispatch(messagingActions.fetchConversations());

    const conversationSubscription = watchUserConversations({
      conversationKey,
      userKey,
      sharedInboxKey,
      callback: (conversations) => dispatch(messagingActions.fetchConversationsSuccess(conversations)),
      error: (error) => dispatch(messagingActions.messageError(error.message)),
    });

    return conversationSubscription;
  };
}

export function watchConversationUnreadCount(): ThunkAction<ObservableSubscription, ReduxState, unknown, AnyAction> {
  return (dispatch) => {
    dispatch(messagingActions.fetchConversations());

    const subscription = watchUserConversationUnreadCount({
      callback: (count: number) => dispatch(messagingActions.fetchUnreadCountSuccess(count)),
      error: (error) => console.error(error),
    });

    return subscription;
  };
}

// TODO: get this working with typescript
export function watchConversationMessages(conversationKey: string): ThunkAction<ObservableSubscription, ReduxState, unknown, AnyAction> {
  return (dispatch) => {
    dispatch(messagingActions.fetchConversationMessages());

    const messageSubscription = watchUserConversationMessages(
      (messages) => dispatch(messagingActions.fetchConversationMessagesSuccess(messages)),
      (error) => dispatch(messagingActions.messageError(error.message)),
      conversationKey,
    );

    return messageSubscription;
  };
}

export function markAsRead(conversationKey: string): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {
    try {
      const response = await api.post('conversations/mark-as-read', { conversationKey });
      if (response.status >= 400) {
        const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Marking conversation as read failed';
        dispatch(messagingActions.messageError(error instanceof Error ? error.message : error));
      }
    } catch (e) {
      dispatch(messagingActions.messageError(e.message));
    }
  };
}

interface Message {
  textContent: string;
  type: 'text'; // Add more types
}

export function sendMessage(conversationKey: string, message: Message): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const user = getState().user;
    const { userId, userData } = user;

    const messageKey = generateKey();
    dispatch(messagingActions.addConversationMessage({
      conversationKey,
      key: messageKey,
      ...message,
      sentAt: moment.utc().toISOString(),
      sentByKey: userId,
      sentByName: userData.displayName,
      likes: null,
      isSending: true,
    }));

    const response = await api.post('messages/send', { conversationKey, message, messageKey });

    if (response.status >= 400) {
      dispatch(messagingActions.updateConversationMessage(messageKey, { error: 'Sending message failed. Please click to retry', isSending: false }));
    }
  };
}

export function resendMessage(conversationKey: string, message: Message, messageKey: string): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {
    const response = await api.post('messages/send', { conversationKey, message, messageKey });

    if (response.status >= 400) {
      dispatch(messagingActions.updateConversationMessage(messageKey, { error: 'Sending message failed. Please to retry', isSending: false }));
    }
  };
}

export function fetchAvailableParticipants(): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {
    try {
      dispatch(messagingActions.fetchAvailableParticipants());

      const response = await api.get('conversations/available-participants');
      if (response.status >= 400) {
        const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Marking conversation as read failed';
        dispatch(messagingActions.messageError(error instanceof Error ? error.message : error));
      }

      dispatch(messagingActions.fetchAvailableParticipantsSuccess(response.body?.availableParticipants));
    } catch (e) {
      dispatch(messagingActions.messageError(e.message));
    }
  };
}

export function createConversation(userKeys: string[], sharedInboxKeys: string[], initialMessage: Message): ThunkAction<Promise<string | null>, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {
    try {
      const response = await api.post('conversations/create', { userKeys, initialMessage, sharedInboxKeys });

      if (response.status >= 400) {
        throw (response.error ?? new Error(response.body?.error?.message ?? response.body?.error ?? 'Creating conversations failed'));
      }

      return response.body?.conversationKey;
    } catch (e) {
      dispatch(messagingActions.messageError(e.message));
      throw e;
    }
  };
}

interface ReturnedConversation {
  conversationKey: string;
  textContent: string;
  latestMessageSentAt: string;
  latestMessageSentByName: string;
}

export function searchConversations(userKeys: string[], sharedInboxKeys: string[], shiftKeys?: string[]): ThunkAction<Promise<ReturnedConversation | null>, ReduxState, undefined, AnyAction> {
  return async (dispatch) => {
    try {
      const response = await api.get('conversations/search', { userKeys, sharedInboxKeys, shiftKeys });

      if (response.status >= 400) {
        const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Creating conversations failed';
        dispatch(messagingActions.messageError(error instanceof Error ? error.message : error));
      }

      return response.body.conversation;
    } catch (e) {
      dispatch(messagingActions.messageError(e.message));
    }

    return null;
  };
}

export function fetchConversationsMetadata() : ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {

    try {
      dispatch(messagingActions.fetchConversationsMetadata());
      const response = await fetchMetadata();

      dispatch(messagingActions.fetchConversationsMetadataSuccess(response.sharedInboxMembership));
    } catch (error) {
      if (error instanceof Error) dispatch(messagingActions.setConversationsMetadataError(error.message));
    }
  };
}
