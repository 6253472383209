import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash-es';

import CWShiftFormSelect from 'components/CWSelect/CWShiftFormSelect';

import FieldLabel from './FieldLabel';

interface TargetedCandidate {
  id: string,
  name: string,
}

interface TargetCandidateFieldProps {
  onChange: (candidates: TargetedCandidate[]) => void
  candidates: TargetedCandidate[],
  isValid: boolean
}

function TargetCandidateField(props: TargetCandidateFieldProps) {

  const applicableCandidates = useSelector(({ createDraftShifts }) => createDraftShifts.applicableCandidates);

  const onChangeCandidates = useCallback((candidateKeys: string[]) => {
    const keyedApplicableCandidates = keyBy(applicableCandidates, 'value');
    const onChangePayload = candidateKeys.map((candidateKey: string) => ({
      id: candidateKey,
      name: keyedApplicableCandidates[candidateKey].label,
    }));
    props.onChange(onChangePayload);
  }, [applicableCandidates, props.onChange]);

  const candidateKeys = useMemo(() => {
    return props.candidates?.map(candidate => candidate.id);
  }, [props.candidates]);

  return (
    <>
      <FieldLabel label="Staff">
        <CWShiftFormSelect
          items={applicableCandidates}
          selectedItems={candidateKeys}
          setSelectedItems={onChangeCandidates}
          placeholder="Target staff to fill slots (optional)"
          isValid={props.isValid}
        />
      </FieldLabel>
    </>
  );
}

export default React.memo(TargetCandidateField);
