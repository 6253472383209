import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Symbols, Surface } from 'recharts';
import { connect } from 'react-redux';
import moment from 'moment';
import ErrorModal from 'components/ErrorModal';
import Page from 'components/Page';
import Loading from 'components/Loading';
import NoReportData from 'components/NoReportData';
import { changeDate, fetchReport } from 'thunks/reporting';
import { clearReportingError } from 'reducers/reporting';
import spacing from 'config/spacing';
import ReportingHeader from '../ReportingHeader';
import DashboardHeader from '../Dashboard/DashboardHeader';
import DrillDownTable from '../DrillDown/DrillDownTable';
import './styles.scss';


const generateHslColour = (string) => {
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
  }

  return `hsl(${hash % 360}, ${Math.abs(hash % 100)}%, 62%)`;
};

const tooltips = {
  active: 'Staff with an application or booking in the given month',
  deactive: 'Staff with an application or booking in the previous month but not in the given month',
  onboarded: 'Staff who signed up in the given month',
  reactive: 'Staff with an application or booking in the given month, but not in the previous',
  removed: 'Staff who were removed from the bank in the given month',
};

const { shape, object, string, bool, func, objectOf, arrayOf } = PropTypes;
class HistoricalGradesReport extends Component {
  static propTypes = {
    report: shape({
      result: object.isRequired,
      siteFilter: string,
      specilialityFilter: string,
    }),
    loading: bool.isRequired,
    reportError: string,
    clearReportingError: func.isRequired,
    fetchReport: func.isRequired,
    selectedSite: shape({
      key: string,
      value: string,
    }).isRequired,
    selectedSpeciality: shape({
      key: string,
      value: string,
    }).isRequired,
    selectedRange: shape({
      from: string.isRequired,
      to: string.isRequired,
    }).isRequired,
    grades: objectOf(object),
    roles: objectOf(object),
    activeUsers: arrayOf(shape({
      month: string,
      onboarded: string,
      removed: string,
      active: string,
      reactive: string,
      deactive: string,
    })),
  }

  static defaultProps = {
    report: null,
    reportError: null,
    grades: {},
    roles: {},
    activeUsers: null,
  }

  componentDidMount() {
    this.props.fetchReport('historicalGrades');
    this.props.fetchReport('activeUsers');
  }

  async componentDidUpdate(prevProps) {
    const siteHasChanged = prevProps.selectedSite.key !== this.props.selectedSite.key;
    const specialityHasChanged = prevProps.selectedSpeciality.key !== this.props.selectedSpeciality.key;
    const dateHasChanged = (prevProps.selectedRange.from !== this.props.selectedRange.from) || (prevProps.selectedRange.to !== this.props.selectedRange.to);

    // If site or date has changed, re-fetch reports
    if (siteHasChanged || dateHasChanged || specialityHasChanged) {
      if (!this.props.loading) {
        await this.props.fetchReport('historicalGrades');
        await this.props.fetchReport('activeUsers');
      }
    }
  }

  renderLegend = (labels) => {
    return (
      <div className="toggleLegend">
        <div><h3>Skills and Qualifications</h3></div>
        <ul className="toggleLegendList">
          {
            Object.entries(labels).map(([key, label]) => {
              const { name, color } = label;

              return (
                <div key={key} className="legend-item-non-clickable">
                  <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                  </Surface>
                  <span style={{ marginLeft: spacing.tiny }}>{name}</span>
                </div>
              );
            }, this)
          }
        </ul>
      </div>
    );
  }

  renderTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          {payload.map(({ fill, dataKey, value, name }) => (
            <div key={dataKey}>
              <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={fill} />
              </Surface>
              <span style={{ marginLeft: spacing.tiny }}>{name}: {value}</span>
            </div>
          ))}
        </div>
      );
    }

    return null;
  }

  render() {
    const labels = {};
    const chartData = Object.entries(this.props?.report?.result ?? {}).map(([date, roles]) => {
      const data = {};
      Object.entries(roles).forEach(([roleKey, grades]) => {
        const role = this.props.roles[roleKey];

        Object.entries(grades).forEach(([gradeKey, count]) => {
          const grade = this.props.grades[gradeKey];
          const key = `${roleKey}-${gradeKey}`;
          labels[key] = {
            name: `${role.name} (${grade.name})`,
            rank: grade.rank,
            color: generateHslColour(key),
          };

          data[key] = count;
        });
      });

      return { date: moment(date).format('MMM YYYY'), ...data };
    });

    return (
      <Page
        vflex
        title="Reporting"
        subTitle="Skills and Qualifications"
        loading={this.props.loading}
      >
        <ReportingHeader selectedTabKey="skills-and-qualifications" />
        <DashboardHeader showSpecialityFilter showDateGroupOptions={false} extraDateRangePickerProps={{ minimumNights: 30, maxDate: moment(), isOutsideRange: date => date.isAfter(moment()) }} />

        {this.props.reportError && (
          <ErrorModal header="Historical Grades Report Error" errorMessage={this.props.reportError} onCancel={() => this.props.clearReportingError('historicalGrades')} />
        )}
        {this.props.loading ? (
          <Loading flex />
        ) :
          <div className="chartWrapper customLegend scrollable">
            {chartData.length ? (
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  data={chartData}
                  margin={{ right: spacing.base }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Legend
                    payload={labels}
                    content={() => this.renderLegend(labels)}
                    verticalAlign="top"
                    align="right"
                  />
                  <Tooltip
                    content={this.renderTooltip}
                  />
                  {Object.entries(labels).map(([key, { name, color }]) => <Bar dataKey={key} stackId="a" name={name} fill={color} maxBarSize={100} />)}
                </BarChart>
              </ResponsiveContainer>
            ) : <NoReportData title="Skills and Qualifications" />
            }
            {this.props.activeUsers?.length ? (<DrillDownTable
              report={this.props.activeUsers}
              headerFormatter={({ month }) => moment(month).format('MMM YYYY')}
              numberFormatter={val => val || '0'}
              labels={['total', 'active', 'reactive', 'deactive', 'onboarded', 'removed']}
              tooltips={tooltips}
              title="Staff"
              sortLabels={false}
            />) : <NoReportData title="Active Users" /> }
          </div>
        }
      </Page>
    );
  }
}

const mapStateToProps = ({ reporting, global }) => {

  return {
    report: reporting.reports.historicalGrades,
    loading: reporting.loading.historicalGrades || reporting.loading.activeUsers,
    activeUsers: reporting.reports.activeUsers?.result,
    selectedRange: reporting.selectedRange,
    durationBetweenDates: reporting.durationBetweenDates,
    selectedSite: reporting.selectedSite,
    selectedSpeciality: reporting.selectedSpeciality,
    reportError: reporting.reportError.historicalGrades,
    grades: global?.metadata?.grades,
    roles: global?.metadata?.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeDate: range => dispatch(changeDate(range)),
    fetchReport: (reportName, groupBy) => dispatch(fetchReport(reportName, groupBy)),
    clearReportingError: reportName => dispatch(clearReportingError(reportName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalGradesReport);
