import React from 'react';
import { useSelector } from 'react-redux';

import { Period, PublishedShiftSummary, ShiftsNav } from 'types/ShiftTypes';

import Page from 'components/Page';
import Loading from 'components/Loading';
import ShiftsSupplementaryPanel from './ShiftsSupplementaryPanel';
import BulkActionBar from './BulkActionBar';
import ShiftsHeader from './ShiftsHeader';

interface ShiftsPageProps {
  loading?: boolean;
  shiftList?: Array<PublishedShiftSummary>;
  period: Period;
  nav: ShiftsNav;
  children: JSX.Element;
  refreshShiftList?: () => void,
}

const ShiftsPage = (props: ShiftsPageProps) => {

  const { period, nav, loading, children, shiftList, refreshShiftList, ...pageProps } = props;

  const shiftDetailsIsPinned = useSelector(state => state.userInterface.shiftDetailsIsPinned);
  const isApplyingTemplate = useSelector(state => state.createShifts.isApplyingTemplate);

  return (
    <Page vflex title="Shifts" {...pageProps}>

      <ShiftsHeader
        period={period}
        goToDate={nav.goToPeriodView}
        gotToApplyTemplate={nav.goToApplyTemplatePeriodView}
        goToTemplatesListView={nav.goToTemplatesListView}
        replaceToTemplateEditView={nav.replaceToTemplateEditView}
        goToShiftTab={nav.goToShiftTab}
      />

      {loading
        ?
          <Loading flex />
        :
          <div style={{ flex: 1, display: 'flex', alignItems: 'stretch' }}>
            <div style={{ flex: '1 1 0px', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              {children}
              {shiftList && refreshShiftList && !isApplyingTemplate && <BulkActionBar refreshShiftList={refreshShiftList} period={period} nav={nav} shiftList={shiftList} />}
            </div>
            {shiftDetailsIsPinned && <ShiftsSupplementaryPanel />}
          </div>
      }
    </Page>
  );
};

ShiftsPage.defaultProps = {
  loading: false,
  shiftList: undefined,
  refreshShiftList: undefined,
};

export default ShiftsPage;
