import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-es';


import './ScrollView.css';

const { oneOfType, node, arrayOf, string, bool, func, objectOf, number } = PropTypes;
let setTimeoutId = null;
let setIntervalId = null;

export default class ScrollView extends Component {
  static propTypes = {
    children: oneOfType([
      arrayOf(node),
      node,
    ]).isRequired,
    className: string,
    blueGrad: bool,
    fadeAtEnd: bool,
    loadMore: func,
    loadAtEnd: bool,
    noFooter: bool,
    style: objectOf(oneOfType([
      string,
      number,
    ])),
  }

  static defaultProps = {
    className: '',
    blueGrad: false,
    fadeAtEnd: false,
    loadMore: null,
    loadAtEnd: false,
    noFooter: false,
    style: {},
  }

  constructor(props) {
    super(props);
    this.scrollViewContainerDiv = React.createRef();
    this.mounted = true;
    this.loadMore = props.loadMore && throttle(props.loadMore, 200);
  }

  componentDidUpdate(prevProps) {
    if (this.props.loadMore !== prevProps.loadMore) {
      this.loadMore = this.props.loadMore && throttle(this.props.loadMore, 200);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (setTimeoutId) {
      clearTimeout(setTimeoutId);
    }
    if (setIntervalId) {
      clearInterval(setIntervalId);
    }
  }

  onScroll = async (event) => {
    if (this.mounted) {
      const scrollPositionNearEnd = event && event.target && event.target.scrollTop >= (event.target.scrollHeight - event.target.offsetHeight - 50);
      if (scrollPositionNearEnd && this.loadMore) this.loadMore();
    }
  }

  onMouseDown = () => {
    setIntervalId = setInterval(() => {
      this.updateScrollState();
    }, 100);
    setTimeoutId = setTimeout(() => {
      if (setIntervalId) {
        clearInterval(setIntervalId);
      }
    }, 2000);
  }

  dataScrollMount = (domNode) => {
    if (domNode) {

      domNode.removeEventListener('scroll', this.onScroll);
      domNode.removeEventListener('mousedown', this.onMouseDown);
      domNode.addEventListener('scroll', this.onScroll);
      domNode.addEventListener('mousedown', this.onMouseDown);

      this.scroller = domNode;
      this.scroller.scrollTop = 0;
      this.onScroll({ target: domNode });
    }
  }

  updateScrollState() {
    this.onScroll({ target: this.scroller });
  }

  render() {
    return (
      <div ref={this.scrollViewContainerDiv} className="scrollViewContainer" style={this.props.containerStyle}>
        <div className={`scrollView ${this.props.className} ${this.props.hideScroll ? 'hideIeScrolls' : ''}`} style={this.props.style} ref={this.dataScrollMount}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
