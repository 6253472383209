import React, { Component } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { MdHelp } from 'react-icons/md';
import { capitalize } from '../../../lib/helpers';

const { array, func, string, shape, bool, objectOf } = PropTypes;

export default class DrillDownTable extends Component {

  static propTypes = {
    report: shape({
      result: array.isRequired,
      siteFilter: string,
      roleFilter: string,
      groupBy: string,
    }),
    headerFormatter: func.isRequired,
    numberFormatter: func.isRequired,
    labels: arrayOf(string).isRequired,
    groupBy: string.isRequired,
    title: string,
    sortLabels: bool,
    tooltips: objectOf(string),
  }

  static defaultProps = {
    report: null,
    title: 'Breakdown',
    sortLabels: true,
    tooltips: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrolling: false,
      atEnd: false,
    };
  }

  onScroll = (event) => {
    this.setState({
      scrolling: event && event.target && event.target.scrollLeft > 0,
      atEnd: event && event.target && event.target.scrollLeft === (event.target.scrollWidth - event.target.offsetWidth),
    });
  }

  dataScrollMount = (node) => {
    if (node) {
      node.removeEventListener('scroll', this.onScroll);
      node.addEventListener('scroll', this.onScroll);
      this.scroller = node;
      this.scroller.scrollLeft = this.scroller.scrollWidth - this.scroller.offsetWidth;
      this.onScroll({ target: node });
    }
  }

  render() {

    const classes = ['dataBoxWrapper'];
    const labels = this.props.labels;
    if (this.props.sortLabels && labels) labels.sort();

    if (this.state.scrolling) classes.push('scrolling');

    return (
      <div className={classes.join(' ')}>
        <div className="dataBox">
          <div className="freezeColumn">
            <div className="drillDownTable">
              <div className="drillDownHeader">{this.props.title}</div>
              {labels.map((label) => {
                const message = this.props.tooltips && this.props.tooltips[label];
                return (
                  <div key={label} className={`drillDownCell ${message && 'withTooltip'}`} data-tip={message}>
                    {capitalize(label)}
                    {message && (
                      <>
                        <MdHelp size="10" style={{ marginLeft: 2 }} />
                        <ReactTooltip type="dark" />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="dataScrollColumn" ref={this.dataScrollMount}>
            <div className="drillDownTable">
              {this.props.report.map((data, index) => (
                <div
                  className="drillDownColumn"
                  key={index.toString()}
                >
                  <div className="drillDownHeader" key={index.toString()}>{this.props.headerFormatter(data, this.props.groupBy)}</div>
                  {labels.map(label => (
                    <div key={label} className="drillDownCell">
                      {this.props.numberFormatter(data[label])}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        {this.state.atEnd ? null : <div className="rightGrad" />}
      </div>
    );
  }
}
