import { uniq } from 'lodash-es';

// *** Calendar and Day List views **************************************

const FETCH_DAY_SUMMARIES_IN_PROGRESS = 'FETCH_DAY_SUMMARIES_IN_PROGRESS';
const FETCH_DAY_SUMMARIES_SUCCESS = 'FETCH_DAY_SUMMARIES_SUCCESS';
const FETCH_DAY_SUMMARIES_FAIL = 'FETCH_DAY_SUMMARIES_FAIL';
const CLEAR_DAY_SUMMARIES_ERROR = 'CLEAR_DAY_SUMMARIES_ERROR';

const FETCH_SHIFTS_FOR_LIST_VIEW_IN_PROGRESS = 'FETCH_SHIFTS_FOR_LIST_VIEW_IN_PROGRESS';
const FETCH_SHIFTS_FOR_LIST_VIEW_SUCCESS = 'FETCH_SHIFTS_FOR_LIST_VIEW_SUCCESS';
const FETCH_SHIFTS_FOR_LIST_VIEW_FAIL = 'FETCH_SHIFTS_FOR_LIST_VIEW_FAIL';
const CLEAR_FETCH_SHIFT_ERROR = 'CLEAR_FETCH_SHIFT_ERROR';
const ADD_SHIFT_TO_LIST = 'ADD_SHIFT_TO_LIST';
const ADD_SHIFTS_TO_LIST = 'ADD_SHIFTS_TO_LIST';

const NO_JOBS_TO_DISPLAY = 'NO_JOBS_TO_DISPLAY';
const FETCH_SHIFT_LIST_EXPORT = 'FETCH_SHIFT_LIST_EXPORT';
const FETCH_SHIFT_LIST_EXPORT_SUCCESS = 'FETCH_SHIFT_LIST_EXPORT_SUCCESS';
const FETCH_SHIFT_LIST_EXPORT_ERROR = 'FETCH_SHIFT_LIST_EXPORT_ERROR';

const SET_SHIFT_IS_SELECTED = 'SET_SHIFT_IS_SELECTED';
const SELECT_SPECIFIED_SHIFTS = 'SELECT_SPECIFIED_SHIFTS';
const UNSELECT_SPECIFIED_SHIFTS = 'UNSELECT_SPECIFIED_SHIFTS';
const UNSELECT_ALL_SHIFTS = 'UNSELECT_ALL_SHIFTS';

const SET_SHIFT_IS_COLLAPSED = 'SET_SHIFT_IS_COLLAPSED';
const EXPAND_SPECIFIED_SHIFTS = 'EXPAND_SPECIFIED_SHIFTS';
const COLLAPSE_SPECIFIED_SHIFTS = 'COLLAPSE_SPECIFIED_SHIFTS';
const EXPAND_ALL_SHIFTS = 'EXPAND_ALL_SHIFTS';

export const isFetchingDaySummaries = () => ({ type: FETCH_DAY_SUMMARIES_IN_PROGRESS });
export const fetchDaySummariesSuccess = daySummaries => ({ type: FETCH_DAY_SUMMARIES_SUCCESS, daySummaries });
export const fetchDaySummariesFail = (error) => ({ type: FETCH_DAY_SUMMARIES_FAIL, error });
export const clearFetchDaySummariesError = () => ({ type: CLEAR_DAY_SUMMARIES_ERROR });

export const isFetchingShiftsListForListView = fromBookingsTab => ({ type: FETCH_SHIFTS_FOR_LIST_VIEW_IN_PROGRESS, fromBookingsTab });
export const fetchShiftsForListViewSuccess = shiftList => ({ type: FETCH_SHIFTS_FOR_LIST_VIEW_SUCCESS, shiftList });
export const fetchShiftsForListViewFailFail = (error) => ({ type: FETCH_SHIFTS_FOR_LIST_VIEW_FAIL, error });
export const clearFetchShiftsForListViewError = () => ({ type: CLEAR_FETCH_SHIFT_ERROR });
export const addShiftToList = shift => ({ type: ADD_SHIFT_TO_LIST, shift });
export const addShiftsToList = shifts => ({ type: ADD_SHIFTS_TO_LIST, shifts });

export const noJobsToDisplay = () => ({ type: NO_JOBS_TO_DISPLAY });

export const fetchShiftListExport = () => ({ type: FETCH_SHIFT_LIST_EXPORT });
export const fetchShiftListExportSuccess = () => ({ type: FETCH_SHIFT_LIST_EXPORT_SUCCESS });
export const fetchShiftListExportError = error => ({ type: FETCH_SHIFT_LIST_EXPORT_ERROR, error });

export const setShiftIsSelected = (shiftKey, isSelected) => ({ type: SET_SHIFT_IS_SELECTED, shiftKey, isSelected });
export const selectSpecifiedShifts = (shiftKeys) => ({ type: SELECT_SPECIFIED_SHIFTS, shiftKeys });
export const unselectSpecifiedShifts = (shiftKeys) => ({ type: UNSELECT_SPECIFIED_SHIFTS, shiftKeys });
export const unselectAllShifts = () => ({ type: UNSELECT_ALL_SHIFTS });

export const setShiftIsCollapsed = (shiftKey, isCollapsed) => ({ type: SET_SHIFT_IS_COLLAPSED, shiftKey, isCollapsed });
export const collapseSpecifiedShifts = (shiftKeys) => ({ type: COLLAPSE_SPECIFIED_SHIFTS, shiftKeys });
export const expandSpecifiedShifts = (shiftKeys) => ({ type: EXPAND_SPECIFIED_SHIFTS, shiftKeys });
export const expandAllShifts = () => ({ type: EXPAND_ALL_SHIFTS });

const initialState = {
  isFetching: false,
  error: null,
  daySummaries: {},
  shiftList: {},
  selectedShiftKeys: [],
  collapsedShiftKeys: [],
  isFetchingDaySummaries: false,
  isFetchingShiftsForListView: false,
  isFetchingFromShiftDetailView: false,
  isFetchingShiftListExport: false,
  exportError: null,
  fetchDaySummariesError: null,
  fetchShiftsListViewError: null,
};

export default function jobs(oldState = initialState, action) {

  // Delegate state.details to job details reducer
  const details = jobDetailsReducer(oldState.details, action);
  const state = (oldState.details === details) ? oldState : { ...oldState, details };

  switch (action.type) {

    case FETCH_DAY_SUMMARIES_IN_PROGRESS: return { ...state, isFetchingDaySummaries: true, fetchDaySummariesError: null };
    case FETCH_DAY_SUMMARIES_FAIL: return { ...state, isFetchingDaySummaries: false, fetchDaySummariesError: action.error };
    case CLEAR_DAY_SUMMARIES_ERROR: return { ...state, fetchDaySummariesError: null };
    case FETCH_DAY_SUMMARIES_SUCCESS: return { ...state, isFetchingDaySummaries: false, daySummaries: action.daySummaries };

    case FETCH_SHIFTS_FOR_LIST_VIEW_IN_PROGRESS: return { ...state, isFetchingShiftsForListView: !action.fromBookingsTab, isFetchingFromShiftDetailView: action.fromBookingsTab, fetchShiftsListViewError: null };
    case FETCH_SHIFTS_FOR_LIST_VIEW_SUCCESS: return { ...state, shiftList: action.shiftList, isFetchingShiftsForListView: false, isFetchingFromShiftDetailView: false };
    case FETCH_SHIFTS_FOR_LIST_VIEW_FAIL: return { ...state, isFetchingShiftsForListView: false, isFetchingFromShiftDetailView: false, fetchShiftsListViewError: action.error };
    case CLEAR_FETCH_SHIFT_ERROR: return { ...state, fetchShiftsListViewError: null };
    case ADD_SHIFT_TO_LIST: return { ...state, shiftList: { ...state.shiftList, [action.shift.key]: action.shift } };
    case ADD_SHIFTS_TO_LIST: return { ...state, shiftList: { ...state.shiftList, ...action.shifts } };

    case FETCH_SHIFT_LIST_EXPORT: return { ...state, isFetchingShiftListExport: true };
    case FETCH_SHIFT_LIST_EXPORT_ERROR: return { ...state, isFetchingShiftListExport: false, exportError: action.error };
    case FETCH_SHIFT_LIST_EXPORT_SUCCESS: return { ...state, isFetchingShiftListExport: false };

    case SET_SHIFT_IS_SELECTED: {
      const isCurrentlySelected = state.selectedShiftKeys.includes(action.shiftKey);
      if (action.isSelected && !isCurrentlySelected) {
        return { ...state, selectedShiftKeys: [...state.selectedShiftKeys, action.shiftKey] };
      }
      if (!action.isSelected && isCurrentlySelected) {
        return { ...state, selectedShiftKeys: state.selectedShiftKeys.filter(shiftKey => shiftKey !== action.shiftKey) };
      }
      return state;
    }
    case UNSELECT_ALL_SHIFTS:
      return { ...state, selectedShiftKeys: [] };
    case UNSELECT_SPECIFIED_SHIFTS:
      return { ...state, selectedShiftKeys: state.selectedShiftKeys.filter(shiftKey => !action.shiftKeys.includes(shiftKey)) };
    case SELECT_SPECIFIED_SHIFTS:
      return { ...state, selectedShiftKeys: uniq([...state.selectedShiftKeys, ...action.shiftKeys]) };

    case SET_SHIFT_IS_COLLAPSED: {
      const isCurrentlyCollapsed = state.collapsedShiftKeys.includes(action.shiftKey);
      if (action.isCollapsed && !isCurrentlyCollapsed) {
        return { ...state, collapsedShiftKeys: [...state.collapsedShiftKeys, action.shiftKey] };
      }
      if (!action.isCollapsed && isCurrentlyCollapsed) {
        return { ...state, collapsedShiftKeys: state.collapsedShiftKeys.filter(shiftKey => shiftKey !== action.shiftKey) };
      }
      return state;
    }
    case EXPAND_ALL_SHIFTS:
      return { ...state, collapsedShiftKeys: [] };
    case EXPAND_SPECIFIED_SHIFTS:
      return { ...state, collapsedShiftKeys: state.collapsedShiftKeys.filter(shiftKey => !action.shiftKeys.includes(shiftKey)) };
    case COLLAPSE_SPECIFIED_SHIFTS:
      console.log('action.shiftKeys: ', action.shiftKeys);
      return { ...state, collapsedShiftKeys: uniq([...state.collapsedShiftKeys, ...action.shiftKeys]) };

    case NO_JOBS_TO_DISPLAY: return { ...state, error: null };

    default:
      return state;
  }
}


// *** Details side panel **************************************

const INCREMENT_SHIFT_REF_COUNT = 'INCREMENT_SHIFT_REF_COUNT';
const DECREMENT_SHIFT_REF_COUNT = 'DECREMENT_SHIFT_REF_COUNT';

const FETCH_SHIFT_DETAILS_IN_PROGRESS = 'FETCH_SHIFT_DETAILS_IN_PROGRESS';
const FETCH_SHIFT_DETAILS_SUCCESS = 'FETCH_SHIFT_DETAILS_SUCCESS';
const FETCH_SHIFT_DETAILS_ERROR = 'FETCH_SHIFT_DETAILS_ERROR';
const CLEAR_SHIFT_DETAILS_ERROR = 'CLEAR_SHIFT_DETAILS_ERROR';

const FETCH_AUDIT_TRAIL_IN_PROGRESSS = 'FETCH_AUDIT_TRAIL_IN_PROGRESSS';
const FETCH_AUDIT_TRAIL_SUCCESS = 'FETCH_AUDIT_TRAIL_SUCCESS';
const FETCH_AUDIT_TRAIL_ERROR = 'FETCH_AUDIT_TRAIL_ERROR';

const FETCH_APPLICANTS_IN_PROGESS = 'FETCH_APPLICANTS_IN_PROGESS';
const SET_SHIFT_APPLICATIONS_AND_BOOKINGS = 'SET_SHIFT_APPLICATIONS_AND_BOOKINGS';
const UPDATE_BOOKING = 'UPDATE_BOOKING';

const SET_IS_UPDATING_SHIFT = 'SET_IS_UPDATING_SHIFT';
const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS';
const SET_UPDATE_SHIFT_ERROR = 'SET_UPDATE_SHIFT_ERROR';
const SET_CLASHING_SHIFTS_ERROR = 'SET_CLASHING_SHIFTS_ERROR';
const CLEAR_CLASHING_SHIFTS_ERROR = 'CLEAR_CLASHING_SHIFTS_ERROR';

export const incrementShiftRefCount = shiftKey => ({ type: INCREMENT_SHIFT_REF_COUNT, shiftKey });
export const decrementShiftRefCount = shiftKey => ({ type: DECREMENT_SHIFT_REF_COUNT, shiftKey });

export const isFetchingShiftDetails = shiftKey => ({ type: FETCH_SHIFT_DETAILS_IN_PROGRESS, shiftKey });
export const setShiftDetails = (shiftKey, shiftDetails) => ({ type: FETCH_SHIFT_DETAILS_SUCCESS, shiftKey, shiftDetails });
export const fetchShiftDetailsError = (shiftKey, error) => ({ type: FETCH_SHIFT_DETAILS_ERROR, shiftKey, error });
export const clearShiftDetailsError = (shiftKey) => ({ type: CLEAR_SHIFT_DETAILS_ERROR, shiftKey });

export const isFetchingAuditTrail = shiftKey => ({ type: FETCH_AUDIT_TRAIL_IN_PROGRESSS, shiftKey });
export const setShiftAuditTrail = (shiftKey, auditTrail) => ({ type: FETCH_AUDIT_TRAIL_SUCCESS, shiftKey, auditTrail });
export const fetchAuditTrailError = (shiftKey, error) => ({ type: FETCH_AUDIT_TRAIL_ERROR, shiftKey, error });

export const isFetchingSelectedJobApplicants = shiftKey => ({ type: FETCH_APPLICANTS_IN_PROGESS, shiftKey });
export const setShiftApplicationsAndBookings = (shiftKey, applicationsAndBookings) => {
  const bookedCandidates = applicationsAndBookings.filter(app => app.status === 'booked');
  const applicants = applicationsAndBookings.filter(app => app.status === 'applied' && app.origin === 'bank');
  const agencyApplicants = Object.assign({}, ...applicationsAndBookings.filter(app => app.origin === 'supplier' && app.status === 'applied').map(applicant => ({ [applicant.candidateKey]: applicant })));
  return { type: SET_SHIFT_APPLICATIONS_AND_BOOKINGS, shiftKey, bookedCandidates, applicants, agencyApplicants };
};
export const updateBooking = (shiftKey, candidateKey, updates) => ({ type: UPDATE_BOOKING, shiftKey, candidateKey, updates });

export const setIsUpdatingShift = shiftKey => ({ type: SET_IS_UPDATING_SHIFT, shiftKey });
export const updateShiftSuccess = shiftKey => ({ type: UPDATE_SHIFT_SUCCESS, shiftKey });
export const setUpdateShiftError = (shiftKey, error) => ({ type: SET_UPDATE_SHIFT_ERROR, shiftKey, error });
export const setClashingShiftsError = (shiftKey, error) => ({ type: SET_CLASHING_SHIFTS_ERROR, shiftKey, error });
export const clearClashingShiftsError = shiftKey => ({ type: CLEAR_CLASHING_SHIFTS_ERROR, shiftKey });

const initialDetailsState = {
  details: null,
  isFetchingShiftDetails: false,

  bookings: [],
  bankApplicants: [],
  supplierApplicants: {},
  isFetchingApplicants: false,

  auditTrail: null,
  isFetchingAuditTrail: false,
  refCount: 0,

  isUpdatingShift: false,
  clashingShiftsError: null,
  updateShiftError: null,
};

function updateShift(state, shiftKey, updates) {
  const shift = { ...(state[shiftKey] ?? initialDetailsState), ...updates };
  return { ...state, [shiftKey]: shift };
}

function jobDetailsReducer(state = {}, action) {

  switch (action.type) {

    // Keeping a count of the number of views currently showing a shift allows us to clean up
    // data that is no longer in active use without accidentally wiping data that is still being used
    // by another view.
    case INCREMENT_SHIFT_REF_COUNT: {
      const refCount = state[action.shiftKey]?.refCount ?? 0;
      return updateShift(state, action.shiftKey, { refCount: refCount + 1 });
    }
    case DECREMENT_SHIFT_REF_COUNT: {
      const refCount = state[action.shiftKey]?.refCount;
      if (!refCount) return state;
      if (refCount > 1) return updateShift(state, action.shiftKey, { refCount: refCount - 1 });
      const { [action.shiftKey]: deletedShift, ...newState } = state;
      return newState;
    }

    case FETCH_SHIFT_DETAILS_IN_PROGRESS: return updateShift(state, action.shiftKey, { isFetchingShiftDetails: true });
    case FETCH_SHIFT_DETAILS_SUCCESS: return updateShift(state, action.shiftKey, { isFetchingShiftDetails: false, details: action.shiftDetails });
    case FETCH_SHIFT_DETAILS_ERROR: return updateShift(state, action.shiftKey, { isFetchingShiftDetails: false, error: action.error });
    case CLEAR_SHIFT_DETAILS_ERROR: return updateShift(state, action.shiftKey, { error: null });

    case FETCH_AUDIT_TRAIL_IN_PROGRESSS: return updateShift(state, action.shiftKey, { isFetchingAuditTrail: true });
    case FETCH_AUDIT_TRAIL_SUCCESS: return updateShift(state, action.shiftKey, { isFetchingAuditTrail: false, auditTrail: action.auditTrail });
    case FETCH_AUDIT_TRAIL_ERROR: return updateShift(state, action.shiftKey, { isFetchingAuditTrail: false, error: action.error });

    case FETCH_APPLICANTS_IN_PROGESS: return updateShift(state, action.shiftKey, { isFetchingApplicants: true });
    case SET_SHIFT_APPLICATIONS_AND_BOOKINGS:
      return updateShift(state, action.shiftKey, {
        error: null,
        isFetchingApplicants: false,
        bookings: action.bookedCandidates,
        bankApplicants: action.applicants,
        supplierApplicants: action.agencyApplicants,
      });
    case UPDATE_BOOKING: {
      const bookings = state[action.shiftKey]?.bookings;
      if (!Array.isArray(bookings)) return state;
      return {
        ...state,
        [action.shiftKey]: {
          ...state[action.shiftKey],
          bookings: bookings.map((booking) => {
            return (booking.candidateKey === action.candidateKey) ? { ...booking, ...action.updates } : booking;
          }),
        },
      };
    }

    case SET_IS_UPDATING_SHIFT: return updateShift(state, action.shiftKey, { isUpdatingShift: true, clashingShiftsError: null, updateShiftError: null });
    case UPDATE_SHIFT_SUCCESS: return updateShift(state, action.shiftKey, { isUpdatingShift: false });
    case SET_UPDATE_SHIFT_ERROR: return updateShift(state, action.shiftKey, { isUpdatingShift: false, updateShiftError: action.error });
    case SET_CLASHING_SHIFTS_ERROR: return updateShift(state, action.shiftKey, { isUpdatingShift: false, clashingShiftsError: action.error });
    case CLEAR_CLASHING_SHIFTS_ERROR: return updateShift(state, action.shiftKey, { clashingShiftsError: null });

    default:
      return state;
  }
}
