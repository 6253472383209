import React from 'react';
import propTypes from 'prop-types';
import { IoIosConstruct } from 'react-icons/io';
import Pill from '../Pill';
import colors from '../../config/colors';


const styles = {
  draftIcon: {
    color: colors.white,
    fontSize: '11px',
    marginRight: '3px',
    marginTop: '-2px',
  },
};

const StatusPill = ({ style, job }) => {

  if (job.isDraft && job.status && job.status === 'Failed') return <Pill style={style} label="Failed" size="tiny" type="danger" icon message={job.response} />;
  if (job.isDraft && job.status && job.status === 'shiftClash') return <Pill style={style} label="Shift Clash" size="tiny" type="danger" icon message={job.response} />;
  if (job.isDraft && !job.isValid) return <Pill icon type="invalid" label="Invalid Draft" customIcon={<IoIosConstruct style={styles.draftIcon} />} />;
  if (job.isDraft) return <Pill style={style} label="Draft" size="medium" type="invalid" icon customIcon={<IoIosConstruct style={styles.draftIcon} />} />;
  if (job.cancelledAt) return <Pill style={style} label="Cancelled" size="small" type="dark" />;

  const slotsFilled = job.bookedCandidates?.length || job.slotsFilled;
  const slotsRequired = job.slotsRequired;
  const noSlotsFilled = slotsFilled === 0;
  const allSlotsFilled = slotsFilled === slotsRequired;
  let pillColor;
  if (noSlotsFilled) {
    pillColor = 'danger';
  } else if (allSlotsFilled) {
    pillColor = 'positive';
  } else {
    pillColor = 'warning';
  }

  const slotsFillRateLabel = `${slotsFilled} of ${slotsRequired} filled`;
  return <Pill style={style} label={slotsFillRateLabel} size="small" type={pillColor} />;
};

const { object } = propTypes;
StatusPill.propTypes = {
  job: object.isRequired,
  style: object,
};

StatusPill.defaultProps = {
  style: null,
};

export default StatusPill;
