import moment from 'moment';

function alphaNumSort(dataArray) {
  // Note: this definitely won't pass any benchmark test
  // and any coding purist would probably be sick at the site
  // of it. But it works and any solution found online didn't
  const nonNumberFields = dataArray.filter(obj => !obj.label.match(/\d/));

  const numberFields = dataArray.map((obj) => {
    if (obj.label.match(/\d/)) {
      return {
        ...obj,
        number: parseInt(obj.label.match(/\d/g).join(''), 10),
      };
    }

    return null;
  }).filter(Boolean);

  if (numberFields && numberFields.length > 0) {
    numberFields.sort((a, b) => {
      if (a.number < b.number) return -1;
      if (b.number < a.number) return 1;
      return 0;
    });
  }

  const joinedArrays = nonNumberFields.concat(numberFields);
  joinedArrays.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (b.label < a.label) return 1;
    return 0;
  });

  return joinedArrays;
}

export function convertToSelectArray(data, field, sort) {
  if (!data || Object.keys(data).length === 0) return [];
  const arr = Object.keys(data).map((key) => {
    const obj = data[key];
    const label = field ? obj[field] : obj;
    return { value: key, label };
  });

  if (sort) {
    return alphaNumSort(arr);
  }

  return arr;
}

export function convertToSelect(data) {
  if (!data) return null;
  if (data.value && data.label) return data;

  return {
    value: data.id ?? data.key,
    label: data.name,
  };
}

export function convertFromSelect(obj) {
  if (!obj) return null;
  return {
    id: obj.value,
    name: obj.label,
  };
}

export function capitalize(str) {
  return str.split(' ')
    .filter(word => word.length > 0) // If there are multple consecutive spaces, we'll get an empty string as one of the words.
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

export function parseDay(day) {
  if (day.length === 1) return `0${day}`;

  return day;
}

export function sortDayData(day) {
  const arr = Object.keys(day);
  return arr.map(key => day[key]).sort((a, b) => {
    const time1 = moment(a.when).valueOf();
    const time2 = moment(b.when).valueOf();
    if (time2 < time1) return 1;
    if (time1 < time2) return -1;
    return 0;
  });
}
