import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isFeatureOn } from 'lib/features';
import { permissions } from 'config/permissions';
import Page from 'components/Page';

import SettingsHeader from '../Settings/SettingsHeader';
import RGSManage from './RGSManage';

const { object, objectOf, oneOfType, number, shape, string, func, bool } = PropTypes;
class RGSManageContainer extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    metadata: objectOf(object).isRequired,
    orgConfig: objectOf(oneOfType([
      number,
      object,
    ])).isRequired,
    screen: shape({
      width: number.isRequired,
      height: number.isRequired,
      size: string.isRequired,
    }).isRequired,
    orgId: string.isRequired,
    canEdit: bool,
  }

  static defaultProps = {
    canEdit: false,
  };

  state = {
    editModalOpen: false,
    newModalOpen: false,
  }

  openEditModal = (roleId) => {
    this.setState({
      selectedRole: roleId,
      editModalOpen: true,
    });
  }

  render() {
    return (
      <Page title="Settings: Roles, Grades &amp; Specialities">
        <SettingsHeader selectedTabKey="rgs" />
        <div style={{ padding: 24 }}>
          <p>Roles, grades and specialities classify staff into different groups.</p>
          <p>Pick which roles, grades and specialities are relevant to your organisation. This is used across jobs, compliance and reporting.</p>
          <RGSManage
            screen={this.props.screen}
            orgRoles={this.props.orgConfig.roles}
            roles={this.props.metadata.roles}
            grades={this.props.metadata.grades}
            specialities={this.props.metadata.specialities}
            editModalOpen={this.state.editModalOpen}
            onCloseEditModal={() => this.setState({ editModalOpen: false })}
            openEditModal={this.openEditModal}
            selectedRole={this.state.selectedRole}
            orgId={this.props.orgId}
            newModalOpen={this.state.newModalOpen}
            onCloseNewModal={() => this.setState({ newModalOpen: false })}
            openNewModal={() => this.setState({ newModalOpen: true })}
            canEdit={this.props.canEdit}
          />
        </div>
      </Page>
    );
  }
}

function mapStateToProps({ global, user, screen }) {
  return {
    metadata: global.metadata,
    orgConfig: global.orgConfig,
    orgId: Object.keys(global.employerAuthorisedOrgs)[0],
    screen,
    canEdit: isFeatureOn(null, permissions.ORG_CAN_EDIT_DETAILS, user, global),
  };
}

export default connect(mapStateToProps)(RGSManageContainer);
