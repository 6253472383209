import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import Page from 'components/Page';
import Loading from 'components/Loading';
import Button from 'components/Button';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { fetchConversationsMetadata } from 'thunks/messaging';
import CandidateProfileModal from '../BankManagement/CandidateProfileModal';
import Inbox from './Inbox';

const MessagingRoutes: React.FC<RouteComponentProps> = ({ history, match }) => {

  const dispatch = useDispatch();
  const url = match.path;

  const conversations = useSelector(state => state.messaging.conversations);
  const isFetchingConversationsMetadata = useSelector(state => state.messaging.isFetchingConversationsMetadata);
  const fetchConversationMetadataError = useSelector(state => state.messaging.fetchConversationMetadataError);
  const sharedInboxMembership = useSelector(state => state.messaging.sharedInboxMembership);
  const latestConversation = conversations?.[0];

  // Fetch conversations metadata if it doesn't already exist in Redux
  useEffect(() => {
    if (!sharedInboxMembership) dispatch(fetchConversationsMetadata());
  }, []);

  // Loading state when fetching converasations metadata
  if (isFetchingConversationsMetadata) {
    return (
      <Page vflex title="Messaging">
        <Loading flex />
      </Page>
    );
  }

  // Show error message if failed to fetch conversations metadata
  if (fetchConversationMetadataError) {
    return (
      <Page vflex title="Messaging">
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: colors.red }}>{fetchConversationMetadataError}</p>
          <div style={{ marginTop: spacing.base }}>
            <Button onClick={() => dispatch(fetchConversationsMetadata())}>Retry</Button>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Switch>
      <Route
        path={`${url}/inbox/:conversationKey/participants/:candidateKey`}
        render={(routeParams) => {
          const { candidateKey, conversationKey } = routeParams.match.params;

          return (
            <Page vflex title="Messaging">
              <CandidateProfileModal
                isOpen
                candidateId={candidateKey}
                onRequestClose={() => history.push(`${url}/inbox/${conversationKey}`)}
              />
            </Page>
          );
        }}
      />

      <Route
        path={`${url}/inbox/:conversationKey`}
        render={(routeParams) => {
          const { conversationKey } = routeParams.match.params;
          const goToConversation = (key: string) => history.push(`${url}/inbox/${key}`);
          const goToNewConversation = () => history.push(`${url}/inbox/new`);
          const nav = { goToNewConversation, goToConversation };


          return (<Inbox nav={nav} selectedConversationKey={conversationKey} />);
        }}
      />
      <Route
        path={url}
        render={() => <Redirect to={`${url}/inbox/${latestConversation?.conversationKey ?? 'new'}`} />}
      />

    </Switch>
  );
};

export default MessagingRoutes;
