import React from 'react';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';
import spacing from 'config/spacing';
import colors from 'config/colors';
import Button from 'components/Button';
import DropDownFilter from 'components/DropDownFilter';


const styles = {
  timesheetsFilterBarContainer: {
    flex: '0 0 auto',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0 12px 12px 12px',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(164, 194, 244, 0.2)',
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  timesheetsFilterBarInnerContainer: {
    width: '100%',
    minWidth: 700,
    display: 'flex',
  },
  timesheetFilterContainer: {
    width: '100%',
    paddingRight: '12px',
  },
  timesheetFilterBarFiltersContainer: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 0',
    marginTop: 2,
    marginLeft: 3,
    marginRight: spacing.tiny,
    color: colors.positive,
  },
  resetButtonStyle: {
    width: '130px',
  },
};

const statusOptions = [
  { value: null, label: 'All' },
  { value: 'created', label: 'Awaiting Submission' },
  { value: 'pending-approval-overdue', label: 'Pending Approval (Overdue)' },
  { value: 'pending-approval', label: 'Pending Approval' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'paid', label: 'Paid' },
  { value: 'locked', label: 'Locked' },
];

const overdueOptions = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Overdue' },
];

const convertToSelectArrayCandidates = (candidates) => {
  if (!candidates) return [];

  const selectArray = Object.values(candidates)
    .map(candidate => ({ value: candidate.candidateKey, label: candidate.name }))
    .sort(firstBy('label'));

  return [{ value: null, label: 'All' }, ...selectArray];
};

const convertToSelectArrayAdminGroups = (adminGroups) => {
  const selectArray = adminGroups.map(adminGroup => ({
    value: adminGroup.key,
    label: adminGroup.name,
  })).sort(firstBy('label'));
  return [{ value: null, label: 'All' }, ...selectArray];
};

const createSpecialityOptions = (specialities) => {
  const specialityOptions = specialities.map(speciality => ({ value: speciality.key, label: speciality.name }));
  return [{ value: null, label: 'All' }, ...specialityOptions];
};

const createSiteOptions = (sites) => {
  const siteOptions = sites.map(site => ({ value: site.key, label: site.name }));
  return [{ value: null, label: 'All' }, ...siteOptions];
};

const customSelectBoxStyles = {
  valueContainer: { fontSize: '14px' },
  menuList: { width: '100%' },
  menu: { width: '100%' },
  option: { fontSize: '14px' },
};

const TimesheetsFilterBar = (props) => {
  return (
    <div style={styles.timesheetsFilterBarContainer}>
      <div style={styles.timesheetsFilterBarInnerContainer}>
        {props.adminGroupsFeatureFlag && (
          <div style={styles.timesheetFilterContainer}>
            <DropDownFilter
              title="Care Group:"
              placeholder="Filter by Care Group..."
              options={props.orgAdminGroups.length ?
                convertToSelectArrayAdminGroups(props.orgAdminGroups)
                : []}
              value={convertToSelectArrayAdminGroups(props.orgAdminGroups).find(option => option.value === props.timesheetFilters.adminGroup)}
              onChange={obj => props.setTimesheetsFilterValue('adminGroup', obj.value)}
              customStyles={customSelectBoxStyles}
            />
          </div>
        )}
        <div style={styles.timesheetFilterContainer}>
          <DropDownFilter
            title="Site"
            placeholder="Filter by Site..."
            options={createSiteOptions(props.adminSites)}
            value={createSiteOptions(props.adminSites).find(option => option.value === props.timesheetFilters.site)}
            onChange={obj => props.setTimesheetsFilterValue('site', obj.value)}
            customStyles={customSelectBoxStyles}
          />
        </div>
        <div style={styles.timesheetFilterContainer}>
          <DropDownFilter
            title="Speciality:"
            placeholder="Filter by Speciality..."
            options={createSpecialityOptions(props.orgSpecialities)}
            value={createSpecialityOptions(props.orgSpecialities).find(option => option.value === props.timesheetFilters.speciality)}
            onChange={obj => props.setTimesheetsFilterValue('speciality', obj.value)}
            customStyles={customSelectBoxStyles}
          />
        </div>
        <div style={styles.timesheetFilterContainer}>
          <DropDownFilter
            title="Candidate:"
            options={convertToSelectArrayCandidates(props.candidates)}
            placeholder="Filter by candidate..."
            value={convertToSelectArrayCandidates(props.candidates).find(option => option.value === props.timesheetFilters.candidate)}
            onChange={obj => props.setTimesheetsFilterValue('candidate', obj.value)}
            loading={props.isFetchingCandidates}
            customStyles={customSelectBoxStyles}
          />
        </div>
        <div style={styles.timesheetFilterContainer}>
          <DropDownFilter
            title="Timesheet Status:"
            placeholder="Filter by timesheet status..."
            options={statusOptions}
            value={statusOptions.find(option => option.value === props.timesheetFilters.status)}
            onChange={obj => props.setTimesheetsFilterValue('status', obj.value)}
            customStyles={customSelectBoxStyles}
          />
        </div>
        <div style={{ alignSelf: 'flex-end' }}>
          <Button black shadow={false} style={styles.resetButtonStyle} onClick={props.resetTimesheetFilters}>
            Reset Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

const { arrayOf, object, func, objectOf, bool, shape, string, number } = PropTypes;

TimesheetsFilterBar.propTypes = {
  orgAdminGroups: arrayOf(object).isRequired,
  candidates: objectOf(object).isRequired,
  orgSpecialities: arrayOf(shape({ key: string, name: string })).isRequired,
  isFetchingCandidates: bool,
  resetTimesheetFilters: func.isRequired,
  setTimesheetsFilterValue: func.isRequired,
  timesheetFilters: shape({
    status: string,
    candidate: string,
    overdue: number.isRequired,
    adminGroup: string,
  }).isRequired,
  adminSites: arrayOf(shape({
    key: string,
    name: string,
    abbr: string,
    orgKey: string,
    timezone: string,
    orgName: string,
    canManageShifts: bool,
    enabled: bool,
  })).isRequired,
  adminGroupsFeatureFlag: bool.isRequired,
};

TimesheetsFilterBar.defaultProps = {
  isFetchingCandidates: false,
};
export default TimesheetsFilterBar;
