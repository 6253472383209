import { fetchShiftDetail, fetchShiftAuditTrail } from 'lib/api/jobs';
import moment from 'moment';
import * as api from 'lib/api';
import * as jobsApis from 'lib/api/jobs';
import downloadFile from 'lib/helpers-download-file';

import {
  isFetchingDaySummaries,
  fetchDaySummariesFail,
  fetchDaySummariesSuccess,

  isFetchingShiftsListForListView,
  fetchShiftsForListViewFailFail,
  fetchShiftsForListViewSuccess,

  isFetchingShiftDetails,
  setShiftDetails,
  setShiftApplicationsAndBookings,
  fetchShiftDetailsError,

  isFetchingAuditTrail,
  setShiftAuditTrail,
  fetchAuditTrailError,

  fetchShiftListExport,
  fetchShiftListExportSuccess,
  fetchShiftListExportError,

  setIsUpdatingShift,
  updateShiftSuccess,
  setUpdateShiftError,
  setClashingShiftsError,
} from 'reducers/jobs';


export function fetchShiftDetails(shiftKey, candidateKey = null) {
  return async (dispatch) => {
    try {
      dispatch(isFetchingShiftDetails(shiftKey));

      const { shift, applicationsAndBookings } = await fetchShiftDetail(shiftKey, candidateKey);

      dispatch(setShiftDetails(shiftKey, shift));
      dispatch(setShiftApplicationsAndBookings(shiftKey, applicationsAndBookings));

    } catch (error) {
      dispatch(fetchShiftDetailsError(shiftKey, 'Oops! Something went wrong. Check your network connectivity and try again. If the problem persists, please contact technical support.'));
    }
  };
}

export function fetchAuditTrail(shiftKey) {
  return async (dispatch) => {
    try {
      dispatch(isFetchingAuditTrail(shiftKey));
      const { shiftAuditTrail } = await fetchShiftAuditTrail(shiftKey);
      dispatch(setShiftAuditTrail(shiftKey, shiftAuditTrail));
    } catch (error) {
      dispatch(fetchAuditTrailError(shiftKey, 'Something went wrong. Please try again.'));
    }
  };
}

export function book(jobId, candidateId, profRegStatus, isAgency = false, applicationId = null, autoBookedAfterReleaseToBank = false) {
  return async (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const jobs = state.jobs.jobData;
    const siteId = jobs[jobId].site.id;
    const { orgKey } = user.sites[siteId];
    const payload = {
      jobId,
      candidateId,
      applicationId,
      orgKey,
      hasAction: true,
      isAgency,
      autoBookedAfterReleaseToBank: autoBookedAfterReleaseToBank || null,
    };

    if (profRegStatus && profRegStatus.overallStatus === 'WARNING' && profRegStatus.warnings && profRegStatus.warnings.length > 0) {
      payload.warnings = profRegStatus.warnings;
    }

    const response = await api.post('jobs/book', payload);
    return response.body;
  };
}

// Calendar view and Day view

const getFilterIds = (selectedFilterKeys) => {
  return {
    // If site filter is set to 'All Sites', send array of admin's sites
    serviceIds: selectedFilterKeys?.services?.length ? selectedFilterKeys?.services : null,
    siteIds: selectedFilterKeys?.sites?.length ? selectedFilterKeys?.sites : null,
    areaIds: selectedFilterKeys?.areas?.length ? selectedFilterKeys?.areas : null,
    roleIds: selectedFilterKeys?.roles?.length ? selectedFilterKeys?.roles : null,
    gradeIds: selectedFilterKeys?.grades?.length ? selectedFilterKeys?.grades : null,
    specialityIds: selectedFilterKeys?.specialities?.length ? selectedFilterKeys?.specialities : null,
    reasonIds: selectedFilterKeys?.reasons?.length ? selectedFilterKeys?.reasons : null,
  };
};

export function getDaySummaries({ startDate, endDate, renderShiftKeys = false }) {
  return async (dispatch, getState) => {
    dispatch(isFetchingDaySummaries());
    const selectedFilterKeys = getState().filter.v2SelectedItems.shifts;
    const response = await api.post('calendars/day-summaries', {
      ...getFilterIds(selectedFilterKeys),
      startDate,
      endDate,
      renderShiftKeys,
    });

    if (response.status !== 200) {
      dispatch(fetchDaySummariesFail(response?.body?.humanReadableErrorMessage ?? 'Failed to fetch shift calendar. If the problem persists, please contact technical support.'));
    } else {
      dispatch(fetchDaySummariesSuccess(response.body.shiftSummaries));
    }
  };
}

export function getShiftsForListView(date, showCancelledShifts = false, fromBookingsTab = false) {
  return async (dispatch, getState) => {
    dispatch(isFetchingShiftsListForListView(fromBookingsTab));
    const selectedFilterKeys = getState().filter.v2SelectedItems.shifts;
    const response = await api.post('jobs/shift-list', {
      ...getFilterIds(selectedFilterKeys),
      date,
      isCancelled: showCancelledShifts,
    });

    console.log('response: ', response);

    if (response.status !== 200) {
      dispatch(fetchShiftsForListViewFailFail());
    } else {
      dispatch(fetchShiftsForListViewSuccess(response.body.shiftList));
    }
  };
}

function formatDates(from, to) {
  const fromDate = from.format('YYYY-MM-DD');
  const toDate = to.format('YYYY-MM-DD');
  return fromDate === toDate ? fromDate : `${fromDate} - ${toDate}`;
}

export function getShiftListExport(from, to) {
  return async (dispatch, getState) => {
    dispatch(fetchShiftListExport());
    const state = getState();
    const selectedFilterKeys = state.filter.v2SelectedItems.shifts;

    const response = await api.post('jobs/shift-list', {
      ...getFilterIds(selectedFilterKeys),
      from: from.toISOString(),
      to: to.toISOString(),
      format: 'csv',
    });

    if (response.status !== 200) {
      dispatch(fetchShiftListExportError(response.body?.error || 'Failed to export view. Please try again'));
    } else {
      downloadFile(`CW Shifts ${formatDates(from, to)} (exported at ${moment().format('YYYY-MM-DD HH.mm.ss')}).csv`, 'text/csv', response.body);
      dispatch(fetchShiftListExportSuccess());
    }
  };
}

export function getShifts(from, to, showCancelledShifts = false, fromBookingsTab = false) {
  return async (dispatch, getState) => {
    dispatch(isFetchingShiftsListForListView(fromBookingsTab));
    const selectedFilterKeys = getState().filter.v2SelectedItems.shifts;
    const response = await api.post('jobs/shift-list', {
      ...getFilterIds(selectedFilterKeys),
      from,
      to,
      isCancelled: showCancelledShifts,
    });

    if (response.status !== 200) {
      dispatch(fetchShiftsForListViewFailFail('Failed to fetch shift list. If the problem persists, please contact technical support.'));
    } else {
      dispatch(fetchShiftsForListViewSuccess(response.body.shiftList));
    }
  };
}

export function updateShift(shiftKey, fields, onShiftChange) {
  return async (dispatch, getState) => {

    const updatePayload = {};

    Object.entries(fields).forEach(([fieldKey, value]) => {
      if (fieldKey === 'area') updatePayload.areaKey = value.id;
      if (fieldKey === 'reason') updatePayload.reasonKey = value?.id ?? null;
      if (fieldKey === 'subReason') updatePayload.subReasonKey = value?.id ?? null;
      if (fieldKey === 'rateCard') updatePayload.rateCardKey = value?.key ?? null;
      if (fieldKey === 'customHourlyRate') {
        const rateCardField = fields.rateCard;
        updatePayload.customHourlyRate = rateCardField?.requireCustomHourlyRate ? value : null;
      }
      if (fieldKey === 'startTime' || fieldKey === 'endTime' || fieldKey === 'publicDescription' || fieldKey === 'privateNotes' || fieldKey === 'slotsRequired') {
        updatePayload[fieldKey] = value;
      }
    });

    try {

      dispatch(setIsUpdatingShift(shiftKey));

      const response = await jobsApis.updateShift(shiftKey, updatePayload);

      if (response.clashes && response.clashingShifts) {
        dispatch(setClashingShiftsError(shiftKey, response.clashingShifts));
        return false;
      }

      // Refetch shift details, audit trail and shift list
      await Promise.all([
        dispatch(fetchShiftDetails(shiftKey)),
        dispatch(fetchAuditTrail(shiftKey)),
        onShiftChange(),
      ]);

      dispatch(updateShiftSuccess(shiftKey));
      return true;

    } catch (error) {
      dispatch(setUpdateShiftError(shiftKey, error.message));
      return false;
    }
  };
}
