import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import moment, { Moment } from 'moment';

import { Period } from 'types/ShiftTypes';

import colors from 'config/colors';

import DropDown from 'components/DropDown';
import Button from 'components/Button';

const styles = {
  container: {
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
    backgroundColor: colors.white,
  },
  dateButton: {
    padding: '0 6px',
    color: colors.text,
    fontSize: '1rem',
    cursor: 'pointer',
    flex: '0 0 auto',
  },
  dateTitle: {
    padding: '0 6px',
    textAlign: 'center' as CSSProperties['textAlign'],
    fontSize: '.8rem',
    color: colors.text,
    flex: '0 0 auto',
    borderRight: `1px solid ${colors.cavalry.line}`,
    borderLeft: `1px solid ${colors.cavalry.line}`,
  },
};

interface TimePeriodControlProps {
  period: Period;
  goToDate: (newDate: Moment, periodDuration?: string) => void;
}

interface Option {
  value: string;
  label: string;
}

const TimePeriodControl : React.FC<TimePeriodControlProps> = (props: TimePeriodControlProps) => {

  const period = props.period;
  const isMobile = useSelector(state => state.screen.isMobile);

  const options : Array<Option> = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'week-am-pm', label: 'Week (split AM/PM)' },
    { value: 'two-weeks', label: 'Two Weeks' },
    { value: 'month', label: 'Month' },
  ];

  return (
    <div style={{ display: 'flex' }}>

      <DropDown
        options={options}
        value={options.find(option => option.value === period.duration)}
        onChange={(selectedOption: Option) => props.goToDate(period.keyDate, selectedOption.value)}
        menuPosition="fixed"
        width={189}
        height={34}
      />

      <div style={styles.container}>
        <div className="p-hide" style={styles.dateButton} onClick={() => props.goToDate(period.previousDate, period.duration)}>
          <MdChevronLeft size={16} />
        </div>

        <div style={{ ...styles.dateTitle, minWidth: isMobile ? 0 : 120 }}>{period.label}</div>

        <div className="p-hide" style={styles.dateButton} onClick={() => props.goToDate(period.nextDate, period.duration)}>
          <MdChevronRight size={16} />
        </div>
      </div>

      <Button white rise shadow={false} onClick={() => props.goToDate(moment(), period.duration)} style={{ marginLeft: 12 }}>
        Today
      </Button>

    </div>
  );
};

export default TimePeriodControl;
