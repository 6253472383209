import * as api from 'lib/api';
import { watchTimesheetMessages, sendTimesheetMessage, watchPaymentRuns as watchPayRuns, fetchPaymentRunSummaries as fetchPaymentRunSummariesApi } from 'lib/api/timesheets';
import { updateBooking } from '../reducers/jobs';
import * as timesheetsReducers from '../reducers/timesheets';
import * as CONSTANTS from '../routes/Timesheets/timesheet-constants';

export function approveTimesheet(timesheetKey, shiftKey, candidateKey) {
  return async (dispatch, getState) => {
    dispatch(timesheetsReducers.isApprovingTimesheets(timesheetKey));

    const response = await api.post('timesheets/approve', { timesheetKey });
    if (response.status > 200) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
      return;
    }

    const { timesheets } = getState();
    const filterValue = timesheets.timesheetFilters.status;

    const updatedTimesheets = timesheets.timesheets.map((timesheet) => {
      if (timesheet.key === timesheetKey) {
        // Remove if updated timesheet no longer matches current filters
        if (filterValue === CONSTANTS.STATUSES.REJECTED || filterValue === CONSTANTS.STATUSES.PENDING_APPROVAL) {
          return null;
        }
        // Otherwise update timesheet in list
        return { ...timesheet, status: 'approved' };
      }
      // else do nothing
      return timesheet;
    }).filter(Boolean);

    dispatch(updateBooking(shiftKey, candidateKey, { timesheetStatus: 'approved' }));

    dispatch(timesheetsReducers.updateTimesheets(updatedTimesheets));
  };
}

export function rejectTimesheet(timesheetKey, shiftKey, candidateKey, rejectReason) {
  return async (dispatch, getState) => {
    dispatch(timesheetsReducers.isRejectingTimesheets(timesheetKey));

    const response = await api.post('timesheets/reject', { rejectReason, timesheetKey });
    if (response.status > 200) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
      return;
    }

    const { timesheets } = getState();
    const filterValue = timesheets.timesheetFilters.status;

    const updatedTimesheets = timesheets.timesheets.map((timesheet) => {
      if (timesheet.key === timesheetKey) {
        // Remove if updated timesheet no longer matches current filters
        if (filterValue === CONSTANTS.STATUSES.APPROVED || filterValue === CONSTANTS.STATUSES.PENDING_APPROVAL) {
          return null;
        }
        // Otherwise update timesheet in list
        return { ...timesheet, status: 'rejected' };
      }
      // else do nothing
      return timesheet;
    }).filter(Boolean);

    dispatch(updateBooking(shiftKey, candidateKey, { timesheetStatus: 'rejected' }));

    dispatch(timesheetsReducers.updateTimesheets(updatedTimesheets));
  };
}

function calculateTimesheetsTotalCount({ currentTotal, offset, limit, timesheetsLength }) {

  // If both offset and total count are zero (first fetch)
  // or if limit is more than currentTotal, then return timesheets length from response
  if ((offset === 0 && currentTotal === 0) || (limit > currentTotal)) return timesheetsLength;

  // If offset >= currentTotal, return timesheetsLength from response + currentTotal
  if (offset >= currentTotal) return currentTotal + timesheetsLength;

  // Otherwise return currentTotal
  return currentTotal;
}

export function fetchTimesheets() {
  return async (dispatch, getState) => {
    dispatch(timesheetsReducers.fetchTimesheets());
    const timesheets = getState().timesheets;
    const {
      candidate,
      adminGroup,
      status,
      speciality,
      site,
    } = timesheets.timesheetFilters;

    const { timesheetsPaginationsOffset, timesheetsPaginationLimit, timesheetsTotalCount } = timesheets;

    const response = await api.post('timesheets/search', {
      status,
      candidate,
      adminGroup,
      speciality,
      site,
      offset: timesheetsPaginationsOffset,
      limit: timesheetsPaginationLimit,
    });
    if (!response?.body?.timesheets) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
      return;
    }

    const timesheetsLength = response.body.timesheets.length;

    const updatedTotalCount = calculateTimesheetsTotalCount({
      currentTotal: timesheetsTotalCount,
      offset: timesheetsPaginationsOffset,
      limit: timesheetsPaginationLimit,
      timesheetsLength,
    });

    dispatch(timesheetsReducers.addTimesheets(response.body.timesheets, response.body.moreRowsAvailable, updatedTotalCount));

  };
}

export function fetchTimesheetRejectReasons() {
  return async (dispatch) => {
    dispatch(timesheetsReducers.fetchingTimesheetRejectReasons());
    const response = await api.get('timesheets/reject-reasons');
    if (!response.body.success) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
    } else {
      dispatch(timesheetsReducers.addTimesheetRejectReasons(response?.body?.timesheetRejectReasons));
    }
  };
}

export function fetchCandidateRatingCategories(candidateKey) {
  return async (dispatch) => {
    dispatch(timesheetsReducers.fetchingCandidateRatingCategories());
    const response = await api.get('rating/categories', { candidateKey });
    if (!response.body.success) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
    } else {
      dispatch(timesheetsReducers.addCandidateRatingCategories(response?.body?.orgRatingCategories));
    }
  };
}

export function submitCandidateRatings({ ratings, comment, shiftKey, candidateKey }) {
  return async (dispatch) => {
    dispatch(timesheetsReducers.submittingCandidateRatings());
    const response = await api.post('candidate-rating/submit', { ratings, comment: comment || null, shiftKey, candidateKey });
    if (!response?.body?.success) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
    } else {
      const submittedRatings = {};
      ratings.forEach((rating) => {
        submittedRatings[rating.key] = rating.rating;
      });
      dispatch(updateBooking(shiftKey, candidateKey, { submittedRatings, submittedReview: comment }));
      dispatch(timesheetsReducers.submitRatingsSuccess());
    }
  };
}

export function watchMessages(timesheetKey) {
  return async (dispatch) => {
    dispatch(timesheetsReducers.fetchTimesheetMessages());

    return watchTimesheetMessages({
      timesheetKey,
      callback: (res) => {
        dispatch(timesheetsReducers.addTimesheetMessages(res?.data?.timesheet_timesheet_messages ?? [])); // eslint-disable-line camelcase
      },
      error: (err) => {
        console.error(err);
        dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
      },
    });
  };
}

export function fetchPaymentRunSummaries() {
  return async (dispatch) => {
    dispatch(timesheetsReducers.fetchingPaymentRuns());
    try {
      const response = await fetchPaymentRunSummariesApi();
      dispatch(timesheetsReducers.setPaymentRuns(response.paymentRunSummaries));
    } catch (err) {
      dispatch(timesheetsReducers.paymentRunListViewError(err.message));
    }
  };
}

export function watchPaymentRuns() {
  return (dispatch) => {
    dispatch(timesheetsReducers.fetchingPaymentRuns());
    return watchPayRuns({
      callback: paymentRuns => dispatch(timesheetsReducers.setPaymentRuns(paymentRuns)),
      error: err => dispatch(timesheetsReducers.paymentRunListViewError(err.message)),
    });
  };
}

export function sendMessage(timesheetKey, messageText) {
  return async (dispatch) => {
    const payload = { messageText, timesheetKey };
    dispatch(timesheetsReducers.sendingMessage());
    const response = await sendTimesheetMessage(payload);

    if (!response.body.success) {
      dispatch(timesheetsReducers.timesheetsError('Something went wrong. Please try again.'));
    } else {
      dispatch(timesheetsReducers.sendMessageSuccess());
    }
  };
}
