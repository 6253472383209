import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import spacing from '../../config/spacing';

const styles = {
  content: {
    width: 400,
    padding: 12,
    fontSize: '0.9rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.cavalry.line}`,
    padding: 12,
  },
  textContent: {
    color: colors.text,
    marginBottom: 12,
  },
};


export default function ConfirmModal(props) {
  const { isOpen, onClose, header, confirmFunction, cancelFunction } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={header}
      contentStyle={styles.modalContent}
      header={props.header}
    >
      <div style={styles.content}>
        {props.textContent && <p style={styles.textContent}>{props.textContent}</p>}
      </div>
      <div className="space-children-12" style={styles.buttonContainer}>
        <Button outline white onClick={cancelFunction}>
          Cancel
        </Button>
        <Button onClick={confirmFunction}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

const { bool, func, string } = PropTypes;
ConfirmModal.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  header: string,
  cancelFunction: func,
  confirmFunction: func,
  textContent: string,
};

ConfirmModal.defaultProps = {
  isOpen: false,
  header: '',
  cancelFunction: () => {},
  confirmFunction: () => {},
  textContent: undefined,
};
