import React from 'react';
import moment from 'moment';
import Button from 'components/Button';
import colors from 'config/colors';
import { convertFromUtcToTimezone, minsToHours } from 'lib/helpers-time';
import { permissions } from 'config/permissions';
import Feature from 'components/Feature';
import Loading from 'components/Loading';
import { StartEndMultiTimesheetSubmission } from './StartEndMultiTimesheetSubmission';

const formatTime = (date, timezone) => convertFromUtcToTimezone(date, timezone).format('HH:mm');

export const TimesheetSubmission = (props) => {
  const { paidAt, timesheetStatus, submittedStartTime, submittedEndTime, submittedOnSiteTime, submittedOffSiteTime, breaks, witnessName, witnessRole, canManageTimesheets, timesheetIsLocked } = props.booking;
  const { timezone } = props.shift;
  const approved = timesheetStatus === 'approved';
  const rejected = timesheetStatus === 'rejected';

  const breaksTotal = breaks?.length ? breaks.reduce((acc, curr) => {
    const breakStart = convertFromUtcToTimezone(curr.start, timezone);
    const breakEnd = convertFromUtcToTimezone(curr.end, timezone);
    const diff = breakEnd.diff(breakStart, 'minutes');
    return diff + acc;
  }, 0) : 'None';

  const candidateHasSubmittedStartTime = (submittedStartTime || submittedOnSiteTime) || false;
  const candidateHasSubmittedEndTime = (submittedEndTime || submittedOffSiteTime) || false;
  const buttonDisabled = props.isApprovingTimesheets || props.isRejectingTimesheets || (props.shiftAdminGroups && !props.adminIsInCareGroup);
  const submittedStartTimeIsSame = props.shift.timesheetTypeKey === 'start-end' && submittedStartTime ? moment(props.shift.startTime).isSame(moment(submittedStartTime)) : true;
  const submittedEndTimeIsSame = props.shift.timesheetTypeKey === 'start-end' && submittedEndTime ? moment(props.shift.endTime).isSame(moment(submittedEndTime)) : true;

  const expectedTotalTime = moment(props.shift.endTime).diff(moment(props.shift.startTime), 'minutes');
  let actualTotalTime = null;
  let totalTimesAreSame = true;
  if (props.candidateHasSubmittedTimesheet) {
    actualTotalTime = props.shift.timesheetTypeKey === 'start-end' ? minsToHours(moment(submittedEndTime).diff(moment(submittedStartTime), 'minutes')) : minsToHours(submittedOnSiteTime + submittedOffSiteTime);

    totalTimesAreSame = props.shift.timesheetTypeKey === 'start-end' ? moment(submittedEndTime).diff(moment(submittedStartTime), 'minutes') === expectedTotalTime : (submittedOnSiteTime + submittedOffSiteTime) === expectedTotalTime;
  }

  const showApproveButton = (!approved && !paidAt && canManageTimesheets) && (!timesheetIsLocked || props.canManageLockedTimesheets);
  const showRejectButton = (!rejected && !paidAt && canManageTimesheets) && (!timesheetIsLocked || props.canManageLockedTimesheets);

  return (
    <div className="timesheet-submission">
      <h4 className="timesheet__header">Timesheet Submission</h4>
      {!props.candidateHasSubmittedTimesheet && <p className="timesheet-submission__info-text">The user has not yet submitted this timesheet.</p>}
      {props.candidateHasSubmittedTimesheet && (
        <>
          {props.shift.timesheetTypeKey === 'start-end-multi' ?
            <StartEndMultiTimesheetSubmission
              submittedMultiTimes={props.booking.submittedMultiTimes}
              shiftStartTime={props.shift.startTime}
              shiftEndTime={props.shift.endTime}
              witnessName={witnessName}
              witnessRole={witnessRole}
              timezone={timezone}
            />
            :
            <div className="submission-table">
              <div className="submission-table__header">
                <div className="submission-table__header-item" />
                <div className="submission-table__header-item">
                  <p className="submission-table__header-text">Grade</p>
                </div>
                <div className="submission-table__header-item">
                  <p className="submission-table__header-text">{props.shift.timesheetTypeKey === 'start-end' ? 'Start Time' : 'On-Site'}</p>
                </div>
                <div className="submission-table__header-item">
                  <p className="submission-table__header-text">{props.shift.timesheetTypeKey === 'start-end' ? 'End Time' : 'Off-Site'}</p>
                </div>
                <div className="submission-table__header-item">
                  <p className="submission-table__header-text">Total Time</p>
                </div>
                {props.shift.timesheetTypeKey === 'start-end' && (
                  <div className="submission-table__header-item">
                    <p className="submission-table__header-text">Breaks</p>
                  </div>
                )}
              </div>
              <div className="submission-table__row">
                <div className="submission-table__header-item">
                  <p className="submission-table__header-text">Expected</p>
                </div>
                <div className="submission-table__row-item">
                  <p className="submission-table__row-text">{props.shift?.gradeName ?? 'Any'}</p>
                </div>
                <div className="submission-table__row-item">
                  <p className="submission-table__row-text">{props.shift.timesheetTypeKey === 'start-end' ? formatTime(props.shift.startTime, timezone) : 'N/A'}</p>
                </div>
                <div className="submission-table__row-item">
                  <p className="submission-table__row-text">{props.shift.timesheetTypeKey === 'start-end' ? formatTime(props.shift.endTime, timezone) : 'N/A'}</p>
                </div>
                <div className="submission-table__row-item">
                  <p className="submission-table__row-text">{minsToHours(expectedTotalTime)}</p>
                </div>
                {props.shift.timesheetTypeKey === 'start-end' && (
                  <div className="submission-table__row-item">
                    <p className="submission-table__row-text" />
                  </div>
                )}
              </div>
              <div className="submission-table__row">
                <div className="submission-table__row-item">
                  <p className="submission-table__header-text">Submitted</p>
                </div>
                <div className="submission-table__row-item">
                  <p className="submission-table__row-text">{props?.booking?.gradeName}</p>
                </div>
                <div className="submission-table__row-item">
                  <p className={`submission-table__row-text${!submittedStartTimeIsSame ? '--red' : ''}`}>{candidateHasSubmittedStartTime ? ((submittedStartTime && formatTime(submittedStartTime, timezone)) || minsToHours(submittedOnSiteTime)) : 'None'}</p>
                </div>
                <div className="submission-table__row-item">
                  <p className={`submission-table__row-text${!submittedEndTimeIsSame ? '--red' : ''}`}>{candidateHasSubmittedEndTime ? ((submittedEndTime && formatTime(submittedEndTime, timezone)) || minsToHours(submittedOffSiteTime)) : 'None'}</p>
                </div>
                <div className="submission-table__row-item">
                  <p className={`submission-table__row-text${!totalTimesAreSame ? '--red' : ''}`}>{actualTotalTime}</p>
                </div>
                {props.shift.timesheetTypeKey === 'start-end' && (
                  <div className="submission-table__row-item">
                    <p className="submission-table__row-text">{`${breaksTotal}${breaksTotal === 'None' ? '' : 'mins'}`}</p>
                  </div>
                )}
              </div>
              {witnessName && witnessRole && (
                <div>
                  <p className="submission-table__header-text">Witnessed By: {witnessName} ({witnessRole})</p>
                </div>
              )}
            </div>
          }
          {!!(canManageTimesheets && props.timesheetApprovalDisclaimer) && (
            <div>
              <p className="timesheet-submission__disclaimer-heading">By approving or rejecting a timesheet you are agreeing to the following:</p>
              <p className="timesheet-submission__disclaimer-message">{props.timesheetApprovalDisclaimer}</p>
            </div>
          )}
          <div className="timesheet-submission__buttons">
            <p className="timesheet-permission-message">{props.timesheetPermissionsMessage}</p>
            {(props.isApprovingTimesheets || props.isRejectingTimesheets) && <Loading size={30} />}
            {showRejectButton && (
              <Feature permissionRequired={permissions.TIMESHEETS_CAN_REJECT}>
                <Button
                  style={{ marginLeft: '3px', marginRight: '3px' }}
                  danger
                  large
                  onClick={() => props.openRejectModal()}
                  disabled={buttonDisabled}
                >
                  Reject
                </Button>
              </Feature>
            )}
            {showApproveButton && (
              <Feature permissionRequired={permissions.TIMESHEETS_CAN_APPROVE}>
                <Button
                  large
                  onClick={() => props.approveTimesheet(props.booking.timesheetKey, props.booking.shiftKey, props.booking.candidateKey)}
                  disabled={buttonDisabled}
                >
                  Approve
                </Button>
              </Feature>
            )}
          </div>
        </>
      )}
    </div>
  );
};
