import React from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import { formatUKTel, formatUKMobile } from '../../lib/helpers-telephone';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import TextSectionHeader from '../../components/TextSectionHeader';

const { object, string, shape, number } = PropTypes;

const styles = {
  mainContainer: {
    overflowY: 'scroll',
    boxSizing: 'border-box',
    maxHeight: 470,
    width: '90%',
    margin: '0 auto',
  },
  paneLeft: {
    width: '40%',
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    padding: '0 5px',
  },
  paneRight: {
    width: '60%',
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    padding: '0 5px',
  },
  labelContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    height: spacing.xxxlarge,
    width: '30%',
    textAlign: 'left',
  },
  label: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: spacing.small,
    color: colors.grays.light,
  },
  valueContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    height: spacing.xxxlarge,
    width: '70%',
  },
  value: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: spacing.small,
    color: colors.positive,
    fontFamily: fonts.main.family,
    fontSize: fonts.main.size,
  },
  name: {
    fontSize: fonts.large.size,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: spacing.small,
    color: colors.positive,
    fontFamily: fonts.main.family,
  },
};

export default function SupplierContact(props) {

  const { firstName, surname, email, mobile, phone } = props.contactData;
  const height = { height: props.screen.height * 0.6 };

  return (
    <div style={{ ...styles.mainContainer, ...height }}>
      <TextSectionHeader text="Contact Details" uppercase />
      <div>

        <div style={styles.paneLeft}>
          <div>
            <div style={styles.labelContainer}>
              <div style={styles.label}>Name</div>
            </div>
            <div style={styles.valueContainer}>
              <div style={styles.name}>{`${firstName} ${surname}`}</div>
            </div>
          </div>
        </div>

        <div style={styles.paneRight}>
          <div>
            <div style={styles.labelContainer}>
              <div style={styles.label}>Telephone</div>
            </div>
            <div style={styles.valueContainer}>
              <div style={styles.value}>{formatUKTel(phone)}</div>
            </div>
          </div>

          <div>
            <div style={styles.labelContainer}>
              <div style={styles.label}>Mobile</div>
            </div>
            <div style={styles.valueContainer}>
              <div style={styles.value}>{formatUKMobile(mobile)}</div>
            </div>
          </div>

          <div>
            <div style={styles.labelContainer}>
              <div style={styles.label}>email</div>
            </div>
            <div style={styles.valueContainer}>
              <div style={styles.value}>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );

}

SupplierContact.propTypes = {
  contactData: object,
  screen: shape({
    width: number.isRequired,
    height: number.isRequired,
    size: string.isRequired,
  }).isRequired,
};

SupplierContact.defaultProps = {
  contactData: {},
};
