import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import SelectedItem from '../../components/SelectedItem';
import UnselectedItem from '../../components/UnselectedItem';
import Button from '../../components/Button';
import colors from '../../config/colors';

export default function RGSEdit(props) {
  function sort(a, b) {
    const aRank = props.available[a] ? props.available[a].order : 0;
    const bRank = props.available[b] ? props.available[b].order : 0;

    return aRank - bRank;
  }

  const available = props.filteredAvailable.length > 0 ? props.filteredAvailable : Object.keys(props.available);
  const selected = props.filteredSelected.length > 0 ? props.filteredSelected : Object.keys(props.selected);
  available.sort(sort);
  selected.sort(sort);
  const availableAndNotInEdited = available.map((id) => {
    if ((props.editedRGS[id] && !props.editedRGS[id].enabled) ||
      (!props.editedRGS[id] && (!props.selected[id] || !props.selected[id].enabled))) {
      return {
        ...props.available[id],
        id,
      };
    }

    return null;
  }).filter(Boolean);

  const showSearchAvailable = Object.keys(props.available).filter((id) => {
    if ((props.editedRGS[id] && !props.editedRGS[id].enabled) ||
      (!props.editedRGS[id] && (!props.selected[id] || !props.selected[id].enabled))) {

      return true;
    }

    return false;
  });
  const selectedAndNotInEdited = selected.map((id) => {
    const item = {
      ...props.selected[id],
      name: props.data && props.data[id] ? props.data[id].name : '',
      id,
    };

    if ((props.editedRGS[id] && props.editedRGS[id].enabled) ||
      (!props.editedRGS[id] && item.enabled)) {
      return item;
    }

    return null;
  }).filter(Boolean);

  return (
    <div style={{ height: 460, backgroundColor: colors.white }}>
      <div className="gsDivider">
        <div className="gsLeftContainer">
          <h3>
Available
            {` ${props.type[0].toUpperCase()}${props.type.slice(1)}`}
          </h3>
          {showSearchAvailable.length > 10 ? <input
            type="text"
            onChange={props.onAvailableSearchChange}
            className="searchRGS"
            placeholder={`Search ${props.type}...`}
          /> : null}
          {availableAndNotInEdited.length > 0 ? (
            <FlipMove disableAllAnimations={props.disableAnimation}>
              {availableAndNotInEdited.map((item) => {
                return (
                  <UnselectedItem
                    key={`${item.id}-available`}
                    title={item.name}
                    onClick={() => props.onSelect(item.id, props.type)}
                  />
                );
              })}
            </FlipMove>
          )
            :
            <p style={{ marginLeft: 10 }}>
No available
              {` ${props.type}`}
            </p>}
        </div>
        <div className="gsRightContainer">
          <h3>
Selected
            {` ${props.type[0].toUpperCase()}${props.type.slice(1)}`}
          </h3>
          {selectedAndNotInEdited.length > 10 ? <input
            type="text"
            onChange={props.onSelectedSearchChange}
            className="searchRGS"
            placeholder={`Search ${props.type}...`}
          /> : null}
          {selectedAndNotInEdited.length > 0 ? (
            <FlipMove disableAllAnimations={props.disableAnimation}>
              {selectedAndNotInEdited.map((item) => {
                return (
                  <SelectedItem
                    key={`${item.id}-selected`}
                    title={item.name}
                    onClick={() => props.onUnselect(item.id, props.type)}
                  />
                );
              })}
            </FlipMove>
          )
            :
            <p style={{ marginLeft: 5 }}>
No selected
              {` ${props.type}`}
            </p>}
        </div>
      </div>
      <div style={{ float: 'right', marginTop: 10 }}>
        <Button
          onClick={() => props.discardChanges(props.type)}
          style={{ marginRight: 10, backgroundColor: colors.negative }}
          className="test-id-rgs-edit-cancel"
        >
          <p>Cancel</p>
        </Button>
        <Button
          onClick={() => props.saveChanges(props.type)}
          disabled={Object.keys(props.editedRGS).length < 1}
        >
          <p>Save Changes</p>
        </Button>
      </div>
    </div>
  );
}

const { string, objectOf, object, func, arrayOf, bool } = PropTypes;
RGSEdit.propTypes = {
  type: string.isRequired,
  data: objectOf(object).isRequired,
  available: objectOf(object).isRequired,
  selected: objectOf(object).isRequired,
  onSelect: func.isRequired,
  onUnselect: func.isRequired,
  editedRGS: objectOf(object).isRequired,
  saveChanges: func.isRequired,
  onAvailableSearchChange: func.isRequired,
  filteredAvailable: arrayOf(string).isRequired,
  filteredSelected: arrayOf(string).isRequired,
  discardChanges: func.isRequired,
  onSelectedSearchChange: func.isRequired,
  disableAnimation: bool.isRequired,
};
