import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchComplianceItem } from 'thunks/compliance';
import { EvidenceCategory } from 'types/ComplianceTypes';

import ComplianceEvidenceCategory from './ComplianceEvidenceCategory';

interface ComplianceItemProps {
  itemKey: string,
  candidateKey: string,
  adminHasWriteAccess: boolean,
  fetchItemsAndUpdateListView: () => void,
}

export default function ComplianceItem(props: ComplianceItemProps): React.ReactElement {

  const dispatch = useDispatch();

  const item = useSelector(state => state.compliance.item);

  useEffect(() => {
    dispatch(fetchComplianceItem(props.itemKey, props.candidateKey));
  }, [props.itemKey]);

  return (
    <div style={{ overflowY: 'auto' }}>
      {(item?.evidenceCategories ?? []).map((category: EvidenceCategory) => {
        return (
          <ComplianceEvidenceCategory
            key={category.key}
            itemKey={props.itemKey}
            candidateKey={props.candidateKey}
            category={category}
            adminHasWriteAccess={props.adminHasWriteAccess}
            fetchItemsAndUpdateListView={props.fetchItemsAndUpdateListView}
          />
        );
      })}
    </div>
  );
}

