import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import './StandardEventComponent.scss';

const Moment = moment().constructor;

export default function StandardEventComponent({ event, date, parentClassName }) {

  // If there are no event on a day, then don't render anything
  if (event.totalShifts === 0) return null;

  const classes = classNames(
    'standardEventComponent',
    parentClassName,
    {
      unfilled: event.slotsFilled < event.totalSlots,
      filled: event.slotsFilled >= event.totalSlots,
      past: date.isBefore(moment.utc()),
    },
  );

  return (
    <div className="standardEventComponentContainer">
      <div className={classes}>
        <div className="standardEventComponentContent shadow">{`${event.slotsFilled}/${event.totalSlots} filled`}</div>
      </div>
    </div>
  );
}

const { number, string, instanceOf, shape } = PropTypes;

StandardEventComponent.propTypes = {
  event: shape({ filled: number, totalShifts: number }),
  parentClassName: string,
  date: instanceOf(Moment).isRequired,
};

StandardEventComponent.defaultProps = {
  parentClassName: '',
  event: { filled: 0, totalShifts: 0 },
};
