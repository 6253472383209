import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaRegBell } from 'react-icons/fa';

import colors from 'config/colors';
import { usePrevious } from 'hooks/previous';
import './styles.css';

const styles = {
  bellStyle: {
    opacity: 1,
  },
};

export default function NotificationBell(props) {

  const notifications = useSelector(state => state.notifications.notifications);
  const notificationCount = notifications?.slice(0, 20)?.filter(n => !n.read)?.length;

  const [newNotification, setNewNotification] = useState(false);
  const prevNotificationCount = usePrevious(notificationCount);

  useEffect(() => {
    // If there is a new notification set newNotification to true and
    // update the state for 5s (the length of the ring animation).
    if (prevNotificationCount && notificationCount && prevNotificationCount < notificationCount) {
      // Setting state in didUpdate is dirty but this seems to work nicely
      setNewNotification(true);
      setTimeout(() => setNewNotification(false), 5000);
    }
  }, [notificationCount]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className="notificationBellContainer"
      onClick={(event) => {
        props.onClick?.(event);
        event.stopPropagation();
      }}
    >
      <a className="sideMenuItem">
        <div style={{ position: 'relative', paddingRight: 18 }}>
          <FaRegBell
            style={styles.bellStyle}
            className={classNames('nav-icon', { swing: newNotification && notificationCount > 0 })}
            size={25}
            color={colors.primary}
          />
          {notificationCount > 0 ? <div className="sideMenuBadgeCount">{notificationCount}</div> : null}
        </div>
        <div>{(props.showText) && 'Notifications'}</div>
      </a>
    </div>
  );
}

const { bool, func, number } = PropTypes;
NotificationBell.propTypes = {
  showText: bool,
  onClick: func.isRequired,
};

NotificationBell.defaultProps = {
  showText: false,
};
