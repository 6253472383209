import * as api from '.';

export async function updatePolicies(payload) {
  const response = await api.post('account/policies/update', payload);
  if (response.status >= 400) throw new Error(response.error);
}

export async function updateRGSConfig(payload) {
  const response = await api.post('account/rgsConfig/update', payload);
  if (response.status >= 400) throw new Error(response.error);
}

export async function updateGradesAndSpecialities(payload) {
  const response = await api.post('account/changeSpecialitiesByGrade', payload);

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Updating specialities failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function fetchAdmins() {
  const response = await api.get('admin/list');
  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching admins failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function fetchAdminGroups() {
  const response = await api.post('user/admin-groups');
  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching admin groups failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function fetchOrgMetadata() {
  const response = await api.get('account/org-metadata/get');

  if (response.status === 200) return response.body;
  if (response.status === 401 && response.body?.details?.passwordExpired) return response.body.details;

  const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching org metadata failed';
  throw (error instanceof Error ? error : new Error(error));
}

export async function fetchShiftCreationMetadata() {
  const response = await api.get('account/org-metadata/get-shift-creation');
  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching shift creation metadata failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function fetchCandidateProfile(candidateKey) {
  const response = await api.get('account/candidate/get', { candidateKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching candidate failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function getCandidateComplianceMetadata(payload) {
  const response = await api.post('account/candidate/getComplianceMetadata', payload);

  if (response.status >= 400) throw new Error(response.error);

  return response.body;
}

export async function fetchCandidateRatingsSummary(candidateKey) {
  const response = await api.get('rating/fetch-candidate-summary', { candidateKey });

  if (response.status >= 400) throw new Error(response.error);

  return response.body;
}

export async function fetchCandidateRatingsDetail(candidateKey, limit, offset) {
  const response = await api.get('rating/fetch-candidate-detail', { candidateKey, limit, offset });

  if (response.status >= 400) throw new Error(response.error);

  return response.body;
}

export async function fetchSites({ mine, adminKey }) {
  const response = await api.post('account/site/fetch', { mine, adminKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching sites failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}


export async function fetchPaymentRates() {
  const response = await api.get('account/payment-rates/list');

  if (response.status >= 400 || !response.body?.paymentRates) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching sites failed';
    throw (error instanceof Error ? error : new Error(typeof error === "string" ? error : JSON.stringify(error)));
  }

  return response.body;
}

export async function fetchCandidateAuditTrail(candidateKey) {
  const response = await api.get('account/candidate/audit-trail', { candidateKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching audit trail failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function fetchServicePermissions(adminKey) {
  const response = await api.get('services/list', { adminKey });

  if (response.body?.services) return response.body;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to fetch services. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function fetchAdminGeneralPermissions(adminKey) {
  const response = await api.get('permissions/get', { adminKey });

  if (response.body?.permissions) return response.body;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to fetch admin permissions. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function updateAdminServices(services, adminKey) {
  const response = await api.post('account/user/update-services', { services, adminKey });
  if (response.body?.success) return response.body;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to update services. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function fetchStaffContract(candidateKey) {
  const response = await api.get('staff-contracts/get', { candidateKey });

  if (response.body?.staffContract !== undefined) return response.body;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to fetch staff contract. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function createStaffContract(candidateKey, fields) {
  const response = await api.post('staff-contracts/create', { candidateKey, fields });

  if (response.body?.staffContract !== undefined) return response.body;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to create staff contract. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function updateStaffContract(contractKey, fields) {
  const response = await api.post('staff-contracts/update', { contractKey, fields });

  if (response.body?.staffContract !== undefined) return response.body;

  const error = response?.body?.details?.humanReadableErrorMessage ?? response?.body?.humanReadableErrorMessage ?? 'Failed to update staff contract. If the problem persists, please contact technical support.';
  throw new Error(error);
}

export async function fetchAdminProfile(adminKey) {
  const response = await api.get('admin/get', { targetAdminKey: adminKey });
  if (response.status === 200 && response.body?.targetAdmin) return response.body;
  const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching admin profile failed';
  throw (error instanceof Error ? error : new Error(error));
}

export async function updateAdminEnableStatus(adminKey, isEnabled) {
  const response = await api.post('admin/set-enabled-disabled', { adminKey, isEnabled });
  if (response.status === 200) return response.body;
  const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Updating admin enabled status failed';
  throw (error instanceof Error ? error : new Error(error));
}

export async function updateAdmin(adminKey, updates) {
  const response = await api.post('admin/update', { adminKey, ...updates });
  if (response.status === 200) return response.body;
  const error = response.body?.details?.humanReadableErrorMessage ?? response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Updating admin failed';
  throw new Error(error);
}
