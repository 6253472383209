import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';

import { convertToSelect } from 'lib/helpers';

import { Reason } from 'types/Metadata';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface ReasonFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  reason: { id: string, name: string } | null,
  mandatory?: boolean
}

function ReasonField(props: ReasonFieldProps) {

  const reasons = useSelector(({ global }) => global.shiftCreateMetadata?.orgShiftReasons ?? []);

  const reasonsInSortOrder = useMemo(() => {
    return reasons.sort(firstBy('name')).map((reason: Reason) => convertToSelect(reason));
  }, [reasons]);

  return (
    <FieldLabel label="Reason">
      <JobsSelectBox
        name="reason1"
        value={props.reason}
        placeholder={props.mandatory ? 'Reason *' : 'Reason (optional)'}
        data={reasonsInSortOrder}
        onChange={props.onChange}
        mandatory={props.mandatory}
        clearable={!props.mandatory}
      />
    </FieldLabel>
  );
}

ReasonField.defaultProps = { mandatory: false };

export default React.memo(ReasonField);
