import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import colors from '../../config/colors';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '24px',
    marginBottom: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '2px',
    boxSizing: 'border-box',
    padding: 6,
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    backgroundSize: 'contain',
  },
  devBanner: {
    background: '#bb445e',
    color: 'white',
    margin: '-6px',
    marginTop: '6px',
    borderRadius: '2px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    padding: '5px 6px 3px 6px',
    boxSizing: 'border-box',
    lineHeight: 1,
    fontSize: '14px',
  },
};

const OrgLogo = ({ isOpen, isDevEnvironment, orgLogoUrl, orgName, padding, backgroundColor }) => {

  const devBannerStyles = {
    ...styles.devBanner,
    borderTopLeftRadius: backgroundColor === 'transparent' ? 2 : 0,
    borderTopRightRadius: backgroundColor === 'transparent' ? 2 : 0,
    margin: -padding,
    marginTop: padding,
  };

  if (!isOpen || !orgLogoUrl) {
    return null;
  }

  return (
    <div style={{ ...styles.container, padding, backgroundColor }}>
      <img
        src={orgLogoUrl}
        alt={orgName}
        style={styles.image}
      />
      {isDevEnvironment ? <div style={devBannerStyles}>Dev Env</div> : null}
    </div>
  );
};

const { bool, string, number } = PropTypes;
OrgLogo.propTypes = {
  isOpen: bool,
  orgName: string,
  orgLogoUrl: string,
  backgroundColor: string,
  padding: number,
};

OrgLogo.defaultProps = {
  isOpen: false,
  orgName: '',
  orgLogoUrl: '',
  backgroundColor: colors.white,
  padding: 6,
};

function mapStateToProps({ global }) {
  return {
    orgName: global.orgConfig?.name,
    orgLogoUrl: global.orgConfig?.orgLogoUrl,
    isDevEnvironment: global.orgConfig?.isDevEnvironment,
    backgroundColor: global.orgConfig?.orgLogoBackgroundColor,
    padding: global.orgConfig?.orgLogoPadding,
  };
}
export default connect(mapStateToProps)(OrgLogo);
