import React from 'react';
import PropTypes from 'prop-types';
import RGSEditContainer from './RGSEditContainer';
import NewRoleModal from './NewRoleModal';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

const modalContentStyles = {
  width: '80%',
  overflow: 'hidden',
  paddingTop: 0,
  padding: spacing.small,
  backgroundColor: colors.blueVeryLight,
};

const styles = {
  title: {
    paddingTop: spacing.large,
    fontFamily: fonts.main.family,
    fontSize: fonts.large.size,
    color: colors.positive,
    textAlign: 'center',
  },
};

export default function RGSManage(props) {
  const modalHeight = { height: props.screen.height * 0.9 };
  const selectedRole = props.roles && props.selectedRole ? props.roles[props.selectedRole] : null;
  const orgSelectedRole = props.orgRoles && props.selectedRole ? props.orgRoles[props.selectedRole] : null;

  return (
    <div>
      <div className="hideWhenModalOpens">
        {props.orgRoles && props.roles && Object.keys(props.orgRoles).map((id, index) => {
          const role = {
            ...props.orgRoles[id],
            name: props.roles[id] ? props.roles[id].name : '',
          };

          if (role.enabled) {
            return (
              <div className="roleContainer" key={id}>
                <div className="roleName">
                  <h3>{role.name}</h3>
                </div>
                <span>
                  <Button
                    small
                    rise
                    onClick={() => props.openEditModal(id)}
                    disabled={!props.canEdit}
                    className={`test-id-role-edit-button-${index}`}
                  >
                    Edit
                  </Button>
                </span>
              </div>
            );
          }

          return null;
        })}

        <Button
          onClick={props.openNewModal}
          small
          rise
          disabled={!props.canEdit}
        >
          + Add New
        </Button>
      </div>

      <Modal
        isOpen={props.editModalOpen}
        onRequestClose={props.onCloseEditModal}
        contetLabel="edit rgs modal"
        contentStyle={{ ...modalContentStyles, ...modalHeight }}
      >
        <h1 style={styles.title}>
Role:
          {selectedRole ? ` ${selectedRole.name[0].toUpperCase()}${selectedRole.name.slice(1)}` : ''}
        </h1>
        <RGSEditContainer
          selectedRole={selectedRole}
          orgSelectedRole={orgSelectedRole}
          grades={props.grades}
          specialities={props.specialities}
          selectedRoleKey={props.selectedRole}
          orgId={props.orgId}
          closeModal={props.onCloseEditModal}
        />
      </Modal>

      <Modal
        isOpen={props.newModalOpen}
        onRequestClose={props.onCloseNewModal}
        contentLabel="new role modal"
        contentStyle={{ ...modalContentStyles, ...modalHeight }}
      >
        <NewRoleModal
          closeModal={props.onCloseNewModal}
        />
      </Modal>
    </div>
  );
}

const { object, objectOf, oneOfType, bool, string, func, shape, number } = PropTypes;
RGSManage.propTypes = {
  orgRoles: objectOf(object),
  roles: objectOf(oneOfType([
    bool,
    string,
    object,
  ])),
  grades: objectOf(object),
  specialities: objectOf(object),
  editModalOpen: bool.isRequired,
  onCloseEditModal: func.isRequired,
  openEditModal: func.isRequired,
  newModalOpen: bool.isRequired,
  onCloseNewModal: func.isRequired,
  openNewModal: func.isRequired,
  selectedRole: string,
  screen: shape({
    width: number.isRequired,
    height: number.isRequired,
    size: string.isRequired,
  }).isRequired,
  orgId: string.isRequired,
  canEdit: bool,
};

RGSManage.defaultProps = {
  selectedRole: null,
  orgRoles: {},
  roles: {},
  grades: {},
  specialities: {},
  canEdit: false,
};
