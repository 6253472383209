import React from 'react';
import moment from 'moment';
import Modal from 'components/Modal';
import Button from 'components/Button';
import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';

export default function ClashBookingModal({ closeClashModal, contentLabel, clashingShifts }) {
  return (
    <Modal isOpen onRequestClose={closeClashModal} contentLabel={contentLabel}>
      <h3 style={{ color: colors.text, fontSize: fonts.headerSub.size, textAlign: 'center', marginBottom: 12 }}>
        This candidate is already booked for shifts during this time period
      </h3>
      {clashingShifts ?
        Object.values(clashingShifts).map(({ key, siteName, areaName, roleName, gradeName, specialityName, startTime, endTime }) => {
          return (
            <p key={key} style={{ marginTop: spacing.small, fontSize: '0.9rem' }}>
              {`${siteName || ''} (${areaName || ''}), ${roleName || 'Any'}, ${gradeName || 'Any'}, ${specialityName || 'Any'}
                at ${startTime ? moment(startTime).format('HH:mm') : ''} - ${endTime ? moment(endTime).format('HH:mm') : ''}.`}
            </p>
          );
        })
        : null
      }
      <div style={{ marginTop: spacing.base, display: 'flex', justifyContent: 'center' }}>
        <Button onClick={closeClashModal}>Close</Button>
      </div>
    </Modal>
  );
}
