import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SettingItem from '../../components/SettingItem';
import spacing from '../../config/spacing';
import fonts from '../../config/fonts';
import colors from '../../config/colors';
import Button from '../../components/Button';

const styles = {
  input: {
    fontFamily: fonts.copy.family,
    fontSize: fonts.copy.size,
    fontWeight: fonts.copy.weight,
    color: colors.text,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.title,
    borderRadius: spacing.tiny,
    marginBottom: spacing.base,
    marginTop: spacing.base,
    padding: spacing.tiny,
    outline: 'none',
  },
  addSiteButton: {
    backgroundColor: colors.green,
    position: 'relative',
    float: 'right',
    margin: spacing.base,
  },
  clearBoth: {
    clear: 'both',
  },
  cancelButton: {
    backgroundColor: colors.negative,
    position: 'relative',
    top: 0,
    marginLeft: spacing.base,
  },
  saveButton: {
    backgroundColor: colors.green,
    position: 'relative',
    top: 0,
    marginLeft: spacing.base,
  },
  hidden: {
    display: 'none',
  },
};

const { bool } = PropTypes;

export default class SitesDetails extends Component {
  static propTypes = {
    canEdit: bool,
    canCreate: bool,
  };

  static defaultProps = {
    canEdit: false,
    canCreate: false,
  };

  render() {

    const { site } = this.props;

    return (
      <>
        <div style={{ padding: 12 }}>
          <h2>{site.name}</h2>
        </div>

        {site.areas?.length && (
          <div style={{ margin: 24 }}>
            <h3>Areas</h3>
            <ul>
              {site.areas.map(area => {
                return <li>{area.name}</li>;
              })}
            </ul>
          </div>
        )}
      </>
    );
  }
}
