import React, { useState, CSSProperties } from 'react';
import { IoIosHelpCircleOutline } from 'react-icons/io';

import colors from 'config/colors';
import Popover from 'components/Popover';
import './HelpIcon.scss';

interface HelpIconProps {
  children: JSX.Element;
  size?: number;
  popoverMaxWidth?: number;
  style?: CSSProperties;
}

const HelpIcon = (props: HelpIconProps) : JSX.Element => {

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  return (
    <Popover
      isOpen={popoverIsOpen}
      setIsOpen={setPopoverIsOpen}
      popoverMaxWidth={props.popoverMaxWidth}
      popoverContent={props.children}
      position="top"
      containerStyle={{ display: 'inline-block', position: 'relative', top: -2, ...props.style }}
    >
      <IoIosHelpCircleOutline style={{ color: colors.cavalry.primary, cursor: 'pointer' }} size={props.size} />
    </Popover>
  );
};

HelpIcon.defaultProps = {
  size: 24,
  popoverMaxWidth: 350,
  style: undefined,
};

export default HelpIcon;

