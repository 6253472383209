import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchPaymentRunSummaries } from 'thunks/timesheets';
import { updatePaymentRun as updatePayRun } from 'lib/api/timesheets';
import PaymentRunForm from './PaymentRunForm';
import { PaymentRunDetailsType, PaymentRunFormFields, PaymentRunDetailsShape } from '../../../types/PaymentRun';

interface EditPaymentRunProps {
  paymentRun: PaymentRunDetailsType,
  onClose: () => void,
  setPaymentRunDetails: (details: PaymentRunDetailsShape) => void,
}

const EditPaymentRun : React.FC<EditPaymentRunProps> = (props: EditPaymentRunProps) => {

  const dispatch = useDispatch();
  const [isUpdatingPaymentRun, setIsUpdatingPaymentRun] = useState(false);
  const [apiError, setApiError] = useState(null);

  const updatePaymentRun = async (fields: PaymentRunFormFields, scheduled: boolean) => {
    const fieldsToUpdate = fields;

    if (!scheduled) {
      fieldsToUpdate.scheduledSendTime = null;
      fieldsToUpdate.scheduledPaymentTime = null;
    }

    // If payment run has already sent, then scheduledPaymentTime is the only field available to update
    const updatePaymentRunPayload = props.paymentRun.sentAt ? { scheduledPaymentTime: fieldsToUpdate.scheduledPaymentTime } : fieldsToUpdate;

    try {
      if (apiError) setApiError(null);
      setIsUpdatingPaymentRun(true);
      const { paymentRun, audit, reports } = await updatePayRun(updatePaymentRunPayload, props.paymentRun.key);
      props.setPaymentRunDetails({ paymentRun, audit, reports });
      await dispatch(fetchPaymentRunSummaries());
      props.onClose();
    } catch (error) {
      setApiError(error.message);
      setIsUpdatingPaymentRun(false);
    }
  };

  return (
    <PaymentRunForm
      submit={updatePaymentRun}
      onClose={props.onClose}
      isLoading={isUpdatingPaymentRun}
      apiError={apiError}
      submittedPaymentRun={props.paymentRun}
      title="Edit Payment Run"
    />
  );
};

export default EditPaymentRun;
