import React from 'react';
import PropTypes, { number } from 'prop-types';
import { firstBy } from 'thenby';
import moment from 'moment';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import Loading from 'components/Loading';
import { MdAccessTime, MdClose, MdCheck } from 'react-icons/md';
import { PaginatedDataTable } from '../../components/DataTable';
import TimesheetStatusPill from './TimesheetStatusPill';
import { permissions } from '../../config/permissions';
import Feature from '../../components/Feature';
import { isOverdue } from './timesheet-helpers';
import colors from '../../config/colors';


const styles = {
  iconStyle: {
    fontSize: 16,
    borderRadius: 50,
    color: colors.white,
    backgroundColor: colors.red,
  },
};

const dataTableColumns = (props) => {
  const columns = [
    {
      name: 'overdue',
      width: 10,
      formatter: (timesheet) => {
        const overdue = isOverdue(timesheet.submittedAt);
        if (timesheet.status === 'pending-approval' && timesheet.submittedAt && overdue) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}><MdAccessTime
              style={styles.iconStyle}
            />
            </div>);
        }
        return null;
      },

    },
    {
      name: 'siteName',
      header: 'Site',
      width: 120,
    },
    {
      name: 'speciality',
      header: 'Speciality',
      width: 120,
      formatter: (speciality) => {
        return props.specialitiesMetadata?.[speciality]?.name ?? 'Any';
      },
    },
    {
      name: 'name',
      header: 'Candidate',
      width: 140,
    },
    {
      name: 'timesheetStatus',
      header: 'Status',
      width: 110,
      formatter: (timesheet) => {
        if (timesheet) { return <TimesheetStatusPill timesheet={timesheet} />; }
        return null;
      },
    },
    {
      name: 'startTime',
      header: 'Date',
      width: 90,
      formatter: (startTime) => {
        return startTime && typeof startTime === 'string' ? moment(startTime).format('Do MMM Y') : 'Unknown';
      },
    },
    {
      name: 'time',
      header: 'Time (Expected / Actual)',
      width: 120,
      formatter: (timesheet) => {
        const {
          actualStartTime,
          actualEndTime,
          startTime,
          endTime,
          timezone,
        } = timesheet;
        const combinedActualStartAndEnd = actualStartTime && actualEndTime ? `${convertFromUtcToTimezone(actualStartTime, timezone).format('HH:mm')} - ${convertFromUtcToTimezone(actualEndTime, timezone).format('HH:mm')}` : 'TBC';
        return `${convertFromUtcToTimezone(startTime, timezone).format('HH:mm')} - ${convertFromUtcToTimezone(endTime, timezone).format('HH:mm')} / ${combinedActualStartAndEnd}`;
      },
    },
    {
      name: 'breaks',
      header: 'Breaks',
      width: 80,
      formatter: (timesheet) => {
        if (timesheet.totalBreakMinutes) return `${timesheet.totalBreakMinutes} mins`;
        return 'TBC';
      },
    },
    {
      name: 'actions',
      header: 'Actions',
      width: 50,
      formatter: (timesheet) => {

        const approved = timesheet.status === 'approved';
        const rejected = timesheet.status === 'rejected';
        const isLocked = timesheet.timesheetIsLocked;
        const adminIsInCareGroup = (timesheet.adminGroups || []).some(adminGroup => props.userAdminGroups.includes(adminGroup));
        const adminCanApproveOrReject = timesheet.canManageTimesheets && (!props.adminGroupsFeatureFlag || adminIsInCareGroup);
        const showApproveButton = (!approved && adminCanApproveOrReject) && (!isLocked || props.canManageLockedTimesheets);
        const showRejectButton = (!rejected && adminCanApproveOrReject) && (!isLocked || props.canManageLockedTimesheets);

        if (timesheet && !timesheet.paidAt && timesheet.status) {
          return (
            <div style={{ display: 'flex' }}>
              {(props.isApprovingTimesheets || props.isRejectingTimesheets) && timesheet.key === props.selectedTimesheetKey ?
                <Loading size={20} />
                :
                <>
                  {!!showRejectButton && (
                    <MdClose
                      style={{ fontSize: 20, color: colors.red, paddingRight: 4 }}
                      onClick={(e) => {
                        props.openRejectModal(timesheet.key, timesheet.candidateKey);
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {!!showApproveButton && (
                    <MdCheck
                      style={{ fontSize: 20, color: colors.positive, paddingLeft: 4 }}
                      onClick={(e) => { props.approveTimesheet(timesheet.key, timesheet.jobId, timesheet.candidateKey); e.stopPropagation(); }}
                    />
                  )}
                </>
              }
            </div>
          );
        }
        return null;
      },
    },
  ];

  if (props.adminGroupsFeatureFlag) {
    const adminGroupColumn = {
      name: 'careGroup',
      header: 'Care Group or Approver',
      width: 120,
      formatter: (timesheet) => {
        const adminGroupsToRender = props.orgAdminGroups.filter(adminGroup => adminGroup && timesheet.adminGroups.includes(adminGroup.key));
        if (!adminGroupsToRender.length) return 'No care group or approver';
        return adminGroupsToRender[0].name + ((adminGroupsToRender.length > 1) ? `, ${adminGroupsToRender.length - 1} more` : '');
      },
    };
    columns.splice(1, 0, adminGroupColumn);
  }

  if (props.servicesFeatureFlag) {
    const adminGroupColumn = {
      name: 'serviceName',
      header: 'Service',
      width: 120,
    };
    columns.splice(2, 0, adminGroupColumn);
  }
  return columns;
};


const TimesheetsListView = (props) => {
  const { timesheets, isFetchingTimesheets } = props;
  return (
    <>
      {timesheets.length === 0 && !isFetchingTimesheets ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <p style={{ color: '#666' }}>No timesheets to display</p>
        </div>
        :
        <PaginatedDataTable
          tableStyle={{ minWidth: '840px' }}
          columns={dataTableColumns(props)}
          rows={
            timesheets
              .sort(firstBy('jobStartTime', -1))
          }
          moreRowsAvailable={props.moreRowsAvailable}
          onRowClick={(timesheetKey) => {
            const clickedTimesheet = timesheets.find(timesheet => timesheet.key === timesheetKey);
            props.openDetailsModal({ shiftKey: clickedTimesheet.jobId, candidateKey: clickedTimesheet.candidateKey, timesheetKey });
          }}
          overrideHeadingStyles={{
            color: colors.text,
            textTransform: 'none',
            fontSize: '0.9rem',
          }}
          isLoading={isFetchingTimesheets}
          updatePagination={props.updatePagination}
          totalRowCount={props.timesheetsTotalCount}
          filters={props.timesheetFilters}
          showTotalRowCount={false}
        />
      }
    </>

  ); };

const { func, bool, arrayOf, object, shape, string } = PropTypes;

TimesheetsListView.propTypes = {
  timesheets: arrayOf(object).isRequired,
  openDetailsModal: func.isRequired,
  updatePagination: func.isRequired,
  timesheetFilters: shape({
    status: string,
    candidate: string,
    overdue: number.isRequired,
    adminGroup: string,
  }).isRequired,
  isFetchingTimesheets: bool.isRequired,
  moreRowsAvailable: bool.isRequired,
  timesheetsTotalCount: number.isRequired,
};

export default TimesheetsListView;
