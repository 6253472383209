import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaCaretRight } from 'react-icons/fa';
import './style.css';

const { string, func } = PropTypes;
// Note: written as a class and not statless function due to FlipMove
// Flip Move uses refs to identify and apply styles to children, and stateless functional components cannot be given refs

export default class UnselectedItem extends Component {
  static propTypes = {
    title: string.isRequired,
    onClick: func.isRequired,
  }

  render() {
    return (
      <div
        className="unselectedContainer"
        onClick={this.props.onClick}
      >
        {this.props.title}
        <FaCaretRight
          className="testClass"
        />
      </div>
    );
  }
}
