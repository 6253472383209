import React, { CSSProperties, useMemo } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';

import colors from 'config/colors';

import { createTemplateShift as createNewTemplateShift } from 'thunks/createShifts';
import * as templateThunks from 'thunks/templates';
import { setTemplateName } from 'reducers/templates';

import { TemplateDraftShift, TemplatePeriod } from 'types/ShiftTypes';

import BreadCrumbsHeader from 'components/BreadCrumbsHeader';
import SplitButton from 'components/SplitButton';
import { MdPushPinOutline } from 'components/Icons';
import Button from 'components/Button';
import Loading from 'components/Loading';

import { PinShiftsPanelButton } from '../ShiftsPage/ShiftsHeaderButtons';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles : Styles = {
  header: {
  },
  outerContainer: {
    display: 'flex',
    borderBottom: '1px solid #EAEAEA',
    alignItems: 'center',
    padding: 3,
  },
  rightContainer: {
    flex: '0 0 350px',
    width: 350,
    borderLeft: '1px solid #EAEAEA',
    marginLeft: 6,
  },
  leftContainer: {
    backgroundColor: colors.white,
    position: 'relative',
    flex: '1 1 0px',
  },
  topLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingRight: 6,
  },
  input: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1,
    height: 30,
    width: 300,
    color: colors.text,
    border: 'none',
    borderBottom: `2px solid ${colors.greyBorder}`,
    outline: 'none',
    marginRight: 6,
  },
};

interface TemplatesHeaderProps {
  goToEditTemplateShift?: (templateShiftKey: string) => void
  goToApplyTemplatePeriodView?: (templateKey: string) => void,
  goToTemplateEditView?: (templateKey: string) => void,
  saveTemplate?: () => void,
  templateKey?: string,
}

function TemplatesHeader(props: TemplatesHeaderProps): React.ReactElement {

  const dispatch = useAppDispatch();
  const isMobile = useSelector(state => state.screen.isMobile);
  const template = useSelector(state => state.templates.template);
  const templateEdited = useSelector(({ templates }) => templates.templateEdited);
  const isSavingTemplate = useSelector(state => state.templates.isSavingTemplate);
  const templateShifts: { [key: string]: TemplateDraftShift } = useSelector(state => state.createShifts.templateShifts);

  const breadCrumbs = useMemo(() => [
    { label: 'Shifts', linkTo: '/shifts' },
    { label: 'Templates', linkTo: '/shifts/templates' },
  ], []);

  const createBlankTemplate = (periodType: TemplatePeriod) => {
    dispatch(templateThunks.createBlankTemplate(periodType));
    if (props.goToTemplateEditView) props.goToTemplateEditView('new');
  };

  const newTemplateSplitButtonMenuItems = useMemo(() => [
    { key: 'new-day-template', label: 'New Day Template', onClick: () => createBlankTemplate('day') },
    { key: 'new-week-template', label: 'New Week Template', onClick: () => createBlankTemplate('week') },
  ], []);

  const createTemplateShift = async () => {
    const templateShiftKey = await dispatch(createNewTemplateShift());
    if (props.goToEditTemplateShift) props.goToEditTemplateShift(templateShiftKey);
  };

  const templateShiftsCount = Object.keys(templateShifts).length;

  const saveChangesButtonDisabled = !templateEdited || isSavingTemplate || !template?.name || templateShiftsCount === 0 || Object.values(templateShifts).some((shift) => !shift.isValid);

  return (
    <div className="shiftHeader" style={styles.outerContainer}>
      <div style={{ ...styles.leftContainer, justifyContent: isMobile ? 'space-between' : 'center' }}>
        <div style={styles.topLeftContainer}>
          <div className="m-hide" style={{ padding: 3, paddingLeft: 6, display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 6, marginBottom: -6 }}>
              <BreadCrumbsHeader breadCrumbs={breadCrumbs} style={styles.header} />
            </span>
            {template && (
              <span style={{ display: 'flex', marginBottom: -4 }}>
                <h1 style={{ fontSize: '1.5rem', color: colors.text, lineHeight: 1, marginRight: 6, marginTop: 6, fontWeight: 600 }}>{'>'}</h1>
                <input
                  style={styles.input}
                  value={template.name}
                  onChange={event => dispatch(setTemplateName(event.target.value))}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="space-children-6" style={{ display: 'flex' }}>
        {template ?
          <>
            {isSavingTemplate && <Loading size={36} />}
            {props.templateKey !== 'new' && (
              <Button
                black
                shadow={false}
                disabled={templateEdited}
                onClick={() => { if (props.goToApplyTemplatePeriodView) props.goToApplyTemplatePeriodView(template.key); }}
              >
                Apply Template
              </Button>
            )}
            <Button onClick={createTemplateShift} outline white shadow={false}>Shift +</Button>
            <Button
              onClick={() => { if (props.saveTemplate) props.saveTemplate(); }}
              disabled={saveChangesButtonDisabled}
              black
              shadow={false}
            >
              Save Changes
            </Button>
          </>
          :
          <SplitButton
            menuItems={newTemplateSplitButtonMenuItems}
            onClick={() => createBlankTemplate('week')}
            openMenuDirection="left"
            black
          >
            + New Template
          </SplitButton>
        }
      </div>
      <div style={styles.rightContainer}>

        {/* Pin button & explanatory text */}
        <div style={{ marginTop: 0, padding: '3px 6px', display: 'flex' }}>
          <PinShiftsPanelButton />
          <p className="m-hide" style={{ flex: 1, color: colors.text, fontSize: 12, lineHeight: 1.2, marginLeft: 12, marginRight: 6, marginTop: 3 }}>
            Tip: Use the <MdPushPinOutline /> button to prevent the shift details panel from covering the calendar or list view.
          </p>
        </div>

      </div>
    </div>
  );
}

TemplatesHeader.defaultProps = { goToEditTemplateShift: undefined, goToApplyTemplatePeriodView: undefined, goToTemplateEditView: undefined };

export default React.memo(TemplatesHeader);
