import React from 'react';
import PropTypes from 'prop-types';
import { MdPerson } from 'react-icons/md';
import colors from 'config/colors';
import './MonthCalendarEventComponent.scss';

const MonthCalendarEventComponent = ({ event }) => {

  const slotsReleasedToSupplier = event.filledBySupplier + event.unfilledAndReleasedToSupplier;
  const slotsReleasedToBankOrFilledByBank = event.filledByBank + event.unfilledAndNotReleasedToSupplier;

  return (
    <div className="MonthCalendarEventComponentContainer">
      <div className="applicant-icons">
        {event.bankApplicants > 0 && (
          <div className="applicant-icon" style={{ color: colors.cavalry.bankFilled }}>
            <MdPerson
              className="calendarToolbarIcon icon"
              style={{ color: colors.cavalry.bankFilled }}
            />
            {event.bankApplicants}
          </div>
        )}
        {event.supplierApplicants > 0 && (
          <div className="applicant-icon" style={{ color: colors.cavalry.agencyFilled }}>
            <MdPerson
              className="calendarToolbarIcon icon"
              style={{ color: colors.cavalry.agencyFilled }}
            />
            {event.supplierApplicants}
          </div>
        )}
      </div>
      <div className="cal-counts-bar space-children-3--bottom">
        {slotsReleasedToBankOrFilledByBank > 0 && (
          <p
            style={{
              fontSize: '0.85rem',
              textAlign: 'left',
              color: '#999',
              marginLeft: 6,
            }}
            className="m-hide"
          >
            Bank
          </p>
        )}
        {slotsReleasedToBankOrFilledByBank > 0 && (
          <div
            className={`shiftCalendarPills shiftCalendarPills--bank ${slotsReleasedToBankOrFilledByBank === 0 ? 'shiftCalendarPills--zero-count' : ''}`}
          >
            {event.totalSlots > 0 && (
              <>
                <div className="shiftCalenderPillLabel">
                  <strong>{`${event.filledByBank} of ${slotsReleasedToBankOrFilledByBank}`}</strong>
                  <span className="m-hide">&nbsp;filled</span>
                </div>
                <div
                  className={`shiftCalendarCount shiftCalendarCount--is-filled ${event.filledByBank === 0 ? 'shiftCalendarCount--zero-count' : ''}`}
                  style={{
                    width: `${(event.filledByBank / slotsReleasedToBankOrFilledByBank) * 100}%`,
                  }}
                />
                <div
                  className={`shiftCalendarCount shiftCalendarCount--un-filled ${!event.unfilledAndNotReleasedToSupplier ? 'shiftCalendarCount--zero-count' : ''}`}
                  style={{
                    width: `${(event.unfilledAndNotReleasedToSupplier / slotsReleasedToBankOrFilledByBank) * 100}%`,
                  }}
                />
              </>
            )}
          </div>
        )}
        {slotsReleasedToSupplier > 0 && (
          <p
            style={{
              fontSize: '0.85rem',
              textAlign: 'left',
              color: '#999',
              marginLeft: 6,
            }}
            className="m-hide"
          >
            Agency
          </p>
        )}
        <div
          className={`shiftCalendarPills shiftCalendarPills--agency ${!(slotsReleasedToSupplier) ? 'shiftCalendarPills--zero-count' : ''}`}
        >
          {slotsReleasedToSupplier > 0 && (
            <>
              <div className="shiftCalenderPillLabel">
                <strong>
                  {`${event.filledBySupplier} of ${slotsReleasedToSupplier}`}
                </strong>
                <span className="m-hide">&nbsp;filled</span>
              </div>
              <div
                className={`shiftCalendarCount shiftCalendarCount--is-filled ${!event.filledBySupplier ? 'shiftCalendarCount--zero-count' : ''}`}
                style={{ width: `${(event.filledBySupplier / slotsReleasedToSupplier) * 100}%` }}
              />
              <div
                className={`shiftCalendarCount shiftCalendarCount--un-filled ${!event.unfilledAndReleasedToSupplier ? 'shiftCalendarCount--zero-count' : ''}`}
                style={{ width: `${(event.unfilledAndReleasedToSupplier / slotsReleasedToSupplier) * 100}%` }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const { number, string, shape } = PropTypes;

MonthCalendarEventComponent.propTypes = {
  event: shape({
    bankApplicants: number,
    date: string,
    filled: number,
    filledByBank: number,
    filledBySupplier: number,
    releasedToSupplier: number,
    supplierApplicants: number,
    totalSlots: number,
    unfilledAndNotReleasedToSupplier: number,
    unfilledAndReleasedToSupplier: number,
  }),
};

MonthCalendarEventComponent.defaultProps = {
  event: {
    bankApplicants: 0,
    date: '',
    filled: 0,
    filledByBank: 0,
    filledBySupplier: 0,
    releasedToSupplier: 0,
    supplierApplicants: 0,
    totalSlots: 0,
    unfilledAndNotReleasedToSupplier: 0,
    unfilledAndReleasedToSupplier: 0,
  },
};

export default MonthCalendarEventComponent;
