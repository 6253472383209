import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useAppDispatch } from 'hooks/redux';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { copyAndCreateDraftShift } from 'thunks/createShifts';
import { removeDraftShift as removeDraftShiftUnbound } from 'reducers/createShifts';
import { clearFetchShiftsForListViewError } from 'reducers/jobs';

import { createPeriod } from 'components/Calendar';
import ErrorScreen from 'components/ErrorScreen';

import colors from 'config/colors';

import ShiftsPage from '../ShiftsPage';
import ShiftsListView from './ShiftsListView';
import { useShiftList } from '../shift-hooks';

import './DayViewContainer.css';

const styles = {
  shiftTotalText: {
    color: colors.text,
    padding: '6px 12px',
    borderBottom: `1px solid ${colors.cavalry.line}`,
    fontSize: '0.9rem',
  },
  actionButton: {
    display: 'inline-block',
    cursor: 'pointer',
    paddingRight: '6px',
  },
};

const MomentType = moment().constructor;

const DayViewContainer = (props) => {

  const dispatch = useAppDispatch();
  const removeDraftShift = useCallback((draftKey) => dispatch(removeDraftShiftUnbound(draftKey)));
  const createDraftShift = useCallback(async (copyShiftKey) => {
    const draftKey = await dispatch(copyAndCreateDraftShift(copyShiftKey));
    props.nav.goToShiftTab(undefined, draftKey, true, 'edit');
  });

  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const period = useMemo(() => createPeriod(props.selectedDate, 'day'), [props.selectedDate.valueOf()]);

  const adminSites = useSelector(state => state.user.sites);
  const selectedFilterItems = useSelector(state => state.filter.v2SelectedItems.shifts);
  const fetchShiftsListViewError = useSelector(state => state.jobs.fetchShiftsListViewError);

  const { shiftList, keyedShifts, refreshShiftList, isFetchingShiftsForListView } = useShiftList(period);

  const isLoading = !hasInitiallyLoaded || isFetchingShiftsForListView;

  const getShiftList = async () => {
    await refreshShiftList(period);
    setHasInitiallyLoaded(true);
  };

  useEffect(async () => {
    getShiftList();
  }, [adminSites, selectedFilterItems, period.startDate.valueOf(), period.endDate.valueOf()]);

  const ALLOWED_DATA_TABLE_COLUMNS = ['checkbox', 'collapseExpand', 'shiftNumber', 'service', 'location', 'shiftTime', 'speciality', 'roleAndGrade', 'bookedCandidate', 'status', 'actions'];

  return (
    <ShiftsPage
      period={period}
      nav={props.nav}
      shiftList={shiftList}
      loading={isLoading}
      refreshShiftList={async () => {
        await refreshShiftList(period);
      }}
    >
      {fetchShiftsListViewError ?
        <ErrorScreen dismiss={() => dispatch(clearFetchShiftsForListViewError())} retry={getShiftList} />
        :
        <>
          <p style={styles.shiftTotalText}>
            <strong>{shiftList.length} {`shift${shiftList.length === 1 ? '' : 's'}`}</strong> matching your filters
          </p>
          <div style={{ flex: '1 1 0px', display: 'flex', overflowY: 'auto' }}>
            <ShiftsListView
              period={period}
              shiftList={shiftList}
              keyedShifts={keyedShifts}
              isLoading={isLoading}
              selectedShift={props.selectedShift}
              goToShiftTab={(shiftKey, tab) => props.nav.goToShiftTab(undefined, shiftKey, !!shiftList.find(shift => shift.key === shiftKey)?.isDraft, tab)}
              removeShift={removeDraftShift}
              copyShift={createDraftShift}
              allowedDataTableColumns={ALLOWED_DATA_TABLE_COLUMNS}
            />
          </div>
        </>
      }
    </ShiftsPage>
  );
};

const { string, objectOf, func, instanceOf } = PropTypes;
DayViewContainer.propTypes = {
  selectedShift: string,
  selectedDate: instanceOf(MomentType),
  nav: objectOf(func).isRequired,
};

DayViewContainer.defaultProps = {
  selectedDate: null,
  selectedShift: null,
};

export default DayViewContainer;
