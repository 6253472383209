import React from 'react';
import moment from 'moment';
import { convertFromUtcToTimezone, minsToHours } from 'lib/helpers-time';
import spacing from 'config/spacing';
import { SubmittedTimeRow } from '../../types/TimesheetTypes';

const formatTime = (date: string, timezone: string) => convertFromUtcToTimezone(date, timezone).format('HH:mm');

interface StartEndMultiTimesheetSubmission {
  submittedMultiTimes: SubmittedTimeRow[],
  shiftStartTime: string,
  shiftEndTime: string,
  witnessName: string | null,
  witnessRole: string | null,
  timezone: string;
}

export const StartEndMultiTimesheetSubmission : React.FC<StartEndMultiTimesheetSubmission> = (props: StartEndMultiTimesheetSubmission) => {
  const { submittedMultiTimes, shiftEndTime, shiftStartTime, witnessName, witnessRole } = props;

  const expectedTotalMins = moment(shiftEndTime).diff(moment(shiftStartTime), 'minutes');
  const expectedTotalHours = minsToHours(expectedTotalMins);

  const submittedTotalMinutes = submittedMultiTimes.reduce((total, time) => total + moment(time.end).diff(moment(time.start), 'minutes'), 0);
  const submittedTotalHours = minsToHours(submittedTotalMinutes);

  return (
    <div>
      <div className="submission-table">
        <div className="submission-table__header">
          <div className="submission-table__header-item">
            <p className="submission-table__header-text">Time Type</p>
          </div>
          <div className="submission-table__header-item">
            <p className="submission-table__header-text">Start Time</p>
          </div>
          <div className="submission-table__header-item">
            <p className="submission-table__header-text">End Time</p>
          </div>
          <div className="submission-table__header-item">
            <p className="submission-table__header-text">Hours</p>
          </div>
        </div>
        {submittedMultiTimes.map((time, index) => {
          const grossMinutes = moment(time.end).diff(moment(time.start), 'minutes');
          const grossHours = minsToHours(grossMinutes);
          return (
            <div key={index} className="submission-table__row">
              <div className="submission-table__row-item">
                <p className="submission-table__row-text">{time.label}</p>
              </div>
              <div className="submission-table__row-item">
                <p className="submission-table__row-text">{formatTime(time.start, props.timezone)}</p>
              </div>
              <div className="submission-table__row-item">
                <p className="submission-table__row-text">{formatTime(time.end, props.timezone)}</p>
              </div>
              <div className="submission-table__row-item">
                <p className="submission-table__row-text">{grossHours}</p>
              </div>
            </div>
          );
        })}
        {witnessName && witnessRole && (
          <div>
            <p className="submission-table__header-text">Witnessed By: {witnessName} ({witnessRole})</p>
          </div>
        )}
      </div>
      <div style={{ marginTop: spacing.small }}>
        <div style={{ display: 'flex' }}>
          <p className="total_hours_label">Expected Total Hours:</p>
          <p className="total_hours_value">{expectedTotalHours}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p className="total_hours_label">Submitted Total Hours:</p>
          <p className="total_hours_value">{submittedTotalHours}</p>
        </div>
      </div>
    </div>
  );
};
