import React, { CSSProperties } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';

import colors from 'config/colors';
import './AcceptanceDocuments.scss';

interface Styles {
  [key: string]: CSSProperties
}

const styles: Styles = {
  documents: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  documentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 3,
    paddingBottom: 3,
  },
  instructions: {
    lineHeight: 1.4,
    fontSize: '0.9rem',
  },
};

interface AccceptanceDocument {
  key: string
  name: string
  version: string
  versionName: string | null
  createdAt: string
  content: string
  isAccepted: boolean,
}

interface AcceptanceDocumentsProps {
  acceptanceDocuments: AccceptanceDocument[],
  displayAcceptanceDocument: (key: string) => void,
}

export default function AcceptanceDocuments(props: AcceptanceDocumentsProps): React.ReactElement {

  return (
    <div>
      <p style={styles.instructions}>Confirm that you have read and accept the following (click name to open and read):</p>

      <div style={styles.documents}>
        {props.acceptanceDocuments.map((doc) => {
          return (
            <div style={styles.documentRow}>
              <a
                className="acceptance-documents__link"
                role="button"
                tabIndex={0}
                onClick={() => props.displayAcceptanceDocument(doc.key)}
              >
                {doc.name}
              </a>
              {doc.isAccepted ? <MdCheck size={18} color={colors.cavalry.active} /> : <MdClose size={18} color={colors.cavalry.error} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
