import React from 'react';
import PropTypes from 'prop-types';
import { MdInsertChart } from 'react-icons/md';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import spacing from '../../config/spacing';

const styles = {
  container: {
    width: `calc(100% - ${spacing.base * 2}px)`,
    height: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing.base,
    border: '1px solid rgba(0, 94, 134, 0.1)',
    borderRadius: '2px',
  },
  chart: {
    color: colors.grays.mediumLight,
  },
  message: {
    color: colors.grays.mediumLight,
    fontFamily: fonts.main.family,
    fontSize: fonts.main.size,
    fontWeight: fonts.main.weight,
    marginLeft: spacing.base,
    marginRight: spacing.base,
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Oxygen',
    textTransform: 'uppercase',
    fontSize: '1.1em',
    marginTop: spacing.large,
    marginLeft: spacing.large,
    marginBottom: spacing.xlarge,
    color: colors.positive,
    fontWeight: '400',
  },
};

export default function EmptyCard(props) {
  return (
    <div style={{ padding: spacing.base }}>
      <h3 style={styles.title}>{props.title}</h3>
      <div style={styles.container}>
        <MdInsertChart style={styles.chart} size={80} />
        <p style={styles.message}>There&#39;s no data to display here right now.</p>
      </div>
    </div>
  );
}

const { string, object } = PropTypes;

EmptyCard.propTypes = {
  dimensions: object,
  message: string,
};

EmptyCard.defaultProps = {
  dimensions: {
    height: 600,
    width: '100%',
  },
  message: 'Nothing to see here',
};
