import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import colors from 'config/colors';
import { permissions } from 'config/permissions';
import { usePermission } from 'hooks/feature';
import ScrollView from 'components/ScrollView';

import SuspendAccount from './SuspendAccount';
import RemoveCandidate from './RemoveCandidate';
import CandidateProfileBubble from '../../CandidateProfileBubble';

const ManageTab = (props) => {
  const hasSuspendPermission = usePermission(permissions.BANK_CAN_SUSPEND);
  const hasRemoveFromBankPermission = usePermission(permissions.BANK_CAN_REMOVE);

  const candidateRemovalError = useSelector(state => state.compliance.candidateRemovalError);
  const profile = useSelector(state => state.candidate.profile);
  // const currentOrgKey = useSelector(state => state.global.currentOrgKey);
  const mainOrg = profile?.orgs?.[profile.mainOrgKey];
  const { accountSuspended, removedAt } = mainOrg;
  const cancelRemoveFromBank = removedAt && moment.utc(removedAt) > moment.utc();

  if (!profile) return null;

  return (
    <ScrollView noFooter containerStyle={{ height: '100%' }} className="scroll-view-container">
      {hasSuspendPermission && (
        <CandidateProfileBubble header={accountSuspended ? 'Remove Suspension' : 'Suspend Account'}>
          <SuspendAccount candidateKey={profile.key} />
        </CandidateProfileBubble>
      )}
      {hasRemoveFromBankPermission && (
        <CandidateProfileBubble
          warning={candidateRemovalError ? 'Something went wrong. Please try again.' : null}
          header={cancelRemoveFromBank ? 'Cancel Removal From Bank' : 'Remove From Bank'}
        >
          <RemoveCandidate datePickerOpenDirection={hasSuspendPermission ? 'up' : 'down'} />
        </CandidateProfileBubble>
      )}
    </ScrollView>
  );
};

export default ManageTab;
