import React from 'react';
import { useSelector } from 'react-redux';

import { convertToSelect } from 'lib/helpers';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface AreaFieldProps {
  area: { id: string, name: string } | null
  onChange: (val: { value: string, label: string }) => void,
  disabled?: boolean,
  siteKey: string,
  mandatory?: boolean,
}

interface Area {
  key: string,
  name: string,
}

function AreaField(props: AreaFieldProps) {

  const areas = useSelector(({ user }) => user.sites[props.siteKey]?.areas);

  return (
    <FieldLabel label="Area">
      <JobsSelectBox
        name="area"
        value={props.area}
        placeholder={`Area ${props.mandatory ? '*' : '(optional)'}`}
        data={(areas ?? []).map((area: Area) => convertToSelect(area))}
        onChange={props.onChange}
        mandatory={props.mandatory}
        isDisabled={props.disabled || !areas || areas.length === 0}
      />
    </FieldLabel>
  );
}

AreaField.defaultProps = { disabled: false, mandatory: false };

export default React.memo(AreaField);
