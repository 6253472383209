import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import colors from 'config/colors';
import * as reportingThunks from 'thunks/reporting';

import './styles.scss';

const styles = {
  group: {
    marginRight: 12,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 99999,
  },
  rangePicker: {
    flex: '0 0 auto',
    backgroundColor: colors.white,
    borderRadius: '4px',
    border: `1px solid ${colors.cavalry.line}`,
    cursor: 'pointer',
  },
};

const groups = [['1W', '1M', '1Q', '1Y'], ['MTD', /* 'QTD', */ 'YTD'], ['ALL']];
const titles = {
  '1W': 'One week',
  '1M': 'One month',
  '1Q': 'One quarter',
  '1Y': 'One year',
  MTD: 'Month-to-date',
  // QTD: 'Quarter-to-date',
  YTD: 'Year-to-date',
  ALL: 'Everything',
};
const { string, shape, func, bool, object } = PropTypes;

class DateFilter extends Component {

  static propTypes = {
    selectedRange: shape({
      from: string.isRequired,
      to: string.isRequired,
    }).isRequired,
    changeDate: func.isRequired,
    showDateGroupOptions: bool,
    extraDateRangePickerProps: object,
  }

  static defaultProps = {
    showDateGroupOptions: true,
    extraDateRangePickerProps: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.getSelectedOption(props),
      from: moment(this.props.selectedRange.from),
      to: moment(this.props.selectedRange.to),
      focusedInput: null,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selectedRange.from !== this.props.selectedRange.from || newProps.selectedRange.to !== this.props.selectedRange.to) {
      this.setState({
        selectedOption: this.getSelectedOption(newProps),
        from: moment(newProps.selectedRange.from),
        to: moment(newProps.selectedRange.to),
      });
    }
  }

  getSelectedOption = (props) => {
    if (props && props.selectedRange) {
      const options = this.generateOptions();
      let selectedOption = null;

      Object.keys(options).forEach((optionKey) => {
        const option = options[optionKey];
        if (option.from === props.selectedRange.from && option.to === props.selectedRange.to) {
          selectedOption = optionKey;
        }
      });

      if (selectedOption) {
        return selectedOption;
      }
    }

    return null;
  }

  generateOptions = () => {
    const options = {};
    const endOfToday = moment().endOf('day').toISOString();

    options[groups[0][0]] = { from: moment().subtract(7, 'days').startOf('day').toISOString(), to: endOfToday };
    options[groups[0][1]] = { from: moment().subtract(30, 'days').startOf('day').toISOString(), to: endOfToday };
    options[groups[0][2]] = { from: moment().subtract(3, 'months').startOf('day').toISOString(), to: endOfToday };
    options[groups[0][3]] = { from: moment().subtract(1, 'year').startOf('day').toISOString(), to: endOfToday };

    options[groups[1][0]] = { from: moment().startOf('month').toISOString(), to: endOfToday };
    // options[groups[1][1]] = { from: moment().startOf('quarter').toISOString(), to: endOfToday };
    options[groups[1][1]] = { from: moment().startOf('year').toISOString(), to: endOfToday };

    options[groups[2][0]] = { from: moment().subtract(3, 'year').toISOString(), to: endOfToday };

    return options;
  }

  change = (option) => {
    const options = this.generateOptions();
    this.props.changeDate(options[option], option !== groups[2][0]);
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.right}>
          {this.props.showDateGroupOptions && (
            <>
              {groups.map(options => (
                <div style={styles.group} key={options}>
                  {options.map(option =>
                    <a // eslint-disable-line
                      className={this.state.selectedOption === option ? 'dateFilterOption dateFilterOptionSelected' : 'dateFilterOption'}
                      onClick={() => this.change(option)}
                      title={titles[option]}
                      key={option}
                    >
                      {option}
                    </a>)}
                </div>
              ))}
            </>
          )}

          <div style={styles.rangePicker} className="datePicker">
            <DateRangePicker
              startDateId={this.props.startDateId || uuidv4()}
              endDateId={this.props.endDateId || uuidv4()}
              displayFormat="ll"
              startDate={this.state.from}
              endDate={this.state.to}
              onDatesChange={({ startDate, endDate }) => { if (startDate && endDate) this.props.changeDate({ from: startDate.toISOString(), to: endDate.toISOString() }, true); }}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              isOutsideRange={() => false}
              firstDayOfWeek={1}
              {...this.props.extraDateRangePickerProps}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeDate: (range, previous) => dispatch(reportingThunks.changeDate(range, previous)),
  };
}

function mapStateToProps({ reporting }) {
  return {
    selectedRange: reporting.selectedRange,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
