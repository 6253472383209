import React, { useEffect, useState, useRef, useCallback, useMemo, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';

import { SharedInboxMembership, Sender, Filters, BatchMessage, StaffList } from 'types/Messaging';
import { SelectedFilterItems } from 'types/Filters';
import { convertToSelect } from 'lib/helpers';
import { sendBatchMessage, fetchBatchMessages as fetchMessages, fetchSendListStaff } from 'lib/api/messaging';

import colors from 'config/colors';
import spacing from 'config/spacing';

import Loading from 'components/Loading';

import BatchToInput from './BatchToInput';
import FromInput from './FromInput';
import MessageList from './MessageList';
import ComposeBatchMessageBar from './ComposeBatchMessageBar';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles : Styles = {
  conversationContainer: {
    display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', overflowY: 'hidden', fontSize: 14, color: colors.text,
  },
};

const createFiltersPayload = (externalFilters : SelectedFilterItems) => {
  const filters : Filters = {};

  if (externalFilters?.banks?.length) filters.bankKeys = externalFilters.banks;
  if (externalFilters?.sites?.length) filters.siteKeys = externalFilters.sites;
  if (externalFilters?.roles?.length) filters.roleKeys = externalFilters.roles;
  if (externalFilters?.grades?.length) filters.gradeKeys = externalFilters.grades;
  if (externalFilters?.specialities?.length) filters.specialityKeys = externalFilters.specialities;

  return filters;
};

function scrollToBottom(ref: HTMLElement | null) {
  if (ref) ref?.scrollTo(0, ref.scrollHeight + 50);
}

export default function ComposeBatchMessage() : React.ReactElement {

  const user = useSelector(state => state.user);
  const sharedInboxes : SharedInboxMembership[] | null = useSelector(state => state.messaging.sharedInboxMembership);
  const selectedFilterItems = useSelector(state => state.filter.v2SelectedItems.composeBatchMessage);

  // Create lists of available senders
  const userSender: Sender = { value: user.userId, label: user.userData?.displayName };
  const availableSenders : Sender[] = useMemo(() => [userSender, ...(sharedInboxes ?? [])].map((obj) => convertToSelect(obj)), [userSender, sharedInboxes]);

  const [selectedSender, setSelectedSender] = useState<Sender | null>(userSender);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [apiError, setApiError] = useState<null | string>(null);
  const [batchMessages, setBatchMessages] = useState<null | BatchMessage[]>(null);
  const [staffList, setStaffList] = useState<null | StaffList[]>(null);
  const [fetchStaffListError, setFetchStaffListError] = useState<null | string>(null);

  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function fetchBatchMessages() {
      const filters = createFiltersPayload(selectedFilterItems);
      const sharedInboxKey = (sharedInboxes ?? []).find(shared => shared.key === selectedSender?.value)?.key;

      try {
        if (apiError) setApiError(null);
        setIsFetchingMessages(true);
        const response = await fetchMessages(filters, sharedInboxKey);
        setBatchMessages(response.batchConversation?.batchMessages ?? null);
        setIsFetchingMessages(false);
      } catch (error) {
        if (error instanceof Error) setApiError(error.message);
        setIsFetchingMessages(false);
      }
    }

    fetchBatchMessages();
  }, [selectedSender, selectedFilterItems?.banks, selectedFilterItems?.sites, selectedFilterItems?.roles, selectedFilterItems?.grades, selectedFilterItems?.specialities]);


  useEffect(() => scrollToBottom(messageContainerRef.current), [batchMessages, isFetchingMessages]);

  useEffect(() => {
    fetchStaff();
  }, [selectedFilterItems?.banks, selectedFilterItems?.sites, selectedFilterItems?.roles, selectedFilterItems?.grades, selectedFilterItems?.specialities]);

  const fetchStaff = async () => {
    const filters = createFiltersPayload(selectedFilterItems);

    try {
      setFetchStaffListError(null);
      const response = await fetchSendListStaff(filters);
      setStaffList(response.recipients);
    } catch (error) {
      if (error instanceof Error) setFetchStaffListError(error.message);
    }
  };

  const sendMessage = useCallback(async (message: string) => {
    setIsSendingMessage(true);
    if (apiError) setApiError(null);

    const messagePayload = { type: 'text', textContent: message };

    const filters = createFiltersPayload(selectedFilterItems);
    const sharedInboxKey = (sharedInboxes ?? []).find(shared => shared.key === selectedSender?.value)?.key;

    try {
      const response = await sendBatchMessage(messagePayload, filters, sharedInboxKey);
      setBatchMessages(response.messages);
      setIsSendingMessage(false);
      scrollToBottom(messageContainerRef.current);
    } catch (error) {
      if (error instanceof Error) setApiError(error.message);
      setIsSendingMessage(false);
    }
  }, [apiError, selectedSender?.value, selectedFilterItems, sharedInboxes]);

  return (
    <>
      <BatchToInput staffList={staffList} fetchStaffListError={fetchStaffListError} retryFetchSendListStaff={fetchStaff} />
      <FromInput availableSenders={availableSenders} selectedSender={selectedSender} selectSender={setSelectedSender} showWarning={selectedSender?.value === user.userId} />
      <div style={styles.conversationContainer}>

        {apiError && (
          <div style={{ width: '100%', backgroundColor: colors.red, padding: spacing.tiny, lineHeight: 1.4 }}>
            <p style={{ color: colors.white }}>{apiError}</p>
          </div>
        )}
        <div className="messagingConversationMessageContainer" ref={messageContainerRef}>
          {isFetchingMessages ? <Loading flex /> : <MessageList messages={batchMessages?.sort(firstBy('sentAt')) ?? null} conversationUsers={undefined} />}
        </div>
        <ComposeBatchMessageBar disabled={staffList?.length === 0 ?? true} isLoading={isSendingMessage} sendMessage={sendMessage} />

      </div>
    </>
  );
}
