import React from 'react';
import './sandboxLabel.css';

const SandboxLabel = () => (
  <div className="sandbox-container">
    <p>Sandbox Mode</p>
  </div>
);

export default SandboxLabel;
