import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeTemplateShift } from 'reducers/createShifts';
import { setTemplateEdited } from 'reducers/templates';

import Page from 'components/Page';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { useSelectedShifts } from '../shift-hooks';

import ShiftsSupplementaryPanel from '../ShiftsPage/ShiftsSupplementaryPanel';
import TemplatesHeader from './TemplatesHeader';

const styles = {
  bottomBar: {
    padding: '12px 12px',
    borderTop: '1px solid rgba(164, 194, 244, 0.2)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface TemplatesPageProps {
  loading?: boolean;
  children?: React.ReactNode;
  goToEditTemplateShift?: (templateShiftKey: string) => void,
  goToApplyTemplatePeriodView?: () => void,
  goToTemplateEditView?: (templateKey: string) => void,
  saveTemplate?: () => void,
  templateKey?: string,
}

const TemplatesPage: React.FC<TemplatesPageProps> = (props: TemplatesPageProps) => {

  const { loading, children, ...pageProps } = props;

  const dispatch = useDispatch();
  const template = useSelector(({ templates }) => templates.template);
  const templateEdited = useSelector(state => state.templates.templateEdited);
  const shiftDetailsIsPinned = useSelector(state => state.userInterface.shiftDetailsIsPinned);

  const { selectedShiftKeys, unselectSpecifiedShifts, unselectAllShifts } = useSelectedShifts();

  return (
    <Page vflex title="Shifts" {...pageProps}>

      <TemplatesHeader {...props} />

      {loading
        ?
          <Loading flex />
        :
          <div style={{ flex: 1, display: 'flex', alignItems: 'stretch' }}>
            <div style={{ flex: '1 1 0px', display: 'flex', flexDirection: 'column' }}>
              {children}

              {!!(template?.key && selectedShiftKeys.length) && (
                <div style={styles.bottomBar}>
                  <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', borderRight: '1px solid #EAEAEA' }}>
                    <div style={{ color: '#666', fontSize: 16, marginRight: 12 }}>
                      Selected:
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: 14, marginRight: 12 }}>
                      {!!selectedShiftKeys.length && (
                        <div style={{ color: '#666', lineHeight: 1, marginTop: 3 }}>
                          {selectedShiftKeys.length} shifts
                        </div>
                      )}
                    </div>
                    <a
                      className="link"
                      style={{ fontSize: 14, marginRight: 12 }}
                      onClick={() => unselectAllShifts()}
                    >
                      Deselect all
                    </a>
                  </div>

                  {/* Action buttons */}
                  <div className="space-children-12" style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', padding: '0 12px' }}>
                    <div style={{ color: '#666', fontSize: 16, marginRight: 12 }}>
                      Actions:
                    </div>

                    <Button
                      danger
                      style={{ marginRight: 12 }}
                      onClick={() => {
                        selectedShiftKeys.forEach((key: string) => dispatch(removeTemplateShift(key)));
                        if (!templateEdited) dispatch(setTemplateEdited());
                        unselectSpecifiedShifts(selectedShiftKeys);
                      }}
                    >
                      Delete Shifts
                    </Button>
                  </div>
                </div>
              )}

            </div>
            {shiftDetailsIsPinned && <ShiftsSupplementaryPanel />}
          </div>
      }
    </Page>
  );
};

TemplatesPage.defaultProps = {
  loading: false,
  goToEditTemplateShift: undefined,
  goToTemplateEditView: undefined,
  goToApplyTemplatePeriodView: undefined,
  children: false,
};

export default TemplatesPage;
