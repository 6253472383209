import React, { useCallback, useMemo, useEffect, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { useFeatureFlag } from 'hooks/feature';
import moment from 'moment-timezone';

import { useFormFields } from 'hooks/form';
import useHourlyRate from 'hooks/useHourlyRate';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { convertFromSelect } from 'lib/helpers';
import { computeNewShiftTimes } from 'routes/Jobs/date-time-field-helper';
import { findApplicableRateCards, fetchMatchingCostCentre } from 'lib/helpers-metadata';

import { RateCard } from 'types/Metadata';

import * as globalThunks from 'thunks/global';
import * as jobsThunks from 'thunks/jobs';
import * as jobsActions from 'reducers/jobs';

import Loading from 'components/Loading';
import Button from 'components/Button';

import SiteField from '../FormFields/SiteField';
import AreaField from '../FormFields/AreaField';
import DateField from '../FormFields/DateField';
import TimeField from '../FormFields/TimeField';
import ReasonField from '../FormFields/ReasonField';
import SubReasonField from '../FormFields/SubReasonField';
import DescriptionField from '../FormFields/DescriptionField';
import RateCardField from '../FormFields/RateCardField';
import CostCentreField from '../FormFields/CostCentreField';
import HourlyRateField from '../FormFields/HourlyRateField';
import SlotsField from '../FormFields/SlotsField';
import ClashesFound from '../DraftShiftsModal/ClashesFound';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  centreContainer: { display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: spacing.small },
};

interface EditShiftProps {
  shiftKey: string
  goToDetailsTab: () => void,
  onShiftChange: () => void,
}

interface FormField {
  id: string,
  name: string
}

interface InitialFormState {
  area?: FormField,
  startTime?: string,
  endTime?: string,
  rateCard?: RateCard | null,
  reason?: FormField | null,
  subReason?: FormField | null,
  publicDescription?: string,
  privateNotes?: string,
  customHourlyRate?: string,
  slotsRequired?: number,
}

function EditShift(props: EditShiftProps) : React.ReactElement {

  const dispatch = useAppDispatch();

  // Feature flags
  const rateCardsOn = useFeatureFlag('rateCard');
  const siteAreasOn = useFeatureFlag('siteAreas');
  const costCentresOn = useFeatureFlag('costCentres');
  const customHourlyRateOn = useFeatureFlag('customHourlyRate');

  // Redux shift data
  const shift = useSelector(({ jobs }) => jobs.details[props.shiftKey]?.details);
  const isUpdatingShift = useSelector(({ jobs }) => jobs.details[props.shiftKey]?.isUpdatingShift ?? false);
  const updateShiftError = useSelector(({ jobs }) => jobs.details[props.shiftKey]?.updateShiftError);
  const clashingShiftsError = useSelector(({ jobs }) => jobs.details[props.shiftKey]?.clashingShiftsError);

  // Redux global data
  const shiftCreateMetadata = useSelector(({ global }) => global.shiftCreateMetadata);
  const currentOrgKey = useSelector(({ global }) => global.currentOrgKey);
  const isFetchingShiftCreationMetadata = useSelector(({ global }) => global.isFetchingShiftCreationMetadata);
  const fetchShiftCreationMetadataError = useSelector(({ global }) => global.fetchShiftCreationMetadataError);
  const editableFields = useSelector(({ global }) => global.orgConfig?.shiftEditableFields);
  const mandatoryFields = useSelector(({ global }) => global.orgConfig?.shiftMandatoryFields);
  const customHourlyRateCriteria = useSelector(({ global }) => global.orgConfig?.customHourlyRateCriteria);
  const timezone = shift?.timezone ?? 'Europe/London';

  const unbookedOrBeforeShiftEnds = shift?.booked === 0 || moment.utc().toISOString() < shift?.endTime;

  // Find shift rate card
  const findShiftRateCard = () => {
    const applicableRateCards = findApplicableRateCards(shiftCreateMetadata?.orgRateCards ?? [], shift?.roleKey, shift?.specialityKey, shift?.gradeKey, shift?.siteKey, shift?.areaKey, shift?.reasonKey, shift?.subReasonKey, shift?.serviceKey, currentOrgKey);
    const customHourlyRateCard = applicableRateCards.find((rateCard: RateCard) => rateCard.requireCustomHourlyRate && shift.customHourlyRate);
    const rateCardsWithoutCustomRate = applicableRateCards.filter(rateCard => !rateCard.requireCustomHourlyRate);
    const sameSettingsRateCard = applicableRateCards.find((rateCard: RateCard) => (shift?.timesheetTypeKey && rateCard.timesheetTypeKey === shift.timesheetTypeKey) && (shift?.rateModifierKey && rateCard.rateModifierKey === shift.rateModifierKey));

    return customHourlyRateCard ?? sameSettingsRateCard ?? rateCardsWithoutCustomRate[0];
  };

  const shiftEditableFields = editableFields ?? [];

  // Set initial form fields state
  const { fields, handleFieldChange } = useFormFields(() => {
    const initialFormState : InitialFormState = {};

    shiftEditableFields.forEach((fieldKey: string) => {
      if (fieldKey === 'areaKey' && siteAreasOn && unbookedOrBeforeShiftEnds) {
        initialFormState.area = { id: shift?.areaKey, name: shift?.areaName };
      }

      if (fieldKey === 'reasonKey' && unbookedOrBeforeShiftEnds) {
        initialFormState.reason = shift?.reasonKey ? { id: shift?.reasonKey, name: shift.reason } : null;
      }

      if (fieldKey === 'subReasonKey' && unbookedOrBeforeShiftEnds) {
        initialFormState.subReason = shift?.subReasonKey ? { id: shift?.subReasonKey, name: shift?.subReason } : null;
      }

      if (fieldKey === 'rateCardKey' && rateCardsOn && unbookedOrBeforeShiftEnds) {
        // Rate cards may not yet be available on first render
        const rateCard = findShiftRateCard();
        initialFormState.rateCard = rateCard ?? null;
      }

      if (fieldKey === 'startTime' || fieldKey === 'endTime' || fieldKey === 'publicDescription' || fieldKey === 'privateNotes' || fieldKey === 'customHourlyRate' || fieldKey === 'slotsRequired') {
        initialFormState[fieldKey] = shift?.[fieldKey];
      }
    });

    return initialFormState;
  });

  // Form field callback functions
  const onChangeArea = useCallback(data => handleFieldChange({ id: 'area', value: convertFromSelect(data) }), []);

  const onChangeDate = useCallback((newDate) => {
    if (newDate) {
      const { newStartTime, newEndTime } = computeNewShiftTimes({
        currentStart: fields.startTime,
        currentEnd: fields.endTime,
        timezone,
        newDate,
      });

      handleFieldChange({ id: 'startTime', value: newStartTime });
      handleFieldChange({ id: 'endTime', value: newEndTime });
    }
  }, [fields.startTime, fields.endTime, timezone]);

  const onChangeStartTime = useCallback((hours, minutes) => {
    if (hours !== null && minutes !== null) {
      const { newStartTime, newEndTime } = computeNewShiftTimes({
        currentStart: fields.startTime,
        currentEnd: fields.endTime,
        timezone,
        newStartTime: { hours, minutes },
      });

      handleFieldChange({ id: 'startTime', value: newStartTime });
      handleFieldChange({ id: 'endTime', value: newEndTime });
    }
  }, [fields.startTime, fields.endTime, timezone]);

  const onChangeEndTime = useCallback((hours, minutes) => {
    if (hours !== null && minutes !== null) {
      const { newStartTime, newEndTime } = computeNewShiftTimes({
        currentStart: fields.startTime,
        currentEnd: fields.endTime,
        timezone,
        newEndTime: { hours, minutes },
      });

      handleFieldChange({ id: 'startTime', value: newStartTime });
      handleFieldChange({ id: 'endTime', value: newEndTime });
    }
  }, [fields.startTime, fields.endTime, timezone]);

  const onChangeReason = useCallback((data) => {
    handleFieldChange({ id: 'reason', value: convertFromSelect(data) });
    handleFieldChange({ id: 'subReason', value: null });
  }, []);
  const onChangeSubReason = useCallback(data => handleFieldChange({ id: 'subReason', value: convertFromSelect(data) }), []);
  const onChangePublicDescription = useCallback(text => handleFieldChange({ id: 'publicDescription', value: text.target?.value ?? null }), []);
  const onChangeSlotsRequired = useCallback(slotsRequired => handleFieldChange({ id: 'slotsRequired', value: slotsRequired }), []);
  const onChangePrivateNotes = useCallback(text => handleFieldChange({ id: 'privateNotes', value: text.target?.value ?? null }), []);
  const onChangeRateCard = useCallback(data => handleFieldChange({ id: 'rateCard', value: applicableRateCards.find(rc => rc.key === data.value) }), []);
  const onChangeHourlyRate = useCallback((value) => {
    handleFieldChange({ id: 'customHourlyRate', value });
  }, []);

  // Fetch shift metadata if it has not already been fetched
  useEffect(() => {
    if (!shiftCreateMetadata) dispatch(globalThunks.fetchShiftCreateMetadata());

    // If shift does not exist in Redux e.g. if user clicks on another shift from list view whilst edit view is still open, navigate user back to details view
    if (!shift) props.goToDetailsTab();
  }, [props.shiftKey]);

  // Find shift rateCard once shift create metadata has been fetched
  useEffect(() => {
    if (shiftCreateMetadata?.orgRateCards && rateCardsOn && unbookedOrBeforeShiftEnds) {
      const rateCard = findShiftRateCard();
      if (rateCard) handleFieldChange({ id: 'rateCard', value: rateCard });
    }
  }, [shiftCreateMetadata?.orgRateCards]);

  // Find rate cards that match shift specification
  const applicableRateCards = useMemo(() => {
    return findApplicableRateCards(shiftCreateMetadata?.orgRateCards ?? [], shift?.roleKey, shift?.specialityKey, shift?.gradeKey, shift?.siteKey, fields.area?.id ?? shift?.areaKey, fields.reason?.id ?? shift?.reasonKey, fields.subReason?.id ?? shift?.subReasonKey, shift?.serviceKey, currentOrgKey);
  }, [props.shiftKey, shiftCreateMetadata?.orgRateCards, fields.area?.id]);

  // Find matching cost centre
  const costCentre = useMemo(() => {
    return fetchMatchingCostCentre(shiftCreateMetadata?.orgCostCentres ?? [], shift?.siteKey, fields.area?.id ?? shift?.areaKey, shift?.roleKey, shift?.gradeKey, shift?.specialityKey, fields.reason?.id ?? shift?.reasonKey, shift?.serviceKey);
  }, [props.shiftKey, shiftCreateMetadata?.orgCostCentres, fields.area?.id, fields.reason?.id]);

  // Compare form field values against current shift
  const changesComparedToShift = useMemo(() => {
    if (fields.startTime !== undefined && fields.startTime !== shift?.startTime) return true;
    if (fields.endTime !== undefined && fields.endTime !== shift?.endTime) return true;
    if (fields.publicDescription !== undefined && (fields.publicDescription || null) !== shift?.publicDescription) return true;
    if (fields.privateNotes !== undefined && (fields.privateNotes || null) !== shift?.privateNotes) return true;
    if (fields.area !== undefined && fields.area.id !== shift?.areaKey) return true;
    if (fields.reason !== undefined && (fields.reason?.id ?? null) !== shift?.reasonKey) return true;
    if (fields.subReason !== undefined && (fields.subReason?.id ?? null) !== shift?.subReasonKey) return true;
    if (fields.rateCard !== undefined) {
      const shiftRateCard = findShiftRateCard();
      if (fields.rateCard?.id !== shiftRateCard?.key) return true;
    }
    if (fields.customHourlyRate !== undefined && fields.customHourlyRate !== shift?.customHourlyRate) return true;
    if (fields.slotsRequired !== undefined && fields.slotsRequired !== shift?.slotsRequired) return true;
    return false;
  }, [fields]);

  const updateShift = async () => {
    const success = await dispatch(jobsThunks.updateShift(props.shiftKey, fields, props.onShiftChange));
    if (success) props.goToDetailsTab();
  };

  const areaEditable = siteAreasOn && shiftEditableFields.includes('areaKey') && unbookedOrBeforeShiftEnds;
  const startTimeEditable = shiftEditableFields.includes('startTime');
  const endTimeEditable = shiftEditableFields.includes('endTime');
  const rateCardEditable = rateCardsOn && shiftEditableFields.includes('rateCardKey') && unbookedOrBeforeShiftEnds;
  const reasonEditable = shiftEditableFields.includes('reasonKey') && unbookedOrBeforeShiftEnds;
  const subReasonEditable = shiftEditableFields.includes('subReasonKey') && unbookedOrBeforeShiftEnds;
  const publicDescriptionEditable = shiftEditableFields.includes('publicDescription');
  const privateNotesEditable = shiftEditableFields.includes('privateNotes');
  const slotsRequiredEditable = shiftEditableFields.includes('slotsRequired');

  const showLocationAndTimeSection = areaEditable || startTimeEditable || endTimeEditable;
  const showShiftReasonSection = reasonEditable || subReasonEditable || publicDescriptionEditable || privateNotesEditable;

  const submitButtonDisabled = !changesComparedToShift || isUpdatingShift;

  const { estimatedCost, customHourlyRateValidation } = useHourlyRate({
    requireCustomHourlyRate: fields.rateCard?.requireCustomHourlyRate ?? false,
    customHourlyRate: fields.customHourlyRate,
    customHourlyRateCriteria,
    startTime: fields.startTime,
    endTime: fields.endTime,
    timezone,
  });

  if (!shift) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.text }}>No shift found.</p>
      </div>
    );
  }

  // Show permissions error if org does not have any editable fields
  if (shiftEditableFields.length === 0) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.text }}>You do not have the correct permissions to edit a shift?.</p>
      </div>
    );
  }

  // Loading state
  if (isFetchingShiftCreationMetadata || isUpdatingShift) {
    return (
      <div style={styles.centreContainer}>
        <Loading />
      </div>
    );
  }

  // Show error if create shift metadata fails to fetch
  if (fetchShiftCreationMetadataError) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.red }}>{fetchShiftCreationMetadataError}</p>
      </div>
    );
  }

  // Display clashing shifts
  if (clashingShiftsError) {
    return (
      <div style={{ ...styles.centreContainer, flexDirection: 'column' }}>
        <ClashesFound clashingShifts={clashingShiftsError} customMessage="The shift date/time could not be updated" />
        <Button fullWidth style={{ marginTop: spacing.base }} onClick={() => dispatch(jobsActions.clearClashingShiftsError(props.shiftKey))} negative>Dismiss</Button>
      </div>
    );
  }

  return (
    <div className="createShiftForm shiftDetailContentContainer" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>

      <div>
        {showLocationAndTimeSection && (
          <div className="shiftDetailsSubSection">
            <div className="shiftDetailsSubSectionTitle">
              <p className="shiftDetailsSubHeading">Location and Time</p>
            </div>

            {/* Render SiteField as disabled to give context when user is editing area */}
            {areaEditable && (
              <>
                <SiteField
                  site={{ id: shift.siteKey, name: shift.siteName }}
                  serviceKey={fields.service?.id ?? null}
                  onChange={() => {}}
                  disabled
                />
                <AreaField
                  siteKey={shift.siteKey}
                  area={fields.area}
                  onChange={onChangeArea}
                />
              </>
            )}

            {startTimeEditable && endTimeEditable && (
              <>
                <DateField
                  date={fields.startTime}
                  onChange={onChangeDate}
                  timezone={timezone}
                  disabled={!startTimeEditable}
                />

                <TimeField
                  disabled={!shiftEditableFields.includes('startTime') || !shiftEditableFields.includes('endTime')}
                  startTime={fields.startTime}
                  endTime={fields.endTime}
                  timezone={timezone}
                  onChangeStartTime={onChangeStartTime}
                  onChangeEndTime={onChangeEndTime}
                />
              </>
            )}
          </div>
        )}

        {slotsRequiredEditable && (
          <div className="shiftDetailsSubSection">
            <div className="shiftDetailsSubSectionTitle">
              <p className="shiftDetailsSubHeading">Shift Specifications</p>
            </div>
            <SlotsField
              slotsRequired={fields.slotsRequired}
              onChange={onChangeSlotsRequired}
              mandatory={mandatoryFields.includes('slotsRequired')}
              minimumSlotsRequired={shift.slotsFilled || 1}
              label="Vacancies"
            />
          </div>
        )}

        {showShiftReasonSection && (
          <div className="shiftDetailsSubSection">
            <div className="shiftDetailsSubSectionTitle">
              <p className="shiftDetailsSubHeading">Shift Reason</p>
            </div>

            {reasonEditable && (
              <ReasonField
                reason={fields.reason}
                onChange={onChangeReason}
                mandatory={mandatoryFields.includes('reason')}
              />
            )}

            {subReasonEditable && (
              <SubReasonField
                reasonKey={fields.reason?.id}
                subReason={fields.subReason}
                onChange={onChangeSubReason}
              />
            )}

            {publicDescriptionEditable && (
              <DescriptionField
                label="Public Description"
                placeholder="Displayed to staff when they view the shift"
                description={fields.publicDescription}
                onChange={onChangePublicDescription}
              />
            )}

            {privateNotesEditable && (
              <DescriptionField
                label="Private Notes"
                placeholder="Only visible to other admins"
                description={fields.privateNotes}
                onChange={onChangePrivateNotes}
              />
            )}

          </div>
        )}

        {costCentresOn && (
          <div className="shiftDetailsSubSection">
            <CostCentreField costCentre={costCentre} />
          </div>
        )}

        {rateCardEditable && (
          <div className="shiftDetailsSubSection" style={{ marginBottom: 12 }}>
            <div className="shiftDetailsSubSectionTitle">
              <p className="shiftDetailsSubHeading">Rate Card</p>
            </div>
            <RateCardField
              rateCards={applicableRateCards}
              rateCard={fields.rateCard}
              onChange={onChangeRateCard}
            />

            {customHourlyRateOn && (
              <>
                <HourlyRateField
                  label="Rate"
                  subLabel="(Per Hour)"
                  placeholder="0.00"
                  customHourlyRate={fields.customHourlyRate}
                  onChange={onChangeHourlyRate}
                  disabled={!fields.rateCard?.requireCustomHourlyRate}
                  mandatory={fields.rateCard?.requireCustomHourlyRate}
                  customHourlyRateValidation={customHourlyRateValidation}
                  estimatedCost={estimatedCost}
                />
              </>
            )}
          </div>
        )}
      </div>

      <div style={{ padding: spacing.small }}>
        {updateShiftError && <div style={{ marginBottom: spacing.small }}><InlineErrorMessage error={updateShiftError} /></div>}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            style={{ width: '48%' }}
            disabled={isUpdatingShift}
            onClick={() => props.goToDetailsTab()}
            white
            outline
            shadow={false}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '48%' }}
            disabled={submitButtonDisabled}
            onClick={() => updateShift()}
            black
            shadow={false}
          >
            Update Shift
          </Button>
        </div>
      </div>
    </div>
  );
}

interface InlineErrorMessageProps {
  error: string,
}

const InlineErrorMessage = (props: InlineErrorMessageProps) => {
  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <p style={{ color: colors.red, fontSize: 14 }}>{props.error}</p>
    </div>
  );
};

export default EditShift;
