import { camelCase, mapKeys } from 'lodash-es';
import * as api from '.';
import { subscribe, gql } from './apollo';
import { reportError } from '../error-reporting';

function updateApprover(updateData) {
  return api.post('timesheets/update-approver', updateData);
}

// Parameters are ids
async function resolveCostCentre(siteKey, areaKey, roleKey, gradeKey, specialityKey, reasonKey) {
  const response = await api.post('jobs/cost-centres/search', { siteKey, areaKey, roleKey, gradeKey, specialityKey, reasonKey });
  return response.body && response.body.costCentre;
}

function watchTimesheetMessages({ timesheetKey, callback, error }) {
  return subscribe({
    fetchPolicy: 'network-only',
    query: gql`
        subscription watchTimesheetMessages($timesheetKey: String!) {
          timesheet_timesheet_messages(where: {timesheet_key: {_eq: $timesheetKey}}, order_by: {sent_at: asc}) {
            from_user_key
            sent_at
            text
            title
            candidate { name }
            admin { display_name }
          }
        }
    `,
    variables: { timesheetKey },
    callback,
    error,
  });
}

function watchPaymentRuns({ callback, error }) {
  return subscribe({
    fetchPolicy: 'network-only',
    query: gql`
        subscription watchPaymentRuns {
          timesheet_payment_runs {
            key
            created_at
            settings
            scheduled_send_time
            sent_at
            scheduled_payment_time
            paid_at
            updated_at
          }
        }
    `,
    callback,
    map: (res) => {
      return (res?.data?.timesheet_payment_runs ?? []).map((element) => {
        const { shiftStartTimeCutOff, runType, ...unusedProps } = element.settings;
        return mapKeys({ ...element, settings: { shiftStartTimeCutOff, runType } }, (value, key) => camelCase(key));
      });
    },
    error,
  });
}

async function fetchPaymentRunSummaries() {
  const response = await makeAPIRequest({ method: 'GET', path: 'pay-run/list', fallbackErrorMessage: 'Fetching payment runs failed. If the problem persists, please contact customer support.' });
  return response.body;
}

async function fetchPaymentRunDetails(payRunKey) {

  const response = await makeAPIRequest({ method: 'GET', path: 'pay-run/details', options: { payRunKey }, fallbackErrorMessage: 'Fetching payment run details failed. If the problem persists, please contact customer support.' });
  return response.body;
}

async function downloadPaymentReport(paymentReportKey) {
  const response = await makeAPIRequest({ method: 'GET', path: 'pay-run/download-report', options: { paymentReportKey }, fallbackErrorMessage: 'Failed to download report. If the problem persists, please contact customer support.' });
  return response;
}

async function previewPaymentRun(paymentRunKey) {
  const response = await makeAPIRequest({ method: 'GET', path: 'pay-run/preview', options: { paymentRunKey }, fallbackErrorMessage: 'Failed to preview report. If the problem persists, please contact customer support.' });
  return response;
}

async function createPaymentRun(options) {
  const response = await makeAPIRequest({ method: 'POST', path: 'pay-run/create', options, fallbackErrorMessage: 'Failed to create payment run. If the problem persists, please contact customer support.' });
  return response.body;
}

async function updatePaymentRun(fields, payRunKey) {
  const response = await makeAPIRequest({ method: 'POST', path: 'pay-run/update', options: { ...fields, payRunKey }, fallbackErrorMessage: 'Failed to update payment run. If the problem persists, please contact customer support.' });
  return response.body;
}

async function runPaymentRunNow(paymentRunKey, markAsPaid) {
  const response = await makeAPIRequest({ method: 'POST', path: 'pay-run/run-now', options: { paymentRunKey, markAsPaid }, fallbackErrorMessage: 'Failed to run payment run. If the problem persists, please contact customer support.' });
  return response.body;
}

async function markPaymentRunAsPaid(paymentRunKey) {
  const response = await makeAPIRequest({ method: 'POST', path: 'pay-run/mark-as-paid', options: { paymentRunKey }, fallbackErrorMessage: 'Failed to mark payment run as paid. If the problem persists, please contact customer support.' });
  return response.body;
}

async function cancelPaymentRun(payRunKey) {
  const response = await makeAPIRequest({ method: 'POST', path: 'pay-run/delete', options: { payRunKey }, fallbackErrorMessage: 'Failed to cancel payment run. If the problem persists, please contact customer support.' });
  return response.body;
}

async function makeAPIRequest({ method, path, options, fallbackErrorMessage }) {
  const response = method === 'GET' ? await api.get(path, options) : await api.post(path, options);

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? fallbackErrorMessage;
    reportError(error instanceof Error ? error : new Error(error), {
      api: {
        path: response?.full?.path ?? path,
        status: response.status,
        error,
        details: response?.body?.details ?? null,
        stacktrace: response?.body?.stacktrace ?? null,
      },
      payload: options,
    });

    // Return human readable error message to component
    throw new Error(response?.body?.details?.humanReadableErrorMessage ?? fallbackErrorMessage);
  }

  return response;
}

const sendTimesheetMessage = messageData => api.post('timesheets/message', messageData);

export {
  updateApprover,
  resolveCostCentre,
  watchTimesheetMessages,
  sendTimesheetMessage,
  watchPaymentRuns,
  fetchPaymentRunSummaries,
  fetchPaymentRunDetails,
  downloadPaymentReport,
  previewPaymentRun,
  createPaymentRun,
  updatePaymentRun,
  runPaymentRunNow,
  markPaymentRunAsPaid,
  cancelPaymentRun,
};
