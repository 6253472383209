import React from 'react';
import { firstBy } from 'thenby';

import { RateCard } from 'types/Metadata';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface RateCardFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  rateCard: RateCard | null
  rateCards: Array<RateCard>,
}

function RateCardField(props: RateCardFieldProps) {
  const rateCardOptions = props.rateCards.sort(firstBy('precedence')).map((rc: RateCard) => ({ label: rc.name, value: rc.key }));

  return (
    <FieldLabel label="Rate Card">
      <JobsSelectBox
        name="rateCards"
        value={props.rateCard}
        placeholder="Rate Card *"
        data={rateCardOptions}
        onChange={props.onChange}
        mandatory
      />
    </FieldLabel>
  );
}

export default React.memo(RateCardField);
