import React from 'react';
import PropTypes from 'prop-types';
import colors from 'config/colors';
import spacing from 'config/spacing';

import DateFilter from '../Filters/DateFilter';
import SiteFilter from '../Filters/SiteFilter';
import SpecialityFilter from '../Filters/SpecialityFilter';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.backgroundColor}`,
    padding: 12,
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterContainer: {
    cursor: 'pointer',
    borderRadius: 2,
    width: 260,
    flex: '0 0 auto',
    marginRight: spacing.base,
  },
};

export default function DashboardHeader(props) {
  return (
    <div style={styles.container}>
      <div style={styles.filtersContainer}>
        <div style={styles.filterContainer}>
          {props.showSiteFilter && <SiteFilter />}
        </div>
        <div style={styles.filterContainer}>
          {props.showSpecialityFilter && <SpecialityFilter />}
        </div>
      </div>
      <DateFilter showDateGroupOptions={props.showDateGroupOptions} extraDateRangePickerProps={props.extraDateRangePickerProps} />
    </div>
  );
}

const { bool, object } = PropTypes;
DashboardHeader.propTypes = {
  showSiteFilter: bool,
  showSpecialityFilter: bool,
  showDateGroupOptions: bool,
  extraDateRangePickerProps: object,
};

DashboardHeader.defaultProps = {
  showSiteFilter: true,
  showDateGroupOptions: true,
  showSpecialityFilter: false,
  extraDateRangePickerProps: {},
};
