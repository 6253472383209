import React from 'react';

import colors from 'config/colors';

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode,
  justifyContent?: 'space-between' | 'flex-end',
}

const headerBarStyles = {
  padding: 6,
  borderBottom: `1px solid ${colors.cavalry.line}`,
  display: 'flex',
  flex: 0,
};

export default function DetailsHeaderBar(props: Props): React.ReactElement {
  return (
    <div style={{ ...headerBarStyles, justifyContent: props.justifyContent }}>
      {props.children}
    </div>
  );
}

DetailsHeaderBar.defaultProps = {
  justifyContent: 'space-between',
};
