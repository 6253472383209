import { gql, from, split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { HASURA_HOST, HASURA_USE_SSL, HASURA_SECRET } from '../../config/settings';
import { getToken } from '../auth';

let store;
function init(newStore) {
  store = newStore;
}

let reportError = (error, opts) => console.error(error, opts);
const setErrorHandler = (errorHandler) => {
  reportError = errorHandler;
};

async function getConnectionParams() {
  const token = await getToken();
  const state = store && store.getState();
  const orgKey = state && state.global && state.global.currentOrgKey;
  return {
    headers: {
      'x-cayman-app-secret': HASURA_SECRET,
      'x-cayman-org-key': orgKey,
      authorization: `Bearer ${token}`,
    },
  };
}

const httpLink = new HttpLink({
  uri: `${HASURA_USE_SSL ? 'https' : 'http'}://${HASURA_HOST}/v1/graphql`,
  options: {
    connectionParams: {
      headers: {
        'x-cayman-app-secret': HASURA_SECRET,
      },
    },
  },
});

const authLink = setContext(async (request, context) => {
  const connectionParams = await getConnectionParams();
  return { ...context, ...connectionParams };
});

const wsClient = new SubscriptionClient(
  `${HASURA_USE_SSL ? 'wss' : 'ws'}://${HASURA_HOST}/v1/graphql`,
  {
    lazy: true,
    reconnect: true,
    connectionParams: getConnectionParams,
  },
);
const wsLink = new WebSocketLink(wsClient);

let startTime = null;
wsClient.onConnecting((...args) => { startTime = Date.now(); console.debug('Apollo connecting', ...args); });
wsClient.onConnected((...args) => { console.debug(`Apollo connected (${Date.now() - startTime}ms)`, ...args); });
wsClient.onReconnecting((...args) => { startTime = Date.now(); console.debug('Apollo reconnecting', ...args); });
wsClient.onReconnected((...args) => { console.debug(`Apollo reconnected (${Date.now() - startTime}ms)`, ...args); });
wsClient.onDisconnected((...args) => console.debug('Apollodisconnected', ...args));
wsClient.onError((...args) => console.debug('Apooloerror', ...args));

function reconnect() {
  // // Copy current operations
  // const operations = Object.assign({}, wsClient.operations)
  wsClient.close(false);
  wsClient.connect();
  // // Push all current operations to the new connection
  // Object.keys(operations).forEach(id => wsClient.sendMessage(id, MessageTypes.GQL_START, operations[id].options))
}

// The split function takes three parameters:
// - A function that's called for each operation to execute
// - The Link to use for an operation if the function returns a "truthy" value
// - The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([authLink, httpLink]),
);

const apollo = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const subscribe = ({ query, variables, callback, error, map }) => {
  return apollo.subscribe({
    fetchPolicy: 'network-only',
    query,
    variables,
  }).subscribe((res) => {
    if (map) callback(map(res));
    else callback(res);
  }, (err) => {
    error(err);
    reportError(new Error(`Apollo subscription failed (${err?.extentions?.path ?? ''})`), {
      metaData: {
        query: { path: err?.extentions?.path ?? '' },
        error: {
          code: err?.extentions?.code ?? 500,
          message: err.message,
        },
      },
    });
  });
};


export {
  gql,
  apollo,
  init,
  reconnect,
  subscribe,
  setErrorHandler,
};
