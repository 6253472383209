import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import moment from 'moment-timezone';
import { keyBy } from 'lodash-es';

import { ReduxState } from 'reducers/index';
import * as templateActions from 'reducers/templates';
import * as createShiftActions from 'reducers/createShifts';

import * as templateApi from 'lib/api/templates';
import { getTemplateMandatoryFields, createDraftFromPublishedShift, createTemplateShiftFromDraftShift, addResponseDataToTemplateDraftShift, convertTemplateApiShiftToDraftFormat } from 'routes/Jobs/helpers';
import generateKey from 'lib/generate-key';

import { RateCard, Role, RgsMetadata, PreferredGender, ShiftCreateMetadata, Reason, CostCentre, ServiceMetadata } from 'types/Metadata';
import { Sites } from 'types/Sites';
import { PublishedShiftSummary, TemplateDraftShift, TemplateShiftFromApi, TemplatePeriod, TemplateSuccessfulResponse, TemplateUnsuccessfulResponse } from 'types/ShiftTypes';

function isSuccessfulResponse(response: TemplateSuccessfulResponse | TemplateUnsuccessfulResponse): response is TemplateSuccessfulResponse {
  return !!(response as TemplateSuccessfulResponse).success;
}

function checkPeriodType(shifts: PublishedShiftSummary[]) : TemplatePeriod {

  const shiftDates = [...new Set(shifts.map((shift: PublishedShiftSummary) => moment.utc(shift.startTime).format('YYYY-MM-DD')))];
  if (shiftDates.length === 1) return 'day';
  if (shiftDates.length <= 7) return 'week';
  return 'month';
}

export function saveToTemplate(shifts: PublishedShiftSummary[], name: string, saveBookedCandidates: boolean): ThunkAction<Promise<TemplateSuccessfulResponse | TemplateUnsuccessfulResponse | null>, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {

    const { global, user } = getState();
    const orgRateCards = global.shiftCreateMetadata?.orgRateCards ?? [];
    const periodType = checkPeriodType(shifts);

    // Convert published shifts into draft shifts, then into template draft shifts
    const templateDraftShifts = shifts
      .map((shift) => createDraftFromPublishedShift({
        publishedShift: shift,
        preferredGender: global.orgConfig?.preferredGender,
        adminBanks: global.adminBanks,
        orgRateCards,
        saveCandidate: saveBookedCandidates,
      }))
      .map((shift) => {
        const timezone = user.sites[shift.site?.id]?.timezone ?? 'Europe/London';
        return createTemplateShiftFromDraftShift(shift, timezone, periodType);
      });

    try {
      dispatch(templateActions.createTemplate());
      const response = await templateApi.createTemplate({ templateDrafts: templateDraftShifts, name, periodType });

      // If success, return response to component
      if (isSuccessfulResponse(response)) {
        dispatch(templateActions.createTemplateSuccess());
        return response;
      }

      // If shifts failed validation, convert to drafts, and nav user to blank template view with validated/failed shifts
      if (!isSuccessfulResponse(response)) {
        const shiftsFromResponse = keyBy([...response.validatedShifts, ...response.failedShifts], 'key');

        const templateDraftsWithResponseData : { [key: string]: TemplateDraftShift } = {};

        templateDraftShifts.forEach((shift) => {
          // Add response props from api to template draft shift
          const error = shiftsFromResponse[shift.key].error ?? null;
          const templateDraftWithResponseData = addResponseDataToTemplateDraftShift(shift, error);
          templateDraftsWithResponseData[shift.key] = templateDraftWithResponseData;
        });

        dispatch(createBlankTemplate(periodType, name, templateDraftsWithResponseData));
        dispatch(templateActions.validationError(response.humanReadableErrorMessage));
        return response;
      }
    } catch (error) {
      if (error instanceof Error) dispatch(templateActions.createTemplateError(error.message));
      return error;
    }

    return null;
  };
}

export function fetchTemplate(templateKey: string): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {

    try {
      dispatch(templateActions.fetchTemplate());
      const response = await templateApi.fetchTemplate(templateKey);

      if (response.templateIncludesDisabledSites && response.humanReadableErrorMessage) {
        dispatch(templateActions.templateIncludesDisabledSitesError(response.humanReadableErrorMessage));
      } else {
        const templateMetadata = { key: response.template.key, name: response.template.name, periodType: response.template.periodType };
        dispatch(openTemplate({ templateShiftsFromApi: response.template.shifts, templateMetadata }));
        dispatch(templateActions.fetchTemplateSuccess(templateMetadata));
      }

    } catch (error) {
      if (error instanceof Error) dispatch(templateActions.fetchTemplateError(error.message));
    }
  };
}

export function fetchTemplateList(): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {

    try {
      dispatch(templateActions.fetchTemplateList());
      const response = await templateApi.fetchTemplateList();
      dispatch(templateActions.fetchTemplateListSuccess(response.templates));

    } catch (error) {
      if (error instanceof Error) dispatch(templateActions.fetchTemplateListError(error.message));
    }
  };
}

export function createTemplate(): ThunkAction<Promise<TemplateSuccessfulResponse | TemplateUnsuccessfulResponse | null>, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {

    const { templates, createShifts } = getState();

    const template = templates.template;
    const templateDrafts: { [key: string]: TemplateDraftShift } = createShifts.templateShifts;
    const templateDraftsArray = Object.entries(templateDrafts).map(([key, draft]) => ({ ...draft, key }));

    try {
      dispatch(templateActions.saveTemplate());
      const response = await templateApi.createTemplate({ name: template.name, periodType: template.periodType, templateDrafts: templateDraftsArray });
      const templateMetadata = { key: response.key, name: response.name, periodType: response.periodType };

      if (isSuccessfulResponse(response)) {
        dispatch(openTemplate({ templateShiftsFromApi: response.shifts, templateMetadata }));
        dispatch(templateActions.saveTemplateSuccess(templateMetadata));
      }

      if (!isSuccessfulResponse(response)) {
        const combinedShiftsFromResponse = keyBy([...response.validatedShifts, ...response.failedShifts], 'key');
        dispatch(createShiftActions.addResponseDataToTemplateDrafts(combinedShiftsFromResponse));
        dispatch(templateActions.validationError(response.humanReadableErrorMessage));
      }

      return response;
    } catch (error) {
      if (error instanceof Error) dispatch(templateActions.saveTemplateError(error.message));
    }
    return null;
  };
}

export function updateTemplate(): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {

    const { templates, createShifts } = getState();

    const template = templates.template;
    const templateDrafts: { [key: string]: TemplateDraftShift } = createShifts.templateShifts;
    const templateDraftsArray = Object.entries(templateDrafts).map(([key, draft]) => ({ ...draft, key }));

    try {
      dispatch(templateActions.saveTemplate());
      const response = await templateApi.saveTemplate({ key: template.key, name: template.name, periodType: template.periodType, templateDrafts: templateDraftsArray });
      const templateMetadata = { key: response.key, name: response.name, periodType: response.periodType };

      if (isSuccessfulResponse(response)) {
        dispatch(openTemplate({ templateShiftsFromApi: response.shifts, templateMetadata }));
        dispatch(templateActions.saveTemplateSuccess(templateMetadata));
      }

      if (!isSuccessfulResponse(response)) {
        const combinedShiftsFromResponse = keyBy([...response.validatedShifts, ...response.failedShifts], 'key');
        dispatch(createShiftActions.addResponseDataToTemplateDrafts(combinedShiftsFromResponse));
        dispatch(templateActions.validationError(response.humanReadableErrorMessage));
      }
    } catch (error) {
      if (error instanceof Error) dispatch(templateActions.saveTemplateError(error.message));
    }
  };
}

interface OpenTemplateOptions {
  templateShiftsFromApi: TemplateShiftFromApi[],
  templateMetadata: {
    key: string,
    name: string,
    periodType: TemplatePeriod,
  }
}

export function openTemplate({ templateShiftsFromApi, templateMetadata }: OpenTemplateOptions): ThunkAction<void, ReduxState, unknown, AnyAction> {
  return async (dispatch, getState) => {

    // Get shift metadata from redux
    const { user, rgs, global } = getState();
    const services: ServiceMetadata[] = global.services;
    const sites: Sites = user.sites;
    const roles: Role[] = rgs.roles;
    const rgsMetadata: RgsMetadata = rgs.rgsMetadata;
    const hasPreferredGender = global.orgConfig?.preferredGender?.enabled;
    const preferredGender: PreferredGender = (hasPreferredGender && global.orgConfig?.preferredGender) || null;
    const currentOrgKey: string = global.currentOrgKey;
    const shiftMandatoryFields = global?.orgConfig?.shiftMandatoryFields ?? [];
    const mandatoryFields = getTemplateMandatoryFields(shiftMandatoryFields, templateMetadata.periodType);
    const shiftCreateMetadata: ShiftCreateMetadata = global.shiftCreateMetadata;
    const rateCards: RateCard[] = shiftCreateMetadata?.orgRateCards ?? [];
    const shiftReasons: Reason[] = shiftCreateMetadata?.orgShiftReasons ?? [];
    const costCentres: CostCentre[] = shiftCreateMetadata?.orgCostCentres ?? [];

    const templateDraftShifts : { [key: string]: TemplateDraftShift } = {};

    templateShiftsFromApi.forEach((shift) => {
      // Create template draft shift from api response
      const templateDraftShift = convertTemplateApiShiftToDraftFormat({
        shift,
        sites,
        roles,
        rgsMetadata,
        preferredGender,
        rateCards,
        shiftReasons,
        costCentres,
        mandatoryFields,
        services,
        orgKey: currentOrgKey,
      });
      templateDraftShifts[shift.key] = templateDraftShift;
    });

    // Save template draft shifts to createShifts state
    dispatch(createShiftActions.setTemplateShifts(templateDraftShifts));
  };
}

export function createBlankTemplate(periodType: TemplatePeriod, name?: string, shifts?: { [key: string]: TemplateDraftShift }) : ThunkAction<void, ReduxState, unknown, AnyAction> {
  return (dispatch) => {
    dispatch(templateActions.saveTemplateSuccess({ key: generateKey(), name: name ?? 'Untitled Template', periodType }));
    if (shifts) dispatch(createShiftActions.setTemplateShifts(shifts));
  };
}

export function deleteTemplate(templateKey: string) : ThunkAction<Promise<boolean>, ReduxState, unknown, AnyAction> {
  return async (dispatch) => {

    dispatch(templateActions.deleteTemplate());

    try {
      const response = await templateApi.deleteTemplate(templateKey);

      if (response.success) {
        // If successful, fetch updated template list and remove template from Redux
        await dispatch(fetchTemplateList());
        dispatch(templateActions.clearTemplate());
        dispatch(createShiftActions.clearTemplateShifts());
        return true;
      }
    } catch (error) {
      if (error instanceof Error) dispatch(templateActions.deleteTemplateError(error.message));
    }
    return false;
  };
}
