import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';

const { string } = PropTypes;

class DashboardContainer extends Component {
  static propTypes = {
    userId: string,
  }

  static defaultProps = {
    userId: null,
  }

  render() {
    return (
      <Dashboard userId={this.props.userId} />
    );
  }
}

function mapStateToProps({ user }) {
  return {
    userId: user.userId,
  };
}

export default connect(mapStateToProps)(DashboardContainer);
