import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';

import colors from 'config/colors';
import { permissions } from 'config/permissions';
import { featureFlags } from 'config/featureFlags';
import { isFeatureOn } from 'lib/features';

import { suspendCandidate } from 'thunks/compliance';
import { fetchCandidateBanks, fetchCandidateProfile } from 'thunks/candidate';
import { clearCandidateData } from 'reducers/candidate';

import FullSizeModal from 'components/FullSizeModal';
import Loading from 'components/Loading';
import { TabBar } from 'components/Tabs';

import CandidateProfileHeader from './CandidateProfileHeader';
import SuspendModal from './SuspendModal';

import BasicInfoTab from './Tabs/BasicInfoTab';
import RgsTab from './Tabs/RgsTab';
import ContractTab from './Tabs/ContractTab';
import ComplianceTab from './Tabs/ComplianceTab';
import ShiftsTab from './Tabs/ShiftsTab';
import CandidateAuditTrail from './Tabs/CandidateAuditTrail';
import RatingsTab from './Tabs/RatingsTab';
import ManageTab from './Tabs/ManageTab';

import './CandidateProfileModal.scss';

const { string, objectOf, object, bool, func, number, oneOfType, arrayOf } = PropTypes;
class CandidateProfileModal extends Component {
  static propTypes = {
    isOpen: bool,
    orgConfig: objectOf(
      oneOfType([
        string,
        object,
        number,
        bool,
      ]),
    ),
    candidateId: string,
    onRequestClose: func,
    suspendCandidate: func,
    accountIsSuspended: bool,
    isLoadingSuspension: bool,
    orgCandidateRelationship: objectOf(oneOfType(
      [string,
        object,
        number,
        bool,
        arrayOf(oneOfType([string])),
      ]),
    ),
    candidateComplianceStatus: arrayOf(object),
    isFetchingCandidateProfile: bool,
    candidateRemovalError: bool,
    removedAt: string,
    adminOrgKey: string,
    candidateOrgKey: string,
    tabKey: string,
    profile: object,
  }

  static defaultProps = {
    isOpen: false,
    candidateId: null,
    onRequestClose: () => { },
    accountIsSuspended: false,
    isLoadingSuspension: false,
    profile: null,
    candidateComplianceStatus: null,
    orgCandidateRelationship: null,
    removedAt: null,
    candidateRemovalError: null,
    candidateOrgKey: null,
    tabKey: null,
    orgConfig: [],
    adminOrgKey: null,
    isFetchingCandidateProfile: false,
    suspendCandidate: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTab: null,
      suspensionModalIsOpen: false,
    };
  }

  async componentDidMount() {
    const candidate = await this.fetchCandidate();
    this.setState({ currentTab: this.props.tabKey || (candidate ? 'basicInformation' : 'compliance') });
  }

  componentDidUpdate(prevProps) {
    if (this.props.candidateId !== prevProps.candidateId) this.fetchCandidate();
  }

  componentWillUnmount() {
    this.props.clearCandidateData();
  }

  fetchCandidate = () => {
    return this.props.fetchCandidateProfile(this.props.candidateId);
  }

  openSuspensionModal = () => this.setState({ suspensionModalIsOpen: true });

  closeSuspensionModal = () => this.setState({ suspensionModalIsOpen: false });

  onRequestClose = async () => {
    this.props.onRequestClose();
  }

  suspendCandidate = async (suspend, reason) => {
    await this.props.suspendCandidate(suspend, reason);
  }

  render() {
    const {
      isOpen,
      isMobile,
      candidateId,
      candidateOrgKey,
      adminOrgKey,
      accountIsSuspended,
      isLoadingSuspension,
      profile,
      orgCandidateRelationship,
      candidateComplianceStatus,
      isFetchingCandidateProfile,
      fetchCandidateError,
      hasSuspendPermission,
      ratingsFeatureEnabled,
      hasRemoveFromBankPermission,
      contractFeatureIsOn,
    } = this.props;

    const {
      currentTab,
      suspensionModalIsOpen,
    } = this.state;

    const mainOrg = profile?.orgs?.[profile?.mainOrgKey];
    const showCandidateProfileModal = profile && !isFetchingCandidateProfile;

    // Permissions
    const canManageStaffPermissions = !!profile?.currentAdminPermissions?.canManageStaff;
    const candidateIsInAdminsOrg = (candidateOrgKey === adminOrgKey);
    const adminHasWriteAccess = canManageStaffPermissions;//candidateIsInAdminsOrg;
    const tabs = [
      !!profile && { key: 'basicInformation', title: isMobile ? 'Info' : 'Basic Information' },
      contractFeatureIsOn && { key: 'contract', title: 'Contract' },
      !!profile && { key: 'rgs', title: isMobile ? 'RGS' : 'Roles, Grades & Specialities' },
      { key: 'compliance', title: 'Compliance' },
      !!profile && { key: 'shifts', title: 'Shifts' },
      ratingsFeatureEnabled && { key: 'ratings', title: 'Ratings' },
      !!profile && adminHasWriteAccess && (hasSuspendPermission || hasRemoveFromBankPermission) && { key: 'manageAccount', title: isMobile ? 'Manage' : 'Manage Account' },
      !!profile && adminHasWriteAccess && { key: 'auditTrail', title: 'Audit Trail' },
    ].filter(Boolean);

    return (
      <FullSizeModal
        isOpen={isOpen}
        contentLabel="New Candidate Profile"
        onRequestClose={this.onRequestClose}
        style={{ zIndex: 6000 }}
        offsetLeft={this.props.offsetLeft}
        offsetRight={this.props.offsetRight}
      >
        {!!fetchCandidateError && (
          <div style={{ position: 'relative', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ position: 'absolute', top: 6, left: 6 }}>
              <MdArrowBack
                className="rise"
                onClick={this.onRequestClose}
                style={{ color: colors.sidebarBackgroundEvenLighter, height: 40, width: 40 }}
              />
            </div>
            <p style={{ color: colors.text }}>No candidate found.</p>
          </div>
        )}

        {isFetchingCandidateProfile && (
          <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
        )}

        {showCandidateProfileModal && (
          <>
            <div className="profile-container">
              <CandidateProfileHeader
                candidateId={candidateId}
                profile={profile}
                isFetchingCandidateProfile={isFetchingCandidateProfile}
                candidateComplianceStatus={candidateComplianceStatus}

                accountIsSuspended={this.props.accountIsSuspended}
                removedAt={this.props.removedAt}
                isLoadingSuspension={isLoadingSuspension}
                openSuspensionModal={this.openSuspensionModal}

                hasSuspendPermission={hasSuspendPermission}
                hasRemoveFromBankPermission={hasRemoveFromBankPermission}

                isMobile={isMobile}
                onRequestClose={this.onRequestClose}
              />
              <TabBar tabs={tabs} selectedTabKey={currentTab} onTabClick={tabKey => this.setState({ currentTab: tabKey })} />
            </div>
            <div className="main-container">
              <div className="info-container">
                {!!profile && currentTab === 'basicInformation' && (
                  <BasicInfoTab
                    adminHasWriteAccess={adminHasWriteAccess}
                    candidateKey={this.props.candidateId}
                  />
                )}
                {!!profile && currentTab === 'contract' && (
                  <ContractTab
                    adminHasWriteAccess={adminHasWriteAccess}
                    candidateKey={this.props.candidateId}
                  />
                )}
                {!!profile && currentTab === 'rgs' && (
                  <RgsTab
                    adminHasWriteAccess={adminHasWriteAccess}
                    isFetchingCandidateProfile={this.props.isFetchingCandidateProfile}
                    fetchCandidate={this.fetchCandidate}
                  />
                )}
                {currentTab === 'compliance' && (
                  <ComplianceTab
                    adminHasWriteAccess={adminHasWriteAccess}
                    candidateKey={this.props.candidateId}
                  />
                )}
                {!!profile && currentTab === 'shifts' && (
                  <ShiftsTab
                    adminHasWriteAccess={adminHasWriteAccess}
                    candidateKey={this.props.candidateId}
                    onShiftChange={this.props.onShiftChange}
                  />
                )}
                {currentTab === 'ratings' && (
                  <RatingsTab candidateKey={this.props.candidateId} />
                )}
                {!!profile && adminHasWriteAccess && currentTab === 'manageAccount' && (
                  <ManageTab />
                )}
                {!!profile && adminHasWriteAccess && currentTab === 'auditTrail' && (
                  <CandidateAuditTrail
                    candidateKey={this.props.candidateId}
                  />
                )}
              </div>
            </div>
            <SuspendModal
              suspendCandidate={this.suspendCandidate}
              accountIsSuspended={accountIsSuspended}
              profile={profile}
              closeSuspensionModal={this.closeSuspensionModal}
              suspensionModalIsOpen={suspensionModalIsOpen}
              orgCandidateRelationship={orgCandidateRelationship}
              orgConfig={this.props.orgConfig}
            />
          </>
        )}
      </FullSizeModal>
    );
  }
}


function mapStateToProps({ candidate, screen, global, compliance, user, bank }) {
  const mainOrg = candidate.profile?.orgs?.[candidate.profile?.mainOrgKey];
  return {
    isMobile: screen.isMobile,
    isLoadingSuspension: compliance.isLoadingSuspension,
    userId: user.userId,
    orgConfig: global.orgConfig,
    accountIsSuspended: !!mainOrg?.accountSuspended,
    removedAt: mainOrg?.removedAt,
    isEditingInviteMetadata: bank.isEditingInviteMetadata,
    orgKey: global.currentOrgKey,
    candidateRemovalError: compliance.candidateRemovalError,
    hasSuspendPermission: isFeatureOn(null, permissions.BANK_CAN_SUSPEND, user, global),
    ratingsFeatureEnabled: !!candidate.profile?.ratingsFeatureEnabled,
    hasRemoveFromBankPermission: isFeatureOn(null, permissions.BANK_CAN_REMOVE, user, global),
    specialitiesByGradeFeatureOn: isFeatureOn(featureFlags.SPECIALITIES_BY_GRADE, null, user, global),
    contractFeatureIsOn: isFeatureOn(featureFlags.CONTRACTED_HOURS, null, user, global),
    profile: candidate.profile,
    orgCandidateRelationship: candidate.candidateOrgRelationship,
    candidateComplianceStatus: compliance.candidateComplianceStatus,
    isFetchingCandidateProfile: candidate.isFetchingCandidateProfile || candidate.isFetchingCandidateOrgRelationship || candidate.fetchingCandidateBanks,
    isFetchingCandidateComplianceStatus: compliance.isFetchingCandidateComplianceStatus,
    hideWorkHistory: !candidate.candidateOrgRelationship?.profileComplete,
    adminOrgKey: global.currentOrgKey,
    candidateOrgKey: candidate.profile?.mainOrgKey,
    fetchingCandidateBanks: candidate.fetchingCandidateBanks,
    fetchCandidateError: candidate.error ?? null,
    customFieldsMetadata: global.customFieldsMetadata,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    suspendCandidate: (suspend, reason) => dispatch(suspendCandidate(ownProps.candidateId, suspend, reason)),
    fetchCandidateProfile: candidateId => dispatch(fetchCandidateProfile(candidateId)),
    fetchCandidateBanks: candidateKey => dispatch(fetchCandidateBanks(candidateKey)),
    clearCandidateData: () => dispatch(clearCandidateData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfileModal);
