import React, { useState } from 'react';

import RadioButtonList from 'components/RadioButtonList';
import ComposeNonBatchMessage from './ComposeNonBatchMessage';
import ComposeBatchMessage from './ComposeBatchMessage';

export default function NewMessage() : React.ReactElement {

  const TYPES = [
    { key: 'individual', displayText: 'Individual/Small Group' },
    { key: 'broadcast', displayText: 'Broadcast' },
  ];

  const [selectedTypeKey, setSelectedTypeKey] = useState(TYPES[0].key);

  return (
    <>
      <div className="messagingFromToContainer">
        <p style={{ fontSize: '1rem', fontWeight: 600 }}>Type:</p>
        <RadioButtonList selectedKey={selectedTypeKey} items={TYPES} onSelect={setSelectedTypeKey} />
      </div>
      {selectedTypeKey === 'individual' && <ComposeNonBatchMessage />}
      {selectedTypeKey === 'broadcast' && <ComposeBatchMessage />}
    </>
  );
}
