import { mapValues, pick } from 'lodash-es';
import { v2SetAllSelectedItems } from '../reducers/filter';

export function stripDisabledFilterValues() {
  return async (dispatch, getState) => {

    const orgConfig = getState().global.orgConfig;
    const enabledFilterKeys = {
      shifts: orgConfig?.enabledShiftFilters?.map(filter => filter.key) ?? [],
      bank: orgConfig?.enabledBankFilters?.map(filter => filter.key) ?? [],
    };

    const allSelectedItems = getState().filter.v2SelectedItems;
    const newSelectedItems = mapValues(allSelectedItems, (nsSelectedItems, namespace) => {
      return pick(nsSelectedItems, enabledFilterKeys[namespace]);
    });

    dispatch(v2SetAllSelectedItems(newSelectedItems));
  };
}
