import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { MdClose } from 'react-icons/md';
import NotificationType from './NotificationType';
import './styles.scss';
import { getFullDateShortDay } from '../../lib/helpers-time';
import { urlMapper } from './urlMapper';

function candidateName(data) {
  if (data.candidate && data.type !== 'timesheet') return data.candidate.name;
  return '';
}

function candidateRole(data) {
  return data.candidate?.role?.name ? ` (${data.candidate?.role?.name})` : '';
}

function eventMessage(data) {
  if (data.candidate && (data.type === 'application' || data.type === 'cancellation')) {
    const speciality = data?.jobSpeciality?.name;
    return `${speciality && speciality !== 'All' ? `${speciality}\n` : ''}${data.message}`;
  } else if (data.candidate && data.type === 'timesheet') {
    return `${data.candidate.name} submitted a timesheet`;
  } else if (data.candidate && data.type === 'status-change-withdrawl') {
    return `${data.candidate.name} has withdrawn their application`;
  }
  return data.message;
}

function jobStartDate(data) {
  if (data.candidate && (data.type === 'application' || data.type === 'cancellation')) {
    return `${getFullDateShortDay(data.jobStartTime)}`;
  } return '';
}

export default function Notification(props) {
  const { data, onClick, removeNotification, removed } = props;

  let notificationMessage;
  if (data.preBaked) {
    notificationMessage = data.message;
  } else {
    notificationMessage = `${candidateName(data)}${candidateRole(data)}\n${eventMessage(data)}\n${jobStartDate(data)}`;
  }

  return (
    <div className={`notificationContainer ${removed ? 'removedNotification' : null}`}>
      <div
        className="notificationDataContainer"
        role="button"
        onKeyPress={() => {}}
        tabIndex={0}
        onClick={() => onClick(urlMapper(data), data.id)}
      >
        <div className="notificationTopContainer"><NotificationType type={data.type} /></div>
        <div
          className={classNames({
            notificationMessageContainer: true,
            notificationMessageContainerRead: !!data.read,
            notificationMessageContainerUnread: !data.read,
          })}
        >
          {notificationMessage.replace(/^\s|\s$/g, '')}
        </div>
        <div
          className={classNames({
            notificationTimeContainer: true,
            notificationTimeContainerRead: !!data.read,
            notificationTimeContainerUnread: !data.read,
          })}
        >
          {moment(data.when).fromNow()}
        </div>
      </div>
      {props.notificationSpecificity === 1 && (
        <div
          className="notificationRemoveContainer"
          onClick={() => removeNotification(data.id)}
          role="button"
          onKeyPress={() => {}}
          tabIndex={0}
        >
          <MdClose />
        </div>
      )}
    </div>
  );
}

const { shape, objectOf, string, bool, func } = PropTypes;
Notification.propTypes = {
  data: shape({
    candidate: shape({
      gender: shape({
        id: string,
        name: string,
      }),
      grades: objectOf(string),
      id: string.isRequired,
      name: string.isRequired,
      role: objectOf(string),
      specialities: objectOf(string),
    }).isRequired,
    jobEndTime: string.isRequired,
    jobStartTime: string.isRequired,
    message: string.isRequired,
    orgKey: string.isRequired,
    recipients: objectOf(bool),
    siteId: string.isRequired,
    type: string.isRequired,
    when: string.isRequired,
  }).isRequired,
  onClick: func.isRequired,
  removeNotification: func.isRequired,
  removed: bool.isRequired,
};
