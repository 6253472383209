import React, { useState, useMemo, useCallback } from 'react';

import colors from 'config/colors';
// import useGetApi from 'hooks/useGetApi';
import useAsync from 'hooks/useAsync';
import { useFeatureFlag } from 'hooks/feature';

import { Member } from 'types/AdminGroup';
import { removeAdminGroupMembers, updateAdminGroupMembers } from 'lib/api/admin-groups';

import Button from 'components/Button';
import FilterBox from 'components/FilterBox';
import DataTable from 'components/DataTable';
import MoreButton from 'components/MoreButton';

import DetailsHeaderBar from './DetailsHeaderBar';
import AddMemberModal from './AddMemberModal';

const styles = {
  errorMessage: {
    padding: 12,
    color: colors.white,
    backgroundColor: colors.red,
    lineHeight: 1.3,
    fontSize: 14,
  },
};

function useFilteredMembers(members: Member[]) {
  const [filterString, setFilterString] = useState('');

  const filteredMembers = useMemo(() => {
    return members.filter(member => member.name.toLowerCase().includes(filterString.toLowerCase()));
  }, [filterString]);

  return { filterString, setFilterString, filteredMembers };
}

interface Props {
  members: Member[] | null;
  refreshGroup: () => void;
  adminGroupKey: string;
  canManage: boolean;
}

export default function AdminGroupMembers(props: Props): React.ReactElement {

  const [addMemberModalIsOpen, setAddMemberModalIsOpen] = useState(false);
  const { filterString, setFilterString, filteredMembers } = useFilteredMembers(props.members ?? []);
  const adminGroupMemberManagementFeatureIsOn = useFeatureFlag('adminGroupMemberManagement');

  // Remove member
  const removeAdminGroupMembersFetcher = useCallback(async (adminKey: string) => {
    const result = await removeAdminGroupMembers([props.adminGroupKey], [adminKey]);
    props.refreshGroup();
    return result;
  }, [props.adminGroupKey]);
  const removeMembers = useAsync<void>();

  // Set member manager permission
  const updateAdminGroupMembersFetcher = useCallback(async (adminKey: string, canManageMembers: boolean) => {
    const result = await updateAdminGroupMembers([props.adminGroupKey], [adminKey], { canManageMembers });
    props.refreshGroup();
    return result;
  }, [props.adminGroupKey]);
  const updateMembers = useAsync<void>();


  const membersListColumns = useMemo(() => {

    return [
      { name: 'name', header: 'Name' },
      { name: 'memberManager', header: 'Member Manager', formatter: (name: string, row: Member) => (row.permissions.canManageMembers ? 'Yes' : 'No') },
      props.canManage && adminGroupMemberManagementFeatureIsOn && {
        name: 'actions',
        width: 60,
        header: 'Actions',
        formatter: (name: string, row: Member) => {
          const adminKey = row.key;
          const canManageMembers = row.permissions.canManageMembers;
          const menuButtonItems = [
            {
              key: 'remove-member',
              label: 'Remove from group',
              color: colors.red,
              onClick: () => removeMembers.run(removeAdminGroupMembersFetcher(adminKey)),
            },
            {
              key: 'set-member-manager',
              label: canManageMembers ? 'Remove permission to manage members' : 'Grant permission to manage members',
              onClick: () => updateMembers.run(updateAdminGroupMembersFetcher(adminKey, !canManageMembers)),
            },
          ];
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MoreButton menuItems={menuButtonItems} openMenuDirection="right" />
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, [props.members, updateAdminGroupMembersFetcher, removeAdminGroupMembersFetcher, props.canManage]);

  const memberKeys = useMemo(() => props.members?.map(member => member.key) ?? [], [props.members]);

  return (
    <>
      {removeMembers.error && (<p style={styles.errorMessage}>{removeMembers.error}</p>)}

      {/* Header */}
      <DetailsHeaderBar>
        <FilterBox
          filterString={filterString}
          setFilterString={setFilterString}
          placeholder="Filter members by name..."
          style={{ width: 200, height: 26, borderRadius: 4, border: `1px solid ${colors.cavalry.line}`, color: colors.text, fontSize: '0.9rem' }}
        />
        {props.canManage && adminGroupMemberManagementFeatureIsOn && <Button black shadow={false} onClick={() => setAddMemberModalIsOpen(true)}>+ Member</Button>}
      </DetailsHeaderBar>


      {/* Member list */}
      {props.members?.length ?
        <div style={{ flex: 1, overflow: 'auto' }}>
          <DataTable<Member, undefined>
            columns={membersListColumns as any}
            rows={filteredMembers}
            onRowClick={() => {
            }}
            cellStyle={{ color: '#333' }}
            overrideHeadingStyles={{
              color: colors.text,
              textTransform: 'none',
              fontSize: '0.9rem',
            }}
          />
        </div>
        :
        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <p style={{ color: colors.text }}>There are no members in this admin group.</p>
        </div>
      }

      {/* Add member modal */}
      {addMemberModalIsOpen && props.canManage && (
        <AddMemberModal
          onClose={() => setAddMemberModalIsOpen(false)}
          existingGroupMembers={memberKeys}
          refreshGroup={props.refreshGroup}
          adminGroupKey={props.adminGroupKey}
        />
      )}
    </>
  );
}
