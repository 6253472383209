import * as scr from '../config/screenSize';

const SCREEN_DIMENSIONS_DID_CHANGE = 'SCREEN_DIMENSIONS_DID_CHANGE';
const SCREEN_NAV_HEIGHT_DID_CHANGE = 'SCREEN_NAV_HEIGHT_DID_CHANGE';
const SCREEN_RESET_STATE = 'SCREEN_RESET_STATE';

//----------------------------------------------------------------
// ACTION CREATORS
//----------------------------------------------------------------

export function dimensionsDidChange(dimensions) {
  return {
    type: SCREEN_DIMENSIONS_DID_CHANGE,
    dimensions,
  };
}

export function navHeightDidChange(navHeight) {
  return {
    type: SCREEN_NAV_HEIGHT_DID_CHANGE,
    navHeight,
  };
}

export function resetState() {
  return {
    type: SCREEN_RESET_STATE,
  };
}

//----------------------------------------------------------------
// REDUCER
//----------------------------------------------------------------

const initialState = {
  width: 0,
  height: 0,
  size: 'phoneMedium',
};

export default function screen(state = initialState, action) {
  switch (action.type) {
    case SCREEN_DIMENSIONS_DID_CHANGE: {
      const { size, isMobile } = scr.getScreenSize(action.dimensions);
      return {
        ...state,
        ...action.dimensions,
        size,
        isMobile,
      };
    }
    case SCREEN_NAV_HEIGHT_DID_CHANGE: {
      return {
        ...state,
        ...action.navHeight,
      };
    }
    case SCREEN_RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
