
import React from 'react';
import moment from 'moment-timezone';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import DropDown from 'components/DropDown';

const MINUTE_INTERVAL = 15;

const customSelectBoxStyles = {
  valueContainer: { lineHeight: 1, fontWeight: 400 },
};

interface TimePickerProps {
  minuteInterval?: number
  startOfRange: moment.Moment,
  endOfRange: moment.Moment | null,
  value: string | null,
  placeholder?: string,
  timezone: string,
  onTimeChange: (time : { hour: number, minute: number }) => void,
  isDisabled: boolean
}

const TimePicker: React.FC<TimePickerProps> = (props: TimePickerProps) => {

  const minuteInterval = props.minuteInterval ?? MINUTE_INTERVAL;
  const endOfDay = props.startOfRange.clone().endOf('day');

  const options = [];

  for (let time = props.startOfRange.clone(); time.isSameOrBefore(props.endOfRange ?? endOfDay); time.add(minuteInterval, 'minutes')) {
    options.push({ value: time.toISOString(), label: time.format('HH:mm') });
  }

  // Add end of day option if there is no set endOfRange
  if (!props.endOfRange) options.push({ value: endOfDay.toISOString(), label: 'End Of Day' });

  const valueAsNormalisedISOString = props.value ? moment.utc(props.value).toISOString() : null;
  const selectedValue = valueAsNormalisedISOString ? options.find(option => option.value === valueAsNormalisedISOString) : null;

  return (
    <div>
      <DropDown
        placeholder={props.placeholder}
        defaultValue={selectedValue}
        value={selectedValue}
        onChange={(time) => {
          const timezoneValue = convertFromUtcToTimezone(time.value, props.timezone);
          props.onTimeChange({ hour: timezoneValue.hour(), minute: timezoneValue.minute() });
        }}
        options={options}
        customStyles={customSelectBoxStyles}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

TimePicker.defaultProps = { minuteInterval: undefined, placeholder: undefined };

export default TimePicker;
