import { useState, useCallback, useMemo } from 'react';

interface GetApi<T> {
  isLoading: boolean;
  error: string | null;
  data: T | null;
  fetch: () => Promise<void>;
}

export default function useApi<Response>(fetcher: () => Promise<Response | null>) : GetApi<Response> {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<Response | null>(null);

  const fetch = useCallback(async () => {

    setIsLoading(true);

    try {
      const res = await fetcher();
      setData(res);
      setError(null);
      setIsLoading(false);
    } catch (e) {
      setError(e?.humanReadableErrorMessage ?? e.message ?? 'Something wen\'t wrong');
      setIsLoading(false);
    }

  }, [fetcher, setIsLoading, setError, setData]);

  return useMemo(() => ({ data, error, isLoading, fetch }), [data, error, isLoading, fetch]);
}
