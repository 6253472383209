export function isIE() {
  const { navigator } = window;
  const ua = navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return msie > 0 || !!ua.match(/Trident.*rv:11\./);
}

export function browserOnBlur() {
  return null;
}
