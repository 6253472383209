import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firstBy } from 'thenby';
import Button from 'components/Button';
import * as api from 'lib/api';
import { isFeatureOn } from 'lib/features';
import { featureFlags } from 'config/featureFlags';
import { createApplicableCandidatesSelectList } from 'routes/Jobs/helpers';
import colors from 'config/colors';
import * as jobsThunks from 'thunks/jobs';
import JobsSelectBox from '../../Adhoc/JobsSelectBox';
import '../../Adhoc/TimesAndAreas.scss';
import '../styles.scss';

const { string, bool, func, objectOf, object } = PropTypes;

class TargetBankCandidateForm extends Component {

  static propTypes = {
    job: objectOf(object).isRequired,
    bookingCandidate: bool.isRequired,
    bookCandidate: func.isRequired,
    shiftError: string,
  }

  static defaultProps = {
    shiftError: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      applicableCandidates: [],
      applicableCandidatesSelectList: [],
      fetchingApplicableCandidates: false,
    };
  }

  componentDidMount() {
    this.fetchApplicableCandidates();
  }

  fetchApplicableCandidates = async () => {
    this.setState({ fetchingApplicableCandidates: true });

    const response = await api.post('jobs/candidates/search', {
      startTime: this.props.job.startTime,
      endTime: this.props.job.endTime,
      siteKey: this.props.job.siteKey,
      roleKey: this.props.job?.roleKey ?? null,
      gradeKey: this.props.job?.gradeKey ?? null,
      specialityKey: this.props.job?.specialityKey ?? null,
      serviceKey: this.props.job?.serviceKey ?? null,
    });

    const site = this.props.sites[this.props.job.siteKey];
    const applicableCandidatesSelectList = createApplicableCandidatesSelectList(response?.body?.candidates ?? [], site, this.props.showCandidateLocationsFeatureIsOn, this.props.contractedHoursFeatureIsOn);

    this.setState({
      applicableCandidates: response?.body?.candidates ?? [],
      applicableCandidatesSelectList,
      fetchingApplicableCandidates: false,
    });
  }

  render() {
    return (
      <>
        <div className="target-bank-candidate-container space-children-6--bottom">
          <div className="shift-details-sub-heading-container">
            <h3 className="shiftDetailsSubHeading">Book Bank Candidate</h3>
          </div>
          <div className="book_candidate_info__container">
            <p className="book_candidate_info__text">This shift still contains unfilled vacancies. You can book a candidate in by using the dropdown below (NB candidates who are not available, or do not meet shift criteria will not appear)</p>
          </div>
          <div className="input-container">

            <div className="select-box-container">
              <div className="target-candidate-select-box">
                <JobsSelectBox
                  name="candidates"
                  value={this.props.selectedCandidate}
                  placeholder={this.state.fetchingApplicableCandidates ? 'Loading Candidates' : 'Select Candidate...'}
                  data={this.state.applicableCandidatesSelectList}
                  onChange={candidate => this.props.selectCandidate(candidate)}
                  isDisabled={this.state.fetchingApplicableCandidates}
                  loading={this.state.fetchingApplicableCandidates}
                />
              </div>
              <Button
                disabled={!this.props.selectedCandidate || this.props.bookingCandidate}
                className="auto-book-button"
                // style={{ height: 40 }}
                onClick={() => this.props.bookCandidate({
                  candidateId: this.props.selectedCandidate.value,
                  candidate: this.state.applicableCandidates.find(candidate => candidate.key === this.props.selectedCandidate.value),
                  profRegStatus: this.props.selectedCandidate.status,
                  isAgency: null,
                  applicationsId: null,
                  autoBookedAfterReleaseToBank: true,
                })}
                black
                shadow={false}
              >
                {`Book${this.props.bookingCandidate ? 'ing' : ''}`}
              </Button>
            </div>

            {this.props.shiftError ?
              <div className="user-feedback-container">
                <p className="auto-book-error-message">{this.props.shiftError || null}</p>
              </div>
              : null
            }
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ user, global }) {
  return {
    showCandidateLocationsFeatureIsOn: isFeatureOn(featureFlags.SHOW_CANDIDATE_LOCATIONS_TO_ADMINS, null, user, global),
    contractedHoursFeatureIsOn: isFeatureOn(featureFlags.CONTRACTED_HOURS, null, user, global),
    sites: user.sites,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    book: (jobId, candidateId, profRegStatus, isAgency, applicationId, autoBookedAfterReleaseToBank) => dispatch(jobsThunks.book(jobId, candidateId, profRegStatus, isAgency, applicationId, autoBookedAfterReleaseToBank)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetBankCandidateForm);
