const colors = {
  // #1d83e4 (original positive color)
  // rgb(0, 144, 228) (same lightness/saturation as old blue)
  // rgb(1, 138, 218) (same, slightly darker)
  // rgb(0, 125, 197) (darker still)
  // rgb(0, 94, 134) (teal) #005E86
  // #45A0A8 (light green from signup mockups)
  // rgb(0, 140, 193) (teal lighter)
  // rgb(0, 135, 193) (teal lighter / less green)
  // rgb(93, 126, 160) grey/desaturated
  brand: '#005E86',
  brandTertiary: '#2C7796',
  positive: '#005E86',
  bank: '#A3B9C3',
  external: '#5A5AA4',
  positiveLight: '#45A0A8',
  negative: '#B7B7B7',
  accent: '#e6e6e6',
  text: '#000000',
  lightText: '#666',
  disabled: '#e6e6e6',
  title: '#A4C2F4',
  understated: '#b7b7b7',
  moreUnderstated: 'hsla(0, 0%, 79%, 1)',
  hoverGrey: '#f7f5f5',
  textInput: '#999999',
  error: '#d8a285',
  lineColor: '#f0f0f0',
  green: '#33cd5f',
  create: '#bbd685',
  amber: '#f6b26b',
  gold: '#E6C100',
  red: '#d8a285',
  white: 'white',
  black: 'hsla(0, 0%, 0%, 1)',
  charcoal: 'hsla(0, 0%, 20%, 1)',
  warning: '#f6b26b',
  backgroundColor: 'rgb(241, 245, 249)',
  buttonTextColor: 'white',
  sideMenuBackgroundColor: 'white',
  sideMenuTextColor: '#999999',
  headerBarBackgroundColor: 'white',
  headerBarTextColor: '#8a8a8a',
  coinColor: 'hsla(55, 100%, 50%, 1)',
  brightRed: '#ff6961', // 'hsla(6, 80%, 53%, 1)',
  deepMaroon: 'hsla(6, 100%, 20%, 1)',
  gray: 'hsla(60, 1%, 33%, 1)',
  greyBackground: '#f7f7f7',
  greyBorder: '#EAEAEA',
  navBarWhite: 'hsla(48, 100%, 99%, 1)',
  pinkishWhite: 'hsla(0, 50%, 99%, 1)',
  agency: '#C7BBB6',
  unfilled: '#f2f2f2',
  redButton: '#EA9999',
  blueBase: 'hsla(209, 79%, 50%, 1)',
  blueLight: 'hsla(209, 79%, 70%, 1)',
  blueVeryLight: 'hsla(209, 79%, 85%, 1)',
  blueLightest: 'hsla(210, 40%, 96%, 1)',
  pastelGreen: '#9cdbae',
  sidebarBackgroundLighter: '#005E86',
  sidebarBackgroundEvenLighter: '#2c7796',
  sidebarLink: '#dae5f9',
  tealUltraLight: '#a0d1db',
  tealUltraLightGray: '#cae7ed',
  tealUltraLightGrayGreen: '#b2e1eb',
  tealUltraLightGrayDark: '#b6cfd4',
  blues: {
    tabBlue: '#eaf6ff',
    base: 'hsla(209, 79%, 50%, 1)',
    light: 'hsla(209, 79%, 70%, 1)',
    veryLight: 'hsla(209, 79%, 85%, 1)',
    ultraLight: 'hsla(210, 40%, 96%, 1)',
  },
  headerBlue: '#e1f0ff',
  grays: {
    text: '#666666',
    charcoal: 'hsla(0, 0%, 20%, 1)',
    medium: 'hsla(0, 0%, 40%, 1)',
    mediumLight: 'hsla(0, 0%, 60%, 1)',
    light: 'hsla(0, 0%, 72%, 1)',
    veryLight: 'hsla(0, 0%, 79%, 1)',
    ultraLight: 'hsla(0, 0%, 86%, 1)',
  },
  transparent: 'hsla(0, 0%, 0%, 0)',
  ltjStatus: {
    noApplicants: '#e78e8e',
    applicants: '#f8c492',
    booked: '#add19e',
    tempBooked: '#9abaf2',
  },
  ratingYellow: '#FDCC0D',
  cavalry: {
    primary: '#D8A285',
    secondary: '#94b8ae',
    tertiary: '#A3B9C3',
    bankFilled: '#879AA3', 
    bankUnfilled: '#A3B9C3', 
    agencyFilled: '#A9A19E', 
    agencyUnfilled: '#C7BBB6', 
    backgroundFocussed: '#c5c0bd1f',
    backgroundLight1: '#FAF8F3',
    backgroundLight2: '#f0e8dd',
    backgroundLight3: '#c7bbb6',
    backgroundLight4: '#C7BBB6',
    backgroundLight5: '#F7F6F4',
    backgroundLight6: '#F2EEE8',
    line: '#0000001a',
    active: '#94b8ae',
    pending: '#e5bb67',
    error: '#d8a285',
    invalid: '#c7bbb6', 
  },
};

// New sidebar link color #dae5f9
// New sidebar background rgb(2, 52, 101)
// New sidebar background lighter #005E86

export default colors;
