import { Moment } from 'moment-timezone';
import { convertFromUtcToTimezone } from 'lib/helpers-time';

interface ComputeNewShiftTimesArgs {
  currentStart: string;
  currentEnd: string;
  timezone: string;
  newDate?: Moment;
  newStartTime?: { hours: number, minutes: number };
  newEndTime?: { hours: number, minutes: number };
}

interface ComputeNewShiftTimesReturn {
  newStartTime: string;
  newEndTime: string;
}

export function computeNewShiftTimes({ currentStart, currentEnd, timezone, newDate, newStartTime, newEndTime }: ComputeNewShiftTimesArgs): ComputeNewShiftTimesReturn {

  const currentStartTz = convertFromUtcToTimezone(currentStart, timezone);
  const currentEndTz = convertFromUtcToTimezone(currentEnd, timezone);

  const date = newDate ? convertFromUtcToTimezone(newDate?.toISOString(), timezone) : currentStartTz;

  const newStart = date.clone()
    .hours(newStartTime?.hours ?? currentStartTz.hours())
    .minutes(newStartTime?.minutes ?? currentStartTz.minutes());

  const newEnd = date.clone()
    .hours(newEndTime?.hours ?? currentEndTz.hours())
    .minutes(newEndTime?.minutes ?? currentEndTz.minutes());

  // If start time is same or after end time, add 1 day to end time
  // If end time > 1 day after start time, subtract 1 day from end time
  const startTimeEndTimeDiffInMinutes = newEnd.diff(newStart, 'minutes');
  if (startTimeEndTimeDiffInMinutes <= 0) newEnd.add(1, 'days');
  if (startTimeEndTimeDiffInMinutes > 1440) newEnd.subtract(1, 'days');

  return { newStartTime: newStart.toISOString(), newEndTime: newEnd.toISOString() };
}
