export const getDistanceInMiles = ({ latFrom, lonFrom, latTo, lonTo }) => {
  const MILES_IN_KM = 0.62137119;
  const EARTH_DIAMTER = 12742;
  const COS = Math.cos;

  const pi = Math.PI / 180;
  const angle = (0.5 - (COS((latTo - latFrom) * pi) / 2)) +
    ((COS(latFrom * pi) * COS(latTo * pi)) *
      ((1 - COS((lonTo - lonFrom) * pi)) / 2));

  const distance = (EARTH_DIAMTER * Math.asin(Math.sqrt(angle))) * MILES_IN_KM;

  return Math.ceil(distance * 100) / 100;
};
