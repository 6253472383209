import React from 'react';
import PropTypes from 'prop-types';

import colors from 'config/colors';

import Button from '../Button';
import spacing from '../../config/spacing';

const { oneOf, objectOf, arrayOf, func, number, string, bool, object } = PropTypes;

const styles = {
  container: {
    padding: '3px 6px',
    fontSize: '0.9em',
    flex: '0 0 auto',
    borderTop: '1px solid rgb(204, 204, 204)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: '#666',
  },
  button: { padding: '0 6px' },
  controlsContainer: { padding: '6px 12px' },
};

const PaginationControls = props => (
  <div style={{ ...styles.container, ...props.style }}>

    {props.isLoading ?
      <div>Loading rows...</div>
      :
      <SummaryMessage
        startOfRange={props.startOfRange}
        endOfRange={props.endOfRange}
        totalRowCount={props.totalRowCount}
        showTotalRowCount={props.showTotalRowCount}
      />
    }

    <div style={styles.controlsContainer}>
      <span style={{ marginRight: '12px', fontSize: '0.8rem' }}>
        Show{' '}
        <select value={props.selectedRowsPerPage} onChange={event => props.updateRowsPerPage(event.target.value)}>
          {props.rowsPerPageOptions.map(count => <option key={count} value={count}>{count}</option>)}
        </select>
        {' '}rows per page
      </span>

      {' '}<Button white shadow={false} small disabled={props.page === 1 || props.isLoading} onClick={() => props.onPageChange({ direction: 'first' })}>«</Button>

      {' '}<Button white shadow={false} small disabled={props.page === 1 || props.isLoading} onClick={() => props.onPageChange({ direction: 'previous' })}>&lt; prev</Button>

      {' '}<Button white shadow={false} small disabled={!props.moreRowsAvailable || props.isLoading} onClick={() => props.onPageChange({ direction: 'next' })}>next &gt;</Button>

      {' '}<Button white shadow={false} small disabled={props.page >= props.pages || props.isLoading} onClick={() => props.onPageChange({ direction: 'last' })}>»</Button>

    </div>
  </div>
);

PaginationControls.propTypes = {
  totalRowCount: number.isRequired,
  selectedRowsPerPage: number.isRequired,
  rowsPerPageOptions: arrayOf(number).isRequired,
  startOfRange: number.isRequired,
  endOfRange: number.isRequired,
  page: number.isRequired,
  pages: number.isRequired,
  updateRowsPerPage: func.isRequired,
  onPageChange: func,
  style: objectOf(oneOf([number, string])),
  isLoading: bool.isRequired,
  rows: arrayOf(object).isRequired,
  moreRowsAvailable: bool.isRequired,
  showTotalRowCount: bool,
};

PaginationControls.defaultProps = {
  onPageChange: () => {},
  style: {},
  showTotalRowCount: true,
};

const SummaryMessage = ({ startOfRange, endOfRange, totalRowCount, showTotalRowCount }) => {

  return (
    <div style={{ fontSize: '0.8rem' }}>
      Showing rows <strong>{startOfRange}</strong> to <strong>{endOfRange}</strong>
      {showTotalRowCount && <> of <strong>{totalRowCount}</strong> rows.</>}
    </div>
  );
};

SummaryMessage.propTypes = {
  startOfRange: number,
  endOfRange: number,
  totalRowCount: number,
  showTotalRowCount: bool.isRequired,
};

SummaryMessage.defaultProps = {
  startOfRange: undefined,
  endOfRange: undefined,
  totalRowCount: undefined,
};


export default PaginationControls;
