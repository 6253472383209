import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import ErrorModal from 'components/ErrorModal';
import NoReportData from 'components/NoReportData';
import colors from '../../../config/colors';
import UnitsFormatter from './UnitsFormatter';
import XAxisAtAngle from './XAxisAtAngle';
import Trend from './Trend';

import { reports, headerFormatter, formatDate } from './index'; // eslint-disable-line import/no-cycle
import './styles.scss';

function getReportTotal(report, labels) {
  let reportTotal = 0;
  if (report) {
    report.forEach((data) => {
      labels.forEach((label) => {
        reportTotal += data[label];
      });
    });
  }
  return reportTotal;
}

const generatePayloadForChart = ({ report, labels, durationBetweenDates }) => {
  if (!report) return null;
  return report.result.map((data) => {
    const payload = {};
    labels.forEach((label) => {
      payload[label] = data[label];
      payload.date = formatDate(data.date, durationBetweenDates);
    });
    return payload;
  });
};

export default function RequestsReport(props) {
  // Find metadata and methods from report config
  const { labels, dataFormatter } = reports.find(report => report.name === 'requests');

  // Create formatted payload for chart
  const reportPayloadForChart = generatePayloadForChart({ report: props.report, labels, durationBetweenDates: props.durationBetweenDates });

  return (
    <>
      {props.reportError && (
        <ErrorModal header="Requests Report Error" errorMessage={props.reportError} onCancel={() => props.clearReportingError('requests')} />
      )}
      <div style={{ width: props.width }} className="test-id-reports-requests">
        {!props.loading && reportPayloadForChart ?
          <div className="chartWrapper">
            {props.report.delta ?
              <Trend
                positive="down"
                delta={props.report.delta}
                deltaPeriod={props.report.deltaPeriod}
                deltaPeriodUnit={props.report.deltaPeriodUnit}
              /> : null}
            <h3 style={{ color: colors.text, fontSize: '1.2rem', textTransform: 'none', fontWeight: 600 }}>Shift Requests</h3>
            <p className="report-explanation">Summary of shifts released to bank and agency</p>
            <h4 className="report-total">{`Total Shift Requests: ${dataFormatter(getReportTotal(reportPayloadForChart, labels))}`}</h4>
            <ResponsiveContainer width="99%" height={300}>
              <BarChart data={reportPayloadForChart} margin={{ bottom: 20 }} onClick={props.onClickChart}>
                <XAxis height={40} dataKey="date" tick={<XAxisAtAngle groupedBy="date" />} />
                <YAxis allowDecimals={false} tickFormatter={UnitsFormatter} />
                <Tooltip formatter={dataFormatter} />
                <Legend verticalAlign="top" />
                <Bar dataKey="agency" name="Agency" stackId="a" fill={colors.agency} />
                <Bar dataKey="bank" name="Bank" stackId="a" fill={colors.bank} />
              </BarChart>
            </ResponsiveContainer>

            {props.drillDownComponent && props.drillDownComponent({
              labels,
              report: reportPayloadForChart,
              numberFormatter: dataFormatter,
              headerFormatter,
              header: 'Shift Requests',
              groupBy: props.report.groupBy,
            })}
          </div> : <NoReportData title="Shift Requests" />}
      </div>
    </>
  );
}


const { object, array, shape, bool, oneOfType, number, node, element, func, string } = PropTypes;

RequestsReport.propTypes = {
  report: shape({
    result: array.isRequired,
    siteFilter: string,
    roleFilter: string,
    groupBy: string,
  }),
  loading: bool.isRequired,
  reportError: string,
  drillDownComponent: oneOfType([node, element, object, func]),
  clearReportingError: func.isRequired,
  durationBetweenDates: number.isRequired,
  onClickChart: func,
  width: string,
};

RequestsReport.defaultProps = {
  report: null,
  width: '100%',
  drillDownComponent: null,
  onClickChart: () => {},
  reportError: '',
};
