import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { MdFileDownload } from 'react-icons/md';
import { DateRangePicker } from 'react-dates';
import { isNil } from 'lodash-es';

import colors from 'config/colors';
import { listExportableReports, watchReport } from 'lib/api/reporting';
import * as api from 'lib/api';

import DropDownFilter from 'components/DropDownFilter';
import Page from 'components/Page';
import Button from 'components/Button';
import Loading from 'components/Loading';
import OptionList from 'components/OptionList';
import { Subscription } from 'zen-observable-ts';

import ReportingHeader from '../ReportingHeader';
import './Exports.scss';

const customSelectBoxStyles = {
  valueContainer: { fontSize: '14px' },
  menuList: { width: '100%' },
  menu: { width: '100%' },
  option: { fontSize: '14px' },
};

interface BankKey {
  key: string,
  name: string,
}
interface ReportDefinition {
  name: string;
  description: string;
  reportKey: string;
  collabKey: string;
  bankKeys: Array<BankKey> | null;
  parameters: {
    showRangeType: boolean;
    showDateRangePicker: boolean;
  }
}

const CUMULATIVE = 'cumulative';
const DATE_RANGE = 'dateRange';

const styles = {
  button: {
    backgroundColor: colors.positive,
    width: '100%',
  },
  downloadButton: {
    backgroundColor: colors.black,
  },
  icon: {
    display: 'inline-block',
    marginRight: 6,
  },
};

interface ExportsContainerState {
  exportableReports: Array<ReportDefinition> | null;
  loading: boolean;
  error: boolean;
}

class ExportsContainer extends Component<Record<string, never>, ExportsContainerState> {

  constructor(props: Record<string, never>) {
    super(props);
    this.state = {
      exportableReports: null,
      loading: true,
      error: false,
    };
  }

  async componentDidMount(): Promise<void> {
    try {
      const exportableReports = await listExportableReports();
      this.setState({ loading: false, error: false, exportableReports });
    } catch (e) {
      this.setState({ loading: false, error: true });
    }
  }

  render() : JSX.Element {
    const { error, loading, exportableReports } = this.state;

    return (
      <Page vflex title="Reporting" subTitle="Exports">

        <ReportingHeader selectedTabKey="exports" />

        {error || loading || !exportableReports?.length ?
          <div style={{ color: colors.text, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {error && <div style={{ color: colors.red }}>Error loading reports</div>}
            {loading && <Loading size={52} />}
            {!error && !loading && 'No exportable reports available'}
          </div>
          : null
        }

        {!error && !loading && exportableReports?.length ?
          <div className="reportExports scrollable">
            <div className="reportExportWrapper">
              {exportableReports.map((item, index) => <ExportedReportForm zIndex={index} key={item.reportKey} definition={item} adminBanks={this.props.adminBanks} />)}
            </div>
          </div>
          : null
        }
      </Page>
    );
  }
}

interface Service {
  banks: Record<string, unknown>
}

interface GlobalState {
  global : { services: Service[] },
}

function mapStateToProps({ global }: GlobalState) {
  const services = global.services;
  const adminBanks = (services ?? []).flatMap((service) => {
    return Object.keys(service.banks ?? []);
  });

  return {
    adminBanks: [...new Set(adminBanks)],
  };
}


interface ExportedReportFormProps {
  definition: ReportDefinition;
  zIndex: number;
  adminBanks: string[],
}
interface ExportedReportFormState {
  selectedBankKey: string | null;
  dateRangeType: typeof DATE_RANGE | typeof CUMULATIVE | null;
  from: moment.Moment | null;
  to: moment.Moment | null;
  focusedInput: 'startDate' | 'endDate' | null;
  loading: boolean;
  error: string | null;
}

class ExportedReportForm extends Component<ExportedReportFormProps, ExportedReportFormState> {

  private querySubscription : Subscription | null;

  constructor(props: ExportedReportFormProps) {
    super(props);

    const { zIndex } = props;
    const dateRangeType = props.definition.parameters.showDateRangePicker ? DATE_RANGE : null;
    const to = props.definition.parameters.showDateRangePicker ? moment().startOf('isoWeek').subtract(1, 'millisecond') : null;
    const from = props.definition.parameters.showDateRangePicker && to ? to.clone().startOf('isoWeek') : null;

    this.state = {
      selectedBankKey: null,
      dateRangeType,
      from,
      to,
      focusedInput: null,
      loading: false,
      error: null,
    };
    this.querySubscription = null;
  }

  componentWillUnmount() {
    if (this.querySubscription) this.querySubscription.unsubscribe();
  }

  reportHasLoaded = ({ error }: { error: string | null }) => {
    this.setState({ loading: false, error });
    if (this.querySubscription) this.querySubscription.unsubscribe();
  }

  downloadReport = async () => {
    // Set loading status to specified report
    this.setState({ loading: true, error: null });

    const reportKey = this.props.definition.reportKey;
    const { from, to, dateRangeType, selectedBankKey } = this.state;
    // // Set range
    const range = { from: from?.toISOString(), to: to?.toISOString() };
    if (dateRangeType === CUMULATIVE) {
      range.from = moment('2018-04-30').toISOString();
      range.to = moment().toISOString();
    }

    const response = await api.get('reports/export/queue', { reportKey, reportName: reportKey, from: range.from, to: range.to, bankKey: selectedBankKey });

    const reportError = 'Something went wrong. Please try downloading the report again.';

    // Check if response returned an error
    if (!response.body.success) {
      this.reportHasLoaded({ error: reportError });
    }

    if (response?.body?.success) {
      // Watch for report status to turn 'completed', then download CSV from URL
      this.querySubscription = watchReport({
        reportKey: response.body.key,
        callback: (report: { status: string }) => {
          const status = report?.status;
          if (status === 'completed') {
            window.location.href = response.body.downloadUrl;
            this.reportHasLoaded({ error: null });
          }
          if (status === 'error') this.reportHasLoaded({ error: reportError });
        },
        error: () => {
          // Check if subscription returned an error
          this.reportHasLoaded({ error: reportError });
        },
      });
    }
  }

  onDatesChange = (from: moment.Moment | null, to: moment.Moment | null) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.setState({ from, to });
  }

  onFocusChange = (focusedInput: 'startDate' | 'endDate' | null) => {
    this.setState({ focusedInput });
  }

  onItemClick = (key: typeof DATE_RANGE | typeof CUMULATIVE) => {
    this.setState({ dateRangeType: key });
  }

  onBankKeyChange = (key: string) => {
    this.setState({ selectedBankKey: key });
  }

  render() {
    const { definition, zIndex } = this.props;
    const { dateRangeType, loading, error, from, to, focusedInput, selectedBankKey } = this.state;

    const bankOptions = definition?.bankKeys?.filter(bank => this.props.adminBanks.includes(bank.key)).map(({ name, key }) => {
      return { value: key, label: name };
    }) ?? [];

    if (bankOptions.length && isNil(selectedBankKey)) this.onBankKeyChange(bankOptions[0].value); // set default options

    return (
      <div className="reportExport" style={{ zIndex: (999 - zIndex) }} key={definition.reportKey}>
        <h2>{definition.name}</h2>
        <div className="reportControls space-children-6--bottom">
          {definition.description ? <p className="report-explanation" style={{ marginLeft: 0 }}>{definition.description}</p> : null}
          {(definition.bankKeys && definition.bankKeys.length) ? (
            <DropDownFilter
              title="Your available staff bank:"
              placeholder=""
              options={bankOptions}
              value={bankOptions.find(option => option.value === selectedBankKey)}
              onChange={(obj) => this.onBankKeyChange(obj.value)}
              customStyles={customSelectBoxStyles}
            />
          ) : null
          }
          {definition.parameters.showRangeType ?
            <div className="picker">
              <OptionList
                items={[
                  {
                    key: DATE_RANGE,
                    name: 'Date Range',
                    selected: dateRangeType === DATE_RANGE,
                  },
                  {
                    key: CUMULATIVE,
                    name: 'Cumulative',
                    selected: dateRangeType === CUMULATIVE,
                  },
                ]}
                onItemClick={this.onItemClick}
              />
            </div> : null
          }
          {definition.parameters.showDateRangePicker ?
            <div className="reportDatePicker">
              <DateRangePicker
                disabled={loading}
                key={definition.reportKey}
                displayFormat="ll"
                startDate={from ?? null}
                endDate={to ?? null}
                onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                focusedInput={focusedInput}
                onFocusChange={newFocusedInput => this.onFocusChange(newFocusedInput)}
                isOutsideRange={() => false}
                firstDayOfWeek={1}
                numberOfMonths={1}
                startDateId={`${definition.reportKey}-start-date`}
                endDateId={`${definition.reportKey}-end-date`}
                openDirection="down"
              />
            </div>
            : null
          }
          <div className="reportButtons">
            <Button disabled={loading} black shadow={false} fullWidth icon={loading ? null : <MdFileDownload style={styles.icon} />} onClick={() => this.downloadReport()}>
              {loading ? 'Downloading Report...' : 'Download Report'}
            </Button>
          </div>
          {error && <div style={{ color: colors.red, paddingTop: 12 }}>{error}</div>}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ExportsContainer);
