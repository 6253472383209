import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';
import { MdFilterList } from 'react-icons/md';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { fetchCandidateShifts } from 'thunks/candidate';
import PaginatedDataTable from 'components/DataTable/PaginatedDataTable';
import Loading from 'components/Loading';
import ShiftPill from 'components/StatusPill/ShiftPill';
import { CWFilterSelect } from 'components/CWSelect';
import ShiftDetails from 'routes/Jobs/ShiftDetails';

import colors from 'config/colors';
import spacing from 'config/spacing';

const styles = {
  filterBarFiltersContainer: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.text,
    fontSize: '0.9rem',
    padding: 6,
  },
};

const dataTableColumns = (props) => {
  return (
    [
      {
        name: 'job',
        header: 'ID',
        width: 80,
        formatter: job => job.shiftNumber,
      },
      {
        name: 'status',
        header: 'Status',
        width: 145,
        formatter: shift => {
          return (
            <ShiftPill
              job={{
                isApplied: shift.applicationStatus === 'applied',
                isBooked: shift.applicationStatus === 'booked',
                hasWithdrawn: shift.applicationStatus === 'declined' && !!shift.bookedAt,
              }}
            />
          );
        },
      },
      {
        name: 'date',
        header: 'Date',
        formatter: job => convertFromUtcToTimezone(job.startTime, job.timezone).format('Do MMM Y'),
      },
      {
        name: 'time',
        header: 'Time',
        formatter: (job) => {
          const format = 'HH:mm';
          const startTime = convertFromUtcToTimezone(job.startTime, job.timezone).format(format);
          const endTime = convertFromUtcToTimezone(job.endTime, job.timezone).format(format);

          return `${startTime} - ${endTime}`;
        },
      },
      {
        name: 'siteName',
        header: 'Site',
      },
      props.siteAreasFeatureOn ? {
        name: 'areaName',
        header: 'Area',
      } : null,
      {
        name: 'roleName',
        header: 'Role',
        width: 50,
        formatter: (roleName, row) => <span>{row.roleName ?? 'Any'}</span>,
      },
      {
        name: 'gradeName',
        header: 'Grade',
        formatter: (gradeName, row) => <span>{row.gradeName ?? 'Any'}</span>,
      },
      {
        name: 'specialityName',
        header: 'Speciality',
        formatter: (specialityName, row) => <span>{row.specialityName ?? 'Any'}</span>,
      },
    ].filter(Boolean)
  );
};

const filterOptions = [
  { value: 'bookings-and-future-applications', label: 'Bookings & Future Applications' },
  { value: 'all-bookings-and-applications', label: 'All Bookings & Applications' },
];

const ShiftsTab = (props) => {

  const dispatch = useDispatch();

  useEffect(() => { dispatch(fetchCandidateShifts(props.candidateKey)); }, [props.candidateKey]);
  const refreshCandidateShifts = useCallback(() => { dispatch(fetchCandidateShifts(props.candidateKey)); }, [props.candidateKey]);
  const onShiftChange = useCallback(() => {
    refreshCandidateShifts();
    if (props.onShiftChange) props.onShiftChange();
  }, [props.onShiftChange, refreshCandidateShifts]);

  const candidateShifts = useSelector(state => state.candidate.candidateShifts);
  const candidateShiftsArray = useMemo(() => Object.values(candidateShifts || {}).sort(firstBy('startTime', -1)), [candidateShifts]);
  const isFetchingCandidateWorkHistory = useSelector(state => state.candidate.isFetchingCandidateShifts);
  const siteAreasFeatureOn = useSelector(state => state.global.features?.siteAreas);

  const [selectedShiftKey, selectShift] = useState(null);
  const [selectedShiftTab, selectTab] = useState('details');
  const [selectedFilterKey, setSelectedFilterKey] = useState(filterOptions[0].value);

  useEffect(() => { dispatch(fetchCandidateShifts(props.candidateKey, selectedFilterKey)); }, [selectedFilterKey]);

  return (
    <>
      {isFetchingCandidateWorkHistory ?
        <Loading flex />
        :
        <>
          <div style={{ padding: 6, borderBottom: '1px solid rgba(164, 194, 244, 0.2)' }}>
            <div style={styles.filterBarFiltersContainer}>
              <p style={{ marginRight: spacing.large }}>Filters:</p>
              <CWFilterSelect
                items={filterOptions}
                selectedItems={[selectedFilterKey]}
                setSelectedItems={(filters) => setSelectedFilterKey(filters[0])}
                isMulti={false}
              />
            </div>
          </div>
          <PaginatedDataTable
            //fixedLayout
            noTextWrap
            idColumn="key"
            totalRowCount={candidateShiftsArray.length}
            rows={candidateShiftsArray}
            columns={dataTableColumns(props)}
            onRowClick={shiftKey => selectShift(shiftKey)}
            additionalProps={{ siteAreasFeatureOn }}
            tableStyle={{ minWidth: '840px' }}
            overrideHeadingStyles={{
              color: colors.text,
              textTransform: 'none',
              fontSize: '0.9rem',
            }}
          />
        </>
      }
      {selectedShiftKey &&
        <ShiftDetails
          jobId={selectedShiftKey}
          sidePanelTab={selectedShiftTab}
          onTabClick={tab => selectTab(tab)}
          onShiftChange={onShiftChange}
          closeSidePanel={() => {
            selectShift(null);
            selectTab('details');
          }}
        />
      }
    </>
  );
};

const { string, func } = PropTypes;
ShiftsTab.propTypes = {
  candidateKey: string.isRequired,
  onShiftChange: func,
};

ShiftsTab.defaultProps = {
  onShiftChange: () => {},
};

export default ShiftsTab;
