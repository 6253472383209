import React, { useCallback, useState, useReducer, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPencilAlt } from 'react-icons/fa';
import { firstBy } from 'thenby';
import moment from 'moment';
import colors from 'config/colors';
import spacing from 'config/spacing';
import Button from 'components/Button';
import Loading from 'components/Loading';
import EditableField from 'components/DetailsTable/EditableField';
import { updateCandidateProfile } from 'thunks/candidate';
import { clearUpdateCandidateProfileError } from 'reducers/candidate';
import * as authApi from 'lib/api/auth';
import regex from 'lib/regex';
import { usePermission } from 'hooks/feature';

const PasswordResetSection = (props) => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const sendPasswordResetEmail = useCallback(async () => {

    setError(null);
    setLoading(true);
    try {
      const response = await authApi.sendPasswordResetEmail(props.email, 'mobile-app');
      setIsSent(true);
    } catch (e) {
      setError(e.details?.humanReadableErrorMessage ?? e.message);
    }
    setLoading(false);

  }, [props.email]);

  return (
    <>
      <h2 style={{ fontWeight: 600, color: colors.text, marginTop: 24, marginBottom: 12 }}>Password Reset</h2>
      <div>
        <Button black shadow={false} onClick={sendPasswordResetEmail} loading={loading}>
          Send Password Reset Email
        </Button>
      </div>
      {error && <p style={{ color: colors.cavalry.error, fontWeight: 500, fontSize: '0.9rem' }}>{error}</p>}
      {isSent && <p style={{ color: colors.text, fontSize: '0.9rem', marginTop: 6 }}>Password reset email sent.</p>}
    </>
  );
};

const BasicInfoTab = (props) => {
  const { candidateKey, adminHasWriteAccess } = props;

  const dispatch = useDispatch();
  const profile = useSelector(state => state.candidate.profile);
  const isUpdatingCandidateProfile = useSelector(state => state.candidate.isUpdatingCandidateProfile);
  const updateCandidateProfileError = useSelector(state => state.candidate.updateCandidateProfileError);
  const currentOrgKey = useSelector(state => state.global.currentOrgKey);
  const customFieldsMetadata = (useSelector(state => state.global.customFieldsMetadata) || []).filter(field => field.entity === 'candidate');
  const candidateCustomFields = profile?.orgs?.[profile.mainOrgKey]?.customFields ?? {};

  const adminCanEditBasicDetails = usePermission('bankCanEditBasicDetails');
  const adminCanEditCustomFields = usePermission('bankCanEditOrgSpecificMetadata');
  const adminCanViewCustomFields = props.adminHasWriteAccess;

  const [isInEditMode, setIsInEditMode] = useState(false);

  const createInitialFormState = () => {
    const initialFormState = {
      firstName: profile.firstName,
      surname: profile.surname,
      middleNames: profile.middleNames,
      email: profile.email,
      phone: profile.phone,
      customFields: {},
    };

    // Construct custom fields initial form state
    if (customFieldsMetadata.length) {
      customFieldsMetadata.forEach((field) => {
        const submittedCustomFieldValue = candidateCustomFields[field.fieldKey];
        let defaultValue = field.parameters?.default;
        if (field.type === 'multi-select' && !Array.isArray(defaultValue)) defaultValue = defaultValue ? [defaultValue] : [];
        initialFormState.customFields[field.fieldKey] = submittedCustomFieldValue ?? defaultValue;
      });
    }
    return initialFormState;
  };

  const [fields, handleFieldChange] = useReducer((state, action) => {
    if (action.type === 'RESET_STATE') {
      const initialFormState = createInitialFormState();
      return initialFormState;
    }

    if (action.type === 'SET_FIELD') {

      if (!action.key) throw new Error('key property is required');
      const updatedState = { ...state };
      updatedState[action.key] = action.value;
      return updatedState;
    }

    throw new Error('action.type is required');

  }, createInitialFormState());

  const updateCandidate = async () => {
    const basicInfoFields = ['firstName', 'middleNames', 'surname', 'email', 'phone'];
    const basicInfoFieldsToUpdate = {};
    const customFieldsToUpdate = {};

    // Add basic info fields if admin has permission
    Object.entries(fields).forEach(([key, value]) => {
      if (basicInfoFields.includes(key) && adminCanEditBasicDetails) basicInfoFieldsToUpdate[key] = value;
    });

    // Filter out custom fields with no set value
    Object.entries(fields.customFields).forEach(([customFieldKey, customFieldValue]) => {
      if (customFieldValue !== candidateCustomFields[customFieldKey]) customFieldsToUpdate[customFieldKey] = customFieldValue;
    });

    const success = await dispatch(updateCandidateProfile(candidateKey, { ...basicInfoFieldsToUpdate, customFields: customFieldsToUpdate }));
    if (success) {
      handleFieldChange({ type: 'RESET_STATE' });
      setIsInEditMode(false);
    }
  };

  // Campare form field values against current candidate profile
  const changesComparedToProfile = useMemo(() => {
    if (fields.firstName !== profile.firstName) return true;
    if (fields.surname !== profile.surname) return true;
    if ((fields.middleNames || profile.middleNames) && fields.middleNames !== profile.middleNames) return true;
    if (fields.email !== profile.email) return true;
    if (fields.phone !== profile.phone) return true;

    // Compare customFields
    const customFieldChanges = Object.entries(fields.customFields).some(([customFieldKey, customFieldValue]) => {
      return customFieldValue !== candidateCustomFields[customFieldKey];
    });
    if (customFieldChanges) return true;

    return false;
  }, [fields]);

  if (!profile) return null;

  // Validate fields
  const phoneNumberIsInvalid = !!fields.phone && !regex.checkNumber(fields.phone);
  const emailAddressIsInvalid = !!(!fields.email || !regex.checkEmail(fields.email));
  const formIsValid = fields.firstName && fields.surname && fields.email && !emailAddressIsInvalid && !phoneNumberIsInvalid;
  const saveButtonDisabled = isUpdatingCandidateProfile || !changesComparedToProfile || !formIsValid;

  return (
    <div style={{ flex: 1, color: colors.grays.mediumLight, padding: '12px 24px', overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {updateCandidateProfileError && <p style={{ color: colors.red, marginRight: spacing.base }}>{updateCandidateProfileError}</p>}
        {isUpdatingCandidateProfile && <Loading size={30} />}
        {(adminHasWriteAccess || adminCanEditBasicDetails) && !isInEditMode && (
          <Button
            onClick={() => setIsInEditMode(true)}
            white
            outline
            shadow={false}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaPencilAlt style={{ marginRight: spacing.tiny }} />
              Edit
            </div>
          </Button>
        )}
        {(adminHasWriteAccess || adminCanEditBasicDetails) && isInEditMode && (
          <>
            <Button
              onClick={() => {
                handleFieldChange({ type: 'RESET_STATE' });
                setIsInEditMode(false);
                if (updateCandidateProfileError) dispatch(clearUpdateCandidateProfileError());
              }}
              white
              outline
              shadow={false}
              disabled={isUpdatingCandidateProfile}
            >
              Cancel
            </Button>
            <div style={{ marginLeft: spacing.small }}>
              <Button
                onClick={() => updateCandidate()}
                black
                shadow={false}
                disabled={saveButtonDisabled}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>

      <div style={{ overflowY: 'auto' }}>
        <EditableField
          fieldName="First Name"
          fieldKey="firstName"
          fieldType="text"
          value={fields.firstName}
          isInEditMode={isInEditMode}
          onChange={useCallback(value => handleFieldChange({ type: 'SET_FIELD', key: 'firstName', value }), [fields.firstName])}
          error={!fields.firstName ? 'First Name is required' : null}
          placeholder="Enter First Name"
          disabled={!adminCanEditBasicDetails && isInEditMode}
        />
        <EditableField
          fieldName="Middle Names"
          fieldKey="middleNames"
          fieldType="text"
          value={fields.middleNames}
          isInEditMode={isInEditMode}
          onChange={useCallback(value => handleFieldChange({ type: 'SET_FIELD', key: 'middleNames', value }), [fields.middleNames])}
          placeholder={!adminCanEditBasicDetails && isInEditMode ? null : 'Enter Middle Names'}
          disabled={!adminCanEditBasicDetails && isInEditMode}
        />
        <EditableField
          fieldName="Surname"
          fieldKey="surname"
          fieldType="text"
          value={fields.surname}
          isInEditMode={isInEditMode}
          onChange={useCallback(value => handleFieldChange({ type: 'SET_FIELD', key: 'surname', value }), [fields.surname])}
          error={!fields.surname ? 'Surname is required' : null}
          placeholder="Enter Surname"
          disabled={!adminCanEditBasicDetails && isInEditMode}
        />
        <EditableField
          isInEditMode={false}
          color={isInEditMode ? colors.accent : '#666'}
          fieldName="Date of Birth"
          value={profile.dob ? moment.utc(profile.dob).format('Do MMM YYYY') : ''}
        />
        <EditableField
          isInEditMode={false}
          color={isInEditMode ? colors.accent : '#666'}
          fieldName="Gender"
          value={profile.gender || 'Data unavailable'}
        />
        <EditableField
          fieldName="Email Address"
          description="The primary email address associated with the staff member's Dagny account"
          fieldKey="email"
          fieldType="text"
          value={fields.email}
          isInEditMode={isInEditMode}
          onChange={useCallback(value => handleFieldChange({ type: 'SET_FIELD', key: 'email', value }), [fields.email])}
          error={emailAddressIsInvalid ? 'Please enter a valid email address' : null}
          placeholder="Enter Email"
          disabled={!adminCanEditBasicDetails && isInEditMode}
        />
        <EditableField
          fieldName="Mobile Number"
          description="The primary phone number associated with the staff member's Dagny account"
          fieldKey="phone"
          fieldType="text"
          value={fields.phone}
          isInEditMode={isInEditMode}
          onChange={useCallback(value => handleFieldChange({ type: 'SET_FIELD', key: 'phone', value: value || null }), [fields.phone])}
          error={phoneNumberIsInvalid ? 'Please enter a valid UK phone number' : null}
          placeholder="Enter Telephone"
          disabled={!adminCanEditBasicDetails && isInEditMode}
        />
        <EditableField
          isInEditMode={false}
          color={isInEditMode ? colors.accent : '#666'}
          fieldName="Dagny Join Date"
          description="The date the staff member signed up to Dagny"
          value={profile.createdAt ? moment(profile.createdAt).format('Do MMM YYYY') : ''}
        />
        {adminCanViewCustomFields && (
          customFieldsMetadata.map((field) => {
            const customFieldValue = fields.customFields[field.fieldKey];

            const parameters = field.parameters ?
              {
                ...field.parameters,
                options: Array.isArray(field.parameters?.options) ? field.parameters.options.sort(firstBy((option) => (option.order ? 'order' : 'name'))) : null,
              } : null;

            return (
              <EditableField
                fieldName={field.fieldName}
                fieldKey={field.fieldKey}
                fieldType={field.type}
                description={field.description}
                value={customFieldValue}
                candidateId={candidateKey}
                key={field.fieldKey}
                parameters={parameters}
                isInEditMode={isInEditMode && adminCanEditCustomFields}
                onChange={value => handleFieldChange({ type: 'SET_FIELD', key: 'customFields', value: { ...fields.customFields, [field.fieldKey]: value } })}
                placeholder={`Enter ${field.fieldName}`}
              />
            );

          })
        )}

        {/* Password Reset Section */}
        {!!profile.email && <PasswordResetSection email={profile.email} />}
      </div>
    </div>
  );
};

const { objectOf, object, string, oneOfType, number, bool, arrayOf, shape } = PropTypes;

BasicInfoTab.propTypes = {
  candidateKey: string.isRequired,
  customFieldsMetadata: arrayOf(shape({
    fieldKey: string.isRequired,
    entity: string.isRequired,
    fieldName: string.isRequired,
    description: string.isRequired,
    type: string.isRequired,
    parameters: objectOf(
      oneOfType([
        number, string, bool, arrayOf(
          shape({ name: string, value: string }),
        ),
      ]),
    ),
    adminCanEdit: bool.isRequired,
    candidateCanEdit: bool.isRequired,
    requiredAtInvite: bool.isRequired,
  })),
  orgCandidateRelationship: objectOf(oneOfType([
    string,
    object,
    number,
    bool,
    arrayOf(oneOfType([string])),
  ]),
  ),
  profile: object,
  adminHasWriteAccess: bool.isRequired,
};

BasicInfoTab.defaultProps = {
  profile: null,
  orgCandidateRelationship: null,
  customFieldsMetadata: null,
};

export default BasicInfoTab;
