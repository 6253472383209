import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import { MdFileUpload, MdThumbUp, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import RadioButtonList from 'components/RadioButtonList';
import { browserOnBlur } from '../../lib/browser-type';
import Modal from '../../components/Modal';
import colors from '../../config/colors';
import spacing from '../../config/spacing';
import fonts from '../../config/fonts';
import Button from '../../components/Button';
import CloseButton from '../../components/CloseButton';
import { fetchAdmins } from '../../thunks/admins';
import { convertToSelectArray } from '../../lib/helpers';
import FullWidthItem from '../../components/FullWidthItem';
import * as orgsAndSitesThunks from '../../thunks/orgsAndSites';
import * as api from '../../lib/api';
import * as orgsAndSitesReducer from '../../reducers/orgsAndSites';
import ScrollView from '../../components/ScrollView';
import '../../config/singleDatePicker.scss';
import '../../index.scss';
import './styles.scss';

const styles = {
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    marginTop: spacing.base,
    width: '100%',
    textAlign: 'center',
  },
  subheader: {
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    marginTop: spacing.base,
    marginBottom: spacing.base,
  },
  input: {
    fontFamily: fonts.copy.family,
    fontSize: fonts.copy.size,
    fontWeight: fonts.copy.weight,
    color: colors.text,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.title,
    borderRadius: spacing.tiny,
    marginBottom: spacing.base,
    marginTop: spacing.base,
    padding: spacing.tiny,
    outline: 'none',
  },
  navButtonContainer: {
    width: '100%',
    clear: 'both',
  },
  nextButton: {
    float: 'right',
  },
  createButton: {
    float: 'right',
    backgroundColor: colors.green,
  },
  select: {
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },
  selectActiveDate: {
    textAlign: 'center',
  },
  profileImage: {
    height: '200px',
    maxWidth: '200px',
  },
  outer: {
    width: '100%',
  },
  page1Left: {
    width: '65%',
    float: 'left',
  },
  page1Right: {
    width: '35%',
    float: 'right',
    textAlign: 'right',
    paddingBottom: spacing.base,
    paddingTop: spacing.xlarge,
  },
  page2Left: {
    width: '50%',
    float: 'left',
    paddingBottom: spacing.base,
  },
  page2Right: {
    width: '50%',
    float: 'right',
    paddingBottom: spacing.base,
  },
};

const numberOfPages = 5;

const { func, string, bool, object, number } = PropTypes;
class NewSiteModal extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    siteImageFileName: string,
    onClose: func.isRequired,
    modalOpen: bool.isRequired,
    allUsers: object,
    siteImageUrl: string.isRequired,
    uploadProgress: number,
  }

  static defaultProps = {
    siteImageFileName: '',
    allUsers: {},
    uploadProgress: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      siteName: null,
      page: 1,
      goLiveType: 'immediate',
      goLiveDate: null,
      focused: false,
      usersType: { value: 'allUsers', label: 'All Admins' },
      selectedUsers: [],
      areaName: '',
      areaNames: [],
      uploadComplete: false,
    };
  }

  async componentDidMount() {
    this.props.dispatch(orgsAndSitesReducer.clearSiteImageData());
    if (this.props.authorisedOrgs) {
      await this.props.dispatch(fetchAdmins());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.uploadProgress === 100 && !this.state.uploadComplete) {
      setTimeout(() => {
        this.setState({ uploadComplete: true });
      }, 350);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.authorisedOrgs !== prevProps.authorisedOrgs) {
      await this.props.dispatch(fetchAdmins());
    }
  }

  nextPage = () => {
    if (this.state.page && this.state.page < numberOfPages) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  prevPage = () => {
    if (this.state.page && this.state.page > 1) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  onMount = (node) => {
    if (node) {
      this.fileInput = node;
    }
  }

  uploadNewImage = () => {
    this.setState({ uploadComplete: false });
    this.fileInput.click();
  }

  uploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.props.dispatch(orgsAndSitesThunks.uploadProfileImage(file));
    }
  }

  onChangeUsersType = (val) => {
    this.setState({ usersType: val });
  }

  onChangeSelectedUsers = (val) => {
    this.setState({ selectedUsers: val });
  }

  addAreaName = () => {
    const { areaNames } = this.state;
    if (areaNames && this.state.areaName && !areaNames.includes(this.state.areaName) && this.state.areaName.length > 0) {
      areaNames.push(this.state.areaName);
      this.setState({ areaNames, areaName: '' });
    }
  }

  onDeleteArea = (areaName) => {
    const { areaNames } = this.state;
    if (areaNames) {
      const index = areaNames.indexOf(areaName);
      if (index !== -1) {
        areaNames.splice(index, 1);
        this.setState({ areaNames });
      }
    }
  }

  canGoNext = () => {
    switch (this.state.page) {
      case 1:
        return this.state.siteName && this.state.siteName !== ' ';
      case 2:
        if (this.state.goLiveType && this.state.goLiveType === 'immediate') {
          return true;
        } else if (this.state.goLiveType && this.state.goLiveType === 'scheduled' && this.state.goLiveDate && this.state.goLiveDate !== null) {
          return true;
        }
        return false;
      case 3:
        if (this.state.usersType && this.state.usersType.value && this.state.usersType.value === 'allUsers') {
          return true;
        } else if (this.state.usersType && this.state.usersType.value && this.state.usersType.value === 'selectUsers' && this.state.selectedUsers && this.state.selectedUsers.length > 0) {
          return true;
        }
        return false;
      case 4:
        return this.state.areaNames && this.state.areaNames.length > 0;
      case 5:
        return false;
      default:
        return false;
    }
  }

  cancelNewSite = () => {
    if (this.props.siteImageFileName) {
      this.props.dispatch(orgsAndSitesThunks.deleteProfileImage(this.props.siteImageFileName));
    }
    this.setState({
      siteName: null,
      page: 1,
      goLiveType: 'immediate',
      goLiveDate: null,
      focused: false,
      usersType: { value: 'allUsers', label: 'All Admins' },
      selectedUsers: [],
      areaName: '',
      areaNames: [],
      uploadComplete: false,
    });
    this.props.dispatch(orgsAndSitesReducer.clearSiteImageData());
    this.props.onClose();
  }

  createSite = async () => {
    const formattedGoLiveDate = this.state.goLiveDate && this.state.goLiveType === 'scheduled' ? this.state.goLiveDate.toDate().toISOString() : moment().toDate().toISOString();
    const formattedUsers = [];
    if (this.state.selectedUsers && this.state.selectedUsers.length > 0) {
      this.state.selectedUsers.forEach((user) => {
        formattedUsers.push(user.value);
      });
    }

    const siteDetails = {
      siteName: this.state.siteName,
      imageUrl: this.props.siteImageUrl,
      goLiveDate: formattedGoLiveDate,
      users: formattedUsers,
      orgId: this.props.orgId,
      areas: this.state.areaNames,
    };
    await api.post('account/site/create', siteDetails);

    this.setState({
      siteName: null,
      page: 1,
      goLiveType: 'immediate',
      goLiveDate: null,
      focused: false,
      usersType: { value: 'allUsers', label: 'All Admins' },
      selectedUsers: [],
      areaName: '',
      areaNames: [],
      uploadComplete: false,
    });
    this.props.dispatch(orgsAndSitesReducer.clearSiteImageData());
    this.props.onClose();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalOpen}
        onRequestClose={this.cancelNewSite}
        contentLabel="New Site"
        contentStyle={{ width: 600, overflow: 'visible' }}
        overlayStyle={{ zIndex: 100 }}
      >
        <CloseButton handleClose={this.cancelNewSite} top={15} right={15} />
        {this.state.page === 1 ?
          <div style={styles.outer}>
            <div className={!this.state.uploadComplete ? 'uploadProgress' : 'uploadProgressNoEase'}>
              <span style={{ width: `${this.props.uploadProgress}%` }} />
            </div>
            <h1 style={styles.header}>Create a New Site</h1>
            <div style={styles.page1Left}>
              <h2 style={styles.subheader}>Basic Site Details</h2>
              <p>Tell us the name of your new site (this can always be changed at a later date). You can also upload a profile picture for your new site if you have one to hand, otherwise you can update this later too.</p>
              <input style={styles.input} value={this.state.siteName || ''} placeholder="Enter Site Name" onChange={event => this.setState({ siteName: event.target.value })} />
              <br />
            </div>
            <div style={styles.page1Right}>
              <img src={this.props.siteImageUrl} alt="Site" style={styles.profileImage} />
              <br />
              <Button
                onClick={this.uploadNewImage}
              >
                <MdFileUpload style={{ color: colors.white, marginRight: spacing.base }} />
                Upload New Image
              </Button>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={this.onMount}
                onChange={this.uploadImage}
              />
            </div>
          </div>
          : null}
        {this.state.page === 2 ?
          <div style={styles.outer}>
            <h2 style={styles.subheader}>Site Active Date</h2>
            <div style={styles.page2Left}>
              <p>Select the date when the new site will become active on Dagny. You can opt for this to happen immediately, or on a date of your choosing. Note that when the site becomes active it may take a short while for it to become available to all organisation users and job candidates.</p>
            </div>
            <div style={styles.page2Right}>
              <RadioButtonList
                items={[
                  { key: 'immediate', displayText: 'Immediate' },
                  { key: 'scheduled', displayText: 'Scheduled' },
                ]}
                selectedKey={this.state.goLiveType}
                onSelect={goLiveType => this.setState({ goLiveType })}
              />

              {this.state.goLiveType === 'scheduled' ?
                <div style={styles.selectActiveDate}>
                  <div className="datePicker">
                    <SingleDatePicker
                      displayFormat="ll"
                      placeholder="Select Date"
                      date={this.state.goLiveDate}
                      onDateChange={date => this.setState({ goLiveDate: date })}
                      focused={this.state.focused}
                      onFocusChange={({ focused }) => this.setState({ focused })}
                      numberOfMonths={1}
                      firstDayOfWeek={1}
                    />
                  </div>
                </div>
                : null}
            </div>
          </div> : null}
        {this.state.page === 3 ?
          <div>
            <h2 style={styles.subheader}>Select Organisation Admins</h2>
            <p>Which admins from your organisation would you like to add to the new site? You can either select all admins, or select from admins individually.</p>
            <div style={styles.select}>
              <Select
                name="usersType"
                value={this.state.usersType ? this.state.usersType : null}
                options={[{ value: 'allUsers', label: 'All Admins' }, { value: 'selectUsers', label: 'Select Admins' }]}
                onChange={val => this.onChangeUsersType(val)}
                clearable={false}
                classNamePrefix="ms-react-select"
                onBlur={browserOnBlur()}
              />
            </div>
            {this.state.usersType.value === 'selectUsers' ?
              <div style={styles.select}>
                <Select
                  name="selectUsers"
                  value={this.state.selectedUsers ? this.state.selectedUsers : null}
                  placeholder="Select users"
                  components={makeAnimated()}
                  options={this.props.allUsers ? convertToSelectArray(this.props.allUsers, 'displayName') : null}
                  onChange={this.onChangeSelectedUsers}
                  clearable={false}
                  isMulti
                  classNamePrefix="ms-react-select"
                  onBlur={browserOnBlur()}
                />
              </div>
              : null}
          </div>
          : null}
        {this.state.page === 4 ?
          <div>
            <h2 style={styles.subheader}>Create Site Areas</h2>
            <p>Your new site needs at least one area where work can be carried out (a ward, for example). Don&apos;t worry if you forget some areas, additional areas can easily be created at a later date, we only need one to be able to create your site.</p>
            <input style={styles.input} value={this.state.areaName} placeholder="Enter Area Name" onChange={event => this.setState({ areaName: event.target.value })} />
            <br />
            <Button
              onClick={this.addAreaName}
              disabled={this.state.areaName === ''}
            >
              Add Area
            </Button>
            <ScrollView className="areaNamesScroller" fadeAtEnd>
              {this.state.areaNames.map((areaName) => {
                return (<FullWidthItem
                  label={areaName}
                  onDelete={this.onDeleteArea}
                  key={areaName}
                />);
              })}
            </ScrollView>
          </div>
          : null}
        {this.state.page === 5 ?
          <div>
            <h2 style={styles.subheader}>Confirm new site</h2>
            <p>Click the Create Site button to confirm the new site details, or use the Back and Next buttons to make changes to your new site before confirming.</p>
          </div>
          : null}
        <div style={styles.navButtonContainer}>
          {this.state.page > 1 ?
            <Button
              onClick={this.prevPage}
            >
              <MdNavigateBefore style={{ color: colors.white, marginRight: spacing.base }} />
              Back
            </Button>
            : null}
          {this.state.page < numberOfPages ?
            <Button
              onClick={this.nextPage}
              disabled={!this.canGoNext()}
              style={styles.nextButton}
            >
              Next
              <MdNavigateNext style={{ color: colors.white, marginLeft: spacing.base }} />
            </Button>
            : null}
          {this.state.page === numberOfPages ?
            <Button
              onClick={this.createSite}
              style={styles.createButton}
            >
              <MdThumbUp style={{ color: colors.white, marginRight: spacing.base }} />
              Create Site
            </Button>
            : null}
        </div>
      </Modal>
    );
  }
}
//----------------------------------------------------------------
// REDUX CONNECT
//----------------------------------------------------------------

function mapStateToProps({ admins, orgsAndSites, global }) {
  return {
    allUsers: admins.admins,
    siteImageUrl: orgsAndSites.imageUrl,
    siteImageFileName: orgsAndSites.fileName,
    orgId: global.employerAuthorisedOrgs ? Object.keys(global.employerAuthorisedOrgs)[0] : null,
    authorisedOrgs: global.employerAuthorisedOrgs,
    uploadProgress: orgsAndSites.uploadProgress,
  };
}

export default connect(mapStateToProps)(NewSiteModal);
