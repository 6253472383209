import React, { useState, CSSProperties } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

const styles : Record<string, CSSProperties> = {
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '3px 6px 3px 6px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#FFF',
    cursor: 'pointer',
  },
};

interface CollapsibleSectionProps {
  title?: string;
  color?: string;
}

const CollapsibleSection : React.FC<CollapsibleSectionProps> = (props) => {

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <div
        style={{
          ...styles.header,
          backgroundColor: props.color ?? '#F0F0F0',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span style={{ marginTop: 6, fontSize: '0.8rem' }}>{props.title ?? ''}</span>
        {isOpen ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
      </div>
      {isOpen && props.children}
    </div>
  );

};

export default CollapsibleSection;
