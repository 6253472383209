import moment from 'moment';
import { keyBy } from 'lodash-es';
import { featureFlags } from '../../../config/featureFlags';
import { isFeatureOn } from '../../../lib/features';

function formatCell(cell) {
  if (cell === undefined || cell === null) return '';
  return `"${String(cell).replace(/"/g, '""')}"`;
}

function formatColumns(colArray) {
  const result = [];
  colArray.forEach((colEntry) => {
    let formattedCol = (colEntry.split(/(?=[A-Z])/).join(' '));
    formattedCol = (formattedCol[0].toUpperCase() + formattedCol.slice(1));
    if (colEntry === 'joinDate') {
      result.push('Join Date');
    } else if (colEntry === 'roleId') {
      result.push('Role');
    } else if (colEntry === 'gradeIds') {
      result.push('Grades');
    } else if (colEntry === 'specialityIds') {
      result.push('Specialities');
    } else {
      result.push(formattedCol);
    }
  });
  return result;
}

export default function convertToCSV(candidates, globalMetadata, customFields, global, user) {


  const specialitiesByGradeFeatureFlag = isFeatureOn(featureFlags.SPECIALITIES_BY_GRADE, null, user, global);

  // Discern Columns
  let bankKeyMappings = [];
  let standardColumns = ['name', 'email', 'phone', 'roleId', 'gradeIds', 'specialityIds', 'profRegNumber', specialitiesByGradeFeatureFlag ? 'needsReview' : null, 'status', 'joinDate'].filter(column => column !== null);
  if (global.currentOrgKey === 'swinton-pcn') {
    standardColumns = [...standardColumns, 'dob', 'gender'];
  } else if (global.currentOrgKey === 'ticketmaster') {
    bankKeyMappings = (global.adminBanks).map((e) => { return { name: e.name, key: e.bankKey }; });
    standardColumns = [...standardColumns, 'dob', 'gender', 'bankKeys'];
  }

  const allColumns = [...standardColumns, ...customFields.map(field => `custom.${field.fieldKey}`)];
  const keyedCustomFields = keyBy(customFields, field => `custom.${field.fieldKey}`);

  const na = 'N/A';

  // generate CSV String
  const headerRow = [...formatColumns(standardColumns), ...customFields.map(field => field.fieldName)];
  const rows = [headerRow];

  candidates.forEach((candidate) => {
    const row = [];
    allColumns.forEach((colTitle) => {
      // NAMES
      if (colTitle === 'name') row.push(candidate?.name ?? na);
      // PHONES
      else if (colTitle === 'phone') {
        if (!candidate.phone) {
          row.push(na);
        } else {
          let phoneString = candidate.phone.toString();
          if (phoneString.includes(' ')) {
            row.push(phoneString);
          } else {
            phoneString = `${phoneString.substr(0, 5)} ${phoneString.substr(5)}`;
            row.push(phoneString);
          }
        }
      }
      // EMAILS
      else if (colTitle === 'email') row.push(candidate?.email ?? na);
      // Date of birth
      else if (colTitle === 'dob') row.push(candidate?.dob ? moment(candidate.dob).format('DD/MM/YYYY') : na);
      else if (colTitle === 'gender') row.push(candidate?.gender ?? na);
      // JOIN DATES
      else if (colTitle === 'joinDate') row.push(candidate.joinDate ? moment(candidate.joinDate).format('Do MMM YYYY') : na);
      // ROLES
      else if (colTitle === 'roleId') {
        if (!candidate.roleKey) {
          row.push(na);
        } else {
          const role = globalMetadata.roles[candidate.roleKey]?.name;
          row.push(role);
        }
      }
      // GRADES
      else if (colTitle === 'gradeIds') {
        if (!candidate.gradeIds?.length) {
          row.push(na);
        } else {
          const grades = candidate[colTitle];
          const cell = [];
          grades.forEach((grade) => {
            if (!globalMetadata.grades[grade]) {
              cell.push(na);
            } else {
              cell.push(globalMetadata.grades[grade]?.name);
            }
          });
          row.push(cell.join(', '));
        }
      }
      // SPECIALITIES
      else if (colTitle === 'specialityIds') {
        if (!candidate.specialityIds?.length) {
          row.push(na);
        } else {
          if (!candidate.hasGranularRgs) {
            const specialities = candidate[colTitle];
            const specCell = specialities.map((specialityKey) => {
              const specialityMetadata = globalMetadata.specialities[specialityKey];
              return specialityMetadata ? specialityMetadata?.name : '';
            }).filter(Boolean);
            row.push(specCell.length ? specCell.join(', ') : na);
          } else {
            const specCell = Object.entries(candidate.specialitiesByGrade).map(([specialityKey, specialityGrades]) => {

              const specialityMetadata = globalMetadata.specialities[specialityKey];
              if (!specialityMetadata) return 'na';

              const grades = specialityGrades.map((gradeKey) => {
                const gradeMetadata = globalMetadata.roles[candidate.roleKey].linkedGrades[gradeKey];
                return gradeMetadata?.name;
              }).filter(Boolean);

              return `${specialityMetadata?.name} [${grades.join(', ')}]`;

            }).filter(Boolean);

            row.push(specCell.length ? specCell.join(', ') : na);
          }
        }
      }


      // PROFREGNUMBER
      else if (colTitle === 'profRegNumber') {
        row.push(candidate.profRegNumber ?? na);
      }

      // NEEDSREVIEW
      else if (colTitle === 'needsReview') {
        row.push(candidate.hasGranularRgs ? 'No' : 'Yes');
      }

      // STATUS
      else if (colTitle === 'status') {
        if (candidate.accountSuspended) {
          row.push('Suspended');
        } else {
          row.push('Active');
        }
      }

      // New bankName Mapping for ticketmaster
      else if (colTitle === 'bankKeys' && bankKeyMappings.length > 0) {
        const bankNames = candidate.bankKeys.map(bankKey => bankKeyMappings.find(e => e.key === bankKey)?.name);
        row.push(bankNames);
      }

      // ORG SPECIFIC FIELDS
      else if (colTitle.indexOf('custom.') === 0) {
        const customField = keyedCustomFields[colTitle];
        const customFieldValue = candidate.customFields?.[customField.fieldKey];
        if (customFieldValue) {
          switch (customField.type) {
            case 'single-select': {
              const value = customField.parameters?.options.find(field => field.value === customFieldValue)?.name;
              row.push(value);
              break;
            }
            case 'multi-select': {
              const values = customFieldValue.map(value => customField.parameters?.options.find(field => field.value === value)?.name).join(', ');
              row.push(values);
              break;
            }
            default:
              row.push(customFieldValue);
          }
        } else {
          row.push(na);
        }
      }
    });

    rows.push(row);
  });

  const csvString = rows.map(row => row.map(formatCell).join(',')).join('\n');
  return csvString;
}
