import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrgSectorKey } from '../../thunks/global';
import { updateRGSConfig } from '../../lib/api/accounts';
import RGSEdit from './RGSEdit';
import Tabs from '../../components/Tabs';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

const styles = {
  title: {
    paddingTop: spacing.large,
    fontFamily: fonts.main.family,
    fontSize: fonts.large.size,
    color: colors.positive,
    textAlign: 'center',
  },
};

const { func, string, objectOf, object } = PropTypes;
class NewRoleModal extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    sectorRoles: objectOf(object),
    roles: objectOf(object).isRequired,
    orgRoles: objectOf(object).isRequired,
    orgId: string.isRequired,
    closeModal: func.isRequired,
  }

  static defaultProps = {
    sectorRoles: {},
  }

  state = {
    editedRGS: {},
    filteredAvailable: [],
    filteredSelected: [],
    disableAnimation: false,
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getOrgSectorKey());
  }

  componentWillUnmount() {
    this.setState({
      editedRGS: {},
      filteredAvailable: [],
      filteredSelected: [],
    });
  }

  onSelect = (id) => {
    this.setState({
      editedRGS: {
        ...this.state.editedRGS,
        [id]: { enabled: true },
      },
    });
  }

  onUnselect = (id) => {
    this.setState({
      editedRGS: {
        ...this.state.editedRGS,
        [id]: { enabled: false },
      },
    });
  }

  saveChanges = async () => {
    await updateRGSConfig({
      data: this.state.editedRGS,
      orgId: this.props.orgId,
      type: 'role',
    });

    this.props.closeModal();
  }

  disableAnimation(text) {
    if (text.length < 1) {
      this.setState({
        disableAnimation: false,
      });
    } else {
      this.setState({
        disableAnimation: true,
      });
    }
  }

  filterAvailableResults = (data, input) => {
    const i = input.target.value.toLowerCase();
    this.disableAnimation(i);

    const arr = Object.keys(data).map((id) => {
      return { id, ...data[id] };
    }).filter((d) => {
      const name = d.name.toLowerCase();
      return name.search(i) !== -1;
    }).map(d => d.id);

    this.setState({
      filteredAvailable: arr,
    });
  }

  filterSelectedResults = (data, input) => {
    const i = input.target.value.toLowerCase();
    this.disableAnimation(i);

    const arr = Object.keys(data).map((id) => {
      return { id, ...data[id], name: this.props.roles[id].name };
    }).filter((d) => {
      const name = d.name.toLowerCase();
      return name.search(i) !== -1;
    }).map(d => d.id);

    this.setState({
      filteredSelected: arr,
    });
  }

  render() {
    const selectedRoles = {
      ...this.props.orgRoles,
      ...this.state.editedRGS,
    };

    const availableRoles = {};
    Object.keys(this.props.sectorRoles).forEach((id) => {
      availableRoles[id] = {
        ...this.props.sectorRoles[id],
        ...this.props.roles[id],
      };
    });

    return (
      <div>
        <h1 style={styles.title}>Configure Roles</h1>
        <Tabs
          tabs={[
            {
              tabTitle: 'Roles',
              renderTabContent: () => {
                return (
                  <RGSEdit
                    type="roles"
                    available={availableRoles}
                    selected={selectedRoles}
                    data={this.props.roles}
                    editedRGS={this.state.editedRGS}
                    onSelect={this.onSelect}
                    onUnselect={this.onUnselect}
                    saveChanges={this.saveChanges}
                    onAvailableSearchChange={event => this.filterAvailableResults(availableRoles, event)}
                    onSelectedSearchChange={event => this.filterSelectedResults(selectedRoles, event)}
                    filteredAvailable={this.state.filteredAvailable}
                    filteredSelected={this.state.filteredSelected}
                    discardChanges={() => {
                      this.setState({ editedRGS: {} });
                      this.props.closeModal();
                    }}
                    disableAnimation={this.state.disableAnimation}
                  />
                );
              },
            }]}
        />
      </div>
    );
  }
}

function mapStateToProps({ global }) {
  const sector = global.sectorKey ? global.metadata.sectors[global.sectorKey] : {};

  return {
    sectorRoles: sector.associatedRoles,
    roles: global.metadata.roles,
    orgRoles: global.orgConfig.roles,
    orgId: Object.keys(global.employerAuthorisedOrgs)[0],
  };
}

export default connect(mapStateToProps)(NewRoleModal);
