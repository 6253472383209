import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import { useFeatureFlag } from 'hooks/feature';

import { capitalize } from 'lib/helpers';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { PublishedShiftSummary, FailedShift } from 'types/ShiftTypes';
import { RolesMetadata, SpecialitiesMetadata, GradesMetadata } from 'types/Metadata';

import DataTable, { DataTableColumnDefinition } from 'components/DataTable';
import StatusPill from 'components/StatusPill';
import Button from 'components/Button';

import { CandidateRatingStar } from '../CandidateRatingStar';
import { shiftListColumns, defaultColumnsOrder, createColumnsSortFunction } from '../ShiftListHelpers';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  scrollContainer: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
};

interface GenerateDataTableColumnsProps {
  orgKey: string,
  siteAreasOn: boolean,
  shiftListViewColumnsOrder: Array<string>,
  rolesMetadata: RolesMetadata,
  specialitiesMetadata: SpecialitiesMetadata,
  gradesMetadata: GradesMetadata,
}

interface FailedShiftListColumns {
  [key: string]: DataTableColumnDefinition<FailedShift, GenerateDataTableColumnsProps>,
}

const generateDataTableColumns = (props: GenerateDataTableColumnsProps) => {

  const { siteAreasOn, shiftListViewColumnsOrder } = props;

  const columns: FailedShiftListColumns = {
    ...shiftListColumns,
    location: {
      name: 'location',
      header: 'Location',
      formatter: (job: PublishedShiftSummary) => {
        if (!siteAreasOn) return job.siteName;

        return (
          <div style={{ display: 'inline-block', lineHeight: 1, verticalAlign: 'middle' }}>
            <span style={{ fontSize: 13, fontWeight: 'bold' }}>{job.siteName}</span>
            <br />
            <span style={{ fontSize: 13 }}>{job.areaName}</span>
          </div>
        );
      },
    },
    bookedCandidate: {
      name: 'booked_candidate',
      header: 'Booked Candidate',
      formatter: (job: PublishedShiftSummary) => {

        // Booked job
        if (job.booked) {
          return (
            <div style={{ display: 'flex' }}>
              {job.candidateName && capitalize(job.candidateName)}
              {job.candidateRating && (
                <CandidateRatingStar size={10} rating={job.candidateRating} />
              )}
              {job.candidateBanks ? <span style={{ fontWeight: 'normal', color: '#666' }}>&nbsp;{` (${Object.values(job.candidateBanks)[0]})`}</span> : ''}
              {job.candidateSupplierName ? <span style={{ fontWeight: 'normal', color: '#666' }}>&nbsp;{`(${capitalize(job.candidateSupplierName)})`}</span> : ''}
            </div>
          );
        }

        // Bank applicants
        if (job.bankApplicantCount || job.supplierApplicantCount) {
          const numberOfApplicants = job.bankApplicantCount + job.supplierApplicantCount;
          const applicantsString = `${numberOfApplicants} applicant${numberOfApplicants > 1 ? 's' : ''}`;
          return <span style={{ color: '#999', fontWeight: 'normal' }}>{applicantsString}</span>;
        }

        // Draft with targeted candidate
        if (job.isDraft && job.candidateName) {
          return <span style={{ color: '#999', fontWeight: 'bold' }}>{job.candidateName}</span>;
        }

        // Released to agency
        if (job.releasedToSupplier) {
          return <span style={{ color: colors.red, opacity: 0.4, fontWeight: 'bold' }}>Released to agency</span>;
        }

        // Agency requested / approved
        if (job.agencyRequested || job.agencyApproved) {
          return <span style={{ color: colors.warning, opacity: 0.5, fontWeight: 'bold' }}>{job.agencyApproved ? 'Approved for agency' : 'Agency requested'}</span>;
        }

        return null;
      },
    },
    status: {
      name: 'status',
      header: 'Status',
      width: 145,
      formatter: (job: PublishedShiftSummary, index, { orgKey }) => <StatusPill job={{ ...job, orgKey }} />,
    },
    failureReason: {
      name: 'failureReason',
      header: 'Reason For Failure',
      formatter: (reason: string) => <span style={{ color: colors.red }}>{reason}</span>,
    },
  };

  const columnsToReturn = [...(shiftListViewColumnsOrder ?? defaultColumnsOrder)].filter(column => column !== 'actions').map(column => columns[column]);
  columnsToReturn.push(columns.failureReason);

  return columnsToReturn;
};

interface FailedShiftsListProps {
  failedShifts: Array<FailedShift>,
  onClose: () => void,
}

function FailedShiftsList(props: FailedShiftsListProps) : React.ReactElement {

  const siteAreasOn = useFeatureFlag('siteAreas');
  const orgKey = useSelector(state => state.global.currentOrgKey);
  const shiftListViewSortOrder = useSelector(state => state.global.orgConfig?.shiftListViewSortOrder);
  const shiftListViewColumnsOrder = useSelector(state => state.global.orgConfig?.shiftListViewColumnsOrder);
  const specialitiesMetadata = useSelector(state => state.global.metadata.specialities);
  const rolesMetadata = useSelector(state => state.global.metadata.roles);
  const gradesMetadata = useSelector(state => state.global.metadata.grades);

  const combinedProps = {
    ...props,
    orgKey,
    siteAreasOn,
    shiftListViewColumnsOrder,
    rolesMetadata,
    specialitiesMetadata,
    gradesMetadata,
  };

  const columnsSortFunction = createColumnsSortFunction({ shiftListViewSortOrder, rolesMetadata, gradesMetadata, specialitiesMetadata });

  return (
    <>
      <div style={styles.scrollContainer}>
        <DataTable
          fixedLayout
          style={{ width: '1060px' }}
          rows={props.failedShifts.sort(columnsSortFunction)}
          columns={generateDataTableColumns(combinedProps)}
          overrideHeadingStyles={{
            color: '#666666',
            textTransform: 'none',
          }}
          additionalProps={combinedProps}
        />
      </div>
      <div style={{ flex: '0 0 auto', display: 'flex', justifyContent: 'flex-end', marginTop: spacing.base }}>
        <Button large outline onClick={props.onClose}>Close</Button>
      </div>
    </>
  );
}

export default FailedShiftsList;
