import moment from 'moment-timezone';

const ADD_SELECTED_DATE = 'ADD_SELECTED_DATE';
const ADD_SELECTED_JOB = 'ADD_SELECTED_JOB';
const CLEAR_SELECTED_JOB = 'CLEAR_SELECTED_JOB';
const ADD_ROLES = 'ADD_ROLES';
const ADD_GRADES = 'ADD_GRADES';
const ADD_SPECIALITIES = 'ADD_SPECIALITIES';
const ADD_LAST_NAV_DATE = 'ADD_LAST_NAV_DATE';

export function addSelectedDate(date) {
  return {
    type: ADD_SELECTED_DATE,
    date,
  };
}

export function addSelectedJob(jobId) {
  return {
    type: ADD_SELECTED_JOB,
    jobId,
  };
}

export function clearSelectedJob() {
  return {
    type: CLEAR_SELECTED_JOB,
  };
}

export function addRoles(roles) {
  return {
    type: ADD_ROLES,
    roles,
  };
}

export function addGrades(grades) {
  return {
    type: ADD_GRADES,
    grades,
  };
}

export function addSpecialities(specialities) {
  return {
    type: ADD_SPECIALITIES,
    specialities,
  };
}

const initialState = {
  selectedDate: null,
  selectedJob: null,
  roles: null,
  grades: null,
  specialities: null,
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case ADD_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.date,
      };
    case ADD_SELECTED_JOB:
      return {
        ...state,
        selectedJob: action.jobId,
      };
    case CLEAR_SELECTED_JOB:
      return {
        ...state,
        selectedJob: null,
      };
    case ADD_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case ADD_GRADES:
      return {
        ...state,
        grades: action.grades,
      };
    case ADD_SPECIALITIES:
      return {
        ...state,
        specialities: action.specialities,
      };
    default:
      return state;
  }
}
