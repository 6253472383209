import React from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import Tabs from '../../components/Tabs';
import SupplierBasicInformationContainer from './SupplierBasicInformationContainer';
import SupplierContactsContainer from './SupplierContactsContainer';

const styles = {
  title: {
    paddingTop: spacing.large,
    fontSize: '1.5rem',
    fontWeight: 500,
    color: colors.text,
    textAlign: 'center',
  },
};

export default function SupplierDetail(props) {
  if (!props.isOpen) {
    return (<div />);
  }
  const basic = {
    tabTitle: 'Basic Information',
    renderTabContent: () => {
      return (
        <SupplierBasicInformationContainer
          closeSupplierModal={props.closeSupplierModal}
        />
      );
    },
  };
  const contacts = {
    tabTitle: 'Contacts',
    renderTabContent: () => {
      return (
        <SupplierContactsContainer />
      );
    },
  };
  const tabs = props.isNewSupplier ? [basic] : [basic, contacts];

  return (
    <div>
      <div style={styles.title}>{props.title}</div>
      <Tabs
        tabs={tabs}
      />
    </div>
  );
}

const { string, bool, func } = PropTypes;

SupplierDetail.propTypes = {
  title: string.isRequired,
  isNewSupplier: bool.isRequired,
  isOpen: bool.isRequired,
  closeSupplierModal: func.isRequired,
};
