import React from 'react';
import { useSelector } from 'hooks/redux';
import { mapValues } from 'lodash-es';

import { SHIFT_GROUPING, smartGroup, Group } from 'lib/shift-grouping';
import { CalendarHeader, CalendarDays } from 'components/Calendar';
import colors from 'config/colors';
import { Period } from 'types/ShiftTypes';

import { useSelectedShifts } from '../shift-hooks';
import CollapsibleSection from './CollapsibleSection';
import WeekCalendarEventComponent from './WeekCalendarEventComponent';

// const sectionColors = [colors.red, colors.ratingYellow, colors.green, colors.positiveLight];
// const sectionColors = [colors.amber, colors.positive];
const sectionColors = [colors.positive];

interface WeekCalendarProps {
  period: Pick<Period, 'groupByDay' | 'days' | 'columns' | 'formatTime'>,
  shiftSections: Array<Group>,
  selectedShift?: string,
  sortShiftsBy: 'startTime' | 'dayOfWeek'
  openShiftTab: (shiftKey: string) => void,
  copyShift: (shiftKey: string) => void,
  removeShift: (shiftKey: string) => void,
}

export default function WeekCalendar(props: WeekCalendarProps) : React.ReactElement {

  const shiftRosterSettings = useSelector(state => state.global.orgConfig.shiftRosterView);
  const specialitiesMetadata = useSelector(state => state.global.metadata.specialities);
  const gradesMetadata = useSelector(state => state.global.metadata.grades);
  const { selectedShiftKeys, setShiftIsSelected, selectSpecifiedShifts, unselectSpecifiedShifts } = useSelectedShifts();

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: '0 0 140px', borderRight: '1px solid #EAEAEA', position: 'relative' }} />
        <div style={{ flex: '1 1 0px' }}>
          <CalendarHeader period={props.period} />
        </div>
      </div>
      <div className="shiftCardScrollContainer" style={{ flex: '1 1 0px', position: 'relative', overflow: 'auto', borderTop: '1px solid #EAEAEA' }}>
        {props.shiftSections.map((section, i) => {

          const sectionTitle = `${section.label} (${section.shifts.length} shifts)`;
          const groupedShifts = smartGroup(section.shifts, SHIFT_GROUPING[shiftRosterSettings?.groupBy ?? 'speciality'], { specialitiesMetadata, gradesMetadata });

          return (
            <CollapsibleSection title={sectionTitle} color={colors.black}>
              {groupedShifts.map(group => (
                <WeekCalendarRow
                  group={group}
                  period={props.period}
                  openShiftTab={props.openShiftTab}
                  selectedShift={props.selectedShift}
                  sortShiftsBy={props.sortShiftsBy}
                  selectedShiftKeys={selectedShiftKeys}
                  setShiftIsSelected={setShiftIsSelected}
                  selectSpecifiedShifts={selectSpecifiedShifts}
                  unselectSpecifiedShifts={unselectSpecifiedShifts}
                  removeShift={props.removeShift}
                  copyShift={props.copyShift}
                />
              ))}
            </CollapsibleSection>
          );
        })}
      </div>
    </div>
  );
}

WeekCalendar.defaultProps = { selectedShift: undefined };

interface WeekCalendarRowProps {
  group: Group,
  period: Pick<Period, 'groupByDay' | 'days' | 'columns' | 'formatTime'>,
  sortShiftsBy: 'startTime' | 'dayOfWeek'
  selectedShift?: string,
  selectedShiftKeys: string[],
  setShiftIsSelected: (key: string, isSelected: boolean) => void
  selectSpecifiedShifts: (shiftKeys: string[]) => void
  unselectSpecifiedShifts: (shiftKeys: string[]) => void,
  openShiftTab: (shiftKey: string) => void,
  copyShift: (shiftKey: string) => void,
  removeShift: (shiftKey: string) => void,
}

function WeekCalendarRow(props: WeekCalendarRowProps): React.ReactElement {

  const { group, period, openShiftTab, selectedShift, selectedShiftKeys, setShiftIsSelected, selectSpecifiedShifts, unselectSpecifiedShifts } = props;

  const events = mapValues(
    period.groupByDay(group.shifts),
    (shifts) => ({
      shifts,
      selectedShift,
      sortShiftsBy: props.sortShiftsBy,
      openShiftTab,
      removeShift: props.removeShift,
      copyShift: props.copyShift,
      selectedShiftKeys,
      setShiftIsSelected,
      selectSpecifiedShifts,
      unselectSpecifiedShifts,
    }),
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          flex: '0 0 140px',
          boxSizing: 'border-box',
          borderRight: '1px solid #EAEAEA',
          borderBottom: '1px solid #EAEAEA',
          marginTop: 1,
          padding: '6px',
          fontSize: '0.9rem',
          fontWeight: 500,
          color: colors.text,
          // backgroundColor: '#f9f9f9',
          lineHeight: 1.2,
        }}
      >
        {group.label}
      </div>
      <div style={{ flex: '1 1 0px' }}>
        <CalendarDays
          period={period}
          events={events}
          eventComponent={WeekCalendarEventComponent}
          highlightCurrentDay={false}
        />
      </div>
    </div>
  );
}

WeekCalendarRow.defaultProps = { selectedShift: undefined };
