import React from 'react';
import { CWTagSelect } from '../../components/CWSelect';

interface Participant {
  value: string;
  label: string;
  isSelected?: boolean;
}

interface IndividualToInputProps {
  availableRecipients: Participant[];
  selectedRecipientKeys: string[];
  setSelectedRecipients: (participants: string[]) => void;
}

export default function IndividualToInput(props: IndividualToInputProps) : React.ReactElement {
  return (
    <div className="messagingFromToContainer">
      <p style={{ fontSize: '1rem', fontWeight: 600 }}>To:</p>
      <CWTagSelect
        items={props.availableRecipients}
        selectedItems={props.selectedRecipientKeys}
        setSelectedItems={props.setSelectedRecipients}
        placeholder="Select recipients..."
      />
    </div>
  );
}
