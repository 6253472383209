const ADD_HELP = 'ADD_HELP';
const LOADING_HELP = 'LOADING_HELP';

export const loadingHelp = () => ({ type: LOADING_HELP });
export const addHelp = helpData => ({ type: ADD_HELP, helpData });

const initialState = {
  helpList: [],
  helpData: {},
  loading: false,
};

export default function help(state = initialState, action) {
  switch (action.type) {

    case LOADING_HELP:
      return { ...state, loading: true };

    case ADD_HELP: {
      const helpList = [];

      if (action.helpData) {
        Object.keys(action.helpData).forEach((key) => {
          helpList.push({
            id: key,
            ...action.helpData[key],
          });
        });

        helpList.sort((a, b) => {
          if (!a.sortOrder && !b.sortOrder) {
            return 0;
          }
          return a.sortOrder - b.sortOrder;
        });
      }

      return {
        ...state,
        helpData: action.helpData,
        helpList,
        loading: false,
      };
    }

    default:
      return state;
  }
}
