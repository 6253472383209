import React, { useEffect, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { MdArrowBack } from 'react-icons/md';
import { firstBy } from 'thenby';

import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import DataTable, { DataTableColumnDefinition } from 'components/DataTable';
import Loading from 'components/Loading';

import { fetchPaymentRunSummaries } from 'thunks/timesheets';

import colors from 'config/colors';
import spacing from 'config/spacing';
import { useFeatureFlag } from 'hooks/feature';
import { convertFromUtcToTimezone } from 'lib/helpers-time';

import { PaymentRunStatusPill } from './PaymentRunStatusPill';

import { CustomFieldMetadata } from '../../../types/CustomFields';
import { PayRunListViewType } from '../../../types/PaymentRun';
import { formatCutoff } from './payment-run-helpers';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  timesheetCount: {
    color: '#999',
    lineHeight: 1,
  },
  paymentRunsHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '18px 12px 18px 12px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.cavalry.line}`,
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  backArrow: {
    color: colors.text,
    height: 36,
    width: 36,
    marginRight: 12,
  },
  bodyContainer: {
    marginTop: 12,
    overflowY: 'auto',
    flex: 1,
  },
  centreContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listHeader: {
    position: 'sticky',
    zIndex: 1, // Otherwise cell contents can end up on top of header
    top: 0,
    fontSize: '1.2rem',
    fontWeight: 600,
    backgroundColor: 'white',
    color: colors.text,
    padding: 12,
  },
};

interface DataTableColumnProps {
  customFieldsMetadata: CustomFieldMetadata[],
  paymentRunListViewColumnsOrder: string[],
  orgTimezone: string
}

interface PaymentRunListViewColumns {
  [key: string]: DataTableColumnDefinition<PayRunListViewType>,
}

const defaultColumnsOrder = ['status', 'scheduledFor', 'sentForPaymentAt', 'toBePaidOn', 'paidAt', 'settings', 'actions'];
const scheduledPaymentRunsColumnsBlackList = ['sentForPaymentAt', 'paidAt'];
const pastPaymentRunsColumnsBlackList = ['scheduledFor', 'toBePaidOn'];

const scheduledFor = (datetime: string) : React.ReactElement => {
  return <span style={{ color: colors.understated }}>Scheduled for:<br />{datetime}</span>;
};

const dataTableColumns = (props: DataTableColumnProps) : DataTableColumnDefinition<PayRunListViewType>[] => {

  const columns: PaymentRunListViewColumns = {
    paymentRunTypeName: {
      header: 'Run Type',
      name: 'paymentRunTypeName',
      width: 140,
    },
    cutOffTime: {
      header: 'Cut Off',
      name: 'cutOffTime',
      width: 140,
      formatter: (cutOffTime: string) => {
        return formatCutoff(convertFromUtcToTimezone(cutOffTime, props.orgTimezone));
      },
    },
    status: {
      header: 'Status',
      name: 'status',
      width: 140,
      formatter: (payRun : PayRunListViewType) => {
        return <PaymentRunStatusPill paidAt={payRun.paidAt} sentAt={payRun.sentAt} scheduledPaymentTime={payRun.scheduledPaymentTime} scheduledSendTime={payRun.scheduledSendTime} />;
      },
    },
    scheduledFor: {
      header: 'Scheduled For',
      name: 'scheduledFor',
      width: 140,
      formatter: ({ scheduledSendTime } : PayRunListViewType) => {
        if (scheduledSendTime) {
          const convertedScheduledSendTime = convertFromUtcToTimezone(scheduledSendTime, props.orgTimezone);
          return scheduledFor(`${convertedScheduledSendTime.format('Do MMM YYYY')} at ${convertedScheduledSendTime.format('HH:mm')}`);
        }
        return 'Not scheduled';
      },
    },
    sentForPaymentAt: {
      header: 'Sent For Payment At',
      name: 'sentForPaymentAt',
      width: 100,
      formatter: ({ sentAt } : PayRunListViewType) => {
        if (sentAt) {
          const convertedSentAtTime = convertFromUtcToTimezone(sentAt, props.orgTimezone);
          return `${convertedSentAtTime.format('Do MMM YYYY')} at ${convertedSentAtTime.format('HH:mm')}`;
        }
        return null;
      },
    },
    toBePaidOn: {
      header: 'To Be Paid On',
      name: 'toBePaidOn',
      width: 140,
      formatter: ({ scheduledPaymentTime } : PayRunListViewType) => {
        if (scheduledPaymentTime) {
          return scheduledFor(convertFromUtcToTimezone(scheduledPaymentTime, props.orgTimezone).format('Do MMM YYYY'));
        }
        return null;
      },
    },
    paidAt: {
      header: 'Paid At',
      name: 'paidAtDate',
      width: 140,
      formatter: ({ paidAt, scheduledPaymentTime } : PayRunListViewType) => {
        if (paidAt) {
          const convertedSentAtTime = convertFromUtcToTimezone(paidAt, props.orgTimezone);
          return `${convertedSentAtTime.format('Do MMM YYYY')} at ${convertedSentAtTime.format('HH:mm')}`;
        }
        if (scheduledPaymentTime) {
          return scheduledFor(convertFromUtcToTimezone(scheduledPaymentTime, props.orgTimezone).format('Do MMM YYYY'));
        }
        return null;
      },
    },
    settings: {
      header: 'Settings',
      name: 'settingsColumn',
      width: 140,
      formatter: (payRun: PayRunListViewType) => {
        // Find any customFields from settings object and return label names from metadata
        const settingsToRender = Object.entries(payRun.settings).flatMap(([settingKey, settingValue]) => {

          const customFieldMetadata = props.customFieldsMetadata.find(meta => meta.fieldKey === settingKey);

          if (customFieldMetadata) {
            // If customField is from select list, find value name
            const customFieldOptionValue = customFieldMetadata.parameters?.options?.find(option => option.value === settingValue);
            return { label: customFieldMetadata.fieldName, value: customFieldOptionValue?.name ?? settingValue };
          }

          return null;
        }).filter(Boolean) as Array<{ label: string, value: string }>;

        // Add shiftStartTimeCutoff to front of array
        if (typeof payRun.settings.shiftStartTimeCutOff === 'string') {
          const convertedShiftStartTimeCutOff = convertFromUtcToTimezone(payRun.settings.shiftStartTimeCutOff, props.orgTimezone);
          settingsToRender.unshift({ label: 'Cut Off', value: `${convertedShiftStartTimeCutOff.format('Do MMM YYYY')} at ${convertedShiftStartTimeCutOff.format('HH:mm')}` });
        }

        return settingsToRender.map((setting: { label: string, value: string }) => {
          return (
            <div key={setting.value}>
              <span style={{ marginRight: spacing.small, lineHeight: 1.4, fontWeight: 600 }}>{setting.label}:</span>
              <span style={{ lineHeight: 1.4 }}>{setting.value}</span>
            </div>
          );
        });
      },
    },
    actions: {
      header: 'Actions',
      name: 'actions',
      width: 60,
      formatter: () => {
        return <Button white outline shadow={false}>Manage</Button>;
      },
    },
  };

  return props.paymentRunListViewColumnsOrder.map((column: string) => columns[column]).filter(Boolean);
};

interface PaymentRunsListProps {
  goToTimesheetsListView: () => void,
  goToPaymentRunDetailsView: (paymentRunKey: string) => void,
  goToPaymentRunCreateForm: () => void,
  selectedPaymentRunKey: string | null,
}

type State = any;

const PaymentRunsList : React.FC<PaymentRunsListProps> = (props : PaymentRunsListProps) => {
  const dispatch: ThunkDispatch<State, void, AnyAction > = useDispatch();

  const customFieldsMetadata = useSelector((state: State) => state.global.customFieldsMetadata);
  const orgTimezone = useSelector((state: State) => state.global.orgConfig?.timezone ?? 'Europe/London');
  const paymentRunListViewColumnsOrder = useSelector((state: State) => state.global.orgConfig?.paymentRunListViewColumnsOrder);
  const paymentRuns = useSelector((state: State) => state.timesheets.paymentRuns);
  const isFetchingPaymentRuns = useSelector((state: State) => state.timesheets.isFetchingPaymentRuns);
  const paymentRunListViewError = useSelector((state: State) => state.timesheets.paymentRunListViewError);

  const adminCanViewPaymentRuns = useFeatureFlag(null, 'payRunCanView');
  const adminCanManagePaymentRuns = useFeatureFlag(null, 'payRunCanManage');

  useEffect(() => {
    dispatch(fetchPaymentRunSummaries());
    // let subscription: Subscription;
    // if (adminCanViewPaymentRuns) subscription = dispatch(watchPaymentRuns());

    // // Unsubscribe when unmounting component
    // return () => {
    //   if (subscription) subscription.unsubscribe();
    // };
  }, []);

  const paymentRunsWithSelected = (paymentRuns ?? []).map((run: PayRunListViewType) => ({
    ...run,
    className: run.key === props.selectedPaymentRunKey ? 'shifts-list__selected-row' : '',
  }));

  const pastPaymentRuns = paymentRunsWithSelected.filter((run: PayRunListViewType) => run.sentAt);
  const scheduledPaymentRuns = paymentRunsWithSelected
    .filter((run: PayRunListViewType) => !run.sentAt)
    .sort(firstBy((run: PayRunListViewType) => !!run.scheduledSendTime).thenBy('scheduledSendTime', -1));

  if (paymentRunListViewError && !paymentRuns) {
    return (
      <Page vflex title="Payment Runs">
        <div style={styles.paymentRunsHeaderContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MdArrowBack
              className="rise"
              onClick={() => props.goToTimesheetsListView()}
              style={styles.backArrow}
            />
            <PageHeader style={{ margin: 0 }} title="Payment Runs" />
          </div>
        </div>
        <div style={styles.centreContainer}>
          <p style={{ color: colors.red }}>Oops! Something went wrong. Please try again. If the problem persists, please contact customer support.</p>
        </div>
      </Page>
    );
  }

  if (isFetchingPaymentRuns) {
    <Page vflex title="Payment Runs">
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loading />
      </div>
    </Page>;
  }

  if (!adminCanViewPaymentRuns) {
    return (
      <Page vflex title="Payment Runs">
        <div style={styles.paymentRunsHeaderContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MdArrowBack
              className="rise"
              onClick={() => props.goToTimesheetsListView()}
              style={styles.backArrow}
            />
            <PageHeader style={{ margin: 0 }} title="Payment Runs" />
          </div>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: colors.text }}>You do have the correct permission to view payment runs.</p>
        </div>
      </Page>
    );
  }

  return (
    <Page vflex title="Payment Runs">
      <div style={styles.paymentRunsHeaderContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MdArrowBack className="rise" onClick={() => props.goToTimesheetsListView()} style={styles.backArrow} />
          <PageHeader style={{ margin: 0 }} title="Payment Runs" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!adminCanManagePaymentRuns && (
            <p style={{ color: colors.red, marginRight: spacing.base }}>You do not have the correct permission to manage payment runs.</p>
          )}
          <Button black shadow={false} onClick={() => props.goToPaymentRunCreateForm()} disabled={!adminCanManagePaymentRuns}>Create Payment Run</Button>
        </div>
      </div>
      <div style={styles.bodyContainer}>
        {!!scheduledPaymentRuns.length && (
          <div>
            <h4 style={styles.listHeader}>Future Payment Runs</h4>
            <DataTable
              rows={scheduledPaymentRuns}
              columns={dataTableColumns({
                customFieldsMetadata,
                paymentRunListViewColumnsOrder: (paymentRunListViewColumnsOrder || defaultColumnsOrder).filter((column: string) => !scheduledPaymentRunsColumnsBlackList.includes(column)),
                orgTimezone,
              })}
              style={{ minWidth: '840px' }}
              overrideHeadingStyles={{
                color: colors.text,
                textTransform: 'none',
                fontSize: '0.9rem',
              }}
              noTextWrap
              onRowClick={paymentRunKey => props.goToPaymentRunDetailsView(paymentRunKey as string)}
            />
          </div>
        )}
        {!!pastPaymentRuns.length && (
          <div>
            <h4 style={{ ...styles.listHeader, paddingTop: 24 }}>Past Payment Runs</h4>
            <DataTable
              rows={pastPaymentRuns.sort(firstBy('sentAt', -1))}
              columns={dataTableColumns({
                customFieldsMetadata,
                paymentRunListViewColumnsOrder: (paymentRunListViewColumnsOrder || defaultColumnsOrder).filter((column: string) => !pastPaymentRunsColumnsBlackList.includes(column)),
                orgTimezone,
              })}
              style={{ minWidth: '840px' }}
              overrideHeadingStyles={{
                color: colors.text,
                textTransform: 'none',
                fontSize: '0.9rem',
              }}
              onRowClick={paymentRunKey => props.goToPaymentRunDetailsView(paymentRunKey as string)}
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default PaymentRunsList;
