import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdPlayArrow, MdAdd } from 'react-icons/md';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import colors from 'config/colors';
import { startWatchingHelp } from 'thunks/help';

import './Help.scss';

const HelpContainer = () => {

  const dispatch = useDispatch();
  dispatch(startWatchingHelp());

  const loading = useSelector(state => state.help.loading);
  const helpList = useSelector(state => state.help.helpList);

  if (loading || !helpList || !helpList.map) {
    return <Page title="Help Centre"><div>Loading...</div></Page>;
  }

  return (
    <Page vflex title="Help Centre">
      <div className="helpCentreHeader"><PageHeader title="Help Centre" /></div>
      <div className="helpCentre">
        {helpList.map((helpItem, index) => {
          const prefix = helpItem.articleNumber ? `${helpItem.articleNumber}. ` : '';
          return (
            <>
              <Link to={`/help/${helpItem.id}`} className={`helpHeaderLink test-id-help-item-${index}`} key={helpItem.id}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 className="helpHeader">
                    {`${prefix}${helpItem.title}`}
                  </h2>
                  <MdAdd color={colors.text} size={24} />
                </div>
              </Link>
              <div>
                {index < helpList.length - 1 && (
                  <div style={{ width: '100%', height: 1, backgroundColor: colors.cavalry.line }} />
                )}
              </div>
            </>
          );
        })}
      </div>
    </Page>
  );
};

export default HelpContainer;
