const LOADING_START = 'LOADING_START';
const LOADING_STOP = 'LOADING_STOP';

export function startLoading() {
  return {
    type: LOADING_START,
  };
}

export function stopLoading() {
  return {
    type: LOADING_STOP,
  };
}

const initialState = {
  loading: false,
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        loading: true,
      };

    case LOADING_STOP:
      return {
        loading: false,
      };

    default:
      return state;
  }
}
