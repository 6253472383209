import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import spacing from '../../config/spacing';
import { addDeepProperty, findInObject } from '../../lib/helpers-object';
import { requiredField } from '../../lib/form-input-validators';
import TextSectionHeader from '../../components/TextSectionHeader';
import FormInput from '../../components/FormInput';

const styles = {
  formContainer: {
    overflowY: 'scroll',
    boxSizing: 'border-box',
    height: 470,
    width: '90%',
    margin: '0 auto',
  },
  contactDetailsContainer: {
    paddingBottom: spacing.small,
  },
  pane: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    padding: '0 5px',
  },
  paneLeft: {
    width: '50%',
  },
  paneRight: {
    width: '50%',
  },
};

const { bool, objectOf, oneOfType, object, number, string, func } = PropTypes;

export default class SupplierContactForm extends Component {
  static propTypes = {
    initialValues: objectOf(oneOfType([object, number, string, bool])),
    onSubmit: func.isRequired,
    triggerSubmit: bool.isRequired,
  }

  static defaultProps = {
    initialValues: {},
  };

  constructor(props) {
    super(props);
    this.formInputsPassingValidation = {};
    this.state = { formInputValues: clone(props.initialValues) };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.triggerSubmit) {
      const formInvalid = this.formIsInvalid();
      if (!formInvalid) {
        this.props.onSubmit(this.state.formInputValues);
      }
    }
  }

  onInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    const newFormInputValues = addDeepProperty(this.state.formInputValues, name, value);
    this.setState({ formInputValues: newFormInputValues });
  }

  onInputValidate = (inputName, isValid) => {
    this.formInputsPassingValidation = addDeepProperty(this.formInputsPassingValidation, inputName, isValid);
  }

  onResponsibilitiesUpdated = (updatedResponsibilities) => {
    this.setState({
      ...this.state,
      formInputValues: {
        ...this.state.formInputValues,
        responsibilities: updatedResponsibilities,
      },
    });
  }

  formIsInvalid = () => {
    return findInObject(this.formInputsPassingValidation, false);
  }

  render() {
    const { formInputValues } = this.state;
    return (
      <div style={styles.formContainer}>
        <TextSectionHeader text="Edit Contact Details" uppercase />
        <form onSubmit={this.props.onSubmit}>
          <div style={styles.contactDetailsContainer}>
            <div style={{ ...styles.pane, ...styles.paneLeft }}>
              <FormInput
                label="First Name"
                name="firstName"
                onChange={this.onInputChange}
                onValidate={this.onInputValidate}
                placeholder=""
                type="text"
                validate={requiredField}
                value={formInputValues.firstName}
              />
              <FormInput
                label="Surname"
                name="surname"
                onChange={this.onInputChange}
                onValidate={this.onInputValidate}
                placeholder=""
                type="text"
                validate={requiredField}
                value={formInputValues.surname}
              />
            </div>
            <div style={{ ...styles.pane, ...styles.paneRight }}>
              <FormInput
                label="Telephone"
                name="phone"
                onChange={this.onInputChange}
                onValidate={this.onInputValidate}
                placeholder=""
                type="text"
                value={formInputValues.phone}
              />
              <FormInput
                label="Mobile"
                name="mobile"
                onChange={this.onInputChange}
                onValidate={this.onInputValidate}
                placeholder=""
                type="text"
                value={formInputValues.mobile}
              />
              <FormInput
                label="Email"
                name="email"
                onChange={this.onInputChange}
                onValidate={this.onInputValidate}
                placeholder=""
                type="text"
                validate={requiredField}
                value={formInputValues.email}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
