import React from 'react';
import { Link } from 'react-router-dom';
import spacing from 'config/spacing';
import colors from 'config/colors';

const styles = {
  logo: {
    display: 'block',
    padding: spacing.base,
    color: colors.positive,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  logoText: {
    fontWeight: 400,
    verticalAlign: 'middle',
    fontSize: '1em',
    marginLeft: spacing.tiny,
    fontFamily: 'Oxygen',
    color: 'white',
  },
};

interface LogoProps {
  showText?: boolean;
  size?: number;
}

const Logo : React.FC<LogoProps> = (props: LogoProps) => {

  const imageStyles = {
    verticalAlign: 'middle',
    width: props.size,
  };

  return (
    <Link to="/" style={styles.logo}>
      <img src={require('../../images/dagny-logo.png')} alt="Dagny" style={imageStyles} />
      {props.showText && <span style={styles.logoText}>Dagny</span>}
    </Link>
  );
};

Logo.defaultProps = {
  size: 80,
  showText: false,
};

export default Logo;
