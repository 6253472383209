import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { login } from 'lib/api/auth';
import * as userThunks from 'thunks/user';
import * as userReducer from 'reducers/user';
import { setHasSkippedPasswordReset, setPasswordExpiryCurrentPassword } from 'reducers/global';

import Button from 'components/Button';
import Loading from 'components/Loading';

import AuthPage from './ui/AuthPage';
import PasswordResetContainer from './PasswordResetContainer';

import { fieldStyles } from './ui/field-styles';

const styles = {
  messageContainer: {
    borderRadius: 2,
    // marginTop: spacing.base,
    padding: spacing.base,
    textAlign: 'center',
    color: colors.positive,
    fontSize: 14,
  },
};

class PasswordExpiryContainer extends Component {

  constructor() {
    super();
    this.state = {
      formValues: {},
      currentPassword: null,
      screen: 'landing',
      passwordResetLoading: false,
      passwordResetSent: false,
      passwordResetError: null,
      passwordValidated: false,
      passwordValidateLoading: false,
      passwordValidateError: false,
    };
  }

  setFormValue = (key, value) => {
    this.setState(state => ({
      ...state,
      formValues: { ...state.formValues, [key]: value },
    }));
  }

  skipPasswordReset = async () => {
    this.props.dispatch(setHasSkippedPasswordReset());
  }

  validatePasswordAndContinue = async () => {
    if (this.props.passwordExpiry?.currentPassword) {
      this.setState({ screen: 'reset-password', passwordValidated: true });
    } else {
      this.setState({ passwordValidateLoading: true, passwordValidated: false, passwordValidateError: false });
      try {
        await login(this.props.passwordExpiry?.email, this.state.currentPassword, { validatePasswordOnly: true });
        this.props.dispatch(setPasswordExpiryCurrentPassword(this.state.currentPassword));
        this.setState({ passwordValidateLoading: false, passwordValidated: true, passwordValidateError: false, screen: 'reset-password' });
      } catch (err) {
        const message = err.humanReadableErrorMessage ?? err.message ?? 'Error validating password';
        this.setState({ passwordValidateLoading: false, passwordValidated: false, passwordValidateError: message });
      }
    }
  }

  sendResetPasswordEmail = async () => {
    this.props.dispatch(userReducer.clearError());
    try {
      this.setState({ passwordResetLoading: true, passwordResetSent: false, passwordResetError: null });
      await this.props.dispatch(userThunks.sendResetPasswordEmail(this.props.passwordExpiry?.email, 'web-app'));
      this.setState({ passwordResetSent: true, passwordResetLoading: false, passwordResetError: null });
    } catch (error) {
      this.setState({ passwordResetSent: false, passwordResetLoading: false, passwordResetError: error.humanReadableErrorMessage ?? error.message ?? 'Error sending password reset' });
    }
  }

  render() {
    const passwordExpiry = this.props.passwordExpiry;
    const humanReadableDate = moment.utc(passwordExpiry.passwordExpiresAt).local().format('Do MMM YYYY');
    const header = passwordExpiry.passwordExpired ? 'Password expired' : 'Password Expiring Soon';
    const continueDisabled = !passwordExpiry.currentPassword && !this.state.currentPassword;

    if (this.state.passwordResetLoading || this.state.passwordValidateLoading) {
      return (
        <AuthPage header={header}>
          <Loading flex />
        </AuthPage>
      );
    }

    if (this.state.passwordResetSent) {
      return (
        <AuthPage header={header}>
          <div style={styles.messageContainer}>
            <p>Password reset email sent. Please check your inbox and follow the link to reset your password.</p>
          </div>
        </AuthPage>
      );
    }

    if (this.state.screen === 'reset-password') {
      return <PasswordResetContainer email={passwordExpiry.email} currentPassword={passwordExpiry.currentPassword} />;
    }

    return (
      <AuthPage header={header}>
        {passwordExpiry.passwordExpired ?
          <>
            <p style={{ marginBottom: 12, fontSize: 14, color: '#333' }}>Your organisation has specified that passwords must be rotated on a periodic basis. You must set a new password in order to continue accessing Dagny.</p>
            {!passwordExpiry.currentPassword && <p style={{ marginBottom: 12, fontSize: 14, color: '#333' }}>First enter your current password to prove it is you:</p>}
          </>
          :
          <>
            <p style={{ marginBottom: 12, fontSize: 14, color: '#333' }}>Your organisation has specified that passwords must be rotated on a periodic basis. You will need to set a new password before <b>{humanReadableDate}</b> in order to continue accessing Dagny.</p>
            {!passwordExpiry.currentPassword && <p style={{ marginBottom: 12, fontSize: 14, color: '#333' }}>To continue, first enter your current password to prove it is you:</p>}
          </>
        }
        {!passwordExpiry.currentPassword && (
          <label htmlFor="password">
            Current Password
            <input
              type="password"
              autoComplete="current-password"
              style={fieldStyles.input}
              placeholder="Enter your password"
              value={this.state.currentPassword}
              onChange={event => this.setState({ currentPassword: event.target.value })}
            />
          </label>
        )}
        {!passwordExpiry.currentPassword && (
          <p style={{ textAlign: 'center', marginBottom: 12, fontSize: 14 }}>
            If you have forgotton your password then you may <a href="#" style={{ color: colors.primary }} onClick={this.sendResetPasswordEmail}>request a password reset email</a> and reset your password
            by following the link in the email.
          </p>
        )}
        <div style={{ display: 'flex', justifyContent: passwordExpiry.passwordExpired ? 'flex-end' : 'space-between' }} className="space-children-12">
          {!passwordExpiry.passwordExpired && (
            <Button large fullWidth negative outline onClick={this.skipPasswordReset}>
              Skip
            </Button>
          )}
          <Button large fullWidth positive submit disabled={continueDisabled} onClick={this.validatePasswordAndContinue}>
            Continue
          </Button>
        </div>

        {!!(this.state.passwordResetError || this.state.passwordValidateError) && (
          <div style={{ ...styles.messageContainer, color: colors.red }}>
            <p>{this.state.passwordResetError ?? this.state.passwordValidateError}</p>
          </div>
        )}
      </AuthPage>
    );
  }
}

function mapStateToProps({ global }) {
  return {
    passwordExpiry: global.passwordExpiry,
  };
}

export default connect(mapStateToProps)(PasswordExpiryContainer);
