import React from 'react';
import colors from 'config/colors';
import PropTypes from 'prop-types';

export default function Loading(props) {

  const src = props.red ? '/images/loadingSpinnerRed.gif' : '/images/spinner-cavalry.gif';
  const spinner = <img src={src} alt="Loading" style={{ width: props.size, height: props.size, ...props.styles }} />;

  if (props.flex) {
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: props.minHeight,
          color: colors.text,
        }}
      >
        {spinner}
        {props.message ?? null}
      </div>
    );
  }

  return spinner;
}

const { number, string, bool, objectOf, oneOfType } = PropTypes;
Loading.propTypes = {
  size: number,
  styles: objectOf(oneOfType([
    string,
    number,
  ])),
  red: bool,
  flex: bool,
  message: string,
  minHeight: oneOfType([string, number]),
};

Loading.defaultProps = {
  size: 60,
  styles: null,
  red: false,
  flex: false,
  message: null,
  minHeight: 'auto',
};
