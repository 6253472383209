import React from 'react';
import propTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import types from '../../config/types';
import colors from '../../config/colors';

const styles = {
  warningBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.red,
    padding: 8,
    paddingLeft: 18,
    paddingRight: 18,
    fontSize: types.regular,
    boxShadow: '0 3px 6px rgba(50,50,93,.1)',
  },
  warningContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 1,
  },
};

const WarningBar = props => (
  <div style={styles.warningBox}>
    <div style={styles.warningContentContainer}>
      <MdWarning
        style={{ color: colors.white, fontSize: '32px', marginBottom: '2px', marginRight: '5px' }}
      />
      <p style={{ color: colors.white, fontSize: '16px' }}>{props.warning}</p>
    </div>
  </div>
);

const { string } = propTypes;
WarningBar.propTypes = {
  warning: string.isRequired,
};

export default WarningBar;
