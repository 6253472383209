import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Slider from 'react-slick';
import { MdFileUpload, MdPerson, MdArrowUpward } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import spacing from 'config/spacing';
import colors from 'config/colors';
import fonts from 'config/fonts';

import { browserOnBlur } from 'lib/browser-type';
import { submitSupplierCandidate } from 'lib/api/jobs';
import { customFilter, customStyles } from 'lib/helpers-select';
import { setShiftApplicationsAndBookings } from 'reducers/jobs';
import * as suppliersReducer from 'reducers/suppliers';
import * as suppliersThunks from 'thunks/suppliers';

import Modal from '../Modal';
import FormInput from '../FormInput';
import Tabs from '../Tabs';
import Button from '../Button';
import DataTable from '../DataTable';
import ScrollView from '../ScrollView';
import Wrapper from '../Wrapper';

import './CandidateUploadModal.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const modalContentStyles = {
  width: 600,
  overflow: 'display',
  paddingTop: 0,
  padding: 0,
  backgroundColor: colors.tealUltraLight,
};

const styles = {
  title: {
    paddingTop: spacing.xlarge,
    fontFamily: fonts.main.family,
    fontSize: fonts.large.size,
    color: colors.positive,
    textAlign: 'center',
  },
  selectContainer: {
    marginBottom: spacing.xlarge,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectLabel: {
    width: '30%',
    color: colors.understated,
    marginLeft: spacing.small,
  },
  select: {
    width: '74%',
  },
  columnsProfilePicContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  columnsProfilePicImg: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  uploadString: {
    textAlign: 'center',
    padding: 2,
    color: 'white',
  },
  uploadStringBox: {
    height: 30,
    borderRadius: 5,
    backgroundColor: colors.positive,
    marginBottom: spacing.base,
    display: 'flex',
  },
  uploadIcon: {
    verticalAlign: 'middle',
    marginBottom: 6,
    marginTop: 6,
    marginRight: 10,
    marginLeft: 10,
    color: 'white',
  },
};

const { bool, func, string, arrayOf, object, objectOf } = PropTypes;
class CandidateUploadModal extends Component {
  static propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    fetchAgencySubmittedCandidates: func.isRequired,
    orgKey: string.isRequired,
    shift: objectOf(object),
    agencySubmittedApplicants: objectOf(object),
    candidates: arrayOf(object),
    resetCandidateState: func.isRequired,
    supplierKey: string,
    rgsMetadata: objectOf(object).isRequired,
    agencySubmittedCandidates: arrayOf(object),
    roles: arrayOf(object).isRequired,
    jobId: string.isRequired,
  };

  static defaultProps = {
    shift: {},
    agencySubmittedApplicants: {},
    agencySubmittedCandidates: [],
    candidates: [],
    supplierKey: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      formFirstName: '',
      formSurname: '',
      formProfReg: '',
      formRef: '',
      formGrades: null,
      formSpecialities: null,
      formRole: null,
      formGrade: null,
      formSpeciality: null,
      candidate: null,
    };
  }

  async componentDidMount() {
    const { fetchAgencySubmittedCandidates, orgKey, shift, jobId } = this.props;
    await fetchAgencySubmittedCandidates(orgKey, shift.roleKey);
  }

  selectCandidate = async (candidate) => {
    await this.setState({ candidate });
  }


  closeCandidateUploadModal = () => {
    const { onClose, resetCandidateState } = this.props;
    this.setState({ candidateAlreadySubmitted: false });
    resetCandidateState();
    onClose();
  }

  updateFormFirstName = (e) => {
    this.setState({ formFirstName: e.target.value });
  }

  updateFormSurname = (e) => {
    this.setState({ formSurname: e.target.value });
  }

  updateFormProfReg = (e) => {
    this.setState({ formProfReg: e.target.value });
  }

  updateFormRef = (e) => {
    this.setState({ formRef: e.target.value });
  }

  rgsObjectToArray = (obj) => {
    const arr = [];
    Object.keys(obj).forEach((key) => {
      const label = obj[key].name ? obj[key].name : '';
      arr.push({ value: key, label });
    });
    return arr;
  }

  updateFormRole = (option) => {
    this.updateFormOptions(option);
    this.setState({ formRole: option });
  }

  updateFormGrade = (option) => {
    this.setState({ formGrade: option });
  }

  updateFormSpeciality = (option) => {
    this.setState({ formSpeciality: option });
  }

  deselectCandidate = () => {
    this.setState({ candidate: null, candidateAlreadySubmitted: false });
  }


  submitSupplierCandidate = async () => {
    const role = this.state.formRole && this.state.formRole.value && this.state.formRole.label ? {
      id: this.state.formRole.value,
      name: this.state.formRole.label,
    } : '';
    const grade = this.state.formGrade && this.state.formGrade.value && this.state.formGrade.label ? {
      [this.state.formGrade.value]: this.state.formGrade.label,
    } : '';
    const speciality = this.state.formSpeciality && this.state.formSpeciality.value && this.state.formSpeciality.label ? {
      [this.state.formSpeciality.value]: this.state.formSpeciality.label,
    } : '';

    const candidateData = {
      firstName: this.state.formFirstName || '',
      surname: this.state.formSurname || '',
      profReg: this.state.formProfReg || '',
      ref: this.state.formRef || '',
      role,
      jobId: this.props.jobId,
      grade,
      speciality,
      supplierKey: this.props.supplierKey,
      candidateId: uuidv4(),
    };
    const supplierKey = this.props.orgKey;
    const response = await submitSupplierCandidate(this.props.jobId, supplierKey, candidateData);
    this.props.setShiftApplicationsAndBookings(this.props.jobId, response.body.shiftAppsAndBookingsFromSql);
    this.props.onClose();
  }

  addSubmittedSupplierCandidate = async (data) => {
    const {
      agencySubmittedApplicants,
      jobId,
      shift,
    } = this.props;
    const applicantIds = [];
    if (agencySubmittedApplicants[jobId]) {
      (Object.values(agencySubmittedApplicants[jobId])).forEach((applicant) => {
        applicantIds.push(applicant.candidateId);
      });
    }
    this.setState({ submittingApplicant: true });
    const role = data && data.role ? data.role : {};
    const grade = data && data.grade ? data.grade : {};
    const speciality = data && data.speciality ? data.speciality : {};
    const { candidateId } = data;
    const orgKey = shift.orgKey;
    const supplierKey = data && data.supplierKey ? data.supplierKey : this.props.orgKey;
    const candidateData = {
      firstName: data.firstName || '',
      surname: data.surname || '',
      profReg: data.profReg || '',
      ref: data.ref || '',
      role,
      grade,
      speciality,
      jobId,
      supplierKey,
      orgKey,
      candidateId,
      previouslySubmitted: true,
    };
    if (applicantIds.includes(candidateId)) {
      this.setState({ candidateAlreadySubmitted: true, submittingApplicant: false });
    } else {
      await submitSupplierCandidate(jobId, supplierKey, candidateData);
      const response = await submitSupplierCandidate(jobId, supplierKey, candidateData);
      this.props.setShiftApplicationsAndBookings(jobId, response.body.shiftAppsAndBookingsFromSql);
      this.setState({ submittingApplicant: false });
      this.props.onClose();
    }
  }

  updateFormOptions(option) {
    const metadata = option?.value && this.props.rgsMetadata[option.value] ? this.props.rgsMetadata[option.value] : {};
    const formGrades = metadata.grades ? this.rgsObjectToArray(metadata.grades) : [];
    const formSpecialities = metadata.specialities ? this.rgsObjectToArray(metadata.specialities) : [];
    this.setState({ formGrades, formSpecialities });
  }

  render() {
    const filterConfig = {
      matchFrom: 'any',
    };

    const COLUMNS = [
      {
        name: 'name',
        icon: <MdPerson />,
        header: 'Name',
      },
      {
        name: 'role',
        header: 'Role',
        formatter: (role) => {
          return <div><p>{role.name}</p></div>;
        },
      },
      {
        name: 'grade',
        header: 'Grade',
        formatter: (grades) => {
          return <div><p>{grades[Object.keys(grades)[0]]}</p></div>;
        },
      },
      {
        name: 'speciality',
        header: 'Speciality',
        formatter: (spec) => {
          return <div><p>{spec[Object.keys(spec)[0]]}</p></div>;
        },
      },
    ];

    let slider = null;
    const candidateName = this.state.candidate && this.state.candidate.name ? this.state.candidate.name : '';
    const role = this.state.candidate && this.state.candidate.role ? this.state.candidate.role.name : '';
    const grade = this.state.candidate && this.state.candidate.grade ? Object.values(this.state.candidate.grade) : '';
    const speciality = this.state.candidate && this.state.candidate.speciality ? Object.values(this.state.candidate.speciality) : '';
    const tabs = [{
      tabTitle: 'Previously Submitted',
      renderTabContent: () => {
        return (
          <div>
            {this.props.candidates && this.props.candidates.length > 0 ?
              <Slider
                arrows={false}
                draggable={false}
                ref={(c) => { slider = c; }}
                easing="ease"
                speed={250}
              >
                <div>
                  <ScrollView
                    className="uploadModalscrollHeight"
                    hideScroll
                  >
                    <DataTable
                      columns={COLUMNS}
                      rows={this.props.candidates.filter(c => !this.props.agencySubmittedCandidates || !this.props.agencySubmittedCandidates.find(a => a.name === c.name))}
                      idColumn="userId"
                      onRowClick={(candidateId, candidate) => {
                        this.selectCandidate(candidate);
                        slider.slickNext();
                      }}
                    />
                  </ScrollView>
                </div>
                <div>
                  <Wrapper
                    onBack={() => {
                      this.deselectCandidate();
                      slider.slickPrev();
                    }}
                    noScroll
                    title="Confirm Applicant"
                    padding={spacing.base}
                  >
                    <div className="submitApplicant">
                      {this.state.candidate ?
                        <div className="submitApplicantBody">
                          <div className="candidate">
                            <div className="name">{candidateName}</div>
                          </div>
                        </div> : null}
                      <div className="submitApplicantHeading">Basic Information</div>
                      <div className="submitApplicantBody">
                        <div className="submitApplicantItem">
                          <div className="submitApplicantItemLabel">Role</div>
                          <div className="submitApplicantItemValue">{role}</div>
                        </div>
                        <div className="submitApplicantItem">
                          <div className="submitApplicantItemLabel">Grade</div>
                          <div className="submitApplicantItemValue">{grade}</div>
                        </div>
                        <div className="submitApplicantItem">
                          <div className="submitApplicantItemLabel">Speciality</div>
                          <div className="submitApplicantItemValue">{speciality}</div>
                        </div>
                        {this.state.candidateAlreadySubmitted ?
                          <div className="submitApplicantWarning">
                            <p>This candidate has already been submitted to this shift.</p>
                            <p>Please submit a different candidate or upload a new one.</p>
                          </div>
                          :
                          <div style={{ textAlign: 'right', marginTop: 15 }}>
                            <Button
                              onClick={() => this.addSubmittedSupplierCandidate(this.state.candidate)}
                              loading={this.state.submittingApplicant}
                            >
                              Submit
                            </Button>
                          </div>
                      }
                      </div>
                    </div>
                  </Wrapper>
                </div>
              </Slider>
              :
              <p>You currently don&#39;t have any candidates matching the required spec</p>
            }
          </div>
        );
      },
    }, {
      tabTitle: 'Upload Candidate',
      renderTabContent: () => {
        const { shift } = this.props;
        if (!this.state.formRole) {
          this.updateFormRole({ value: shift.roleKey, label: shift.roleName });
        }

        if (!this.state.formGrade) {
          this.updateFormGrade({ value: shift.gradeKey, label: shift.gradeName });
        }

        if (!this.state.formSpeciality) {
          this.updateFormSpeciality({ value: shift.specialityKey, label: shift.specialityName });
        }

        return (
          <div>
            <ScrollView
              containerStyle={{ height: '415px', padding: spacing.base }}
              className="uploadModalScrollView"
              hideScroll
            >
              <div style={styles.uploadStringBox}>
                <MdArrowUpward style={styles.uploadIcon} />
                <p style={styles.uploadString}>Please check the previously submitted tab before uploading a new candidate</p>
              </div>
              <FormInput
                label="First Name *"
                name="firstName"
                onChange={this.updateFormFirstName}
                placeholder=""
                type="text"
                value={this.state.formFirstName}
              />
              <FormInput
                label="Surname *"
                name="surname"
                onChange={this.updateFormSurname}
                placeholder=""
                type="text"
                value={this.state.formSurname}
              />
              <FormInput
                label="Professional Registration"
                name="profReg"
                onChange={this.updateFormProfReg}
                placeholder=""
                type="text"
                value={this.state.formProfReg}
              />
              <FormInput
                label="Your Ref"
                name="ref"
                onChange={this.updateFormRef}
                placeholder=""
                type="text"
                value={this.state.formRef}
              />
              <div style={styles.selectContainer}>
                <div style={styles.selectLabel}>Role</div>
                {this.props.roles && this.props.roles.length > 0 ?
                  <div style={styles.select}>
                    <Select
                      name="role"
                      placeholder=""
                      options={this.props.roles}
                      onChange={option => this.updateFormRole(option)}
                      value={this.state.formRole}
                      clearable={false}
                      styles={customStyles}
                      filterOption={customFilter(filterConfig)}
                      classNamePrefix="react-select"
                      onBlur={browserOnBlur()}
                    />
                  </div>
                  : null
                }
              </div>
              <div style={styles.selectContainer}>
                <div style={styles.selectLabel}>Grade</div>
                {this.state.formGrades && this.state.formGrades.length > 0 ?
                  <div style={styles.select}>
                    <Select
                      name="grade"
                      placeholder=""
                      options={this.state.formGrades}
                      onChange={option => this.updateFormGrade(option)}
                      value={this.state.formGrade}
                      clearable={false}
                      styles={customStyles}
                      filterOption={customFilter(filterConfig)}
                      classNamePrefix="react-select"
                      onBlur={browserOnBlur()}
                    />
                  </div>
                  : null
              }
              </div>
              <div style={styles.selectContainer}>
                <div style={styles.selectLabel}>Speciality</div>
                {this.state.formSpecialities && this.state.formSpecialities.length > 0 ?
                  <div style={styles.select}>
                    <Select
                      name="speciality"
                      placeholder=""
                      options={this.state.formSpecialities}
                      onChange={option => this.updateFormSpeciality(option)}
                      value={this.state.formSpeciality}
                      clearable={false}
                      styles={customStyles}
                      filterOption={customFilter(filterConfig)}
                      classNamePrefix="react-select"
                      onBlur={browserOnBlur()}
                    />
                  </div>
                  : null
              }
              </div>
            </ScrollView>
            <div style={{ textAlign: 'right', margin: spacing.base }}>
              <Button
                disabled={!(this.state.formFirstName && this.state.formSurname)}
                onClick={() => this.submitSupplierCandidate()}
              >
                <MdFileUpload style={{ color: colors.white, marginRight: spacing.base }} />
                Submit
              </Button>
            </div>
          </div>
        );
      },
    }];

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.closeCandidateUploadModal}
        onClose={this.props.onClose}
        contentLabel="Upload Candidate"
        contentStyle={modalContentStyles}
      >
        <div>
          <div style={styles.title}>Submit Candidate</div>
          <Tabs tabs={tabs} />
        </div>
      </Modal>
    );
  }
}

function mapStateToProps({ jobs, global, rgs, filter, suppliers }, ownProps) {
  const shift = jobs.details[ownProps.jobId];

  return {
    shift: shift?.details,
    rgsMetadata: rgs.rgsMetadata,
    roles: rgs.roles,
    candidates: suppliers.candidates || [],
    orgKey: global.employerAuthorisedOrgs ? Object.keys(global.employerAuthorisedOrgs)[0] : null,
    agencySubmittedApplicants: jobs.agencySubmittedApplicants,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetCandidateState: () => dispatch(suppliersReducer.resetCandidateState()),
    fetchAgencySubmittedCandidates: (supplierKey, jobRole) => dispatch(suppliersThunks.fetchAgencySubmittedCandidates(supplierKey, jobRole)),
    setShiftApplicationsAndBookings: (shiftKey, appsAndBookings) => dispatch(setShiftApplicationsAndBookings(shiftKey, appsAndBookings)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateUploadModal);
