import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextAreaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import Button from 'components/Button';
import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';
import { suspendCandidate as suspendCandidateAccount } from 'thunks/compliance';
import * as candidatePropTypes from 'models/candidate';

const { func, objectOf, oneOfType, object, string, number, bool } = PropTypes;

const styles = {
  textarea: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 4,
    padding: 6,
    resize: 'none',
    overflowX: 'scroll',
    overflowY: 'none',
    outline: 'none',
    minHeight: 100,
    width: '96%',
    fontSize: '0.9rem',
    color: colors.text,
  },
  cancel: {
    backgroundColor: colors.negative,
    marginRight: spacing.small,
    marginTop: spacing.base,
  },
  confirm: {
    marginLeft: spacing.small,
    marginTop: spacing.base,
  },
  message: {
    fontSize: 14,
    color: colors.warning,
    fontWeight: 500,
  },
  labelContainer: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
  },
  selectBox: {
    width: '100%',
  },
  labelText: {
    fontWeight: 600,
    color: colors.understated,
    fontSize: '0.9rem',
  },
  inputContainer: {
    marginTop: spacing.xxlarge,
    display: 'flex',
    flexDirection: 'column',
  },
  selectBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '90%',
    alignContent: 'center',
    marginBottom: spacing.xxlarge,
    marginLeft: '15px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '92%',
  },
};

class SuspendAccount extends Component {
  static propTypes = {
    suspendCandidate: func.isRequired,
    accountSuspended: bool,
    orgConfig: objectOf(
      oneOfType([
        string,
        object,
        number,
        bool,
      ]),
    ),
  }

  static defaultProps = {
    accountSuspended: false,
    orgConfig: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      showReasonRequiredMessage: false,
    };
  }

  requestSuspendCandidate = async () => {
    const { accountSuspended, suspendCandidate, candidateSuspensionReasonType } = this.props;
    const candidateSuspensionReasonTypeIsList = candidateSuspensionReasonType && candidateSuspensionReasonType.type === 'list';
    if (this.state.reason || accountSuspended) {
      await suspendCandidate(!accountSuspended, candidateSuspensionReasonTypeIsList ? this.state.reason.value : this.state.reason);
    } else {
      this.setState({ showReasonRequiredMessage: true });
    }
  }

  changeReason = (data) => {
    const reason = data.target ? data.target.value : '';
    this.setState({ reason });
  }

  setSuspensionSelectListReason = (reason) => {
    this.setState({ reason });
  }

  render() {
    const { accountSuspended, candidateSuspensionReasonType, name: candidateName } = this.props;
    const { reason, showReasonRequiredMessage } = this.state;
    const confirmString = candidateName ? `Click confirm to remove ${candidateName}'s suspension.` : 'Click confirm to reverse this candidate\'s suspension.';
    const candidateSuspensionReasonTypeIsList = candidateSuspensionReasonType && candidateSuspensionReasonType.type === 'list';
    return (
      <>
        {showReasonRequiredMessage ?
          <div>
            <p style={styles.message}>Please give reason for suspension</p>
          </div>
          : null
      }
        {accountSuspended ?
          <div style={{ marginTop: '10px' }}>
            <p style={{ fontSize: '0.9rem', color: colors.text }}>{confirmString}</p>
          </div>
          :
          <div>
            <div style={{ marginTop: '10px', fontSize: '0.9rem', color: colors.text }}>
              <p>Suspension can be reversed at any time by a user with the required permissions.</p>
            </div>
            <div style={styles.inputContainer}>
              <div style={styles.selectBoxContainer}>
                <div style={styles.labelContainer}>
                  <p style={styles.labelText}>Reason</p>
                </div>
                {candidateSuspensionReasonTypeIsList ?
                  <div style={styles.selectBox}>
                    <Select
                      name="label"
                      placeholder="Select Reason..."
                      value={this.state.reason}
                      options={(candidateSuspensionReasonType.options || []).map(option => ({ label: option, value: option }))}
                      onChange={this.setSuspensionSelectListReason}
                    />
                  </div>
                  :
                  <TextAreaAutosize
                    style={styles.textarea}
                    value={reason || ''}
                    onChange={val => this.changeReason(val)}
                    placeholder="Reason for suspension (required)"
                  />
          }
              </div>
            </div>
          </div>
      }
        <div style={styles.buttonContainer}>
          {accountSuspended ?
            <Button black shadow={false} onClick={() => this.requestSuspendCandidate()} style={{ ...styles.confirm }}>Confirm</Button>
            :
            <Button onClick={() => this.requestSuspendCandidate()} style={{ ...styles.confirm, backgroundColor: colors.cavalry.error }} disabled={!this.state.reason}>Suspend</Button>
    }
        </div>
      </>

    );
  }
}

function mapStateToProps({ global, candidate }) {
  const mainOrg = candidate.profile?.orgs?.[candidate.profile?.mainOrgKey];
  return {
    candidateSuspensionReasonType: global.orgConfig?.candidateSuspensionReasonType,
    candidateName: candidate.profile?.name,
    accountSuspended: mainOrg.accountSuspended,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    suspendCandidate: (suspend, reason) => dispatch(suspendCandidateAccount(ownProps.candidateKey, suspend, reason)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuspendAccount);
