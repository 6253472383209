import React, { CSSProperties } from 'react';
import { MdAccountCircle, MdAccessTime } from 'react-icons/md';
import { convertFromUtcToTimezone } from 'lib/helpers-time';

import colors from 'config/colors';

import './AuditTrail.scss';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles : Styles = {
  username: {
    fontSize: '0.9rem',
    color: '#999',
    display: 'inline-block',
    paddingLeft: 10,
  },
};

interface ItemsType {
  when: string,
  icon?: React.ReactElement,
  title?: string | null,
  message?: string,
  userName?: string,
}

interface AuditTrailProps {
  items: ItemsType[],
  orgTimezone?: string,
}

const AuditTrail : React.FC<AuditTrailProps> = (props: AuditTrailProps) => {
  return (
    <div className="cd-timeline-wrapper small">
      <section className="cd-timeline cd-container">
        {props.items.map(item => (
          <div key={item.when} className="cd-timeline-block">
            <MdAccountCircle color={colors.cavalry.backgroundLight2} className="cd-timeline-img" />
            <div className="cd-timeline-content space-children-6--bottom">
              <h2 style={{ fontSize: '1.2rem' }}>
                {item.icon}
                {item.title || 'Dagny'}
              </h2>
              {item.message && typeof item.message === 'string' ?
                <p style={{ fontSize: '0.9rem', lineHeight: 1.2, color: colors.text }}>
                  {item.message.split('\n').map((line, index) => {
                    const lineKey = index;
                    return (
                      <span
                        key={lineKey}
                        style={{
                          width: 600,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '0.9rem',
                          lineHeight: 1.5,
                        }}
                      >
                        {line}
                        <br />
                      </span>);
                  })}
                </p> :
                (item.message || null)}
              <span className="cd-date">
                <MdAccessTime />
                {convertFromUtcToTimezone(item.when, props.orgTimezone ?? 'Europe/London').format('HH:mm on D MMMM YYYY')}
              </span>
              {item.userName ?
                <span style={styles.username}>
                  {' '}
                  By
                  {' '}
                  {item.userName}
                </span>
                : null}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

AuditTrail.defaultProps = { orgTimezone: 'Europe/London' };

export default AuditTrail;
