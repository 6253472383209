import { useState } from 'react';

export const useFormFields = (initialState) => {
  const [fields, setValues] = useState(initialState);

  return {
    fields,
    handleFieldChange: ({ id, value }) => setValues(prevState => ({ ...prevState, [id]: value })),
  };
};
