import React, { CSSProperties } from 'react';

import { convertFromUtcToTimezone } from 'lib/helpers-time';

import Modal from 'components/Modal';
import Button from 'components/Button';

interface Styles {
  [key: string]: CSSProperties
}

const styles: Styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 12,
    borderTop: '1px solid #EAEAEA',
  },
  text: {
    color: '#666',
    lineHeight: 1.4,
  },
};

interface RejectedApplication {
  rejectedAt: string,
  rejectedBy: string,
  rejectedByName: string,
  publicRejectionReason: string | null,
  privateRejectionReason: string | null,
}

interface RejectedApplicationBookingModalProps {
  onClose: () => void,
  book: () => void,
  rejectedApplication: RejectedApplication,
  applicant: { candidateKey: string, candidateName: string },
  timezone: string,
}

export default function RejectedApplicationBookingModal(props: RejectedApplicationBookingModalProps): React.ReactElement {

  const { onClose, rejectedApplication: { rejectedAt, rejectedByName, publicRejectionReason, privateRejectionReason }, applicant, book } = props;
  const rejectedAtTz = convertFromUtcToTimezone(rejectedAt, props.timezone);

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      contentLabel="bookRejectedApplicantConfirm"
      header="Confirm Booking"
      contentStyle={{ width: 600 }}
    >
      <div style={{ padding: '12px 18px' }}>

        <p style={{ ...styles.text, marginBottom: 12, fontWeight: 700 }}>The application was rejected by {rejectedByName} on {rejectedAtTz.format('Do MMM')} at {rejectedAtTz.format('HH:mm')}</p>

        {publicRejectionReason && (
          <div style={{ marginBottom: 12 }}>
            <p style={styles.text}>The public reason given (displayed to staff member) was:</p>
            <p style={{ ...styles.text, fontWeight: 700 }}>{publicRejectionReason}</p>
          </div>
        )}

        {privateRejectionReason && (
          <div style={{ marginBottom: 12 }}>
            <p style={styles.text}>The private reason given (only displayed to other admins) was:</p>
            <p style={{ ...styles.text, fontWeight: 700 }}>{privateRejectionReason}</p>
          </div>
        )}

        <p style={{ ...styles.text, marginBottom: 12 }}>Do you still wish to book {applicant.candidateName}?</p>
      </div>

      <div className="space-children-12" style={styles.buttonContainer}>
        <Button onClick={onClose} outline>Cancel</Button>
        <Button danger onClick={() => book()}>Book</Button>
      </div>
    </Modal>
  );
}
