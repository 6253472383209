const UPLOAD_PROFILE_PIC = 'UPLOAD_PROFILE_PIC';
const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOADPROFILE_PIC_SUCCESS';
const UPLOAD_PROFILE_PIC_ERROR = 'UPLOAD_PROFILE_PIC_ERROR';
const DELETE_PROFILE_PIC = 'DELETE_PROFILE_PIC';
const DELETE_PROFILE_PIC_SUCCESS = 'DELETE_PROFILE_PIC_SUCCESS';
const DELETE_PROFILE_PIC_ERROR = 'DELETE_PROFILE_PIC_ERROR';
const CLEAR_SITE_IMAGE_DATA = 'CLEAR_SITE_IMAGE_DATA';
const UPDATE_PROFILE_PIC_UPLOAD_PROGRESS = 'UPDATE_PROFILE_PIC_UPLOAD_PROGRESS';

export function uploadProfilePic() {
  return {
    type: UPLOAD_PROFILE_PIC,
  };
}

export function uploadProfilePicSuccess(imageUrl, fileName) {
  return {
    type: UPLOAD_PROFILE_PIC_SUCCESS,
    imageUrl,
    fileName,
  };
}

export function uploadProfilePicError(error) {
  return {
    type: UPLOAD_PROFILE_PIC_ERROR,
    error,
  };
}

export function DeleteProfilePic() {
  return {
    type: DELETE_PROFILE_PIC,
  };
}

export function DeleteProfilePicSuccess() {
  return {
    type: DELETE_PROFILE_PIC_SUCCESS,
  };
}

export function DeleteProfilePicError(error) {
  return {
    type: DELETE_PROFILE_PIC_ERROR,
    error,
  };
}

export function clearSiteImageData() {
  return {
    type: CLEAR_SITE_IMAGE_DATA,
  };
}
export function updateProfilePicUploadProgress(percent) {
  return {
    type: UPDATE_PROFILE_PIC_UPLOAD_PROGRESS,
    percent,
  };
}

const initialState = {
  isUploadingProfilePic: false,
  error: null,
  imageUrl: 'https://firebasestorage.googleapis.com/v0/b/wildfire-dev-13be6.appspot.com/o/sitePhotos%2Fdefault.png?alt=media&token=2cef20b9-2a10-4389-bc70-74cef90ad980',
  fileName: null,
  isDeletingProfilePic: false,
  uploadProgress: 0,
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_PROFILE_PIC:
      return {
        ...state,
        isUploadingProfilePic: true,
      };
    case UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isUploadingProfilePic: false,
        imageUrl: action.imageUrl,
        fileName: action.fileName,
        error: null,
      };
    case UPLOAD_PROFILE_PIC_ERROR:
      return {
        ...state,
        isUploadingProfilePic: false,
        error: action.error,
      };
    case DELETE_PROFILE_PIC:
      return {
        ...state,
        isDeletingProfilePic: true,
      };
    case DELETE_PROFILE_PIC_ERROR:
      return {
        ...state,
        isDeletingProfilePic: false,
        error: action.error,
      };
    case DELETE_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isDeletingProfilePic: false,
        error: null,
        imageUrl: initialState.imageUrl,
        fileName: null,
      };
    case CLEAR_SITE_IMAGE_DATA:
      return {
        ...state,
        imageUrl: initialState.imageUrl,
        fileName: null,
      };
    case UPDATE_PROFILE_PIC_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.percent,
      };
    default:
      return state;
  }
}
