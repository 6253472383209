import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { hashPageScriptTags } from 'lib/update-checking';
import * as globalReducers from 'reducers/global';

// This is the interval of time to check the new version, every 5 minutes
const CHECK_INTERVAL = 1000 * 60 * 5;

// Explained below...
const getRootUniqueUrl = () => `/random-url-${encodeURIComponent(moment.utc().toISOString())}-${encodeURIComponent(Math.random())}`;
let timeout = null;

const { func, string } = PropTypes;

class VersionManager extends PureComponent {

  static propTypes = {
    currentVersionHash: string.isRequired,
    updateComplete: func,
    updateVersion: func,
  }

  static defaultProps = {
    updateVersion: null,
    updateComplete: null,
  }

  constructor(props) {
    super(props);
    this.checkNewVersion();
    this.checkLocalStorage();
  }

  checkLocalStorage = () => {
    const updateCompleted = JSON.parse(window.localStorage.getItem('updateComplete'));
    if (updateCompleted && this.props.updateComplete) {
      this.props.updateComplete();
    }
  }

  // This function HTTP GETS the root of the web app
  // with a random querystring (using current time and random int) and a cache-control
  // HTTP header set to no-cache. This forces HTTP proxies and the current browser to get
  // and end to end refresh. Also using a unique URL forces this too.
  // Our customers use microsoft proxies and internet explorer which do a LOT of caching, even when asked not to.
  // Thus using both methods.
  checkNewVersion = async () => {
    try {
      const response = await fetch(getRootUniqueUrl(), {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      if (response.status === 200) {
        const htmlBody = await response.text();
        const newVersionHash = hashPageScriptTags(htmlBody);

        if (newVersionHash !== this.props.currentVersionHash) {
          // window.alert('NEW VERSION AVAILABLE');
          // Update redux here instead of refreshing page, this code will need to run inside the context
          // of a react component that's been `connect(mapStateToProps)(ComponentName)`
          // then update redux and animate the show/hide of this bar inside the Page.js
          // component. Make sure the component in placed inside AppContainer but
          // not inside the Switch/Router component. Lifetime needs to be for entire session, not route dependant.
          if (this.props.updateVersion) this.props.updateVersion();
        } else {
          this.scheduleNext();
        }
      } else {
        this.scheduleNext();
      }
    } catch (e) {
      console.log(e);
      this.scheduleNext();
    }
  };

  scheduleNext = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(this.checkNewVersion, CHECK_INTERVAL);
  }

  // Start off the version checker.
  // Read the comments on line 186 to
  // understand where this codes needs to be moved to

  render() {
    return null;
  }
}


function mapStateToProps({ global }) {
  return {
    updateRequired: global.updateRequired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateComplete: () => dispatch(globalReducers.updateComplete()),
    updateVersion: () => dispatch(globalReducers.updateVersion()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionManager);
