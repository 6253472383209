import React, { CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { setShiftDetailsIsPinned } from 'reducers/userInterface';

import SidePanel from 'components/SidePanel';
import Loading from 'components/Loading';
import Button from 'components/Button';

import colors from 'config/colors';
import * as createShiftThunks from 'thunks/createShifts';
import * as templateThunks from 'thunks/templates';

import { useApplyTemplateFields } from './template-hooks';

import TemplateField from '../FormFields/TemplateField';
import TemplatePeriodField from '../FormFields/TemplatePeriodField';
import DateField from '../FormFields/DateField';

interface Styles {
  [key: string]: CSSProperties,
}

const styles: Styles = {
  centreContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  errorText: {
    color: colors.red,
    fontSize: 14,
    lineHeight: 1.4,
    marginBottom: 6,
  },
};

interface ApplyTemplateProps {
  closeSidePanel: () => void,
  selectedDate: moment.Moment,
  goToPeriodView: (date: moment.Moment, templateKey: string | undefined) => void,
  periodDuration: string,
  templateKey?: string,
}

export default function ApplyTemplate(props: ApplyTemplateProps) : React.ReactElement {

  const dispatch = useDispatch();
  const isFetchingTemplateList = useSelector((state) => state.templates.isFetchingTemplateList);
  const fetchTemplateListError = useSelector((state) => state.templates.fetchTemplateListError);
  const isFetchingTemplate = useSelector((state) => state.templates.isFetchingTemplate);
  const fetchTemplateError = useSelector((state) => state.templates.fetchTemplateError);
  const sidePanelIsPinned = useSelector((state) => state.userInterface.shiftDetailsIsPinned);

  const { isFetchingTemplatesMetadata, templateList, template, templatePeriod, date, timezone, onChangeTemplate, onChangeDate, isOutsideRange } = useApplyTemplateFields(props.templateKey, props.selectedDate, props.goToPeriodView, props.periodDuration);

  if (isFetchingTemplatesMetadata || isFetchingTemplateList || isFetchingTemplate) {
    return (
      <SidePanel
        vflex
        isOpen
        closeSidePanel={props.closeSidePanel}
        title="Apply Template"
        setIsPinned={(isPinned: boolean) => dispatch(setShiftDetailsIsPinned(isPinned))}
        isPinned={sidePanelIsPinned}
      >
        <div className="createShiftForm shiftDetailContentContainer" style={styles.centreContainer}>
          <Loading flex />
        </div>
      </SidePanel>
    );
  }

  if (fetchTemplateListError) {
    return (
      <SidePanel
        vflex
        isOpen
        closeSidePanel={props.closeSidePanel}
        title="Apply Template"
        setIsPinned={(isPinned: boolean) => dispatch(setShiftDetailsIsPinned(isPinned))}
        isPinned={sidePanelIsPinned}
      >
        <div className="createShiftForm shiftDetailContentContainer" style={{ ...styles.centreContainer, padding: 12 }}>
          <p style={styles.errorText}>{fetchTemplateListError}</p>
          <Button onClick={() => dispatch(templateThunks.fetchTemplateList())}>Retry</Button>
        </div>
      </SidePanel>
    );
  }

  const applyButtonDisabled = !template || isFetchingTemplate || isFetchingTemplateList || fetchTemplateError;

  return (
    <SidePanel
      vflex
      isOpen
      closeSidePanel={props.closeSidePanel}
      title="Apply Template"
      setIsPinned={(isPinned: boolean) => dispatch(setShiftDetailsIsPinned(isPinned))}
      isPinned={sidePanelIsPinned}
    >
      {templateList?.length === 0 ?
        <div style={styles.centreContainer}>
          <p style={{ color: colors.text }}>No templates available.</p>
        </div>
        :
        <div className="createShiftForm shiftDetailContentContainer" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

          <div className="shiftDetailsSubSection">
            <TemplateField template={template} onChange={onChangeTemplate} />
            <TemplatePeriodField templatePeriod={templatePeriod} />
            {date && <DateField date={date} onChange={onChangeDate} timezone={timezone} isOutsideRange={isOutsideRange} label="Apply To" />}
          </div>

          <div className="shiftDetailsSubSection space-children-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={applyButtonDisabled}
              onClick={() => { dispatch(createShiftThunks.applyTemplate()); props.closeSidePanel(); }}
              black
              shadow={false}
            >
              Apply Template
            </Button>
          </div>

          <div className="shiftDetailsSubSection">
            <p style={{ color: colors.text, lineHeight: 1.4, fontSize: '0.9rem' }}>
              Applying the template will add the shifts configured in the template to your calendar as draft shifts. You can then make any required changes (including booked staff) before publishing the shifts.
            </p>
          </div>

          {fetchTemplateError && (
            <div className="shiftDetailsSubSection" style={styles.centreContainer}>
              <p style={styles.errorText}>{fetchTemplateError}</p>
              {!!templateList?.length && (
                <Button
                  onClick={() => dispatch(templateThunks.fetchTemplate(templateList[0].key))}
                  outline
                >
                  Retry
                </Button>
              )}
            </div>
          )}
        </div>
    }
    </SidePanel>
  );
}

ApplyTemplate.defaultProps = { templateKey: undefined };
