import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MdFiberNew } from 'react-icons/md';
import ScrollView from '../ScrollView';
import { useMenuItems } from './menu-items';

import './styles.scss';

const styles = {
  newIcon: {
    display: 'inline-block',
    marginLeft: 5,
    opacity: '0.7',
    fontSize: '1.3em',
  },
};

const MainMenu = (props) => {

  const routes = useMenuItems();

  return (
    <ScrollView className="sideMenu" blueGrad hideScroll fadeAtEnd>
      <>
        {routes
          .map((route) => {

            const routeIsActive = window.location.pathname.startsWith(route.activeUrl || route.url);

            if (!props.isOpen) {
              return (
                <Fragment key={route.id}>
                  <Link to={route.url} className={routeIsActive ? 'sideMenuItem sideMenuItemSelected' : 'sideMenuItem'}> {route.icon}</Link>
                </Fragment>
              );
            }

            return (
              <Fragment key={route.id}>
                <Link to={route.url} className={routeIsActive ? 'sideMenuItem sideMenuItemSelected' : 'sideMenuItem'}>
                  <div style={{ textAlign: 'center', lineHeight: 1, paddingRight: 18 }}>
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      {route.icon}
                      {route.badgeCount > 0 ? <div className="sideMenuBadgeCount">{route.badgeCount}</div> : null}
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>{route.title}</div>
                </Link>
                {(props.expandAll || routeIsActive) && route.routes ?
                  route.routes
                    .map((innerRoute) => {
                      const innerRouteIsActive = window.location.pathname.startsWith(innerRoute.activeUrl || innerRoute.url);
                      return (
                        <Link key={innerRoute.id} to={innerRoute.url} className={innerRouteIsActive ? 'sideMenuInner sideMenuItem sideMenuItemSelected' : 'sideMenuInner sideMenuItem'} id={innerRoute.id}>
                          {innerRoute.title}
                          {innerRoute.new ? <span style={styles.newIcon}><MdFiberNew /></span> : null}
                        </Link>
                      );
                    })
                  : null
                }
              </Fragment>
            );
          })
        }
      </>
    </ScrollView>
  );
};

export default MainMenu;
