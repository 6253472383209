import React, { useState, CSSProperties, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import { firstBy } from 'thenby';

import SidePanel from 'components/SidePanel';
import Loading from 'components/Loading';
import Button from 'components/Button';
import DropDown from 'components/DropDown';
import SplitButtonBar from 'components/SplitButtonBar';
import DateAndTimePicker from 'components/DateAndTimePicker';
import EditCustomField from 'components/EditCustomField';

import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { isBeforeToday } from 'lib/helpers-date-picker';

import { useFeatureFlag } from 'hooks/feature';
import { useFormFields } from 'hooks/form';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { CustomFieldMetadata } from '../../../types/CustomFields';
import { PaymentRunType, PaymentRunDetailsType, PaymentRunFormFields } from '../../../types/PaymentRun';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  fieldLabel: {
    flexBasis: '30%', lineHeight: 1.4, color: '#999', fontWeight: 500, fontSize: '0.9rem', marginBottom: 6,
  },
  fieldValue: {
    flexBasis: '70%',
  },
  input: {
    flex: '1 1 0px',
    fontSize: 16,
    color: colors.textInput,
    border: '1px solid #CCC',
    borderRadius: 2,
    padding: '3px 6px',
    outline: 'none',
  },
  centreContainer: {
    display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
  },
  sidePanelContainer: { display: 'flex', padding: '12px', flex: 1, flexDirection: 'column', justifyContent: 'space-between', overflowY: 'auto' },
  headerText: { color: colors.text, fontWeight: 500, fontSize: '1.3rem', marginBottom: spacing.base },
  validationText: { fontSize: '0.9rem', fontWeight: 500, color: colors.cavalry.error, lineHeight: 1.4 },
};

const customSelectBoxStyles = {
  valueContainer: { fontSize: '14px' },
  option: { fontSize: '14px' },
};

const splitButtons = [
  { value: 'unscheduled', label: 'Unscheduled' },
  { value: 'scheduled', label: 'Scheduled' },
];

const compareFieldsAgainstSubmittedPaymentRun = (fields: PaymentRunFormFields, previousSubmission: PaymentRunDetailsType, paymentRunType: string) : boolean => {
  // If no previous submissions, return true (submit button enabled)
  if (!previousSubmission) return true;

  // Compare paymentRunTypeKey
  if (fields.paymentRunTypeKey !== previousSubmission.paymentRunTypeKey) return true;

  // Compare shiftStartTimeCutOff
  const previousShiftStartTimeCutOff = previousSubmission.cutOffTime;
  if (!moment.utc(fields.shiftStartTimeCutOff).isSame(moment.utc(previousShiftStartTimeCutOff), 'day')) return true;

  // Compare customFields
  const customFieldChanges = Object.entries(fields.customFields).some(([customFieldKey, customFieldValue]) => {
    return previousSubmission.settings[customFieldKey] && customFieldValue !== previousSubmission.settings[customFieldKey];
  });
  if (customFieldChanges) return true;

  // Compare scheduled times
  if (paymentRunType === 'scheduled' && !moment.utc(fields.scheduledSendTime).isSame(moment.utc(previousSubmission.scheduledSendTime).startOf('millisecond'))) return true;
  if (paymentRunType === 'scheduled' && fields.scheduledPaymentTime !== previousSubmission.scheduledPaymentTime && !moment.utc(fields.scheduledPaymentTime).isSame(moment.utc(previousSubmission.scheduledPaymentTime).startOf('millisecond'))) return true;
  if (paymentRunType === 'unscheduled' && previousSubmission.scheduledSendTime) return true;

  return false;
};

// SingleDatePicker defaults to 13:00, so we set the date to the correct time of day to compare against the scheduled time
const compareShiftCutOffAgainstScheduledRunDate = (cutOffDate: moment.Moment, scheduledSendTime: string, timezone: string, selectedScheduleTab: string) => {
  if (selectedScheduleTab === 'unscheduled') return false;
  const timezoneScheduledSendTime = convertFromUtcToTimezone(scheduledSendTime, timezone);

  const scheduledSendTimeHour = timezoneScheduledSendTime.hour();
  const scheduledSendTimeMinute = timezoneScheduledSendTime.minute();

  const newTimezoneDate = convertFromUtcToTimezone(cutOffDate.toISOString(), timezone);

  // Set new date and time
  const updatedCutOffDateAndTime = newTimezoneDate.hours(scheduledSendTimeHour).minutes(scheduledSendTimeMinute).seconds(0)
    .milliseconds(0);

  return moment.utc(updatedCutOffDateAndTime).isAfter(moment.utc(scheduledSendTime));
};

interface PaymentRunFormProps {
  onClose: () => void,
  submit: (fields: PaymentRunFormFields, scheduled: boolean) => void,
  apiError: string | null,
  isLoading: boolean,
  submittedPaymentRun?: PaymentRunDetailsType,
  title: 'Create Payment Run' | 'Edit Payment Run',
}

const PaymentRunForm : React.FC<PaymentRunFormProps> = (props : PaymentRunFormProps) => {

  const paymentRunTypes = useSelector(state => state.global.paymentRunTypes);
  const customFieldsMetadata = useSelector(state => state.global.customFieldsMetadata)?.filter((field: CustomFieldMetadata) => field.entity === 'pay-run');
  const adminCanViewPaymentRuns = useFeatureFlag(null, 'payRunCanView');
  const adminCanManagePaymentRuns = useFeatureFlag(null, 'payRunCanManage');
  const timezone = useSelector(state => state.global.orgConfig?.timezone ?? 'Europe/London');

  const manageablePaymentRunTypes = useMemo(() => paymentRunTypes.filter((runType: PaymentRunType) => runType.permissions.canManage), [paymentRunTypes]);
  // const paymentRunTypeOptions = useMemo(() => manageablePaymentRunTypes.map((runType: PaymentRunType) => { }), [paymentRunTypes]);

  let defaultShiftStartTimeCutOffTime = moment.tz(timezone).endOf('day');

  if (props.submittedPaymentRun?.cutOffTime && typeof props.submittedPaymentRun?.cutOffTime === 'string') {
    defaultShiftStartTimeCutOffTime = moment.utc(props.submittedPaymentRun?.cutOffTime);
  }

  const defaultScheduleTime = defaultShiftStartTimeCutOffTime.clone().startOf('day').add(1, 'days');
  const defaultScheduleSendTime = props.submittedPaymentRun?.scheduledSendTime ? moment.utc(props.submittedPaymentRun.scheduledSendTime) : defaultScheduleTime.clone();
  let defaultScheduledPaymentTime : moment.Moment | null = defaultScheduleSendTime.clone();

  // If submitted payment run included scheduledPaymentTime, then set as default.
  // If there is a submitted payment run, but no scheduledPaymentTime, then set to null
  if (props.submittedPaymentRun?.scheduledPaymentTime) defaultScheduledPaymentTime = moment.utc(props.submittedPaymentRun.scheduledPaymentTime);
  if (props.submittedPaymentRun && !props.submittedPaymentRun?.scheduledPaymentTime) defaultScheduledPaymentTime = null;

  const initalFormState : PaymentRunFormFields = {
    paymentRunTypeKey: props.submittedPaymentRun?.paymentRunTypeKey ?? manageablePaymentRunTypes[0].key,
    shiftStartTimeCutOff: defaultShiftStartTimeCutOffTime.toISOString(),
    scheduledSendTime: defaultScheduleSendTime.toISOString(),
    scheduledPaymentTime: defaultScheduledPaymentTime?.toISOString() ?? null,
    customFields: {},
  };

  if (customFieldsMetadata.length) {
    customFieldsMetadata.forEach((field: CustomFieldMetadata) => {
      const submittedCustomFieldValue = props.submittedPaymentRun?.settings?.[field.fieldKey];
      let defaultValue : string | string[] | null = field.parameters?.default ?? field.parameters?.options?.[0].value ?? null;
      if (field.type === 'multi-select' && !Array.isArray(defaultValue)) defaultValue = defaultValue ? [defaultValue] : [];
      initalFormState.customFields[field.fieldKey] = submittedCustomFieldValue ?? defaultValue;
    });
  }

  // Set to unscheduled if submitted payment run has no scheduledSendTime
  const defaultScheduleTab = props.submittedPaymentRun && !props.submittedPaymentRun?.scheduledSendTime && !props.submittedPaymentRun?.sentAt ? 'unscheduled' : splitButtons[0].value;

  const { fields, handleFieldChange } = useFormFields(initalFormState);
  const [selectedScheduleTab, setSelectedScheduleTab] = useState(defaultScheduleTab);

  useEffect(() => {
    if (selectedScheduleTab === 'scheduled' && fields.scheduledSendTime && fields.shiftStartTimeCutOff > fields.scheduledSendTime) {
      handleFieldChange({ id: 'scheduledSendTime', value: fields.shiftStartTimeCutOff });
    }
    if (fields.scheduledSendTime > fields.scheduledPaymentTime) handleFieldChange({ id: 'scheduledPaymentTime', value: fields.scheduledSendTime });
  }, [fields.shiftStartTimeCutOff, fields.scheduledSendTime, fields.scheduledPaymentTime, selectedScheduleTab]);

  let scheduleExplainerText;

  if (props.submittedPaymentRun?.sentAt) {
    scheduleExplainerText = 'The payment run has already run. You can update the \"To Be Paid On \" date.';
  } else if (selectedScheduleTab === 'scheduled') {
    scheduleExplainerText = 'The payment run will automatically run at the “Scheduled For” time below. You can also choose to manually run it at an alternative date after it has been scheduled.';
  } else {
    scheduleExplainerText = 'The payment run will not run automatically. You can run it manually by clicking the \"Run Now\" button from the details tab.';
  }

  // Validate form fields

  const noScheduledDateAndTime = selectedScheduleTab === 'scheduled' && !fields.scheduledSendTime;
  const scheduledTimeIsInPast = selectedScheduleTab === 'scheduled' && fields.scheduledSendTime && fields.scheduledSendTime < moment.utc().toISOString();
  const submissionHasChanged = props.submittedPaymentRun ? compareFieldsAgainstSubmittedPaymentRun(fields, props.submittedPaymentRun, selectedScheduleTab) : true;
  const submitButtonDisabled = noScheduledDateAndTime || scheduledTimeIsInPast || !submissionHasChanged;

  const submittedPaymentRunHasRun = !!props.submittedPaymentRun?.sentAt;

  // Render permissions message if admin does not have correct permissions
  if (!adminCanManagePaymentRuns || !adminCanViewPaymentRuns) {
    return (
      <SidePanel
        vflex
        isOpen
        title={props.title}
        closeSidePanel={props.onClose}
      >
        <div style={{ ...styles.centreContainer, color: colors.text, padding: spacing.base }}>
          You do not have the correct permissions to create a payment run.
        </div>
      </SidePanel>
    );
  }

  // Render loading spinner when creating/updating a payment run
  if (props.isLoading) {
    return (
      <SidePanel
        vflex
        isOpen
        title={props.title}
        closeSidePanel={props.onClose}
      >
        <div style={styles.centreContainer}>
          <Loading />
          <p style={{ color: colors.text }}>{`${props.submittedPaymentRun ? 'Updating' : 'Creating'} Payment Run...`}</p>
        </div>
      </SidePanel>
    );
  }

  return (
    <SidePanel
      vflex
      isOpen
      closeSidePanel={props.onClose}
      title={props.title}
    >
      <div style={styles.sidePanelContainer}>
        <div style={{ overflowY: 'auto', flex: 1 }}>

          <div style={{ marginBottom: 24 }}>
            <h4 style={styles.headerText}>Settings</h4>

            <div style={{ display: 'flex', alignItems: 'stretch', marginBottom: spacing.base, position: 'relative', flexDirection: 'column' }}>
              <p style={styles.fieldLabel}>Run Type</p>
              <div style={{ flex: 1 }}>
                <DropDown
                  defaultValue={manageablePaymentRunTypes.find((runType: PaymentRunType) => runType.key === fields.paymentRunTypeKey) ?? null}
                  getOptionValue={(runType: PaymentRunType) => runType.key}
                  getOptionLabel={(runType: PaymentRunType) => runType.name}
                  options={manageablePaymentRunTypes}
                  customStyles={customSelectBoxStyles}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  portalPlacement="bottom"
                  onChange={(newRunType: PaymentRunType) => handleFieldChange({ id: 'paymentRunTypeKey', value: newRunType.key })}
                />
              </div>
            </div>

            <PaymentRunDateAndTimeField
              date={fields.shiftStartTimeCutOff}
              additionalInformationText="Shifts that started at or before this time will be included"
              onChange={(value: string) => handleFieldChange({ id: 'shiftStartTimeCutOff', value })}
              label="Cutoff Time"
              defaultDate={defaultShiftStartTimeCutOffTime.toISOString()}
              timezone={timezone}
              disabled={false}
            />

            {customFieldsMetadata.sort(firstBy('sortOrder')).map((field: CustomFieldMetadata) => {
              return (
                <div key={field.fieldKey} style={{ display: 'flex', alignItems: 'center', marginBottom: spacing.base }}>
                  <p style={styles.fieldLabel}>{field.fieldName}</p>
                  <div style={styles.fieldValue}>
                    <EditCustomField
                      field={field}
                      onChange={(value: string | string[]) => handleFieldChange({ id: 'customFields', value: { ...fields.customFields, [field.fieldKey]: value } })}
                      value={fields.customFields[field.fieldKey]}
                      setDefaultValue
                      fieldIsValid
                      isDisabled={submittedPaymentRunHasRun}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <h4 style={styles.headerText}>Schedule</h4>

            {!submittedPaymentRunHasRun && (
              <div style={{ marginBottom: spacing.base }}>
                <SplitButtonBar onClick={(button) => setSelectedScheduleTab(button)} selectedButton={selectedScheduleTab} buttons={splitButtons} />
              </div>
            )}

            <p style={{ color: colors.text, lineHeight: 1.2, marginBottom: spacing.base, fontSize: '0.9rem' }}>{scheduleExplainerText}</p>

            {selectedScheduleTab === 'scheduled' && (
              <>
                <div style={{ marginBottom: spacing.base }}>
                  <PaymentRunDateAndTimeField
                    date={fields.scheduledSendTime}
                    clearValue={() => handleFieldChange({ id: 'scheduledSendTime', value: null })}
                    onChange={(value: string) => handleFieldChange({ id: 'scheduledSendTime', value })}
                    label="Send for payment at"
                    defaultDate={defaultScheduleTime.toISOString()}
                    timezone={timezone}
                    disabled={submittedPaymentRunHasRun}
                    isOutsideRange={(date: moment.Moment) => {
                      return date.isBefore(moment.max(moment().tz(timezone), convertFromUtcToTimezone(fields.shiftStartTimeCutOff, timezone)), 'day');
                    }}
                    startOfRange={fields.shiftStartTimeCutOff && convertFromUtcToTimezone(fields.shiftStartTimeCutOff, timezone).isSame(convertFromUtcToTimezone(fields.scheduledSendTime, timezone), 'day') ? fields.shiftStartTimeCutOff : null}
                  />
                </div>
                <div style={{ marginBottom: spacing.base }}>
                  <PaymentRunDateAndTimeField
                    date={fields.scheduledPaymentTime}
                    clearValue={() => handleFieldChange({ id: 'scheduledPaymentTime', value: null })}
                    onChange={(value: string) => handleFieldChange({ id: 'scheduledPaymentTime', value })}
                    label="Mark as paid at"
                    defaultDate={defaultScheduleTime.toISOString()}
                    timezone={timezone}
                    disabled={false}
                    isOutsideRange={(date) => moment.utc(date).isBefore(moment.utc(fields.scheduledSendTime))}
                    startOfRange={fields.scheduledSendTime}
                  />
                </div>

                {noScheduledDateAndTime && <p style={styles.validationText}>{'\"Schedule For\" field is required for a scheduled payment run.'}</p>}
                {scheduledTimeIsInPast && <p style={styles.validationText}>{'\"Schedule For\" field must not be in the past.'}</p>}
              </>
            )}
          </div>
        </div>

        <div>
          {props.apiError && <p style={{ color: colors.red, lineHeight: 1.4, marginBottom: spacing.base }}>{props.apiError}</p>}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => props.onClose()}
              style={{ width: '48%' }}
              white
              outline
              shadow={false}
            >
              Cancel
            </Button>
            <Button
              onClick={() => props.submit(fields, selectedScheduleTab === 'scheduled')}
              style={{ width: '48%' }}
              black
              shadow={false}
              disabled={submitButtonDisabled}
            >
              {(props.submittedPaymentRun ? 'Update Payment Run' : `${selectedScheduleTab === 'scheduled' ? 'Schedule' : 'Save'}`)}
            </Button>
          </div>
        </div>

      </div>
    </SidePanel>
  );
};

PaymentRunForm.defaultProps = { submittedPaymentRun: undefined };

interface PaymentRunDateFieldProps {
  onSelectDate: (date: string) => void,
  label: string,
  additionalInformationText?: string,
  date: moment.Moment,
  timezone: string,
  disabled: boolean
  isOutsideRange: (date: moment.Moment) => boolean,
}

const PaymentRunDateField : React.FC<PaymentRunDateFieldProps> = (props: PaymentRunDateFieldProps) => {

  const [datePickerFocused, setDatePickerFocused] = useState(false);

  const onSelectDate = (newDate: moment.Moment) => props.onSelectDate(convertFromUtcToTimezone(newDate.toISOString(), props.timezone).toISOString());

  return (
    <div style={{ marginBottom: spacing.base }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: spacing.tiny }}>
        <p style={styles.fieldLabel}>{props.label}</p>
        <div className="createShiftDatePicker" style={styles.fieldValue}>
          <SingleDatePicker
            displayFormat="ll"
            placeholder="Select Date"
            date={props.date}
            onDateChange={(date) => { if (date) onSelectDate(date); }}
            focused={datePickerFocused}
            onFocusChange={({ focused }) => setDatePickerFocused(focused)}
            openDirection="down"
            numberOfMonths={1}
            firstDayOfWeek={1}
            showDefaultInputIcon
            inputIconPosition="after"
            isOutsideRange={props.isOutsideRange}
            id="id"
            disabled={props.disabled}
          />
        </div>
      </div>
      {props.additionalInformationText && <p style={{ color: colors.text, lineHeight: 1.4 }}>{props.additionalInformationText}</p>}
    </div>
  );
};

PaymentRunDateField.defaultProps = { additionalInformationText: undefined };

interface PaymentRunDateAndTimeFieldProps {
  date: string | null,
  defaultDate: string,
  label: string,
  onChange: (date: string) => void,
  clearValue?: () => void,
  timezone: string,
  disabled: boolean,
  isOutsideRange?: (day: moment.Moment) => boolean
  startOfRange?: string
  endOfRange?: string
  additionalInformationText?: string,
  clearable?: boolean
}

const PaymentRunDateAndTimeField : React.FC<PaymentRunDateAndTimeFieldProps> = (props: PaymentRunDateAndTimeFieldProps) => {

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'stretch', marginBottom: spacing.base, position: 'relative', flexDirection: 'column' }}>
        <p style={styles.fieldLabel}>{props.label}</p>
        {props.additionalInformationText && <p style={{ color: colors.text, lineHeight: 1.4, fontSize: '0.9rem', marginBottom: 3 }}>{props.additionalInformationText}</p>}
        <DateAndTimePicker
          date={props.date}
          defaultDate={props.defaultDate}
          onChange={props.onChange}
          clearValue={props.clearValue}
          timezone={props.timezone}
          disabled={props.disabled}
          isOutsideRange={props.isOutsideRange ?? (() => false)}
          startOfRange={props.startOfRange}
          endOfRange={props.endOfRange}
          openDirection="down"
          clearable={props.clearable}
        />
      </div>
    </div>
  );
};

PaymentRunDateAndTimeField.defaultProps = { startOfRange: undefined, endOfRange: undefined, isOutsideRange: () => false, clearValue: undefined, clearable: undefined, additionalInformationText: undefined };

export default PaymentRunForm;
