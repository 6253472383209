const SET_TYPE = 'SET_TYPE';
const ADD_SELECTED_SITE = 'ADD_SELECTED_SITE';
const ADD_SELECTED_ORG = 'ADD_SELECTED_ORG';
const FETCH_POLICIES = 'FETCH_POLICIES';
const ADD_POLICIES = 'ADD_POLICIES';
const CLEAR_POLICIES = 'CLEAR_POLICIES';

export function addSelectedSite(siteKey) {
  return {
    type: ADD_SELECTED_SITE,
    siteKey,
  };
}

export function addSelectedOrg(orgKey) {
  return {
    type: ADD_SELECTED_ORG,
    orgKey,
  };
}

export function setType(policiesType) {
  return {
    type: SET_TYPE,
    policiesType,
  };
}

export function fetchPolicies() {
  return {
    type: FETCH_POLICIES,
  };
}

export function addPolicies(policies) {
  return {
    type: ADD_POLICIES,
    policies,
  };
}

export function clearPolicies() {
  return {
    type: CLEAR_POLICIES,
  };
}

const initialState = {
  policiesType: null,
  selectedSite: null,
  selectedOrg: null,
  data: {},
  isFetchingPolicies: false,
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case SET_TYPE:
      return {
        ...state,
        policiesType: action.policiesType,
      };
    case ADD_SELECTED_SITE:
      return {
        ...state,
        selectedSite: action.siteKey,
      };
    case ADD_SELECTED_ORG:
      return {
        ...state,
        selectedOrg: action.orgKey,
      };
    case FETCH_POLICIES:
      return {
        ...state,
        isFetchingPolicies: true,
      };
    case ADD_POLICIES:
      return {
        ...state,
        data: action.policies,
        isFetchingPolicies: false,
      };
    case CLEAR_POLICIES:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
}
