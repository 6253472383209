import { firstBy } from 'thenby';
import { Conversation, Message, Participant, SharedInboxMembership } from '../types/Messaging';

type FetchUnreadCountSuccess = { type: 'FETCH_UNREAD_COUNT_SUCCESS', count: number };

type FetchConversations = { type: 'FETCH_CONVERSATIONS' };
type FetchConversationsSuccess = { type: 'FETCH_CONVERSATIONS_SUCCESS', conversations: Conversation[] };
type MessageError = { type: 'MESSAGE_ERROR', error: string };

type FetchConversationMessages = { type: 'FETCH_CONVERSATION_MESSAGES' };
type FetchConversationMessagesSuccess = { type: 'FETCH_CONVERSATION_MESSAGES_SUCCESS', messages: Message[] };
type AddConversationMessage = { type: 'ADD_CONVERSATION_MESSAGE', message: Message };
type UpdateConversationMessage = { type: 'UPDATE_CONVERSATION_MESSAGE', messageKey: string, toUpdate: { error?: string | null, isSending: boolean } };
type RemoveConversationMessage = { type: 'REMOVE_CONVERSATION_MESSAGE', messageKey: string };

type FetchAvailableParticipants = { type: 'FETCH_AVAILABLE_PARTICIPANTS' };
type FetchAvailableParticipantsSuccess = { type: 'FETCH_AVAILABLE_PARTICIPANTS_SUCCESS', participants: Participant[] }
type FetchAvailableParticipantsError = { type: 'FETCH_AVAILABLE_PARTICIPANTS_ERROR', error: string };
type FetchConversationsMetadata = { type: 'FETCH_CONVERSATION_METADATA' };
type FetchConversationsMetadataSuccess = { type: 'FETCH_CONVERSATION_METADATA_SUCCESS', sharedInboxMembership: Array<SharedInboxMembership> };
type SetConversationsMetadataError = { type: 'SET_CONVERSATION_METADATA_ERROR', error: string };

export interface MessagingState {
  isFetchingConversations: boolean;
  isFetchingConversationsMetadata: boolean;
  conversations: Conversation[] | null;
  error: string | null;
  unreadConversationsCount: number;
  isFetchingConversationMessages: boolean;
  conversationMessages: Message[] | null;
  recentlySentMessages: Message[] | [];
  availableParticipants: Participant[] | [];
  isFetchingAvailableParticipants: boolean,
  errorFetchingAvailableParticipants: string | null;
  sharedInboxMembership: Array<SharedInboxMembership> | null
  fetchConversationMetadataError: string | null
}

type MessagingAction =
  | FetchUnreadCountSuccess
  | FetchConversationsSuccess
  | MessageError
  | FetchConversations
  | FetchConversationMessages
  | FetchConversationMessagesSuccess
  | AddConversationMessage
  | RemoveConversationMessage
  | UpdateConversationMessage
  | FetchAvailableParticipants
  | FetchAvailableParticipantsSuccess
  | FetchAvailableParticipantsError
  | FetchConversationsMetadata
  | FetchConversationsMetadataSuccess
  | SetConversationsMetadataError;

export const fetchUnreadCountSuccess = (count: number): FetchUnreadCountSuccess => ({ type: 'FETCH_UNREAD_COUNT_SUCCESS', count });

export const fetchConversations = (): FetchConversations => ({ type: 'FETCH_CONVERSATIONS' });
export const fetchConversationsSuccess = (conversations: Conversation[]): FetchConversationsSuccess => ({ type: 'FETCH_CONVERSATIONS_SUCCESS', conversations });
export const messageError = (error: string): MessageError => ({ type: 'MESSAGE_ERROR', error });

export const fetchConversationMessages = (): FetchConversationMessages => ({ type: 'FETCH_CONVERSATION_MESSAGES' });
export const fetchConversationMessagesSuccess = (messages: Message[]): FetchConversationMessagesSuccess => ({ type: 'FETCH_CONVERSATION_MESSAGES_SUCCESS', messages });
export const addConversationMessage = (message: Message): AddConversationMessage => ({ type: 'ADD_CONVERSATION_MESSAGE', message });
export const removeConversationMessage = (messageKey: string): RemoveConversationMessage => ({ type: 'REMOVE_CONVERSATION_MESSAGE', messageKey });
export const updateConversationMessage = (messageKey: string, toUpdate: { error?: string | null, isSending: boolean }): UpdateConversationMessage => ({ type: 'UPDATE_CONVERSATION_MESSAGE', messageKey, toUpdate });

export const fetchAvailableParticipants = (): FetchAvailableParticipants => ({ type: 'FETCH_AVAILABLE_PARTICIPANTS' });
export const fetchAvailableParticipantsSuccess = (participants: Participant[]): FetchAvailableParticipantsSuccess => ({ type: 'FETCH_AVAILABLE_PARTICIPANTS_SUCCESS', participants });
export const fetchAvailableParticipantsError = (error: string): FetchAvailableParticipantsError => ({ type: 'FETCH_AVAILABLE_PARTICIPANTS_ERROR', error });
export const fetchConversationsMetadata = (): FetchConversationsMetadata => ({ type: 'FETCH_CONVERSATION_METADATA' });
export const fetchConversationsMetadataSuccess = (sharedInboxMembership: Array<SharedInboxMembership>): FetchConversationsMetadataSuccess => ({ type: 'FETCH_CONVERSATION_METADATA_SUCCESS', sharedInboxMembership });
export const setConversationsMetadataError = (error: string): SetConversationsMetadataError => ({ type: 'SET_CONVERSATION_METADATA_ERROR', error });

const initialState: MessagingState = {
  isFetchingConversations: false,
  conversations: null,
  error: null,
  unreadConversationsCount: 0,
  isFetchingConversationMessages: false,
  conversationMessages: null,
  recentlySentMessages: [],
  availableParticipants: [],
  isFetchingAvailableParticipants: false,
  errorFetchingAvailableParticipants: null,
  isFetchingConversationsMetadata: false,
  sharedInboxMembership: null,
  fetchConversationMetadataError: null,
};

export function messaging(state = initialState, action: MessagingAction): MessagingState {
  switch (action.type) {

    case 'FETCH_UNREAD_COUNT_SUCCESS':
      return { ...state, unreadConversationsCount: action.count };

    case 'FETCH_CONVERSATIONS':
      return { ...state, isFetchingConversations: true };
    case 'FETCH_CONVERSATIONS_SUCCESS': {
      const sortedConversations = action.conversations.sort(firstBy('latestMessageSentAt', 'desc'));

      return {
        ...state,
        isFetchingConversations: false,
        conversations: sortedConversations,
        error: null,
      };
    }
    case 'MESSAGE_ERROR':
      return {
        ...state,
        isFetchingConversations: false,
        error: action.error,
        isFetchingConversationMessages: false,
      };
    case 'FETCH_CONVERSATION_MESSAGES':
      return {
        ...state,
        isFetchingConversationMessages: true,
      };
    case 'FETCH_CONVERSATION_MESSAGES_SUCCESS': {
      const newRecentlySentMessages = state.recentlySentMessages.filter((message) => {
        const messageSent = action.messages.find(({ key }) => key === message.key);

        return !messageSent;
      });

      return {
        ...state,
        conversationMessages: action.messages,
        isFetchingConversationMessages: false,
        recentlySentMessages: newRecentlySentMessages,
        error: null,
      };
    }
    case 'ADD_CONVERSATION_MESSAGE': {
      const recentlySentMessages = [...state.recentlySentMessages, action.message];

      return {
        ...state,
        recentlySentMessages,
      };
    }
    case 'REMOVE_CONVERSATION_MESSAGE': {
      const recentlySentMessages = state.recentlySentMessages.filter(({ key }) => key !== action.messageKey);

      return {
        ...state,
        recentlySentMessages,
      };
    }
    case 'UPDATE_CONVERSATION_MESSAGE': {
      const toUpdateIndex = state.recentlySentMessages.findIndex(({ key }) => key === action.messageKey);
      const updatedMessage = { ...state.recentlySentMessages[toUpdateIndex], ...action.toUpdate };
      const recentlySentMessages = state.recentlySentMessages.filter((_, index) => index !== toUpdateIndex);

      return {
        ...state,
        recentlySentMessages: [...recentlySentMessages, updatedMessage],
      };
    }
    case 'FETCH_AVAILABLE_PARTICIPANTS':
      return {
        ...state,
        isFetchingAvailableParticipants: true,
      };
    case 'FETCH_AVAILABLE_PARTICIPANTS_SUCCESS':
      return {
        ...state,
        isFetchingAvailableParticipants: false,
        availableParticipants: action.participants,
        errorFetchingAvailableParticipants: null,
      };
    case 'FETCH_AVAILABLE_PARTICIPANTS_ERROR':
      return {
        ...state,
        isFetchingAvailableParticipants: false,
        errorFetchingAvailableParticipants: action.error,
      };
    case 'FETCH_CONVERSATION_METADATA':
      return {
        ...state,
        isFetchingConversationsMetadata: true,
        fetchConversationMetadataError: null,
      };
    case 'FETCH_CONVERSATION_METADATA_SUCCESS':
      return {
        ...state,
        isFetchingConversationsMetadata: false,
        sharedInboxMembership: action.sharedInboxMembership,
      };
    case 'SET_CONVERSATION_METADATA_ERROR':
      return {
        ...state,
        isFetchingConversationsMetadata: false,
        fetchConversationMetadataError: action.error,
      };
    default:
      return state;
  }
}
