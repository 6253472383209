import { reconnect } from 'lib/api/apollo';
import { mapValues, uniqBy, keyBy } from 'lodash-es';

import { fetchOrgMetadata, fetchShiftCreationMetadata } from 'lib/api/accounts';
import { watchConversationUnreadCount } from 'thunks/messaging';
import * as notificationThunks from 'thunks/notifications';
import { setUser } from 'lib/tracking';

import {
  addGlobalData,
  clearGlobalData,
  addMetadata,
  addOrgConfig,
  addSectorKey,
  addCurrentOrgKey,
  setPasswordExpiry,
  addOrgMetadata,
  addShiftCreationMetadata,
  setIsFetchingShiftCreationMetadata,
  setFetchShiftCreationMetadataError,
} from 'reducers/global';
import * as userReducer from 'reducers/user';

import * as rgsThunks from './rgs';
import { stripDisabledFilterValues } from './filter';

export function getOrgSectorKey() {
  return async (dispatch, getState) => {
    const global = getState().global;
    return global.currentOrgKey && global?.employerAuthorisedOrgs[global.currentOrgKey]?.sectorKey;
  };
}

let conversationSubscription;

export function startWatchingGlobalData(userKey) {
  return async (dispatch, getState) => {

    const hasSkippedPasswordReset = !!getState().global.hasSkippedPasswordReset;

    // Fetch metadata
    const {
      user,
      employerAuthorisedOrgs,
      orgKey,
      orgConfig,
      orgFeatureFlags,
      rgsConfig,
      orgSites,
      orgAdminGroups,
      adminAdminGroups,
      globalConfig,
      suppliers,
      banks,
      rateCards,
      customFields,
      shiftReasons,
      services,
      passwordExpiresAt,
      passwordExpired,
      passwordExpiringSoon,
      paymentRunTypes,
    } = await fetchOrgMetadata();

    if (user) setUser(false, user.key, user.email, user.display);

    // If password has expired or expiring soon, set root to password expired view. Only set candidate key and email. Do not set metadata.
    if (passwordExpiresAt && (passwordExpired || (passwordExpiringSoon))) {
      dispatch(setPasswordExpiry({ passwordExpiresAt, passwordExpiringSoon, passwordExpired }));
      dispatch(addGlobalData('globalConfig', globalConfig ?? { maintenanceMode: false }));
    }

    if (!passwordExpired) {
      dispatch({ type: 'LOGIN', id: userKey });
      dispatch(addGlobalData('globalConfig', globalConfig ?? { maintenanceMode: false }));
      dispatch(addGlobalData('employerAuthorisedOrgs', employerAuthorisedOrgs));
      dispatch(addCurrentOrgKey(orgKey));
      reconnect(); // Force Apollo websocket to reconnect in order to pickup new auth token and orgKey
      dispatch(addOrgMetadata(orgAdminGroups, suppliers, banks, rateCards, customFields, shiftReasons, services, paymentRunTypes));
      dispatch(userReducer.addEmployerData(user));
      dispatch(userReducer.addAdminGroups(adminAdminGroups));
      dispatch(userReducer.addAdminSites(orgSites));
      dispatch(addOrgConfig({ ...orgConfig, features: orgFeatureFlags }));
      dispatch(setMetadata(rgsConfig));
      dispatch(rgsThunks.fetchRGSMetadata());
      dispatch(userReducer.fetchUserSuccess(userKey));
      dispatch(stripDisabledFilterValues());
      conversationSubscription = await dispatch(watchConversationUnreadCount());
      dispatch(notificationThunks.watchNotifications());
    }

  };
}


function setMetadata(rgsConfig) {
  return (dispatch) => {
    const roles = mapValues(rgsConfig, role => {
      const { grades, specialities, ...rest } = role;
      return { ...rest, linkedGrades: grades, linkedSpecialities: specialities };
    });
    const specialities = keyBy(uniqBy(Object.values(rgsConfig).flatMap(role => Object.values(role.specialities)), spec => spec.key), spec => spec.key);
    const grades = keyBy(uniqBy(Object.values(rgsConfig).flatMap(role => Object.values(role.grades)), grade => grade.key), grade => grade.key);
    const sectors = keyBy(uniqBy(Object.values(rgsConfig).map(role => ({ key: role.sectorKey, name: role.sectorName })), sector => sector.key), sector => sector.key);

    dispatch(addMetadata({ sectors, roles, grades, specialities }));
  };
}

export function stopWatchingGlobalData() {
  return (dispatch) => {
    if (conversationSubscription) conversationSubscription.unsubscribe();
    dispatch(clearGlobalData());
    dispatch(notificationThunks.stopWatchingNotifications());
  };
}

export function fetchShiftCreateMetadata() {
  return async (dispatch) => {
    try {
      dispatch(setIsFetchingShiftCreationMetadata());
      const response = await fetchShiftCreationMetadata();

      if (response.success && response.rateCards && response.shiftReasons && response.costCentres) {
        dispatch(addShiftCreationMetadata(response.rateCards, response.shiftReasons, response.costCentres));
      } else {
        dispatch(setFetchShiftCreationMetadataError(response.humanReadableErrorMessage ?? 'Failed to fetch shift creation metadata. If the problem persists, please contact customer support.'));
      }
    } catch (error) {
      dispatch(setFetchShiftCreationMetadataError(error.humanReadableErrorMessage ?? 'Failed to fetch shift creation metadata. If the problem persists, please contact customer support.'));
    }
  };
}
