import { uniq } from 'lodash-es';
import { Template as TemplateListTemplate } from 'types/ShiftTypes';

interface TemplateDetails {
  key: string,
  name: string,
  periodType: 'day' | 'week' | 'month',
}

type CreateTemplate = { type: 'CREATE_TEMPLATE' };
type CreateTemplateSuccess = { type: 'CREATE_TEMPLATE_SUCCESS' };
type CreateTemplateError = { type: 'CREATE_TEMPLATE_ERROR', error: string };
type ClearCreateTemplateError = { type: 'CLEAR_CREATE_TEMPLATE_ERROR' };

type SaveTemplate = { type: 'SAVE_TEMPLATE' };
type SaveTemplateSuccess = { type: 'SAVE_TEMPLATE_SUCCESS', template: TemplateDetails };
type SaveTemplateError = { type: 'SAVE_TEMPLATE_ERROR', error: string };
type TemplateDisabledSitesError = { type: 'TEMPLATE_DISABLED_SITES_ERROR', error: string };
type ValidationError = { type: 'VALIDATION_ERROR', error: string };
type ClearValidationError = { type: 'CLEAR_VALIDATION_ERROR' };

type SetTemplateName = { type: 'SET_TEMPLATE_NAME', name: string }
type SetTemplateEdited = { type: 'SET_TEMPLATE_EDITED' }

type DeleteTemplate = { type: 'DELETE_TEMPLATE' }
type DeleteTemplateSuccess = { type: 'DELETE_TEMPLATE_SUCCESS' }
type DeleteTemplateError = { type: 'DELETE_TEMPLATE_ERROR', error: string }

type FetchTemplate = { type: 'FETCH_TEMPLATE' };
type FetchTemplateSuccess = { type: 'FETCH_TEMPLATE_SUCCESS', template: TemplateDetails };
type FetchTemplateError = { type: 'FETCH_TEMPLATE_ERROR', error: string };
type ClearTemplate = { type: 'CLEAR_TEMPLATE' };

type FetchTemplateList = { type: 'FETCH_TEMPLATE_LIST' };
type FetchTemplateListSuccess = { type: 'FETCH_TEMPLATE_LIST_SUCCESS', templateList: TemplateListTemplate[] };
type FetchTemplateListError = { type: 'FETCH_TEMPLATE_LIST_ERROR', error: string };
type ClearTemplatesListError = { type: 'CLEAR_TEMPLATE_LIST_ERROR' };

type SetShiftIsSelected = { type: 'SET_SHIFT_IS_SELECTED', shiftKey: string, isSelected: boolean }
type SelectSpecifiedShifts = { type: 'SELECT_SPECIFIED_SHIFTS', shiftKeys: string[] }
type UnselectSpecifiedShifts = { type: 'UNSELECT_SPECIFIED_SHIFTS', shiftKeys: string[] }
type UnselectAllShifts = { type: 'UNSELECT_ALL_SHIFTS' }

export interface TemplatesState {
  isCreatingTemplate: boolean,
  isSavingTemplate: boolean,
  isFetchingTemplate: boolean
  isFetchingTemplateList: boolean,
  isDeletingTemplate: boolean,
  templateEdited: boolean,
  template: TemplateDetails | null,
  templateList: TemplateListTemplate[] | null
  createTemplateError: string | null,
  fetchTemplateError: string | null,
  saveTemplateError: string | null,
  validationError: string | null,
  templateDisabledSitesError: string | null,
  fetchTemplateListError: string | null,
  deleteTemplateError: string | null,
  selectedShiftKeys: string[],
}

type TemplatesAction = CreateTemplate | CreateTemplateError | CreateTemplateSuccess | FetchTemplate | FetchTemplateSuccess | FetchTemplateError | SetShiftIsSelected | SelectSpecifiedShifts | UnselectSpecifiedShifts | UnselectAllShifts | FetchTemplateList | FetchTemplateListSuccess | FetchTemplateListError | ClearTemplate | SetTemplateName | SaveTemplate | SaveTemplateSuccess | SaveTemplateError | ClearCreateTemplateError | ClearTemplatesListError | TemplateDisabledSitesError | DeleteTemplate | DeleteTemplateError | DeleteTemplateSuccess | SetTemplateEdited | ValidationError | ClearValidationError;

export const createTemplate = (): CreateTemplate => ({ type: 'CREATE_TEMPLATE' });
export const createTemplateSuccess = (): CreateTemplateSuccess => ({ type: 'CREATE_TEMPLATE_SUCCESS' });


export const saveTemplate = (): SaveTemplate => ({ type: 'SAVE_TEMPLATE' });
export const saveTemplateSuccess = (template: TemplateDetails): SaveTemplateSuccess => ({ type: 'SAVE_TEMPLATE_SUCCESS', template });


export const deleteTemplate = (): DeleteTemplate => ({ type: 'DELETE_TEMPLATE' });
export const deleteTemplateSuccess = (): DeleteTemplateSuccess => ({ type: 'DELETE_TEMPLATE_SUCCESS' });


export const setTemplateName = (name: string): SetTemplateName => ({ type: 'SET_TEMPLATE_NAME', name });

export const setTemplateEdited = (): SetTemplateEdited => ({ type: 'SET_TEMPLATE_EDITED' });

export const fetchTemplate = (): FetchTemplate => ({ type: 'FETCH_TEMPLATE' });
export const fetchTemplateSuccess = (template: TemplateDetails): FetchTemplateSuccess => ({ type: 'FETCH_TEMPLATE_SUCCESS', template });

export const clearTemplate = (): ClearTemplate => ({ type: 'CLEAR_TEMPLATE' });

export const fetchTemplateList = (): FetchTemplateList => ({ type: 'FETCH_TEMPLATE_LIST' });
export const fetchTemplateListSuccess = (templateList: TemplateListTemplate[]): FetchTemplateListSuccess => ({ type: 'FETCH_TEMPLATE_LIST_SUCCESS', templateList });
export const clearTemplatesListError = (): ClearTemplatesListError => ({ type: 'CLEAR_TEMPLATE_LIST_ERROR' });

export const setShiftIsSelected = (shiftKey: string, isSelected: boolean): SetShiftIsSelected => ({ type: 'SET_SHIFT_IS_SELECTED', shiftKey, isSelected });
export const selectSpecifiedShifts = (shiftKeys: string[]): SelectSpecifiedShifts => ({ type: 'SELECT_SPECIFIED_SHIFTS', shiftKeys });
export const unselectSpecifiedShifts = (shiftKeys: string[]): UnselectSpecifiedShifts => ({ type: 'UNSELECT_SPECIFIED_SHIFTS', shiftKeys });
export const unselectAllShifts = (): UnselectAllShifts => ({ type: 'UNSELECT_ALL_SHIFTS' });

// Error actions
export const createTemplateError = (error: string): CreateTemplateError => ({ type: 'CREATE_TEMPLATE_ERROR', error });
export const clearCreateTemplateError = (): ClearCreateTemplateError => ({ type: 'CLEAR_CREATE_TEMPLATE_ERROR' });
export const saveTemplateError = (error: string): SaveTemplateError => ({ type: 'SAVE_TEMPLATE_ERROR', error });
export const deleteTemplateError = (error: string): DeleteTemplateError => ({ type: 'DELETE_TEMPLATE_ERROR', error });
export const fetchTemplateError = (error: string): FetchTemplateError => ({ type: 'FETCH_TEMPLATE_ERROR', error });
export const templateIncludesDisabledSitesError = (error: string): TemplateDisabledSitesError => ({ type: 'TEMPLATE_DISABLED_SITES_ERROR', error });
export const fetchTemplateListError = (error: string): FetchTemplateListError => ({ type: 'FETCH_TEMPLATE_LIST_ERROR', error });
export const validationError = (error: string): ValidationError => ({ type: 'VALIDATION_ERROR', error });
export const clearValidationError = (): ClearValidationError => ({ type: 'CLEAR_VALIDATION_ERROR' });

const initialState: TemplatesState = {
  isCreatingTemplate: false,
  isFetchingTemplate: false,
  isSavingTemplate: false,
  isDeletingTemplate: false,
  deleteTemplateError: null,
  isFetchingTemplateList: false,
  templateList: null,
  template: null,
  createTemplateError: null,
  saveTemplateError: null,
  fetchTemplateError: null,
  fetchTemplateListError: null,
  templateDisabledSitesError: null,
  validationError: null,
  templateEdited: false,
  selectedShiftKeys: [],
};

export default function templates(state = initialState, action: TemplatesAction): TemplatesState {
  switch (action.type) {

    case 'CREATE_TEMPLATE': return { ...state, isCreatingTemplate: true, createTemplateError: null, validationError: null };
    case 'CREATE_TEMPLATE_SUCCESS': return { ...state, isCreatingTemplate: false };
    case 'CREATE_TEMPLATE_ERROR': return { ...state, isCreatingTemplate: false, createTemplateError: action.error };
    case 'CLEAR_CREATE_TEMPLATE_ERROR': return { ...state, isCreatingTemplate: false, createTemplateError: null };

    case 'SET_TEMPLATE_NAME': {
      const updatedState = { ...state, templateEdited: true };
      if (state.template) updatedState.template = { ...state.template, name: action.name };
      return updatedState;
    }

    case 'SET_TEMPLATE_EDITED': return { ...state, templateEdited: true };

    case 'DELETE_TEMPLATE': return { ...state, isDeletingTemplate: true, deleteTemplateError: null };
    case 'DELETE_TEMPLATE_SUCCESS': return { ...state, isDeletingTemplate: false };
    case 'DELETE_TEMPLATE_ERROR': return { ...state, isDeletingTemplate: false, deleteTemplateError: action.error };

    case 'SAVE_TEMPLATE': return { ...state, isSavingTemplate: true, saveTemplateError: null, validationError: null };
    case 'SAVE_TEMPLATE_SUCCESS': return { ...state, isSavingTemplate: false, template: action.template, templateEdited: false };
    case 'SAVE_TEMPLATE_ERROR': return { ...state, isSavingTemplate: false, saveTemplateError: action.error };

    case 'FETCH_TEMPLATE': return { ...state, isFetchingTemplate: true, fetchTemplateError: null };
    case 'FETCH_TEMPLATE_SUCCESS': return { ...state, isFetchingTemplate: false, template: action.template };
    case 'FETCH_TEMPLATE_ERROR': return { ...state, isFetchingTemplate: false, fetchTemplateError: action.error };
    case 'TEMPLATE_DISABLED_SITES_ERROR': return { ...state, isFetchingTemplate: false, templateDisabledSitesError: action.error };

    case 'CLEAR_TEMPLATE': return { ...state, template: null, saveTemplateError: null, fetchTemplateError: null, isDeletingTemplate: false, templateEdited: false, isSavingTemplate: false };

    case 'FETCH_TEMPLATE_LIST': return { ...state, isFetchingTemplateList: true, fetchTemplateListError: null };
    case 'FETCH_TEMPLATE_LIST_SUCCESS': return { ...state, isFetchingTemplateList: false, templateList: action.templateList };
    case 'FETCH_TEMPLATE_LIST_ERROR': return { ...state, isFetchingTemplateList: false, fetchTemplateListError: action.error };
    case 'CLEAR_TEMPLATE_LIST_ERROR': return { ...state, fetchTemplateListError: null };
    case 'VALIDATION_ERROR': return { ...state, isSavingTemplate: false, isCreatingTemplate: false, validationError: action.error };
    case 'CLEAR_VALIDATION_ERROR': return { ...state, validationError: null };

    case 'SET_SHIFT_IS_SELECTED': {
      const isCurrentlySelected = state.selectedShiftKeys.includes(action.shiftKey);
      if (action.isSelected && !isCurrentlySelected) {
        return { ...state, selectedShiftKeys: [...state.selectedShiftKeys, action.shiftKey] };
      }
      if (!action.isSelected && isCurrentlySelected) {
        return { ...state, selectedShiftKeys: state.selectedShiftKeys.filter(shiftKey => shiftKey !== action.shiftKey) };
      }
      return state;
    }
    case 'UNSELECT_ALL_SHIFTS':
      return { ...state, selectedShiftKeys: [] };
    case 'UNSELECT_SPECIFIED_SHIFTS': {
      return { ...state, selectedShiftKeys: state.selectedShiftKeys.filter(shiftKey => !action.shiftKeys.includes(shiftKey)) };
    }
    case 'SELECT_SPECIFIED_SHIFTS':
      return { ...state, selectedShiftKeys: uniq([...state.selectedShiftKeys, ...action.shiftKeys]) };

    default:
      return state;
  }
}
