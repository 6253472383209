import { omit } from 'lodash-es';

const ADD_DRAFT_SHIFT = 'ADD_DRAFT_SHIFT';
const REMOVE_DRAFT_SHIFT = 'REMOVE_DRAFT_SHIFT';
const REMOVE_MULTIPLE_DRAFT_SHIFTS = 'REMOVE_MULTIPLE_DRAFT_SHIFTS';
const CLEAR_DRAFT_SHIFTS = 'CLEAR_DRAFT_SHIFTS';
const UPDATE_DRAFT_SHIFT_PROP = 'UPDATE_DRAFT_SHIFT_PROP';
const UPDATE_TEMPLATE_SHIFT_PROP = 'UPDATE_TEMPLATE_SHIFT_PROP';
const ADD_MULTIPLE_DRAFTS = 'ADD_MULTIPLE_DRAFTS';

export const PERSIST_ACTIONS = [
  UPDATE_DRAFT_SHIFT_PROP,
  ADD_DRAFT_SHIFT,
  REMOVE_DRAFT_SHIFT,
  CLEAR_DRAFT_SHIFTS,
  ADD_MULTIPLE_DRAFTS,
  REMOVE_MULTIPLE_DRAFT_SHIFTS,
];

export const addDraftShift = (shiftId, updatedShift) => ({ type: ADD_DRAFT_SHIFT, updatedShift, shiftId });
export const addMultipleDraftShifts = (newDraftShifts) => ({ type: ADD_MULTIPLE_DRAFTS, newDraftShifts });
export const removeDraftShift = (shiftId) => ({ type: REMOVE_DRAFT_SHIFT, shiftId });
export const removeMultipleDraftShifts = (shiftKeys) => ({ type: REMOVE_MULTIPLE_DRAFT_SHIFTS, shiftKeys });
export const clearDraftShifts = () => ({ type: CLEAR_DRAFT_SHIFTS });

export const updateShiftProp = ({ shiftId, propKey, propVal, valid, resetErrors, shiftType }) => ({
  type: shiftType === 'draftShifts' ? UPDATE_DRAFT_SHIFT_PROP : UPDATE_TEMPLATE_SHIFT_PROP,
  shiftId,
  propKey,
  propVal,
  valid,
  resetErrors,
});

function updateShifts(state, action) {
  const existingShift = state[action.shiftId];
  if (!existingShift) return state; // This can happen if a draft is deleted while an async action is going on the background

  const newShift = { ...existingShift, [action.propKey]: action.propVal };
  if (action.resetErrors) {
    newShift.status = null;
    newShift.response = null;
  }
  if (action.valid !== undefined) newShift.isValid = action.valid;

  return { ...state, [action.shiftId]: newShift };
}

function draftShiftsReducer(state = {}, action) {
  switch (action.type) {

    // Add/Remove drafts
    case ADD_DRAFT_SHIFT:
      return { ...state, [action.shiftId]: { ...action.updatedShift } };
    case ADD_MULTIPLE_DRAFTS:
      return { ...state, ...action.newDraftShifts };
    case REMOVE_DRAFT_SHIFT: {
      const { [action.shiftId]: remove, ...remainingDraftShifts } = state;
      return remainingDraftShifts;
    }
    case REMOVE_MULTIPLE_DRAFT_SHIFTS: {
      return omit(state, action.shiftKeys);
    }
    case CLEAR_DRAFT_SHIFTS:
      return {};

    // Update drafts
    case UPDATE_DRAFT_SHIFT_PROP: {
      const newState = updateShifts(state, action);
      return newState;
    }

    default:
      return state;
  }
}

const UPSERT_RECENTLY_CREATED_SHIFTS = 'UPSERT_RECENTLY_CREATED_SHIFTS';
const CLEAR_RECENTLY_CREATED_SHIFTS = 'CLEAR_RECENTLY_CREATED_SHIFTS';

export const upsertRecentlyCreatedShifts = (shifts) => ({ type: UPSERT_RECENTLY_CREATED_SHIFTS, shifts });
export const clearRecentlyCreatedShifts = () => ({ type: CLEAR_RECENTLY_CREATED_SHIFTS });

function recentlyCreatedShiftsReducer(state = {}, action) {
  switch (action.type) {

    case UPSERT_RECENTLY_CREATED_SHIFTS: return { ...state, ...action.shifts };
    case CLEAR_RECENTLY_CREATED_SHIFTS: return {};

    default:
      return state;
  }
}

const ADD_TEMPLATE_SHIFT = 'ADD_TEMPLATE_SHIFT';
const SET_TEMPLATE_SHIFTS = 'SET_TEMPLATE_SHIFTS';
const CLEAR_TEMPLATE_SHIFTS = 'CLEAR_TEMPLATE_SHIFTS';
const REMOVE_TEMPLATE_SHIFT = 'REMOVE_TEMPLATE_SHIFT';
const ADD_RESPONSE_PROPS_TO_TEMPLATE_SHIFTS = 'ADD_RESPONSE_PROPS_TO_TEMPLATE_SHIFTS';

export const addTemplateShift = (templateShiftId, shift) => ({ type: 'ADD_TEMPLATE_SHIFT', templateShiftId, shift });
export const setTemplateShifts = (shifts) => ({ type: SET_TEMPLATE_SHIFTS, shifts });
export const clearTemplateShifts = () => ({ type: CLEAR_TEMPLATE_SHIFTS });
export const removeTemplateShift = key => ({ type: REMOVE_TEMPLATE_SHIFT, key });
export const addResponseDataToTemplateDrafts = shifts => ({ type: ADD_RESPONSE_PROPS_TO_TEMPLATE_SHIFTS, shifts });

const initialStateTemplateShiftsState = {};

function templateShiftsReducer(state = initialStateTemplateShiftsState, action) {
  switch (action.type) {

    case ADD_TEMPLATE_SHIFT: return { ...state, [action.templateShiftId]: { ...action.shift } };

    case SET_TEMPLATE_SHIFTS: return { ...initialStateTemplateShiftsState, ...action.shifts };

    case UPDATE_TEMPLATE_SHIFT_PROP: {
      const newState = updateShifts(state, action);
      return newState;
    }
    case REMOVE_TEMPLATE_SHIFT: {
      const { [action.key]: remove, ...remainingTemplateShifts } = state;
      return remainingTemplateShifts;
    }
    case CLEAR_TEMPLATE_SHIFTS: return initialStateTemplateShiftsState;

    case ADD_RESPONSE_PROPS_TO_TEMPLATE_SHIFTS: {

      const updatedTemplateDrafts = {};

      // Add response props to template drafts
      Object.entries(state).forEach(([key, shift]) => {
        const error = action.shifts[key].error ?? null;
        updatedTemplateDrafts[key] = { ...shift, status: error ? 'Failed' : null, response: error ?? null, isValid: !error };
      });

      return { ...state, ...updatedTemplateDrafts };
    }

    default:
      return state;
  }
}

const APPLY_TEMPLATE_TO_DATE = 'APPLY_TEMPLATE_TO_DATE';
const CLEAR_APPLY_TEMPLATE = 'CLEAR_APPLY_TEMPLATE';
const IS_CREATING_SHIFTS = 'IS_CREATING_SHIFTS';
const CREATE_SHIFTS_SUCCESS = 'CREATE_SHIFTS_SUCCESS';
const CREATE_SHIFTS_ERROR = 'CREATE_SHIFTS_ERROR';
const CLEAR_CREATE_SHIFTS_ERROR = 'CLEAR_CREATE_SHIFTS_ERROR';
const VALIDATION_ERROR = 'VALIDATION_ERROR';

export const applyTemplateToDate = (date) => ({ type: APPLY_TEMPLATE_TO_DATE, date });
export const clearApplyTemplate = () => ({ type: CLEAR_APPLY_TEMPLATE });
export const isCreatingShifts = () => ({ type: IS_CREATING_SHIFTS });
export const createShiftsSuccess = () => ({ type: CREATE_SHIFTS_SUCCESS });
export const createShiftsError = (error) => ({ type: CREATE_SHIFTS_ERROR, error });
export const clearCreateShiftsError = () => ({ type: CLEAR_CREATE_SHIFTS_ERROR });
export const validationError = (error) => ({ type: VALIDATION_ERROR, error });

const initialState = {
  isApplyingTemplate: false,
  applyTemplateToDate: null,
  isCreatingShifts: false,
  createShiftsError: null,
  validationError: null,
};

function createShiftsReducer(oldState = initialState, action) {

  const draftShifts = draftShiftsReducer(oldState.draftShifts, action);
  const recentlyCreatedShifts = recentlyCreatedShiftsReducer(oldState.recentlyCreatedShifts, action);
  const templateShifts = templateShiftsReducer(oldState.templateShifts, action);
  const combinedState = { ...oldState, draftShifts, recentlyCreatedShifts, templateShifts };

  switch (action.type) {
    case APPLY_TEMPLATE_TO_DATE: return { ...combinedState, applyTemplateToDate: action.date, isApplyingTemplate: true };
    case CLEAR_APPLY_TEMPLATE: return { ...combinedState, applyTemplateToDate: null, isApplyingTemplate: false };
    case IS_CREATING_SHIFTS: return { ...combinedState, isCreatingShifts: true, createShiftsError: null, validationError: null };
    case CREATE_SHIFTS_SUCCESS: return { ...combinedState, isCreatingShifts: false };
    case CREATE_SHIFTS_ERROR: return { ...combinedState, isCreatingShifts: false, createShiftsError: action.error };
    case CLEAR_CREATE_SHIFTS_ERROR: return { ...combinedState, isCreatingShifts: false, createShiftsError: null, validationError: null };
    case VALIDATION_ERROR: return { ...combinedState, isCreatingShifts: false, validationError: action.error };

    default:
      return combinedState;
  }
}

export default createShiftsReducer;


