import React from 'react';
import PropTypes from 'prop-types';

import { featureFlags } from 'config/featureFlags';
import Page from 'components/Page';
import Feature from 'components/Feature';
import Loading from 'components/Loading';


import ReportingHeader from '../ReportingHeader';
import DashboardHeader from './DashboardHeader';

import SpendReport from '../Reports/SpendReport';
import FillRateReport from '../Reports/FillRateReport';
import RequestsReport from '../Reports/RequestsReport';

const styles = {
  columns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  siteFilter: {
    width: 250,
    marginLeft: 30,
  },
};


export default function Dashboard(props) {

  return (
    <Page
      vflex
      title="Reporting"
      subTitle="Dashboard"
      loading={props.isLoadingReports}
    >

      <ReportingHeader selectedTabKey="dashboard" />
      <DashboardHeader />

      {props.isLoadingReports ?
        <Loading flex />
        :
        <div className="scrollable">
          <Feature featureFlag={featureFlags.REPORTING_SPEND}>
            <SpendReport
              clearReportingError={props.clearReportingError}
              durationBetweenDates={props.durationBetweenDates}
              reportError={props.reportError.spend}
              report={props.reports.spend}
              loading={props.isLoadingReports}
              onClickChart={() => props.onClickChart('spend')}
            />
          </Feature>

          <div style={styles.columns}>
            <FillRateReport
              clearReportingError={props.clearReportingError}
              durationBetweenDates={props.durationBetweenDates}
              reportError={props.reportError.fillRate}
              report={props.reports.fillRate}
              loading={props.isLoadingReports}
              onClickChart={() => props.onClickChart('fillRate')}
              width="49%"
            />
            <RequestsReport
              clearReportingError={props.clearReportingError}
              durationBetweenDates={props.durationBetweenDates}
              reportError={props.reportError.requests}
              report={props.reports.requests}
              loading={props.isLoadingReports}
              onClickChart={() => props.onClickChart('requests')}
              width="49%"
            />
          </div>
        </div>
      }
    </Page>
  );
}

const { object, number, func, string, bool, shape, objectOf } = PropTypes;

Dashboard.propTypes = {
  reports: objectOf(object).isRequired,
  onClickChart: func.isRequired,
  isLoadingReports: bool.isRequired,
  clearReportingError: func.isRequired,
  durationBetweenDates: number.isRequired,
  reportError: shape({
    fillRate: string,
    fillRateByRole: string,
    fillRateBySpeciality: string,
    requests: string,
    requestsByRole: string,
    requestsBySpeciality: string,
    spend: string,
    spendByRole: string,
    spendBySpeciality: string,
    activity: string,
  }).isRequired,
};
