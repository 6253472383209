import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SectionedList extends Component {

  processList = (listData, sectionSortProperty, sectionSortOrder, rowSortProperty, rowSortOrder) => {
    const processedList = {};
    Object.keys(listData).forEach((key) => {
      const cur = { ...listData[key] };
      const curSectionValue = cur[sectionSortProperty] ? cur[sectionSortProperty].toString() : '_UNSORTABLE';
      if (!processedList[curSectionValue]) {
        processedList[curSectionValue] = {
          sectionHeaderText: this.props.getSectionHeaderText(curSectionValue),
          rows: [cur],
        };
      } else {
        processedList[curSectionValue].rows.push(cur);
      }
    });
    // sort the list sections
    const sortedBySection = Object.keys(processedList).map((sectionKey) => {
      return processedList[sectionKey];
    }).sort((a, b) => {
      const aComp = a.sectionHeaderText.toString().toLowerCase();
      const bComp = b.sectionHeaderText.toString().toLowerCase();
      if (sectionSortOrder === 'ascending') {
        return aComp > bComp;
      } else if (sectionSortOrder === 'descending') {
        return aComp < bComp;
      }
      return null;
    });
    // sort the rows
    const sortedBySectionAndRow = sortedBySection.map((section) => {
      const sortedRows = Array.from(section.rows).sort((a, b) => {
        const aComp = a[rowSortProperty] ? a[rowSortProperty].toString().toLowerCase() : 0;
        const bComp = b[rowSortProperty] ? b[rowSortProperty].toString().toLowerCase() : 0;
        if (rowSortOrder === 'ascending') {
          return aComp > bComp;
        } else if (rowSortOrder === 'descending') {
          return aComp < bComp;
        }
        return null;
      });
      return {
        sectionHeaderText: section.sectionHeaderText,
        rows: sortedRows,
      };
    });

    return sortedBySectionAndRow;
  }

  render() {

    const data = this.props.data || {};
    const sections = this.processList(
      data,
      this.props.sectionSortProperty,
      this.props.sectionSortOrder,
      this.props.rowSortProperty,
      this.props.rowSortOrder,
    );

    return (
      <div>
        {
          sections.map((section, sectionIdx) => {
            const sectionKey = sectionIdx;
            return (
              <div key={sectionKey}>
                {
                  this.props.renderSectionHeader(section.sectionHeaderText)
                }
                {
                  section.rows.map((rowData, rowIdx) => {
                    const rowKey = rowIdx;
                    return (this.props.renderRow(rowData, rowKey));
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}

const { object, string, func, oneOf } = PropTypes;

SectionedList.propTypes = {
  data: object,
  getSectionHeaderText: func,
  renderRow: func.isRequired,
  renderSectionHeader: func.isRequired,
  rowSortOrder: oneOf(['ascending', 'descending']),
  rowSortProperty: string.isRequired,
  sectionSortOrder: oneOf(['ascending', 'descending']),
  sectionSortProperty: string.isRequired,
};

SectionedList.defaultProps = {
  data: {},
  getSectionHeaderText: value => value,
  rowSortOrder: 'ascending',
  sectionSortOrder: 'ascending',
};
