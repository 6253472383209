import React from 'react';
import { useSelector } from 'react-redux';
import PageHeader from 'components/PageHeader';
import '../BankManagement.css';

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 12,
    paddingRight: 0,
    paddingBottom: 12,
    // height: 48,
    position: 'relative',
    // bottom: 5,
  },
};

const BankHeader = ({ buttons, needReviewCount, navigateToReview }) => {
  const customTerminology = useSelector(state => state.global.orgConfig?.customTerminology);
  return (
    <>
      <div style={styles.headerContainer} className="m-hide">
        <div style={{ marginBottom: 6 }}>
          <PageHeader title={customTerminology?.bank ?? 'Staff Banks'} />
        </div>
        <div className="bank-header-button-container">
          {needReviewCount && needReviewCount > 0 ? (
          <div className="bank-header-need-review-count" onClick={navigateToReview}>
            <p>{needReviewCount} {needReviewCount === 1 ? 'candidate needs' : 'candidates need'} review. Click to view</p>
          </div>
          ) : null}
          <div style={{ paddingTop: 6 }}>
            {buttons}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankHeader;
