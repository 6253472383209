import React from 'react';

const styles = {
  display: 'block',
  position: 'absolute',
  bottom: '24px',
  left: '24px',
  zIndex: 1,
};

const CustomerSupportButton = () => (
  <a style={styles} href="mailto:support@circularwave.co.uk">
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="25" fill="#032E82" />
      <path d="M37 38L37 32H23L37 38Z" fill="white" />
      <rect x="13" y="11" width="24" height="24" rx="3" fill="white" />
      <path d="M17 27C22.7353 30.35 27.1471 30.35 32 27" stroke="#032E82" strokeWidth="0.7" />
    </svg>
  </a>
);

export default CustomerSupportButton;
