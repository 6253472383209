import React from 'react';
import PropTypes from 'prop-types';
import './radioButtonList.scss';
import spacing from '../../config/spacing';
import colors from '../../config/colors';

export default function RadioButtonList(props) {
  const styles = {
    radioButton: {
      // display: 'inline-block',
      display: 'flex',
      alignItems: 'center',
    },
    circle: {
      display: 'inline-block',
      marginRight: 6,
      border: `1px solid ${colors.cavalry.primary}`,
      width: 18,
      height: 18,
      borderRadius: '50%',
      verticalAlign: 'middle',
    },
    selected: {
      backgroundColor: colors.cavalry.primary,
      borderRadius: '50%',
      width: 12,
      height: 12,
      marginLeft: 3,
      marginTop: 3,
    },
    display: {
      display: 'inline-block',
      fontSize: '1rem',
      fontWeight: 500,
    },
    splitter: {
      clear: 'both',
    },
    container: {
      display: 'block',
      textAlign: 'center',
    },
    horizontalRadioButton: {
      width: '10%',
      float: 'left',
      textAlign: 'left',
    },
    horizontalText: {
      width: '75%',
      float: 'left',
      lineHeight: '32px',
      fontWeight: 200,
    },
    horizontalSplitter: {
      clear: 'both',
      marginBottom: spacing.xlarge,
    },
    subText: {
      fontSize: 12,
      color: colors.text,
      lineHeight: 1,
    },
    verticalContainer: {
      marginTop: spacing.base,
      marginBottom: spacing.base,
    },
  };

  const containerStyle = !props.style ? styles.container : [...styles.container, ...props.style];

  /* eslint jsx-a11y/no-static-element-interactions:"off" */
  /* eslint jsx-a11y/click-events-have-key-events:"off" */
  if (props.vertical) {
    return (
      <div style={styles.verticalContainer}>
        {props.items.map(item => (
          <div key={item.key} style={{ display: 'flex', alignItems: 'center' }} className="fadeOnTouch" onClick={() => props.onSelect(item.key)}>
            <div style={styles.horizontalRadioButton}>
              <div style={styles.circle}>
                {props.selectedKey === item.key ? <div style={styles.selected} /> : null}
              </div>
            </div>
            <div style={styles.horizontalText}>
              <h4 className="radio-button-item-title">{item.displayText}</h4>
              {item.description ? <p style={styles.subText}>{item.description}</p> : null }
            </div>
            <div style={styles.horizontalSplitter} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-children-12" style={{ display: 'flex', alignItems: 'center' }}>
      {props.items.map(item => (
        <div style={styles.radioButton} className="fadeOnTouch" key={item.key} onClick={() => props.onSelect(item.key)}>
          <div style={styles.circle}>
            {props.selectedKey === item.key ? <div style={styles.selected} /> : null}
          </div>
          <h4 style={styles.display}>{item.displayText}</h4>
        </div>
      ))}
      <div style={styles.splitter} />
    </div>
  );
}

const { string, arrayOf, shape, bool, func } = PropTypes;

RadioButtonList.propTypes = {
  items: arrayOf(shape({
    key: string,
    displayText: string,
    description: string,
  })),
  selectedKey: string,
  vertical: bool,
  onSelect: func.isRequired,
};

RadioButtonList.defaultProps = {
  items: [
    { key: 'add', displayText: 'Add' },
    { key: 'some', displayText: 'some' },
    { key: 'items', displayText: 'items' },
  ],
  selectedKey: '',
  vertical: false,
};
