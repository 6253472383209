import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { capitalise } from '../../lib/helpers-text';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

import './FormInput.scss';

//----------------------------------------------------------------
// DEFAULT STYLES
//----------------------------------------------------------------

const defaultStyles = {
  mainContainer: {
    boxSizing: 'border-box',
    width: '100%',
  },
  labelContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    height: 30,
    width: '30%',
  },
  label: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    color: colors.understated,
    fontSize: '0.9rem',
  },
  fieldContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: '70%',
  },
  input: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: 30,
    width: '100%',
    paddingLeft: 10,
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 4,
    fontSize: '0.9rem',
    color: colors.text,
  },
  validationContainer: {
    boxSizing: 'border-box',
    height: 20,
    color: colors.cavalry.error,
    fontSize: '0.9rem',
    fontWeight: 500,
  },
};

//----------------------------------------------------------------
// EXPORT
//----------------------------------------------------------------

// NOTE: this is a pretty dumb Component - responsibility for managing the overall form data, actions upon validation, // and overall form validation lie with parent container
// NOTE: all passed-in validators (validate prop) should return a validation failure string, or null
// NOTE: onValidate should return true if input was valid, or false if not

const { bool, number, string, object, func, oneOfType } = PropTypes;

export default class FormInput extends Component {

  static propTypes = {
    label: string.isRequired,
    name: string.isRequired,
    onChange: func.isRequired,
    onValidate: func,
    placeholder: string,
    styles: object,
    type: string,
    validate: func,
    value: oneOfType([bool, number, string]),
  }

  static defaultProps = {
    onValidate: (inputName, isValid) => {
      // NOTE: typically this would update formValidationErrors on state of parent
      console.log(`onValidate() input:${inputName} isValid:${isValid} `);
      return true;
    },
    placeholder: '',
    styles: {},
    type: 'text',
    validate: () => { return null; },
    value: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      validationFailedMessage: null,
    };
  }

  componentDidMount() {
    this.runValidation();
  }

  //----------------------------------------------------------------
  // INPUT EVENTS
  //----------------------------------------------------------------

  onBlur = () => {
    this.runValidation();
  }

  //----------------------------------------------------------------
  // CUSTOM
  //----------------------------------------------------------------

  runValidation = () => {
    const { name, value } = this.props;
    const vfm = this.props.validate(value);
    const inputIsValid = !vfm;
    this.setState({
      validationFailedMessage: vfm,
    }, () => {
      this.props.onValidate(name, inputIsValid);
    });
  }

  render() {
    const { label, name, onChange, placeholder, styles, type, value } = this.props;
    const fieldStyles = { ...defaultStyles, ...styles };
    const validationFailedMessage = this.state.validationFailedMessage ?
      `* ${capitalise(this.state.validationFailedMessage)}` :
      '';
    return (
      <div style={fieldStyles.mainContainer}>
        {label !== false &&
          <div style={fieldStyles.labelContainer}>
            <div style={fieldStyles.label}>{label}</div>
          </div>
        }
        <div style={{ ...fieldStyles.fieldContainer, width: label === false ? '100%' : '70%' }}>
          <input
            onBlur={() => { this.onBlur(); }}
            onChange={onChange}
            name={name}
            style={fieldStyles.input}
            placeholder={placeholder}
            type={type}
            value={value}
            className="form-input"
          />
          {validationFailedMessage && <div style={fieldStyles.validationContainer}>{validationFailedMessage}</div>}
        </div>
      </div>
    );
  }
}
