import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Loading from '../Loading';

import { createPeriod, addEventsToPeriod } from './helpers';
import StandardEventComponent from './StandardEventComponent';
import './calendar.scss';

const { bool, object, objectOf, func, instanceOf, oneOf, node, element, oneOfType, shape, arrayOf, string } = PropTypes;

const Moment = moment().constructor;

export const CalendarHeader = (props) => {
  const period = props.period;
  return (
    <div className="dayOfWeekContainer">
      {period.columns.map((columnName, index) => (
        <div className={`dayName ${index !== 6 ? 'dayRightBorder' : null}`} key={`${columnName}${index}`}>
          <p className="weekdayText">{columnName}{period.duration === 'week' ? ` ${period.days[index].label}` : null}</p>
        </div>
      ))}
    </div>
  );
};

export const CalendarDays = (props) => {
  const period = addEventsToPeriod(props.period, props.events);
  const daysPerRow = period.columns.length ?? 7;
  const useFullHeight = props.useFullHeight ?? false;
  const now = moment();
  const EventComponent = props.eventComponent;
  return (
    <div className="calendarDayContainer">
      {period.days.map((day, index) => {
        const isCurrentDay = day.date && moment(day.date).isSame(now, 'd');
        return (
          <div
            role="button"
            tabIndex={0}
            key={day.key}
            onClick={props.onSelectEvent && (() => props.onSelectEvent(day))}
            className={classNames('calendarDay', {
              grayDay: day.notInPeriod,
              calendarDayBorderRight: ((index + 1) % daysPerRow) !== 0,
              'calendarDay--current': isCurrentDay && props.highlightCurrentDay,
              'calendarDay--hoverable': props.hoverEffect,
            })}
            style={{
              boxSizing: 'border-box',
              height: useFullHeight ? `${(100 / Math.ceil(period.days.length / daysPerRow)).toFixed(2)}%` : 'auto',
              width: `${((100 / daysPerRow) - ((index % daysPerRow === daysPerRow - 1) ? 0.1 : 0)).toFixed(2)}%`,
            }}
          >
            {period.duration === 'month' ?
              <p className={`dayText ${isCurrentDay ? 'dayTextCurrentDay' : ''}`}>
                {day.label === '01' ? `${day.label} ${day.month}` : day.label}
              </p>
              : null
            }
            <div className="event">
              <EventComponent {...day} period={period} />
            </div>
          </div>
        ); })}
    </div>
  );
};

export class Calendar extends PureComponent {
  static propTypes = {
    events: objectOf(object),
    onNavigate: func,
    onSelectEvent: func,
    periodDuration: oneOf('month', 'week', 'two-weeks', 'week-am-pm'),
    date: instanceOf(Moment).isRequired,
    shiftHasTargetedCandidate: bool,
    eventComponent: oneOfType([node, element, object, func]),
    footer: oneOfType([node, element, object, func]),
    isFetching: bool.isRequired,
    hoverEffect: bool,
    onlyHeader: bool,
    highlightCurrentDay: bool,
    period: shape({
      startDate: instanceOf(Moment).isRequired,
      endDate: instanceOf(Moment).isRequired,
      duration: oneOf(['month', 'week', 'two-weeks', 'week-am-pm']),
      columns: arrayOf(string),
      days: arrayOf(object),
    }),
    noHeader: bool,
  }

  static defaultProps = {
    onNavigate: () => {},
    onSelectEvent: null,
    shiftHasTargetedCandidate: false,
    periodDuration: 'month',
    period: null,
    eventComponent: StandardEventComponent,
    footer: null,
    hoverEffect: false,
    onlyHeader: false,
    noHeader: false,
    highlightCurrentDay: true,
    events: {},
  }

  render() {
    const period = this.props.period ?? createPeriod(this.props.date, this.props.periodDuration);
    return (
      <>
        {this.props.isFetching && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!this.props.isFetching && (
          <div className="calendarContainer" style={(this.props.onlyHeader || this.props.noHeader) ? { height: 'auto' } : null}>
            {!this.props.noHeader && <CalendarHeader period={period} />}
            {!this.props.onlyHeader && (
              <CalendarDays
                period={period}
                events={this.props.events}
                onSelectEvent={this.props.onSelectEvent}
                hoverEffect={this.props.hoverEffect}
                highlightCurrentDay={this.props.highlightCurrentDay}
                eventComponent={this.props.eventComponent}
                useFullHeight
              />
            )}
            {this.props.footer}
          </div>
        )}
      </>
    );
  }
}
