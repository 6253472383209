import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';

import { featureFlags } from 'config/featureFlags';
import { shiftFieldMappings } from 'config/shiftFieldMappings';
import colors from 'config/colors';
import spacing from 'config/spacing';

import { convertFromSelect } from 'lib/helpers';
import { findApplicableRateCards } from 'lib/helpers-metadata';

import { useFeatureFlag } from 'hooks/feature';
import useHourlyRate from 'hooks/useHourlyRate';

import * as createDraftShiftActions from 'reducers/createDraftShifts';
import * as createShiftThunks from 'thunks/createShifts';
import * as createShiftReducer from 'reducers/createShifts';
import * as globalThunks from 'thunks/global';

import Feature from 'components/Feature';
import SectionSeparator from 'components/SectionSeparator';
import Button from 'components/Button';
import HelpIcon from 'components/HelpIcon';
import Loading from 'components/Loading';

import MultipleDraftShiftsModal from './MultipleDraftShiftModal';
import ServiceField from '../FormFields/ServiceField';
import SiteField from '../FormFields/SiteField';
import AreaField from '../FormFields/AreaField';
import DateField from '../FormFields/DateField';
import TimeField from '../FormFields/TimeField';
import RoleField from '../FormFields/RoleField';
import GradeField from '../FormFields/GradeField';
import SpecialityField from '../FormFields/SpecialityField';
import GenderField from '../FormFields/GenderField';
import CostCentreField from '../FormFields/CostCentreField';
import ReasonField from '../FormFields/ReasonField';
import SubReasonField from '../FormFields/SubReasonField';
import DescriptionField from '../FormFields/DescriptionField';
import RateCardField from '../FormFields/RateCardField';
import TargetCandidatesField from '../FormFields/TargetCandidatesField';
import BanksField from '../FormFields/BanksField';
import BanksFieldNew from '../FormFields/BanksFieldNew';
import HourlyRateField from '../FormFields/HourlyRateField';
import SlotsField from '../FormFields/SlotsField';

const styles = {
  centreContainer: { display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: spacing.small },
  popupText: {
    color: colors.white,
    fontSize: '0.9rem',
  },
};

const offsetTopFromParent = (parent, el) => {
  let offset = el.offsetTop;
  while (el.offsetParent !== parent) {
    el = el.offsetParent; // eslint-disable-line no-param-reassign
    offset += el.offsetTop;
  }
  return offset;
};

const elemIsVisible = (scrollContainer, el) => {
  const containerHeight = scrollContainer.offsetHeight;
  const containerScrollOffset = scrollContainer.scrollTop;

  const elemTop = offsetTopFromParent(scrollContainer, el);
  const elemBottom = elemTop + el.offsetHeight;

  return (elemTop >= containerScrollOffset && elemBottom <= containerScrollOffset + containerHeight);
};

const scrollShiftCardIntoView = debounce((shiftKey) => {
  const scrollContainer = document.querySelector('.shiftCardScrollContainer');
  const elem = document.querySelector(`.shiftCard--${shiftKey}`);
  if (scrollContainer && elem && !elemIsVisible(scrollContainer, elem)) elem.scrollIntoView({ block: 'center' });
}, 300, { leading: true, trailing: true });

function checkIfTargetedCandidatesFieldIsValid(candidates, slotsRequired) {
  const fieldIsInvalid = candidates.length > 0 && slotsRequired >= 1 && candidates.length > slotsRequired;

  return {
    success: !fieldIsInvalid,
    message: fieldIsInvalid ? '- There are more candidates selected than the number of vacancies required' : null,
  };
}

function CreateShift(props) {

  const dispatch = useDispatch();

  const rateCardsOn = useFeatureFlag('rateCard');
  const costCentresOn = useFeatureFlag('costCentres');
  const servicesOn = useFeatureFlag('services');
  const customHourlyRateOn = useFeatureFlag('customHourlyRate');

  const shiftCreateMetadata = useSelector(({ global }) => global.shiftCreateMetadata);
  const isFetchingShiftCreationMetadata = useSelector(({ global }) => global.isFetchingShiftCreationMetadata);
  const fetchShiftCreationMetadataError = useSelector(({ global }) => global.fetchShiftCreationMetadataError);
  const draftShiftValues = useSelector(({ createShifts }) => createShifts.draftShifts[props.jobId]);
  const mandatoryFields = useSelector(({ global }) => global.orgConfig?.shiftMandatoryFields);
  const customHourlyRateCriteria = useSelector(({ global }) => global.orgConfig?.customHourlyRateCriteria);
  const orgRateCards = useSelector(({ global }) => global.shiftCreateMetadata?.orgRateCards ?? []);
  const currentOrgKey = useSelector(({ global }) => global.currentOrgKey);
  const preferredGenderEnabled = useSelector(({ global }) => global.orgConfig?.preferredGender?.enabled ?? false);
  const sites = useSelector(({ user }) => user.sites);

  // TODO add isFetchingShiftCreationMetadata loading state

  const selectedSite = draftShiftValues?.site;
  const timezone = sites[selectedSite?.id]?.timezone ?? 'Europe/London';
  const isValid = draftShiftValues?.isValid ?? false;

  const [multipleShiftsModalOpen, setMultipleShiftsModalOpen] = useState(false);

  useEffect(() => {

    const fetchCreateShiftMetadata = async () => {
      dispatch(createDraftShiftActions.clearData());
      dispatch(createDraftShiftActions.setShiftId(props.jobId));

      // If no create shift metadata exists in Redux, then call api before attempting to populate form data
      if (!shiftCreateMetadata) await dispatch(globalThunks.fetchShiftCreateMetadata());

      dispatch(createShiftThunks.populateShiftFormData(props.jobId, 'draftShifts'));
      scrollShiftCardIntoView(props.jobId);
    };

    // If draft shift does not exist, redirect user to calendar view
    if (!draftShiftValues) {
      props.closeSidePanel();
      return;
    }

    fetchCreateShiftMetadata();

  }, [props.jobId]);

  // Find rate cards that match shift specification
  const applicableRateCards = useMemo(() => {
    return findApplicableRateCards(orgRateCards, draftShiftValues?.role?.id, draftShiftValues?.speciality?.id, draftShiftValues?.grade?.id, draftShiftValues?.site?.id, draftShiftValues?.area?.id, draftShiftValues?.reason?.id, draftShiftValues?.reason2?.id, draftShiftValues?.service?.id, currentOrgKey);
  }, [orgRateCards, props.jobId, draftShiftValues?.role?.id, draftShiftValues?.grade?.id, draftShiftValues?.speciality?.id, draftShiftValues?.site.id, draftShiftValues?.area?.id, draftShiftValues?.reason?.id, draftShiftValues?.reason2?.id]);

  const cancel = () => {
    dispatch(createDraftShiftActions.clearData());
    dispatch(createShiftReducer.removeDraftShift(props.jobId));
    props.closeSidePanel();
  };

  const updateDraftShiftField = (field, value) => {
    dispatch(createShiftThunks.updateShiftProp(props.jobId, field, value, 'draftShifts'));
  };

  // Form field component callbacks
  const onChangeService = useCallback(data => updateDraftShiftField('service', convertFromSelect(data)), [props.jobId]);
  const onChangeSite = useCallback(data => updateDraftShiftField('site', convertFromSelect(data)), [props.jobId]);
  const onChangeArea = useCallback(data => updateDraftShiftField('area', convertFromSelect(data)), [props.jobId]);
  const onChangeDate = useCallback(newDate => updateDraftShiftField('date', newDate, [props.jobId]));
  const onChangeStartTime = useCallback((hours, minutes) => {
    if (hours !== null && minutes !== null) updateDraftShiftField('startTime', { hours, minutes });
  }, [props.jobId]);
  const onChangeEndTime = useCallback((hours, minutes) => {
    if (hours !== null) updateDraftShiftField('endTime', { hours, minutes });
  }, [props.jobId]);
  const onChangeRole = useCallback(data => updateDraftShiftField('role', convertFromSelect(data)), [props.jobId]);
  const onChangeSlotsRequired = useCallback(slotsRequired => updateDraftShiftField('slotsRequired', slotsRequired), [props.jobId]);
  const onChangeGrade = useCallback(data => updateDraftShiftField('grade', convertFromSelect(data)), [props.jobId]);
  const onChangeSpeciality = useCallback(data => updateDraftShiftField('speciality', convertFromSelect(data)), [props.jobId]);
  const onChangeGender = useCallback(data => updateDraftShiftField('preferredGender', data), [props.jobId]);
  const onChangeReason = useCallback(data => updateDraftShiftField('reason', convertFromSelect(data)), [props.jobId]);
  const onChangeSubReason = useCallback(data => updateDraftShiftField('reason2', convertFromSelect(data)), [props.jobId]);
  const onChangePublicDescription = useCallback(text => updateDraftShiftField('publicDescription', text.target?.value ?? null), [props.jobId]);
  const onChangePrivateNotes = useCallback(text => updateDraftShiftField('privateNotes', text.target?.value ?? null), [props.jobId]);
  const onChangeHourlyRate = useCallback((text) => {
    updateDraftShiftField('customHourlyRate', text);
  }, [props.jobId]);
  const onChangeRateCard = useCallback(data => updateDraftShiftField('rateCard', applicableRateCards.find(rc => rc.key === data.value)), [props.jobId, applicableRateCards]);
  const onChangeCandidates = useCallback(candidates => updateDraftShiftField('candidates', candidates), [props.jobId]);
  const onChangeBanks = useCallback((bankKeys) => {
    updateDraftShiftField('bankKeys', bankKeys);
  }, [props.jobId, draftShiftValues?.bankKeys]);

  if (!draftShiftValues) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ textAlign: 'center', color: '#999' }}>Draft shift does not exist.</p>
      </div>
    );
  }

  const missingMandatoryFields = useMemo(() => {
    const missingFields = mandatoryFields.filter((field) => {

      // Return null if costCentre. This is calculated under the hood and not manually entered by the user
      if (field === 'costCentre') return null;
      if (!draftShiftValues) return null;
      const shiftFieldMapping = shiftFieldMappings[field];
      const draftValue = draftShiftValues[shiftFieldMapping?.key ?? field];
      return Array.isArray(draftValue) ? !draftValue.length : !draftValue;
    });

    // If rateCard requires a custom hourly rate, and it has not yet been set, add customHourlyRate field to missingFields
    if (draftShiftValues.rateCard?.requireCustomHourlyRate && !draftShiftValues.customHourlyRate) {
      missingFields.push('customHourlyRate');
    }
    return missingFields;
  }, [mandatoryFields, draftShiftValues]);

  const { estimatedCost, customHourlyRateValidation } = useHourlyRate({
    requireCustomHourlyRate: draftShiftValues.rateCard?.requireCustomHourlyRate ?? false,
    customHourlyRate: draftShiftValues.customHourlyRate,
    customHourlyRateCriteria,
    startTime: draftShiftValues.startTime,
    endTime: draftShiftValues.endTime,
    timezone,
  });

  const targetCandidatesFieldValidation = useMemo(() => {
    return checkIfTargetedCandidatesFieldIsValid(draftShiftValues.candidates, draftShiftValues.slotsRequired);
  }, [draftShiftValues.candidates, draftShiftValues.slotsRequired]);

  const minSlotsRequiredValidationError = draftShiftValues.slotsRequired < 1;
  const maxSlotsRequiredValidationError = draftShiftValues.slotsRequired > 99;

  const showCostCentreError = (
    costCentresOn &&
    mandatoryFields.includes('costCentre') &&
    !draftShiftValues.costCentre &&
    !missingMandatoryFields.includes('role') &&
    !missingMandatoryFields.includes('grade') &&
    !missingMandatoryFields.includes('speciality') &&
    !missingMandatoryFields.includes('site') &&
    !missingMandatoryFields.includes('area') &&
    !missingMandatoryFields.includes('reason')
  );

  const showRateCardError = rateCardsOn && !draftShiftValues.rateCard;

  if (isFetchingShiftCreationMetadata) {
    return (
      <div style={styles.centreContainer}>
        <Loading />
      </div>
    );
  }

  // Show error if create shift metadata fails to fetch
  if (fetchShiftCreationMetadataError) {
    return (
      <div style={styles.centreContainer}>
        <p style={{ color: colors.red }}>{fetchShiftCreationMetadataError}</p>
      </div>
    );
  }

  return (
    <>
      <div className="createShiftForm shiftDetailContentContainer">

        {servicesOn && (
          <div className="shiftDetailsSubSection">
            <ServiceField onChange={onChangeService} service={draftShiftValues.service} />
          </div>
        )}

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Location and Time</p>
          </div>

          <SiteField
            site={draftShiftValues.site}
            serviceKey={draftShiftValues.service?.id ?? null}
            onChange={onChangeSite}
            mandatory={mandatoryFields.includes('site')}
          />

          <Feature featureFlag={featureFlags.SITE_AREAS}>
            <AreaField
              area={draftShiftValues.area}
              siteKey={draftShiftValues.site.id}
              onChange={onChangeArea}
              mandatory={mandatoryFields.includes('area')}
            />
          </Feature>

          <DateField
            date={draftShiftValues.startTime}
            onChange={onChangeDate}
            timezone={timezone}
          />

          <TimeField
            startTime={draftShiftValues.startTime}
            endTime={draftShiftValues.endTime}
            timezone={timezone}
            onChangeStartTime={onChangeStartTime}
            onChangeEndTime={onChangeEndTime}
          />
        </div>
        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Shift Specifications</p>
          </div>

          <SlotsField
            slotsRequired={draftShiftValues.slotsRequired}
            onChange={onChangeSlotsRequired}
            mandatory={mandatoryFields.includes('slotsRequired')}
            label="Vacancies"
          />

          <RoleField
            role={draftShiftValues.role}
            mandatoryFields={mandatoryFields}
            onChange={onChangeRole}
            mandatory={mandatoryFields.includes('role')}
          />

          <GradeField
            grade={draftShiftValues.grade}
            role={draftShiftValues.role}
            mandatoryFields={mandatoryFields}
            onChange={onChangeGrade}
            mandatory={mandatoryFields.includes('grade')}
          />

          <SpecialityField
            speciality={draftShiftValues.speciality}
            mandatoryFields={mandatoryFields}
            onChange={onChangeSpeciality}
            mandatory={mandatoryFields.includes('speciality')}
          />

          {preferredGenderEnabled && (
            <GenderField
              gender={draftShiftValues.preferredGender}
              onChange={onChangeGender}
            />
          )}

          <Feature featureFlag={featureFlags.COST_CENTRES}>
            <CostCentreField costCentre={draftShiftValues.costCentre} />
          </Feature>
        </div>

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Shift Reason</p>
          </div>

          <ReasonField
            reason={draftShiftValues.reason}
            onChange={onChangeReason}
            mandatory={mandatoryFields.includes('reason')}
          />

          <SubReasonField
            reasonKey={draftShiftValues.reason?.id}
            subReason={draftShiftValues.reason2}
            onChange={onChangeSubReason}
            mandatory={mandatoryFields.includes('subReason')}
          />

          <DescriptionField
            label="Public Description"
            placeholder="Displayed to staff when they view the shift"
            description={draftShiftValues.publicDescription}
            onChange={onChangePublicDescription}
          />

          <DescriptionField
            label="Private Notes"
            placeholder="Only visible to other admins"
            description={draftShiftValues.privateNotes}
            onChange={onChangePrivateNotes}
          />

        </div>

        {rateCardsOn ?
          <div className="shiftDetailsSubSection" style={{ marginBottom: 12 }}>
            <div className="shiftDetailsSubSectionTitle">
              <p className="shiftDetailsSubHeading">Rate Card</p>
            </div>
            <RateCardField
              rateCard={draftShiftValues.rateCard}
              rateCards={applicableRateCards}
              onChange={onChangeRateCard}
            />

            {customHourlyRateOn && (
              <>
                <HourlyRateField
                  label="Rate"
                  subLabel="(Per Hour)"
                  placeholder="0.00"
                  customHourlyRate={draftShiftValues.customHourlyRate}
                  onChange={onChangeHourlyRate}
                  disabled={!draftShiftValues.rateCard?.requireCustomHourlyRate}
                  mandatory={draftShiftValues.rateCard?.requireCustomHourlyRate}
                  customHourlyRateValidation={customHourlyRateValidation}
                  estimatedCost={estimatedCost}
                />
              </>
            )}
          </div>
          :
          null
        }

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">
              Release Shift To
              <HelpIcon style={{ marginLeft: 6 }}>
                <h4>Staff</h4>
                <p style={styles.popupText}>You can directly book staff into a shift as soon as it is created to fill the required amount of slots. If all slots are filled, then other staff will not be notified about the shift.</p>
                <h4>Bank</h4>
                <p style={styles.popupText}>The shift will be released to the selected banks if there are slots available.</p>
              </HelpIcon>
            </p>
          </div>

          <TargetCandidatesField
            candidates={draftShiftValues.candidates}
            onChange={onChangeCandidates}
            isValid={targetCandidatesFieldValidation.success}
          />

          <BanksFieldNew
            bankKeys={draftShiftValues.bankKeys}
            onChange={onChangeBanks}
            isValid={targetCandidatesFieldValidation.success}
            mandatory={mandatoryFields.includes('bank')}
          />
        </div>


        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">
              Duplicate To Multiple Dates
              <HelpIcon style={{ marginLeft: 6 }}>
                <p style={styles.popupText}>The multiple dates feature allows you to duplicate a draft shift into many identical draft shifts accross multiple dates.</p>
                <p style={styles.popupText}>Once created, you can edit the new draft individually before publishing them.</p>
              </HelpIcon>
            </p>
          </div>
          <Button outline white shadow={false} disabled={!isValid} fullWidth large onClick={() => setMultipleShiftsModalOpen(true)}>Select Multiple Dates</Button>
        </div>

      </div>

      <div style={{ borderTop: '1px solid #EAEAEA' }}>

        {
          missingMandatoryFields.length || showCostCentreError || showRateCardError || !targetCandidatesFieldValidation.success || maxSlotsRequiredValidationError || minSlotsRequiredValidationError
            ?
              <div className="shiftDetailsSubSection">
                <div className="cs__validation-messages">
                  {(missingMandatoryFields.length > 0) &&
                    <p>{`- Please complete empty mandatory field${missingMandatoryFields.length === 1 ? '' : 's'} (${missingMandatoryFields.map(field => shiftFieldMappings[field].name).join(', ')})`}</p>
                  }
                  {showCostCentreError && <p>- There is no cost centre for this Role, Grade and Speciality combination - please review or contact your system administrator</p>}
                  {showRateCardError && <p>- There is no Rate Card for this Role, Grade and Speciality combination - please review or contact your system administrator</p>}
                  {minSlotsRequiredValidationError && <p>- The minimum number of slots can be 1</p>}
                  {maxSlotsRequiredValidationError && <p>- The number of slots cannot exceed 99</p>}
                  {!targetCandidatesFieldValidation.success && targetCandidatesFieldValidation.message && (
                    <p>
                      {targetCandidatesFieldValidation.message}
                    </p>
                  )}
                </div>
              </div>
            :
            null
        }

        <div className="shiftDetailsSubSection">
          <div className="cs__button-container">
            <Button shadow={false} outline large white onClick={cancel}>Delete Draft shift</Button>
            <Button shadow={false} large black disabled={!isValid} onClick={props.closeSidePanel}>Save Draft Shift</Button>
          </div>
        </div>

      </div>

      {multipleShiftsModalOpen && (
        <MultipleDraftShiftsModal
          originalDraftShiftDate={draftShiftValues.startTime}
          originalDraftShiftEndTime={draftShiftValues.endTime}
          isOpen={multipleShiftsModalOpen}
          onCancel={() => setMultipleShiftsModalOpen(false)}
          shiftHasTargetedCandidate={!!draftShiftValues.candidate}
          originalDraftShiftId={props.jobId}
          originalDraftShift={draftShiftValues}
          onSuccess={() => props.openDraftShiftView()}
        />
      )}
    </>
  );
}

CreateShift.propTypes = {
  jobId: PropTypes.string.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  openDraftShiftView: PropTypes.func.isRequired,
};

export default CreateShift;
