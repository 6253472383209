import { useSelector } from 'react-redux';
import { isFeatureOn } from 'lib/features';
import { FeatureFlag } from 'config/featureFlags';
import { Permission } from 'config/permissions';

export function useFeatureFlag(featureFlag: FeatureFlag | null, permissionRequired: Permission | null = null) : boolean {

  const userState = useSelector(state => state.user);
  const globalState = useSelector(state => state.global);

  return !!isFeatureOn(featureFlag, permissionRequired, userState, globalState);
}


export function usePermission(permissionRequired: Permission) : boolean {

  const userState = useSelector(state => state.user);
  const globalState = useSelector(state => state.global);

  return !!isFeatureOn(null, permissionRequired, userState, globalState);
}
