const COMPLIANCE_SUSPENSION_START_LOADING = 'COMPLIANCE_SUSPENSION_START_LOADING';
const COMPLIANCE_SUSPENSION_STOP_LOADING = 'COMPLIANCE_SUSPENSION_STOP_LOADING';
const REMOVING_CANDIDATE_FROM_BANK = 'REMOVING_CANDIDATE_FROM_BANK';
const CANDIDATE_REMOVED_FROM_BANK = 'CANDIDATE_REMOVED_FROM_BANK';
const CANCELLING_REMOVE_FROM_BANK = 'CANCELLING_REMOVE_FROM_BANK';
const CANCELLED_REMOVE_FROM_BANK = 'CANCELLED_REMOVE_FROM_BANK';
const CANDIDATE_REMOVAL_ERROR = 'CANDIDATE_REMOVAL_ERROR';
const DISMISS_CANDIDATE_REMOVAL_ERROR = 'DISMISS_CANDIDATE_REMOVAL_ERROR';
const FETCH_COMPLIANCE_ITEM = 'FETCH_COMPLIANCE_ITEM';
const FETCH_COMPLIANCE_ITEM_SUCCESS = 'FETCH_COMPLIANCE_ITEM_SUCCESS';
const FETCH_COMPLIANCE_ITEM_ERROR = 'FETCH_COMPLIANCE_ITEM_ERROR';
const IS_APPROVING_OR_REJECTING_SUBMISSION = 'IS_APPROVING_OR_REJECTING_SUBMISSION';
const APPROVE_OR_REJECT_SUBMISSION_ERROR = 'APPROVE_OR_REJECT_SUBMISSION_ERROR';

export function complianceSuspensionStartLoading() {
  return {
    type: COMPLIANCE_SUSPENSION_START_LOADING,
  };
}

export function complianceSuspensionStopLoading() {
  return {
    type: COMPLIANCE_SUSPENSION_STOP_LOADING,
  };
}

export function removingCandidateFromBank() {
  return {
    type: REMOVING_CANDIDATE_FROM_BANK,
  };
}

export function candidateRemovedFromBank() {
  return {
    type: CANDIDATE_REMOVED_FROM_BANK,
  };
}

export function cancellingRemoveFromBank() {
  return {
    type: CANCELLING_REMOVE_FROM_BANK,
  };
}

export function cancelledRemoveFromBank() {
  return {
    type: CANCELLED_REMOVE_FROM_BANK,
  };
}

export function candidateRemovalError() {
  return {
    type: CANDIDATE_REMOVAL_ERROR,
  };
}

export function dismissCandidateRemovalError() {
  return {
    type: DISMISS_CANDIDATE_REMOVAL_ERROR,
  };
}

export const fetchComplianceItem = () => ({ type: FETCH_COMPLIANCE_ITEM });
export const fetchComplianceItemSuccess = item => ({ type: FETCH_COMPLIANCE_ITEM_SUCCESS, item });
export const fetchComplianceItemError = error => ({ type: FETCH_COMPLIANCE_ITEM_ERROR, error });

export const isApprovingOrRejectingSubmission = (submissionKey) => ({ type: IS_APPROVING_OR_REJECTING_SUBMISSION, submissionKey });
export const approveOrRejectSubmissionError = (error) => ({ type: APPROVE_OR_REJECT_SUBMISSION_ERROR, error });

const initialState = {
  error: null,
  isLoadingSuspension: false,
  isFetchingComplianceHistory: false,
  history: {},
  isRemovingCandidate: false,
  isCancellingRemoval: false,
  candidateRemovalError: null,
  isFetchingCandidateComplianceStatus: false,
  item: null,
  isApprovingOrRejectingSubmission: null,
  approveOrRejectSubmissionError: null,
  selectedSubmissionKey: null,
};

export default function compliance(state = initialState, action) {
  switch (action.type) {
    case COMPLIANCE_SUSPENSION_START_LOADING:
      return {
        ...state,
        isLoadingSuspension: true,
      };
    case COMPLIANCE_SUSPENSION_STOP_LOADING:
      return {
        ...state,
        isLoadingSuspension: false,
      };
    case REMOVING_CANDIDATE_FROM_BANK:
      return {
        ...state,
        isRemovingCandidate: true,
      };
    case CANDIDATE_REMOVED_FROM_BANK:
      return {
        ...state,
        isRemovingCandidate: false,
      };
    case CANCELLING_REMOVE_FROM_BANK:
      return {
        ...state,
        isCancellingRemoval: true,
      };
    case CANCELLED_REMOVE_FROM_BANK:
      return {
        ...state,
        isCancellingRemoval: false,
      };
    case CANDIDATE_REMOVAL_ERROR:
      return {
        ...state,
        candidateRemovalError: true,
        isCancellingRemoval: false,
      };
    case DISMISS_CANDIDATE_REMOVAL_ERROR:
      return {
        ...state,
        candidateRemovalError: false,
      };
    case FETCH_COMPLIANCE_ITEM:
      return {
        ...state,
        isFetchingComplianceItem: true,
        fetchComplianceItemError: null,
        isApprovingOrRejectingSubmission: false,
      };
    case FETCH_COMPLIANCE_ITEM_SUCCESS:
      return {
        ...state,
        isFetchingComplianceItem: false,
        item: action.item,
        isApprovingOrRejectingSubmission: false,
        selectedSubmissionKey: null,
      };
    case FETCH_COMPLIANCE_ITEM_ERROR:
      return {
        ...state,
        isFetchingComplianceItem: false,
        fetchComplianceItemError: action.error,
      };
    case IS_APPROVING_OR_REJECTING_SUBMISSION:
      return {
        ...state,
        isApprovingOrRejectingSubmission: true,
        selectedSubmissionKey: action.submissionKey,
      };
    case APPROVE_OR_REJECT_SUBMISSION_ERROR:
      return {
        ...state,
        isApprovingOrRejectingSubmission: false,
        approveOrRejectSubmissionError: action.error,
        selectedSubmissionKey: null,
      };
    default:
      return state;
  }
}
