const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
const FETCH_PERMISSIONS_ERROR = 'FETCH_PERMISSIONS_ERROR';
const ADD_PERMISSIONS_DATA = 'ADD_PERMISSIONS_DATA';
const CLEAR_ERROR = 'CLEAR_ERROR';
const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS';
const FETCH_SITE_PERMISSIONS = 'FETCH_SITE_PERMISSIONS';
const FETCH_SITE_PERMISSIONS_SUCCESS = 'FETCH_SITE_PERMISSIONS_SUCCESS';
const FETCH_SITE_PERMISSIONS_ERROR = 'FETCH_SITE_PERMISSIONS_ERROR';
const ADD_SITE_PERMISSIONS_DATA = 'ADD_SITE_PERMISSIONS_DATA';
const CLEAR_SITE_PERMISSIONS = 'CLEAR_SITE_PERMISSIONS';

export function fetchPermissions() {
  return {
    type: FETCH_PERMISSIONS,
  };
}
export function fetchPermissionsSuccess(id, key, data) {
  return {
    type: FETCH_PERMISSIONS_SUCCESS,
    id,
    key,
    data,
  };
}
export function fetchPermissionsError(error) {
  return {
    type: FETCH_PERMISSIONS_ERROR,
    error,
  };
}
export function addPermissionsData(data) {
  return {
    type: ADD_PERMISSIONS_DATA,
    data,
  };
}
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}
export function clearPermissions() {
  return {
    type: CLEAR_PERMISSIONS,
  };
}
export function fetchSitePermissions() {
  return {
    type: FETCH_SITE_PERMISSIONS,
  };
}
export function fetchSitePermissionsSuccess(id, key, data) {
  return {
    type: FETCH_SITE_PERMISSIONS_SUCCESS,
    id,
    key,
    data,
  };
}
export function fetchSitePermissionsError(error) {
  return {
    type: FETCH_SITE_PERMISSIONS_ERROR,
    error,
  };
}
export function addSitePermissionsData(data) {
  return {
    type: ADD_SITE_PERMISSIONS_DATA,
    data,
  };
}
export function clearSitePermissions() {
  return {
    type: CLEAR_SITE_PERMISSIONS,
  };
}

const initialState = {
  userId: null,
  permissions: null,
  isFetchingPermissions: false,
  sites: null,
  isFetchingSitePermissions: false,
  error: null,
};

export default function employer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return {
        ...state,
        isFetchingPermissions: true,
      };
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchingPermissions: false,
        userId: action.id,
        orgKey: action.key,
        permissions: action.data,
        error: null,
      };
    case FETCH_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchingPermissions: false,
        error: true,
      };
    case ADD_PERMISSIONS_DATA:
      return {
        ...state,
        permissions: action.data,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_PERMISSIONS:
      return {
        ...initialState,
      };
    case FETCH_SITE_PERMISSIONS:
      return {
        ...state,
        isFetchingSitePermissions: true,
      };
    case FETCH_SITE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchingSitePermissions: false,
        userId: action.id,
        orgKey: action.key,
        sites: action.data,
        error: null,
      };
    case FETCH_SITE_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchingSitePermissions: false,
        error: true,
      };
    case ADD_SITE_PERMISSIONS_DATA:
      return {
        ...state,
        sites: action.data,
      };
    case CLEAR_SITE_PERMISSIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
