import moment from 'moment';

export function isGreaterThanTwoWeeks(day) {
  const twoWeeks = moment().add(2, 'weeks');

  if (moment(day).isBefore(twoWeeks)) return true;
  return false;
}

export function isBeforeToday(day) {
  const startOfToday = moment().utc().startOf('day');
  return moment.utc(day).isBefore(startOfToday);
}
