import React, { CSSProperties, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Period, PublishedShiftSummary, ShiftsNav } from 'types/ShiftTypes';
import Button from 'components/Button';
import { useFeatureFlag } from 'hooks/feature';
import colors from 'config/colors';

import { useSelectedShifts } from '../shift-hooks';
import { CreateShiftButton } from './ShiftsHeaderButtons';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import BulkAgencyRelease from './BulkAgencyRelease';
import SaveToTemplateModal from './SaveToTemplateModal';

const styles = {
  shiftTotalText: {
    color: '#999',
    padding: '3px 12px',
    borderBottom: '1px solid rgba(164, 194, 244, 0.2)',
    fontSize: '0.9rem',
  },
  bottomBar: {
    padding: '12px 12px',
    borderTop: '1px solid rgba(164, 194, 244, 0.2)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomBarText: {
    textAlign: 'left' as CSSProperties['textAlign'],
    fontSize: '.9rem',
    lineHeight: 1.2,
    color: colors.text,
  },
};

interface BulkActionBarProps {
  period: Period,
  nav: ShiftsNav,
  shiftList: Array<PublishedShiftSummary>;
  refreshShiftList: () => void,
}

const BulkActionBar = React.memo((props: BulkActionBarProps) : JSX.Element | null => {

  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);
  const [saveToTemplateModalIsOpen, setSaveToTemplateModalIsOpen] = useState(false);
  const [agencyReleaseModalIsOpen, setAgencyReleaseModalIsOpen] = useState(false);

  const isMobile = useSelector(state => state.screen.isMobile);
  const allDraftShifts = Object.values(useSelector(state => state.createShifts.draftShifts) ?? {});
  const { selectedShiftKeys, unselectSpecifiedShifts, unselectAllShifts } = useSelectedShifts(props.period);

  const shiftList = props.shiftList;
  const draftShifts = useMemo(() => shiftList.filter(shift => shift.isDraft), [shiftList]);
  const nonDraftShifts = useMemo(() => shiftList.filter(shift => !shift.isDraft), [shiftList]);
  const selectedDraftShifts = useMemo(() => draftShifts.filter(shift => selectedShiftKeys.includes(shift.key)), [draftShifts, selectedShiftKeys]);
  const selectedNonDraftShifts = useMemo(() => nonDraftShifts.filter(shift => selectedShiftKeys.includes(shift.key)), [nonDraftShifts, selectedShiftKeys]);

  const adminCanDeleteShifts = useFeatureFlag(null, 'jobsCanDeleteJobs');
  const adminCanCreateShifts = useFeatureFlag(null, 'jobsCanCreate');

  // Check that agency feature flag is on, and admin has release to agency permission
  const adminCanReleaseToAgency = useFeatureFlag('vms', 'vmsCanApproveAgencyRequests');
  const canSaveToTemplateFromCurrentView = ['day', 'week', 'week-am-pm'].includes(props.period.duration);

  const invalidDraftsSelected = selectedDraftShifts.some(shift => !shift.isValid);

  if (!selectedShiftKeys.length && allDraftShifts.length > 0) {
    return (
      <div style={{ ...styles.bottomBar }}>
        <div style={{ ...styles.bottomBarText }}>
          You have <strong>{allDraftShifts.length}</strong> unpublished {isMobile ? 'draft' : 'draft shift'}{allDraftShifts.length === 1 ? '' : 's'}
          {!!(draftShifts.length && draftShifts.length !== allDraftShifts.length) && (
            <span> (<strong>{draftShifts.length}</strong> visible in the current view)</span>)
          }
        </div>
        <div style={{ width: 12, height: 12, flex: '0 0 auto' }} />
        <div style={{ flex: '0 0 auto' }}>
          <Button
            black
            onClick={() => props.nav.goToConfirmDraftsView()}
          >
            Review &amp; Publish
          </Button>
          {/* <Button */}
          {/*   positive */}
          {/*   onClick={() => { */}
          {/*     const shiftKeys = shiftList.filter(shift => shift.isDraft && shift.isValid).map(shift => shift.key); */}
          {/*     createShifts(shiftKeys); */}
          {/*     unselectAllShifts(shiftKeys); */}
          {/*   }} */}
          {/* > */}
          {/*   Publish All */}
          {/* </Button> */}
        </div>
      </div>
    );
  }

  if (!selectedShiftKeys.length) return null;

  return (
    <div style={{ ...styles.bottomBar, justifyContent: 'space-between' }}>

      {/* Selected shifts counts */}
      <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', borderRight: '1px solid #EAEAEA' }}>
        <div style={{ color: colors.text, fontSize: '0.9rem', marginRight: 12 }}>
          Selected:
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '0.9rem', marginRight: 12 }}>
          {!!selectedDraftShifts.length && (
            <div style={{ color: colors.text, lineHeight: 1 }}>
              {selectedDraftShifts.length} / {draftShifts.length} drafts
            </div>
          )}
          {!!selectedNonDraftShifts.length && (
            <div style={{ color: colors.text, lineHeight: 1, marginTop: 3 }}>
              {selectedNonDraftShifts.length} / {nonDraftShifts.length} shifts
            </div>
          )}
        </div>
        <a
          className="link"
          style={{ fontSize: '0.9rem', marginRight: 12 }}
          onClick={() => unselectAllShifts()}
        >
          Deselect all
        </a>
      </div>

      {/* Action buttons */}
      <div className="space-children-12" style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', padding: '0 12px' }}>
        <div style={{ color: colors.text, fontSize: '0.9rem', marginRight: 12 }}>
          Actions:
        </div>
{/*        <Button
          positive
          style={{ marginRight: 12 }}
          disabled={!selectedDraftShifts.length}
          onClick={() => {
            const shiftKeys = selectedDraftShifts.map(shift => shift.key);
            createShifts(shiftKeys);
            unselectAllShifts(shiftKeys);
          }}
        >
          Publish Drafts
        </Button>*/}
{/*        <Button
          positive
          onClick={() => props.nav.goToConfirmDraftsView()}
          style={{ marginRight: 12 }}
        >
          Review{!isMobile && ' & Publish'}
        </Button>*/}


        {adminCanCreateShifts && canSaveToTemplateFromCurrentView && (
          <Button
            outline
            white
            disabled={invalidDraftsSelected}
            onClick={() => setSaveToTemplateModalIsOpen(true)}
          >
            Save To Template
          </Button>
        )}

        {adminCanReleaseToAgency && (
          <Button
            onClick={() => setAgencyReleaseModalIsOpen(true)}
          >
            Release To Agency
          </Button>
        )}

        {adminCanDeleteShifts && (
          <Button
            danger
            style={{ marginRight: 12 }}
            onClick={() => {
              setConfirmDeleteModalIsOpen(true);
            }}
          >
            Delete…
          </Button>
        )}

{/*        <Button positive style={{ marginRight: 12 }} onClick={() => window.alert('Not implemented!')}>
          Copy
        </Button>
        <Button positive style={{ marginRight: 12 }} onClick={() => window.alert('Not implemented!')}>
          Message Staff
        </Button>*/}
      </div>

      {saveToTemplateModalIsOpen && (
        <SaveToTemplateModal
          selectedShifts={[...selectedDraftShifts, ...selectedNonDraftShifts]}
          onClose={() => setSaveToTemplateModalIsOpen(false)}
          unselectSpecifiedShifts={unselectSpecifiedShifts}
          replaceToTemplateEditView={props.nav.replaceToTemplateEditView}
        />
      )}

      {confirmDeleteModalIsOpen && (
        <ConfirmDeleteModal
          selectedDraftShifts={selectedDraftShifts}
          selectedNonDraftShifts={selectedNonDraftShifts}
          onClose={() => setConfirmDeleteModalIsOpen(false)}
          unselectSpecifiedShifts={unselectSpecifiedShifts}
          refreshShiftList={props.refreshShiftList}
        />
      )}

      {agencyReleaseModalIsOpen && (
        <BulkAgencyRelease
          refreshShiftList={props.refreshShiftList}
          unselectSpecifiedShifts={unselectSpecifiedShifts}
          shifts={selectedNonDraftShifts}
          selectedDrafts={selectedDraftShifts}
          onClose={() => setAgencyReleaseModalIsOpen(false)}
        />
      )}

    </div>
  );
});

export default BulkActionBar;

