import React from 'react';

import colors from 'config/colors';
import Button from 'components/Button';
import { fieldStyles } from './ui/field-styles';

const stopSubmit = (cb) => {
  return (ev) => {
    ev.preventDefault();
    cb();
    return false;
  };
};

const RequestPasswordResetForm = (props) => {

  const sendResetPasswordEmail = () => {
    if (!props.pwDisabled && props.sendResetPasswordEmail) props.sendResetPasswordEmail();
  };

  return (
    <form method="post" onSubmit={stopSubmit(sendResetPasswordEmail)}>
      <div className="space-children-24--bottom">
        <p style={{ color: colors.text, fontSize: '0.9rem' }}>
          Please enter the email address associated with your Dagny account.
          We will email you a link to a page where you can easily create a new password.
        </p>
        <input
          type="email"
          autoComplete="email"
          style={fieldStyles.input}
          placeholder="Registered Email Address"
          value={props.formValues.email}
          onChange={event => props.setFormValue('email', event.target.value)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }} className="space-children-12">
        <Button large white outline onClick={props.cancelForgotPassword}>
          Cancel
        </Button>
        <Button large submit disabled={props.disabled}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default RequestPasswordResetForm;
