import React, { useRef } from 'react';
import moment from 'moment';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getShifts } from 'thunks/jobs';
import { refreshRecentlyCreatedShifts } from 'thunks/createShifts';

import { createPeriod } from 'components/Calendar';
import MonthCalendarContainer from './MonthCalendar/MonthCalendarContainer';
import WeekCalendarContainer from './WeekCalendar/WeekCalendarContainer';
import DayViewContainer from './DayView/DayViewContainer';
import DraftShiftsModal from './DraftShiftsModal';
import ShiftDetails from './ShiftDetails';
import EditTemplate from './Templates/EditTemplate';
import BlankTemplate from './Templates/BlankTemplate';
import ApplyTemplate from './Templates/ApplyTemplate';
import TemplatesList from './Templates/TemplatesList';
import CandidateProfileModal from '../BankManagement/CandidateProfileModal';

const ShiftRoutes = ({ history, match }) => {

  const shiftsRootUrl = match.path;

  const dispatch = useDispatch();
  const isSupplier = useSelector(state => state.global.orgConfig?.features?.supplier);
  const currentOrgKey = useSelector(state => state.global.currentOrgKey);
  const supplierKey = isSupplier ? currentOrgKey : null;
  const periodRef = useRef(null);

  return (
    <Switch>

      <Route
        exact
        path={`${shiftsRootUrl}/:shiftReleaseStatus(bank|agency-approval|agency-release)?`}
        render={() => <Redirect to={`${shiftsRootUrl}/month/${moment().format('YYYY/MM/DD')}`} />}
      />

      <Route
        path={`${shiftsRootUrl}/:periodDuration(month|week|two-weeks|week-am-pm)?/:showDay(day)?/:year([0-9]{4})/:month([0-9]{2})?/:day([0-9]{2})?/:applyTemplate(apply-template)?/:modal(confirm-drafts)?/:shiftType(drafts)?/:shiftKeyOrTemplateKey?/:view(details|bookings|audit|edit)?/:candidateId?`}
        render={(routeProps) => {
          const { year, week, month, day, modal, shiftType, shiftKeyOrTemplateKey, view, candidateId, applyTemplate } = routeProps.match.params;

          let shiftKey = null;
          let templateKey = null;

          if (shiftKeyOrTemplateKey && applyTemplate) templateKey = shiftKeyOrTemplateKey;
          if (shiftKeyOrTemplateKey && !applyTemplate) shiftKey = shiftKeyOrTemplateKey;

          const showDay = !!routeProps.match.params.showDay;
          const periodDuration = routeProps.match.params.periodDuration ?? 'month';
          const periodDurationWithDay = showDay ? 'day' : periodDuration;

          const currentDate = moment({ year: parseInt(year, 10), month: parseInt(month, 10) - 1, day: day ? parseInt(day, 10) : undefined });
          const period = createPeriod(currentDate, showDay ? 'day' : periodDuration);
          periodRef.current = period;
          const shiftIsDraft = (shiftType === 'drafts');
          const inDraftsModal = (modal === 'confirm-drafts');

          const calendarViewUrl = `${shiftsRootUrl}/${periodDuration}/${year}/${month}`;
          const goToShiftsRoot = () => history.push(shiftsRootUrl);
          const goToCalendarView = () => history.push(calendarViewUrl);

          const goToPeriodView = (newDate = currentDate, newPeriodDuration = periodDuration) => {
            if (newPeriodDuration === 'day') history.push(`${shiftsRootUrl}/${periodDuration}/day/${newDate.format('YYYY/MM/DD')}`);
            else history.push(`${shiftsRootUrl}/${newPeriodDuration}/${(!newPeriodDuration && showDay) ? 'day/' : ''}${newDate.format('YYYY/MM/DD')}`);
          };
          const goToApplyTemplatePeriodView = (newDate = currentDate, tempKey) => history.push(`${shiftsRootUrl}/week/${newDate.format('YYYY/MM/DD')}/apply-template${tempKey ? `/${tempKey}` : ''}`);
          const goToTemplatesListView = () => history.push(`${shiftsRootUrl}/templates`);
          const goToDayView = (newDate = currentDate, newPeriodDuration = periodDuration) => history.push(`${shiftsRootUrl}/${newPeriodDuration}/day/${newDate.format('YYYY/MM/DD')}`);
          const goToShiftTab = (newDate = currentDate, shiftId = shiftKey, isDraft = false, tab) => {
            const actualTab = tab ?? (isDraft ? 'edit' : (view ?? 'details'));
            return history.push(`${shiftsRootUrl}/${periodDuration}/${showDay ? 'day/' : ''}${newDate.format('YYYY/MM/DD')}/${inDraftsModal ? 'confirm-drafts/' : ''}${isDraft ? 'drafts/' : ''}${shiftId}/${actualTab}`);
          };
          const goToListViewWithShiftTab = (newDate = currentDate, shiftId = shiftKey, isDraft = false, tab) => {
            return history.push(`${shiftsRootUrl}/${periodDuration}/${showDay ? 'day/' : ''}${newDate.format('YYYY/MM/DD')}/${isDraft ? 'drafts/' : ''}${shiftId}/${tab}`);
          };
          const goToShift = (dayDate = currentDate, shiftId = shiftKey, shiftIsDraft2) => goToShiftTab(dayDate, shiftId, shiftIsDraft2, 'details');
          const goToConfirmDraftsView = () => history.push(`${shiftsRootUrl}/${periodDuration}/${showDay ? 'day/' : ''}${currentDate.format('YYYY/MM/DD')}/confirm-drafts`);
          const goToApplicantsModal = canId => history.push(`${shiftsRootUrl}/${periodDuration}/${showDay ? 'day/' : ''}${currentDate.format('YYYY/MM/DD')}/${inDraftsModal ? 'confirm-drafts/' : ''}${shiftKey}/bookings/${canId}`);
          const replaceToTemplateEditView = (tempKey) => history.replace(`${shiftsRootUrl}/templates/${tempKey}/edit`);
          const nav = { goToShiftsRoot, goToCalendarView, goToPeriodView, goToApplyTemplatePeriodView, goToDayView, goToShift, goToShiftTab, goToConfirmDraftsView, goToApplicantsModal, goToListViewWithShiftTab, replaceToTemplateEditView, goToTemplatesListView };

          const filterName = isSupplier ? 'supplier' : 'all';
          const shiftFilters = {
            bank: shift => !shift.agencyRequested && !shift.approvedForAgency,
            'agency-approval': shift => shift.agencyRequested && !shift.approvedForAgency,
            'agency-release': shift => shift.agencyRequested && shift.approvedForAgency,
            all: () => true,
            supplier: (shift, jobDetails) => {
              if (jobDetails) return (shift.agencies && shift.agencies[supplierKey]) || (shift.autoVendedAgencies && shift.autoVendedAgencies[supplierKey]);
              return shift.suppliers && shift.suppliers[supplierKey];
            },
          };

          // This is passed down the component hierarchy and is often invoked after a network request such as booking a candidate.
          // If the component which references the callback is no longer rendered (eg if ShiftDetails has been closed), then the prop won't update
          // and an old version of the callback will be called, which captures an outdated period.
          // We use a ref to ensure that even old versions of the callback has access to the latest period value.
          const refreshShiftsListView = () => {
            if (inDraftsModal) dispatch(refreshRecentlyCreatedShifts());
            const currentPeriod = periodRef.current;
            dispatch(getShifts(currentPeriod.startDate.toISOString(), currentPeriod.endDate.clone().endOf('day').toISOString(), false, true));
          };

          const showApplyTemplate = applyTemplate && !showDay && periodDuration === 'week';

          return (
            <>
              {inDraftsModal &&
                <DraftShiftsModal
                  selectedJob={shiftKeyOrTemplateKey}
                  sidePanelTab={view}
                  closeDraftShiftsView={() => goToPeriodView(currentDate, periodDurationWithDay)}
                  closeSidePanel={() => goToConfirmDraftsView()}
                  nav={nav}
                  selectedDate={currentDate}
                  year={parseInt(year, 10)}
                  month={parseInt(month, 10)}
                />
              }

              {shiftKey && (
                <ShiftDetails
                  isDraft={shiftIsDraft}
                  jobId={shiftKey}
                  sidePanelTab={view}
                  onTabClick={tab => goToShiftTab(currentDate, shiftKey, shiftIsDraft, tab)}
                  closeSidePanel={() => (inDraftsModal ? goToConfirmDraftsView() : goToPeriodView(currentDate, periodDurationWithDay))}
                  goToApplicantsModal={goToApplicantsModal}
                  showWarningIfDateNotInPeriod={(modal !== 'confirm-drafts') && period}
                  showWarningIfFiltersDontMatch={(modal !== 'confirm-drafts')}
                  nav={nav}
                  period={period}
                  openDraftShiftView={nav.goToConfirmDraftsView}
                  onShiftChange={refreshShiftsListView}
                  goToEditShift={() => goToShiftTab(currentDate, shiftKey, shiftIsDraft, 'edit')}
                  goToDetailsTab={() => goToShiftTab(currentDate, shiftKey, shiftIsDraft, 'details')}
                />
              )}

              {candidateId && (
                <CandidateProfileModal
                  isOpen
                  candidateId={candidateId}
                  onRequestClose={() => goToShiftTab(currentDate, shiftKey, shiftIsDraft, 'bookings')}
                  onShiftChange={refreshShiftsListView}
                  offsetRight={400}
                />
              )}

              {!!showDay &&
                <DayViewContainer
                  year={parseInt(year, 10)}
                  month={currentDate.month() + 1}
                  day={parseInt(day, 10)}
                  selectedDate={currentDate}
                  selectedShift={!inDraftsModal && shiftKey}
                  selectedShiftIsDraft={!inDraftsModal && shiftIsDraft}
                  sidePanelTab={!inDraftsModal && view}
                  shiftFilter={shiftFilters[filterName]}
                  nav={nav}
                />
              }

              {!showDay &&
                <>
                  {periodDuration === 'month' ?
                    <MonthCalendarContainer
                      shiftFilter={shiftFilters[filterName]}
                      year={parseInt(year, 10)}
                      month={parseInt(month, 10)}
                      selectedMonth={currentDate}
                      nav={nav}
                      isSupplier={isSupplier}
                    />
                    :
                    <WeekCalendarContainer
                      shiftFilter={shiftFilters[filterName]}
                      periodDuration={periodDuration}
                      year={parseInt(year, 10)}
                      week={parseInt(currentDate.format('W'), 10)}
                      selectedWeek={currentDate}
                      selectedShift={!inDraftsModal && shiftKey}
                      nav={nav}
                      isSupplier={isSupplier}
                    />
                  }
                </>
              }

              {showApplyTemplate && (
                <ApplyTemplate
                  templateKey={templateKey}
                  selectedDate={currentDate}
                  closeSidePanel={() => goToPeriodView(currentDate, periodDurationWithDay)}
                  goToPeriodView={goToApplyTemplatePeriodView}
                  periodDuration={periodDuration}
                />
              )}
            </>
          );
        }}
      />

      <Route
        path={`${shiftsRootUrl}/templates/:templateKey/edit/:templateShiftKey?`}
        render={(routeParams) => {
          const { templateKey, templateShiftKey } = routeParams.match.params;
          const goToEditTemplateShift = (shiftKey) => history.push(`${shiftsRootUrl}/templates/${templateKey}/edit/${shiftKey}`);
          const goToTemplateEditView = () => history.replace(`${shiftsRootUrl}/templates/${templateKey}/edit`);
          const goToBlankTemplateView = () => history.replace(`${shiftsRootUrl}/templates/new/edit`);
          const goToApplyTemplatePeriodView = (tempKey) => history.replace(`${shiftsRootUrl}/week/${moment.utc().format('YYYY/MM/DD')}/apply-template/${tempKey}`);
          const goToTemplatesListView = () => history.replace(`${shiftsRootUrl}/templates`);

          if (templateKey === 'new') {
            return (
              <BlankTemplate
                templateKey={templateKey}
                goToEditTemplateShift={goToEditTemplateShift}
                templateShiftKey={templateShiftKey}
                closeEditTemplateShift={goToBlankTemplateView}
                goToTemplateEditView={newTemplateKey => history.replace(`${shiftsRootUrl}/templates/${newTemplateKey}/edit`)}
              />
            );
          }
          return (
            <EditTemplate
              templateKey={templateKey}
              goToEditTemplateShift={goToEditTemplateShift}
              templateShiftKey={templateShiftKey}
              closeEditTemplateShift={goToTemplateEditView}
              goToApplyTemplatePeriodView={goToApplyTemplatePeriodView}
            />
          );
        }}
      />

      <Route
        path={`${shiftsRootUrl}/templates`}
        render={() => {
          const goToTemplateEditView = (templateKey) => history.push(`${shiftsRootUrl}/templates/${templateKey}/edit`);
          return (<TemplatesList goToTemplateEditView={goToTemplateEditView} />);
        }}
      />
    </Switch>
  );
};


export default ShiftRoutes;
