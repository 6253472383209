import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';
import { firstBy } from 'thenby';
import { MdDelete } from 'react-icons/md';

import * as templateThunks from 'thunks/templates';
import { clearTemplatesListError } from 'reducers/templates';
import colors from 'config/colors';
import spacing from 'config/spacing';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { capitalize } from 'lib/helpers';
import { useFeatureFlag } from 'hooks/feature';
import { Template, TemplatePeriod } from 'types/ShiftTypes';

import Loading from 'components/Loading';
import Button from 'components/Button';
import DataTable, { DataTableColumnDefinition } from 'components/DataTable';
import ConfirmModal from 'components/ConfirmModal';

import TemplatesPage from './TemplatesPage';

const styles = {
  actionButton: {
    display: 'inline-block',
    cursor: 'pointer',
    paddingRight: '6px',
  },
};

interface TemplateListColumns<TemplateShape, AdditionalProps> {
  [key: string]: DataTableColumnDefinition<TemplateShape, AdditionalProps>,
}

interface DataTableAdditionalProps {
  deleteTemplate: (templateKey: string) => void,
  adminCanDeleteTemplates: boolean
}

const generateDataTableColumns = (orgTimezone: string) => {

  const columns: TemplateListColumns<Template, DataTableAdditionalProps> = {
    name: {
      header: 'Name',
      name: 'name',
      width: 140,
    },
    periodType: {
      header: 'Period Type',
      name: 'periodType',
      width: 140,
      formatter: (periodType: TemplatePeriod) => capitalize(periodType),
    },
    createdAt: {
      header: 'Created At',
      name: 'createdAt',
      width: 140,
      formatter: (createdAt: string) => {
        return convertFromUtcToTimezone(createdAt, orgTimezone).format('Do MMM YYYY');
      },
    },
    createdByName: {
      header: 'Created By',
      name: 'createdByName',
      width: 140,
    },
    updatedAt: {
      header: 'Updated At',
      name: 'updatedAt',
      width: 140,
      formatter: (updatedAt: string | null) => {
        if (updatedAt) return convertFromUtcToTimezone(updatedAt, orgTimezone).format('Do MMM YYYY');
        return null;
      },
    },
    updatedByName: {
      header: 'Updated By',
      name: 'updatedByName',
      width: 140,
    },
    actions: {
      name: 'actions',
      header: 'Actions',
      width: 50,
      className: () => 'p-hide',
      formatter: (template, index, additionalProps) => {
        if (!additionalProps.adminCanDeleteTemplates) return null;

        return (
          <div onClick={(e) => { additionalProps.deleteTemplate(template.key); e.stopPropagation(); }} title="Delete Template" className="rise" style={styles.actionButton}>
            <MdDelete size={18} />
          </div>
        );
      },
    },
  };

  return Object.entries(columns).map(([, column]) => column);
};

interface TemplatesListProps {
  goToTemplateEditView: (templateKey: string) => void,
}

export default function TemplatesList(props: TemplatesListProps) : React.ReactElement {

  const dispatch = useAppDispatch();
  const isFetchingTemplates = useSelector(({ templates }) => templates.isFetchingTemplates);
  const fetchTemplateListError = useSelector(({ templates }) => templates.fetchTemplateListError);
  const templateList = useSelector(({ templates }) => templates.templateList);
  const isDeletingTemplate = useSelector(state => state.templates.isDeletingTemplate);
  const deleteTemplateError = useSelector(state => state.templates.deleteTemplateError);
  const orgTimezone = useSelector(state => state.global.orgConfig?.timezone);
  const adminCanDeleteTemplates = useFeatureFlag(null, 'jobsCanDeleteJobs');

  const [selectedTemplateKey, setSelectedTemplateKey] = useState<string | null>(null);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);

  useEffect(() => {
    dispatch(templateThunks.fetchTemplateList());

    return () => {
      if (fetchTemplateListError) dispatch(clearTemplatesListError());
    };
  }, []);

  const deleteTemplate = useCallback(async () => {
    if (selectedTemplateKey) {
      setConfirmDeleteModalIsOpen(false);
      const success = await dispatch(templateThunks.deleteTemplate(selectedTemplateKey));
      if (success) {
        setSelectedTemplateKey(null);
        dispatch(templateThunks.fetchTemplateList());
      }
    }
  }, [selectedTemplateKey]);

  const dataTableColumns = useMemo(() => generateDataTableColumns(orgTimezone), [orgTimezone]);
  const additionalProps = useMemo(() => ({
    deleteTemplate: (templateKey: string) => {
      setSelectedTemplateKey(templateKey);
      setConfirmDeleteModalIsOpen(true);
    },
    adminCanDeleteTemplates,
  }), [adminCanDeleteTemplates]);
  const selectedTemplate = useMemo(() => (templateList ?? []).find((template: Template) => template.key === selectedTemplateKey), [selectedTemplateKey]);


  if (isFetchingTemplates || isDeletingTemplate) {
    return (
      <TemplatesPage>
        <div style={{ display: 'flex', flex: 1 }}>
          <Loading flex />
        </div>
      </TemplatesPage>
    );
  }

  if (fetchTemplateListError) {
    return (
      <TemplatesPage>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: colors.red, marginBottom: spacing.base }}>{fetchTemplateListError}</p>
          <Button onClick={() => dispatch(templateThunks.fetchTemplateList())} outline>Retry</Button>
        </div>
      </TemplatesPage>
    );
  }

  if (deleteTemplateError) {
    return (
      <TemplatesPage>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: colors.red, marginBottom: spacing.base }}>{deleteTemplateError}</p>
          <Button onClick={deleteTemplate} outline>Retry</Button>
        </div>
      </TemplatesPage>
    );
  }

  return (
    <TemplatesPage goToTemplateEditView={props.goToTemplateEditView}>
      <div style={{ flex: '1', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {(templateList ?? []).length === 0 ?
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: colors.text }}>There are currently no templates to view</p>
          </div>
          :
          <div style={{ display: 'flex', overflow: 'auto' }}>
            <DataTable
              rows={(templateList ?? []).sort(firstBy('createdAt').thenBy('updatedAt'))}
              columns={dataTableColumns}
              style={{ minWidth: '840px' }}
              overrideHeadingStyles={{
                color: '#666666',
                textTransform: 'none',
              }}
              onRowClick={(key) => props.goToTemplateEditView(key as string)}
              additionalProps={additionalProps}
            />
          </div>
        }
      </div>
      <ConfirmModal
        isOpen={confirmDeleteModalIsOpen}
        onClose={() => setConfirmDeleteModalIsOpen(false)}
        header={`Delete ${selectedTemplate?.name ?? 'Template'}`}
        textContent={`Are you sure you wish to delete ${selectedTemplate?.name ?? 'the selected template'}?`}
        confirmFunction={deleteTemplate}
        cancelFunction={() => setConfirmDeleteModalIsOpen(false)}
      />
    </TemplatesPage>
  );
}
