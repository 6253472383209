import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdFileUpload } from 'react-icons/md';
import RadioButtonList from 'components/RadioButtonList';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import colors from '../../../config/colors';
import spacing from '../../../config/spacing';
import fonts from '../../../config/fonts';
import { buildOrgComplianceLevelsRadioListItems } from '../../../lib/helpers-compliance';

const styles = {
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    marginTop: spacing.base,
    marginBottom: spacing.base,
    width: '100%',
    textAlign: 'center',
  },
  button: {
    width: '100%',
  },
  closeButton: {
    width: '100%',
    backgroundColor: colors.negative,
    marginTop: spacing.small,
  },
  details: {
    color: colors.text,
    lineHeight: 1,
    marginBottom: spacing.xlarge,
  },
  chooseContainer: {
    height: 136,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  finishContainer: {
    height: 251,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const { func } = PropTypes;
class BulkUploadModal extends Component {
  static propTypes = {
    onClose: func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedKey: props.items && props.items.length > 0 ? props.items[0].key : null,
      choose: false,
      submitted: false,
    };
  }

  close = () => {
    this.props.onClose();
  }

  render() {
    // TODO: make this actually do something
    return (
      <Modal
        isOpen
        onRequestClose={this.close}
        contentLabel="Bulk upload"
        contentStyle={{ maxWidth: 400 }}
      >
        {this.state.submitted ?
          <div>
            <h2 style={styles.header}>Bulk Upload</h2>
            <div style={styles.finishContainer}>
              <p style={styles.details}>Thank you for submitting your upload for invites to Dagny.</p>
              <p style={styles.details}>Our customer support team will let you know when your file has been imported.</p>
            </div>
            <Button
              style={styles.button}
              onClick={this.close}
            >
              Finish
            </Button>
          </div>
          :
          <div>
            <h2 style={styles.header}>Bulk Upload</h2>
            {this.state.choose ?
              <div>
                <p style={styles.details}>Now you need to select your import file. The file must be a CSV or Excel Spreadsheet (.xls, xlsx). The format is simply one column with a list of mobile phone numbers.</p>
                <div style={styles.chooseContainer}>
                  <Button>
                    <MdFileUpload style={{ color: colors.white, marginRight: spacing.base }} />
                    Choose File
                  </Button>
                </div>
                <Button
                  style={styles.button}
                  onClick={() => this.setState({ submitted: true })}
                >
                  Submit
                </Button>
                <Button
                  style={styles.closeButton}
                  onClick={() => this.setState({ choose: false })}
                >
                  Back
                </Button>
              </div>
              :
              <div>
                <p style={styles.details}>Please select the level of compliance you wish these candidates to perform.</p>
                <RadioButtonList
                  items={buildOrgComplianceLevelsRadioListItems(this.props.orgComplianceLevels)}
                  selectedKey={this.state.selectedKey}
                  onSelect={selectedKey => this.setState({ selectedKey })}
                  vertical
                />
                <Button
                  style={styles.button}
                  onClick={() => this.setState({ choose: true })}
                  disabled={!this.state.selectedKey}
                >
                  Next
                </Button>
                <Button
                  style={styles.closeButton}
                  onClick={this.close}
                >
                  Close
                </Button>
              </div>
            }
          </div>
        }
      </Modal>
    );
  }
}

function mapStateToProps({ global }) {
  return {
    orgComplianceLevels: global.orgComplianceLevels,
  };
}

export default connect(mapStateToProps)(BulkUploadModal);
