import React, { useRef } from 'react';
import { MdExpandMore } from 'react-icons/md';
import classNames from 'classnames';

import CWSelect from './CWSelect';
import Checkbox from '../Checkbox';

interface CWSelectItem {
  value: string;
  label: string;
  isSelected?: boolean;
}

interface CWShiftFormSelectProps {
  items: Array<CWSelectItem>;
  selectedItems: Array<string>;
  setSelectedItems: (items: Array<string>) => unknown
  placeholder?: string,
  isMulti?: boolean,
  isValid?: boolean,
  disabled?: boolean
}

export default function CWShiftFormSelect(props: CWShiftFormSelectProps): React.ReactElement {

  const buttonRef = useRef<any>();

  return (
    <CWSelect
      items={props.items}
      selectedItems={props.selectedItems}
      setSelectedItems={props.setSelectedItems}
      noneSelectedLabel={props.placeholder ?? 'None Selected'}
      truncateButtonLabel
      buttonRef={buttonRef}
      minWidth={294}
      disabled={props.disabled}
      button={({ title, onClick, isOpen }) => (
        <div
          ref={buttonRef}
          role="button"
          tabIndex={0}
          className={classNames('shift-form-select__container', {
            'shift-form-select__container--open': isOpen,
            'shift-form-select__container--invalid': !props.isValid,
          })}
          onClick={onClick}
        >
          <p
            className={classNames('shift-form-select__value-text', {
              'shift-form-select__value-text--normal': props.selectedItems.length > 0,
              'shift-form-select__value-text--placeholder': props.selectedItems.length === 0,
            })}
          >
            {title}
          </p>
          <div className="shift-form-select__dropdown-container">
            <div className="shift-form-select__divider" />
            <MdExpandMore className="shift-form-select__chevron" size={24} />
          </div>
        </div>
      )}
      menuItem={({ item, closeMenu, selectSingleItem, deselectItem, selectItem, isMultiSelecting, isFocussed }) => (
        <CWSelectItem
          item={item}
          closeMenu={closeMenu}
          selectSingleItem={selectSingleItem}
          deselectItem={deselectItem}
          selectItem={selectItem}
          isMultiSelecting={isMultiSelecting}
          isFocussed={isFocussed}
          isMulti={props.isMulti}
        />
      )}
    />
  );
}

CWShiftFormSelect.defaultProps = { isMulti: true, placeholder: undefined, isValid: true, disabled: false };

interface CWSelectItemProps {
  item: CWSelectItem;
  closeMenu: (shouldFocusButton?: boolean) => void;
  selectSingleItem: (item: CWSelectItem) => void;
  deselectItem: (item: CWSelectItem) => void;
  selectItem: (item: CWSelectItem) => void;
  isMultiSelecting: boolean;
  isFocussed: boolean;
  isMulti?: boolean
}

const CWSelectItem : React.FC<CWSelectItemProps> = (props: CWSelectItemProps) => {

  const { item, closeMenu, selectSingleItem, deselectItem, selectItem, isMultiSelecting, isFocussed } = props;

  return (
    <div
      key={item.value}
      role="button"
      tabIndex={0}
      data-select-menu-item={item.value}
      className={classNames('cw-select__item', {
        'cw-select__item--selected': item.isSelected,
        'cw-select__item--focussed': isFocussed,
      })}
      onClick={() => {
        if (isMultiSelecting) {
          selectSingleItem(item);
          closeMenu(false);
        } else {
          if (item.isSelected) {
            deselectItem(item);
          } else {
            selectItem(item);
          }
        }
      }}
      onKeyPress={(event) => {
        const keyCode = event.which || event.keyCode;
        switch (keyCode) {
          case 13: // Enter
            event.preventDefault();
            event.stopPropagation();
            if (isMultiSelecting) {
              selectSingleItem(item);
            }
            closeMenu();
            break;
          case 32: // Space
            event.preventDefault();
            event.stopPropagation();
            if (item.isSelected) {
              deselectItem(item);
            } else {
              selectItem(item);
            }
            break;
          default:
            break;
        }
      }}
      onKeyDown={(event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === 27) closeMenu(); // Escape
      }}
    >
      <Checkbox
        disabled={!props.isMulti}
        size={14}
        boxStyle={{ marginTop: 3, backgroundColor: 'white', borderColor: item.isSelected ? 'white' : '#999' }}
        checked={!!item.isSelected}
        onChange={() => (item.isSelected ? deselectItem(item) : selectItem(item))}
      />
      <div className="cw-select__label">{item.label}</div>
    </div>
  );
};

CWSelectItem.defaultProps = { isMulti: true };
