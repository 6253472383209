import { firstBy } from 'thenby';
import { uniq, pickBy } from 'lodash-es';
import { DraftShiftField } from 'types/ShiftTypes';
import { RgsMetadata, Grades, Specialities, Grade, Speciality, ServiceMetadata } from 'types/Metadata';

type SelectOption = { label: string, value: string };

export function getRoleOptions(service: ServiceMetadata | null, rgsMetadata: RgsMetadata) : Array<{ label: string, value: string }> {

  let roles = Object.entries(rgsMetadata).map(([roleKey, role]) => ({ value: roleKey, label: role.name }));
  if (service?.hasRestrictedRgs) {
    const serviceRoleKeys = Object.keys(service.rgs);
    roles = roles.filter((role: SelectOption) => serviceRoleKeys.includes(role.value));
  }

  return roles;
}


export function getGradeOptions(service: ServiceMetadata | null, role: DraftShiftField | null, rgsMetadata: RgsMetadata): Grades | null {
  let grades = role?.id ? rgsMetadata[role.id].grades : null;

  if (grades && service?.hasRestrictedRgs) {
    const roleKeys = role?.id ? [role?.id] : Object.keys(rgsMetadata);
    const serviceGradeKeys = uniq(roleKeys.flatMap(roleKey => service.rgs?.[roleKey]?.gradeKeys ?? []));

    grades = pickBy(grades, (value: Grade, key: string) => serviceGradeKeys.includes(key));
  }

  return grades;
}

export function getFirstGrade(currentGradeKey: string | null, grades: Grades) : DraftShiftField | null {
  let grade = null;
  if (grades) {
    const firstGrade = Object.keys(grades)[0];
    grade = { id: firstGrade, name: grades[firstGrade].name };
  }
  return grade;
}

export function getSpecialityOptions(service: ServiceMetadata | null, role: DraftShiftField | null, rgsMetadata: RgsMetadata) : Specialities {

  // Set list of possible specialities
  let specialities : Specialities = {};
  if (role?.id) {
    specialities = { ...rgsMetadata[role.id].specialities };
  } else {
    // If no role, include specialities from all roles
    Object.entries(rgsMetadata).forEach(([, { specialities: specs }]) => {
      specialities = { ...specialities, ...specs };
    });
  }

  if (service?.hasRestrictedRgs) {
    const roleKeys = role?.id ? [role?.id] : Object.keys(rgsMetadata);
    const serviceSpecialityKeys = uniq(roleKeys.flatMap(roleKey => service.rgs?.[roleKey]?.specialityKeys ?? []));

    specialities = pickBy(specialities, (value: Speciality, key: string) => serviceSpecialityKeys.includes(key));
  }

  return specialities;
}

export function getSpeciality(currentSpecialityKey: string | null, specialities: Specialities, mandatoryFields: string[]): DraftShiftField | null {

  const existingSpeciality = currentSpecialityKey && specialities[currentSpecialityKey] ? { id: currentSpecialityKey, name: specialities[currentSpecialityKey].name } : null;
  const existingSpecialityValid = !!existingSpeciality;

  if (!existingSpecialityValid) {
    // If existing speciality is no longer valid and speciality is not mandatory, return null
    if (!mandatoryFields.includes('speciality')) {
      return null;
    }

    // If it's no longer valid and speciality is mandatory, return new default speciality
    const newSpeciality = Object.values(specialities)
      .sort(firstBy('order'))
      .slice(0)
      .map(spec => ({ id: spec.key, name: spec.name }))[0]
      ?? null;
    return newSpeciality;
  }

  // Other return existing speciality
  return existingSpeciality;
}
