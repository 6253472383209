import React, { useEffect, useState, useCallback, useMemo, CSSProperties } from 'react';
import { firstBy } from 'thenby';

import colors from 'config/colors';
import { fetchAdmins } from 'lib/api/accounts';
import useApi from 'hooks/useGetApi';

import { AdminGroupPermissions } from 'types/AdminGroup';
import { addAdminGroupMembers } from 'lib/api/admin-groups';

import Modal from 'components/Modal';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DataTable from 'components/DataTable';
import CWTagSelect from 'components/CWSelect/CWTagSelect';

interface GroupPermission {
  key: keyof AdminGroupPermissions;
  name: string;
  default: boolean;
}

const groupPermissions : Array<GroupPermission> = [
  { key: 'canManageMembers', name: 'Manage Group Members', default: false },
];

interface Admin {
  key: string;
  displayName: string;
}

interface Props {
  onClose: () => void,
  adminGroupKey: string,
  existingGroupMembers: Array<string>,
  refreshGroup: () => void,
}
export default function AddMemberModal(props: Props): React.ReactElement {

  // State
  const [selectedStaff, setSelectedStaff] = useState<Array<string>>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<AdminGroupPermissions>(Object.fromEntries(groupPermissions.map(perm => [perm.key, perm.default])) as unknown as AdminGroupPermissions);

  // Data fetching
  const adminFetcher = useCallback(async () => Object.values((await fetchAdmins()).orgAdmins ?? {}).sort(firstBy('displayName' as any)), []) as () => Promise<Array<Admin> | null>;
  const adminList = useApi(adminFetcher);
  useEffect(() => { adminList.fetch(); }, []);

  // Data updating
  const addAdminGroupMembersFetcher = useCallback(async () => {
    const result = await addAdminGroupMembers([props.adminGroupKey], selectedStaff, selectedPermissions);
    props.refreshGroup();
    props.onClose();
    return result;
  }, [props.adminGroupKey, selectedStaff, selectedPermissions]);
  const addMembers = useApi(addAdminGroupMembersFetcher);

  // Computed
  const items = useMemo(() => {
    return adminList.data
      ?.filter(({ key }) => !props.existingGroupMembers.includes(key))
      ?.map(({ key, displayName }) => ({ label: displayName, value: key }));
  }, [adminList.data, props.existingGroupMembers]);

  const additionalPermissionsColumns = useMemo(() => {
    return [
      { name: 'name', header: 'Additional Permissions' },
      {
        name: 'enabled',
        header: 'Enabled?',
        formatter: (name: string, row: GroupPermission) => {
          return (
            <Checkbox
              size={18}
              checked={selectedPermissions[row.key]}
              onChange={(value) => setSelectedPermissions({ ...selectedPermissions, [row.key]: value })}
            />
          );
        },
      },
    ];
  }, [selectedPermissions]);


  if (adminList.isLoading) {
    return (
      <Modal isOpen vflex header="Add Group Member" onRequestClose={props.onClose} bodyStyle={{ width: 600, padding: 12, color: colors.text }}>
        <Loading flex />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      vflex
      header="Add Group Member"
      onRequestClose={props.onClose}
      bodyStyle={{ width: 500, justifyContent: 'start', fontSize: '0.9rem' }}
    >
      {!!items?.length && (
        <>
          <div style={{ padding: 12 }}>
            <p>Administrators to add:</p>
            <CWTagSelect items={items} selectedItems={selectedStaff} setSelectedItems={setSelectedStaff} placeholder="Select admins..." />
          </div>
          <div style={{ flex: 1, overflow: 'auto', borderTop: `1px solid ${colors.cavalry.line}` }}>
            <DataTable<GroupPermission, undefined>
              columns={additionalPermissionsColumns as any}
              rows={groupPermissions}
              cellStyle={{ color: '#333' }}
              overrideHeadingStyles={{
                color: colors.text,
                textTransform: 'none',
                fontSize: '0.9rem',
              }}
            />
          </div>
          <div className="space-children-12" style={{ borderTop: `1px solid ${colors.cavalry.line}`, display: 'flex', justifyContent: 'end', padding: 12 }}>
            <Button white outline onClick={props.onClose}>Cancel</Button>
            <Button positive onClick={addMembers.fetch}>Confirm</Button>
          </div>
        </>
      )}
      {!!(adminList.data?.length && !items?.length) && (
        <>
          <p style={{ padding: 12, textAlign: 'center' }}>All admins have already been added to this group</p>
        </>
      )}
    </Modal>
  );
}
