import firebase from 'firebase/app';
import 'firebase/firestore';
import { connect } from './auth';
import { track } from './tracking';
import { firebaseConfig, FIREBASE_EMULATOR_HOST } from '../config/settings';

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

if (FIREBASE_EMULATOR_HOST) {
  firebase.firestore().useEmulator(...FIREBASE_EMULATOR_HOST.split(':'));
}

const firestore = firebase.firestore();

const trackCall = (method, callerInfo) => {
  track({ collection: 'methodTracking', source: 'webApp', method, callerInfo });
};

let reportError = (error, opts) => console.error(error, opts);
export const setErrorHandler = (errorHandler) => {
  reportError = errorHandler;
};

export const findAll = async (collectionName, queries, asObjectGraph, callerInfo, orderBy) => {
  trackCall(`orm.findAll(${collectionName})`, callerInfo);
  const executionTime = Date.now();
  await connect();
  const rows = [];
  const objectGraph = {};
  let rowCount = 0;

  try {
    const collectionRef = firestore.collection(collectionName);
    let collectionQuery = collectionRef;
    if (queries && queries.length > 0) {
      queries.forEach((query) => {
        let prop = null;
        let op = null;
        let value = null;
        if (Array.isArray(query)) {
          [prop, op, value] = query;
        } else {
          prop = query.prop; // eslint-disable-line
          op = query.op; // eslint-disable-line
          value = query.value; // eslint-disable-line
        }
        collectionQuery = collectionQuery.where(prop, op, value);
      });
    }
    if (orderBy) collectionQuery = collectionQuery.orderBy(orderBy);
    const querySnapshot = await collectionQuery.get();
    querySnapshot.forEach((doc) => {
      if (asObjectGraph) {
        objectGraph[doc.id] = { id: doc.id, ...doc.data() };
      } else {
        rows.push({ id: doc.id, ...doc.data() });
      }
      rowCount += 1;
    });
  } catch (e) {
    console.error(`orm.findAll(${collectionName}) failed`, e);
    reportError(new Error(`orm.findAll(${collectionName}) failed`), {
      metaData: {
        query: { collectionName, queries, callerInfo },
        error: {
          code: e.code,
          message: e.message,
        },
      },
    });
  }
  const duration = Date.now() - executionTime;
  console.debug('debug', `orm.findAll(${collectionName}) END took ${duration}ms, ${rowCount} found`);

  return asObjectGraph ? objectGraph : rows;
};

export const findOne = async (collectionName, queries, callerInfo) => {
  trackCall(`orm.findOne(${collectionName})`, callerInfo);
  const results = await findAll(collectionName, queries, false, 'from orm.findOne');
  return results.length > 0 ? results[0] : null;
};

export const findById = async (collectionName, id, callerInfo) => {
  trackCall(`orm.findById(${collectionName}, ${id})`, callerInfo);
  const executionTime = Date.now();
  await connect();
  try {
    const docRef = firestore.collection(collectionName).doc(id);
    const snap = await docRef.get();

    const duration = Date.now() - executionTime;
    console.debug(`orm.findById(${collectionName}) took ${duration}ms`);

    return snap.exists ? { id: snap.id, ...snap.data() } : null;
  } catch (e) {
    console.error('orm.findById failed', e);
    reportError(new Error(`orm.findById(${collectionName}.${id}) failed`), {
      metaData: {
        query: { collectionName, id, callerInfo },
        error: {
          code: e.code,
          message: e.message,
        },
      },
    });
    return null;
  }
};

export const watch = async (collectionName, queriesOrDocId, callback, asObjectGraph, callerInfo, orderBy, orderByDirection, limit) => {

  trackCall(`orm.watch(${collectionName})`, callerInfo);
  const executionTime = Date.now();
  let duration = -1;
  await connect();
  let rows = [];
  let objectGraph = {};
  let docResult = false;

  console.debug('orm.watch START');

  return new Promise((resolve) => {
    const collectionRef = firestore.collection(collectionName);
    let collectionQuery = collectionRef;
    if (queriesOrDocId) {
      if (Array.isArray(queriesOrDocId)) {
        queriesOrDocId.forEach((query) => {
          let prop = null;
          let op = null;
          let value = null;
          if (Array.isArray(query)) {
            [prop, op, value] = query;
          } else {
            prop = query.prop; // eslint-disable-line
            op = query.op; // eslint-disable-line
            value = query.value; // eslint-disable-line
          }
          collectionQuery = collectionQuery.where(prop, op, value);
        });
      } else if (typeof queriesOrDocId !== 'object') {
        docResult = true;
        collectionQuery = collectionQuery.doc(queriesOrDocId);
      }
    }
    if (orderBy) collectionQuery = collectionQuery.orderBy(orderBy, orderByDirection);
    if (limit) collectionQuery = collectionQuery.limit(limit);
    const unsubscribe = collectionQuery.onSnapshot((snapshot) => {
      if (duration === -1) {
        duration = Date.now() - executionTime;
        console.debug(`orm.watch(${collectionName}) took ${duration}ms`);
      }
      if (docResult) {
        callback(snapshot.exists ? { id: queriesOrDocId, ...snapshot.data() } : null);
      } else {
        objectGraph = {};
        rows = [];
        snapshot.forEach((doc) => {
          if (asObjectGraph) {
            objectGraph[doc.id] = doc.data();
          } else {
            rows.push({ id: doc.id, ...doc.data() });
          }
        });
        callback(asObjectGraph ? objectGraph : rows, snapshot.docChanges);
        rows = [];
        objectGraph = {};
      }
      resolve(unsubscribe);
    }, (e) => {
      console.error('orm.watch failed', e);
      reportError(new Error(`orm.watch(${collectionName}) failed`), {
        metaData: {
          query: { collectionName, queriesOrDocId, callerInfo },
          error: {
            code: e.code,
            message: e.message,
          },
        },
      });
      callback(asObjectGraph ? objectGraph : rows);
      resolve(unsubscribe);
    });
  });
};
