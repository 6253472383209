import { CSSProperties } from 'react';
import colors from 'config/colors';
import fonts from 'config/fonts';
import spacing from 'config/spacing';

export const fieldStyles : Record<string, CSSProperties> = {
  input: {
    boxSizing: 'border-box',
    width: '100%',
    height: 50,
    padding: '6px 12px',
    marginBottom: 12,
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 2,
    fontWeight: 400,
    fontSize: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 'none',
    color: colors.text,
  },
  error: {
    color: 'colors.white',
    fontFamily: fonts.copy.family,
    fontSize: fonts.sub.size,
    fontWeight: fonts.sub.weight,
    width: '100%',
  },
};
