import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import Loading from 'components/Loading';
import DashboardHeader from '../Dashboard/DashboardHeader';
import ByRoleReport from '../Reports/ByRoleReport';
import DrillDownTable from './DrillDownTable';
import ReportingHeader from '../ReportingHeader';

import './styles.scss';

const getHeader = (reportName) => {
  switch (reportName) {
    case 'spend':
      return 'Temporary Staffing Spend By Role';
    case 'requests':
      return 'Shift Requests By Role';
    case 'fillRate':
      return 'Fill Rate By Role';
    default:
      return '';
  }
};

export default function DrillDown(props) {

  const { ReportingComponent } = props;

  const byRoleReportName = `${props.reportName}ByRole`;

  const isloadingReports = props.loading[props.reportName] || props.loading[`${props.reportName}ByRole`];

  return (
    <Page vflex title="Reporting" subTitle={props.reportName}>
      <ReportingHeader selectedTabKey={props.reportName} />
      <DashboardHeader />

      {isloadingReports && <Loading flex />}

      {!isloadingReports && (
        <div className="v-flex-1 scrollable">

           {/* Render reporting chart */}
          <ReportingComponent
            report={props.reports[props.reportName]}
            durationBetweenDates={props.durationBetweenDates}
            loading={props.loading[props.reportName]}
            clearReportingError={props.clearReportingError}
            reportError={props.reportError[props.reportName]}
            drillDownComponent={(reportProps) => {
              return (
                <DrillDownTable {...reportProps} />
              );
            }}
          />

          {/* Render ByRoleReport bar chart */}
          {props.roleBreakdown && (
            <ByRoleReport
              isloadingReports={isloadingReports}
              report={props.reports[byRoleReportName]}
              onBarClick={props.roleBreakdownBarClick}
              reportName={props.reportName}
              reportTitle={props.reportTitle}
              byRoleReportName={byRoleReportName}
              reportError={props.reportError[byRoleReportName]}
              clearReportingError={props.clearReportingError}
              header={getHeader(props.reportName)}
              drillDownComponent={(reportProps) => {
                return (
                  <DrillDownTable {...reportProps} />
                );
              }}
            />
          )}
        </div>
      )}
    </Page>
  );
}

const { object, objectOf, shape, bool, oneOfType, number, node, element, func, string } = PropTypes;

DrillDown.propTypes = {
  reports: objectOf(object).isRequired,
  loading: shape({
    fillRate: bool.isRequired,
    fillRateByRole: bool.isRequired,
    fillRateBySpeciality: bool.isRequired,
    requests: bool.isRequired,
    requestsByRole: bool.isRequired,
    requestsBySpeciality: bool.isRequired,
    spend: bool.isRequired,
    spendByRole: bool.isRequired,
    spendBySpeciality: bool.isRequired,
    activity: bool.isRequired,
  }).isRequired,
  roleBreakdown: bool.isRequired,
  roleBreakdownBarClick: func.isRequired,
  ReportingComponent: oneOfType([node, element, object, func]).isRequired,
  durationBetweenDates: number.isRequired,
  reportName: string.isRequired,
  clearReportingError: func.isRequired,
  reportError: shape({
    fillRate: string,
    fillRateByRole: string,
    fillRateBySpeciality: string,
    requests: string,
    requestsByRole: string,
    requestsBySpeciality: string,
    spend: string,
    spendByRole: string,
    spendBySpeciality: string,
    activity: string,
    reportTitle: string.isRequired,
  }).isRequired,
};
