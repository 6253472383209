const V2_SET_SELECTED_ITEMS = 'V2_SET_SELECTED_ITEMS';
const V2_SET_ALL_SELECTED_ITEMS = 'V2_SET_ALL_SELECTED_ITEMS';

// Array of action type to trigger persisting to localstorage
export const PERSIST_ACTIONS = [
  V2_SET_SELECTED_ITEMS,
  V2_SET_ALL_SELECTED_ITEMS,
];

export function v2SetSelectedItems(namespace, filterKey, items) {
  return {
    type: V2_SET_SELECTED_ITEMS,
    namespace,
    filterKey,
    items,
  };
}

export function v2SetAllSelectedItems(v2SelectedItems) {
  return {
    type: V2_SET_ALL_SELECTED_ITEMS,
    v2SelectedItems,
  };
}

const initialState = {
  v2SelectedItems: {},
};

export default function calendar(state = initialState, action) {
  switch (action.type) {

    case V2_SET_SELECTED_ITEMS: {
      return {
        ...state,
        v2SelectedItems: {
          ...state.v2SelectedItems,
          [action.namespace]: {
            ...state.v2SelectedItems[action.namespace],
            [action.filterKey]: action.items,
          },
        },
      };
    }

    case V2_SET_ALL_SELECTED_ITEMS: {
      return {
        ...state,
        v2SelectedItems: action.v2SelectedItems,
      };
    }

    default:
      return state;
  }
}
