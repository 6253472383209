import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../config/colors';

// TODO: get rid of fixed height somehow - it's in a scrollview though

const styles = {
  outerContainer: {
    width: '100%',
  },
  innerContainer: {
    height: '100%',
  },
  message: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
    color: colors.text,
  },
};

export default function EmptyCard(props) {

  return (
    <div style={{ ...styles.outerContainer, ...props.dimensions }}>
      <div style={styles.innerContainer}>
        <div style={styles.message}>{props.message}</div>
      </div>
    </div>
  );
}

const { string, object } = PropTypes;

EmptyCard.propTypes = {
  dimensions: object,
  message: string,
};

EmptyCard.defaultProps = {
  dimensions: {
    height: 600,
    width: '100%',
  },
  message: 'Nothing to see here',
};
