import React from 'react';
import propTypes from 'prop-types';
import Pill from '../Pill';

const ShiftPill = ({ job }) => {
  if (job.isBooked) return <Pill label="Booked" size="tiny" type="positive" />;
  if (job.isApplied) return <Pill label="Applied" size="tiny" type="positive" outline />;
  if (job.hasWithdrawn) return <Pill label="Withdrawn" size="tiny" type="danger" outline />;
  return <Pill label="Unfilled" size="tiny" type="positive" outline />;
};

const { object } = propTypes;

ShiftPill.propTypes = {
  job: object.isRequired,
};

export default ShiftPill;
