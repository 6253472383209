import React, { useState, CSSProperties } from 'react';
import TextAreaAutosize from 'react-autosize-textarea';

import colors from 'config/colors';
import spacing from 'config/spacing';

import Button from 'components/Button';
import Loading from 'components/Loading';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${colors.greyBorder}`,
    padding: spacing.base,
  },
  label: {
    color: colors.text,
    fontWeight: 600,
    fontSize: '1rem',
    paddingBottom: spacing.base,
    lineHeight: 1.4,
  },
};

interface ComposeBatchMessageBarProps {
  sendMessage: (message: string) => void;
  disabled?: boolean;
  autoFocusTextArea?: boolean;
  isLoading: boolean,
}

const ComposeBatchMessageBar: React.FC<ComposeBatchMessageBarProps> = (props: ComposeBatchMessageBarProps) => {
  const { sendMessage, disabled = false, autoFocusTextArea = true, isLoading } = props;
  const [messageText, setMessageText] = useState('');
  const sendDisabled = disabled || !messageText;

  return (
    <div style={styles.container}>
      <p style={styles.label}>Compose Message:</p>
      <div>
        <TextAreaAutosize
          className="messagingComposeMessageTextArea messagingComposeMessageTextArea--long"
          value={messageText}
          onChange={event => setMessageText((event.target as HTMLInputElement).value)}
          autoFocus={autoFocusTextArea}
          maxRows={10}
        />
        <div className="space-children-12" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 6 }}>
          {isLoading && <Loading size={30} />}
          <Button
            disabled={sendDisabled}
            black
            shadow={false}
            onClick={() => {
              sendMessage(messageText);
              setMessageText('');
            }}
          >
            Send Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ComposeBatchMessageBar;
