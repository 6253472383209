import React from 'react';
import { useFeatureFlag } from 'hooks/feature';
import { FeatureFlag } from 'config/featureFlags';
import { Permission } from 'config/permissions';

interface FeatureProps {
  featureFlag?: FeatureFlag;
  permissionRequired?: Permission;
  failureMessage?: JSX.Element;
  children: JSX.Element,
}

const Feature = (props: FeatureProps) : JSX.Element | null => {
  const passed = useFeatureFlag(props.featureFlag || null, props.permissionRequired);
  return passed ? props.children : (props.failureMessage ?? null);
};

export default Feature;
