import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { MdAccountCircle, MdArrowBack } from 'react-icons/md';
import Loading from 'components/Loading';
import MessageButton from 'components/MessageButton';
import ComplianceStatus from 'components/ComplianceStatus';
import colors from 'config/colors';
import fonts from 'config/fonts';
import spacing from 'config/spacing';
import * as candidatePropTypes from 'models/candidate';
import StatusIndicator from './StatusIndicator';
import '../CandidateProfileModal.scss';


function checkCharactersInName(title, limit = 20) {
  if (!title) return false;
  if (title.length > limit) return true;
  return false;
}

const styles = {
  loadingSpinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    color: colors.text,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    clear: 'both',
    paddingTop: 10,
  },
  menuContainer: {
    width: 220,
    padding: 10,
    display: 'flex',
    alignItems: 'space-around',
    justifyContent: 'space-around',
    flexDirection: 'column',
    marginTop: '10%',
    marginLeft: '10px',
  },
  infoContainer: {
    flex: '0 0 80%',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: colors.text,
  },
  role: {
    fontSize: '1rem',
    fontWeight: 500,
    color: colors.text,
    marginRight: spacing.base,
  },
  longName: {
    fontSize: fonts.logo.size,
    fontWeight: fonts.logo.weight,
    color: colors.positive,
  },
  profilePic: {
    color: colors.sidebarLink,
    height: 65,
    width: 65,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: 12,
    textAlign: 'center',
    boxShadow: `${colors.sidebarLink} 0px 3px 16px`,
    backgroundRepeat: 'no-repeat',
  },
  backArrow: {
    color: colors.text,
    height: 40,
    width: 40,
    marginLeft: '-4%',
  },
  profileTitle: {
    display: 'flex',
    height: 50,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
    borderBottom: '1px solid rgba(164, 194, 244, 0.4)',
  },
  profileNameImage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentContainer: {
    width: '77%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(164, 194, 244, 0.4)',
    padding: '8px',
    paddingBottom: '12px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    maxWidth: '280px',
    maxHeight: 45,
    justifyContent: 'space-between',
    textAlign: 'left',
  },
};

function buildStatus(suspended, removedAt) {
  const now = moment.utc().toISOString();

  // Active
  if (!suspended && !removedAt) return [{ status: 'active' }];

  // Removed
  if (removedAt && removedAt < now) return [{ status: 'removed' }];

  // A candidate can be both suspended and pending removal, in which case we return both statuses.
  const statuses = [];
  if (removedAt && removedAt > now) statuses.push({ status: 'pendingRemoval', removedAt });
  if (suspended) statuses.push({ status: 'suspended' });
  return statuses;
}

export default function CandidateProfileHeader(props) {

  const {
    onRequestClose,
    accountIsSuspended,
    isLoadingSuspension,
    // openSuspensionModal, // TODO: map to new button
    profile,
    isFetchingCandidateProfile,
    removedAt,
  } = props;
  if (isFetchingCandidateProfile) {
    return (
      <div style={styles.loadingSpinner}>
        <Loading size={60} />
      </div>
    );
  }
  const showProfileImage = !!profile?.imageUrl;
  const { adminComplianceStatusColor, adminComplianceStatusLabel } = (profile || {});

  return (
    <div className="candidate-profile-header">
      <div style={styles.profileNameImage}>
        <MdArrowBack className="rise" onClick={onRequestClose} style={styles.backArrow} />
        <div
          style={{
            ...styles.profilePic,
            backgroundImage: showProfileImage ? `url(${profile?.imageUrl})` : null,
          }}
        >
          {!showProfileImage ? <MdAccountCircle style={styles.profilePic} /> : null}
        </div>
        <div>
          <h3 style={checkCharactersInName(profile?.name) ? styles.longName : styles.name}>{profile && profile.name ? profile.name : null}</h3>
          <div style={{ display: 'flex', alignItems: 'center' }} className="space-children-12">
            <h3 style={styles.role}>{profile?.roleName}</h3>
            <span>
              <ComplianceStatus fontSize="0.9rem" statusColor={adminComplianceStatusColor} statusLabel={adminComplianceStatusLabel} />
            </span>
            <MessageButton candidateKey={props.candidateId} />
          </div>
        </div>
      </div>
      <div style={styles.buttonContainer} className="m-hide">
        {isLoadingSuspension ?
          <Loading
            size={50}
          />
          :
          <div style={styles.buttonContainer}>
            {buildStatus(accountIsSuspended, removedAt).map((data) => {
              return (
                <StatusIndicator
                  key={data.status}
                  {...data}
                />
              );
            })}
          </div>
        }
      </div>
    </div>
  );
}

const { func, bool, string } = PropTypes;
CandidateProfileHeader.propTypes = {
  RGSTabOpen: bool,
  renderRGSTab: func,
  shiftsTabOpen: bool,
  renderShiftsTab: func,
  handleTabChange: func.isRequired,
  complianceTabOpen: bool,
  renderBasicInfoTab: func,
  renderManageAccountTab: func,
  accountIsSuspended: bool,
  isLoadingSuspension: bool,
  renderComplianceTab: func,
  // openSuspensionModal: func.isRequired,
  candidateProfileData: candidatePropTypes.candidateProfileData,
  checkCharactersInName: func.isRequired,
  basicInformationTabOpen: bool,
  isFetchingCandidateProfile: bool,
  removedAt: string,
  hasSuspendPermission: bool.isRequired,
  hasRemoveFromBankPermission: bool.isRequired,
  ratingsFeatureEnabled: bool.isRequired,
  renderRatingsTab: func.isRequired,
  ratingsTabOpen: bool.isRequired,
  candidateOrgKey: string,
  adminOrgKey: string.isRequired,
};

CandidateProfileHeader.defaultProps = {
  RGSTabOpen: false,
  renderRGSTab: () => { },
  shiftsTabOpen: false,
  renderShiftsTab: () => { },
  complianceTabOpen: false,
  renderBasicInfoTab: () => { },
  renderManageAccountTab: () => { },
  accountIsSuspended: false,
  isLoadingSuspension: false,
  renderComplianceTab: () => { },
  basicInformationTabOpen: false,
  isFetchingCandidateProfile: false,
  candidateProfileData: null,
  removedAt: null,
  candidateOrgKey: null,
};
