import { fetchOrgMetadata } from 'lib/api/accounts';
import {
  addOrgMetadata,
} from 'reducers/global';
import * as api from '../lib/api';
import * as notificationActions from '../reducers/notifications';
import { addAdminSites } from '../reducers/user';
import { fs } from '../lib/db';
import notificationsApi from '../lib/api/notifications';
import safeExec from '../lib/safeExec';
import { reportError } from '../lib/error-reporting';

let removedIds = [];

function buildInitialQuery(specificity, userId) {
  const queries = [];
  if (specificity === 1) {
    // For me is selected
    // Only get notifications with users userId
    queries.push(['recipient', '==', userId]);
  }

  // All is selected
  return queries;
}

let unwatchers = [];
export function watchNotifications(limit = 20) {
  return async (dispatch, getState) => {
    const state = getState();
    const orgKey = state.global && state.global.employerAuthorisedOrgs ? Object.keys(state.global.employerAuthorisedOrgs)[0] : null;
    const authorisedSiteKeys = Object.keys(state.user?.sites ?? []);
    const { userId } = state.user;
    const notificationState = state.notifications;
    const { watching } = notificationState;
    const specificity = notificationState.notificationSpecificity;
    const queries = [
      ['orgKey', '==', orgKey],
      ...notificationState.queries || buildInitialQuery(specificity, userId),
    ];

    if (!watching) {
      // Watch employer notifications if not already watching
      const employerNotificationsCollection = fs().collection('employerNotifications');
      let employerNotificationsQuery = null;
      queries.forEach((query) => {
        if (query.length >= 3 && query.every(param => !!param)) {
          employerNotificationsQuery = employerNotificationsCollection.where(query[0], query[1], query[2]);
        }
      });
      const unwatch = await employerNotificationsQuery
        .orderBy('when', 'desc')
        .limit(limit)
        .onSnapshot((snapshot) => {
          const data = [];
          snapshot.forEach((doc) => {
            if (!removedIds.includes(doc.id)) {
              data.push({ ...doc.data(), id: doc.id });
            }
          });

          if (data.length > 0) {
            // Only show authorised sites
            const silentData = data.filter(notification => notification.silent);
            if (silentData.length) silentData.forEach(notification => dispatch(handleSilentNotification(notification)));
            const filteredData = data.filter(notification => (!notification.siteId || authorisedSiteKeys.includes(notification.siteId)) && !notification.silent);

            dispatch(notificationActions.addNotifications(filteredData));
            removedIds = [];
          } else {
            dispatch(notificationActions.addNotifications(data));
            removedIds = [];
          }

          if (!watching) {
            dispatch(notificationActions.setWatching());
          }
        });

      unwatchers.push(unwatch);
    }
  };
}

function handleSiteNotification() {
  return async (dispatch) => {
    try {
      const response = await api.post('account/site/fetch', { mine: true });
      if (response.status >= 400) {
        const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Error handling request from silent notification';
        throw (error instanceof Error ? error : new Error(error));
      } else {
        const { orgSites } = response.body;
        dispatch(addAdminSites(orgSites));
      }
    } catch (e) {
      reportError(e);
    }
  };
}

function handleServiceNotification() {
  return async (dispatch) => {
    // Fetch services from org-metadata endpoint and update Redux
    const {
      orgAdminGroups,
      suppliers,
      banks,
      rateCards,
      customFields,
      shiftReasons,
      services,
    } = await fetchOrgMetadata();
    dispatch(addOrgMetadata(orgAdminGroups, suppliers, banks, rateCards, customFields, shiftReasons, services));
  };
}

function handleSilentNotification(notification) {
  return async (dispatch) => {
    try {
      dispatch(removeNotification(notification.id));

      switch (notification.type) {
        case 'site': {
          dispatch(handleSiteNotification());
          break;
        }
        case 'service': {
          dispatch(handleServiceNotification());
          break;
        }
        default:
          break;
      }
    } catch (e) {
      reportError(e, notification);
    }
  };
}

export function stopWatchingNotifications(changingLimit = false) {
  return async (dispatch) => {
    dispatch(notificationActions.unsetWatching());
    if (!changingLimit) {
      dispatch(notificationActions.clearNotifications());
    }
    unwatchers.forEach((unwatcher) => {
      safeExec(unwatcher);
    });

    unwatchers = [];
  };
}

export function markNotificationAsRead(id) {
  return async (dispatch) => {
    const response = await notificationsApi.markNotificationAsRead(id);
    if (response.status === 200) dispatch(notificationActions.markNotificationAsRead(id));
  };
}

export function removeNotification(id) {
  return async (dispatch) => {
    dispatch(notificationActions.removeNotification(id));

    await notificationsApi.removeNotification(id);
  };
}

export function removeAllNotifications(ids) {
  return async (dispatch) => {
    removedIds = ids;
    dispatch(notificationActions.clearNotifications());
    await notificationsApi.removeAllNotifications();
  };
}
