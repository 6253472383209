
const ADD_TIMESHEETS = 'ADD_TIMESHEETS';
const IS_APPROVING_TIMESHEETS = 'IS_APPROVING_TIMESHEETS';
const IS_REJECTING_TIMESHEETS = 'IS_REJECTING_TIMESHEETS';
const RESET_TIMESHEET_FILTERS = 'RESET_TIMESHEET_FILTERS';
const FETCH_TIMESHEETS = 'FETCH_TIMESHEETS';
const SET_TIMESHEETS_FILTER_VALUE = 'SET_TIMESHEETS_FILTER_VALUE';
const UPDATE_TIMESHEETS_PAGINATION_STATE = 'UPDATE_TIMESHEETS_PAGINATION_STATE';
const UPDATE_TIMESHEETS = 'UPDATE_TIMESHEETS';
const TIMESHEETS_ERROR = 'TIMESHEETS_ERROR';
const CLEAR_TIMESHEETS_ERROR = 'CLEAR_TIMESHEETS_ERROR';
const ADD_TIMESHEET_REJECT_REASONS = 'ADD_TIMESHEET_REJECT_REASONS';
const FETCHING_TIMESHEET_REJECT_REASONS = 'FETCHING_TIMESHEET_REJECT_REASONS';
const FETCHING_CANDIDATE_RATING_CATEGORIES = 'FETCHING_CANDIDATE_RATING_CATEGORIES';
const ADD_CANDIDATE_RATING_CATEGORIES = 'ADD_CANDIDATE_RATING_CATEGORIES';
const SUBMITTING_CANDIDATE_RATINGS = 'SUBMITTING_CANDIDATE_RATINGS';
const SUBMIT_RATINGS_SUCCESS = 'SUBMIT_RATINGS_SUCCESS';
const FETCHING_TIMESHEET_MESSAGES = 'FETCHING_TIMESHEET_MESSAGES';
const ADD_TIMESHEET_MESSAGES = 'ADD_TIMESHEET_MESSAGES';
const SENDING_MESSAGE = 'SENDING_MESSAGE';
const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
const FETCHING_PAYMENT_RUNS = 'FETCHING_PAYMENT_RUNS';
const SET_PAYMENT_RUNS = 'SET_PAYMENT_RUNS';
const PAYMENT_RUN_LIST_VIEW_ERROR = 'PAYMENT_RUN_LIST_VIEW_ERROR';
const RESET_TIMESHEETS_STATE = 'RESET_TIMESHEETS_STATE';

export const DEFAULT_PAGINATION_LIMIT = 200;

export const resetTimesheetFilters = () => ({ type: RESET_TIMESHEET_FILTERS });

export const fetchTimesheets = () => ({ type: FETCH_TIMESHEETS });

export const setTimesheetsFilterValue = (filterName, filterValue) => ({ type: SET_TIMESHEETS_FILTER_VALUE, filterName, filterValue });

export const updateTimesheetsPaginationState = (offset, limit) => ({ type: UPDATE_TIMESHEETS_PAGINATION_STATE, limit, offset });

export const updateTimesheets = updatedTimesheets => ({ type: UPDATE_TIMESHEETS, updatedTimesheets });

export const addTimesheets = (newTimesheets, moreRowsAvailable, totalCount) => ({ type: ADD_TIMESHEETS, newTimesheets, moreRowsAvailable, totalCount });

export const isApprovingTimesheets = selectedTimesheet => ({ type: IS_APPROVING_TIMESHEETS, selectedTimesheet });

export const isRejectingTimesheets = selectedTimesheet => ({ type: IS_REJECTING_TIMESHEETS, selectedTimesheet });

export const timesheetsError = errorMessage => ({ type: TIMESHEETS_ERROR, errorMessage });

export const clearTimesheetsError = () => ({ type: CLEAR_TIMESHEETS_ERROR });

export const addTimesheetRejectReasons = timesheetRejectReasons => ({ type: ADD_TIMESHEET_REJECT_REASONS, timesheetRejectReasons });

export const fetchingTimesheetRejectReasons = () => ({ type: FETCHING_TIMESHEET_REJECT_REASONS });

export const fetchingCandidateRatingCategories = () => ({ type: FETCHING_CANDIDATE_RATING_CATEGORIES });

export const addCandidateRatingCategories = categories => ({ type: ADD_CANDIDATE_RATING_CATEGORIES, categories });

export const submittingCandidateRatings = () => ({ type: SUBMITTING_CANDIDATE_RATINGS });

export const submitRatingsSuccess = () => ({ type: SUBMIT_RATINGS_SUCCESS });

export const fetchTimesheetMessages = () => ({ type: FETCHING_TIMESHEET_MESSAGES });
export const addTimesheetMessages = messages => ({ type: ADD_TIMESHEET_MESSAGES, messages });

export const sendingMessage = () => ({ type: SENDING_MESSAGE });
export const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
export const clearMessages = () => ({ type: CLEAR_MESSAGES });

export const fetchingPaymentRuns = () => ({ type: FETCHING_PAYMENT_RUNS });
export const setPaymentRuns = paymentRuns => ({ type: SET_PAYMENT_RUNS, paymentRuns });
export const paymentRunListViewError = error => ({ type: PAYMENT_RUN_LIST_VIEW_ERROR, error });

export const resetTimesheetsState = () => ({ type: RESET_TIMESHEETS_STATE });

const initialState = {
  timesheets: [],
  isFetchingTimesheets: false,
  isApprovingTimesheets: false,
  isRejectingTimesheets: false,
  timesheetFilters: {
    status: null,
    candidate: null,
    adminGroup: null,
    speciality: null,
    site: null,
  },
  timesheetsPaginationsOffset: 0,
  timesheetsPaginationLimit: DEFAULT_PAGINATION_LIMIT,
  moreRowsAvailable: false,
  timesheetsTotalCount: 0,
  selectedTimesheetKey: null,
  timesheetsError: '',
  timesheetRejectReasons: null,
  candidateRatingCategories: [],
  fetchingTimesheetRejectReasons: false,
  fetchingTimesheetMessages: false,
  messages: [],
  submittingCandidateRatings: false,
  paymentRuns: null,
  isFetchingPaymentRuns: false,
  paymentRunListViewError: null,
};

export default function timesheets(state = initialState, action) {
  switch (action.type) {
    case RESET_TIMESHEET_FILTERS:
      return { ...state, timesheetFilters: initialState.timesheetFilters };

    case FETCH_TIMESHEETS:
      return { ...state, isFetchingTimesheets: true };

    case ADD_TIMESHEETS:
      return {
        ...state,
        timesheets: action.newTimesheets,
        isFetchingTimesheets: false,
        timesheetsTotalCount: action.totalCount,
        moreRowsAvailable: action.moreRowsAvailable,
      };

    case UPDATE_TIMESHEETS:
      return {
        ...state,
        timesheets: action.updatedTimesheets,
        isApprovingTimesheets: false,
        isRejectingTimesheets: false,
        selectedTimesheetKey: null,
      };

    case SET_TIMESHEETS_FILTER_VALUE: {
      return {
        ...state,
        timesheetFilters: { ...state.timesheetFilters, [action.filterName]: action.filterValue },
      };
    }

    case UPDATE_TIMESHEETS_PAGINATION_STATE:
      return { ...state, timesheetsPaginationsOffset: action.offset, timesheetsPaginationLimit: action.limit };

    case IS_APPROVING_TIMESHEETS:
      return { ...state, selectedTimesheetKey: action.selectedTimesheet, isApprovingTimesheets: true };

    case IS_REJECTING_TIMESHEETS:
      return { ...state, selectedTimesheetKey: action.selectedTimesheet, isRejectingTimesheets: true };

    case TIMESHEETS_ERROR:
      return {
        ...state,
        isApprovingTimesheets: false,
        isRejectingTimesheets: false,
        isFetchingTimesheets: false,
        timesheetsError: action.errorMessage,
      };

    case CLEAR_TIMESHEETS_ERROR:
      return { ...state, timesheetsError: '' };

    case ADD_TIMESHEET_REJECT_REASONS:
      return { ...state, fetchingTimesheetRejectReasons: false, timesheetRejectReasons: action.timesheetRejectReasons };

    case FETCHING_TIMESHEET_REJECT_REASONS:
      return { ...state, fetchingTimesheetRejectReasons: true, timesheetsError: null };

    case FETCHING_CANDIDATE_RATING_CATEGORIES:
      return { ...state, fetchingCandidateRatingCategories: true, timesheetsError: null };

    case ADD_CANDIDATE_RATING_CATEGORIES:
      return { ...state, candidateRatingCategories: action.categories };

    case SUBMITTING_CANDIDATE_RATINGS:
      return { ...state, submittingCandidateRatings: true, timesheetsError: null };

    case SUBMIT_RATINGS_SUCCESS:
      return { ...state, submittingCandidateRatings: false };

    case FETCHING_TIMESHEET_MESSAGES: {
      return { ...state, fetchingTimesheetMessages: true };
    }

    case ADD_TIMESHEET_MESSAGES: {
      return { ...state, messages: action.messages, fetchingTimesheetMessages: false };
    }

    case SENDING_MESSAGE: {
      return { ...state, sendingMessage: true, timesheetsError: null };
    }

    case SEND_MESSAGE_SUCCESS: {
      return { ...state, sendingMessage: false };
    }

    case CLEAR_MESSAGES: {
      return { ...state, messages: [] };
    }

    case RESET_TIMESHEETS_STATE: {
      return {
        ...state,
        timesheets: [],
        timesheetsPaginationsOffset: 0,
        timesheetsPaginationLimit: DEFAULT_PAGINATION_LIMIT,
        timesheetsTotalCount: 0,
      };
    }

    case FETCHING_PAYMENT_RUNS: {
      return { ...state, paymentRunListViewError: null, isFetchingPaymentRuns: true };
    }

    case SET_PAYMENT_RUNS: {
      return { ...state, paymentRunListViewError: null, paymentRuns: action.paymentRuns, isFetchingPaymentRuns: false };
    }

    case PAYMENT_RUN_LIST_VIEW_ERROR: {
      return { ...state, paymentRunListViewError: action.error, isFetchingPaymentRuns: false };
    }

    default:
      return state;
  }
}
