import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Trend(props) {
  const down = props.delta < 0;
  const valueToRender = down ? -(props.delta) : props.delta;
  const renderedValue = Math.round(valueToRender);

  if (renderedValue === 0) return null;

  let positive = false;
  if ((props.positive === options[0] && props.delta > 0) || (props.positive === options[1] && props.delta < 0)) {
    positive = true;
  }
  const icon = down ? 'fa-arrow-down' : 'fa-arrow-up';
  const plural = props.deltaPeriod > 1 ? 's' : '';

  return (
    <div className="trend">
      <h4 className={positive ? 'trendPositive' : 'trendNegative'}>
        <i className={`fa ${icon}`} />
        {renderedValue}
%
      </h4>
      <span>
        {props.deltaPeriod}
        {' '}
        {props.deltaPeriodUnit}
        {plural}
        {' '}
ago
      </span>
    </div>
  );
}

const { oneOf, number } = PropTypes;
const options = ['up', 'down'];
const deltaUnits = ['day', 'month', 'year'];

Trend.propTypes = {
  positive: oneOf(options),
  delta: number,
  deltaPeriod: number,
  deltaPeriodUnit: oneOf(deltaUnits),
};

Trend.defaultProps = {
  positive: options[0],
  delta: 0,
  deltaPeriod: 30,
  deltaPeriodUnit: deltaUnits[0],
};
