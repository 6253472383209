import { on, off } from '../lib/db';
import * as policiesActions from '../reducers/policies';

export function startWatchingPolicies(siteId) {
  return async (dispatch, getState) => {
    const state = getState();
    const type = state.policies.policiesType;
    const id = siteId || state.policies[`selected${type.charAt(0).toUpperCase() + type.slice(1)}`];

    dispatch(policiesActions.fetchPolicies());
    const path = `${type}Policies/${id}`;
    on(path,
      (result) => {
        if (result) {
          console.log(`watching ${path}`);
          dispatch(policiesActions.addPolicies(result));
        } else {
          console.log(`Nothing to see here ${path}`);
        }
      },
      error => console.log(error));
  };
}

export function stopWatchingPolicies() {
  return async (dispatch, getState) => {
    const state = getState();
    const type = state.policies.policiesType;
    const id = state.policies[`selected${type.charAt(0).toUpperCase() + type.slice(1)}`];

    dispatch(policiesActions.clearPolicies());
    const path = `${type}Policies/${id}`;
    off(path);
    console.log(`stopped watching ${path}`);
  };
}
