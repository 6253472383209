import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdSend } from 'react-icons/md';
import TextAreaAutosize from 'react-autosize-textarea';
import Button from '../../components/Button';
import colors from '../../config/colors';
import spacing from '../../config/spacing';

//----------------------------------------------------------------
// STYLES
//----------------------------------------------------------------

const styles = {
  container: {
    // backgroundColor: colors.positive,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
    boxSizing: 'border-box',
    width: '100%',
  },
  textarea: {
    borderRadius: '2px',
    outline: 'none',
    width: '80%',
    fontSize: '0.9rem',
    fontWeight: 400,
    color: colors.text,
    minHeight: 40,
    boxSizing: 'border-box',
    padding: '12px 12px',
    border: `1px solid ${colors.cavalry.line}`,
    // flex: '1 1 0px',
    resize: 'none',
  },
};


//----------------------------------------------------------------
// EXPORT
//----------------------------------------------------------------

const { bool } = PropTypes;

export default class MessageBar extends Component {

  static propTypes = {
    isSendingMessage: bool,
  }

  static defaultProps = {
    isSendingMessage: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      messageText: '',
    };
  }

  onChangeMessageText = (event) => {
    this.setState({ messageText: event.target.value });
  }

  sendMessage = () => {
    if (this.state.messageText !== '') {
      this.props.sendMessage(this.state.messageText);
      this.setState({ messageText: '' });
    }
  }

  sendMessageFromKeyPress = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.sendMessage();
    }
  }

  render() {

    return (
      <div className="messageBar" style={styles.container}>
        <TextAreaAutosize
          style={styles.textarea}
          value={this.state.messageText || ''}
          onChange={event => this.onChangeMessageText(event)}
          placeholder="Send a message"
          autoFocus={false}
          onFocus={() => this.props.focusTextArea()}
          maxRows={5}
        />
        {this.props.isSendingMessage ?
          <Button
            style={{ backgroundColor: colors.white, color: colors.positive }}
            onClick={() => { }}
            disabled
            large
          >
            ...
          </Button>
          :
          <Button
            onClick={this.sendMessage}
            disabled={this.state.messageText === '' || this.props.isSendingMessage}
            black
            large
            shadow={false}
          >
            Send
          </Button>
        }
      </div>
    );
  }
}
