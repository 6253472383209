import { SiteWithAreas } from 'types/Metadata';
import { AdminGroupFromList } from 'types/AdminGroup';


type FetchSitesInProgress = { type: 'FETCH_SITES_IN_PROGRESS' };
type FetchSitesSuccess = { type: 'FETCH_SITES_SUCCESS', sites: Record<string, SiteWithAreas> };
type FetchSitesError = { type: 'FETCH_SITES_ERROR', error: string };

type FetchAdminGroupList = { type: 'FETCH_ADMIN_GROUP_LIST' };
type FetchAdminGroupListSuccess = { type: 'FETCH_ADMIN_GROUP_LIST_SUCCESS', adminGroups:AdminGroupFromList[] };
type FetchAdminGroupListError = { type: 'FETCH_ADMIN_GROUP_LIST_ERROR', error: string };
type ClearAdminGroupListError = { type: 'CLEAR_ADMIN_GROUP_LIST_ERROR' };
type ClearAdminGroupList = { type: 'CLEAR_ADMIN_GROUP_LIST' };

type Action =
  | FetchSitesInProgress
  | FetchSitesSuccess
  | FetchSitesError
  | FetchAdminGroupList
  | FetchAdminGroupListSuccess
  | FetchAdminGroupListError
  | ClearAdminGroupListError
  | ClearAdminGroupList;

export const fetchSitesInProgress = () : FetchSitesInProgress => ({ type: 'FETCH_SITES_IN_PROGRESS' });
export const fetchSitesSuccess = (sites: Record<string, SiteWithAreas>) : FetchSitesSuccess => ({ type: 'FETCH_SITES_SUCCESS', sites });
export const fetchSitesError = (error: string) : FetchSitesError => ({ type: 'FETCH_SITES_ERROR', error });

export const fetchAdminGroupList = () : FetchAdminGroupList => ({ type: 'FETCH_ADMIN_GROUP_LIST' });
export const fetchAdminGroupListSuccess = (adminGroups: AdminGroupFromList[]) : FetchAdminGroupListSuccess => ({ type: 'FETCH_ADMIN_GROUP_LIST_SUCCESS', adminGroups });
export const fetchAdminGroupListError = (error: string) : FetchAdminGroupListError => ({ type: 'FETCH_ADMIN_GROUP_LIST_ERROR', error });
export const clearAdminGroupListError = () : ClearAdminGroupListError => ({ type: 'CLEAR_ADMIN_GROUP_LIST_ERROR' });
export const clearAdminGroupList = () : ClearAdminGroupList => ({ type: 'CLEAR_ADMIN_GROUP_LIST' });

export interface SettingsState {
  isFetchingSites: boolean;
  sitesError: string | null;
  sites: Record<string, SiteWithAreas> | null;
  isFetchingAdminGroupList: boolean,
  adminGroups: AdminGroupFromList[] | null,
  adminGroupListError: string | null,
}

const initialState : SettingsState = {
  sites: null,
  sitesError: null,
  isFetchingSites: false,
  isFetchingAdminGroupList: false,
  adminGroups: null,
  adminGroupListError: null,
};

export function settingsReducer(state = initialState, action: Action) {
  switch (action.type) {

    // Fetch sites
    case 'FETCH_SITES_IN_PROGRESS':
      return { ...state, isFetchingSites: true, error: null };
    case 'FETCH_SITES_SUCCESS':
      return { ...state, sites: action.sites, isFetchingSites: false };
    case 'FETCH_SITES_ERROR':
      return { ...state, isFetchingSites: false, error: action.error };

    // Fetch admin groups
    case 'FETCH_ADMIN_GROUP_LIST':
      return { ...state, isFetchingAdminGroupList: true, adminGroupListError: null };
    case 'FETCH_ADMIN_GROUP_LIST_SUCCESS':
      return { ...state, adminGroups: action.adminGroups, isFetchingAdminGroupList: false };
    case 'FETCH_ADMIN_GROUP_LIST_ERROR':
      return { ...state, isFetchingAdminGroupList: false, adminGroupListError: action.error };
    case 'CLEAR_ADMIN_GROUP_LIST_ERROR':
      return { ...state, adminGroupListError: null };
    case 'CLEAR_ADMIN_GROUP_LIST':
      return { ...state, adminGroups: null };

    default:
      return state;
  }
}
