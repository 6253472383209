
export interface RegexRequirement {
  description: string;
  pattern: string;
  flags?: string;
}

export interface PasswordRequirements {
  expiryTimeDays?: number;
  minLength?: number;
  regexRequirements?: Array<RegexRequirement>
}

export interface PasswordRequirementWithStatus {
  description: string;
  isPassing: boolean;
}

export const checkPasswordRequirements = (passwordRequirements: PasswordRequirements, password = '') : Array<PasswordRequirementWithStatus> => {
  const reqsWithStatus = [];

  if (passwordRequirements?.minLength) {
    reqsWithStatus.push({
      description: `Password must be at least ${passwordRequirements.minLength} characters`,
      isPassing: password.length >= passwordRequirements.minLength,
    });
  }

  passwordRequirements?.regexRequirements?.forEach((req) => {
    const regex = new RegExp(req.pattern, req.flags);
    reqsWithStatus.push({
      description: req.description,
      isPassing: regex.test(password),
    });
  });

  return reqsWithStatus;
};
