import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { FaPencilAlt } from 'react-icons/fa';

import { fetchPaymentRunSummaries } from 'thunks/timesheets';

import Button from 'components/Button';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import Checkbox from 'components/Checkbox';

import { convertFromUtcToTimezone } from 'lib/helpers-time';
import downloadFile from 'lib/helpers-download-file';
import {
  previewPaymentRun as previewPayRun,
  runPaymentRunNow as runNow,
  markPaymentRunAsPaid as markPayRunAsPaid,
  cancelPaymentRun as cancelPayRun,
} from 'lib/api/timesheets';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { PaymentRunStatusPill } from './PaymentRunStatusPill';
import { CustomFieldMetadata } from '../../../types/CustomFields';
import { PaymentRunDetailsShape, PaymentRunDetailsType } from '../../../types/PaymentRun';
import { formatCutoff } from './payment-run-helpers';

interface DetailsTabProps {
  paymentRun: PaymentRunDetailsType,
  orgTimezone: string,
  customFieldsMetadata: CustomFieldMetadata[],
  adminCanManagePaymentRuns: boolean,
  setPaymentRunDetails: (details: PaymentRunDetailsShape) => void,
  goToPaymentRunEditForm: (paymentRunKey: string) => void,
  goToPaymentRunListView: () => void,
}

interface ConvertedDatesType {
  [key: string]: moment.Moment,
}

const styles = {
  headerText: { color: colors.text, fontWeight: 500, fontSize: '1.3rem', marginBottom: spacing.base },
  fieldLabel: { color: '#999', fontWeight: 500, fontSize: '0.9rem' },
  fieldValue: { color: colors.text, fontSize: '0.9rem' },
};

const DetailsTab : React.FC<DetailsTabProps> = (props : DetailsTabProps) => {

  const dispatch = useDispatch();
  const { scheduledSendTime, sentAt, scheduledPaymentTime, paidAt, settings } = props.paymentRun;

  const [isPreviewingPaymentRun, setIsPreviewingPaymentRun] = useState(false);
  const [isRunningPaymentRun, setIsRunningPaymentRun] = useState(false);
  const [isMarkingAsPaid, setIsMarkingAsPaid] = useState(false);
  const [isCancellingPaymentRun, setIsCancellingPaymentRun] = useState(false);
  const [apiError, setApiError] = useState(null as string | null);
  const [runNowConfirmationModalIsOpen, setRunNowConfirmationModalIsOpen] = useState(false);

  const previewPaymentRun = async () => {
    try {
      setIsPreviewingPaymentRun(true);
      const response = await previewPayRun(props.paymentRun.key);
      downloadFile(response.filename, response.mimeType, response.body);
      setIsPreviewingPaymentRun(false);
    } catch (error) {
      setApiError(error.message);
      setIsPreviewingPaymentRun(false);
    }
  };

  const runPaymentRunNow = async (markAsPaid: boolean) => {
    try {
      setIsRunningPaymentRun(true);
      setRunNowConfirmationModalIsOpen(false);
      const { paymentRun, audit, reports } = await runNow(props.paymentRun.key, markAsPaid);
      props.setPaymentRunDetails({ paymentRun, audit, reports });
      await dispatch(fetchPaymentRunSummaries());
      setIsRunningPaymentRun(false);
    } catch (error) {
      setApiError(error.message);
      setRunNowConfirmationModalIsOpen(false);
      setIsRunningPaymentRun(false);
    }
  };

  const markPaymentRunAsPaid = async () => {
    try {
      setIsMarkingAsPaid(true);
      const { paymentRun, audit, reports } = await markPayRunAsPaid(props.paymentRun.key);
      props.setPaymentRunDetails({ paymentRun, audit, reports });
      await dispatch(fetchPaymentRunSummaries());
      setIsMarkingAsPaid(false);
    } catch (error) {
      setApiError(error.message);
      setIsMarkingAsPaid(false);
    }
  };

  const cancelPaymentRun = async () => {
    try {
      setIsCancellingPaymentRun(true);
      await cancelPayRun(props.paymentRun.key);
      await dispatch(fetchPaymentRunSummaries());
      setIsCancellingPaymentRun(false);
      props.goToPaymentRunListView();
    } catch (error) {
      setApiError(error.message);
      setIsCancellingPaymentRun(false);
    }
  };

  const convertedDates = {} as ConvertedDatesType;
  convertedDates.cutOffTime = convertFromUtcToTimezone(props.paymentRun.cutOffTime, props.orgTimezone);

  Object.entries({ ...settings, ...props.paymentRun }).forEach(([settingKey, settingValue]) => {
    if (typeof settingValue === 'string') {
      const momentValue = moment.utc(settingValue, moment.ISO_8601, true);
      if (momentValue.isValid()) {
        convertedDates[settingKey] = convertFromUtcToTimezone(settingValue, props.orgTimezone);
      }
    }
  });


  const paymentRunIsNotScheduled = !scheduledSendTime && !sentAt as boolean;
  const cutOffTimeIsInFuture = convertedDates.cutOffTime.isAfter(moment.utc().tz(props.orgTimezone));

  // Find any customFields from settings object and return label names from metadata
  const customFieldsToRender = Object.entries(props.paymentRun.settings).flatMap(([settingKey, settingValue]) => {

    const customFieldMetadata = props.customFieldsMetadata.find(meta => meta.fieldKey === settingKey);

    if (customFieldMetadata) {

      // If customField is from single-select or multi-select list, then find value name(s)
      // Else just display the value itself.
      const customFieldOptionValue = customFieldMetadata.parameters?.options ?
        [settingValue]
          .flat()
          .map(value => customFieldMetadata.parameters?.options?.find(option => option.value === value)?.name ?? value)
          .join('\n')
        :
        settingValue;

      return { label: customFieldMetadata.fieldName, value: customFieldOptionValue };
    }

    return null;
  }).filter(Boolean) as Array<{ label: string, value: string }>;

  if (isPreviewingPaymentRun || isRunningPaymentRun || isMarkingAsPaid || isCancellingPaymentRun) {

    let message = '';
    if (isPreviewingPaymentRun) message = 'Downloading reports...';
    else if (isRunningPaymentRun) message = 'Running payment run...';
    else if (isCancellingPaymentRun) message = 'Cancelling payment run...';
    else message = 'Marking payment run as paid...';

    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Loading />
        <p style={{ color: colors.text }}>{message}</p>
      </div>
    );
  }

  if (apiError) {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: spacing.small }}>
        <p style={{ color: colors.red }}>{apiError}</p>
      </div>
    );
  }

  return (
    <div style={{ padding: '12px', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between', overflowY: 'auto' }}>
      <div style={{ overflowY: 'auto' }}>
        <div style={{ marginBottom: spacing.large }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: spacing.base }}>
            <h4 style={styles.headerText}>Settings</h4>
            {props.adminCanManagePaymentRuns && (
              <Button
                onClick={() => props.goToPaymentRunEditForm(props.paymentRun.key)}
                disabled={!!paidAt || !props.adminCanManagePaymentRuns}
                white
                outline
                shadow={false}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaPencilAlt style={{ marginRight: spacing.tiny }} />
                  Edit
                </div>
              </Button>
            )}
          </div>

          {!!props.paymentRun.paymentRunTypeName && <PaymentRunField label="Run Type" value={props.paymentRun.paymentRunTypeName} />}

          {/* Shift start time cutoff */}
          {convertedDates.cutOffTime && (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }} className="space-children-6--bottom">
                <p style={{ ...styles.fieldLabel, flexBasis: '36%' }}>Cutoff Time</p>
                <p style={styles.fieldValue}>{formatCutoff(convertedDates.cutOffTime)}</p>
              </div>
              <p style={{ ...styles.fieldLabel, marginTop: 3, fontSize: '0.85rem', fontWeight: 400 }}>Shifts that started on or before this date will be included.</p>
            </>
          )}

          {/* Render any custom fields from payment run settings */}
          {customFieldsToRender.map(field => <PaymentRunField label={field.label} value={field.value} />)}
        </div>

        <div style={{ marginBottom: spacing.large }}>
          <h4 style={styles.headerText}>Schedule</h4>
{/*          <p style={{ lineHeight: 1.4, color: colors.text  }}>
            Scheduling is optional. If you specify a scheduled time/date, then the payment run will automatically run at that time. If you do not, then you can run the payment run manually.
          </p>*/}

          {/* Render status pill */}
          <div style={{ marginBottom: spacing.base }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ ...styles.fieldLabel, flexBasis: '36%' }}>Status</p>
              <PaymentRunStatusPill
                paidAt={paidAt}
                sentAt={sentAt}
                scheduledPaymentTime={scheduledPaymentTime}
                scheduledSendTime={scheduledSendTime}
              />
            </div>
          </div>

          {paymentRunIsNotScheduled && (
            <p style={{ lineHeight: 1.4, color: colors.text, fontSize: '0.9rem' }}>This payment run is not scheduled. Run manually using the button below, or edit to add a scheduled run time.</p>
          )}

          {convertedDates.scheduledSendTime && !convertedDates.sentAt && (
            <PaymentRunField label="Scheduled For" value={`${convertedDates.scheduledSendTime.format('Do MMM YYYY')} at ${convertedDates.scheduledSendTime.format('HH:mm')}`} />
          )}

          {convertedDates.sentAt && <PaymentRunField label="Sent At" value={`${convertedDates.sentAt.format('Do MMM YYYY')} at ${convertedDates.sentAt.format('HH:mm')}`} />}

          {convertedDates.scheduledPaymentTime && !convertedDates.paidAt && (
            <PaymentRunField label="To Be Paid On" value={`${convertedDates.scheduledPaymentTime.format('Do MMM YYYY')} at ${convertedDates.scheduledPaymentTime.format('HH:mm')}`} />
          )}

          {convertedDates.paidAt && <PaymentRunField label="Paid At" value={`${convertedDates.paidAt.format('Do MMM YYYY')} at ${convertedDates.paidAt.format('HH:mm')}`} />}
        </div>

        {!paidAt && (
          <div style={{ marginBottom: spacing.base }}>
            <h4 style={styles.headerText}>Actions</h4>

            <div style={{ marginTop: spacing.base, marginBottom: spacing.base, display: 'flex', justifyContent: 'space-between' }}>

              {sentAt && !paidAt && (
                <Button
                  onClick={() => markPaymentRunAsPaid()}
                  disabled={!props.adminCanManagePaymentRuns}
                  style={{ width: '100%', backgroundColor: colors.cavalry.pending }}
                >
                  Mark As Paid
                </Button>
              )}

              {!sentAt && (
                <>
                  <Button
                    onClick={() => previewPaymentRun()}
                    disabled={!props.adminCanManagePaymentRuns}
                    style={{ width: '48%' }}
                    white
                    outline
                    shadow={false}
                  >
                    Preview
                  </Button>
                  <Button
                    onClick={() => setRunNowConfirmationModalIsOpen(true)}
                    disabled={!props.adminCanManagePaymentRuns || !!convertedDates.sentAt || cutOffTimeIsInFuture}
                    style={{ width: '48%' }}
                    black
                    shadow={false}
                  >
                    Run Now
                  </Button>
                </>
              )}
            </div>
            {!props.adminCanManagePaymentRuns && <p style={{ color: colors.red }}>You do not have the required permission to manage payment runs.</p>}
            {cutOffTimeIsInFuture && <p style={{ color: colors.cavalry.pending }}>{'If you wish to run the payment run now, please edit and update the \"Cut Off\" field. The date must not be in the future.'}</p>}
          </div>
        )}

      </div>

      {props.adminCanManagePaymentRuns && !sentAt && (
        <div>
          <h4 style={styles.headerText}>Cancel</h4>
          <Button onClick={() => cancelPaymentRun()} style={{ width: '100%' }} danger outline>Cancel Payment Run</Button>
        </div>
      )}

      <RunNowConfirmationModal
        modalOpen={runNowConfirmationModalIsOpen}
        onClose={() => setRunNowConfirmationModalIsOpen(false)}
        checkMarkAsPaidByDefault={convertedDates.scheduledSendTime?.isSame(convertedDates.scheduledPaymentTime) ?? false}
        runNow={(markAsPaid: boolean) => runPaymentRunNow(markAsPaid)}
      />
    </div>
  );
};

interface PaymentRunFieldProps {
  value: string,
  label: string,
}

const PaymentRunField = (props: PaymentRunFieldProps) => {
  return (
    <div style={{ marginBottom: spacing.base }}>
      <div style={{ display: 'flex', flexDirection: 'column' }} className="space-children-6--bottom">
        <p style={styles.fieldLabel}>{props.label}</p>
        <p style={styles.fieldValue}>
          {props.value}
        </p>
      </div>
    </div>
  );
};

interface RunNowConfirmationModal {
  onClose: () => void,
  modalOpen: boolean,
  checkMarkAsPaidByDefault: boolean,
  runNow: (markAsPaid: boolean) => void,
}

const RunNowConfirmationModal = (props: RunNowConfirmationModal) => {

  const [markAsPaidIsChecked, setMarkAsPaidIsChecked] = useState(props.checkMarkAsPaidByDefault);
  return (
    <Modal
      isOpen={props.modalOpen}
      onRequestClose={props.onClose}
      header="Run Payment Run Now"
    >
      <CloseButton handleClose={props.onClose} top={15} right={15} />
      <div style={{ padding: '12px 12px 0px 12px', width: 400, textAlign: 'center', lineHeight: 1.4 }}>
        <p style={{ color: colors.text, fontSize: '0.9rem' }}>Please confirm that you wish to manually run the payment run now. You can optionally mark as paid now or at a later date.</p>

        <div style={{ padding: '12px 0px 12px 12px', display: 'flex', alignItems: 'center' }}>
          <p style={{ color: colors.text, marginRight: spacing.small, fontSize: '0.9rem' }}>Mark As Paid</p>
          <Checkbox
            checked={markAsPaidIsChecked}
            onChange={() => setMarkAsPaidIsChecked(!markAsPaidIsChecked)}
          />
        </div>

        <div style={{ marginTop: spacing.base, marginBottom: spacing.base, display: 'flex', justifyContent: 'space-between' }}>
          <Button style={{ width: '48%' }} onClick={() => props.onClose()} white outline>Cancel</Button>
          <Button style={{ width: '48%' }} onClick={() => props.runNow(markAsPaidIsChecked)} positive>Run Now</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DetailsTab;
