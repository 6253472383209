import React from 'react';

import FullSizeModal from 'components/FullSizeModal';
import CloseButton from 'components/CloseButton';

interface PdfViewProps {
  onRequestClose: () => void,
  name: string,
  source: string
}

export default function PdfView(props: PdfViewProps): React.ReactElement {
  return (
    <FullSizeModal isOpen onRequestClose={props.onRequestClose}>
      <CloseButton handleClose={props.onRequestClose} top={80} right={40} size={32} />
      <div className="submission-full-size">
        <iframe height="100%" width="100%" title="PDF" name={props.name} src={props.source}>PDF</iframe>
      </div>
    </FullSizeModal>
  );
}
