import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import './SettingItem.css';

const { oneOfType, arrayOf, string, node, bool, func } = PropTypes;

export default class SettingItem extends PureComponent {
  static propTypes = {
    title: string.isRequired,
    description: oneOfType([arrayOf(node), node, string]),
    children: oneOfType([
      arrayOf(node),
      node,
    ]),
    open: bool,
    onExpand: func,
    identifier: string.isRequired,
  }

  static defaultProps = {
    description: null,
    children: null,
    open: null,
    onExpand: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props && newProps && this.props.open !== newProps.open) {
      this.setState({ open: this.props.open });
    }
  }

  onExpand = () => {
    if (this.props.children) {
      if (this.props.onExpand) {
        this.props.onExpand();
      } else {
        this.setState({ open: !this.state.open });
      }
    }
  }

  render() {
    const classes = ['settingItemExpand'];

    if (this.props.open) {
      classes.push('expanded');
    }

    return (
      <div className="settingItem">
        <span className="expandButton">
          <Button
            small
            rise
            onClick={this.onExpand}
            className={`test-id-setting-expand-${this.props.identifier}`}
          >
            Expand
          </Button>
        </span>
        <h3>{this.props.title}</h3>
        {this.props.description ? <p>{this.props.description}</p> : null}
        {this.props.children ? <div className={classes.join(' ')}>{this.props.children}</div> : null}
      </div>
    );
  }
}
