import { reportError } from 'lib/error-reporting';
import * as candidateReducer from '../reducers/candidate';
import * as api from '../lib/api';
import * as accountsApi from '../lib/api/accounts';
import { fetchCandidates } from './bank';

export function fetchCandidateBanks(candidateKey) {
  return async (dispatch) => {
    dispatch(candidateReducer.isFetchingCandidateBanks());
    const response = await api.get('candidate/banks', { candidateKey });

    if (!response?.body?.success) {
      dispatch(candidateReducer.fetchCandidateBanksError({ api: 'candidate/banks', error: response?.body?.error ?? 'Something went wrong. Please try again' }));
    } else {
      dispatch(candidateReducer.fetchCandidateBanksSuccess({
        banks: response.body.banks,
        candidateOrgKey: response.body.candidateOrgKey,
        ratingsFeatureEnabled: response.body.ratingsFeatureEnabled,
      }));
    }
  };
}

export function fetchCandidateProfile(candidateKey) {
  return async (dispatch) => {
    dispatch(candidateReducer.isFetchingCandidateProfile());

    try {
      const response = await accountsApi.fetchCandidateProfile(candidateKey);
      dispatch(candidateReducer.fetchCandidateProfileSuccess(candidateKey, response.candidate));
      return response.candidate;
    } catch (error) {
      dispatch(candidateReducer.fetchCandidateProfileError(error.message));
    }

    return null;
  };
}

export function fetchCandidateShifts(candidateKey, filterKey) {
  return async (dispatch) => {
    dispatch(candidateReducer.isFetchingCandidateShifts());

    try {
      const response = await api.post('jobs/shift-list', { candidateKey, allCandidateBookingsAndApplications: filterKey === 'all-bookings-and-applications' });
      if (response.status === 200 && response.body?.shiftList) {
        dispatch(candidateReducer.isFetchingCandidateShiftsSuccess(response.body.shiftList));
      } else {
        dispatch(candidateReducer.isFetchingCandidateShiftsError('Error fetching jobs'));
      }
    } catch (e) {
      dispatch(candidateReducer.isFetchingCandidateShiftsError('Error fetching jobs'));
    }
  };
}

export function fetchStaffContract(candidateKey) {
  return async (dispatch) => {
    dispatch(candidateReducer.isFetchingStaffContract());

    try {
      const response = await accountsApi.fetchStaffContract(candidateKey);
      dispatch(candidateReducer.fetchStaffContractSuccess(response.staffContract));
    } catch (error) {
      dispatch(candidateReducer.fetchStaffContractError(error.message));
    }
  };
}

export function createStaffContract(candidateKey, fields) {
  return async (dispatch) => {
    dispatch(candidateReducer.isUpdatingStaffContract());

    const payload = {
      ...fields,
      contractedHoursPeriodType: fields.contractedHoursPeriodType.value,
    };

    try {
      const response = await accountsApi.createStaffContract(candidateKey, payload);
      dispatch(candidateReducer.updateStaffContractSuccess(response.staffContract));
    } catch (error) {
      dispatch(candidateReducer.updateStaffContractError(error.message));
    }
  };
}

export function updateStaffContract(contractKey, fields) {
  return async (dispatch) => {
    dispatch(candidateReducer.isUpdatingStaffContract());

    const payload = {
      ...fields,
      contractedHoursPeriodType: fields.contractedHoursPeriodType.value,
    };

    try {
      const response = await accountsApi.updateStaffContract(contractKey, payload);
      dispatch(candidateReducer.updateStaffContractSuccess(response.staffContract));
    } catch (error) {
      dispatch(candidateReducer.updateStaffContractError(error.message));
    }
  };
}

export function updateCandidateComplianceStatus(candidateKey, adminComplianceStatusColor, adminComplianceStatusLabel) {
  return async (dispatch, getState) => {

    const { profile } = getState().candidate;

    // Throw error if candidate profile does not exist in Redux
    if (!profile || profile.key !== candidateKey) {
      const errorMessage = 'Candidate profile not found. Failed to update compliance status in Redux.';
      reportError(new Error(errorMessage), {
        options: {
          candidateKey,
          adminComplianceStatusColor,
          adminComplianceStatusLabel,
        },
      });
      return;
    }
    dispatch(candidateReducer.updateCandidateComplianceStatus({ ...profile, adminComplianceStatusColor, adminComplianceStatusLabel }));
  };
}

export function updateCandidateProfile(candidateKey, details) {
  return async (dispatch) => {

    const fallbackError = 'Failed to update candidate profile. If the problem persists, please contact technical support.';

    try {
      dispatch(candidateReducer.setIsUpdatingCandidateProfile());
      const response = await api.post('account/candidate/update', { candidateKey, ...details });

      if (response?.body?.candidate) {
        dispatch(candidateReducer.updateCandidateProfileSuccess(candidateKey, response.body.candidate));
        dispatch(fetchCandidates());
        return true;
      }

      dispatch(candidateReducer.updateCandidateProfileError(response?.body?.humanReadableErrorMessage ?? fallbackError));

      const error = typeof response?.body?.error === 'string' ? response.body.error : fallbackError;
      reportError(new Error(error), {
        options: {
          candidateKey,
          details,
        },
        responseBody: response?.body,
      });
      return false;
    } catch (error) {
      dispatch(candidateReducer.updateCandidateProfileError(fallbackError));
      reportError(error, {
        options: {
          candidateKey,
          details,
        },
      });
      return false;
    }
  };
}
