import React from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

//----------------------------------------------------------------
// EXPORT
//----------------------------------------------------------------

const styles = {
  mainContainer: {
    paddingBottom: spacing.xlarge,
  },
  text: {
    color: colors.text,
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
  },
};

export default function TextSectionHeader(props) {
  return (
    <div style={styles.mainContainer}>
      <span style={{ ...styles.text, ...props.extraStyles }}>{props.text}</span>
    </div>
  );
}

const { bool, string, object } = PropTypes;


// NOTE: containerId only needed if > 1 on page

TextSectionHeader.propTypes = {
  extraStyles: object,
  text: string.isRequired,
  uppercase: bool,
};

TextSectionHeader.defaultProps = {
  extraStyles: {},
  uppercase: true,
};
