import { useMemo } from 'react';

import { useFeatureFlag } from 'hooks/feature';

import { formatHourlyRate, validateHourlyRate, calculateShiftLength } from '../routes/Jobs/helpers';

interface useHourlyRateProps {
  requireCustomHourlyRate: boolean,
  customHourlyRate: string | null,
  customHourlyRateCriteria: Record<string, number>,
  startTime: string,
  endTime: string,
  timezone: string,
}

interface ReturnProps {
  estimatedCost: string | null,
  customHourlyRateValidation: { success: boolean, message: string | null }
}

export default function useHourlyRate({
  requireCustomHourlyRate,
  customHourlyRate,
  customHourlyRateCriteria,
  startTime,
  endTime,
  timezone,
}: useHourlyRateProps): ReturnProps {

  const customHourlyRateOn = useFeatureFlag('customHourlyRate');

  const estimatedCost = useMemo(() => {
    if (!customHourlyRateOn) return null;
    if (!requireCustomHourlyRate) return 'N/A';

    const shiftLength = calculateShiftLength(startTime, endTime, timezone);
    const rateAsNumber = Number(formatHourlyRate(customHourlyRate || '0.00')).toFixed(2);
    return `£${formatHourlyRate(String(Number(rateAsNumber) * shiftLength))}`;

  }, [customHourlyRate, requireCustomHourlyRate, startTime, endTime, timezone]);

  const customHourlyRateValidation = useMemo(() => {
    if (!requireCustomHourlyRate) return { success: true, message: null };
    return validateHourlyRate(customHourlyRate, customHourlyRateCriteria ?? {});
  }, [requireCustomHourlyRate, customHourlyRate, customHourlyRateCriteria]);

  return { estimatedCost, customHourlyRateValidation };
}
