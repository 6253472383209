const ADD_GLOBAL_DATA = 'ADD_GLOBAL_DATA';
const CLEAR_GLOBAL_DATA = 'CLEAR_GLOBAL_DATA';
const ADD_METADATA = 'ADD_METADATA';
const ADD_ORG_CONFIG = 'ADD_ORG_CONFIG';
const ADD_CURRENT_ORG_KEY = 'ADD_CURRENT_ORG_KEY';
const ADD_SECTOR_KEY = 'ADD_SECTOR_KEY';
const UPDATE_VERSION = 'UPDATE_VERSION';
const UPDATE_COMPLETE = 'UPDATE_COMPLETE';
const UPDATE_MESSAGE_DISPLAYED = 'UPDATE_MESSAGE_DISPLAYED';

const SET_PASSWORD_EXPIRY = 'SET_PASSWORD_EXPIRY';
const SET_PASSWORD_EXPIRY_CURRENT_PASSWORD = 'SET_PASSWORD_EXPIRY_CURRENT_PASSWORD';
const CLEAR_PASSWORD_EXPIRY = 'CLEAR_PASSWORD_EXPIRY';
const SET_PASSWORD_EXPIRY_HAS_SKIPPED = 'SET_PASSWORD_EXPIRY_HAS_SKIPPED';

const ADD_ORG_METADATA = 'ADD_ORG_METADATA';
const ADD_SHIFT_CREATION_METADATA = 'ADD_SHIFT_CREATION_METADATA';
const IS_FETCHING_SHIFT_CREATION_METADATA = 'IS_FETCHING_SHIFT_CREATION_METADATA';
const SET_FETCH_SHIFT_CREATION_METADATA_ERROR = 'SET_FETCH_SHIFT_CREATION_METADATA_ERROR';

export const addGlobalData = (path, data) => ({ type: ADD_GLOBAL_DATA, path, data });
export const clearGlobalData = () => ({ type: CLEAR_GLOBAL_DATA });

export const addMetadata = data => ({ type: ADD_METADATA, data });

export const addOrgConfig = data => ({ type: ADD_ORG_CONFIG, data });
export const addCurrentOrgKey = orgKey => ({ type: ADD_CURRENT_ORG_KEY, orgKey });
export const addSectorKey = key => ({ type: ADD_SECTOR_KEY, key });

export const updateVersion = () => ({ type: UPDATE_VERSION });
export const updateComplete = () => ({ type: UPDATE_COMPLETE });
export const updateMessageDisplayed = () => ({ type: UPDATE_MESSAGE_DISPLAYED });

export const addOrgMetadata = (adminGroups, suppliers, banks, rateCards, customFields, shiftReasons, services, paymentRunTypes) => ({ type: ADD_ORG_METADATA, adminGroups, suppliers, banks, rateCards, customFields, shiftReasons, services, paymentRunTypes });
export const setPasswordExpiry = ({ passwordExpiresAt, passwordExpired, passwordExpiringSoon, email, currentPassword }) => ({ type: SET_PASSWORD_EXPIRY, passwordExpiresAt, passwordExpired, passwordExpiringSoon, email, currentPassword });
export const setPasswordExpiryCurrentPassword = (currentPassword) => ({ type: SET_PASSWORD_EXPIRY_CURRENT_PASSWORD, currentPassword });
export const clearPasswordExpiry = () => ({ type: CLEAR_PASSWORD_EXPIRY });
export const setHasSkippedPasswordReset = () => ({ type: SET_PASSWORD_EXPIRY_HAS_SKIPPED });

export const setIsFetchingShiftCreationMetadata = () => ({ type: IS_FETCHING_SHIFT_CREATION_METADATA });
export const setFetchShiftCreationMetadataError = error => ({ type: SET_FETCH_SHIFT_CREATION_METADATA_ERROR, error });
export const addShiftCreationMetadata = (rateCards, shiftReasons, costCentres) => ({ type: ADD_SHIFT_CREATION_METADATA, rateCards, shiftReasons, costCentres });

const initialState = {
  employerAuthorisedOrgs: null,
  globalConfig: {},
  metadata: {},
  orgConfig: null,
  sectorKey: null,
  updateRequired: false,
  updateCompleted: false,
  responseUrl: '',
  currentOrgKey: null,
  orgAdminGroups: null,
  orgSuppliers: null,
  adminBanks: null,
  orgRateCards: null,
  shiftReasons: null,
  customFieldsMetadata: null,
  shiftCreateMetadata: null,
  isFetchingShiftCreationMetadata: false,
  fetchShiftCreationMetadataError: null,
  services: null,
  paymentRunTypes: null,
  passwordExpiry: null,
};

export default function global(state = initialState, action) {
  switch (action.type) {
    case ADD_GLOBAL_DATA:
      return {
        ...state,
        [action.path]: action.data,
      };
    case ADD_METADATA:
      return {
        ...state,
        metadata: action.data,
      };
    case ADD_ORG_CONFIG:
      return {
        ...state,
        orgConfig: action.data,
      };
    case ADD_CURRENT_ORG_KEY:
      return {
        ...state,
        currentOrgKey: action.orgKey,
      };
    case ADD_SECTOR_KEY:
      return {
        ...state,
        sectorKey: action.key,
      };
    case CLEAR_GLOBAL_DATA:
      return initialState;
    case UPDATE_VERSION:
      return {
        ...state,
        updateRequired: true,
      };
    case UPDATE_COMPLETE:
      return {
        ...state,
        updateRequired: false,
        updateCompleted: true,
      };
    case UPDATE_MESSAGE_DISPLAYED:
      return {
        ...state,
        updateCompleted: false,
      };

    case SET_PASSWORD_EXPIRY: {
      return {
        ...state,
        passwordExpiry: {
          ...state.passwordExpiry,
          passwordExpiresAt: action.passwordExpiresAt,
          passwordExpired: action.passwordExpired,
          passwordExpiringSoon: action.passwordExpiringSoon,
          currentPassword: action.currentPassword ?? state.passwordExpiry?.currentPassword,
          email: action.email ?? state.passwordExpiry?.email,
        },
      };
    }
    case SET_PASSWORD_EXPIRY_CURRENT_PASSWORD: {
      return {
        ...state,
        passwordExpiry: {
          ...state.passwordExpiry,
          currentPassword: action.currentPassword,
        },
      };
    }
    case CLEAR_PASSWORD_EXPIRY: {
      return {
        ...state,
        passwordExpiry: null,
      };
    }

    case SET_PASSWORD_EXPIRY_HAS_SKIPPED: {
      return {
        ...state,
        passwordExpiry: {
          ...state.passwordExpiry,
          hasSkippedReset: true,
        },
      };
    }

    case ADD_ORG_METADATA:
      return {
        ...state,
        orgAdminGroups: action.adminGroups,
        orgSuppliers: action.suppliers,
        adminBanks: action.banks,
        orgRateCards: action.rateCards,
        customFieldsMetadata: action.customFields,
        shiftReasons: action.shiftReasons,
        services: action.services,
        paymentRunTypes: action.paymentRunTypes,
      };

    case IS_FETCHING_SHIFT_CREATION_METADATA:
      return {
        ...state,
        fetchShiftCreationMetadataError: null,
        isFetchingShiftCreationMetadata: true,
      };
    case ADD_SHIFT_CREATION_METADATA:
      return {
        ...state,
        shiftCreateMetadata: {
          ...(state.shiftCreateMetadata ?? {}),
          orgRateCards: action.rateCards,
          orgShiftReasons: action.shiftReasons,
          orgCostCentres: action.costCentres,
        },
        isFetchingShiftCreationMetadata: false,
      };
    case SET_FETCH_SHIFT_CREATION_METADATA_ERROR:
      return {
        ...state,
        fetchShiftCreationMetadataError: action.error,
        isFetchingShiftCreationMetadata: false,
      };
    default:
      return state;
  }
}
