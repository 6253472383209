import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import firstBy from 'thenby';

import * as bankThunks from 'thunks/bank';

import colors from 'config/colors';

import Page from 'components/Page';
import BankHeader from './BankHeader';
import BankTabBar from './BankTabBar';
import BankInProgress from './BankInProgress';

const { func, objectOf, object, string, bool } = PropTypes;
class BankInProgressContainer extends Component {
  static propTypes = {
    candidates: objectOf(object),
    selectedBankKey: string.isRequired,
    isFetchingCandidates: bool.isRequired,
    fetchCandidates: func.isRequired,
    customTerminology: objectOf(string),
    nav: objectOf(func).isRequired,
  }

  static defaultProps = {
    customTerminology: {},
    candidates: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      filterString: '',
    };
  }

  componentDidMount() {
    this.props.fetchCandidates();
  }

  setFilterString(filterString) {
    this.setState({ filterString });
  }

  filterAndSortCandidates = () => {
    const filterStringLowerCase = this.state.filterString.toLowerCase();
    const filteredCandidates = Object.values(this.props.candidates)
      .filter(candidate => {
        return !candidate.profileComplete
          && !candidate.removedAt
          && candidate.name?.toLowerCase()?.includes(filterStringLowerCase)
          && (this.props.selectedBankKey === 'all' || candidate.bankKeys.includes(this.props.selectedBankKey));
      });
    filteredCandidates.sort(firstBy('progress'));
    return filteredCandidates;
  }

  render() {
    const filteredCandidates = this.props.candidates ? this.filterAndSortCandidates(this.props.candidates) : [];
    const bankName = this.props.customTerminology?.bank ?? 'Staff Bank';
    return (
      <Page vflex title={`${bankName}: In Progress`} contentClassName="bankManagement">
        <div style={{ backgroundColor: colors.cavalry.backgroundLight6, paddingTop: 6 }}>
          <div style={{ paddingBottom: 6 }}>
            <BankHeader />
          </div>
          <BankTabBar
            selectedTabKey="signing-up"
            setSelectedTab={this.props.nav.setSelectedTab}
            selectedBankKey={this.props.selectedBankKey}
            setSelectedBank={this.props.nav.setSelectedBank}
            filterString={this.state.filterString}
            setFilterString={this.setFilterString}
          />
        </div>
        <BankInProgress
          isFetchingCandidates={this.props.isFetchingCandidates}
          candidates={filteredCandidates}
          openProfile={id => this.props.nav.openCandidateProfile(id)}
        />
      </Page>
    );
  }
}

function mapStateToProps({ bank, global, screen }) {
  return {
    isMobile: screen.isMobile,
    candidates: bank.candidates,
    isFetchingCandidates: bank.isFetchingCandidates,
    candidateProgress: bank.candidateProgress,
    customTerminology: global.orgConfig ? global.orgConfig.customTerminology : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCandidates: () => dispatch(bankThunks.fetchCandidates()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BankInProgressContainer);
