import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFeatureFlag } from 'hooks/feature';
import useHourlyRate from 'hooks/useHourlyRate';

import * as createShiftActions from 'reducers/createShifts';
import { setShiftDetailsIsPinned } from 'reducers/userInterface';

import Button from 'components/Button';
import SidePanel from 'components/SidePanel';
import SectionSeparator from 'components/SectionSeparator';

import { shiftFieldMappings } from 'config/shiftFieldMappings';

import { setTemplateEdited } from 'reducers/templates';
import { useTemplateShiftFields } from './template-hooks';

import SiteField from '../FormFields/SiteField';
import AreaField from '../FormFields/AreaField';
import DayOfWeekField from '../FormFields/DayOfWeekField';
import TimeField from '../FormFields/TimeField';
import RoleField from '../FormFields/RoleField';
import GradeField from '../FormFields/GradeField';
import SpecialityField from '../FormFields/SpecialityField';
import GenderField from '../FormFields/GenderField';
import ReasonField from '../FormFields/ReasonField';
import SubReasonField from '../FormFields/SubReasonField';
import DescriptionField from '../FormFields/DescriptionField';
import RateCardField from '../FormFields/RateCardField';
import CostCentreField from '../FormFields/CostCentreField';
import TargetCandidatesField from '../FormFields/TargetCandidatesField';
import BanksFieldNew from '../FormFields/BanksFieldNew';
import ServiceField from '../FormFields/ServiceField';
import HourlyRateField from '../FormFields/HourlyRateField';
import SlotsField from '../FormFields/SlotsField';

interface EditTemplateProps {
  shiftKey: string,
  closeSidePanel: () => void,
}

function checkIfTargetedCandidatesFieldIsValid(candidates: any[], slotsRequired: number) {
  console.log(candidates);
  const fieldIsInvalid = !candidates || (candidates.length > 0 && slotsRequired >= 1 && candidates.length > slotsRequired);
  return {
    success: !fieldIsInvalid,
    message: fieldIsInvalid ? '- There are more candidates selected than the number of vacancies required' : null,
  };
}

export default function EditTemplate(props: EditTemplateProps) : React.ReactElement {

  const dispatch = useDispatch();
  const mandatoryFields = useSelector(({ global }) => global.orgConfig?.shiftMandatoryFields) ?? [];
  const preferredGenderEnabled = useSelector(({ global }) => global.orgConfig?.preferredGender?.enabled ?? false);
  const template = useSelector(({ templates }) => templates.template);
  const templateEdited = useSelector(({ templates }) => templates.templateEdited);
  const templateShift = useSelector(({ createShifts }) => createShifts.templateShifts[props.shiftKey]);
  const sidePanelIsPinned = useSelector((state) => state.userInterface.shiftDetailsIsPinned);
  const customHourlyRateCriteria = useSelector(({ global }) => global.orgConfig?.customHourlyRateCriteria);

  // Feature flags
  const rateCardsOn = useFeatureFlag('rateCard');
  const siteAreasOn = useFeatureFlag('siteAreas');
  const costCentresOn = useFeatureFlag('costCentres');
  const servicesOn = useFeatureFlag('services');
  const customHourlyRateOn = useFeatureFlag('customHourlyRate');

  useEffect(() => {
    // If template or template shift does not exist, then close
    if (!templateShift) props.closeSidePanel();
  }, [templateShift]);

  const {
    fields,
    startTimeAsISOString,
    endTimeAsISOString,
    timezone,
    missingMandatoryFields,
    applicableRateCards,
    onChangeSite,
    onChangeArea,
    onChangeStartTimeOfDay,
    onChangeEndTimeOfDay,
    onChangeDayOfWeek,
    onChangeSlotsRequired,
    onChangeRole,
    onChangeGrade,
    onChangeSpeciality,
    onChangeGender,
    onChangeReason,
    onChangeSubReason,
    onChangePublicDescription,
    onChangePrivateNotes,
    onChangeRateCard,
    onChangeCandidates,
    onChangeBanks,
    onChangeService,
    onChangeHourlyRate,
  } = useTemplateShiftFields(props.shiftKey);

  const { estimatedCost, customHourlyRateValidation } = useHourlyRate({
    requireCustomHourlyRate: fields.rateCard?.requireCustomHourlyRate ?? false,
    customHourlyRate: fields.customHourlyRate,
    customHourlyRateCriteria,
    startTime: startTimeAsISOString,
    endTime: endTimeAsISOString,
    timezone,
  });

  const targetCandidatesFieldValidation = useMemo(() => {
    return checkIfTargetedCandidatesFieldIsValid(fields.candidates, fields.slotsRequired);
  }, [fields.candidates, fields.slotsRequired]);

  const minSlotsRequiredValidationError = fields.slotsRequired < 1;
  const maxSlotsRequiredValidationError = fields.slotsRequired > 99;

  const showCostCentreError = (
    costCentresOn &&
    mandatoryFields.includes('costCentre') &&
    !fields.costCentre &&
    !missingMandatoryFields.includes('role') &&
    !missingMandatoryFields.includes('grade') &&
    !missingMandatoryFields.includes('speciality') &&
    !missingMandatoryFields.includes('site') &&
    !missingMandatoryFields.includes('area') &&
    !missingMandatoryFields.includes('reason')
  );

  const showRateCardError = rateCardsOn && !fields.rateCard;
  const isValid = !(missingMandatoryFields.length || showCostCentreError || showRateCardError || !targetCandidatesFieldValidation.success || maxSlotsRequiredValidationError || minSlotsRequiredValidationError);

  return (
    <SidePanel
      vflex
      isOpen={!!templateShift}
      closeSidePanel={props.closeSidePanel}
      title="Edit Template Shift"
      setIsPinned={(isPinned: boolean) => dispatch(setShiftDetailsIsPinned(isPinned))}
      isPinned={sidePanelIsPinned}
    >
      <div className="createShiftForm shiftDetailContentContainer" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>

        {servicesOn && (
          <div className="shiftDetailsSubSection">
            <ServiceField onChange={onChangeService} service={fields.service} />
          </div>
        )}

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Location and Time</p>
          </div>

          <SiteField
            site={fields.site}
            serviceKey={fields.service?.id ?? null}
            onChange={onChangeSite}
            mandatory={mandatoryFields.includes('site')}
          />

          {siteAreasOn && (
            <AreaField
              siteKey={fields.site?.id}
              area={fields.area}
              onChange={onChangeArea}
              mandatory={mandatoryFields.includes('area')}
            />
          )}

          {template?.periodType === 'week' && (
            <DayOfWeekField dayOfWeek={fields.dayOfWeek} onChange={onChangeDayOfWeek} />
          )}

          <TimeField
            startTime={startTimeAsISOString}
            endTime={endTimeAsISOString}
            timezone={timezone}
            onChangeStartTime={onChangeStartTimeOfDay}
            onChangeEndTime={onChangeEndTimeOfDay}
          />

        </div>

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Shift Specifications</p>
          </div>

          <SlotsField
            slotsRequired={fields.slotsRequired}
            onChange={onChangeSlotsRequired}
            mandatory={mandatoryFields.includes('slotsRequired')}
            label="Vacancies"
          />

          <RoleField
            role={fields.role}
            mandatoryFields={mandatoryFields}
            onChange={onChangeRole}
            mandatory={mandatoryFields.includes('role')}
          />

          <GradeField
            grade={fields.grade}
            role={fields.role}
            mandatoryFields={mandatoryFields}
            onChange={onChangeGrade}
            mandatory={mandatoryFields.includes('grade')}
          />

          <SpecialityField
            speciality={fields.speciality}
            mandatoryFields={mandatoryFields}
            onChange={onChangeSpeciality}
            mandatory={mandatoryFields.includes('speciality')}
          />

          {preferredGenderEnabled && (
            <GenderField
              gender={fields.preferredGender}
              onChange={onChangeGender}
            />
          )}

          {costCentresOn && <CostCentreField costCentre={fields.costCentre} />}
        </div>

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Shift Reason</p>
          </div>

          <ReasonField
            reason={fields.reason}
            onChange={onChangeReason}
            mandatory={mandatoryFields.includes('reason')}
          />

          <SubReasonField
            reasonKey={fields.reason?.id}
            subReason={fields.reason2}
            onChange={onChangeSubReason}
            mandatory={mandatoryFields.includes('subReason')}
          />

          <DescriptionField
            label="Public Description"
            placeholder="Displayed to staff when they view the shift"
            description={fields.publicDescription}
            onChange={onChangePublicDescription}
          />

          <DescriptionField
            label="Private Notes"
            placeholder="Only visible to other admins"
            description={fields.privateNotes}
            onChange={onChangePrivateNotes}
          />

        </div>

        {rateCardsOn ?
          <div className="shiftDetailsSubSection" style={{ marginBottom: 12 }}>
            <div className="shiftDetailsSubSectionTitle">
              <p className="shiftDetailsSubHeading">Rate Card</p>
            </div>
            <RateCardField
              rateCard={fields.rateCard}
              rateCards={applicableRateCards}
              onChange={onChangeRateCard}
            />

            {customHourlyRateOn && (
              <>
                <HourlyRateField
                  label="Rate"
                  subLabel="(Per Hour)"
                  placeholder="0.00"
                  customHourlyRate={fields.customHourlyRate ?? ''}
                  onChange={onChangeHourlyRate}
                  disabled={!fields.rateCard?.requireCustomHourlyRate}
                  mandatory={fields.rateCard?.requireCustomHourlyRate ?? false}
                  customHourlyRateValidation={customHourlyRateValidation}
                  estimatedCost={estimatedCost}
                />

              </>
            )}
          </div>
          :
          null
        }

        <div className="shiftDetailsSubSection">
          <div className="shiftDetailsSubSectionTitle">
            <p className="shiftDetailsSubHeading">Release Shift To</p>
          </div>

          <TargetCandidatesField
            candidates={fields.candidates}
            onChange={onChangeCandidates}
            isValid={targetCandidatesFieldValidation.success}
          />
          <BanksFieldNew
            bankKeys={fields.bankKeys ?? []}
            onChange={onChangeBanks}
            mandatory={false}
          />
        </div>

        <div style={{ borderTop: '1px solid #EAEAEA' }}>

          {
            !isValid
              ?
                <div className="shiftDetailsSubSection">
                  <div className="cs__validation-messages">
                    {(missingMandatoryFields.length > 0) &&
                      <p>{`Please complete empty mandatory field${missingMandatoryFields.length === 1 ? '' : 's'} (${missingMandatoryFields.map((field: string) => shiftFieldMappings[field]?.name).join(', ')})`}</p>
                    }
                    {showCostCentreError && <p>There is no cost centre for this Role, Grade and Speciality combination - please review or contact your system administrator</p>}
                    {showRateCardError && <p>There is no Rate Card for this Role, Grade and Speciality combination - please review or contact your system administrator</p>}
                    {minSlotsRequiredValidationError && <p>- The minimum number of slots can be 1</p>}
                    {maxSlotsRequiredValidationError && <p>- The number of slots cannot exceed 99</p>}
                    {!targetCandidatesFieldValidation.success && targetCandidatesFieldValidation.message && (
                      <p>
                        {targetCandidatesFieldValidation.message}
                      </p>
                    )}
                  </div>
                </div>
              :
              null
          }

          <div className="shiftDetailsSubSection">
            <div className="cs__button-container">
              <Button
                style={{ padding: '12px 6px 12px 6px' }}
                large
                white
                outline
                shadow={false}
                onClick={() => {
                  props.closeSidePanel();
                  dispatch(createShiftActions.removeTemplateShift(props.shiftKey));
                  if (!templateEdited) dispatch(setTemplateEdited());
                }}
              >
                Delete Template Shift
              </Button>
              <Button
                style={{ padding: '12px 6px 12px 6px' }}
                large
                black
                shadow={false}
                disabled={!isValid}
                onClick={props.closeSidePanel}
              >
                Save Template Shift
              </Button>
            </div>
          </div>

        </div>

      </div>
    </SidePanel>
  );
}
