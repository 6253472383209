import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  MdOutlineCalendarToday,
  MdOutlineAccessTime,
  MdInsertChartOutlined,
  MdOutlineHelpOutline,
  MdPeopleOutline,
  MdOutlineChatBubbleOutline,
} from 'react-icons/md';

import { featureFlags } from 'config/featureFlags';
import { permissions } from 'config/permissions';
import { isFeatureOn } from 'lib/features';
import colors from 'config/colors';

import Page from 'components/Page';
import Feature from 'components/Feature';

import './Dashboard.scss';

const supplierItems = [
  {
    icon: MdOutlineCalendarToday,
    title: 'Shifts',
    url: '/shifts',
    featureFlag: featureFlags.SUPPLIER,
  },
];
const employerItems = [
  {
    title: 'Shifts',
    url: '/shifts',
    flag: featureFlags.JOBS,
    icon: MdOutlineCalendarToday,
  },
  {
    title: 'Time & Pay',
    url: '/time-and-pay/timesheets',
    featureFlag: featureFlags.TIMESHEETS_ADMIN,
    icon: MdOutlineAccessTime,
  },
  {
    title: 'Staff Bank',
    url: '/bank/all',
    permissionRequired: permissions.BANK_CAN_VIEW,
    icon: MdPeopleOutline,
  },
  {
    icon: MdInsertChartOutlined,
    title: 'Reporting',
    url: '/reporting/dashboard',
    featureFlag: featureFlags.REPORTING,
  },
  {
    title: 'Messaging',
    url: '/messaging/inbox',
    featureFlag: featureFlags.MESSAGING,
    icon: MdOutlineChatBubbleOutline,
  },
  {
    icon: MdOutlineHelpOutline,
    title: 'Help Centre',
    url: '/help',
    featureFlag: featureFlags.HELP_CENTRE,
  },
];

const Manage = (props) => {
  let items = null;

  if (isFeatureOn(featureFlags.SUPPLIER, null, props.user, props.global)) {
    items = supplierItems;
  } else {
    items = employerItems;
  }

  return (
    <Page title="Home" backgroundColor={colors.cavalry.backgroundLight6}>
      <div className="manageItems">
        {items.map(item => (
          <Feature
            featureFlag={item.featureFlag}
            permissionRequired={item.permissionRequired}
            key={item.url}
          >
            <Link className="manageItem space-children-24--bottom" to={item.url}>
              <span className="innerIcon"><item.icon /></span>
              <span className="manageItemTitle">{item.title}</span>
            </Link>
          </Feature>
        ))}
      </div>
    </Page>
  );
};

const { string } = PropTypes;

Manage.propTypes = {
  userId: string,
};

Manage.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ user, global }) => {
  return {
    user,
    global,
  };
};

export default connect(mapStateToProps)(Manage);
