import React from 'react';
import PropTypes from 'prop-types';
import { MdStar } from 'react-icons/md';

export const CandidateRatingStar = ({ rating, size }) => (
  <span className="applicant-rating">
    <p>{(Math.round(rating * 10) / 10)}</p>
    <MdStar size={size} className="rating__star--filled" />
  </span>
);
const { number } = PropTypes;
CandidateRatingStar.propTypes = { rating: number.isRequired, size: number };
CandidateRatingStar.defaultProps = { size: 20 };
