import React, { useEffect, CSSProperties, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';

import colors from 'config/colors';
import spacing from 'config/spacing';

import * as createShiftActions from 'reducers/createShifts';
import * as templateActions from 'reducers/templates';
import * as templateThunks from 'thunks/templates';

import TemplateWeekCalendar from '../WeekCalendar/TemplateWeekCalendar';
import TemplateDayView from './TemplateDayView';
import TemplatesPage from './TemplatesPage';
import EditTemplateShift from './EditTemplateShift';

interface Styles {
  [key: string]: CSSProperties,
}

const styles: Styles = {
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface BlankTemplateProps {
  templateShiftKey?: string,
  goToEditTemplateShift: (templateShiftKey: string) => void
  closeEditTemplateShift: () => void
  goToApplyTemplatePeriodView: () => void
  goToTemplateEditView: (templateKey: string) => void,
}

export default function BlankTemplate(props: BlankTemplateProps) : React.ReactElement {

  const dispatch = useAppDispatch();
  const templateDisabledSitesError = useSelector(({ templates }) => templates.templateDisabledSitesError);
  const template = useSelector(({ templates }) => templates.template);

  useEffect(() => {
    if (!template) dispatch(templateThunks.createBlankTemplate('week'));
  }, [template?.key]);

  useEffect(() => {
    return () => {
      dispatch(templateActions.clearTemplate());
      dispatch(createShiftActions.clearTemplateShifts());
    };
  }, []);

  const createTemplate = useCallback(async () => {
    const response = await dispatch(templateThunks.createTemplate());
    // If template created successfully, navigate user to edit template view
    if (response?.success && response?.key) props.goToTemplateEditView(response.key);
  }, []);

  if (templateDisabledSitesError) {
    return (
      <TemplatesPage goToEditTemplateShift={props.goToEditTemplateShift} goToApplyTemplatePeriodView={props.goToApplyTemplatePeriodView} saveTemplate={createTemplate} templateKey="new">
        <div style={styles.errorContainer}>
          <p style={{ color: colors.text, marginBottom: spacing.base }}>{templateDisabledSitesError}</p>
        </div>
      </TemplatesPage>
    );
  }

  const templateViewProps = {
    goToEditTemplateShift: props.goToEditTemplateShift,
    selectedShift: props.templateShiftKey,
    goToApplyTemplatePeriodView: props.goToApplyTemplatePeriodView,
    saveTemplate: createTemplate,
    templateKey: 'new',
  };

  return (
    <>
      {template?.periodType === 'week' && <TemplateWeekCalendar {...templateViewProps} />}
      {template?.periodType === 'day' && <TemplateDayView {...templateViewProps} />}
      {props.templateShiftKey && <EditTemplateShift closeSidePanel={props.closeEditTemplateShift} shiftKey={props.templateShiftKey} />}
    </>
  );
}

BlankTemplate.defaultProps = { templateShiftKey: undefined };
