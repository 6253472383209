const TELEPHONE_FORMATS = [
  { initialDigits: '011', format: [4, 3, 4] },
  { initialDigits: '02', format: [3, 4, 4] },
  { initialDigits: '0121', format: [4, 3, 4] },
  { initialDigits: '0131', format: [4, 3, 4] },
  { initialDigits: '0141', format: [4, 3, 4] },
  { initialDigits: '0151', format: [4, 3, 4] },
  { initialDigits: '0161', format: [4, 3, 4] },
  { initialDigits: '0171', format: [4, 3, 4] },
  { initialDigits: '0181', format: [4, 3, 4] },
  { initialDigits: '0191', format: [4, 3, 4] },
  { initialDigits: '0121', format: [4, 3, 4] },
  { initialDigits: '0345', format: [4, 3, 4] },
  { initialDigits: '0800', format: [4, 3, 4] },
];

export function formatUKTel(tel) {
  if (tel) {
    const cleaned = tel.toString().toLowerCase().trim().replace(' ', '');
    let format = [5, 3, 3]; // default UK phone format
    for (let i = 0, len = TELEPHONE_FORMATS.length; i < len; i += 1) {
      if (cleaned.indexOf(TELEPHONE_FORMATS[i].initialDigits) === 0) {
        format = TELEPHONE_FORMATS[i].format; // eslint-disable-line
        break;
      }
    }
    const code = cleaned.slice(0, format[0]);
    const first = cleaned.slice(format[0], (format[0] + format[1]));
    const second = cleaned.slice((format[0] + format[1]));
    return `${code} ${first} ${second}`;
  }
  return '';
}

export function formatTelWithExtension(tel, ext) {
  const extStr = ext ? ` ext ${ext}` : '';
  return `${formatUKTel(tel)}${extStr}`;
}

export function formatUKMobile(mob) {
  if (mob) {
    return `${mob.slice(0, 5)} ${mob.slice(5, 8)} ${mob.slice(8)}`;
  }
  return '';
}
