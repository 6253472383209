import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import { FaPencilAlt } from 'react-icons/fa';
import * as api from '../../../lib/api';
import StatusPill from '../../../components/StatusPill';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { convertFromUtcToTimezone } from '../../../lib/helpers-time';
import colors from '../../../config/colors';
import spacing from '../../../config/spacing';
import fonts from '../../../config/fonts';
import CloseButton from '../../../components/CloseButton';
import { capitalize } from '../../../lib/helpers';

import WarningModal from './WarningModal';
import { permissions } from '../../../config/permissions';
import Feature from '../../../components/Feature';
import { formatHourlyRate } from '../helpers';

const styles = {
  listItem: {
    width: '36%',
    fontWeight: 500,
    color: '#999',
  },
  listItemValue: {
    width: '64%',
    color: colors.text,
  },
};

const MomentType = moment().constructor;
const { bool, string, func, object, instanceOf } = PropTypes;
class DetailsTab extends Component {
  static propTypes = {
    jobId: string.isRequired,
    shift: object,
    closeSidePanel: func.isRequired,
    showBookingStatus: bool.isRequired,
    showCancelShiftButton: bool,
    hasPreferredGender: bool,
    costCentresOn: bool.isRequired,
    isSupplier: bool,
    orgKey: string.isRequired,
    onShiftChange: func.isRequired,
    selectedDate: instanceOf(MomentType).isRequired,
    orgCanEditShift: bool.isRequired,
    goToEditShift: func.isRequired,
  }

  static defaultProps = {
    showCancelShiftButton: false,
    shift: null,
    hasPreferredGender: false,
    isSupplier: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteModalOpen: false,
      noCancelShiftModalOpen: false,
      showWarningModal: false,
      warningModalText: null,
      cancellingShift: false,
    };
  }

  formatTime = (date, timezone) => {
    return convertFromUtcToTimezone(date, timezone).format('HH:mm');
  }

  formatDate = (date, timezone) => {
    return convertFromUtcToTimezone(date, timezone).format('dddd DD MMMM YYYY');
  }

  deleteShift = async () => {
    this.setState({ deleteModalOpen: false, cancellingShift: true });
    try {
      const response = await api.post('jobs/cancel', { jobId: this.props.jobId });
      // If an approved timesheet is found, show message to user
      if (response.body.approvedTimesheetsFound) {
        this.setState({ noCancelShiftModalOpen: true, cancellingShift: false });
      } else {
        // Otherwise close side panel.
        await this.props.onShiftChange();
        this.setState({ cancellingShift: false });
        this.props.closeSidePanel();
      }
    } catch (e) {
      console.log('e: ', e);
    }
  }

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
  }

  selectTimeTypes = (timesheetTypeKey) => {
    if (timesheetTypeKey === 'on-site-off-site') {
      return 'On Site / Off Site';
    } else if (timesheetTypeKey === 'start-end-multi') {
      return 'Multiple Times';
    }
    return '100% On Site';
  }

  render() {
    const job = this.props.shift;
    const { showCancelShiftButton, hasPreferredGender, isSupplier, orgKey } = this.props;
    const bookedCandidate = (this.props.bookedCandidates || [])[0] ?? {};
    const bookedCandidateNames = (this.props.bookedCandidates || []).map(candidate => candidate.candidateName && capitalize(candidate.candidateName));
    const supplierNames = job.suppliers && (isSupplier ? [job.suppliers[orgKey]] : Object.values(job.suppliers));
    const showCostCentreInfo = this.props.costCentresOn;
    const bookedCandidateNamesText = bookedCandidateNames.length === 0 ? 'None' : `${bookedCandidateNames[0]}${bookedCandidateNames.length > 1 ? ` + ${bookedCandidateNames.length - 1} more` : ''}`;

    let rate;
    if (job.customHourlyRate) {
      rate = 'Custom Rate';
    } else if (job.rateModifierName) {
      rate = job.rateModifierName;
    } else {
      rate = 'Standard Rate';
    }

    return (
      <>
        <div className="shiftDetailsViewContainer">
          {job && (
          <>
            <div className="shiftDetailsSubSection">
              <div className="shiftDetailsSubSectionTitle" style={{ justifyContent: 'space-between', alignItems: 'center', flex: 1, marginBottom: 12 }}>
                <p className="shiftDetailsSubHeading" style={{ marginBottom: 0 }}>Candidate Specifications</p>
                {this.props.orgCanEditShift && (
                  <Button
                    onClick={() => this.props.goToEditShift()}
                    white
                    outline
                    shadow={false}
                    style={{ padding: '9px 12px' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span style={{ marginRight: spacing.small }}>Edit</span>
                      <FaPencilAlt size={12} />
                    </div>
                  </Button>
                )}
              </div>
              <div className="shiftDetailsSubSectionContent">
                <div className="shiftDetailsBoldHeadings">
                  <p className="detail-list-item">Role</p>
                  <p className="detail-list-item">Grade</p>
                  <p className="detail-list-item">Speciality</p>
                  <p className="detail-list-item">Rate</p>
                  {job.customHourlyRate && <p className="detail-list-item">Hourly Rate</p>}
                  {hasPreferredGender ? <p className="detail-list-item">Gender</p> : null }
                </div>
                <div className="shiftDetailsValues">
                  <p className="detail-list-item">{job?.roleName ?? 'Any'}</p>
                  <p className="detail-list-item">{job?.gradeName ?? 'Any'}</p>
                  <p className="detail-list-item">{job?.specialityName ?? 'Any'}</p>
                  <p className="detail-list-item">{rate}</p>
                  {job.customHourlyRate && <p className="detail-list-item">{`£${formatHourlyRate(job.customHourlyRate)}`}</p>}
                  {hasPreferredGender ? <p className="detail-list-item">{job.genderName || 'N/A'}</p> : null}
                </div>
              </div>
            </div>
            <div className="shiftDetailsSubSection">
              <div className="shiftDetailsSubSectionTitle">
                <p className="shiftDetailsSubHeading">Shift Details</p>
              </div>
              <div className="shiftDetailsSubSectionContent" style={{ display: 'flex', flexDirection: 'column' }}>
                {!!job.serviceName && (
                  <div style={{ display: 'flex' }}>
                    <p className="detail-list-item" style={styles.listItem}>Service</p>
                    <p className="detail-list-item" style={styles.listItemValue}>{job.serviceName}</p>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <p className="detail-list-item" style={styles.listItem}>Site</p>
                  <p className="detail-list-item" style={styles.listItemValue}>{job.siteName}</p>
                </div>
                {!!job.areaName && (
                  <div style={{ display: 'flex' }}>
                    <p className="detail-list-item" style={styles.listItem}>Area</p>
                    <p className="detail-list-item" style={styles.listItemValue}>{job.areaName}</p>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <p className="detail-list-item" style={styles.listItem}>Date</p>
                  <p className="detail-list-item" style={styles.listItemValue}>{this.formatDate(job.startTime, job.timezone)}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p className="detail-list-item" style={styles.listItem}>Times</p>
                  <p className="detail-list-item" style={styles.listItemValue}>{`${this.formatTime(job.startTime, job.timezone)} - ${this.formatTime(job.endTime, job.timezone)}`}</p>
                </div>
              </div>
            </div>
            <div className="shiftDetailsSubSection">
              <div className="shiftDetailsSubSectionTitle">
                <p className="shiftDetailsSubHeading">Additional Information</p>
              </div>
              <div>
                <div style={{ display: 'flex' }}>
                  <p className="additionalDetailsBoldHeading">Reason</p>
                  <div className="additionalDetailsValue">
                    <p className="detail-list-item">{job.reason || 'No reason provided'}</p>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <p className="additionalDetailsBoldHeading">Sub-Reason</p>
                  <div className="additionalDetailsValue">
                    <p className="detail-list-item">{job.subReason || 'No sub-reason'}</p>
                  </div>
                </div>
                {job.publicDescription && (
                  <div style={{ display: 'flex' }}>
                    <p className="additionalDetailsBoldHeading">Public Description</p>
                    <div className="additionalDetailsValue">
                      <p className="detail-list-item">{job.publicDescription}</p>
                    </div>
                  </div>
                )}
                {job.privateNotes && (
                  <div style={{ display: 'flex' }}>
                    <p className="additionalDetailsBoldHeading">Private Notes</p>
                    <div className="additionalDetailsValue">
                      <p className="detail-list-item">{job.privateNotes}</p>
                    </div>
                  </div>
                )}
                {job.timesheetTypeKey && (
                  <div style={{ display: 'flex' }}>
                    <p className="additionalDetailsBoldHeading">Timesheet Type</p>
                    <div className="additionalDetailsValue">
                      <p className="detail-list-item">{this.selectTimeTypes(job.timesheetTypeKey)}</p>
                    </div>
                  </div>
                )}
                {showCostCentreInfo && (
                  <>
                  <div style={{ display: 'flex' }}>
                    <p className="additionalDetailsBoldHeading">Cost Centre ID</p>
                    <div className="additionalDetailsValue">
                      <p className="detail-list-item">{job?.costCentreKey ?? 'None'}</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <p className="additionalDetailsBoldHeading">Cost Centre Name</p>
                    <div className="additionalDetailsValue">
                      <p className="detail-list-item">{job?.costCentreName ?? 'None'}</p>
                    </div>
                  </div>
                  </>
                )}
              </div>
            </div>
            <div className="shiftDetailsSubSection">
              {this.props.showBookingStatus ?
                <div className="shiftDetailsSubSectionTitleBooking">
                  <div className="bookingTitleBox">
                    <p className="shiftDetailsSubHeading">Booking Status</p>
                  </div>
                  <div className="viewsApplicantsBox">
                    <p style={{ color: colors.text, fontSize: '0.9rem' }}>{`${job.views || 0} ${job.views === 1 ? 'view' : 'views'}`}</p>
                    <p style={{ color: colors.text, fontSize: '0.9rem' }} className="appliesText">{`${this.props.numberOfApplications} ${this.props.numberOfApplications === 1 ? 'applicant' : 'applicants'}`}</p>
                  </div>
                </div>
                : null
                }
              <div className="shiftDetailsSubSectionContent">
                <div className="shiftDetailsBoldHeadings">
                  <p className="detail-list-item">Status</p>
                  <p className="detail-list-item">Booked Staff</p>
                  <p className="detail-list-item">Available to</p>
                </div>
                <div className="shiftStatusAndAvailabilityBox">
                  {/* <StatusPill job={{ ...job, orgKey, ...bookedCandidate, fromDetails: true }} /> */}
                  <p style={{ color: colors.text, fontSize: '0.9rem' }}>{job.slotsFilled} of {job.slotsRequired} filled</p>
                  <p className="detail-list-item" style={{ color: colors.text }}>{bookedCandidateNamesText}</p>
                  {job.banks && Object.values(job.banks).map(bank => <p className="detail-list-item" style={{ color: colors.text }}>{bank}</p>)}
                  {supplierNames ? supplierNames.map((name) => { return <p style={{ color: colors.text }}>{name}</p>; }) : null}
                </div>
              </div>
            </div>
          </>
          )}
          {!job && !this.props.fetchingShift && (
          <div style={{ color: colors.positive, textAlign: 'center', marginTop: 20 }}>
            <p>No job details</p>
          </div>
          )}
          {this.props.fetchingShift && (
          <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Loading />
          </div>
          )}
          <Modal
            isOpen={this.state.deleteModalOpen}
            onRequestClose={this.closeDeleteModal}
            contentLabel="delete job"
          >
            <CloseButton top={7} right={7} handleClose={this.closeDeleteModal} />
            <div style={{ textAlign: 'center', maxWidth: '500px' }}>
              <h1 style={{ color: colors.positive, fontSize: fonts.header.size, fontWeight: fonts.header.weight, margin: spacing.base }}>Do you wish to cancel this shift?</h1>
              <MdWarning size={40} style={{ color: colors.warning }} />
              <p style={{ marginTop: spacing.large }}>If a candidate is booked they will be notified.</p>
              <p style={{ marginBottom: spacing.large }}>There is no way to undo this action.</p>

              <Button
                onClick={this.closeDeleteModal}
                style={{ marginRight: spacing.base, backgroundColor: colors.negative }}
              >
                Undo
              </Button>
              <Button
                onClick={() => this.deleteShift()}
              >
                Confirm
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={this.state.noCancelShiftModalOpen}
            contentLabel="No cancel shift"
            onRequestClose={() => this.setState({ noCancelShiftModalOpen: false })}
          >
            <div style={{ color: colors.positive }}>
              <h3>The shift cannot be cancelled as there is an approved timesheet associated with it</h3>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: spacing.base }}>
              <Button
                onClick={() => this.setState({ noCancelShiftModalOpen: false })}
                style={{ backgroundColor: colors.negative }}
              >
                Close
              </Button>
            </div>
          </Modal>
          <WarningModal
            isOpen={this.state.showWarningModal}
            contentLabel="Warning"
            onRequestClose={() => this.setState({ showWarningModal: false, warningModalText: null })}
            warningModalText={this.state.warningModalText}
          />
        </div>
        {showCancelShiftButton ?
          <Feature permissionRequired={permissions.JOBS_CAN_DELETE_JOBS}>
            <div className="shiftDetailsButtonContainer">
              <Button large danger fullWidth style={{ margin: '12px', padding: 0, height: 40 }} onClick={() => this.setState({ deleteModalOpen: true })} loading={this.state.cancellingShift} loadingContent={<Loading size={40} red />}>Cancel Shift</Button>
            </div>
          </Feature>
          :
          null
        }
      </>
    );
  }
}

export default DetailsTab;
