import React, { useRef } from 'react';
import classNames from 'classnames';
import FilterItem from 'components/FilterItem';
import CWSelect from './CWSelect';
import Checkbox from '../Checkbox';

interface CWSelectItem {
  value: string;
  label: string;
  isSelected?: boolean;
}

interface CWFilterSelectProps {
  items: Array<CWSelectItem>;
  selectedItems: Array<string>;
  setSelectedItems: (items: Array<string>) => unknown
  noneSelectedLabel: string;
  truncateButtonLabel?: boolean;
  isMulti?: boolean
}

const CWFilterSelect: React.FC<CWFilterSelectProps> = (props: CWFilterSelectProps) => {
  const buttonRef = useRef<HTMLElement | undefined>();

  return (
    <CWSelect
      items={props.items}
      selectedItems={props.selectedItems}
      setSelectedItems={props.setSelectedItems}
      noneSelectedLabel={props.noneSelectedLabel}
      truncateButtonLabel={props.truncateButtonLabel}
      buttonRef={buttonRef}
      button={({ title, onClick, onKeyDown }) => (
        <FilterItem
          ref={buttonRef}
          title={title}
          onClick={onClick}
          onKeyDown={onKeyDown}
        />
      )}
      menuItem={({ item, closeMenu, selectSingleItem, deselectItem, selectItem, isMultiSelecting, isFocussed }) => (
        <CWSelectItem
          item={item}
          closeMenu={closeMenu}
          selectSingleItem={selectSingleItem}
          deselectItem={deselectItem}
          selectItem={selectItem}
          isMultiSelecting={isMultiSelecting}
          isFocussed={isFocussed}
          isMulti={props.isMulti}
        />
      )}
    />
  );
};

CWFilterSelect.defaultProps = { truncateButtonLabel: false, isMulti: true };

interface CWSelectItemProps {
  item: CWSelectItem;
  closeMenu: (shouldFocusButton?: boolean) => void;
  selectSingleItem: (item: CWSelectItem) => void;
  deselectItem: (item: CWSelectItem) => void;
  selectItem: (item: CWSelectItem) => void;
  isMultiSelecting: boolean;
  isFocussed: boolean;
  isMulti?: boolean
}

const CWSelectItem : React.FC<CWSelectItemProps> = (props: CWSelectItemProps) => {

  const { item, closeMenu, selectSingleItem, deselectItem, selectItem, isMultiSelecting, isFocussed } = props;

  return (
    <div
      key={item.value}
      role="button"
      tabIndex={0}
      data-select-menu-item={item.value}
      className={classNames('cw-select__item', {
        'cw-select__item--selected': item.isSelected,
        'cw-select__item--focussed': isFocussed,
      })}
      onClick={() => {
        if (isMultiSelecting) {
          selectSingleItem(item);
          closeMenu(false);
        } else {
          if (item.isSelected) {
            deselectItem(item);
          } else {
            selectItem(item);
          }
        }
      }}
      onKeyPress={(event) => {
        const keyCode = event.which || event.keyCode;
        switch (keyCode) {
          case 13: // Enter
            event.preventDefault();
            event.stopPropagation();
            if (isMultiSelecting) {
              selectSingleItem(item);
            }
            closeMenu();
            break;
          case 32: // Space
            event.preventDefault();
            event.stopPropagation();
            if (item.isSelected) {
              deselectItem(item);
            } else {
              selectItem(item);
            }
            break;
          default:
            break;
        }
      }}
      onKeyDown={(event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === 27) closeMenu(); // Escape
      }}
    >
      <Checkbox
        disabled={!props.isMulti}
        size={14}
        boxStyle={{ marginTop: 3, backgroundColor: 'white', borderColor: item.isSelected ? 'white' : '#999' }}
        checked={!!item.isSelected}
        onChange={() => (item.isSelected ? deselectItem(item) : selectItem(item))}
      />
      <div className="cw-select__label">{item.label}</div>
    </div>
  );
};

CWSelectItem.defaultProps = { isMulti: true };

export default CWFilterSelect;
