const HOSTNAME = (typeof window !== 'undefined') ? window.location.hostname : 'localhost';

const LOCAL_BASE_URL = `http://${HOSTNAME}:9000`;
export const BASE_URL = process.env.NODE_ENV === 'production' ? '' : LOCAL_BASE_URL;
export const BLOG_URL = 'https://medium.com/@wildfireapp';

let DEV_SETTINGS = {};
if (process.env.REACT_APP_PRODUCTION !== 'y' && window.location.hostname === 'localhost') {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${LOCAL_BASE_URL}/api/dev-settings`, false);  // synchronous request
    xhr.send(null);
    DEV_SETTINGS = JSON.parse(xhr.responseText);
  } catch (e) {
    console.error(e);
  }
}

function isTruthy(input) {
  return input && ['t', 'y', 'true'].includes(input.toLowerCase());
}

export const HASURA_HOST = (DEV_SETTINGS.REACT_APP_HASURA_HOST || process.env.REACT_APP_HASURA_HOST || 'localhost:3399')?.replace('localhost', HOSTNAME);
export const HASURA_USE_SSL = isTruthy(DEV_SETTINGS.REACT_APP_HASURA_USE_SSL ?? process.env.REACT_APP_HASURA_USE_SSL ?? false);
export const HASURA_SECRET = DEV_SETTINGS.REACT_APP_HASURA_SECRET || process.env.REACT_APP_HASURA_SECRET || 'someAppSecret';

export const FIREBASE_AUTH_EMULATOR_HOST = (DEV_SETTINGS.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST || process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST)?.replace('localhost', HOSTNAME) || null;
export const FIREBASE_EMULATOR_HOST = (DEV_SETTINGS.REACT_APP_FIREBASE_EMULATOR_HOST || process.env.REACT_APP_FIREBASE_EMULATOR_HOST)?.replace('localhost', HOSTNAME) || null;
export const FIREBASE_DATABASE_EMULATOR_HOST =(DEV_SETTINGS.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST || process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST)?.replace('localhost', HOSTNAME) || null;

export const firebaseConfig = {
  apiKey: DEV_SETTINGS.REACT_APP_FIREBASE_API_KEY || process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyAiHkm2KetGWvQtJ9lTBOBoQ4j9wMZIf14',
  authDomain: DEV_SETTINGS.REACT_APP_FIREBASE_AUTH_DOMAIN || process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'wildfire-dev-13be6.firebaseapp.com',
  databaseURL: DEV_SETTINGS.REACT_APP_FIREBASE_DATABASE_URL || process.env.REACT_APP_FIREBASE_DATABASE_URL || 'https://wildfire-dev-13be6.firebaseio.com',
  projectId: DEV_SETTINGS.REACT_APP_FIREBASE_PROJECT_ID || process.env.REACT_APP_FIREBASE_PROJECT_ID || 'wildfire-dev-13be6',
  storageBucket: DEV_SETTINGS.REACT_APP_FIREBASE_STORAGE_BUCKET || process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'wildfire-dev-13be6.appspot.com',
  messagingSenderId: DEV_SETTINGS.REACT_APP_MESSAGING_SENDER_ID || process.env.REACT_APP_MESSAGING_SENDER_ID || '175455726069',
};

export const AGENCY_ACTIONS = {
  REQUEST: 'request',
  APPROVE: 'approve',
  RELEASE: 'release',
  SENT: 'sent',
};

export const INTERCOM_APP_ID = process.env.REACT_APP_PRODUCTION === 'y' ? 'f3ylmrh1' : 'ik5dhd9y';

export const INTERCOM_ENABLED = true;

export const RGS_URL = 'metadata/-KHih8_9J9p740mxQiYN';

export const LOGGING_ENABLED = process.env.REACT_APP_LOGGING_ENABLED || false;
