import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Modal from '../../../components/Modal';
import CreateDraftsEventComponent from './CreateDraftsEventComponent';
import * as createShiftReducer from '../../../reducers/createShifts';
import { Calendar } from '../../../components/Calendar';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import colors from '../../../config/colors';
import spacing from '../../../config/spacing';
import fonts from '../../../config/fonts';
import './createShift.scss';

const styles = {
  dateButton: {
    color: colors.text,
    padding: 6,
    fontSize: fonts.textMain.size,
    fontWeight: fonts.headerSub.weight,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  dateTitle: {
    minWidth: '250px',
    margin: '0 12px',
    textAlign: 'center',
    fontSize: fonts.headerSub.size,
    fontWeight: fonts.headerSub.weight,
    color: colors.text,
  },
};

const { string, bool, func, objectOf, object } = PropTypes;

class MultipleDraftShiftModal extends Component {

  static propTypes = {
    originalDraftShiftDate: string.isRequired,
    originalDraftShiftEndTime: string.isRequired,
    onCancel: func.isRequired,
    shiftHasTargetedCandidate: bool,
    originalDraftShiftId: string.isRequired,
    onSuccess: func.isRequired,
    dispatch: func.isRequired,
    originalDraftShift: objectOf(object).isRequired,
  }

  static defaultProps = {
    shiftHasTargetedCandidate: false,
  }

  constructor(props) {
    super();
    this.state = {
      currentDate: moment(props.originalDraftShiftDate),
      events: {},
    };
  }

  componentDidMount() {
    // Initialise calendar with original draft date
    const events = {};
    events[this.state.currentDate.format('YYYY-MM-DD')] = { count: 1 };
    this.setState({ events });
  }

  incrementCount = (date) => {
    this.setState(({ events }) => {
      const selectedDay = date.format('YYYY-MM-DD');
      const initialCount = events[selectedDay] && events[selectedDay].count;
      const newCount = (initialCount || 0) + 1;
      return {
        events: { ...events, [selectedDay]: { count: newCount } },
      };
    });
  }

  decrementCount = (date) => {
    this.setState((prevState) => {
      const updatedEvents = { ...prevState.events };
      const selectedDay = date.format('YYYY-MM-DD');
      updatedEvents[selectedDay] = { count: updatedEvents[selectedDay].count - 1 };
      return { events: updatedEvents };
    });
  }

  createMultipleDrafts = () => {
    const originalDraftShiftDate = moment(this.props.originalDraftShiftDate).format('YYYY-MM-DD');
    const originalStartTime = moment(this.props.originalDraftShiftDate).format('HH:mm');
    const originalEndTime = moment(this.props.originalDraftShiftEndTime).format('HH:mm');
    const newDraftShifts = {};
    this.setState({ creatingDrafts: true });

    setTimeout(() => {
      Object.keys(this.state.events).forEach((dateString) => {
        let count = this.state.events[dateString].count;
        // Remove 1 from originalDraft count. This is already in Redux before accessing modal.
        if (dateString === originalDraftShiftDate) count -= 1;
        for (let i = 0; i < count; i += 1) {
          // Concatinate strings from original draft start and end times along with new date
          const newStartTime = moment(dateString + originalStartTime, 'YYYY-MM-DD HH:mm');
          // If start time is after end time, add one day to end time
          const newEndTime = moment(originalStartTime < originalEndTime ? dateString + originalEndTime : moment(dateString).add(1, 'day').format('YYYY-MM-DD') + originalEndTime, 'YYYY-MM-DD HH:mm');
          const draftId = uuidv4();
          newDraftShifts[draftId] = { ...this.props.originalDraftShift, startTime: newStartTime.toISOString(), endTime: newEndTime.toISOString() };
        }
      });
      this.props.dispatch(createShiftReducer.addMultipleDraftShifts(newDraftShifts));
      if (!this.state.events[originalDraftShiftDate].count) this.props.dispatch(createShiftReducer.removeDraftShift(this.props.originalDraftShiftId));
      this.props.onSuccess();
    }, 500);
  }

  render() {

    const shiftCount = Object.values(this.state.events).reduce((count, event) => count + event.count, 0);

    return (
      <Modal
        contentStyle={{ height: '90vh', width: '80vw' }}
        vflex
        // bodyStyle={{ padding: spacing.base }}
        isOpen
        onRequestClose={this.props.onCancel}
        header="Draft Shift / Multiple"
      >
        {this.state.creatingDrafts ?
          <Loading styles={{ alignSelf: 'center' }} />
          :
          <>
            <MultipleDraftNavBar
              previousMonth={this.state.currentDate.clone().subtract(1, 'month').format('MMM YYYY')}
              currentDate={this.state.currentDate.clone().format('MMMM YYYY')}
              nextMonth={this.state.currentDate.clone().add(1, 'month').format('MMM YYYY')}
              navigateToPreviousMonth={() => this.setState(prevState => ({ currentDate: prevState.currentDate.subtract(1, 'month') }))}
              navigateToNextMonth={() => this.setState(prevState => ({ currentDate: prevState.currentDate.add(1, 'month') }))}
              shiftHasTargetedCandidate={this.props.shiftHasTargetedCandidate}
            />
            <div style={{ flex: '1 1 auto', padding: 12 }}>
              <Calendar
                date={this.state.currentDate}
                events={this.state.events}
                eventComponent={(eventProps) => {
                  return (
                    <CreateDraftsEventComponent
                      {...eventProps}
                      incrementCount={this.incrementCount}
                      decrementCount={this.decrementCount}
                      shiftHasTargetedCandidate={this.props.shiftHasTargetedCandidate}
                    />
                  );
                }}
              />
            </div>
            <MultipleDraftShiftFooter
              createMultipleDrafts={this.createMultipleDrafts}
              originalDraftDate={moment(this.props.originalDraftShiftDate).format('YYYY-MM-DD')}
              events={this.state.events}
              confirmButtonDisabled={shiftCount === 0}
              onCancel={this.props.onCancel}
            />
          </>
        }
      </Modal>
    );
  }
}

const MultipleDraftNavBar = props => (
  <div className="multiple-drafts-nav">
    <p className="multiple-drafts-nav__text">
      {`Click on a date to add a draft shift.${props.shiftHasTargetedCandidate ? ' The original draft has a targeted candidate. Only one draft per day can be selected.' : ''}`}
    </p>
    <div className="multiple-drafts-nav__buttons-container">
      <div style={styles.dateButton} onClick={() => props.navigateToPreviousMonth()}>
        <MdArrowBack size={20} style={{ marginRight: spacing.tiny }} />
        {props.previousMonth}
      </div>
      <p style={styles.dateTitle}>{props.currentDate}</p>
      <div style={styles.dateButton} onClick={() => props.navigateToNextMonth()}>
        {props.nextMonth}
        <MdArrowForward size={20} style={{ marginLeft: spacing.tiny }} />
      </div>
    </div>
  </div>
);

MultipleDraftNavBar.propTypes = {
  navigateToPreviousMonth: func.isRequired,
  navigateToNextMonth: func.isRequired,
  previousMonth: string.isRequired,
  nextMonth: string.isRequired,
  currentDate: string.isRequired,
  shiftHasTargetedCandidate: bool,
};

MultipleDraftNavBar.defaultProps = { shiftHasTargetedCandidate: null };

const MultipleDraftShiftFooter = (props) => {
  return (
    <div className="multiple-drafts-footer space-children-12">
      <Button onClick={() => props.onCancel()} large white outline>Cancel</Button>
      <Button
        onClick={() => props.createMultipleDrafts()}
        large
        forSaveButton
        disabled={props.confirmButtonDisabled}
      >
        Save Drafts
      </Button>
    </div>
  );
};

MultipleDraftShiftFooter.propTypes = {
  onCancel: func.isRequired,
  createMultipleDrafts: func.isRequired,
  confirmButtonDisabled: bool.isRequired,
};

function mapStateToProps({ createShifts }) {
  return { creatingDrafts: createShifts.creatingDrafts };
}

export default connect(mapStateToProps)(MultipleDraftShiftModal);
