import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { FaAngleLeft } from 'react-icons/fa';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';

import './Help.scss';

const HelpDetailsContainer = () => {

  const history = useHistory();
  const loading = useSelector(state => state.help.loading);
  const helpData = useSelector(state => state.help.helpData);
  const { helpKey } = useParams();
  const helpDetails = helpKey && helpData?.[helpKey];

  if (loading) {
    return <Page title="Help Centre"><div>Loading...</div></Page>;
  }

  if (!helpDetails) {
    return <Page title="Help Centre"><p>Not Found</p></Page>;
  }

  return (
    <Page vflex title="Help Centre">
      <div className="helpCentreHeader">
        <PageHeader title="Help Centre" />
        <Button black shadow={false} onClick={() => history.replace('/help')} className="helpCentreBackButton test-id-help-back-button">
          <FaAngleLeft /> Back
        </Button>
      </div>
      <div className="helpCentre">
        <h1>{helpDetails.title}</h1>
        {helpDetails.descHtml ? <div className="descWrapper" dangerouslySetInnerHTML={{ __html: helpDetails.descHtml }} /> : null}
        {helpDetails.contentHtml ? <div className="contentWrapper" dangerouslySetInnerHTML={{ __html: helpDetails.contentHtml }} /> : null}
      </div>
    </Page>
  );
};

export default HelpDetailsContainer;
