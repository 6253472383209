import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import colors from 'config/colors';
import { featureFlags } from 'config/featureFlags';
import { isFeatureOn } from 'lib/features';
import * as bankThunks from 'thunks/bank';
import * as timesheetsThunks from 'thunks/timesheets';
import * as timesheetsReducers from 'reducers/timesheets';

import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Feature from 'components/Feature';
import Button from 'components/Button';
import ErrorModal from 'components/ErrorModal';

import TimesheetsListView from './TimesheetsListView';
import TimesheetsFilterBar from './TimesheetsFilterBar';
import { TimesheetRejectModal } from './TimesheetRejectModal';

const styles = {
  timesheetCount: {
    color: '#999',
    lineHeight: 1,
  },
  timesheetsHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: 12,
    backgroundColor: colors.cavalry.backgroundLight6,
  },
};

const { func, objectOf, object, bool, string, number, arrayOf, shape } = PropTypes;

class TimesheetsContainer extends Component {
  static propTypes = {
    timesheets: arrayOf(object),
    timesheetsTotalCount: number.isRequired,
    selectedTimesheetKey: string,
    fetchCandidates: func.isRequired,
    candidates: objectOf(object),
    goToDetailsView: func.isRequired,
    orgAdminGroups: arrayOf(object),
    userAdminGroups: arrayOf(string),
    isApprovingTimesheets: bool.isRequired,
    isFetchingTimesheets: bool.isRequired,
    isFetchingCandidates: bool,
    approveTimesheet: func.isRequired,
    rejectTimesheet: func.isRequired,
    timesheetFilters: shape({
      status: string,
      candidate: string,
      overdue: number.isRequired,
      adminGroup: string,
    }).isRequired,
    timesheetsPaginationsOffset: number.isRequired,
    timesheetsPaginationLimit: number.isRequired,
    fetchTimesheets: func.isRequired,
    resetTimesheetFilters: func.isRequired,
    setTimesheetsFilterValue: func.isRequired,
    updateTimesheetsPaginationState: func.isRequired,
    clearTimesheetsError: func.isRequired,
    timesheetsError: string.isRequired,
    orgSpecialities: arrayOf(shape({ key: string, name: string })).isRequired,
    specialitiesMetadata: objectOf(object).isRequired,
    fetchTimesheetRejectReasons: func.isRequired,
    timesheetsCanViewPermission: bool.isRequired,
    adminGroupsFeatureFlag: bool.isRequired,
    adminOrgKey: string.isRequired,
    adminSites: shape({
      key: string,
      name: string,
      abbr: string,
      orgKey: string,
      timezone: string,
      orgName: string,
      canManageShifts: bool,
      enabled: bool,
    }).isRequired,
  };

  static defaultProps = {
    isFetchingCandidates: false,
    candidates: {},
    orgAdminGroups: [],
    userAdminGroups: [],
    timesheets: [],
    selectedTimesheetKey: null,
  };

  state = { showRejectModal: false }

  componentDidMount() {
    if (this.props.timesheetsCanViewPermission) {
      this.props.fetchTimesheets();
      this.props.fetchCandidates();
      this.props.fetchTimesheetRejectReasons();
    }
  }

  componentWillUnmount() {
    this.props.resetTimesheetsState();
  }

  componentDidUpdate(prevProps) {
    // If filters or pagination settings change, refetch timesheets
    if (prevProps.timesheetFilters !== this.props.timesheetFilters || prevProps.timesheetsPaginationsOffset !== this.props.timesheetsPaginationsOffset || prevProps.timesheetsPaginationLimit !== this.props.timesheetsPaginationLimit) {
      if (this.props.timesheetsCanViewPermission) {
        this.props.fetchTimesheets();
      }
    }
  }

  openDetailsModal = ({ shiftKey, candidateKey, timesheetKey }) => this.props.goToDetailsView(shiftKey, candidateKey, timesheetKey);

  render() {

    if (!this.props.timesheetsCanViewPermission) {
      return (
        <Page vflex title="Timesheets" loading={this.props.isFetchingTimesheets}>
          <div style={styles.timesheetsHeaderContainer}>
            <PageHeader style={{ margin: 0 }} title="Timesheets" />
            <Feature featureFlag="paymentRuns" permissionRequired="payRunCanView">
              <Button onClick={() => this.props.goToPaymentRunListView()}>Manage Pay Runs</Button>
            </Feature>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <p style={{ color: colors.red }}>You do not have permission to view timesheets</p>
          </div>
        </Page>
      );
    }

    return (
      <Page vflex title="Timesheets" loading={this.props.isFetchingTimesheets}>
        <ErrorModal header="Timesheets Error" onCancel={() => this.props.clearTimesheetsError()} errorMessage={this.props.timesheetsError} />
        {!this.props.isMobile ?
          <div style={styles.timesheetsHeaderContainer}>
            <PageHeader style={{ margin: 0 }} title="Timesheets" />
            <Feature featureFlag="paymentRuns" permissionRequired="payRunCanView">
              <Button black shadow={false} onClick={() => this.props.goToPaymentRunListView()}>Manage Pay Runs</Button>
            </Feature>
          </div>
          :
          <div style={{ height: 9 }} />
        }
        <TimesheetsFilterBar
          timesheetFilters={this.props.timesheetFilters}
          orgSpecialities={this.props.orgSpecialities}
          candidates={this.props.candidates}
          orgAdminGroups={this.props.orgAdminGroups}
          isFetchingCandidates={this.props.isFetchingCandidates}
          setTimesheetsFilterValue={this.props.setTimesheetsFilterValue}
          resetTimesheetFilters={() => this.props.resetTimesheetFilters()}
          adminGroupsFeatureFlag={this.props.adminGroupsFeatureFlag}
          adminSites={Object.values(this.props.adminSites).map(site => site)}
        />
        <TimesheetsListView
          orgAdminGroups={this.props.orgAdminGroups}
          specialitiesMetadata={this.props.specialitiesMetadata}
          userAdminGroups={this.props.userAdminGroups}
          timesheets={this.props.timesheets}
          moreRowsAvailable={this.props.moreRowsAvailable}
          openDetailsModal={({ shiftKey, candidateKey, timesheetKey }) => this.openDetailsModal({ shiftKey, candidateKey, timesheetKey })}
          isFetchingTimesheets={this.props.isFetchingTimesheets}
          isApprovingTimesheets={this.props.isApprovingTimesheets}
          isRejectingTimesheets={this.props.isRejectingTimesheets}
          approveTimesheet={this.props.approveTimesheet}
          openRejectModal={(timesheetKey, candidateKey) => this.setState({ showRejectModal: true, timesheetKey, candidateKey })}
          updatePagination={(offset, limit) => this.props.updateTimesheetsPaginationState(offset, limit)}
          timesheetsTotalCount={this.props.timesheetsTotalCount}
          timesheetFilters={this.props.timesheetFilters}
          selectedTimesheetKey={this.props.selectedTimesheetKey}
          adminGroupsFeatureFlag={this.props.adminGroupsFeatureFlag}
          canManageLockedTimesheets={this.props.canManageLockedTimesheets}
          adminOrgKey={this.props.adminOrgKey}
          servicesFeatureFlag={this.props.servicesFeatureFlag}
        />
        {this.props.timesheetRejectReasons && (
          <TimesheetRejectModal
            rejectReasons={this.props.timesheetRejectReasons.map(reason => ({ value: reason.key, label: reason.name }))}
            modalOpen={this.state.showRejectModal}
            onClose={() => this.setState({ showRejectModal: false })}
            rejectTimesheet={rejectReason => this.props.rejectTimesheet(this.state.timesheetKey, this.state.shiftKey, this.state.candidateKey, { reasonKey: rejectReason.value, reasonText: rejectReason.label })}
          />
        )}
      </Page>
    );
  }
}

function mapStateToProps({ bank, global, timesheets, user, rgs, screen }) {

  // Only provide linkedSpecialities from roles specific to org
  const specialitiesMetadata = Object.values(rgs.rgsMetadata).map((roleData) => {
    return Object.entries(roleData.specialities).map(([specialityKey, specialityData]) => {
      return { key: specialityKey, name: specialityData.name };
    });
  }).flat();

  // Filter out duplicate specialities generated from multiple roles
  const uniqueSpecialitiesMetadata = Array.from(new Set(specialitiesMetadata.map(speciality => speciality.key)))
    .map(key => specialitiesMetadata.find(speciality => speciality.key === key));

  return {
    isMobile: screen.isMobile,
    timesheets: timesheets.timesheets,
    timesheetsTotalCount: timesheets.timesheetsTotalCount,
    moreRowsAvailable: timesheets.moreRowsAvailable,
    selectedTimesheetKey: timesheets.selectedTimesheetKey,
    isFetchingTimesheets: timesheets.isFetchingTimesheets,
    isFetchingCandidates: bank.isFetchingCandidates,
    candidates: bank.candidates,
    orgAdminGroups: global.orgAdminGroups,
    adminOrgKey: global.currentOrgKey,
    userAdminGroups: user.adminGroups,
    isApprovingTimesheets: timesheets.isApprovingTimesheets,
    isRejectingTimesheets: timesheets.isRejectingTimesheets,
    timesheetsPaginationsOffset: timesheets.timesheetsPaginationsOffset,
    timesheetsPaginationLimit: timesheets.timesheetsPaginationLimit,
    timesheetsError: timesheets.timesheetsError,
    timesheetFilters: timesheets.timesheetFilters,
    orgSpecialities: uniqueSpecialitiesMetadata,
    specialitiesMetadata: global.metadata.specialities,
    timesheetRejectReasons: timesheets.timesheetRejectReasons,
    adminGroupsFeatureFlag: isFeatureOn(featureFlags.ADMIN_GROUPS, null, user, global),
    servicesFeatureFlag: isFeatureOn(featureFlags.SERVICES, null, user, global),
    timesheetsCanViewPermission: isFeatureOn(null, 'timesheetsCanView', user, global),
    canManageLockedTimesheets: isFeatureOn(null, 'timesheetsCanManageLockedTimesheets', user, global),
    adminSites: user.sites,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCandidates: () => dispatch(bankThunks.fetchCandidates({ showCandidatesFromBanks: true })),
    fetchTimesheets: () => dispatch(timesheetsThunks.fetchTimesheets()),
    setTimesheetsFilterValue: (filterName, filterValue) => dispatch(timesheetsReducers.setTimesheetsFilterValue(filterName, filterValue)),
    resetTimesheetFilters: () => dispatch(timesheetsReducers.resetTimesheetFilters()),
    updateTimesheetsPaginationState: (offset, limit) => dispatch(timesheetsReducers.updateTimesheetsPaginationState(offset, limit)),
    approveTimesheet: (timesheetKey, shiftKey, candidateKey) => dispatch(timesheetsThunks.approveTimesheet(timesheetKey, shiftKey, candidateKey)),
    rejectTimesheet: (timesheetKey, shiftKey, candidateKey, rejectReason) => dispatch(timesheetsThunks.rejectTimesheet(timesheetKey, shiftKey, candidateKey, rejectReason)),
    clearTimesheetsError: () => dispatch(timesheetsReducers.clearTimesheetsError()),
    resetTimesheetsState: () => dispatch(timesheetsReducers.resetTimesheetsState()),
    fetchTimesheetRejectReasons: orgKey => dispatch(timesheetsThunks.fetchTimesheetRejectReasons(orgKey)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsContainer);
