import sha1 from 'sha1';

// The original list of script tags inside index.html gets changed onload/execution
// of javascript. So we have to exclude them, otherwise the comparasion of the current version
// of the app and the one hosted on the web app will have different results, thus "New version available" when
// there actually isn't.
const IGNORE_LIST = ['google-analytics', 'hot-update'];

// This get's the list of code dependencies in a block of HTML
// these are like `/static/js/bundle.somerandomstring.js`. So on each new version deployed they change
// thus using them for the comparasion.
const getScriptsList = (html) => {
  try {
    const regex = /<script.*?src="(.*?)">/gm; // The exec() method executes a search for a match in a specified string. Returns a result array, or null.
    let m;
    const results = [];

    while ((m = regex.exec(html)) !== null) { // eslint-disable-line
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex += 1;
      }

      // The result can be accessed through the `m`-variable.
      if (m.length >= 2 && IGNORE_LIST.every(ignoreItem => m[1].indexOf(ignoreItem) === -1)) { // eslint-disable-line
        results.push(m[1]);
      }
    }

    return results.sort();
  } catch (e) {
    return [];
  }
};

// Gets the list of src urls from script tags
// in a block of html, orders them alphabetically
// then sha1 (hashes) them.
export const hashPageScriptTags = (html) => {
  const scripts = getScriptsList(html);
  return sha1(scripts.join(''));
};


// Trys to get HTML source of page until success
export const getPageSource = () => {

  // List of HTML source functions, so different versions of browsers all work reliably.
  const htmlFuncs = [
    () => window.document.documentElement.outerHTML,
    () => window.document.body.parentNode.outerHTML,
    () => window.document.getElementsByTagName('html')[0].outerHTML,
  ];

  for (let i = 0; i < htmlFuncs.length; i += 1) {
    try {
      return htmlFuncs[i]();
    } catch (e) {
      console.log('failed to execute', htmlFuncs[i]);
    }
  }
  return '';
};
