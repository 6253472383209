import React, { useCallback } from 'react';
import { useAppDispatch, useSelector } from 'hooks/redux';

import colors from 'config/colors';
import { removeTemplateShift } from 'reducers/createShifts';
import { copyAndCreateTemplateShift } from 'thunks/createShifts';
import { setTemplateEdited } from 'reducers/templates';

import Button from 'components/Button';

import { useTemplateShifts } from './template-hooks';
import TemplatesPage from './TemplatesPage';
import ShiftsListView from '../DayView/ShiftsListView';

interface TemplateDayViewProps {
  goToEditTemplateShift: (templateShiftKey: string) => void,
  selectedShift?: string,
  goToApplyTemplatePeriodView: () => void,
  saveTemplate: () => void,
  templateKey: string,
}

function TemplateDayView(props: TemplateDayViewProps) : React.ReactElement {

  const dispatch = useAppDispatch();
  const saveTemplateError = useSelector(state => state.templates.saveTemplateError);
  const validationError: string | null = useSelector(state => state.templates.validationError);
  const templateEdited = useSelector(state => state.templates.templateEdited);

  const { shifts, keyedShifts } = useTemplateShifts();

  const removeDraftShift = useCallback((shiftKey: string) => {
    dispatch(removeTemplateShift(shiftKey));
    if (!templateEdited) dispatch(setTemplateEdited());
  }, []);

  const ALLOWED_DATA_TABLE_COLUMNS = ['checkbox', 'collapseExpand', 'service', 'location', 'shiftTime', 'speciality', 'roleAndGrade', 'bookedCandidate', 'status', 'actions'];

  return (
    <TemplatesPage {...props}>
      <div style={{ flex: '1 1 0px', display: 'flex', overflow: 'auto' }}>
        <ShiftsListView
          shiftList={shifts}
          keyedShifts={keyedShifts}
          selectedShift={props.selectedShift}
          goToShiftTab={(shiftKey: string) => props.goToEditTemplateShift(shiftKey)}
          copyShift={(shiftKey: string) => dispatch(copyAndCreateTemplateShift(shiftKey))}
          removeShift={removeDraftShift}
          allowedDataTableColumns={ALLOWED_DATA_TABLE_COLUMNS}
        />
        {!!(validationError || saveTemplateError) && (
          <div className="space-children-12" style={{ position: 'absolute', width: '100%', bottom: 0, padding: 12, backgroundColor: colors.red, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
            <p style={{ color: colors.white }}>{validationError || saveTemplateError}</p>
            {saveTemplateError && <Button onClick={() => props.saveTemplate()} outline>Retry</Button>}
          </div>
        )}
      </div>
    </TemplatesPage>
  );
}

TemplateDayView.defaultProps = { selectedShift: undefined };

export default TemplateDayView;
