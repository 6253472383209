import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MdAdd } from 'react-icons/md';

import spacing from 'config/spacing';
import colors from 'config/colors';
import fonts from 'config/fonts';
import { permissions } from 'config/permissions';

import * as suppliersThunks from 'thunks/suppliers';
import * as suppliersReducer from 'reducers/suppliers';

import Page from 'components/Page';
import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import Button from 'components/Button';
import EmptyCard from 'components/EmptyCard';
import Feature from 'components/Feature';

import Suppliers from './Suppliers';
import SettingsHeader from '../Settings/SettingsHeader';
import SupplierDetail from './SupplierDetail';

const styles = {
  modalContentStyles: {
    width: '80%',
    overflow: 'hidden',
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  button: {
    position: 'absolute',
    top: spacing.base,
    right: spacing.base,
    zIndex: 999999,
    color: colors.white,
    textAlign: 'right',
  },
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    margin: spacing.base,
  },
};

const { func, shape, string, number, object } = PropTypes;

class SuppliersContainer extends Component {

  static propTypes = {
    dispatch: func.isRequired,
    screen: shape({
      width: number.isRequired,
      height: number.isRequired,
      size: string.isRequired,
    }).isRequired,
    suppliersData: object,
    supplierKey: string,
    orgKey: string,
  }

  static defaultProps = {
    suppliersData: {},
    supplierKey: null,
    orgKey: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      supplierModalIsOpen: false,
      infoModalIsOpen: false,
      infoModalText: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(suppliersThunks.startWatchingSuppliers(this.props.orgKey));
  }

  componentWillUnmount() {
    this.props.dispatch(suppliersThunks.stopWatchingSuppliers());
  }

  openSupplierModal = (supplierKey) => {
    this.props.dispatch(suppliersReducer.setSupplierKey(supplierKey));
    this.setState({
      supplierModalIsOpen: true,
    });
  }

  newSupplier = () => {
    this.props.dispatch(suppliersReducer.setSupplierKey(null));
    this.setState({
      supplierModalIsOpen: true,
    });
  }

  closeSupplierModal = () => {
    this.setState({ supplierModalIsOpen: false });
    this.props.dispatch(suppliersReducer.setSupplierKey(null));
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
      infoModalText: '',
    });
  }

  render() {
    const modalTitle = this.props.suppliersData && this.props.suppliersData[this.props.supplierKey] ?
      this.props.suppliersData[this.props.supplierKey].companyName : 'New Supplier';
    const isNewSupplier = !this.props.supplierKey;
    const modalHeight = { maxHeight: this.props.screen.height * 0.9 };

    return (
      <div>
        <Page
          title="Suppliers"
        >
          <SettingsHeader selectedTabKey="suppliers" />
          <div style={{ position: 'relative' }}>

            <Feature permissionRequired={permissions.VMS_CAN_MANAGE}>
              <Button black shadow={false} style={styles.button} onClick={this.newSupplier}>
                New Supplier  <MdAdd />
              </Button>
            </Feature>

            {this.props.suppliersData ?
              <Suppliers
                suppliersData={this.props.suppliersData}
                openSupplierModal={this.openSupplierModal}
              /> :
              <EmptyCard
                dimensions={{ height: (this.props.screen.height * 0.9) }}
                message="No suppliers found"
              />
            }
          </div>
        </Page>

        <Modal
          isOpen={this.state.supplierModalIsOpen}
          padding={0}
          contentLabel="Supplier Details"
          onRequestClose={this.closeSupplierModal}
          contentStyle={{ ...styles.modalContentStyles, ...modalHeight }}
        >
          <CloseButton handleClose={this.closeSupplierModal} top={15} right={15} />
          <SupplierDetail
            title={modalTitle}
            isNewSupplier={isNewSupplier}
            isOpen={this.state.supplierModalIsOpen}
            closeSupplierModal={this.closeSupplierModal}
          />
        </Modal>

        <Modal
          isOpen={this.state.infoModalIsOpen}
          contentLabel="Info"
          onRequestClose={this.closeInfoModal}
        >
          <div>
            <h2 style={styles.header}>{this.state.infoModalText}</h2>
          </div>
        </Modal>

      </div>
    );
  }
}

function mapStateToProps({ screen, suppliers, global }) {
  const empAuthOrgs = global.employerAuthorisedOrgs || {};
  const orgKey = Object.keys(empAuthOrgs)[0] || null;
  const suppliersData = suppliers.suppliersData || {};
  const { supplierKey } = suppliers;
  return {
    screen,
    orgKey,
    suppliersData,
    supplierKey,
  };
}

export default withRouter(connect(mapStateToProps)(SuppliersContainer));
