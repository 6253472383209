import React, { useEffect, useState, useCallback, useMemo, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';

import useAsync from 'hooks/useAsync';

import { AdminGroup, Member, PermissionsMetadata } from 'types/AdminGroup';
import { fetchAdminGroupList } from 'thunks/settings';
import { fetchAdminGroupDetails } from 'lib/api/admin-groups';

import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import colors from 'config/colors';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { TabBar } from 'components/Tabs';

import AdminGroupMembers from './AdminGroupMembers';
import AdminGroupPermissions from './AdminGroupPermissions';

interface Styles {
  [key: string]: CSSProperties,
}

const styles: Styles = {
  centreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  },
  headerContainer: {
    padding: 24,
  },
  headerText: {
    color: colors.text,
    fontWeight: 600,
  },
  descriptionText: {
    color: colors.text,
    fontWeight: 500,
  },
  errorText: {
    color: colors.red,
    lineHeight: 1.4,
    padding: 12,
  },
};

function useTabs(members: Member[] | null) {
  const tabs = useMemo(() => {
    const memberCount = members?.length;
    return [
      { key: 'members', title: `Members${memberCount ? ` (${memberCount})` : ''}` },
      { key: 'permissions', title: 'Permissions' },
      // { key: 'audit', title: 'Audit' },
    ];
  }, [members]);
  const [currentTabKey, setCurrentTabKey] = useState(tabs[0].key);
  return { currentTabKey, setCurrentTabKey, tabs };
}

interface AdminGroupDetailsAPIResponse {
  adminGroupDetails: AdminGroup,
  permissionsMetadata: PermissionsMetadata
}

interface Props {
  adminGroupKey: string,
  onClose: () => void,
}

export default function AdminGroupDetails(props: Props): React.ReactElement {

  const dispatch = useDispatch();

  const { status, data, run, error } = useAsync<AdminGroupDetailsAPIResponse>();
  const { adminGroupDetails: details, permissionsMetadata } = data ?? {};


  const { currentTabKey, setCurrentTabKey, tabs } = useTabs(details?.members ?? null);

  const fetchAdminGroupDetailsCb = useCallback(() => {
    run(fetchAdminGroupDetails(props.adminGroupKey), { refetch: false });
  }, [run, props.adminGroupKey]);

  useEffect(() => { fetchAdminGroupDetailsCb(); }, [fetchAdminGroupDetailsCb]);

  const refreshGroupAndList = useCallback(() => {
    fetchAdminGroupDetailsCb();
    dispatch(fetchAdminGroupList());
  }, [fetchAdminGroupDetailsCb]);


  const canManageMembers = !!details?.currentAdminPermissions.canManageMembers;
  return (
    <Modal
      isOpen
      vflex
      onRequestClose={props.onClose}
      contentStyle={{ width: 600, height: 600, padding: 0, color: colors.text }}
    >
      <CloseButton top={15} right={15} handleClose={props.onClose} />
      {status === 'pending' && <div style={styles.centreContainer}><Loading /></div>}
      {status === 'rejected' && error && <ErrorMessage error={error} retry={fetchAdminGroupDetailsCb} />}
      {status === 'resolved' && details && permissionsMetadata && (
        <>
          <div style={{ backgroundColor: colors.cavalry.backgroundLight6 }}>
            <Header name={details.name} description={details.description} />
            <TabBar tabs={tabs} selectedTabKey={currentTabKey} onTabClick={setCurrentTabKey} />
          </div>

          {currentTabKey === 'members' && (
            <AdminGroupMembers
              members={details.members}
              refreshGroup={refreshGroupAndList}
              adminGroupKey={props.adminGroupKey}
              canManage={canManageMembers}
            />
          )}
          {currentTabKey === 'permissions' && <AdminGroupPermissions permissionGrants={details.permissionGrants} permissionsMetadata={permissionsMetadata} />}
          {currentTabKey === 'audit' && <div style={{ flex: 1 }} />}
        </>
      )}
    </Modal>
  );
}

function Header({ name, description }: { name: string, description: string | null }): React.ReactElement {
  return (
    <div style={styles.headerContainer}>
      <div>
        <h2 style={styles.headerText}>{name}</h2>
        {description && <p style={styles.descriptionText}>{description}</p>}
      </div>
    </div>
  );
}

function ErrorMessage({ error, retry }: { error: string, retry: () => void }): React.ReactElement {
  return (
    <div style={styles.centreContainer}>
      <p style={styles.errorText}>{error}</p>
      <Button onClick={retry}>Retry</Button>
    </div>
  );
}
