import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import moment from 'moment';

import { SHIFT_GROUPING, smartGroup } from 'lib/shift-grouping';
import { copyAndCreateDraftShift } from 'thunks/createShifts';
import { removeDraftShift } from 'reducers/createShifts';
import { clearFetchShiftsForListViewError } from 'reducers/jobs';

import { createPeriod } from 'components/Calendar';
import ErrorScreen from 'components/ErrorScreen';
import ShiftsPage from '../ShiftsPage';

import WeekCalendar from './WeekCalendar';
import { useShiftList } from '../shift-hooks';

const Moment = moment().constructor;
const { func, number, objectOf, instanceOf, string } = PropTypes;
const WeekCalendarContainer = (props) => {

  const { year, week, selectedWeek, nav, periodDuration } = props;

  const dispatch = useAppDispatch();

  // Retrieve data from redux
  const shiftRosterSettings = useSelector(state => state.global.orgConfig.shiftRosterView);
  const specialitiesMetadata = useSelector(state => state.global.metadata.specialities);
  const gradesMetadata = useSelector(state => state.global.metadata.grades);
  const selectedFilterItems = useSelector(state => state.filter.v2SelectedItems.shifts);
  const fetchShiftsListViewError = useSelector(state => state.jobs.fetchShiftsListViewError);

  // Compute derived data
  const period = useMemo(() => createPeriod(selectedWeek, periodDuration), [selectedWeek.valueOf(), periodDuration]);
  const { shiftList, keyedShifts, refreshShiftList, isFetchingShiftsForListView } = useShiftList(period);
  const shiftSections = useMemo(() => {
    return smartGroup(keyedShifts, SHIFT_GROUPING[shiftRosterSettings?.sectionBy ?? 'site'], { specialitiesMetadata, gradesMetadata });
  }, [keyedShifts, shiftRosterSettings?.sectionBy]);

  // Load day summary data (API call)
  useEffect(() => refreshShiftList(period), [year, week, selectedFilterItems, period.startDate.valueOf(), period.endDate.valueOf()]);

  // Callbacks
  const openShiftTab = useCallback((shiftKey, isDraft, tab) => nav.goToShiftTab(selectedWeek, shiftKey, isDraft, tab), [nav]);

  return (
    <ShiftsPage
      period={period}
      nav={nav}
      shiftList={shiftList}
      loading={isFetchingShiftsForListView}
      refreshShiftList={async () => {
        await refreshShiftList(period);
      }}
    >
      {fetchShiftsListViewError ?
        <ErrorScreen dismiss={() => dispatch(clearFetchShiftsForListViewError())} retry={() => refreshShiftList(period)} />
        :
        <WeekCalendar
          period={period}
          shiftSections={shiftSections}
          openShiftTab={openShiftTab}
          selectedShift={props.selectedShift}
          sortShiftsBy="startTime"
          copyShift={shiftKey => dispatch(copyAndCreateDraftShift(shiftKey))}
          removeShift={shiftKey => dispatch(removeDraftShift(shiftKey))}
        />
    }
    </ShiftsPage>
  );
};

WeekCalendarContainer.propTypes = {
  year: number.isRequired,
  week: number.isRequired,
  selectedWeek: instanceOf(Moment).isRequired,
  nav: objectOf(func).isRequired,
  selectedShift: string,
};

WeekCalendarContainer.defaultProps = {
  selectedShift: null,
};

export default WeekCalendarContainer;
