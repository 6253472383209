import React from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';

import { convertToSelect } from 'lib/helpers';
import { ServiceMetadata } from 'types/Metadata';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface ServiceFieldProps {
  service: { id: string, name: string } | null
  onChange: (val: { value: string, label: string }) => void,
}

function ServiceField(props: ServiceFieldProps) {

  const services = useSelector(({ global }) => global.services);

  return (
    <FieldLabel label="Service">
      <JobsSelectBox
        name="service"
        value={props.service}
        placeholder="Service *"
        data={(services ?? []).sort(firstBy('name')).map((area: ServiceMetadata) => convertToSelect(area))}
        onChange={props.onChange}
        mandatory
      />
    </FieldLabel>
  );
}

export default React.memo(ServiceField);
