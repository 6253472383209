import React, { CSSProperties } from 'react';
import { MdWarning } from 'react-icons/md';
import DropDown from 'components/DropDown';
import colors from 'config/colors';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  warningText: {
    fontSize: '0.9rem',
    lineHeight: 1.4,
    fontWeight: 400,
    color: colors.cavalry.pending,
  },
  warning: {
    color: colors.cavalry.pending,
    fontSize: '42px',
    marginRight: '6px',
  },
};

interface Participant {
  value: string;
  label: string;
  isSelected?: boolean;
}

interface FromInputProps {
  availableSenders: Participant[];
  selectedSender: Participant | null;
  selectSender: (sender: Participant | null) => void;
  showWarning?: boolean,
}

export default function FromInput(props: FromInputProps) : React.ReactElement {
  return (
    <div className="messagingFromToContainer">
      <p style={{ fontSize: '1rem', fontWeight: 600 }}>From:</p>
      <div className="messagingFromSelectContainer">
        <DropDown
          value={props.selectedSender}
          options={props.availableSenders}
          onChange={props.selectSender}
        />
      </div>
      {props.showWarning && (
        <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 0px' }}>
          <MdWarning style={styles.warning} />
          <p style={styles.warningText}>While you can send broadcast messages from your personal account, it is recommended to send them from a shared inbox.</p>
        </div>
      )}
    </div>
  );
}
