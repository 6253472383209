import moment, { Moment } from 'moment-timezone';
import { groupBy } from 'lodash-es';
import { Day, Period, PeriodDuration, ShiftSummary } from 'types/ShiftTypes';

export const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const daysOfTwoWeeks = [...daysOfWeek, ...daysOfWeek];
const daysOfWeekAmPm = ['Mon AM', 'Mon PM', 'Tue AM', 'Tue PM', 'Wed AM', 'Wed PM', 'Thu AM', 'Thu PM', 'Fri AM', 'Fri PM', 'Sat AM', 'Sat PM', 'Sun AM', 'Sun PM'];
const monthsList = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };

function groupByDate(shiftList: Array<ShiftSummary>) : { [key:string]: Array<ShiftSummary> } {
  return groupBy(shiftList, (shift: ShiftSummary) => shift.startTime?.slice(0, 10));
}

function groupByDateAmPm(shiftList: Array<ShiftSummary>) : { [key:string]: Array<ShiftSummary> } {
  return groupBy(shiftList, (shift: ShiftSummary) => shift.startTime && moment(shift.startTime).tz(shift.timezone).format('YYYY-MM-DD A'));
}

export function groupByDayOfWeek(shiftList: ShiftSummary[]) : { [key:string]: ShiftSummary[] } {
  return groupBy(shiftList, (shift: ShiftSummary) => shift.dayOfWeek);
}

function formatTime24Hour(shift: ShiftSummary) {
  const startTime = moment(shift.startTime).tz(shift.timezone);
  const endTime = moment(shift.endTime).tz(shift.timezone);

  return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
}

function getStartAndEndDates(date: Moment, periodDuration: PeriodDuration) {
  switch (periodDuration) {
    case 'month':
      return {
        keyDate: date.clone(),
        startDate: date.clone().startOf('month'),
        endDate: date.clone().endOf('month'),
        displayStartDate: date.clone().startOf('month').startOf('isoWeek'),
        displayEndDate: date.clone().endOf('month').endOf('isoWeek'),
        previousDate: date.clone().subtract(1, 'month'),
        nextDate: date.clone().add(1, 'month'),
        keyFormat: 'YYYY-MM-DD',
        columns: daysOfWeek,
        groupByDay: groupByDate,
        label: date.format('MMM YYYY'),
        formatTime: formatTime24Hour,
      };
    case 'week': {
      const startDate = date.clone().startOf('isoWeek');
      const endDate = date.clone().endOf('isoWeek');
      return {
        keyDate: date.clone(),
        startDate,
        endDate,
        previousDate: date.clone().subtract(1, 'week'),
        nextDate: date.clone().add(1, 'week'),
        keyFormat: 'YYYY-MM-DD',
        columns: daysOfWeek,
        groupByDay: groupByDate,
        label: `${startDate.format('Do MMM')} - ${endDate.format('Do MMM')}`,
        formatTime: formatTime24Hour,
      };
    }
    case 'week-am-pm': {
      const startDate = date.clone().startOf('isoWeek');
      const endDate = date.clone().endOf('isoWeek');
      return {
        keyDate: date.clone(),
        startDate,
        endDate,
        previousDate: date.clone().subtract(1, 'week'),
        nextDate: date.clone().add(1, 'week'),
        keyFormat: 'YYYY-MM-DD A',
        columns: daysOfWeekAmPm,
        groupByDay: groupByDateAmPm,
        label: `${startDate.format('Do MMM')} - ${endDate.format('Do MMM')}`,
        formatTime: formatTime24Hour,
      };
    }
    case 'two-weeks': {
      const startDate = date.clone().startOf('isoWeek');
      const endDate = date.clone().endOf('isoWeek').add(1, 'week');
      return {
        keyDate: date.clone(),
        startDate,
        endDate,
        previousDate: date.clone().subtract(1, 'week'),
        nextDate: date.clone().add(1, 'week'),
        keyFormat: 'YYYY-MM-DD',
        columns: daysOfTwoWeeks,
        groupByDay: groupByDate,
        label: `${startDate.format('Do MMM')} - ${endDate.format('Do MMM')}`,
        formatTime: formatTime24Hour,
      };
    }
    case 'day':
      return {
        keyDate: date.clone(),
        startDate: date.clone(),
        endDate: date.clone(),
        previousDate: date.clone().subtract(1, 'day'),
        nextDate: date.clone().add(1, 'day'),
        keyFormat: 'YYYY-MM-DD',
        columns: ['Today'],
        groupByDay: groupByDate,
        label: date.format('ddd Do MMM YYYY'),
        formatTime: formatTime24Hour,
      };
    default:
      throw new Error('Invalid time period duration');
  }
}

export const createPeriod = (date: Moment, periodDuration: PeriodDuration) : Period => {

  const { keyDate, startDate, endDate, displayStartDate, displayEndDate, previousDate, nextDate, keyFormat, columns, groupByDay, label, formatTime } = getStartAndEndDates(date, periodDuration);

  const daysStartDate = displayStartDate ?? startDate;
  const daysEndDate = displayEndDate ?? endDate;

  const daysToRender : Array<Day> = [];
  for (let day = daysStartDate.clone(); day.isBefore(daysEndDate); day.add(1, 'days')) {

    daysToRender.push({
      key: day.format(keyFormat),
      label: day.format('DD'),
      month: monthsList[(day.month() + 1) as keyof typeof monthsList],
      date: day.clone(),
      event: undefined,
      notInPeriod: periodDuration === 'month' && day.month() !== date.month(),
    });

    if (periodDuration === 'week-am-pm') {
      const dayPM = day.clone().hour(12);
      daysToRender.push({
        key: dayPM.format(keyFormat),
        label: dayPM.format('DD'),
        month: monthsList[(dayPM.month() + 1) as keyof typeof monthsList],
        date: dayPM.clone(),
        event: undefined,
        notInPeriod: false,
      });
    }
  }

  return {
    keyDate,
    startDate,
    endDate,
    previousDate,
    nextDate,
    duration: periodDuration,
    columns,
    groupByDay,
    days: daysToRender,
    label,
    formatTime,
  };
};

export type Events = { [key:string]: unknown };
export const addEventsToPeriod = (period: Period, events: Events = {}) => {
  return {
    ...period,
    days: period.days.map(day => ({ ...day, event: events[day.key] })),
  };
};
