import moment from 'moment';
import SpendReport from './SpendReport'; // eslint-disable-line import/no-cycle
import FillRateReport from './FillRateReport'; // eslint-disable-line import/no-cycle
import RequestsReport from './RequestsReport'; // eslint-disable-line import/no-cycle
import CreamFormatter from './CreamFormatter';
import CancellationReport from './CancellationReport';
import NumberFormatter from './NumberFormatter';
import { featureFlags } from '../../../config/featureFlags';

const headerFormatter = (rec, groupedBy) => {
  if (groupedBy === 'date') {
    return rec.date;
  }
  return rec[groupedBy];
};

const defaultDataFormatter = (value) => {
  return NumberFormatter(value);
};

const percentFormatter = (value) => {
  const newValue = Math.round(Math.min(100.00, value));
  return `${newValue}%`;
};

const computePercentage = (value, total) => {
  return Math.round(Math.min(100.00, ((value / total) * 100)));
};

const totalFormatter = value => CreamFormatter(value, true);

const generateFillRateByRolePayload = (report) => {
  if (!report) return null;
  return report.result.map((data) => {
    return {
      categoryKey: data.categoryKey,
      categoryName: data.categoryName,
      agency: computePercentage(data.agency, data.total),
      bank: computePercentage(data.bank, data.total),
      unfilled: computePercentage(data.unfilled, data.total),
    };
  });
};

const generateFillRateBySpecialityPayload = (report, labels) => {
  if (!report) return null;
  return report.result.map((entry) => {
    const payload = {};
    payload.total = entry.total;
    payload.categoryKey = entry.categoryKey;
    payload.categoryName = entry.categoryName;
    labels.forEach((origin) => {
      payload[origin] = Math.round(((entry[origin] / entry.total) * 100));
    });
    return payload;
  });
};

const formatDate = (date, durationBetweenDates) => {
  if (durationBetweenDates < 2) return moment(date).format('Do MMM YY');
  else if (durationBetweenDates < 6) return `${moment(date).format('DD/MM')} - ${moment(date).add(6, 'days').format('DD/MM')}`;
  return moment(date).format('MMMM YY');
};

const reports = [
  {
    name: 'dashboard',
    title: 'Dashboard',
    url: 'dashboard',
    reportsToLoad: ['fillRate', 'requests', 'spend'],
  },
  {
    name: 'events',
    title: 'Activity',
    component: CancellationReport,
    drillDown: false,
    url: 'activity',
    featureFlag: featureFlags.REPORTING_ACTIVITY,
    labels: [
      {
        color: '#7227ac',
        name: 'applies',
        title: 'Candidate Applies',
      },
      {
        color: '#28886b',
        name: 'withdrawals',
        title: 'Candidate Withdraws Application',

      },
      {
        color: '#9c103d',
        name: 'cancellations',
        title: 'Candidate Cancels Booking',
      },
      {
        color: '#005e86',
        name: 'shiftViews',
        title: 'Candidate Views Shift',
      },
    ],
  },
  {
    name: 'spend',
    title: 'Spend',
    component: SpendReport,
    drillDown: true,
    url: 'spend',
    dataFormatter: totalFormatter,
    groupByOptions: ['date', 'role'],
    labels: ['bank'],
    roleBreakdown: {
      trendPositive: 'down',
      tooltipFormatter: totalFormatter,
      totalFormatter,
      yAxisFormatter: CreamFormatter,
      title: 'Temporary Staffing Spend By Role',
      showTotal: false,
      generatePayloadForChart: report => report && report.result,
    },
    specialitiesBreakdown: {
      title: 'Temporary Staffing Spend for __ROLE__ By Speciality',
      showTotal: true,
      trendPositive: 'down',
      tooltipFormatter: totalFormatter,
      totalFormatter,
      yAxisFormatter: CreamFormatter,
      tableFormatter: totalFormatter,
      xAxisVertical: true,
      generatePayloadForChart: report => report && report.result,
    },
    featureFlag: featureFlags.REPORTING_SPEND,
  },
  {
    name: 'fillRate',
    title: 'Fill Rate',
    component: FillRateReport,
    url: 'fill',
    groupByOptions: ['role', null],
    labels: ['agency', 'bank', 'unfilled'],
    roleBreakdown: {
      trendPositive: 'up',
      title: 'Fill Rate By Role',
      showTotal: false,
      dataMax: true,
      tooltipFormatter: percentFormatter,
      yAxisFormatter: percentFormatter,
      generatePayloadForChart: generateFillRateByRolePayload,
    },
    specialitiesBreakdown: {
      title: 'Fill Rate for __ROLE__ By Speciality',
      showTotal: true,
      trendPositive: 'up',
      xAxisVertical: true,
      dataMax: true,
      tooltipFormatter: percentFormatter,
      yAxisFormatter: percentFormatter,
      generatePayloadForChart: generateFillRateBySpecialityPayload,
    },
    dataFormatter: percentFormatter,
  },
  {
    name: 'requests',
    title: 'Shift Requests',
    component: RequestsReport,
    drillDown: true,
    url: 'requests',
    groupByOptions: ['date', 'role'],
    labels: ['agency', 'bank'],
    dataFormatter: defaultDataFormatter,
    roleBreakdown: {
      trendPositive: 'down',
      title: 'Shift Requests By Role',
      showTotal: false,
      generatePayloadForChart: report => report && report.result,
    },
    specialitiesBreakdown: {
      title: 'Requests for __ROLE__ By Specialities',
      showTotal: true,
      trendPositive: 'down',
      xAxisVertical: true,
      generatePayloadForChart: report => report && report.result,
    },
  },
  {
    name: 'Exports',
    title: 'Exports',
    url: 'exports',
    featureFlag: featureFlags.REPORTING_EXPORTS,
  },
  {
    name: 'historicalGrades',
    title: 'Skills and Qualifications',
    drillDown: false,
    url: 'skills-and-qualifications',
    // featureFlag: featureFlags.REPORTING_HISTORICAL_GRADES,
  },
];

export { reports, headerFormatter, formatDate };
