import React from 'react';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';

import colors from 'config/colors';

import Checkbox from 'components/Checkbox';
import ShiftCard from './ShiftCard';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  header: {
    boxSizing: 'border-box',
    height: 24,
    flex: '0 0 auto',
    paddingLeft: 6,
    paddingTop: 3,
    fontSize: '0.9rem',
    textAlign: 'left',
    color: colors.text,
    display: 'flex',
    alignItems: 'center',
  },
};

const WeekCalendarEventComponent = React.memo(({ event, period }) => {
  const { shifts, ...props } = event;

  const someShiftsSelected = shifts.some(shift => props.selectedShiftKeys.includes(shift.key));
  const allShiftsSelected = someShiftsSelected && shifts.every(shift => props.selectedShiftKeys.includes(shift.key));

  let checked = false;
  if (someShiftsSelected) checked = null;
  if (allShiftsSelected) checked = true;

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {shifts.length} shifts
        <div style={{ position: 'absolute', right: 6, top: 6 }}>
          <Checkbox
            size={16}
            checked={checked}
            boxStyle={{ borderColor: '#999' }}
            onChange={() => {
              const shiftKeys = shifts.map(shift => shift.key);
              if (shiftKeys.length) {
                if (allShiftsSelected) props.unselectSpecifiedShifts(shiftKeys);
                else props.selectSpecifiedShifts(shiftKeys);
              }
            }}
          />
        </div>
      </div>
      <div style={{ flex: '0 0 auto', paddingBottom: 12 }}>
        {shifts
          .sort(firstBy(props.sortShiftsBy).thenBy(shift => shift.candidateName ?? 'zzzz').thenBy(shift => (shift.isDraft ? 1 : 0)))
          .map((shift, index) => {
            return (
              <ShiftCard
                key={shift.key}
                shift={shift}
                formatTime={period.formatTime}
                copyShift={props.copyShift}
                removeShift={props.removeShift}
                zIndex={500 - index} // Puts earlier cards above later cards, which makes action buttons show properly
                {...props}
              />
            );
          })
        }
      </div>
    </div>
  );
});

const { arrayOf, object, string, shape } = PropTypes;

WeekCalendarEventComponent.propTypes = {
  event: shape({
    date: string,
    shifts: arrayOf(object),
  }),
};

WeekCalendarEventComponent.defaultProps = {
  event: {
    shifts: [],
    date: null,
  },
};

export default WeekCalendarEventComponent;
