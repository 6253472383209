import moment from 'moment';

export default function DateFormatter(value) {
  const now = moment();
  const valMo = moment(value);
  if (now.diff(valMo, 'days') < 1) {
    return 'Today';
  }

  if (valMo.get('year') !== now.get('year')) {
    return valMo.format('D MMMM YY');
  }

  return valMo.format('D MMM');
}
