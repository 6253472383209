import React from 'react';
import colors from 'config/colors';
import FieldLabel from './FieldLabel';

interface CostCentreFieldProps {
  costCentre: { code: string, description: string, careGroup: string } | null
}

function CostCentreField(props: CostCentreFieldProps) {
  return (
    <FieldLabel label="Cost Centre">
      <div style={{ fontSize: '0.9rem', color: colors.text, lineHeight: 1.3, textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {props.costCentre ?
          <p style={{ marginTop: 4 }}>{props.costCentre.code} | {props.costCentre.description}</p>
          :
          <p style={{ color: colors.cavalry.error, fontSize: '0.9rem', fontWeight: 500 }}>None</p>
        }
      </div>
    </FieldLabel>
  );
}

export default React.memo(CostCentreField);
