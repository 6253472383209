import React from 'react';
import PropTypes from 'prop-types';
import { MdPerson } from 'react-icons/md';
import colors from 'config/colors';
import spacing from '../../config/spacing';
import { PaginatedDataTable } from '../../components/DataTable';
import EmptyCard from '../../components/EmptyCard';
import './BankManagement.css';


const styles = {
  progressBarContainer: {
    border: '1px solid #e6e6e6',
    backgroundColor: 'white',
    height: 17,
    borderRadius: spacing.small,
  },
  progressBar: {
    backgroundColor: colors.cavalry.active,
    height: 17,
    borderRadius: spacing.small,
  },
};

const COLUMNS = [
  { name: 'name', header: 'Name', icon: <MdPerson /> },
  {
    name: 'progress',
    header: 'Progress',
    formatter: percentage => (
      <div style={styles.progressBarContainer}>
        <div style={{ ...styles.progressBar, width: `${percentage * 100 || 0}%` }} />
      </div>
    ),
  },
];

export default function BankManagement(props) {

  const isLoading = props.isFetchingCandidates;
  const noInProgressCandidates = props.totalCandidateCount < 1;

  return (
    <React.Fragment>
      {noInProgressCandidates ?
        <EmptyCard message="No compliance items in progress. Invite a member of staff via the Approved tab above to start the process." />
        :
        <PaginatedDataTable
          totalRowCount={props.candidates.length}
          isLoading={isLoading}
          columns={COLUMNS}
          rows={props.candidates}
          onRowClick={props.openProfile}
          idColumn="candidateKey"
          noDataMessage="No compliance items match the selected role grade and speciality"
        />
      }
    </React.Fragment>
  );
}

const { arrayOf, object, bool, number, func, string } = PropTypes;
BankManagement.propTypes = {
  isFetchingCandidates: bool.isRequired,
  candidates: arrayOf(object).isRequired,
  totalCandidateCount: number.isRequired,
  filteredCandidateCount: number.isRequired,
  filterString: string.isRequired,
  setFilterString: func.isRequired,
  openProfile: func.isRequired,
};
