import React from 'react';
import { firstBy } from 'thenby';

import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { PublishedShiftSummary, TemplateShift, ShiftSlot } from 'types/ShiftTypes';
import { RolesMetadata, SpecialitiesMetadata, GradesMetadata } from 'types/Metadata';
import { DataTableColumnDefinition } from 'components/DataTable';

import colors from 'config/colors';

export interface ShiftListColumns<ShiftShape, AdditionalProps> {
  [key: string]: DataTableColumnDefinition<ShiftShape, AdditionalProps>,
}

interface ColumnsLookup {
  [key: string]: (shift: PublishedShiftSummary) => string | number | null,
}

interface CreateColumnsSortFunctionProps {
  shiftListViewSortOrder: Array<string>,
  rolesMetadata: RolesMetadata,
  gradesMetadata: GradesMetadata,
  specialitiesMetadata: SpecialitiesMetadata
}

type ShiftType = PublishedShiftSummary | TemplateShift | ShiftSlot

export const defaultColumnsOrder = ['shiftNumber', 'service', 'location', 'shiftTime', 'bookedCandidate', 'speciality', 'roleAndGrade', 'status', 'actions'];

export const defaultSortOrder = ['site', 'startTime', 'specialityName', 'role', 'grade'];

export const createColumnsSortFunction = ({ shiftListViewSortOrder, rolesMetadata, gradesMetadata, specialitiesMetadata }: CreateColumnsSortFunctionProps) : IThenBy<PublishedShiftSummary | TemplateShift> => {

  const columnsLookup: ColumnsLookup = {
    site: (shift) => shift.siteName,
    startTime: (shift) => shift.startTime,
    specialityName: (shift) => shift.specialityName,
    specialityOrder: (shift) => {
      if (shift.specialityKey) return specialitiesMetadata[shift.specialityKey]?.order ?? 0;
      return 0;
    },
    role: (shift) => {
      if (shift.roleKey) return rolesMetadata[shift.roleKey]?.order ?? 0;
      return 0;
    },
    grade: (shift) => {
      if (shift.gradeKey) return gradesMetadata[shift.gradeKey]?.order ?? 0;
      return 0;
    },
  };

  return (shiftListViewSortOrder ?? defaultSortOrder)
    .reduce((acc: IThenBy<PublishedShiftSummary>, curr: string) => {
      return acc.thenBy(columnsLookup[curr]);
    }, firstBy(() => 0))
    .thenBy((shift) => {
      return isShiftSummary(shift) ? shift.shiftNumber : null;
    });
};

function isShiftSummary(shift: ShiftType): shift is PublishedShiftSummary {
  return !!(shift as PublishedShiftSummary).startTime;
}

function isShiftSlot(shift: ShiftType): shift is ShiftSlot {
  return !!(shift as ShiftSlot).isSlot;
}

export const shiftListColumns: ShiftListColumns<ShiftType, undefined> = {
  shiftNumber: {
    name: 'job',
    header: 'ID',
    width: 80,
    formatter: (job: ShiftType) : React.ReactElement | string | null => {
      const fontColor = isShiftSlot(job) ? 'rgba(0, 0, 0, 0.3)' : colors.text;

      let shiftNumber = '';

      if (isShiftSlot(job) && job.slotId) {
        shiftNumber = `${job.shiftNumber}-${job.slotId}`;
      } else if (isShiftSummary(job) && job.shiftNumber) {
        shiftNumber = job.shiftNumber;
      } else {
        shiftNumber = 'DRAFT';
      }
      return <span style={{ color: fontColor }}>{shiftNumber}</span>;
    },
  },
  shiftTime: {
    name: 'time',
    header: 'Time',
    width: 100,
    formatter: (job: ShiftType) : React.ReactElement | null => {
      const fontColor = isShiftSlot(job) ? 'rgba(0, 0, 0, 0.3)' : colors.text;

      const format = 'HH:mm';
      const startTime = isShiftSummary(job) ? convertFromUtcToTimezone(job.startTime, job.timezone).format(format) : job.startTimeOfDay;
      const endTime = isShiftSummary(job) ? convertFromUtcToTimezone(job.endTime, job.timezone).format(format) : job.endTimeOfDay;

      return <span style={{ fontSize: '0.9rem', color: fontColor }}>{`${startTime} - ${endTime}`}</span>;
    },
  },
  speciality: {
    name: 'speciality',
    header: 'Speciality',
    formatter: (shift: ShiftType) : React.ReactElement | null => {
      const fontColor = isShiftSlot(shift) ? 'rgba(0, 0, 0, 0.3)' : colors.text;
      return <span style={{ fontWeight: 'normal', color: fontColor }}>{shift.specialityName ?? 'Any'}</span>;
    },
  },
  roleAndGrade: {
    name: 'person',
    header: 'Role & Grade',
    formatter: (shift: ShiftType) : React.ReactElement | null => {

      const fontColor = isShiftSlot(shift) ? 'rgba(0, 0, 0, 0.3)' : colors.text;

      const showGrade = shift.gradeName !== shift.roleName && shift.gradeName !== 'All';

      return (
        <span style={{ color: fontColor }}>{shift.roleName ?? 'Any'}{showGrade ? ` (${shift.gradeName ?? 'Any'})` : ''}</span>
      );
    },
  },
};
