import React from 'react';
import TextAreaAutosize from 'react-autosize-textarea';

import FieldLabel from './FieldLabel';

interface DescriptionFieldProps {
  onChange: (val: React.SyntheticEvent) => void,
  description: string | null,
  label?: string,
  placeholder?: string,
}

function DescriptionField(props: DescriptionFieldProps) {

  return (
    <FieldLabel label={props.label ?? 'Description'}>
      <TextAreaAutosize
        className="cs__description-text-area"
        value={props.description ?? ''}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </FieldLabel>
  );
}

DescriptionField.defaultProps = {
  label: 'Description',
  placeholder: 'Additional information (optional)',
};

export default React.memo(DescriptionField);
