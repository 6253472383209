import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface Speciality {
  enabled: boolean
  lastEdit: string
  lastEditByUserId: string
  key: string
  name: string
  order: number
}

interface SpecialityFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  speciality: { id: string, name: string } | null,
  mandatoryFields: Array<string>,
  mandatory?: boolean,
}

function SpecialityField(props: SpecialityFieldProps) {

  const specialities = useSelector(({ createDraftShifts }) => createDraftShifts.specialities) as { [key: string]: Speciality };

  const sortedSpecialities = useMemo(() => {

    const convertedSpecialities = Object.entries(specialities ?? {})
      .map(([key, spec]) => ({ value: key, label: spec.name, order: spec.order }))
      .sort(firstBy('order')) as Array<{ value: string | null, label: string, order?: number }>;

    if (!props.mandatoryFields.includes('speciality')) convertedSpecialities.unshift({ value: null, label: 'Any' });
    return convertedSpecialities;

  }, [specialities]);


  return (
    <FieldLabel label="Speciality">
      <JobsSelectBox
        name="specialities"
        value={props.speciality ?? { id: null, name: 'Any' }}
        placeholder="Speciality *"
        data={sortedSpecialities}
        onChange={props.onChange}
        mandatory={props.mandatory}
      />
    </FieldLabel>
  );
}

SpecialityField.defaultProps = { mandatory: false };

export default React.memo(SpecialityField);
