import React from 'react';

import colors from 'config/colors';

import Button from 'components/Button';
import { MdUndo } from 'react-icons/md';
import InlineSwitch from 'components/InlineSwitch';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Switch from 'react-switch';

import { SwitchListHookObject } from './useSwitchList';

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  switchesContainer: {
    flex: '1 1 0px',
    overflow: 'auto',
    padding: 12,
  },
  buttonsContainer: {
    flex: '0 0 auto',
    padding: 12,
    textAlign: 'right',
    borderTop: '1px solid #EAEAEA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  topBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export interface SwitchListProps {
  hookObject: SwitchListHookObject,
  showSelectAll?: boolean,
  showResetButton?: boolean,
}

const SwitchList: React.FunctionComponent<SwitchListProps> = (props: SwitchListProps) => {
  const hookObject = props.hookObject;

  return (
    <div style={styles.mainContainer}>
      <div style={styles.switchesContainer}>
        {(props.showResetButton || props.showSelectAll) && (
          <div style={styles.topBarContainer}>
            {props.showResetButton && (
              <Button white outline shadow={false} onClick={hookObject.reset} disabled={!hookObject.hasChanged}>
                <MdUndo style={{ color: colors.text, marginRight: 10 }} />Reset
              </Button>
            )}
            {props.showSelectAll && (
              <div className="space-children-12" style={{ display: 'flex', flex: '0 0 auto', alignItems: 'center' }}>
                <p>Select All</p>
                <Switch
                  checked={hookObject.allSelected}
                  onChange={hookObject.toggleSelectAll}
                  onColor={colors.cavalry.active}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            )}
          </div>

        )}
        {hookObject.items.map((item) => {
          const itemState = !!hookObject.state[item.key];
          return (
            <InlineSwitch
              key={item.key}
              title={item.label}
              on={itemState}
              onClick={() => hookObject.setItemState(item.key, !itemState)}
            />
          );
        })}
      </div>
    </div>
  );
};

SwitchList.defaultProps = {
  showSelectAll: false,
  showResetButton: false,
};

export default SwitchList;
