import React, { CSSProperties } from 'react';
import spacing from 'config/spacing';

interface ComplianceStatusProps {
  statusColor: string,
  statusLabel: string,
  fontSize: number;
  marginLeft: number;
}

const ComplianceStatus : React.FC<ComplianceStatusProps> = (props: ComplianceStatusProps) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center', marginLeft: props.marginLeft }}>
      <div style={{ height: 10, width: 10, borderRadius: '50%', marginRight: spacing.tiny, marginBottom: 3, backgroundColor: props.statusColor }} />
      <p style={{ color: props.statusColor, fontSize: props.fontSize, fontWeight: 500 as CSSProperties['fontWeight'] }}>
        {props.statusLabel}
      </p>
    </span>
  );
};

export default ComplianceStatus;
