export default {
  micro: 2,
  tiny: 6,
  small: 8,
  base: 12,
  large: 15,
  xlarge: 20,
  xxlarge: 30,
  xxxlarge: 50,
};
