import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loading from 'components/Loading';
import { fetchAvailableParticipants, createConversation, searchConversations } from 'thunks/messaging';
import { convertToSelect } from 'lib/helpers';
import { Participant, SharedInboxMembership, Sender } from 'types/Messaging';
import { useAppDispatch } from 'hooks/redux';
import { isNonNullish } from 'lib/helpers-ts';
import ConversationContainer from './ConversationContainer';
import ComposeMessageBar from './ComposeMessageBar';
import IndividualToInput from './IndividualToInput';
import FromInput from './FromInput';

const ComposeNonBatchMessage: React.FC = () => {

  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useSelector(state => state.user);
  const availableParticipants: Participant[] = useSelector(state => state.messaging.availableParticipants);
  const sharedInboxes : SharedInboxMembership[] | null = useSelector(state => state.messaging.sharedInboxMembership);
  const userSharedInboxKeys = (sharedInboxes ?? []).map(shared => shared.key);

  // Create lists of available senders
  const userSender: Sender = { value: user.userId, label: user.userData?.displayName };
  const availableSenders : Sender[] = [userSender, ...(sharedInboxes ?? [])].map((obj) => convertToSelect(obj));

  const [selectedRecipientKeys, setSelectedRecipients] = useState<string[]>([]);
  const [selectedSender, setSelectedSender] = useState<Sender | null>(userSender);
  const [existingConversation, setExistingConversation] = useState('');
  const [isCreatingConversation, setIsCreatingConversation] = useState(false);

  // Combine selected recipients and sender, then split into users and shared inboxes
  const sharedInboxKeys = (sharedInboxes ?? []).map(({ key }) => key);
  const selectedUserKeysAndSharedInboxKeys = [...selectedRecipientKeys, selectedSender?.value].filter(isNonNullish);
  const selectedSharedInboxKeys: string[] = selectedUserKeysAndSharedInboxKeys.filter(key => sharedInboxKeys.includes(key));
  const selectedUserKeys = selectedUserKeysAndSharedInboxKeys.filter(key => !sharedInboxKeys.includes(key));

  // If selected sender is a shared inbox, then filter out shared inboxes from participants list
  const selectedSenderIsSharedInbox = !!selectedSender && userSharedInboxKeys.includes(selectedSender.value);
  const availableParticipantsForSelect = useMemo(() => {
    return availableParticipants
      .filter((obj: Participant) => !selectedSenderIsSharedInbox || !obj.type.includes('shared-inbox'))
      .map((obj: Participant) => ({ ...convertToSelect(obj), subLabel: obj.subLabel }));
  }, [availableParticipants, selectedSenderIsSharedInbox]);

  useEffect(() => {
    dispatch(fetchAvailableParticipants());
  }, []);

  useEffect(() => {
    if (availableParticipants?.length && !selectedRecipientKeys?.length) {

      const availableParticipantKeys = availableParticipants.map(participant => participant.key);

      const initialParticipantKeys = window.location.search
        .match(/to=([^/&:]*)/g)
        ?.map(param => param.replace('to=', ''))
        ?.filter(key => availableParticipantKeys.includes(key));

      if (initialParticipantKeys) setSelectedRecipients(initialParticipantKeys);
    }
  }, [availableParticipants]);

  useEffect(() => {
    async function search() {

      const conversation = await dispatch(searchConversations(selectedUserKeys, selectedSharedInboxKeys));

      if (!conversation) setExistingConversation('');
      if (conversation?.conversationKey) setExistingConversation(conversation.conversationKey);
    }

    search();
  }, [selectedSender, selectedRecipientKeys]);

  const createNewConversation = async (message: string) => {
    setIsCreatingConversation(true);

    try {
      const conversationKey = await dispatch(createConversation(
        selectedUserKeys,
        selectedSharedInboxKeys,
        { type: 'text', textContent: message },
      ));
      history.replace(`/messaging/inbox/${conversationKey}`);
    } catch (e) {
      // Do nothing
    }

    setIsCreatingConversation(false);
  };

  if (isCreatingConversation) {
    return (
      <div className="messagingNewMessageLoadingContainer">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <IndividualToInput availableRecipients={availableParticipantsForSelect} setSelectedRecipients={setSelectedRecipients} selectedRecipientKeys={selectedRecipientKeys} />
      <FromInput availableSenders={availableSenders} selectedSender={selectedSender} selectSender={setSelectedSender} />
      {existingConversation ?
        <ConversationContainer conversationKey={existingConversation} onSendMessage={() => history.replace(`/messaging/inbox/${existingConversation}`)} />
        :
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end' }}>
          <ComposeMessageBar sendMessage={createNewConversation} disabled={!selectedSender || selectedRecipientKeys.length < 1} autoFocusTextArea={false} />
        </div>
      }
    </>
  );
};

export default ComposeNonBatchMessage;
