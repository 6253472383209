import React from 'react';
import ComplianceStatus from 'components/ComplianceStatus';

const styles = {
  label: {
    color: '#333',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.35,
    marginRight: 12,
    width: '80px',
    marginBottom: 0,
    flex: 1,
  },
  status: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    marginBottom: 0,
  },
};

export default function ComplianceItemStatus(props) {

  return (
    <div style={{ display: 'flex', columnGap: 12 }}>
      <div>
        {props.items?.map(item => <p key={item.itemName} style={{ ...styles.label, ...props.labelStyle, width: 'max-content' }}>{item.itemName}</p>)}
      </div>
      <div>
        {props.items?.map(item => <ComplianceStatus statusColor={item.statusColor} statusLabel={item.statusLabel} fontSize={12} />)}
      </div>
    </div>
  );
}
