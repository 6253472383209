import React from 'react';
import PropTypes from 'prop-types';
import Policies from './Policies';
import SplitView from '../../components/SplitView';
import { permissions } from '../../config/permissions';
import Feature from '../../components/Feature';

const { func, string, array, bool } = PropTypes;

const SitesPolicies = (props) => {
  return (
    <React.Fragment>
      <Feature
        permissionRequired={permissions.SITES_CAN_VIEW_POLICIES}
        failureMessage={
          <p className="failureMessage">
            You do not have permissions to view site policies. Please contact your system administrator.
          </p>
        }
      >
        <SplitView
          items={props.items}
          selectedKey={props.selectedSiteKey}
          loading={props.loading}
          onItemClick={props.onSiteClick}
        >
          <Policies
            onRateCardClick={props.onRateCardClick}
            canEdit={permissions.SITES_CAN_EDIT_POLICIES}
            {...props}
          />
        </SplitView>
      </Feature>
    </React.Fragment>
  );
};

SitesPolicies.propTypes = {
  onSiteClick: func.isRequired,
  selectedSiteKey: string,
  items: array.isRequired,
  loading: bool.isRequired,
};

SitesPolicies.defaultProps = {
  selectedSiteKey: null,
};

export default SitesPolicies;
