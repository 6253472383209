import moment from 'moment';
import { auth } from 'lib/auth';
import { setUser } from 'lib/tracking';
import * as api from 'lib/api';
import * as authApi from 'lib/api/auth';
import * as globalThunks from 'thunks/global';
import * as userReducer from 'reducers/user';
import { setPasswordExpiry, clearPasswordExpiry } from 'reducers/global';


export function signIn(email, password) {
  return async (dispatch, getState) => {
    dispatch(userReducer.fetchUser());
    dispatch(clearPasswordExpiry());

    try {
      // Validate username and password with login API
      const response = await authApi.login(email, password);

      // Sign in with custom token and set userKey
      await auth().signInWithCustomToken(response.token);

      // // If password has expired or expiring soon, set root to password expired view
      // if (response.passwordExpiresAt && (response.passwordExpired || response.passwordExpiringSoon)) {
      const { passwordExpiresAt, passwordExpiringSoon, passwordExpired } = response;
      dispatch(setPasswordExpiry({ passwordExpiresAt, passwordExpiringSoon, passwordExpired, email, currentPassword: password }));
      //   return true;
      // }

      // Otherwise call global startup thunk
      await dispatch(globalThunks.startWatchingGlobalData(response.userKey));
      dispatch(userReducer.fetchUserSuccess(response.userKey));
      return true;

    } catch (error) {
      dispatch(userReducer.fetchUserError(error.humanReadableErrorMessage ?? error.message ?? 'User not found'));
      return false;
    }
  };
}

export function logout(redirect = true) { // eslint-disable-line consistent-return
  return async (dispatch) => {
    await Promise.all([
      dispatch(globalThunks.stopWatchingGlobalData()),
      dispatch(userReducer.logout()),
      auth().signOut(),
      setUser(true),
    ]);
    dispatch({ type: 'RESET_STATE' });
    if (redirect) {
      window.location.href = '/';
      window.location.reload(true);
    }
  };
}

export function logoutIfLoggedIn(redirect = true) {
  return async (dispatch, getState) => {
    const userId = getState().user.userId;
    if (userId) return dispatch(logout(redirect));
    return null;
  };
}

// Firebase password reset
export function confirmFirebasePasswordReset(actionCode, newPassword) {
  return async (dispatch) => {
    try {
      await auth().confirmPasswordReset(actionCode, newPassword);
      dispatch(userReducer.fetchUserError('Password reset has been confirmed and new password updated'));
      dispatch(userReducer.passwordIsReset());
    } catch (error) {
      dispatch(userReducer.fetchUserError(error.message));
    }
  };
}
export function verifyFirebasePasswordResetCode(actionCode) {
  return async (dispatch) => {
    try {
      const email = await auth().verifyPasswordResetCode(actionCode);
      dispatch(userReducer.passwordResetDetails(email));
    } catch (error) {
      dispatch(userReducer.verifyTokenError(error.message));
    }
  };
}


// Dagny password reset
export function sendResetPasswordEmail(email, loginTarget) {
  return async (dispatch) => {
    try {
      // await auth().sendPasswordResetEmail(email); // Send firebase reset email (no longer used)
      await authApi.sendPasswordResetEmail(email, loginTarget);
      dispatch(userReducer.fetchUserError('Email sent successfully'));
      setTimeout(() => {
        dispatch(userReducer.cancelForgotPassword());
      }, 5000);
    } catch (error) {
      dispatch(userReducer.fetchUserError(error.message));
      throw error;
    }
  };
}

export function fetchPasswordRequirements() {
  return async (dispatch) => {
    try {
      const response = await authApi.fetchPasswordRequirements();
      dispatch(userReducer.passwordResetDetails(null, response.passwordRequirements));
    } catch (error) {
      dispatch(userReducer.verifyTokenError(error.humanReadableErrorMessage ?? error.message));
    }
  };
}

export function verifyCircularWavePasswordResetToken(token) {
  return async (dispatch) => {
    try {
      const response = await authApi.lookupPasswordResetToken(token);
      dispatch(userReducer.passwordResetDetails(response.email, response.passwordRequirements));
      if (response.loginTarget === 'mobile-app') dispatch(userReducer.landFromMobile());
    } catch (error) {
      dispatch(userReducer.verifyTokenError(error.humanReadableErrorMessage ?? error.message));
    }
  };
}

export function resetPasswordWithCurrentPassword(currentPassword, newPassword) {
  return async (dispatch) => {
    try {
      const response = await authApi.resetPasswordWithCurrentPassword(currentPassword, newPassword);
      dispatch(userReducer.fetchUserError('Password reset has been confirmed and new password updated'));
      dispatch(userReducer.passwordIsReset());
      if (response.loginTarget === 'mobile-app') dispatch(userReducer.landFromMobile());
    } catch (error) {
      dispatch(userReducer.fetchUserError(error.humanReadableErrorMessage ?? error.message));
    }
  };
}

export function resetPasswordWithToken(token, newPassword) {
  return async (dispatch) => {
    try {
      const response = await authApi.resetPasswordWithToken(token, newPassword);
      dispatch(userReducer.fetchUserError('Password reset has been confirmed and new password updated'));
      dispatch(userReducer.passwordIsReset());
      if (response.loginTarget === 'mobile-app') dispatch(userReducer.landFromMobile());
    } catch (error) {
      dispatch(userReducer.fetchUserError(error.humanReadableErrorMessage ?? error.message));
    }
  };
}


let lastActiveLastUpdatedAt = null;
export function setLastActive() {
  return async (dispatch) => {

    async function callSetLastActiveApi(time) {
      const when = time.toISOString();
      await api.post('user/setLastActive', { when });
      dispatch(userReducer.setLastActive(when));
    }

    // Get current time
    const now = moment.utc();

    // If this is the first call, delay by 10 seconds to avoid slowing down the initial page load
    if (!lastActiveLastUpdatedAt) {
      lastActiveLastUpdatedAt = now;
      setTimeout(() => callSetLastActiveApi(now), 10 * 1000);
    }

    // Else throttle calls to setLastActive API to at most every 60 seconds
    if (now.diff(lastActiveLastUpdatedAt, 'seconds') > 60) {
      lastActiveLastUpdatedAt = now;
      callSetLastActiveApi(now);
    }

  };
}
