import React, { Component } from 'react';
import moment from 'moment-timezone';
import {
  MdUpdate,
  MdCheckCircle,
} from 'react-icons/md';
import { connect } from 'react-redux';
import colors from '../../config/colors';
import * as globalReducers from '../../reducers/global';
import './updateMessageBar.css';

const styles = {
  updateMessage: {
    color: colors.white,
    fontSize: '0.9rem',
  },
  icon: {
    width: '30px',
    height: '30px',
    color: 'white',
    marginBottom: '3px',
    marginRight: '5px',
  },
};

class UpdateMessageBar extends Component {

  constructor(props) {
    super(props);
    this.timeout = null;
    this.mounted = false;
    this.state = {
      messageFadeOut: false,
    };
  }

  getRootUniqueUrl = () => `/random-url-${encodeURIComponent(moment.utc().toISOString())}-${encodeURIComponent(Math.random())}`;

  updateVersion = () => {
    window.localStorage.setItem('updateComplete', JSON.stringify(true));
    window.location.href = this.getRootUniqueUrl();
  }

  checkStatus = () => {
    if (this.props.updateCompleted) {
      this.startTimeout();
      return 'updateCompleteMessageBar';
    }
    if (this.props.updateRequired) {
      return 'updateMessageBar';
    }
    return 'hideMessageBar';
  }

  componentWillUnmount = () => {
    this.mounted = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  startTimeout = () => {
    this.timeout = setTimeout(() => {
      window.localStorage.setItem('updateComplete', JSON.stringify(false));
      this.props.updateMessageDisplayed();
      if (this.mounted) this.setState({ messageFadeOut: true });
    }, 5000);
  }

  removeUpdatedMessage = () => {
    if (this.props.updateCompleted) {
      window.localStorage.setItem('updateComplete', JSON.stringify(false));
      this.props.updateMessageDisplayed();
      clearTimeout(this.timeout);
      if (this.mounted) this.setState({ messageFadeOut: true });
    }
  }

  showContent = () => {
    if (this.props.updateRequired) {
      return (
        <>
          <div>
            <MdUpdate style={styles.icon} />
          </div>
          <p style={styles.updateMessage}>
            {}
            We have a new version of Dagny ready for you. Please click
            {' '}
            <span><a className="restartLink" onClick={() => this.updateVersion()}>here</a></span>
            {' '}
            to refresh and update.
          </p>
        </>
      );
    } else if (this.props.updateCompleted || this.state.messageFadeOut) {
      return (
        <>
          <div>
            <MdCheckCircle style={styles.icon} />
          </div>
          <p style={styles.updateMessage}>
            You are now running the latest version of Dagny.
          </p>
        </>
      );
    }
    return null;
  }

  render() {

    // Don't show bar when in development when hot-reloading is enabled
    if (module.hot && process.env.NODE_ENV === 'development') return null;

    // Don't show if there is no message to show
    if (!(this.props.updateRequired || this.props.updateCompleted || this.state.messageFadeOut)) return null;

    return (
      <div
        role="button"
        tabIndex={0}
        onClick={this.removeUpdatedMessage}
        className={!this.state.messageFadeOut ? this.checkStatus() : 'messageFadeOut'}
      >
        {this.showContent()}
      </div>
    );
  }
}


function mapStateToProps({ global }) {
  return {
    responseUrl: global.responseUrl,
    updateCompleted: global.updateCompleted,
    updateRequired: global.updateRequired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateComplete: () => dispatch(globalReducers.updateComplete()),
    updateMessageDisplayed: () => dispatch(globalReducers.updateMessageDisplayed()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMessageBar);
