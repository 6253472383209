import React from 'react';
import Pill from 'components/Pill';
import colors from 'config/colors';

interface PaymentRunStatusPillProps {
  paidAt: string | null,
  sentAt: string | null,
  scheduledPaymentTime: string | null,
  scheduledSendTime: string | null,
}

const PaymentRunStatusPill : React.FC<PaymentRunStatusPillProps> = (props : PaymentRunStatusPillProps) => {
  if (props.paidAt) { return <Pill label="Paid" style={{ backgroundColor: colors.cavalry.pending, width: 150 }} />; }
  if (props.sentAt && props.scheduledPaymentTime) { return <Pill label="Payment Scheduled" style={{ color: colors.cavalry.pending, borderColor: colors.cavalry.pending, width: 150 }} outline />; }
  if (props.sentAt) { return <Pill style={{ width: 150 }} label="Sent For Payment" type="positive" />; }
  if (props.scheduledSendTime) { return <Pill style={{ width: 150 }} label="Scheduled" type="positive" outline />; }
  return <Pill label="Unscheduled" style={{ width: 150, backgroundColor: colors.cavalry.backgroundLight4, color: colors.white }} outline />;
};

export { PaymentRunStatusPill };
