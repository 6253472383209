import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendMessage } from 'thunks/timesheets';
import { clearMessages } from 'reducers/timesheets';
import Loading from 'components/Loading';
import MessageBar from './MessageBar';
import TimesheetAuditTrailItem from './TimesheetAuditTrailItem';

import './style.scss';

class TimesheetMessages extends Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  state = { }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messages !== this.props.messages) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    this.props.clearMessages();
  }

  scrollToBottom = () => {
    if (!this.messageList) return;
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  render() {
    const adminIsInSameOrgAsShift = this.props.adminOrgKey === this.props.shiftOrgKey;

    return (
      <div
        className="timesheet-messages"
      >
        <h4 className="timesheet__header timesheet-messages__header">Messages & Audit Trail</h4>
        {this.props.fetchingTimesheetMessages ?
          <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
          :
          <>
            <div
              ref={(div) => {
                this.messageList = div;
              }}
              className="timesheet-messages__scrollview"
            >
              {this.props.messages.map((message, index) => {
                return (<TimesheetAuditTrailItem
                  message={message}
                  key={message.sent_at}
                  index={index}
                />);
              })}
            </div>
            {adminIsInSameOrgAsShift && (
              <MessageBar sendMessage={message => this.props.sendMessage(this.props.timesheetKey, message)} focusTextArea={() => this.scrollToBottom()} />
            )}
          </>
        }
      </div>
    );
  }
}

function mapStateToProps({ timesheets, jobs, global }, ownProps) {
  const shift = jobs?.details[ownProps.shiftKey]?.details;
  const shiftOrgKey = shift?.orgKey ?? null;
  return {
    adminOrgKey: global.currentOrgKey,
    shiftOrgKey,
    messages: timesheets.messages,
    sendingMessage: timesheets.sendingMessage,
    fetchingTimesheetMessages: timesheets.fetchingTimesheetMessages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendMessage: (timesheetKey, message) => dispatch(sendMessage(timesheetKey, message)),
    clearMessages: () => dispatch(clearMessages()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetMessages);
