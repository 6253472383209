import React from 'react';
import PropTypes from 'prop-types';
import { MdRemoveCircle } from 'react-icons/md';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import '../../index.scss';

//----------------------------------------------------------------
// STYLES
//----------------------------------------------------------------

const styles = {
  container: {
    marginBottom: spacing.base,
    borderWidth: 1,
    borderColor: colors.title,
    borderStyle: 'solid',
    borderRadius: spacing.tiny,
    padding: spacing.tiny,
  },
  delete: {
    color: colors.red,
    float: 'right',
    height: '26px',
    width: '26px',
    cursor: 'pointer',
  },
};

//----------------------------------------------------------------
// EXPORT
//----------------------------------------------------------------

export default function FullWidthItem(props) {
  return (
    <div
      style={styles.container}
    >
      {props.label}
      <MdRemoveCircle style={styles.delete} onClick={() => { props.onDelete(props.label); }} className="rise" />
    </div>
  );
}

const { string, func } = PropTypes;
FullWidthItem.propTypes = {
  label: string.isRequired,
  onDelete: func.isRequired,
};
