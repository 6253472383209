/* eslint no-useless-escape:"off" */

function checkInviteCode(string) {
  return /^[a-zA-Z0-9]+$/.test(string);
}

function checkEmail(string) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
}

function checkName(string) {
  return /^[a-zA-Z]+$/.test(string);
}

function checkNumber(string) {
  return /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(string);
}

export default {
  checkInviteCode,
  checkEmail,
  checkName,
  checkNumber,
};
