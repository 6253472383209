import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { updateBanksAndAgencies } from 'lib/api/jobs';
import { ManageBanksAndAgenciesModal } from './ManageBanksAndAgenciesModal';


export const ManageBanks = (props) => {
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [isUpdatingBanks, setIsUpdatingBanks] = useState(false);
  const banksMetadata = props.adminBanksMetadata.map(bank => ({ key: bank.bankKey, name: bank.name, checked: Object.keys(props.releasedBanks).includes(bank.bankKey) }));

  const updateBanks = async (payload) => {
    setManageModalOpen(false);
    setIsUpdatingBanks(true);
    const response = await updateBanksAndAgencies({ shiftKey: props.shiftKey, ...payload });
    const { updatedShift } = response;
    props.setShiftDetails(props.shiftKey, updatedShift);
    await props.onShiftChange();
    setIsUpdatingBanks(false);
  };
  const bankNames = Object.values(props.releasedBanks);
  const canReleaseOrRetract = props.canReleaseToBank || props.canRetractBank;
  return (
    <div className="shiftDetailsBookingsButtonsContainer">
      <div className="shiftDetailsSubSectionContent">
        <div className="shiftDetailsBoldHeadings">
          <p className="detail-list-item">Released to</p>
        </div>
        <div className="shiftDetailsValues">
          {bankNames.length > 0 ?
            <p className="detail-list-item">{bankNames.map(bank => <p>{bank}</p>)}</p>
            :
            <p className="detail-list-item">Not released to bank</p>
          }
        </div>
      </div>
      {(canReleaseOrRetract) && (
        <div style={{ alignSelf: 'flex-end', marginTop: '12px' }}>
          {isUpdatingBanks ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loading size={23} />
            </div>
            :
            <Button outline white shadow={false} onClick={() => setManageModalOpen(true)} disabled={false}>
              Edit Banks
            </Button>
          }
        </div>
      )}
      {manageModalOpen && <ManageBanksAndAgenciesModal canRelease={props.canReleaseToBank} canRetract={props.canRetractBank} submit={payload => updateBanks(payload)} type="bank" header="Manage Staff Banks" options={banksMetadata} isOpen onClose={() => setManageModalOpen(false)} />}
    </div>
  );
};

const { arrayOf, shape, bool, string, func, objectOf } = PropTypes;

ManageBanks.propTypes = {
  shiftKey: string.isRequired,
  setShiftDetails: func.isRequired,
  onShiftChange: func.isRequired,
  adminBanksMetadata: arrayOf(shape({ bankKey: string, defaultBank: bool, name: string })).isRequired,
  releasedBanks: objectOf(string),
  canReleaseToBank: bool.isRequired,
  canRetractBank: bool.isRequired,
};

ManageBanks.defaultProps = { releasedBanks: {} };
