import React from 'react';
import { useHistory } from 'react-router';
import SearchBox from 'components/SearchBox';
import { searchForShift } from 'lib/api/jobs';
import { convertFromUtcToTimezone } from 'lib/helpers-time';


function ShiftSearchBox(props) {

  const history = useHistory();

  const searchForShiftByJobNumber = async (jobNumber) => {
    const response = await searchForShift(jobNumber);
    if (!response?.body?.shiftKey) return null;

    const date = convertFromUtcToTimezone(response.body.startTime, response.body.timezone).format('YYYY/MM/DD');
    history.push(`/shifts/${date}/${response.body.shiftKey}/details`);
    return true;
  };

  return (
    <SearchBox
      search={searchForShiftByJobNumber}
      placeholder="Search for shift by id..."
      noResultMessage="No shift found!"
      expand={props.expand}
      style={props.style}
      fullWidth={props.fullWidth}
    />
  );
}

export default ShiftSearchBox;
