import React, { Component } from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import { formatUKTel } from '../../lib/helpers-telephone';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import SectionedList from '../../components/SectionedList';

const styles = {
  header: {
    display: 'inline-block',
    margin: '20px 0 20px 30px',
    padding: '5px 40px',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.text,
    borderRadius: 4,
    color: colors.text,
    fontFamily: fonts.header.family,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
  },
  rowContainer: {
    boxSizing: 'border-box',
    height: 100,
    width: '90%',
    margin: '0 auto 20px auto',
    padding: spacing.small,
    borderRadius: 4,
    border: `1px solid ${colors.cavalry.line}`,
    cursor: 'pointer',
  },
  logoContainer: {
    display: 'inline-block',
    height: '100%',
    width: '30%',
    boxSizing: 'border-box',
    padding: spacing.tiny,
    verticalAlign: 'top',
  },
  logo: {
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  nameContainer: {
    display: 'inline-block',
    height: '100%',
    width: '50%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  textContainer: {
    display: 'flex',
    height: '50%',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fonts.header.family,
    fontWeight: fonts.main.weight,
  },
  name: {
    display: 'flex',
    color: colors.text,
    fontSize: 24,
  },
  mainTelephone: {
    display: 'flex',
    color: colors.grays.light,
    fontSize: fonts.header.size,
  },
  rightContainer: {
    display: 'inline-block',
    height: '100%',
    width: '20%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
};

export default class Suppliers extends Component {

  renderSectionHeader = (sectionHeaderText) => {
    return (
      <div style={styles.header}>{sectionHeaderText}</div>
    );
  }

  renderRow = (rowData, rowKey) => {
    const logoStyles = { ...styles.logo,
      backgroundImage: `url(${rowData.logoUrl})`,
    };
    return (
      <div
        key={rowKey}
        style={styles.rowContainer}
        onClick={() => this.props.openSupplierModal(rowData.supplierKey)}
      >
        <div style={styles.logoContainer}>
          {rowData.logoUrl ? <div style={logoStyles} /> : ''}
        </div>
        <div style={styles.nameContainer}>
          <div style={styles.textContainer}>
            <div style={styles.name}>{rowData.companyName}</div>
          </div>
          <div style={styles.textContainer}>
            <div style={styles.mainTelephone}>
              {formatUKTel(rowData.mainTelephone)}
            </div>
          </div>
        </div>
        <div style={styles.rightContainer}>&nbsp;</div>
      </div>
    );
  }

  render() {
    return (
      <SectionedList
        data={this.props.suppliersData}
        getSectionHeaderText={value => `Tier ${value}`}
        handleRowClicked={this.props.openSupplierModal}
        renderRow={this.renderRow}
        renderSectionHeader={this.renderSectionHeader}
        sectionSortProperty="tier"
        sectionSortOrder="ascending"
        rowSortProperty="companyName"
        rowSortOrder="ascending"
      />
    );
  }
}

const { object, func } = PropTypes;

Suppliers.propTypes = {
  suppliersData: object,
  openSupplierModal: func.isRequired,
};

Suppliers.defaultProps = {
  suppliersData: {},
};
