import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchAdminGroupList } from 'thunks/settings';
import { clearAdminGroupList, clearAdminGroupListError } from 'reducers/settings';

import { AdminGroupFromList } from 'types/AdminGroup';

import colors from 'config/colors';

import Page from 'components/Page';
import Loading from 'components/Loading';
import DataTable from 'components/DataTable';
import ErrorScreen from 'components/ErrorScreen';

import SettingsHeader from '../SettingsHeader';

const adminGroupListColumns = [
  { name: 'name', header: 'Name' },
  { name: 'description', header: 'Description' },
  { name: 'memberCount', header: 'User Count', formatter: (name: string, row: AdminGroupFromList) => `${row.memberCount ?? 0} user${row.memberCount === 1 ? '' : 's'}` },
];

function useAdminGroupList() {
  const dispatch = useDispatch();

  const isFetchingAdminGroupList = useSelector(state => state.settings.isFetchingAdminGroupList);
  const adminGroupList = useSelector(state => state.settings.adminGroups);
  const adminGroupListError = useSelector(state => state.settings.adminGroupListError);

  const fetchAdminGroupsList = () => dispatch(fetchAdminGroupList());

  useEffect(() => {
    fetchAdminGroupsList();

    return () => {
      dispatch(clearAdminGroupList());
    };
  }, []);

  const clearAdminGroupsError = () => dispatch(clearAdminGroupListError());

  return {
    isFetchingAdminGroupList,
    adminGroupList,
    adminGroupListError,
    adminGroupListColumns,
    clearAdminGroupsError,
    fetchAdminGroupsList,
  };
}

interface Props {
  goToDetailsView: (adminGroupKey: string) => void,
}

export default function AdminGroupList(props: Props): React.ReactElement {

  const {
    isFetchingAdminGroupList,
    adminGroupList,
    adminGroupListError,
    clearAdminGroupsError,
    fetchAdminGroupsList: refetchAdminGroupList,
  } = useAdminGroupList();

  return (
    <Page vflex disableScroll title="Settings: Admin Groups">
      <SettingsHeader selectedTabKey="admin-groups" />

      {adminGroupListError ?
        <ErrorScreen retry={refetchAdminGroupList} />
        :
        <>
          {/* Loading and No-Content */}
          {isFetchingAdminGroupList && <Loading flex />}
          {!isFetchingAdminGroupList && !adminGroupList?.length && (
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ color: colors.text }}>No Admin Groups Configured</p>
            </div>
          )}

          {/* Payment rates table */}
          {!!adminGroupList?.length && (
            <div style={{ flex: 1, overflow: 'auto' }}>
              <DataTable<AdminGroupFromList, undefined>
                columns={adminGroupListColumns as any}
                rows={adminGroupList}
                onRowClick={(adminGroupKey) => {
                  if (adminGroupKey) props.goToDetailsView(adminGroupKey);
                }}
                overrideHeadingStyles={{
                  color: colors.text,
                  textTransform: 'none',
                  fontSize: '0.9rem',
                }}
              />
            </div>
          )}
        </>
      }
    </Page>
  );
}
