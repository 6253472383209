import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from 'config/colors';
import spacing from 'config/spacing';

import Button from 'components/Button';
import AuthPage from './ui/AuthPage';
import NamesForm from './ui/NamesForm';
import NewPasswordForm from './ui/NewPasswordForm';

import { checkPasswordRequirements } from './password-requirements';

const styles = {
  errorBox: {
    width: 280,
    marginBottom: spacing.base,
    color: colors.red,
    lineHeight: 1.4,
    textAlign: 'left',
  },
};

const steps = ['names', 'email-password'];
function Registration(props) {

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];

  let header = '';
  header += props.invite?.roleName ? `${props.invite.roleName} ` : '';
  header += `Registration (step ${currentStepIndex + 1}/${steps.length})`;

  const passwordRequirementsWithStatus = checkPasswordRequirements(props.passwordRequirements, props.formValues?.password ?? '');
  const hasPasswordErrors = passwordRequirementsWithStatus.some(req => !req.isPassing);

  const hasUnacceptedDocuments = props.acceptanceDocuments?.some(doc => !doc.isAccepted);

  return (
    <AuthPage header={header}>

      {/* Error message */}
      {!!props.regError && (
        <p style={styles.errorBox}>{props.regError}</p>
      )}

      {!!props.showForm &&
        <>
          {/* Form Fields */}
          <div>
            {currentStep === 'names' && (
              <NamesForm
                email={props.email}
                formValues={props.formValues}
                setFormValue={props.setFormValue}
              />
            )}
            {currentStep === 'email-password' && (
              <NewPasswordForm
                acceptanceDocuments={props.acceptanceDocuments}
                passwordRequirementsWithStatus={passwordRequirementsWithStatus}
                formValues={props.formValues}
                setFormValue={props.setFormValue}
                displayAcceptanceDocument={props.displayAcceptanceDocument}
              />
            )}
          </div>

          {/* Back, Next and Signup buttons */}
          <div className="space-children-12" style={{ textAlign: 'right' }}>
            {currentStepIndex > 0 && (
              <Button white outline large onClick={() => setCurrentStepIndex(index => index - 1)}>Back</Button>
            )}
            {currentStepIndex < (steps.length - 1) && (
              <Button positive large onClick={() => setCurrentStepIndex(index => index + 1)}>Next</Button>
            )}
            {currentStepIndex === (steps.length - 1) && (
              <Button disabled={hasPasswordErrors || hasUnacceptedDocuments} positive large onClick={props.register}>Register</Button>
            )}
          </div>
        </>
      }

    </AuthPage>
  );
}

const { string, func, bool } = PropTypes;

Registration.propTypes = {
  email: string.isRequired,
  register: func.isRequired,
  regError: string,
  showForm: bool.isRequired,
};

Registration.defaultProps = {
  regError: null,
};


export default Registration;
