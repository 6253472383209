import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Specialities from './Specialities';
import { fetchReportGroupedBySpeciality } from '../../../thunks/reporting';
import { clearReportingError } from '../../../reducers/reporting';
import { reports } from '../Reports';

import '../DrillDown/styles.scss';

const { objectOf, object, func, string, number, bool, shape, oneOfType } = PropTypes;

class SpecialitiesContainer extends Component {
  static propTypes = {
    loading: shape({
      fillRate: bool.isRequired,
      fillRateByRole: bool.isRequired,
      fillRateBySpeciality: bool.isRequired,
      requests: bool.isRequired,
      requestsByRole: bool.isRequired,
      requestsBySpeciality: bool.isRequired,
      spend: bool.isRequired,
      spendByRole: bool.isRequired,
      spendBySpeciality: bool.isRequired,
      activity: bool.isRequired,
    }).isRequired,
    reports: objectOf(object).isRequired,
    selectedRange: shape({
      from: string.isRequired,
      to: string.isRequired,
    }).isRequired,
    selectedSite: shape({ key: string, name: string }),
    selectedRole: shape({ key: string, name: string }),
    fetchReportGroupedBySpeciality: func.isRequired,
    match: objectOf(oneOfType([bool, number, string, object])).isRequired,
    reportError: shape({
      fillRate: string,
      fillRateByRole: string,
      fillRateBySpeciality: string,
      requests: string,
      requestsByRole: string,
      requestsBySpeciality: string,
      spend: string,
      spendByRole: string,
      spendBySpeciality: string,
      activity: string,
    }).isRequired,
    clearReportingError: func.isRequired,
  }

  static defaultProps = {
    selectedSite: {},
    selectedRole: {},
  }

  async componentDidMount() {
    const reportConfig = this.getReportConfig();
    await this.props.fetchReportGroupedBySpeciality(reportConfig.name);
  }

  async componentDidUpdate(prevProps) {
    const reportConfig = this.getReportConfig();
    const siteHasChanged = prevProps.selectedSite.key !== this.props.selectedSite.key;
    const dateHasChanged = (prevProps.selectedRange.from !== this.props.selectedRange.from) || (prevProps.selectedRange.to !== this.props.selectedRange.to);

    // Refetch report if site or date range has changed
    if (siteHasChanged || dateHasChanged) {
      if (!this.props.loading[`${reportConfig.name}BySpeciality`]) {
        await this.props.fetchReportGroupedBySpeciality(reportConfig.name);
      }
    }
  }

  // Find report config
  getReportConfig = () => reports.find(r => r.url === this.props.match.params.reportName);

  render() {
    const reportConfig = this.getReportConfig();

    return (
      <Specialities
        reports={this.props.reports}
        reportName={reportConfig.name}
        specialitiesBreakdown={!!reportConfig.specialitiesBreakdown}
        loading={this.props.loading}
        selectedRole={this.props.selectedRole}
        reportError={this.props.reportError}
        clearReportingError={this.props.clearReportingError}
      />
    );
  }
}

const mapStateToProps = ({ reporting }) => {
  return {
    loading: reporting.loading,
    reports: reporting.reports,
    selectedRange: reporting.selectedRange,
    selectedRole: reporting.selectedRole,
    selectedSite: reporting.selectedSite,
    reportError: reporting.reportError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReportGroupedBySpeciality: report => dispatch(fetchReportGroupedBySpeciality(report)),
    clearReportingError: reportName => dispatch(clearReportingError(reportName)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecialitiesContainer));
