import React, { CSSProperties, useEffect, useState, useMemo, useCallback } from 'react';
import { MdEmail } from 'react-icons/md';

import { AdminProfile } from 'types/Admin';

import colors from 'config/colors';
import { isTruthy } from 'lib/helpers-ts';
import useSafeDispatch from 'hooks/useSafeDispatch';
import useAsync from 'hooks/useAsync';
import { useAppDispatch, useSelector } from 'hooks/redux';
import { useFeatureFlag } from 'hooks/feature';

import { fetchAdmins, fetchAdminProfile } from 'thunks/admins';
import { fetchAdminProfileSuccess } from 'reducers/admins';
import { updateAdminEnableStatus, updateAdmin } from 'lib/api/accounts';

import Modal from 'components/Modal';
import Loading from 'components/Loading';
import ErrorScreen from 'components/ErrorScreen';
import CloseButton from 'components/CloseButton';
import { TabBar } from 'components/Tabs';
import SwitchList from 'components/SwitchList';
import Button from 'components/Button';
import SplitButton from 'components/SplitButton';

import { useAdminManagementPermissionsList } from './useAdminManagementPermissionsList';

const styles = {
  header: {
    display: 'flex',
    padding: 24,
    paddingRight: 12,
    alignItems: 'center',
    backgroundColor: colors.cavalry.backgroundLight6,
  },
  profilePic: {
    height: 80,
    width: 80,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: 'rgba(0, 68, 132, 0.33) 0px 4px 20px',
  },
  nameContainer: {
    marginLeft: 24,
    textAlign: 'left' as CSSProperties['textAlign'],
    color: colors.text,
  },
  icon: {
    color: colors.positive,
    paddingBottom: 4,
  },
};

interface AdminManagementModalProps {
  adminKey: string;
  closeModal: () => void;
}

export default function AdminManagementModal(props: AdminManagementModalProps) {

  const dispatch = useAppDispatch();
  // Fetch admin
  const loadAdmin = useCallback(() => dispatch(fetchAdminProfile(props.adminKey)), [props.adminKey]);
  useEffect(() => { loadAdmin(); }, [loadAdmin]);
  const canManageServices = useFeatureFlag('services', 'servicesCanManage');
  const currentAdminKey = useSelector(state => state.user.userId);
  const admin = useSelector(state => state.admins.adminProfile);
  const adminProfileError = useSelector(state => state.admins.adminProfileError);
  const isFetchingAdminProfile = useSelector(state => state.admins.isFetchingAdminProfile);

  const tabs = useMemo(() => {
    return [
      { key: 'general', title: 'Permissions' },
      { key: 'site', title: 'Sites' },
      canManageServices && { key: 'service', title: 'Services' },
    ].filter(isTruthy);
  }, [canManageServices]);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].key);

  // const [isUserEnabled, setIsUserEnabled] = useState<boolean | null>(admin?.isEnabled ?? null);
  const generalPermissionsHookObject = useAdminManagementPermissionsList(admin?.legacyGeneralPermissions);
  const sitePermissionsHookObject = useAdminManagementPermissionsList(admin?.sitePermissions);
  const servicePermissionsHookObject = useAdminManagementPermissionsList(admin?.servicePermissions);

  // const userEnabledHasChanged = isUserEnabled !== admin?.isEnabled;
  const saveButtonEnabled = !!admin && (
    // userEnabledHasChanged ||
    generalPermissionsHookObject.hasChanged ||
    sitePermissionsHookObject.hasChanged ||
    servicePermissionsHookObject.hasChanged
  );

  // Safely update profile state
  const updateAdminProfileState = useSafeDispatch(useCallback((newProfile: AdminProfile) => {
    if (newProfile.key === props.adminKey) dispatch(fetchAdminProfileSuccess(newProfile));
  }, [props.adminKey, dispatch]));

  // Update/save admin
  // Enable/disable admin
  const saveAdmin = useAsync<void>();
  const enableDisableAdmin = useAsync<void>();

  const save = useCallback(() => {
    const saveInner = async () => {
      const params : any = {};
      if (generalPermissionsHookObject.hasChanged) params.legacyGeneralPermissions = generalPermissionsHookObject.state;
      if (sitePermissionsHookObject.hasChanged) params.sitePermissions = sitePermissionsHookObject.state;
      if (servicePermissionsHookObject.hasChanged) params.servicePermissions = servicePermissionsHookObject.state;

      const result = await updateAdmin(props.adminKey, params);
      updateAdminProfileState(result.adminProfile);
      dispatch(fetchAdmins());
    };

    enableDisableAdmin.clearError();
    return saveAdmin.run(saveInner());
  }, [saveAdmin.run, props.adminKey, generalPermissionsHookObject.state, sitePermissionsHookObject.state, servicePermissionsHookObject.state]);


  const setEnabledStatus = useCallback((newIsUserEnabled) => {
    const saveInner = async () => {
      const result = await updateAdminEnableStatus(props.adminKey, newIsUserEnabled);
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      updateAdminProfileState(result.adminProfile);
      dispatch(fetchAdmins());
    };

    saveAdmin.clearError();
    return enableDisableAdmin.run(saveInner());
  }, [enableDisableAdmin.run, props.adminKey]);

  // Enable/Disable menu items
  const splitButtonMenuItems = [
    (props.adminKey !== currentAdminKey) && (
      admin?.isEnabled
        ? { key: 'disable-admin', label: 'Disable', onClick: () => setEnabledStatus(false) }
        : { key: 'enable-admin', label: 'Enable', onClick: () => setEnabledStatus(true) }
    ),
  ].filter(isTruthy);

  if (adminProfileError) {
    return (
      <Modal
        isOpen
        padding={0}
        onRequestClose={props.closeModal}
        vflex
        contentStyle={{ width: 600, height: 600, padding: 0, color: colors.text }}
      >
        <CloseButton top={15} right={15} handleClose={props.closeModal} />
        <div style={{ padding: 24 }}>
          <ErrorScreen
            title="Error loading admin"
            message={adminProfileError}
            retry={loadAdmin}
            dismiss={props.closeModal}
          />
        </div>
      </Modal>
    );
  }

  if (isFetchingAdminProfile || (admin && (enableDisableAdmin.isLoading || saveAdmin.isLoading))) {

    let message = '';
    if (isFetchingAdminProfile) message = 'Loading admin...';
    else if (saveAdmin.isLoading) message = 'Updating admin...';
    else if (enableDisableAdmin.isLoading) message = admin?.isEnabled ? 'Disabling admin...' : 'Enabling admin...';

    return (
      <Modal
        isOpen
        padding={0}
        onRequestClose={props.closeModal}
        vflex
        contentStyle={{ width: 600, height: 600, padding: 0, color: colors.text }}
      >
        <Loading flex message={message} />
      </Modal>
    );
  }

  if (!admin) {
    return <div />;
  }

  return (
    <Modal
      isOpen
      padding={0}
      onRequestClose={props.closeModal}
      vflex
      contentStyle={{ width: 600, height: 600, padding: 0, color: colors.text }}
    >
      <CloseButton top={15} right={15} handleClose={props.closeModal} />

      <div style={{ backgroundColor: colors.cavalry.backgroundLight6 }}>
        <div style={styles.header}>
          <div
            title={admin.displayName}
            style={{
              ...styles.profilePic,
              backgroundImage: `url(${
                admin.imageUrl ?
                  admin.imageUrl :
                  require('../../images/default-profile-pic.png')})`,
            }}
          />
          <div style={styles.nameContainer}>
            <h2>{admin.displayName}</h2>
            <p>{admin.email}</p>
          </div>
          <div style={{ flex: '1' }} />
          <div>
            <SplitButton
              menuItems={splitButtonMenuItems}
              openMenuDirection="left"
              black={admin.isEnabled}
              danger={!admin.isEnabled}
            >
              {admin.isEnabled ? 'Active' : 'Disabled'}
            </SplitButton>
          </div>
        </div>
        <TabBar tabs={tabs} selectedTabKey={currentTab} onTabClick={setCurrentTab} />
      </div>
      {(enableDisableAdmin.error || saveAdmin.error) && (
        <div style={{ padding: 12, color: colors.white, backgroundColor: colors.red }}>{enableDisableAdmin.error || saveAdmin.error}</div>
      )}

      <div style={{ flex: '1 1 0px', overflow: 'hidden' }}>
        {currentTab === 'basicInformation' && (
          <div style={{ padding: 12 }}>
            <MdEmail style={styles.icon} />
            <a href={`mailto:${admin.email}`} style={{ textDecoration: 'none' }}>
              {admin.email ?? ' '}
            </a>
          </div>
        )}
        {currentTab === 'general' && <SwitchList showSelectAll showResetButton hookObject={generalPermissionsHookObject} />}
        {currentTab === 'site' && <SwitchList showSelectAll showResetButton hookObject={sitePermissionsHookObject} />}
        {currentTab === 'service' && <SwitchList showSelectAll showResetButton hookObject={servicePermissionsHookObject} />}
      </div>
      <div className="space-children-12" style={{ flex: '0 0 auto', padding: 12, textAlign: 'right', borderTop: '1px solid #EAEAEA', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button black shadow={false} onClick={save} disabled={!saveButtonEnabled}>
          Save Changes
        </Button>
      </div>
    </Modal>
  );
}
