import React, { useState, CSSProperties } from 'react';
import { Popover as ReactTinyPopover, ArrowContainer } from 'react-tiny-popover';

import './styles.scss';

type PopoverPosition = 'top' | 'bottom' | 'left' | 'right';

interface PopoverProps {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;

  position?: PopoverPosition;
  positions?: Array<PopoverPosition>;
  popoverContent: JSX.Element;
  popoverMaxWidth?: number;
  popoverStyle?: CSSProperties;
  backgroundColor?: string,

  children: JSX.Element;
  containerStyle?: CSSProperties;
}

const Popover = (props: PopoverProps) : JSX.Element => {

  const isOpenState = useState(false);

  const isOpen = props.isOpen ?? isOpenState[0];
  const setPopoverIsOpen = props.setIsOpen ?? isOpenState[1];

  return (
    <ReactTinyPopover
      isOpen={isOpen}
      positions={props.positions ?? (props.position ? [props.position] : ['top', 'bottom', 'left', 'right'])}
      padding={10}
      onClickOutside={() => setPopoverIsOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={props.backgroundColor ?? '#333'}
          arrowSize={12}
          arrowStyle={{ opacity: 0.9 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="helpPopoverContainer" style={{ maxWidth: props.popoverMaxWidth, backgroundColor: props.backgroundColor }}>
            {props.popoverContent}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        style={props.containerStyle}
        onClick={() => setPopoverIsOpen(!isOpen)}
        onMouseOver={() => setPopoverIsOpen(true)}
        onMouseOut={() => setPopoverIsOpen(false)}
      >
        {props.children}
      </div>
    </ReactTinyPopover>
  );
};

Popover.defaultProps = {
  popoverMaxWidth: 350,
  containerStyle: undefined,
  popoverStyle: undefined,
  isOpen: undefined,
  setIsOpen: undefined,
  position: 'top',
  backgroundColor: '#333',
};

export default Popover;

