import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdAdd, MdFileDownload } from 'react-icons/md';
import { get, intersection } from 'lodash-es';
import { firstBy } from 'thenby';

import { isFeatureOn } from 'lib/features';
import { capitalize } from 'lib/helpers';
import colors from 'config/colors';
import { permissions } from 'config/permissions';
import { featureFlags } from 'config/featureFlags';

import * as bankThunks from 'thunks/bank';

import Page from 'components/Page';

import { FilterBarV2, FilterBarButton } from 'components/FilterBar';
import Feature from 'components/Feature';

import BankHeader from './BankHeader';
import BankTabBar from './BankTabBar';

import BankManagement from './BankManagement';
import ExportCSVModal from './ExportCSVModal';

const candidateNeedsReview = candidate => candidate.profileComplete
  && candidate.underReview
  && (!candidate.removedAt || candidate.removedAt > currentDateString);

// Candidate has joined up in last 3 months
const candidateIsNew = candidate => moment.utc(candidate.joinDate).isAfter(moment.utc().subtract(3, 'months'));

const { objectOf, object, bool, func, arrayOf, string } = PropTypes;
class BankManagementContainer extends Component {
  static propTypes = {
    loaded: bool.isRequired,
    rgsMetadata: objectOf(object),
    candidates: objectOf(object).isRequired,
    isFetchingCandidates: bool.isRequired,
    fetchCandidates: func.isRequired,
    roles: arrayOf(object).isRequired,
    customTerminology: objectOf(string),
    candidateSubsetFilter: func,
    adminSites: objectOf(object).isRequired,
    orgId: string,
    nav: objectOf(func).isRequired,
    subsetLabel: string.isRequired,
    specialitiesByGradeFeatureFlag: bool.isRequired,
    displayCandidateRatingsFeatureFlag: bool.isRequired,
    canViewRemoved: bool.isRequired,
    selectedBankKey: string.isRequired,
    selectedTabKey: string.isRequired,
  }

  static defaultProps = {
    rgsMetadata: null,
    customTerminology: {},
    candidateSubsetFilter: () => true,
    orgId: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      exportCSVModalOpen: false,

      filterString: '',
      sortedBy: props.selectedTabKey === 'new' ? 'joinDate' : 'name',
      sortInTheRightOrder: props.selectedTabKey !== 'new',
      filteredCandidates: [],
    };
  }

  componentDidMount() {
    this.props.fetchCandidates();
  }

  shouldComponentUpdate(nextProps) {
    if (!nextProps.loaded) return false;

    if (nextProps.candidates !== this.props.candidates || !this.state.initialStateSet) {
      this.filterAndSortCandidates();
    }

    return true;
  }

  async componentDidUpdate(prevProps) {

    // When candidates load, then compute filtered candidates list and cache it
    if (this.props.candidates !== prevProps.candidates || this.props.selectedBankKey !== prevProps.selectedBankKey || this.props.selectedTabKey !== prevProps.selectedTabKey) {
      this.filterAndSortCandidates();
    }

    // If the non-string filters change, then clear the filter string
    if (this.props.selectedFilterItems !== prevProps.selectedFilterItems) {
      this.setState({
        filterString: '',
        sortedBy: 'name',
        sortInTheRightOrder: true,
      }, () => this.filterAndSortCandidates());
    }
  }

  // Apply filters to raw candidates object
  filterCandidates(candidates) { // eslint-disable-line react/sort-comp
    const { subsetFilter, selectedFilterItems, canViewRemoved, selectedBankKey } = this.props;

    const candidatesArray = Object.values(candidates);
    const currentDate = moment.utc().toISOString();
    let candidateSubsetCount = candidatesArray.length;
    const filterString = (this.state.filterString || '').toLowerCase();

    // Apply filters to candidate array
    const filteredCandidates = candidatesArray.filter((candidate) => {

      // Reject removed candidates if admin does not have the required permission
      if (!canViewRemoved && (candidate?.removedAt < currentDate)) {
        return false;
      }

      // Reject candidate's that don't match the passed in filter lambda
      if (!candidate || !subsetFilter(candidate)) {
        candidateSubsetCount -= 1;
        return false;
      }

      // Reject if candidate doesn't match the bank filter
      if (selectedBankKey !== 'all') {
        if (!candidate.bankKeys.includes(selectedBankKey)) return false;
      }

      // Reject if candidate doesn't match drop-down filters
      if (selectedFilterItems) {

        // Reject if candidate doesn't match sites filter
        const siteKeys = Object.entries(candidate?.sites || {}).filter(([key, enabled]) => !!enabled).map(([key]) => key);
        if (selectedFilterItems.sites?.length && siteKeys.length && !intersection(selectedFilterItems.sites, siteKeys).length) return false;

        // Reject if candidate doesn't match roles filter
        if (selectedFilterItems.roles?.length && !selectedFilterItems.roles.includes(candidate.roleKey)) return false;

        // Reject if candidate doesn't match specialities filter
        if (selectedFilterItems.specialities?.length && candidate.specialityIds && !intersection(selectedFilterItems.specialities, candidate.specialityIds).length) return false;

        // Reject if candidate doesn't match specialities filter
        if (selectedFilterItems.grades?.length && candidate.gradeIds && !intersection(selectedFilterItems.grades, candidate.gradeIds).length) return false;
      }

      // Reject if candidate doesn't match filter string
      if (filterString) {
        const name = candidate.name ? candidate.name.toLowerCase() : null;
        if (!name || name.indexOf(filterString) === -1) {
          return false;
        }
      }

      // Else keep candidate in filtered array
      return true;
    });

    return {
      filteredCandidates,
      candidateSubsetCount,
      total: candidatesArray.length,
      filtered: filteredCandidates.length,
    };
  }

  /* eslint-disable-next-line react/sort-comp */
  sortCandidates = (candidates, headerName, sortInTheRightOrder) => {

    const getSortableValue = (candidate, sortableAttribute) => {

      const sortValue = candidate[sortableAttribute];
      if (!sortValue) return '';

      if (sortableAttribute === 'roleKey') {
        return get(this.props.rgsMetadata, [sortValue, 'name'], '');
      }

      if (sortableAttribute === 'gradeIds' || sortableAttribute === 'specialityIds') {

        const rgsGradesData = get(this.props, ['rgsMetadata', candidate.roldId, (sortableAttribute === 'gradeIds') ? 'grades' : 'specialities']);
        const firstCandidateGradeOrSpecId = get(sortValue, '0');
        const firstCandidateGradeOrSpecName = get(rgsGradesData, [firstCandidateGradeOrSpecId, 'name']);

        return firstCandidateGradeOrSpecName ? `${firstCandidateGradeOrSpecName}, ${sortValue.length - 1}` : sortValue;
      }

      return sortValue;
    };

    // .slice(0) forces a new array to be created which allows the DataTable component to detect that the data has changed (order)
    const currentDate = moment.utc().toISOString();
    return candidates
      .slice(0)
      .sort(
        firstBy(row => row.removedAt < currentDate) // Sort removed candidates to end of the list
          .thenBy(row => getSortableValue(row, headerName), sortInTheRightOrder ? 1 : -1),
      );
  };

  // Apply filters to candidates array and cache the result
  filterAndSortCandidates = () => {
    this.setState((state, props) => {
      const { filteredCandidates, candidateSubsetCount } = this.filterCandidates(props.candidates);
      const sortedFilteredCandidates = this.sortCandidates(filteredCandidates, state.sortedBy, state.sortInTheRightOrder);
      return {
        filteredCandidates: sortedFilteredCandidates,
        candidateSubsetCount,
        initialStateSet: true,
      };
    });
  }

  setSortColumn = (headerName) => {
    this.setState(state => ({
      sortedBy: headerName,
      sortInTheRightOrder: (headerName === state.sortedBy) ? !state.sortInTheRightOrder : true,
    }), () => this.filterAndSortCandidates());
  }

  setFilterString = (newFilterString) => {
    this.setState({
      sortedBy: 'name',
      sortInTheRightOrder: true,
      filterString: newFilterString,
    }, () => this.filterAndSortCandidates());
  }

  openExportCSVModal = () => {
    this.setState({ exportCSVModalOpen: true });
  }

  openProfile = (id) => {
    const candidate = this.props.candidates[id];
    this.props.nav.openCandidateProfile(id, candidate?.underReview && 'compliance');
  }

  render() {

    const buttons = (
      <>
        <FilterBarButton white outline border={false} onClick={this.openExportCSVModal} disabled={!this.state.filteredCandidates.length}>
        {!this.props.isMobile ? 'Export' : null} <MdFileDownload />
        </FilterBarButton>
        <Feature permissionRequired={permissions.BANK_CAN_INVITE}>
          <FilterBarButton className="bank-invite-button" black shadow={false} onClick={this.props.nav.openInviteModal}>
          {!this.props.isMobile ? 'Invite' : null} <MdAdd />
          </FilterBarButton>
        </Feature>
      </>
    );

    const isLoading = !this.props.loaded || !this.state.initialStateSet || this.props.isFetchingCandidates;
    const bankName = this.props.customTerminology?.bank ?? 'Staff Bank';
    const needReviewCount = Object.values(this.props.candidates || {})
      .filter(candidate => candidateNeedsReview(candidate) && (this.props.selectedBankKey === 'all' || candidate.bankKeys.includes(this.props.selectedBankKey)))
      .length;

    return (
      <Page vflex title={`${bankName}: ${capitalize(this.props.subsetLabel)}`} contentClassName="bankManagement" topBarButtons={buttons}>
        <div style={{ backgroundColor: colors.cavalry.backgroundLight6 }}>
          <BankHeader
            buttons={buttons}
            needReviewCount={this.props.selectedTabKey === 'for-review' ? 0 : needReviewCount}
            navigateToReview={() => this.props.nav.setSelectedTab('for-review')}
          />
          <BankTabBar
            selectedTabKey={this.props.selectedTabKey}
            setSelectedTab={this.props.nav.setSelectedTab}
            selectedBankKey={this.props.selectedBankKey}
            setSelectedBank={this.props.nav.setSelectedBank}
            filterString={this.state.filterString}
            setFilterString={this.setFilterString}
          />
        </div>
        <div style={{ padding: '12px 0px', borderBottom: `1px solid ${colors.cavalry.line}` }}>
          <FilterBarV2 namespace="bank" filterConfigKey="enabledBankFilters" />
        </div>
        <BankManagement
          isLoading={isLoading}
          candidates={this.state.filteredCandidates || []}
          isFetchingCandidates={this.props.isFetchingCandidates}
          openProfile={this.openProfile}
          openBulkUploadModal={this.props.nav.openBulkUploadModal}
          rgsMetadata={this.props.rgsMetadata}
          adminBanks={this.props.adminBanks}
          sortDataByHeader={column => this.setSortColumn(column)}
          sortedBy={this.state.sortedBy}
          sortInTheRightOrder={this.state.sortInTheRightOrder}
          specialitiesByGradeFeatureFlag={this.props.specialitiesByGradeFeatureFlag}
          displayCandidateRatingsFeatureFlag={this.props.displayCandidateRatingsFeatureFlag}
        />
        {this.state.exportCSVModalOpen && <ExportCSVModal candidates={this.state.filteredCandidates || []} onClose={() => this.setState({ exportCSVModalOpen: false })} />}
      </Page>
    );
  }
}

function mapStateToProps({ bank, filter, rgs, global, user, screen }) {
  return {
    isMobile: screen.isMobile,
    candidates: bank.candidates || [],
    isFetchingCandidates: bank.isFetchingCandidates,
    rgsMetadata: rgs.rgsMetadata,
    adminBanks: global.adminBanks,
    roles: rgs.roles,
    customTerminology: global.orgConfig ? global.orgConfig.customTerminology : null,
    loaded: !!(bank.candidates && bank.fetched),
    adminSites: user.sites || {},
    specialitiesByGradeFeatureFlag: isFeatureOn(featureFlags.SPECIALITIES_BY_GRADE, null, user, global),
    displayCandidateRatingsFeatureFlag: isFeatureOn(featureFlags.RATINGS_DISPLAY_CANDIDATE, null, user, global),
    canViewRemoved: isFeatureOn(null, permissions.BANK_CAN_VIEW_REMOVED, user, global),
    selectedFilterItems: filter.v2SelectedItems.bank,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCandidates: cb => dispatch(bankThunks.fetchCandidates(cb)),
  };
}

const ConnectedBankManagementContainer = connect(mapStateToProps, mapDispatchToProps)(BankManagementContainer);

const currentDateString = moment.utc().toISOString();
const candidateIsApprovedAndNotRemoved = candidate => candidate.profileComplete
  && candidate.isCompliant
  && (!candidate.removedAt || candidate.removedAt > currentDateString);

export const BankApprovedContainer = (props) => {
  return <ConnectedBankManagementContainer {...props} subsetFilter={candidateIsApprovedAndNotRemoved} subsetLabel="approved" selectedTabKey="approved" />;
};
export const BankRemovedContainer = (props) => {
  const candidateHasBeenRemoved = candidate => !!candidate.removedAt && candidate.removedAt < currentDateString;
  return <ConnectedBankManagementContainer {...props} subsetFilter={candidateHasBeenRemoved} subsetLabel="removed" selectedTabKey="removed" />;
};

export const BankAllContainer = (props) => {
  return <ConnectedBankManagementContainer {...props} subsetLabel="all" subsetFilter={() => true} selectedTabKey="all" />;
};

export const BankNonCompliantContainer = (props) => {
  const candidateIsNonCompliant = candidate => candidate.profileComplete
  && !candidate.isCompliant
  && !candidate.underReview
  && (!candidate.removedAt || candidate.removedAt > currentDateString);

  return <ConnectedBankManagementContainer {...props} subsetLabel="non-compliant" subsetFilter={candidateIsNonCompliant} selectedTabKey="non-compliant" />;
};

export const BankForReviewContainer = (props) => {
  return <ConnectedBankManagementContainer {...props} subsetLabel="for-review" subsetFilter={candidateNeedsReview} selectedTabKey="for-review" />;
};

export const BankNewStaffContainer = (props) => {
  return <ConnectedBankManagementContainer {...props} subsetLabel="new" subsetFilter={candidateIsNew} selectedTabKey="new" />;
};
