import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import spacing from 'config/spacing';
import colors from 'config/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  rightAlignContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
export default function InlineSwitch(props) {
  return (
    <div style={styles.container}>
      <p>{props.title}</p>
      <Switch
        onChange={props.onClick}
        checked={props.on}
        onColor={colors.cavalry.active}
        uncheckedIcon={false}
        checkedIcon={false}
      />
    </div>
  );
}

export const RightAlignSwitch = (props) => {
  return (
    <div style={styles.rightAlignContainer}>
      <div style={{ width: '30%' }}>
        <InlineSwitch {...props} />
      </div>
    </div>
  );
};

const { func, string, bool } = PropTypes;
InlineSwitch.propTypes = {
  onClick: func,
  title: string.isRequired,
  on: bool.isRequired,
};

InlineSwitch.defaultProps = {
  onClick: () => console.log('TOGGLE'),
};
