import React from 'react';
import propTypes from 'prop-types';

import colors from 'config/colors';

const styles = {
  label: {
    fontSize: '0.9rem',
    fontWeight: 600,
  },
};

const ApplicantsStatusPill = (props) => {
  if (props.agency) return <div style={{ color: colors.red, ...styles.label, ...props.style }}>{props.bank ?? 'Agency'}</div>;
  if (props.external) return <div style={{ color: colors.external, ...styles.label, ...props.style }}>{props.bank ?? 'External Bank'}</div>;
  return <div style={{ color: colors.text, ...styles.label, ...props.style }}>{props.bank ?? 'Bank'}</div>;
};

const { objectOf, bool, oneOfType, number, string } = propTypes;
ApplicantsStatusPill.propTypes = {
  style: objectOf(oneOfType([number, string])),
  agency: bool,
  external: bool,
  bank: string,
};

ApplicantsStatusPill.defaultProps = {
  agency: false,
  style: null,
  external: false,
  bank: null,
};


export default ApplicantsStatusPill;
