import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import colors from 'config/colors';

import { formatHourlyRate } from '../helpers';
import FieldLabel from './FieldLabel';

interface HourlyRateProps {
  onChange: (val: string) => void,
  customHourlyRate: string,
  label: string,
  placeholder: string,
  subLabel: string,
  disabled: boolean;
  mandatory: boolean;
  customHourlyRateValidation: { success: boolean, message: string | null },
  estimatedCost: string | null,
}

function HourlyRate(props: HourlyRateProps) {

  const [inputValue, setInputValue] = useState(formatHourlyRate(props.customHourlyRate));
  const [inputIsFocussed, setInputIsFocussed] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const validatedInput = formatHourlyRate(props.customHourlyRate);
    setInputValue(validatedInput);
  }, [props.customHourlyRate]);

  const onClickCurrencyBox = () => {
    if (inputRef?.current) {

      // Focus input when currency box is clicked
      inputRef.current.focus();
      // Change to type type in order to set the cursor at the beginning of the input (not possible with number type)
      inputRef.current.type = 'text';
      inputRef.current.setSelectionRange(0, 0);
      // Change back to number type
      inputRef.current.type = 'number';
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setInputIsFocussed(false);
    props.onChange(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      setInputIsFocussed(false);
      event.preventDefault();
      const target = event.target as Element;
      if (target instanceof HTMLElement) {
        target.blur();
        props.onChange(inputValue);
      }
    }
  };

  const { customHourlyRateValidation, estimatedCost } = props;

  return (
    <>
      <FieldLabel subLabel={props.subLabel} label={props.label}>
        <div style={{ display: 'flex', height: 30, minHeight: 30 }}>
          <div
            role="button"
            tabIndex={0}
            onClick={onClickCurrencyBox}
            className={classNames({
              'hourly-rate-currency-box': true,
              'hourly-rate-currency-box--focussed': inputIsFocussed,
              'hourly-rate-currency-box--disabled': props.disabled,
              'hourly-rate-currency-box--missing': props.mandatory && !props.customHourlyRate,
            })}
          >
            <p>£</p>
          </div>
          <input
            ref={inputRef}
            type="number"
            onFocus={() => { if (!inputIsFocussed) setInputIsFocussed(true); }}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onChange={(event) => setInputValue(event?.target.value)}
            name="customHourlyRate"
            className={classNames({
              'hourly-rate-input': true,
              'hourly-rate-input--disabled': props.disabled,
              'hourly-rate-input--missing': props.mandatory && !props.customHourlyRate,
            })}
            placeholder={props.placeholder}
            value={inputValue}
            disabled={props.disabled}
          />
        </div>
      </FieldLabel>
      {!customHourlyRateValidation.success && customHourlyRateValidation.message && (
        <p style={{ paddingLeft: '90px', color: colors.cavalry.error, fontSize: '0.9rem', fontWeight: 500, lineHeight: 1.3 }}>
          {customHourlyRateValidation.message}
        </p>
      )}
      {estimatedCost && customHourlyRateValidation.success && (
        <p style={{ paddingLeft: '90px', color: '#999', fontSize: '0.9rem', fontWeight: 500, lineHeight: 1.3 }}>
          Estimated Cost: {estimatedCost}
        </p>
      )}
    </>
  );
}

export default React.memo(HourlyRate);
