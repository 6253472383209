import React, { Component } from 'react';
import PropTypes from 'prop-types';
import spacing from '../../config/spacing';
import { requiredField } from '../../lib/form-input-validators';
import TextSectionHeader from '../../components/TextSectionHeader';
import FormInput from '../../components/FormInput';

const styles = {
  formContainer: {
    overflowY: 'scroll',
    boxSizing: 'border-box',
    maxHeight: 450,
    width: '90%',
    margin: '0 auto',
  },
  pane: {
    display: 'inline-block',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    paddingLeft: spacing.tiny,
    paddingRight: spacing.tiny,
  },
  paneLeft: {
    width: '50%',
  },
  paneRight: {
    width: '50%',
  },
};

const { bool, object, func } = PropTypes;

export default class SupplierBasicInformationForm extends Component {

  static propTypes = {
    initialValues: object.isRequired,
    onSubmit: func.isRequired,
    triggerSubmit: bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        ...props.initialValues,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.triggerSubmit) {
      this.props.onSubmit(this.state.formValues);
    }
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({
      ...this.state,
      formValues: {
        ...this.state.formValues,
        [target.name]: value,
      },
    });
  }

  render() {
    const { formValues } = this.state;
    return (
      <div style={styles.formContainer}>
        <TextSectionHeader text="Edit Basic Information" uppercase />
        <form onSubmit={this.props.onSubmit}>
          <div style={{ ...styles.pane, ...styles.paneLeft }}>
            <FormInput
              label="Company Name"
              name="companyName"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.companyName}
            />
            <FormInput
              label="Address 1"
              name="address1"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.address1}
            />
            <FormInput
              label="Address 2"
              name="address2"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.address2}
            />
            <FormInput
              label="City"
              name="city"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.city}
            />
            <FormInput
              label="County"
              name="county"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.county}
            />
            <FormInput
              label="Postcode"
              name="postcode"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.postcode}
            />
          </div>
          <div style={{ ...styles.pane, ...styles.paneRight }}>
            <FormInput
              label="Phone"
              name="mainTelephone"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.mainTelephone}
            />
            <FormInput
              label="Tier"
              name="tier"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.tier}
            />
            <FormInput
              label="Company Number"
              name="companyNumber"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.companyNumber}
            />
            <FormInput
              label="VAT Number"
              name="vatNumber"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.vatNumber}
            />
            <FormInput
              label="Logo URL"
              name="logoUrl"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.logoUrl}
            />
          </div>
        </form>
      </div>
    );
  }
}
