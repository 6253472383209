import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MdClose } from 'react-icons/md';
import { FaGhost } from 'react-icons/fa';

import Button from 'components/Button';

import colors from 'config/colors';
import * as notificationActions from '../../reducers/notifications';
import * as notificationThunks from '../../thunks/notifications';
import Loading from '../Loading';
import ScrollView from '../ScrollView';
import Toggle from '../Toggle';
import Notification from '../Notification';
import './styles.scss';

const { bool, func, arrayOf, object, number, shape } = PropTypes;
class NotificationCentre extends Component {
  static propTypes = {
    isOpen: bool.isRequired,
    closeNotificationCentre: func.isRequired,
    notifications: arrayOf(object),
    toggleNotificationSpecificity: func.isRequired,
    notificationSpecificity: number.isRequired,
    stopWatchingNotifications: func.isRequired,
    watchNotifications: func.isRequired,
    removeNotification: func.isRequired,
    markNotificationAsRead: func.isRequired,
    loading: bool.isRequired,
    pageHeight: number.isRequired,
    removeAllNotifications: func.isRequired,
    history: shape({
      push: func.isRequired,
      replace: func.isRequired,
      createHref: func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    notifications: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      limit: 20,
      removedNotificationIds: [],
    };
  }

  async componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside);
    await this.props.watchNotifications(this.state.limit);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      // Reset removed notifications on close
      this.setState({ removedNotificationIds: [] }); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onClickNotification = async (url, id) => {
    this.props.closeNotificationCentre();
    setTimeout(() => this.props.history.push(url), 300);
    // When user clicks notification, do not remove if in 'All' tab
    if (this.props.notificationSpecificity === 1) await this.props.markNotificationAsRead(id);
  }

  setNotificationCentreRef = (node) => {
    this.notificationCentreRef = node;
  }

  markNotificationAsRead = async (id) => {
    await this.props.markNotificationAsRead(id);
  }

  removeNotification = async (id) => {
    // For delete animation
    const removedNotificationIds = [];
    removedNotificationIds.push(id);
    this.setState({ removedNotificationIds });

    // Only remove when animation has finished running
    setTimeout(async () => {
      await this.props.removeNotification(id);
    }, 300);
  }

  removeAllNotifications = async () => {
    if (this.props.notifications && this.props.notifications.length > 0) {
      const removedNotificationIds = this.props.notifications.map(notif => notif.id);
      this.setState({ removedNotificationIds });

      setTimeout(async () => {
        await this.props.removeAllNotifications(removedNotificationIds);
      }, 300);
    }
  }

  handleClickOutside = (event) => {
    if (this.notificationCentreRef && !this.notificationCentreRef.contains(event.target)) {
      this.props.closeNotificationCentre();
    }
  }

  changeNotificationSpecificity = async () => {
    this.props.toggleNotificationSpecificity();
    await this.props.stopWatchingNotifications();
    await this.props.watchNotifications(this.state.limit);
  }

  loadMoreNotifications = async () => {
    if (this.props.notifications && this.props.notifications.length >= this.state.limit) {
      const newLimit = this.state.limit + 10;
      if (newLimit > 100 && this.props.notificationSpecificity === 2) return; // Do not fetch any more notifications once limit passes threshold. On 'All' tab only.
      await this.props.stopWatchingNotifications(true);
      await this.props.watchNotifications(newLimit);
      this.setState({ limit: newLimit });
    }
  }

  noNotifcationsText = () => {
    if (this.props.notificationSpecificity === 1) {
      return (
        <div
          className="notificationTextContainer"
        >
          <FaGhost style={{ marginBottom: 10 }} size={55} />
          <p style={{ textAlign: 'center' }}>
            There are no notifications assigned to you
          </p>
          <p style={{ textAlign: 'center' }}>
            - toggle the button above to view all
          </p>
          <p style={{ textAlign: 'center' }}>
            notifications for your organisation
          </p>
        </div>);
    }
    return (
      <div
        className="notificationTextContainer"
      >
        <FaGhost style={{ marginBottom: 10 }} size={55} />
        <p style={{ textAlign: 'center' }}>There are no notifications for your organisation</p>
      </div>
    );
  }

  render() {
    return (
      <div
        className={`notificationCentreContainer ${this.props.isOpen ? 'notificationCentreContainerOpen' : 'notificationCentreClosed'}`}
        ref={this.setNotificationCentreRef}
      >
        <div style={{ backgroundColor: colors.cavalry.backgroundLight6 }}>
          <div className={`notificationCentreTitleContainer ${this.props.isOpen ? null : 'notificationCentreNotificationsContainerClosed'}`}>
            <p style={{ fontSize: '1.5rem', color: colors.text, fontWeight: 600 }}>Notifications</p>
            <div
              onClick={this.props.closeNotificationCentre}
              className="notificationCentreCloseButton"
            >
              {' '}
              <MdClose size={24} />
            </div>
          </div>
            <Toggle
              option1="For Me"
              option2="All"
              toggle={this.changeNotificationSpecificity}
              selectedOption={this.props.notificationSpecificity}
            />
        </div>
        <div className={`notificationCentreNotificationsContainer ${this.props.isOpen ? null : 'notificationCentreNotificationsContainerClosed'}`}>
          <div className="notificationCentreNotificationsTopContainer">
            {this.props.notificationSpecificity === 1 && this.props.notifications && this.props.notifications.length > 0 ?
              <div style={{ alignSelf: 'flex-end', paddingRight: 6 }}>
                <Button onClick={this.removeAllNotifications} black shadow={false}>Dismiss all Notifications</Button>
              </div>
              :
              <div
                className="emptyNotificationCentreClearAll"
              />
            }
          </div>
          {this.props.loading && (!this.props.notifications || (this.props.notifications && this.props.notifications.length < 1)) ?
            <div className="notificationCentreCentralLoading">
              <Loading
                size={40}
              />
            </div>
            :
            <ScrollView
              style={{
                minHeight: this.props.pageHeight - 200,
                maxHeight: this.props.pageHeight - 200,
              }}
              loadMore={this.loadMoreNotifications}
              loading={this.props.loading}
            >
              {this.props.notifications && this.props.notifications.length > 0 ?
                <ul style={{ listStyle: 'none' }}>
                  {this.props.notifications.map((notification) => {
                    return (
                      <li key={notification.id}>
                        <Notification
                          data={notification}
                          onClick={this.onClickNotification}
                          removeNotification={this.removeNotification}
                          removed={this.state.removedNotificationIds.includes(notification.id)}
                          notificationSpecificity={this.props.notificationSpecificity}
                        />
                      </li>
                    );
                  })}
                </ul>
                :
                this.noNotifcationsText()
              }
            </ScrollView>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps({ screen, notifications }) {
  return {
    pageHeight: screen.height,
    notificationSpecificity: notifications.notificationSpecificity,
    notifications: notifications.notifications,
    loading: notifications.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleNotificationSpecificity: () => dispatch(notificationActions.toggleNotificationSpecificity()),
    stopWatchingNotifications: changingLimit => dispatch(notificationThunks.stopWatchingNotifications(changingLimit)),
    watchNotifications: limit => dispatch(notificationThunks.watchNotifications(limit)),
    markNotificationAsRead: id => dispatch(notificationThunks.markNotificationAsRead(id)),
    removeNotification: id => dispatch(notificationThunks.removeNotification(id)),
    removeAllNotifications: ids => dispatch(notificationThunks.removeAllNotifications(ids)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationCentre));
