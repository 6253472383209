import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseConfig, FIREBASE_AUTH_EMULATOR_HOST } from '../config/settings';

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

if (FIREBASE_AUTH_EMULATOR_HOST) {
  firebase.auth().useEmulator(`http://${FIREBASE_AUTH_EMULATOR_HOST}/`);
}

let reportError = (error, opts) => console.error(error, opts);
export const setErrorHandler = (errorHandler) => {
  reportError = errorHandler;
};

export function auth() {
  return firebase.auth();
}

let connected = null;
export function connect() {
  if (!connected) {
    connected = new Promise((resolve) => {
      console.debug('Authenticating with firebase.');
      const start = Date.now();
      auth().onAuthStateChanged(
        (authState) => {
          console.debug(`Authenticated (${Date.now() - start}ms)`, authState);
          resolve();
        },
        (err) => {
          console.error('Firebase auth failed', err);
          reportError(new Error('Firebase auth failed'), {
            metaData: {
              error: {
                code: err.code,
                message: err.message,
              },
            },
          });
        },
      );
    });
  }

  return connected;
}

export async function getToken() {
  await connect();
  if (auth().currentUser) {
    try {
      return await auth().currentUser.getIdToken();
    } catch (e) {
      reportError(e, {
        metaData: {
          context: {
            message: 'getToken failed',
          },
        },
      });
      console.warn('firebase.getToken failed', e);
    }
  }
  return null;
}
