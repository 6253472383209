import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleRight } from 'react-icons/fa';

import Loading from '../Loading';
import './SplitView.scss';

const { arrayOf, shape, string, oneOfType, node, func, bool } = PropTypes;

const SplitView = (props) => {

  if (props.loading) {
    return (
      <div className="splitViewLoading">
        {props.loadingMessage || <Loading flex />}
      </div>
    );
  }

  return (
    <div className="splitViewWrapper">
      <div className="splitViewItems">
        {props.items && props.items.length ?
          props.items.map(item => (
            <div
              key={item.key}
              className={`splitViewItem rollover ${item.key === props.selectedKey ? 'selected' : ''}`}
              onClick={() => props.onItemClick(item.key)}
            >
              <div className="splitViewItemHeader">{item.header}</div>
              {item.description ? <div className="splitViewItemDesc">{item.description}</div> : null}
              <div className="splitViewItemIcon"><FaAngleRight className="navigateIcon" /></div>
            </div>
          )) :
          <div className="splitViewNoItems">
            {props.noItemsMessage || <div>No items to display.</div>}
          </div>
        }
      </div>
      <div className="splitViewContent">
        {props.children || '\u00a0'}
      </div>
    </div>
  );
};

SplitView.propTypes = {
  items: arrayOf(shape({
    key: string.isRequired,
    header: oneOfType([string, node]).isRequired,
    description: oneOfType([string, node]),
  })),
  loading: bool,
  onItemClick: func,
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
  noItemsMessage: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  loadingMessage: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  selectedKey: string,
};

SplitView.defaultProps = {
  items: [],
  children: null,
  loading: true,
  onItemClick: () => {},
  noItemsMessage: null,
  loadingMessage: null,
  selectedKey: null,
};

export default SplitView;
