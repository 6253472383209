import React from 'react';
import propTypes from 'prop-types';
import { MdStar, MdStarBorder } from 'react-icons/md';
import './RatingStars.scss';

const RatingStars = (props) => {

  const renderStar = number => (
    props.rating >= number ? <MdStar size={40} className="rating__star--filled" /> : <MdStarBorder size={40} className="rating__star--empty" />
  );

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= props.numberOfStars; i += 1) {
      stars.push(
        <div
          className="rating-star"
          role="button"
          tabIndex="0"
          onMouseOver={() => {
            if (!props.disabled) props.onMouseOver(i);
          }}
          onMouseOut={() => {
            if (!props.disabled) props.onMouseOut();
          }}
          onClick={() => {
            if (!props.disabled) props.onClick(i);
          }}
          onFocus={() => undefined}
          onBlur={() => undefined}
          style={{ cursor: props.disabled ? 'default' : 'pointer' }}
        >
          {renderStar(i)}
        </div>,
      );
    }
    return stars;
  };

  return (
    <div className="rating__stars">
      {renderStars()}
    </div>
  );
};

const { bool, func, number } = propTypes;
RatingStars.propTypes = {
  numberOfStars: number,
  disabled: bool,
  onMouseOver: func,
  onMouseOut: func,
  onClick: func,
};

RatingStars.defaultProps = {
  numberOfStars: 5,
  disabled: false,
  onMouseOver: () => {},
  onMouseOut: () => {},
  onClick: () => {},
};

export default RatingStars;
