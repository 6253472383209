import React from 'react';
import propTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from 'hooks/feature';
import Loading from 'components/Loading';
import ScrollToTop from './ScrollToTop';
import ProtectedRoute from './ProtectedRoute';

import LoginContainer from '../Auth/LoginContainer';
import RegistrationContainer from '../Auth/RegistrationContainer';
import PasswordResetContainer from '../Auth/PasswordResetContainer';

import DashboardContainer from '../Dashboard/DashboardContainer';

import BankRoutes from '../BankManagement/BankRoutes';
import ShiftRoutes from '../Jobs/ShiftRoutes';
import TimesheetRoutes from '../Timesheets/TimesheetRoutes';
import MessagingRoutes from '../Messaging/MessagingRoutes';
import SettingsRoutes from '../Settings/SettingsRoutes';

import ActivityDrilldown from '../Reporting/Reports/ActivityDrilldown';
import ReportingDashboardContainer from '../Reporting/Dashboard/DashboardContainer';
import DrillDownContainer from '../Reporting/DrillDown/DrillDownContainer';
import SpecialitiesContainer from '../Reporting/Specialities/SpecialitiesContainer';
import ExportsContainer from '../Reporting/Exports/ExportsContainer';
import HistoricalGradesReport from '../Reporting/Reports/HistoricalGradesReport';

import HelpContainer from '../Help/HelpContainer';
import HelpDetailsContainer from '../Help/HelpDetailsContainer';
import NotFound from './NotFound';

const Routes = ({ loggedIn }) => {

  // We must fetch feature flags before short-circuit condition, because hooks should not be
  // run conditionally. See: https://reactjs.org/docs/hooks-rules.html
  const FEATS = {
    timesheetsAdmin: useFeatureFlag('timesheetsAdmin') || null,
    reportingActivity: useFeatureFlag('reportingActivity') || null,
    reportingExports: useFeatureFlag('reportingExports') || null,
    reportingSpend: useFeatureFlag('reportingSpend') || null,
    helpCentre: useFeatureFlag('helpCentre') || null,
    messaging: useFeatureFlag('messaging') || null,
  };

  // If logged in:
  // Ensure that the necessary state for determining feature flags has loaded before rendering UI.
  // Otherwise Routes initially renders without feature flagged routes, which causes the URL to
  // redirected by the NotFound before they are enabled.
  const userId = useSelector(state => state.user?.userId);
  const adminGroups = useSelector(state => state.user?.adminGroups);
  const features = useSelector(state => state.global?.orgConfig?.features);
  const currentOrg = useSelector(state => state.global?.currentOrgKey && state.global?.employerAuthorisedOrgs?.[state.global?.currentOrgKey]);
  if (loggedIn && (!userId || !adminGroups || !features || !currentOrg)) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
        <Loading />
      </div>
    );
  }

  return (
    <ScrollToTop loggedIn={loggedIn}>
      <Switch>
        {/* Home/Portal Route */}
        <ProtectedRoute exact path="/" component={DashboardContainer} />
        <Route path="/test-error-reporting" render={() => { throw new Error('Test Exception'); }} />
        <Redirect from="/portal" to="/" />
        <Route path="/manage/bank" render={({ location }) => <Redirect to={{ ...location, pathname: location.pathname.replace('manage/bank', 'bank') }} />} />

        {/* Auth/Login Routes */}
        <Route path="/login" render={props => <LoginContainer {...props} />} />
        <Route path="/password-reset" render={props => <PasswordResetContainer {...props} />} />
        <Route path="/register-with-invite/:inviteCode" render={props => <RegistrationContainer {...props} />} />

        {/* Shifts Routes */ }
        <ProtectedRoute path="/shifts" component={ShiftRoutes} />

        {/* Timesheets Routes */}
        {/* <Route path="/timesheets" render={({ location }) => <Redirect to={{ ...location, pathname: location.pathname.replace('/timesheets', '/pay/timesheets') }} />} />; */}
        {FEATS.timesheetsAdmin && <ProtectedRoute path="/pay/timesheets/:timesheetStatus(pending|pending-submission|rejected|approved)/:timesheetKey(ts=.*)" render={({ location }) => <Redirect to={{ ...location, pathname: location.pathname.replace('ts=', '') }} />} />}
        {FEATS.timesheetsAdmin && <ProtectedRoute path="/time-and-pay" component={TimesheetRoutes} />}

        {FEATS.messaging && <ProtectedRoute path="/messaging" component={MessagingRoutes} />}

        {/* Bank Routes */}
        <ProtectedRoute path="/bank" component={BankRoutes} />

        {/* Reporting Routes */}
        <ProtectedRoute path="/reporting/dashboard" component={ReportingDashboardContainer} />
        <ProtectedRoute path="/reporting/:reportName/specialities" component={SpecialitiesContainer} />
        <ProtectedRoute path="/reporting/fill" component={DrillDownContainer} />
        <ProtectedRoute path="/reporting/requests" component={DrillDownContainer} />
        {FEATS.reportingActivity && <ProtectedRoute path="/reporting/activity" component={ActivityDrilldown} />}
        {FEATS.reportingSpend && <ProtectedRoute path="/reporting/spend" component={DrillDownContainer} />}
        {FEATS.reportingExports && <ProtectedRoute path="/reporting/exports" component={ExportsContainer} />}
        <ProtectedRoute path="/reporting/skills-and-qualifications" component={HistoricalGradesReport} />

        {/* Settings Routes */}
        <ProtectedRoute path="/settings" component={SettingsRoutes} />

        {/* Help Centre Routes */}
        {FEATS.helpCentre && <ProtectedRoute path="/help/:helpKey" component={HelpDetailsContainer} />}
        {FEATS.helpCentre && <ProtectedRoute path="/help" component={HelpContainer} />}

        {/* Not Found Route */}
        <Route path="*" component={NotFound} />
      </Switch>
    </ScrollToTop>
  );
};

const { bool } = propTypes;
Routes.propTypes = {
  loggedIn: bool.isRequired,
};

export default Routes;
