import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';

import * as api from 'lib/api';
import { reportError } from 'lib/error-reporting';
import regex from 'lib/regex';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ErrorModal from 'components/ErrorModal';
import DropDown from 'components/DropDown';
import { CWFilterSelect } from 'components/CWSelect';


const inviteCodeModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    border: 'none',
  },
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const styles = {
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    marginTop: spacing.base,
    width: '100%',
    textAlign: 'center',
  },
  input: {
    flex: '1 1 0px',
    fontSize: '0.9rem',
    color: colors.text,
    border: `1px solid ${colors.cavalry.line}`,
    borderRadius: 2,
    padding: '6px',
    outline: 'none',
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  textLeft: {
    alignItems: 'center',
    width: '30%',
    lineHeight: 1.2,
    marginRight: spacing.small,
    color: '#999',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  right: {
    flex: '1 1 0px',
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 12,
    borderTop: '1px solid #EAEAEA',
  },
  inviteCode: {
    fontSize: 26,
    letterSpacing: 8,
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    padding: '24px 48px',
  },
};

const { func, bool, arrayOf, string, shape, objectOf, oneOfType, number, array } = PropTypes;
class InviteModal extends Component {
  static propTypes = {
    onClose: func.isRequired,
    customFieldsMetadata: arrayOf(shape({
      fieldKey: string.isRequired,
      entity: string.isRequired,
      fieldName: string.isRequired,
      description: string.isRequired,
      type: string.isRequired,
      parameters: objectOf(
        oneOfType([
          number, string, bool, arrayOf(
            shape({ name: string, value: string }),
          ),
        ]),
      ),
      adminCanEdit: bool.isRequired,
      candidateCanEdit: bool.isRequired,
      requiredAtInvite: bool.isRequired,
    })),
  }

  static defaultProps = {
    customFieldsMetadata: null,
  }

  constructor(props) {
    super(props);
    const metadata = this.resetMetadataFields();
    // If only 1 bank, then select as default, otherwise force user to pick banks
    const canInviteStaffBanks = props.adminBanks.filter(orgBank => orgBank.canInviteStaff);
    const defaultBanks = canInviteStaffBanks.length === 1 ? [canInviteStaffBanks[0].bankKey] : [];

    this.state = {
      isSendingInvite: false,
      emailAddress: '',
      mobilePhoneNumber: '',
      bankKeys: defaultBanks,
      firstName: '',
      surname: '',
      metadata,
      sendInviteError: null,
    };
  }

  sendInvite = async () => {
    this.setState({ isSendingInvite: true });

    const payload = {
      mobile: this.state.mobilePhoneNumber,
      email: this.state.emailAddress,
      firstName: this.state.firstName,
      surname: this.state.surname,
      relationshipData: this.state.metadata,
      bankKeys: this.state.bankKeys,
    };
    const response = await api.post('bank/send-bank-invite', payload);

    if (response.body?.success) {
      this.setState({ isSendingInvite: false, inviteCode: response.body.newInviteCode });
    } else {
      const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Error sending bank invite';
      reportError(new Error(error), {
        api: {
          path: response.path,
          status: response.status,
          error,
          details: response.details,
          stackTrace: response.stackTrace,
        },
        payload,
      });
      this.setState({ isSendingInvite: false, sendInviteError: 'Oops! Something went wrong. Please try again. If the problem persists, please contact customer support' });
    }
  }

  submit = () => {
    this.sendInvite();
    this.resetState();
  }

  resetMetadataFields = () => {

    const metadata = {};
    (this.props.customFieldsMetadata || [])
      .filter(field => field.entity === 'candidate' && field.requiredAtInvite)
      .forEach(field => {
        metadata[field.fieldKey] = null;
      });

    return metadata;
  }

  resetState = () => {
    const metadata = this.resetMetadataFields();
    this.setState({
      mobilePhoneNumber: '',
      metadata,
    });
  }

  render() {

    // Invite Code Modal (shown on successful invite send)
    if (this.state.inviteCode) {
      return (
        <Modal
          isOpen
          onRequestClose={this.props.onClose}
          contentLabel="Invite Code"
          header="Invite Sent"
          style={inviteCodeModalStyles}
        >
          <div style={{ padding: 12 }}>
            <p style={{ color: '#666' }}>Invite code:</p>
            <div style={styles.inviteCode}>{this.state.inviteCode}</div>
          </div>
        </Modal>
      );
    }

    const customFieldsMetadata = (this.props.customFieldsMetadata ?? []).filter(field => field.entity === 'candidate' && field.requiredAtInvite);

    const phoneNumberIsInvalid = !!(this.state.mobilePhoneNumber && !regex.checkNumber(this.state.mobilePhoneNumber));
    const emailAddressIsInvalid = !!(this.state.emailAddress && !regex.checkEmail(this.state.emailAddress));
    const hasSelectedAtLeastOneBank = !!this.state.bankKeys.length;

    const formIsValid = (this.state.mobilePhoneNumber || this.state.emailAddress)
      && !phoneNumberIsInvalid
      && !emailAddressIsInvalid
      && hasSelectedAtLeastOneBank
      && customFieldsMetadata?.every(field => this.state.metadata[field.fieldKey]);

    const bankOptions = this.props.adminBanks
      .filter(orgBank => orgBank.canInviteStaff)
      .map(bank => ({ label: bank.name, value: bank.bankKey }));

    // Main Invite Modal (form to send invite)
    return (
      <Modal
        isOpen
        vflex
        onRequestClose={this.props.onClose}
        contentLabel="Invite"
        contentStyle={{ width: 500 }}
        header="Invite a Candidate"
      >
        {!!this.state.sendInviteError && (
          <ErrorModal
            header="Error Inviting Candidate"
            errorMessage={this.state.sendInviteError}
            onCancel={() => this.setState({ sendInviteError: null })}
          />
        )}
        {this.state.isSendingInvite ?
          <div style={{ padding: 24 }}>
            <Loading flex />
          </div>
          :
          <>

            {/* Email Address / Mobile Number / Banks */}
            <div style={{ padding: 24 }} className="space-children-6--bottom">
              <p style={{ color: colors.text, lineHeight: 1.35, marginBottom: 12 }}>You must provide either a mobile number or an email address (or both).</p>

              {/* Mobile  Number */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={styles.inline}>
                  <div style={styles.textLeft}>Mobile Number</div>
                  <input
                    style={{ ...styles.input, border: phoneNumberIsInvalid ? `1px solid ${colors.cavalry.error}` : `1px solid ${colors.cavalry.line}` }}
                    value={this.state.mobilePhoneNumber}
                    onChange={event => this.setState({ mobilePhoneNumber: event.target.value })}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: spacing.small, padding: '6px 0px' }}>
                  <div style={{ width: '30%', marginRight: spacing.small }} />
                  <div>
                    {phoneNumberIsInvalid && <p style={{ lineHeight: 1.2, color: colors.cavalry.error, fontSize: '0.85rem', fontWeight: 500 }}>Please enter a valid UK phone number</p>}
                  </div>
                </div>
              </div>

              {/* Email Address */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={styles.inline}>
                  <div style={styles.textLeft}>Email Address</div>
                  <input
                    style={styles.input}
                    value={this.state.emailAddress}
                    onChange={event => this.setState({ emailAddress: event.target.value })}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: spacing.small, padding: '6px 0px' }}>
                  <div style={{ width: '30%', marginRight: spacing.small }} />
                  <div>
                    {emailAddressIsInvalid && <p style={{ lineHeight: 1.2, color: colors.cavalry.error, fontSize: '0.85rem', fontWeight: 500 }}>Please enter a valid email address</p>}
                  </div>
                </div>
              </div>

              {/* Banks */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={styles.inline}>
                  <div style={styles.textLeft}>Banks</div>
                  <div style={{ flex: '1 1 0px' }}>
                    {/* <DropDown */}
                    {/*   // borderColor={props.fieldIsValid ? colors.positive : colors.cavalry.error} */}
                    {/*   isMulti */}
                    {/*   isClearable={false} */}
                    {/*   options={this.props.adminBanks} */}
                    {/*   getOptionLabel={bank => bank.name} */}
                    {/*   getOptionValue={bank => bank.bankKey} */}
                    {/*   value={this.state.banks} */}
                    {/*   customStyles={{ */}
                    {/*     menuPortal: { zIndex: 1000000 }, */}
                    {/*     option: { height: null }, */}
                    {/*     multiValueLabel: { fontSize: 10, padding: 0, paddingLeft: 6 }, */}
                    {/*   }} */}
                    {/*   height={60} */}
                    {/*   menuPortalTarget={document.body} */}
                    {/*   menuPosition="fixed" */}
                    {/*   portalPlacement="bottom" */}
                    {/*   onChange={newSelectedBanks => this.setState({ banks: newSelectedBanks })} */}
                    {/* /> */}
                    <CWFilterSelect
                      items={bankOptions}
                      selectedItems={this.state.bankKeys}
                      setSelectedItems={newSelectedBanks => this.setState({ bankKeys: newSelectedBanks })}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', paddingTop: spacing.small, paddingBottom: spacing.small, flexDirection: 'row', alignItems: 'center', height: spacing.small }}>
                  <div style={{ width: '30%', marginRight: spacing.small }} />
                  <div>
                    {!hasSelectedAtLeastOneBank && <p style={{ lineHeight: 1.2, color: colors.cavalry.error, fontSize: '0.9rem', fontWeight: 500 }}>Please select at least one bank</p>}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ padding: 24 }}>
              <p style={{ color: colors.black, fontSize: '0.9rem', marginBottom: 12 }}>Fields marked with<span style={{ color: colors.cavalry.error }}>*</span>{' '}are required</p>

              {/* First name / surname */}
              <div style={styles.inline}>
                <div style={styles.textLeft}>
                  <p>First name</p>
                </div>
                <input
                  style={styles.input}
                  value={this.state.firstName}
                  onChange={event => this.setState({ firstName: event.target.value })}
                />
              </div>
              <div style={{ height: spacing.small, paddingTop: spacing.small, paddingBottom: spacing.small }} />

              <div style={styles.inline}>
                <div style={styles.textLeft}>
                  <p>Surname</p>
                </div>
                <input
                  style={styles.input}
                  value={this.state.surname}
                  onChange={event => this.setState({ surname: event.target.value })}
                />
              </div>
              <div style={{ height: spacing.small, paddingTop: spacing.small, paddingBottom: spacing.small }} />

              {/* Custom fields */}
              {customFieldsMetadata.map((field) => {
                return (
                  <>
                    <div style={styles.inline} key={field.fieldKey}>
                      <div style={styles.textLeft}>
                        <p>
                          {field.fieldName}
                          {' '}
                          {field.requiredAtInvite && <span style={{ color: colors.cavalry.error }}>*</span>}
                        </p>
                      </div>
                      <CustomField
                        fieldIsValid={!!this.state.metadata[field.fieldKey]}
                        field={field}
                        onChange={value => this.setState(prevState => ({ metadata: { ...prevState.metadata, [field.fieldKey]: value } }))}
                        value={this.state.metadata[field.fieldKey]}
                      />
                    </div>
                    <div style={{ display: 'flex', paddingTop: spacing.small, paddingBottom: spacing.small, flexDirection: 'row', alignItems: 'center', height: spacing.small }}>
                      <div style={{ width: '30%', marginRight: spacing.small }} />
                      <div>
                        {!this.state.metadata[field.fieldKey] && <p style={{ lineHeight: 1.2, color: colors.cavalry.error, fontSize: '0.9rem', fontWeight: 500 }}>Field is required</p>}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div style={styles.buttonContainer}>
              <Button
                onClick={this.submit}
                disabled={!formIsValid}
                black
                shadow={false}
              >
                Send Invite
              </Button>
            </div>
          </>
        }
      </Modal>
    );

  }
}

const CustomField = (props) => {
  const { field, onChange } = props;

  switch (field.type) {
    case 'text':
      return (
        <input
          style={{ ...styles.input, border: `1px solid ${props.fieldIsValid ? '#CCC' : colors.cavalry.error}` }}
          value={props.value}
          onChange={event => onChange(event.target.value)}
          // placeholder={`Enter ${field.fieldName}`}
          type="text"
        />
      );
    case 'single-select': {
      const options = field?.parameters?.options?.map(({ name: label, value }) => ({ value, label }));

      return (
        <div style={{ flex: '1 1 0px' }}>
          <DropDown
            borderColor={props.fieldIsValid ? colors.positive : colors.cavalry.error}
            options={options}
            customStyles={{ menuPortal: { zIndex: 1000000 }, option: { height: null } }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            portalPlacement="bottom"
            onChange={option => onChange(option.value)}
          />
        </div>
      );
    }
    default:
      return null;
  }
};

CustomField.propTypes = {
  field: shape({
    fieldName: string.isRequired,
    type: string.isRequired,
    parameters: objectOf(
      oneOfType([
        number, string, bool, arrayOf(
          shape({ name: string, value: string }),
        ),
      ]),
    ),
  }).isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, number, array]),
  fieldIsValid: bool.isRequired,
};

CustomField.defaultProps = {
  value: null,
};
//----------------------------------------------------------------
// REDUX CONNECT
//----------------------------------------------------------------

function mapStateToProps({ filter, global }) {
  return {
    filters: filter.filters,
    filterIndex: filter.filterIndex,
    filterFieldToAdd: filter.filterFieldToAdd,
    filterData: filter.filterData,
    selectedFilter: filter.selectedFilter,
    selectedFilterItem: filter.selectedFilterItem,
    customFieldsMetadata: global.customFieldsMetadata,
    adminBanks: global.adminBanks,
  };
}

export default connect(mapStateToProps)(InviteModal);
