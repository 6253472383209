import * as ratingsActions from '../reducers/ratings';
import { fetchCandidateRatingsSummary, fetchCandidateRatingsDetail } from '../lib/api/accounts';

export function fetchCandidateRatings(candidateKey) {
  return async (dispatch, getState) => {
    dispatch(ratingsActions.fetchCandidateRatings());
    const { ratingsLimit, ratingsOffset } = getState().ratings;

    try {
      const { candidateRatingsSummary } = await fetchCandidateRatingsSummary(candidateKey);
      const { candidateRatingsDetail, ratingsCount } = await fetchCandidateRatingsDetail(candidateKey, ratingsLimit, ratingsOffset);

      dispatch(ratingsActions.fetchCandidateRatingsSuccess(candidateRatingsSummary, candidateRatingsDetail, ratingsCount));
    } catch (error) {
      dispatch(ratingsActions.fetchCandidateRatingsError(error));
    }
  };
}

export function fetchMoreCandidateRatings(candidateKey) {
  return async (dispatch, getState) => {
    dispatch(ratingsActions.fetchMoreCandidateRatings());
    const { ratingsLimit, ratingsOffset } = getState().ratings;

    try {
      const { candidateRatingsDetail } = await fetchCandidateRatingsDetail(candidateKey, ratingsLimit, ratingsOffset);

      dispatch(ratingsActions.fetchMoreCandidateRatingsSuccess(candidateRatingsDetail));
    } catch (error) {
      dispatch(ratingsActions.fetchCandidateRatingsError(error));
    }
  };
}
