import { MdClear, MdKeyboardArrowDown } from 'react-icons/md';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import './FilterItem.scss';

const filterItemCallback = (props, ref) => {

  // const buttonEl = useRef(null);
  // useImperativeHandle(ref, () => ({
  //   focus: () => {
  //     buttonEl.current.focus();
  //   },
  // }));

  // ref={buttonEl}
  return (
    <button
      ref={ref}
      type="button"
      className="filterItemContainer"
      onClick={() => props.onClick(props)}
      onKeyDown={props.onKeyDown}
    >
      {props.title}{props.showDelete ? <span style={{ marginLeft: 3 }} className="filterItemDelete" onClick={(e) => { e.stopPropagation(); props.deleteItem(props.index); }}><MdClear size={14} /></span> : null}
      <MdKeyboardArrowDown size={18} />
    </button>
  );
};

const FilterItem = forwardRef(filterItemCallback);

const { string, bool, func, number } = PropTypes;
FilterItem.propTypes = {
  title: string.isRequired,
  onClick: func,
  onKeyDown: func,
  showDelete: bool,
  deleteItem: func,
  index: number,
};

FilterItem.defaultProps = {
  showDelete: false,
  onClick: () => {},
  onKeyDown: () => {},
  deleteItem: () => {},
  index: null,
};

export default FilterItem;
