import { on } from '../lib/db';
import { addHelp } from '../reducers/help';

let loadedData;

export function startWatchingHelp() {
  return async (dispatch) => {
    if (!loadedData) {
      loadedData = new Promise((resolve) => {
        on('employerHelp', (data) => {
          dispatch(addHelp(data));
          resolve(data);
        });
      });
    }
    return loadedData;
  };
}
