import React from 'react';
import ReactTooltip from 'react-tooltip';
import propTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import './styles.scss';

const Pill = props => (
  <span
    style={props.style}
    className={`pill ${props.size ? `pill--${props.size}` : ''} ${props.type ? `pill--${props.type}` : ''} ${props.outline ? 'pill--outline' : ''}`}
    data-tip={props.message}
    data-html
    data-multiline
  >
    {props.icon ? (props.customIcon || <MdWarning className="defaultIconStyle" />) : null }
    <span>{props.label}</span>
    {props.message ? <ReactTooltip type="light" className="border" /> : null}
  </span>
);

const { string, bool, object } = propTypes;
Pill.propTypes = {
  size: string,
  type: string,
  icon: bool,
  outline: bool,
  label: string.isRequired,
  message: string,
  style: object,
};

Pill.defaultProps = {
  icon: false,
  size: '',
  type: '',
  outline: false,
  message: '',
  style: {},
};

export default Pill;
