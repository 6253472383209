import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { useFeatureFlag } from 'hooks/feature';

import * as jobsThunk from 'thunks/jobs';
import { clearFetchDaySummariesError } from 'reducers/jobs';

import { Calendar, createPeriod } from 'components/Calendar';
import ErrorScreen from 'components/ErrorScreen';
import StandardEventComponent from 'components/Calendar/StandardEventComponent';
import ShiftsPage from '../ShiftsPage';
import MonthCalendarKey from './MonthCalendarKey';
import MonthCalendarEventComponent from './MonthCalendarEventComponent';

const Moment = moment().constructor;
const { func, number, objectOf, instanceOf } = PropTypes;
const MonthCalendarContainer = (props) => {

  const dispatch = useDispatch();
  const { year, month, selectedMonth, nav } = props;

  const period = createPeriod(selectedMonth, 'month');

  // Retrieve data from redux
  const daySummaries = useSelector(state => state.jobs.daySummaries);
  const fetchDaySummariesError = useSelector(state => state.jobs.fetchDaySummariesError);
  const isFetchingDaySummaries = useSelector(state => state.jobs.isFetchingDaySummaries);
  const selectedFilterItems = useSelector(state => state.filter.v2SelectedItems.shifts);
  const adminSites = useSelector(state => state.user.sites || {});
  const isSupplier = useFeatureFlag('supplier');

  // Compute derived data
  const adminSiteKeys = useMemo(() => Object.keys(adminSites).sort(), [adminSites]);

  const getDaySummaries = () => {
    dispatch(jobsThunk.getDaySummaries({
      startDate: moment({ year, month: month - 1 }).startOf('month').format('YYYY-MM-DD'),
      endDate: moment({ year, month: month - 1 }).endOf('month').format('YYYY-MM-DD'),
    }));
  };

  // Load day summary data (API call)
  useEffect(() => {
    getDaySummaries();
  }, [year, month, selectedFilterItems, adminSiteKeys]);

  // Callbacks
  const goToDayView = useCallback(day => nav.goToDayView(day.date), [nav]);

  return (
    <ShiftsPage period={period} nav={nav}>
      {fetchDaySummariesError ?
        <ErrorScreen dismiss={() => dispatch(clearFetchDaySummariesError())} retry={getDaySummaries} />
        :
        <Calendar
          date={moment({ year, month: month - 1, day: 1 })}
          events={daySummaries}
          onSelectEvent={goToDayView}
          isFetching={isFetchingDaySummaries}
          eventComponent={isSupplier ? StandardEventComponent : MonthCalendarEventComponent}
          footer={!isSupplier ? <MonthCalendarKey /> : null}
          hoverEffect
        />
    }
    </ShiftsPage>
  );
};

MonthCalendarContainer.propTypes = {
  year: number.isRequired,
  month: number.isRequired,
  selectedMonth: instanceOf(Moment).isRequired,
  nav: objectOf(func).isRequired,
};

export default MonthCalendarContainer;
