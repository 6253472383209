import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { browserOnBlur } from '../../lib/browser-type';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import { fetchRateCardLabels } from '../../thunks/rateCards';
import { requiredField } from '../../lib/form-input-validators';
import { convertToSelectArray } from '../../lib/helpers';
import * as api from '../../lib/api';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import '../../index.scss';

const modalContentStyles = {
  width: 600,
  overflow: 'display',
  paddingTop: 0,
  padding: 0,
  backgroundColor: colors.blueVeryLight,
};

const styles = {
  title: {
    paddingTop: 20,
    fontFamily: fonts.main.family,
    fontSize: fonts.large.size,
    color: colors.blueBase,
    textAlign: 'center',
  },
  selectContainer: {
    marginBottom: spacing.xlarge,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectLabel: {
    width: '30%',
    color: colors.understated,
    marginLeft: spacing.small,
  },
  select: {
    width: '74%',
  },
  container: {
    padding: spacing.base,
    backgroundColor: colors.white,
    borderRadius: spacing.small,
    margin: spacing.base,
  },
  buttonContainer: {
    textAlign: 'center',
  },
};

function convertToSortedArray(data) {
  return Object.keys(data).map((key) => {
    return {
      key,
      ...data[key],
    };
  }).sort((a, b) => a.order - b.order);
}

const { bool, func, objectOf, object, string } = PropTypes;
class NewRateCardModal extends Component {
  static propTypes = {
    isOpen: bool.isRequired,
    onRequestClose: func.isRequired,
    rgsMetadata: objectOf(object),
    type: string.isRequired,
    org: string.isRequired,
    selectedSite: string,
    isFetchingLabels: bool.isRequired,
    labels: objectOf(object),
  }

  static defaultProps = {
    rgsMetadata: null,
    labels: {},
    selectedSite: null,
  }

  state = {
    formValues: {},
    labels: [],
  }

  async UNSAFE_componentWillMount() {
    if (!this.props.rgsMetadata) {
      // TODO: add code to fetch if not present
    }

    await this.props.dispatch(fetchRateCardLabels(this.props.type === 'site' ? this.props.selectedSite : null));
    const { roles } = this.props;
    this.setState({
      roles,
      labels: Object.keys(this.props.labels),
    });
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({
      ...this.state,
      formValues: {
        ...this.state.formValues,
        [target.name]: value,
      },
    });
  }

  handleSelect = (val, field) => {
    if (field === 'role') {
      this.setState({
        [field]: val,
        grade: null,
        speciality: null,
        grades: convertToSelectArray(this.props.rgsMetadata[val.value].grades, 'name'),
        specialities: convertToSelectArray(this.props.rgsMetadata[val.value].specialities, 'name'),
      });
    } else {
      this.setState({
        [field]: val,
      });
    }
  }

  createNewRateCard = async () => {
    const { state } = this;
    const payload = {
      roleKey: state.role.value,
      gradeKey: state.grade.value,
      specialityKey: state.speciality ? state.speciality.value : null,
      name: state.formValues.name,
      type: this.props.type,
      org: this.props.org,
      site: this.props.selectedSite,
      labels: state.labels,
    };

    state.labels.forEach((key) => {
      payload[key] = {
        label: this.props.labels[key].label,
        rate: +state.formValues[key],
      };
    });

    await api.post('rateCard/create', payload);

    this.setState({
      role: null,
      grade: null,
      speciality: null,
      formValues: {},
      labels: [],
    });
    this.props.onRequestClose();
  }

  render() {
    const { formValues } = this.state;

    if (this.props.isFetchingLabels) {
      return (
        <Modal
          isOpen={this.props.isOpen}
          onRequestClose={this.props.onRequestClose}
          contentStyle={modalContentStyles}
        >
          <Loading />
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        contentStyle={modalContentStyles}
      >
        <div>
          <h2 style={styles.title}>New Rate Card</h2>
          <div style={styles.container}>
            <FormInput
              label="Name"
              name="name"
              onChange={this.handleInputChange}
              placeholder=""
              type="text"
              validate={requiredField}
              value={formValues.name}
            />
            <div style={styles.selectContainer}>
              <div style={styles.selectLabel}>
                Role
              </div>
              <div style={styles.select}>
                <Select
                  name="role"
                  value={this.state.role ? this.state.role : null}
                  placeholder="Select Role"
                  options={this.state.roles}
                  onChange={val => this.handleSelect(val, 'role')}
                  clearable={false}
                  classNamePrefix="react-select"
                  onBlur={browserOnBlur()}
                />
              </div>
            </div>
            <div style={styles.selectContainer}>
              <div style={styles.selectLabel}>
                Grade
              </div>
              <div style={styles.select}>
                <Select
                  name="grade"
                  value={this.state.grade ? this.state.grade : null}
                  disabled={!this.state.grades}
                  placeholder="Select Grade"
                  options={this.state.grades}
                  onChange={val => this.handleSelect(val, 'grade')}
                  clearable={false}
                  classNamePrefix="react-select"
                  onBlur={browserOnBlur()}
                />
              </div>
            </div>
            <div style={styles.selectContainer}>
              <div style={styles.selectLabel}>
                Speciality (optional)
              </div>
              <div style={styles.select}>
                <Select
                  name="speciality"
                  value={this.state.speciality ? this.state.speciality : null}
                  disabled={!this.state.specialities}
                  placeholder="Select Speciality"
                  options={this.state.specialities}
                  onChange={val => this.handleSelect(val, 'speciality')}
                  clearable={false}
                  classNamePrefix="react-select"
                  onBlur={browserOnBlur()}
                />
              </div>
            </div>
            {Object.keys(this.props.labels).length > 0 &&
                convertToSortedArray(this.props.labels).map((label) => {
                  const lab = label.label;

                  return (
                    <FormInput
                      key={label.key}
                      label={lab}
                      name={label.key}
                      onChange={this.handleInputChange}
                      placeholder="£"
                      type="number"
                      validate={requiredField}
                      value={formValues[label.key]}
                    />
                  );
                })}
            <div style={styles.buttonContainer}>
              <Button
                style={{ backgroundColor: colors.negative, marginRight: spacing.base }}
                onClick={this.props.onRequestClose}
              >
                Cancel
              </Button>
              <Button
                onClick={this.createNewRateCard}
                disabled={
                  !formValues.name ||
                  !this.state.role ||
                  !this.state.grade ||
                  !this.state.labels.every(label => formValues[label])
                }
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps({ rgs, policies, global, rateCards }) {
  return {
    rgsMetadata: rgs.rgsMetadata,
    type: policies.policiesType,
    selectedSite: policies.selectedSite,
    org: Object.keys(global.employerAuthorisedOrgs).map((key) => {
      if (global.employerAuthorisedOrgs[key].enabled) return key;
      return null;
    }).filter(Boolean)[0],
    isFetchingLabels: rateCards.isFetchingLabels,
    labels: rateCards.labels,
    roles: rgs.roles,
  };
}

export default connect(mapStateToProps)(NewRateCardModal);
