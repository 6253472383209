import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Intercom from 'react-intercom';
import IdleTimer from 'react-idle-timer';
import { IconContext } from 'react-icons';

import { INTERCOM_APP_ID, INTERCOM_ENABLED } from 'config/settings';
import { isFeatureOn } from 'lib/features';
import { setUser } from 'lib/tracking';

import * as userThunks from 'thunks/user';
import * as globalThunks from 'thunks/global';
import * as screenRdc from 'reducers/screen';

import Loading from 'components/Loading';
import VersionManager from './VersionManager';
import MaintenanceMode from './MaintenanceMode';
import OfflineMode from './OfflineMode';
import MobileBlocker from './MobileBlocker';
import Routes from './Routes';

const SIXTY_MINUTES_IN_MILLISECONDS = 1000 * 3600; // Default idle duration before logout
let userIsActive = false;
let refreshIntervalId = null; // Used to then clear the timeout subscription

const styles = {
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
};

const { func, string, number, bool } = PropTypes;

class AppContainer extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    userId: string,
    inactiveLogoutDuration: number,
    maintenanceMode: bool,
    reportError: func.isRequired,
    userOrgKey: string,
    userOrgName: string,
  }

  static defaultProps = {
    userId: null,
    inactiveLogoutDuration: null,
    maintenanceMode: false,
    userOrgKey: null,
    userOrgName: null,
  }

  componentDidMount() {

    // Window dimension handling
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    // Logged-in user handling
    if (this.props.userId) {
      this.onActive();
      this.loadUserData();
    }
    refreshIntervalId = setTimeout(this.updateActivity, 30000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.onActive();
      refreshIntervalId = setTimeout(this.updateActivity, 30000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    if (refreshIntervalId) {
      userIsActive = false;
      clearTimeout(refreshIntervalId);
    }
  }

  onActive = async () => {
    if (this.props && this.props.userId) {
      userIsActive = true;
      await this.props.dispatch(userThunks.setLastActive());
    }
  }

  onIdle = async () => {

    // TODO: Re-enabled auto-logout once CW-245 is implemented
    if (true) return;

    if (this.props && this.props.userId && this.props.inactiveLogoutDuration) {
      userIsActive = false;

      if (this.props.userData && this.props.userData.email) {
        if (this.props.autoLogoutWhitelist && this.props.autoLogoutWhitelist.length > 0) {
          if (!this.emailIsWhitelisted(this.props.autoLogoutWhitelist, this.props.userData.email)) {
            this.props.dispatch(userThunks.logout());
            this.updateWindowDimensions();
          }
        } else {
          this.props.dispatch(userThunks.logout());
          this.updateWindowDimensions();
        }
      }
    }
  }

  emailIsWhitelisted = (list, email) => {
    let emailExists = false;
    if (list && list.length > 0) {
      const splitted = list.trim().split(',');
      const withoutUnnecessarySpaces = splitted.filter(e => e !== '');
      for (let i = 0; i < withoutUnnecessarySpaces.length; i += 1) {
        if (withoutUnnecessarySpaces[i].trim() === email) {
          emailExists = true;
        }
      }
    }
    return emailExists;
  }

  // NOTE: Self invoking function with setTimeout is implemented
  // instead of setInterval because setInterval causes
  // flickering of UI
  updateActivity = () => {
    if (this.props && this.props.userId) {
      if (userIsActive) {
        this.onActive();
      }
      refreshIntervalId = setTimeout(this.updateActivity, 30000);
    }
  }

  loadUserData = async () => {
    try {
      await this.props.dispatch(globalThunks.startWatchingGlobalData(this.props.userId));
    } catch (e) {

      // Logout firebase error (probably either auth error or user not found)
      await this.props.dispatch(userThunks.logout(false));

      this.props.reportError(e, {
        metaData: {
          context: {
            message: 'Auto-login failed',
          },
        },
      });
    }
  }

  updateWindowDimensions = () => {
    // Stop resize firing on scroll on iOS
    if (this.lastWidth && this.lastWidth === window.innerWidth) return;

    // Cache last width
    this.lastWidth = window.innerWidth;

    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.props.dispatch(screenRdc.dimensionsDidChange({
        width: window.innerWidth,
        height: window.innerHeight,
      }));
    }, 100);
  }

  render() {

    const { userId, userData, maintenanceMode, offlineMode, loadedGlobalData, passwordExpiry } = this.props;

    let user;
    const loggedIn = !!(userId && userData);

    if (loggedIn) {
      user = {
        user_id: userId, // eslint-disable-line camelcase
        name: userData.displayName,
        email: userData.email,
        'Org Key': this.props.userOrgKey,
        'Org Name': this.props.userOrgName,
        'Role': 'Admin', // eslint-disable-line quote-props
      };
    }

    const passwordExpiredOrExpiringSoon = passwordExpiry?.passwordExpired || (passwordExpiry?.passwordExpiringSoon && !passwordExpiry?.hasSkippedReset);
    const loading = userId && !passwordExpiredOrExpiringSoon && !loadedGlobalData;

    const showMaintenanceMode = !!maintenanceMode;
    const showOfflineMode = !maintenanceMode && offlineMode;
    const showLoadingSpinner = !maintenanceMode && !offlineMode && loading;
    const showRoutes = !maintenanceMode && !offlineMode && !loading;

    const renderIntercom = !!(INTERCOM_ENABLED && !showLoadingSpinner);

    return (
      <>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          timeout={this.props.inactiveLogoutDuration}
          startOnMount
        />
        {this.props.isMobile ? <MobileBlocker /> : null}
        {renderIntercom && <Intercom appID={INTERCOM_APP_ID} {...user} alignment="left" horizontal_padding="20" vertical_padding="20" />}
        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <Router>
            <VersionManager currentVersionHash={this.props.currentVersionHash} />
            {showMaintenanceMode && <MaintenanceMode screenWidth={this.props.screen.width} screenHeight={this.props.screen.height} />}
            {showOfflineMode && <OfflineMode />}
            {showLoadingSpinner && <div style={styles.loadingContainer}><Loading /></div>}
            {showRoutes && <Routes loggedIn={loggedIn} />}
          </Router>
        </IconContext.Provider>
      </>
    );
  }
}

function mapStateToProps({ screen, user, global, rgs }) {
  const { orgConfig, globalConfig } = global;

  const isLoaded = (obj) => {
    if (obj === null || obj === undefined) return false;
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') return true;
    if (Array.isArray(obj)) return obj.length > 0;
    return Object.keys(obj).length > 0;
  };

  const sandbox = isFeatureOn('sandbox', null, user, global);

  const loadedGlobalData = user.userId &&
    global.orgConfig &&
    global.employerAuthorisedOrgs &&
    user.sites &&
    isLoaded(global.metadata) &&
    isLoaded(rgs.rgsMetadata) &&
    isLoaded(rgs.roles);

  return {
    screen,
    isMobile: screen.isMobile,
    userId: user.userId,
    userData: user.userData,
    userOrgKey: global.currentOrgKey,
    userOrgName: orgConfig?.name ?? null,
    inactiveLogoutDuration: orgConfig?.inactiveLogoutDurationInMinutes ?? SIXTY_MINUTES_IN_MILLISECONDS,
    autoLogoutWhitelist: orgConfig?.autoLogoutWhitelist ?? null,
    maintenanceMode: globalConfig?.maintenanceMode ?? null,
    offlineMode: !!orgConfig?.offlineMode || !!user.userData?.offlineMode,
    sandbox,
    loadedGlobalData,
    passwordExpiry: global.passwordExpiry,
  };
}

export default connect(mapStateToProps)(AppContainer);
