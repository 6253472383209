import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Subscription } from 'zen-observable-ts';

import { Conversation } from 'types/Messaging';
import colors from 'config/colors';
import { watchConversations } from 'lib/api/messaging';
import { reportError } from 'lib/error-reporting';

import ConversationContainer from './ConversationContainer';
import ComposeMessage from './ComposeMessage';
import SharedInboxMembersModal from './SharedInboxMembersModal';

interface useConversationDetailsReturnType {
  conversation: Conversation | null,
  watchConversationError: string | null,
}

function useConversationDetails(conversationKey: string | undefined, externalConversation: Conversation | undefined) : useConversationDetailsReturnType {

  const [watchConversationError, setWatchConversationError] = useState(null as null | string);
  const [conversation, setConversation] = useState(null as null | Conversation);

  let subscription: Subscription;

  useEffect(() => {
    const getConversationDetails = () => {

      // Subscribe
      subscription = watchConversations({
        conversationKey,
        callback: (conversations: Conversation[]) => {
          // If no conversation found via Hasura, dislay error in UI and notify Bugsnag
          if (!conversations.length) {
            const errorString = 'No conversation found. If the problem persists, please contact technical support.';
            setWatchConversationError(errorString);
            reportError(new Error(errorString), {
              api: {
                path: 'messaging_v_user_conversation',
                details: 'apollo_subscription',
              },
            });
          }
          setConversation(conversations[0]);
        },
        error: (error) => {
          const customErrorMessage = 'There was an error whilst fetching the conversation. If the problem persists, please contact technical support.';
          setWatchConversationError(customErrorMessage);
          const apolloError = error instanceof Error ? error : new Error(customErrorMessage);
          reportError(apolloError, {
            api: {
              path: 'messaging_v_user_conversation',
              details: 'apollo_subscription',
            },
          });
        },
      });
    };

    // If conversation already exists, then save to state
    if (externalConversation) setConversation(externalConversation);
    // else if there is not already a subscription, the conversationKey exists and it's not a new conversation, subscribe
    else if (!subscription && conversationKey && conversationKey !== 'new') getConversationDetails();

    // Unsubscribe when unmounting component
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [externalConversation?.conversationKey]);

  useEffect(() => {
    if (!subscription && externalConversation) setConversation(externalConversation);
  }, [externalConversation]);

  return { conversation, watchConversationError };
}

interface InboxDetailsProps {
  conversationKey: string | undefined;
}

const InboxDetails: React.FC<InboxDetailsProps> = (props: InboxDetailsProps) => {
  const conversationFromRedux : Conversation | undefined = useSelector(state => state.messaging.conversations?.find(({ conversationKey }) => conversationKey === props.conversationKey));
  const loggedInUserKey = useSelector(state => state.user.userId);

  const [selectedSharedInboxKey, setSelectedSharedInboxKey] = useState<string | null>(null);

  const { conversation, watchConversationError } = useConversationDetails(props.conversationKey, conversationFromRedux);

  // Create conversation members and shared inbox arrays
  const conversationMembers = (conversation?.conversationMembers ?? [])
    .map((member) => ({
      key: member.userKey,
      name: member.userKey === loggedInUserKey ? 'You' : member.userName,
      isAdmin: member.isAdmin,
      isCandidate: member.isCandidate,
      isSharedInbox: false,
    }));
  const loggedInUserMember = conversationMembers.filter(member => member.key === loggedInUserKey);
  const otherMembers = conversationMembers.filter(member => member.key !== loggedInUserKey);
  const conversationSharedInboxes = (conversation?.conversationSharedInboxes ?? []).map((shared) => ({ name: shared.sharedInboxName, key: shared.sharedInboxKey, isAdmin: false, isCandidate: false, isSharedInbox: true }));

  // Array to contain both conversation members and conversation shared inboxes
  const conversationParticipants = [...loggedInUserMember, ...conversationSharedInboxes, ...otherMembers];

  // Show error if Hasura watch fails
  if (watchConversationError) {
    return (
      <div className="messagingInboxDetailsContainer" style={{ justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: colors.red, fontSize: '0.9rem', fontWeight: 600, padding: 12 }}>{watchConversationError}</p>
      </div>
    );
  }

  return (
    <div className="messagingInboxDetailsContainer">
      {props.conversationKey === 'new' ?
        <ComposeMessage />
        :
        <>
          <ConversationHeader conversationParticipants={conversationParticipants} setSelectedSharedInboxKey={setSelectedSharedInboxKey} conversationKey={props.conversationKey} />
          {props.conversationKey && <ConversationContainer conversationKey={props.conversationKey} />}
        </>
      }
      {selectedSharedInboxKey && <SharedInboxMembersModal onClose={() => setSelectedSharedInboxKey(null)} sharedInboxKey={selectedSharedInboxKey} />}
    </div>
  );
};

interface ConversationParticipant {
  name: string,
  key: string,
  isAdmin: boolean,
  isCandidate: boolean,
  isSharedInbox: boolean,
}

interface ConversationHeaderProps {
  conversationParticipants: ConversationParticipant[],
  setSelectedSharedInboxKey: (sharedInboxKey: string) => void,
  conversationKey: string | undefined,
}

function ConversationHeader(props: ConversationHeaderProps) {
  const history = useHistory();

  return (
    <div className="messagingInboxDetailsHeader">
      {props.conversationParticipants.map((participant, index) => (
        <span>
          {/* Candidate */}
          {participant.isCandidate && <a onClick={() => history.push(`${props.conversationKey}/participants/${participant.key}`)}>{participant.name}</a>}

          {/* Shared Inbox */}
          {participant.isSharedInbox && <a onClick={() => props.setSelectedSharedInboxKey(participant.key)}>{participant.name}</a>}

          {/* Admin (and not candidate) */}
          {!participant.isCandidate && participant.isAdmin && <span>{participant.name}</span>}

          {index < props.conversationParticipants.length - 1 ? ',\u00A0' : ''}
        </span>
      ))}
    </div>
  );
}

export default InboxDetails;
