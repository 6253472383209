import React from 'react';
import PropTypes from 'prop-types';
import { MdStar } from 'react-icons/md';
import './RatingDisplayStars.scss';

const RatingDisplayStars = (props) => {

  const renderEmptyStars = () => {
    const stars = [];
    for (let i = 1; i <= props.numberOfStars; i += 1) {
      stars.push(<MdStar size={40} className="rating__star--empty" key={i} />);
    }
    return stars;
  };

  const renderFilledStars = () => {
    const stars = [];
    for (let i = 1; i <= props.numberOfStars; i += 1) {
      stars.push(<MdStar size={40} className="rating__star--filled" key={i} />);
    }
    return stars;
  };

  const STAR_PADDING = 6.67;
  const STAR_WIDTH = 33.33;
  const containerWidth = props.numberOfStars * STAR_WIDTH;
  const numberOfFullStars = Math.floor(props.rating);
  const additional = (numberOfFullStars * STAR_PADDING) + STAR_PADDING / 2;
  const width = ((props.rating / props.numberOfStars) * containerWidth) + additional;
  return (
    <div className="rating__stars">
      <div className="empty-container">
        {renderEmptyStars()}
      </div>
      <div className="filled-container" style={{ width: `${width}px` }}>
        {renderFilledStars()}
      </div>
    </div>
  );
};

const { number } = PropTypes;
RatingDisplayStars.propTypes = {
  numberOfStars: number.isRequired,
  rating: number.isRequired,
};

export default RatingDisplayStars;
