import React from 'react';
import Policies from './Policies';
import { permissions } from '../../config/permissions';
import Feature from '../../components/Feature';

const OrgPolicies = (props) => {
  return (
    <div>
      <Feature
        permissionRequired={permissions.ORG_CAN_VIEW_POLICIES}
        failureMessage={
          <p className="failureMessage">
            You do not have permissions to view organisation policies. Please contact your system administrator.
          </p>
        }
      >
        <Policies
          onRateCardClick={props.onRateCardClick}
          canEdit={props.canEdit}
          {...props}
        />
      </Feature>
    </div>
  );
};

export default OrgPolicies;
