import React from 'react';
import { useSelector } from 'react-redux';
import Page from 'components/Page';
import Button from 'components/Button';
import { MdAdd } from 'react-icons/md';
import ConversationListPanel from './ConversationListPanel';
import InboxDetails from './InboxDetails';
import './styles.scss';

interface InboxProps {
  nav: {
    goToNewConversation: () => void;
    goToConversation: (key: string) => void;
  };
  selectedConversationKey: 'new' | string | undefined;
}

const Inbox: React.FC<InboxProps> = (props: InboxProps) => {
  const messageError = useSelector(state => state.messaging.error);
  const errorFetchingAvailableParticipants = useSelector(state => state.messaging.errorFetchingAvailableParticipants);

  const error = messageError || errorFetchingAvailableParticipants;

  return (
    <Page vflex title="Messaging">
      <div className="inboxHeader">
        <p>Messages</p>
        <Button
          onClick={props.nav.goToNewConversation}
          icon={<MdAdd />}
          black
          shadow={false}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          New Conversation
        </Button>
      </div>
      {error && (
        <div className="inboxError">
          <p>{error}</p>
        </div>
      )}
      <div className="inboxContentContainer">
        <ConversationListPanel goToConversation={props.nav.goToConversation} selectedConversationKey={props.selectedConversationKey} />
        <InboxDetails conversationKey={props.selectedConversationKey} />
      </div>
    </Page>
  );
};

export default Inbox;
