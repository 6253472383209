const SET_DRAFT_SITE_AREAS = 'SET_DRAFT_SITE_AREAS';
const SET_DRAFT_APPLICABLE_RATE_CARDS = 'SET_DRAFT_APPLICABLE_RATE_CARDS';
const IS_FETCHING_APPLICABLE_CANDIDATES = 'IS_FETCHING_APPLICABLE_CANDIDATES';
const SET_DRAFT_APPLICABLE_CANDIDATES = 'SET_DRAFT_APPLICABLE_CANDIDATES';
const CLEAR_DRAFT_SHIFT_DATA = 'CLEAR_DRAFT_SHIFT_DATA';
const SET_DRAFT_ROLES = 'SET_DRAFT_ROLES';
const SET_DRAFT_GRADES = 'SET_DRAFT_GRADES';
const SET_DRAFT_SPECIALITIES = 'SET_DRAFT_SPECIALITIES';
const SET_ACTIVE_SHIFT_ID = 'SET_ACTIVE_SHIFT_ID';

export const setSiteAreas = data => ({ type: SET_DRAFT_SITE_AREAS, data });
export const setSpecialities = data => ({ type: SET_DRAFT_SPECIALITIES, data });
export const setRoles = data => ({ type: SET_DRAFT_ROLES, data });
export const setGrades = data => ({ type: SET_DRAFT_GRADES, data });
export const setApplicableRateCards = rateCards => ({ type: SET_DRAFT_APPLICABLE_RATE_CARDS, rateCards });
export const setApplicableCandidates = candidates => ({ type: SET_DRAFT_APPLICABLE_CANDIDATES, candidates });
export const fetchingApplicableCandidates = () => ({ type: IS_FETCHING_APPLICABLE_CANDIDATES });
export const clearData = () => ({ type: CLEAR_DRAFT_SHIFT_DATA });
export const setShiftId = id => ({ type: SET_ACTIVE_SHIFT_ID, id });

const initialState = {
  applicableCandidates: [],
  reasons: null,
  subReasons: null,
  applicableRateCards: [],
  isFetchingApplicableCandidates: false,
  shiftId: null,
  roles: null,
  grades: null,
  specialities: null,
};

export default function createDraftShifts(state = initialState, action) {
  switch (action.type) {
    case SET_DRAFT_SITE_AREAS:
      return { ...state, siteAreas: action.data };
    case SET_DRAFT_ROLES:
      return { ...state, roles: action.data };
    case SET_DRAFT_GRADES:
      return { ...state, grades: action.data };
    case SET_DRAFT_SPECIALITIES:
      return { ...state, specialities: action.data };
    case SET_DRAFT_APPLICABLE_RATE_CARDS:
      return { ...state, applicableRateCards: action.rateCards };
    case SET_DRAFT_APPLICABLE_CANDIDATES:
      return { ...state, applicableCandidates: action.candidates, isFetchingApplicableCandidates: false };
    case IS_FETCHING_APPLICABLE_CANDIDATES:
      return { ...state, isFetchingApplicableCandidates: true };
    case SET_ACTIVE_SHIFT_ID:
      return { ...state, shiftId: action.id };
    case CLEAR_DRAFT_SHIFT_DATA:
      return initialState;
    default:
      return state;
  }
}
