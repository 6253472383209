import { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';
import { keyBy } from 'lodash-es';
import { firstBy } from 'thenby';

import {
  setShiftIsSelected,
  selectSpecifiedShifts,
  unselectSpecifiedShifts,
  unselectAllShifts,
  setShiftIsCollapsed,
  collapseSpecifiedShifts,
  expandSpecifiedShifts,
  expandAllShifts,
} from 'reducers/jobs';
import { getShifts } from 'thunks/jobs';
import { shiftMatchesFilters, calculateStartAndEndTimesFromTemplateShift } from './helpers';

export function mapDraftShapeToShiftShape(key, draft, adminSites, orgTimezone) {

  const timezone = (draft.site?.id && adminSites[draft.site?.id])?.timezone ?? orgTimezone;

  const bookedCandidates = draft.candidates.map(candidate => ({ candidateKey: candidate.id, candidateName: candidate.name, candidateBanks: null }));

  return {
    key,
    serviceKey: draft.service?.id,
    serviceName: draft.service?.name,
    siteKey: draft.site?.id,
    siteName: draft.site?.name,
    areaKey: draft.area?.id,
    areaName: draft.area?.name,
    roleKey: draft.role?.id,
    roleName: draft.role?.name,
    gradeKey: draft.grade?.id,
    gradeName: draft.grade?.name,
    specialityKey: draft.speciality?.id,
    specialityName: draft.speciality?.name,
    reasonKey: draft.reason?.id,
    reasonName: draft.reason?.name,
    subReasonKey: draft.reason2?.id,
    subReasonName: draft.reason2?.name,
    preferredGenderKey: draft.preferredGender?.id,
    description: draft.description,
    slotsRequired: draft.slotsRequired,
    bookedCandidates,
    startTime: draft.startTime,
    endTime: draft.endTime,
    costCentreKey: draft.costCentre ? draft.costCentre.code : null,
    costCentreName: draft.costCentre ? draft.costCentre.description : null,
    isValid: draft.isValid,
    isDraft: true,
    isTemplateDraft: draft.isTemplateDraft,
    statusError: draft.status,
    response: draft.response,
    timezone,
  };
}

function computeMainRowBookedCandidateText(shiftIsCollapsed, bookedCandidates) {

  if (bookedCandidates.length >= 1 && shiftIsCollapsed) {

    // If there is 1 booked candidate and rows are collapsed, return all booked candidate info
    if (bookedCandidates.length === 1) {
      return bookedCandidates[0];
    }

    // if there are multiple bookings and rows are collapsed, return truncated label
    const firstBookedCandidateName = bookedCandidates[0].candidateName;
    const firstBookedCandidateBanks = bookedCandidates[0]?.candidateBanks;
    const firstCandidateBank = firstBookedCandidateBanks ? `(${Object.values(firstBookedCandidateBanks)[0]})` : '';
    const candidatesTrucatedLabel = `${firstBookedCandidateName} ${firstCandidateBank} + ${bookedCandidates.length - 1} more`;
    return { candidatesTrucatedLabel };
  }

  // Return nothing
  return {};
}

export function flattenShiftsFromSlots(shift, keyedCollapsedShiftKeys) {

  const rows = [];

  const slotsFilled = shift.bookedCandidates.length;
  const shiftIsCollapsed = !!keyedCollapsedShiftKeys[shift.key];
  const mainRowBookedCandidateText = computeMainRowBookedCandidateText(shiftIsCollapsed, shift.bookedCandidates);

  const mainShiftRow = {
    ...shift,
    ...mainRowBookedCandidateText,
    canControlCollapsibleRows: slotsFilled > 0,
    showBooking: shift.bookedCandidates.length > 0 && shiftIsCollapsed,
    isSlot: false,
  };

  rows.push(mainShiftRow);

  if (slotsFilled > 0) {

    const sortedBookedCandidates = shift.bookedCandidates.sort(firstBy('slotId'));

    sortedBookedCandidates.forEach((bookedCandidate) => {
      rows.push({
        ...shift,
        ...bookedCandidate,
        key: `${shift.key}_${bookedCandidate.candidateKey}`,
        slotShiftKey: shift.key,
        showBooking: true,
        canControlCollapsibleRows: false,
        isCollapsed: !!keyedCollapsedShiftKeys[shift.key],
        isSlot: true,
        className: shift.className,
      });
    });
  }

  return rows;
}

export function useShiftList(period) {

  const shiftList = useSelector(state => state.jobs.shiftList);
  const collapsedShiftKeys = useSelector(state => state.jobs.collapsedShiftKeys);
  const isFetchingShiftsForListView = useSelector(state => state.jobs.isFetchingShiftsForListView);
  const draftShifts = useSelector(state => state.createShifts.draftShifts);
  const selectedTemplate = useSelector(state => state.templates.template);
  const templateShifts = useSelector(state => state.createShifts.templateShifts);
  const isApplyingTemplate = useSelector((state) => state.createShifts.isApplyingTemplate);
  const applyTemplateToDate = useSelector((state) => state.createShifts.applyTemplateToDate);
  const selectedFilterItems = useSelector(state => state.filter.v2SelectedItems.shifts);
  const adminSites = useSelector(state => state.user.sites);
  const orgTimezone = useSelector(state => state.global.orgConfig?.timezone);

  const dispatch = useAppDispatch();
  const refreshShiftList = useCallback((newPeriod) => dispatch(getShifts(newPeriod.startDate.toISOString(), newPeriod.endDate.clone().endOf('day').toISOString())));

  return useMemo(() => {

    const periodStartDateString = period.startDate.toISOString();
    const periodEndDateString = period.endDate.clone().endOf('day').toISOString();

    function filterByDate([key, shift]) {
      if (!shift.startTime) return true;
      if (shift.startTime < periodStartDateString) return false;
      if (shift.startTime > periodEndDateString) return false;
      return true;
    }

    function filterByFilters([key, shift]) {
      return shiftMatchesFilters(shift, selectedFilterItems);
    }


    const filteredShifts = Object.entries(shiftList)
      .filter(filterByDate)
      .map(([, shift]) => shift);

    const filteredDraftShifts = Object.entries(draftShifts)
      .filter(filterByDate)
      .filter(filterByFilters)
      .map(([draftShiftKey, draftShift]) => mapDraftShapeToShiftShape(draftShiftKey, draftShift, adminSites, orgTimezone));

    const filteredTemplateShifts = isApplyingTemplate && applyTemplateToDate && selectedTemplate?.periodType
      ? Object.entries(templateShifts)
        .map(([templateShiftKey, shift]) => {
          const timezone = (shift.site?.id && adminSites[shift.site?.id]?.timezone) ?? orgTimezone;
          const [startTime, endTime] = calculateStartAndEndTimesFromTemplateShift({ date: applyTemplateToDate.toISOString(), startTimeOfDay: shift.startTimeOfDay, endTimeOfDay: shift.endTimeOfDay, periodType: selectedTemplate.periodType, dayOfWeek: shift.dayOfWeek?.id ?? null, timezone });
          return [templateShiftKey, { ...shift, startTime, endTime, isTemplateDraft: true }];
        })
        .filter(filterByDate)
        .filter(filterByFilters)
        .map(([key, templateShift]) => mapDraftShapeToShiftShape(key, templateShift, adminSites, orgTimezone)) : [];

    const filteredShiftList = [...filteredDraftShifts, ...filteredShifts, ...filteredTemplateShifts];

    return {
      isFetchingShiftsForListView,
      refreshShiftList,
      shiftList: filteredShiftList,
      keyedShifts: keyBy(filteredShiftList, 'key'),
      draftShiftCount: 0,
      // draftShiftCount: filteredDraftShifts.length,
      nonDraftShiftCount: filteredShifts.length,
      totalShiftCount: filteredShifts.length,
      // totalShiftCount: filteredDraftShifts.length + filteredShifts.length,
    };

  }, [shiftList, draftShifts, templateShifts, selectedFilterItems, isFetchingShiftsForListView, period?.startTime?.valueOf?.(), period?.endTime?.valueOf?.(), applyTemplateToDate?.valueOf?.(), isApplyingTemplate, selectedTemplate?.periodType, collapsedShiftKeys]);
}

export function useSelectedShifts(period) {
  const selectedShiftKeys = useSelector(state => state.jobs.selectedShiftKeys);

  const dispatch = useAppDispatch();
  const setShiftIsSelectedCb = useCallback((shiftKey, isSelected) => dispatch(setShiftIsSelected(shiftKey, isSelected)));
  const selectSpecifiedShiftsCb = useCallback((shiftKeys) => dispatch(selectSpecifiedShifts(shiftKeys)));
  const unselectSpecifiedShiftsCb = useCallback((shiftKeys) => dispatch(unselectSpecifiedShifts(shiftKeys)));
  const unselectAllShiftsCb = useCallback(() => dispatch(unselectAllShifts()));

  useEffect(() => period && dispatch(unselectAllShifts()), [period?.startDate?.valueOf?.(), period?.endDate?.valueOf?.()]);

  return useMemo(() => ({
    selectedShiftKeys,
    setShiftIsSelected: setShiftIsSelectedCb,
    selectSpecifiedShifts: selectSpecifiedShiftsCb,
    unselectSpecifiedShifts: unselectSpecifiedShiftsCb,
    unselectAllShifts: unselectAllShiftsCb,
    selectedShiftCount: selectedShiftKeys.length,
  }), [selectedShiftKeys]);
}

export function useCollapsedSlots(period) {
  const collapsedShiftKeys = useSelector(state => state.jobs.collapsedShiftKeys);

  const dispatch = useAppDispatch();
  const setShiftIsCollapsedCb = useCallback((shiftKey, isCollapsed) => dispatch(setShiftIsCollapsed(shiftKey, isCollapsed)));
  const collapseSpecifiedShiftsCb = useCallback((shiftKeys) => dispatch(collapseSpecifiedShifts(shiftKeys)));
  const expandSpecifiedShiftsCb = useCallback((shiftKeys) => dispatch(expandSpecifiedShifts(shiftKeys)));
  const expandAllShiftsCb = useCallback(() => dispatch(expandAllShifts()));

  useEffect(() => period && dispatch(expandAllShifts()), [
    period?.startDate?.valueOf?.(),
    period?.endDate?.valueOf?.(),
  ]);

  const keyedCollapsedShiftKeys = useMemo(() => {
    const hashTable = {};
    collapsedShiftKeys.forEach((shiftKey) => {
      hashTable[shiftKey] = true;
    });
    return hashTable;
  }, [collapsedShiftKeys]);

  return useMemo(() => ({
    collapsedShiftKeys,
    keyedCollapsedShiftKeys,
    setShiftIsCollapsed: setShiftIsCollapsedCb,
    collapseSpecifiedShifts: collapseSpecifiedShiftsCb,
    expandSpecifiedShifts: expandSpecifiedShiftsCb,
    expandAllShifts: expandAllShiftsCb,
  }), [collapsedShiftKeys]);
}
