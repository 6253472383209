import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-toggle-switch';
import deepEqual from 'deep-equal';
import SettingItem from '../../components/SettingItem';
import RateCardPolicies from './RateCardPolicies';
import Button from '../../components/Button';
import AuditTrailModal from '../../components/AuditTrailModal';
import colors from '../../config/colors';
import spacing from '../../config/spacing';

const styles = {
  link: {
    padding: spacing.small,
    color: colors.positive,
    float: 'right',
    outline: 'none',
    userSelect: 'none',
  },
};

function convertToHours(minutes) {
  return minutes / 60;
}

function convertToMinutes(hours) {
  return hours * 60;
}

function convertToSortedArray(data) {
  return Object.keys(data).map((key) => {
    return data[key];
  }).sort((a, b) => {
    if (a.when < b.when) return 1;
    if (b.when < a.when) return -1;
    return 0;
  });
}

const { objectOf, object, func, bool } = PropTypes;
export default class Policies extends Component {
  static propTypes = {
    policies: objectOf(object),
    updatePolicies: func.isRequired,
    canEdit: bool,
  }

  static defaultProps = {
    policies: {},
    canEdit: false,
  }

  constructor(props) {
    super(props);
    const { policies } = props;
    this.state = {
      auditModalOpen: false,
      messagesToShow: {},
      isOpen: {
        breaks: false,
        earlyStarts: false,
        lateFinishes: false,
        nearest: false,
      },
      breaks: {
        breakDurationInMinutes: policies.breaks ? policies.breaks.breakDurationInMinutes : 0,
        breakIntervalInMinutes: policies.breaks ? policies.breaks.breakIntervalInMinutes : 0,
      },
      earlyStarts: {
        permitted: policies.earlyStarts ? policies.earlyStarts.permitted : false,
        maxDeviationInMinutes: policies.earlyStarts ? policies.earlyStarts.maxDeviationInMinutes : 0,
      },
      lateFinishes: {
        permitted: policies.lateFinishes ? policies.lateFinishes.permitted : false,
        maxDeviationInMinutes: policies.lateFinishes ? policies.lateFinishes.maxDeviationInMinutes : 0,
      },
      timesheetRounding: {
        permitted: policies.timesheetRounding ? policies.timesheetRounding.permitted : false,
        timesheetsPaidToNearestMinutes: policies.timesheetRounding ? policies.timesheetRounding.timesheetsPaidToNearestMinutes : 0,
      },
    };
  }

  setPolicyState(policies) {
    this.setState({
      breaks: {
        breakDurationInMinutes: policies.breaks ? policies.breaks.breakDurationInMinutes : 0,
        breakIntervalInMinutes: policies.breaks ? policies.breaks.breakIntervalInMinutes : 0,
      },
      earlyStarts: {
        permitted: policies.earlyStarts ? policies.earlyStarts.permitted : false,
        maxDeviationInMinutes: policies.earlyStarts ? policies.earlyStarts.maxDeviationInMinutes : 0,
      },
      lateFinishes: {
        permitted: policies.lateFinishes ? policies.lateFinishes.permitted : false,
        maxDeviationInMinutes: policies.lateFinishes ? policies.lateFinishes.maxDeviationInMinutes : 0,
      },
      timesheetRounding: {
        permitted: policies.timesheetRounding ? policies.timesheetRounding.permitted : false,
        timesheetsPaidToNearestMinutes: policies.timesheetRounding ? policies.timesheetRounding.timesheetsPaidToNearestMinutes : 0,
      },
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!deepEqual(nextProps.policies, this.props.policies)) {
      this.setPolicyState(nextProps.policies);
    }
  }

  onExpand = (propName) => {
    this.setState({
      isOpen: {
        [propName]: !this.state.isOpen[propName],
      },
    });
  }

  onChangeBreaksInterval = (e) => {
    this.setState({
      breaks: {
        ...this.state.breaks,
        breakIntervalInMinutes: convertToMinutes(e.target.value),
      },
    });
  }

  onChangeBreakMinutes = (e) => {
    this.setState({
      breaks: {
        ...this.state.breaks,
        breakDurationInMinutes: +e.target.value,
      },
    });
  }

  onChange = (propName, fieldName, event) => {
    this.setState({
      [propName]: {
        permitted: this.state[propName].permitted,
        [fieldName]: +event.target.value,
      },
    });
  }

  enable = (propName) => {
    this.setState({
      [propName]: {
        ...this.state[propName],
        permitted: !this.state[propName].permitted,
      },
    });
  }

  cancel = (part) => {
    const { policies } = this.props;
    if (part === 'breaks') {
      this.setState({
        breaks: {
          breakDurationInMinutes: policies.breaks ? policies.breaks.breakDurationInMinutes : 0,
          breakIntervalInMinutes: policies.breaks ? policies.breaks.breakIntervalInMinutes : 0,
        },
      });
    } else if (part === 'earlyStarts') {
      this.setState({
        earlyStarts: {
          permitted: policies.earlyStarts ? policies.earlyStarts.permitted : false,
          maxDeviationInMinutes: policies.earlyStarts ? policies.earlyStarts.maxDeviationInMinutes : 0,
        },
      });
    } else if (part === 'lateFinishes') {
      this.setState({
        lateFinishes: {
          permitted: policies.lateFinishes ? policies.lateFinishes.permitted : false,
          maxDeviationInMinutes: policies.lateFinishes ? policies.lateFinishes.maxDeviationInMinutes : 0,
        },
      });
    } else if (part === 'timesheetRounding') {
      this.setState({
        timesheetRounding: {
          permitted: policies.timesheetRounding ? policies.timesheetRounding.permitted : false,
          timesheetsPaidToNearestMinutes: policies.timesheetRounding ? policies.timesheetRounding.timesheetsPaidToNearestMinutes : 0,
        },
      });
    }

    this.onExpand(part);
  }

  save = (part) => {
    this.props.updatePolicies(part, this.state[part]);
    this.onExpand(part);
  }

  renderButtons = (part) => {
    return (
      <div className="policiesButtons">
        <Button
          onClick={() => this.cancel(part)}
          small
          style={{ backgroundColor: colors.negative }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => this.save(part)}
          small
          style={{ backgroundColor: colors.green }}
          disabled={!this.props.canEdit}
        >
          Save
        </Button>
        {this.props.policies && this.props.policies[part] && this.props.policies[part].messages ?
          <div
            onClick={() => this.setState({
              auditModalOpen: true,
              messagesToShow: this.props.policies[part].messages,
            })}
            role="link"
            tabIndex={0}
            className="rise"
            style={styles.link}
          >
            Show audit trail
          </div>
          : null
        }
      </div>
    );
  }

  renderEnabledAndMinutes = (title, description, propName, scenario) => {
    return (
      <SettingItem
        title={title}
        description={description}
        onExpand={() => this.onExpand(propName)}
        open={this.state.isOpen[propName]}
      >
        <div className="toggleWrapper">
          Enabled
          <Switch
            onClick={() => this.enable(propName)}
            on={this.state[propName].permitted}
          />
        </div>
        {this.state[propName].permitted ?
          <p>
            Staff can
            {' '}
            {scenario === 'start' ? 'start' : 'finish'}
            {' '}
shifts
            {' '}
            <input
              type="number"
              className="inlineNumber"
              value={this.state[propName].maxDeviationInMinutes}
              onChange={event => this.onChange(propName, 'maxDeviationInMinutes', event)}
            />
            {' '}
minute
            {this.state[propName].maxDeviationInMinutes > 1 ? 's' : ''}
            {' '}
            {scenario === 'start' ? 'before' : 'after'}
            {' '}
they are booked to
            {' '}
            {scenario === 'start' ? 'begin' : 'end'}
.
          </p> : null}
        {this.renderButtons(propName)}
      </SettingItem>
    );
  }

  render() {
    return (
      <div>
        <SettingItem
          title="Rate Cards"
          description="Rate cards help enormously to govern spend. You can modify all rate cards for any Role, Grade & Speciality or add a new one."
          onExpand={() => this.onExpand('rateCards')}
          open={this.state.isOpen.rateCards}
        >
          <RateCardPolicies
            onRateCardClick={this.props.onRateCardClick}
            openCreateModal={this.props.openRateCardCreateModal}
            closeCreateModal={this.props.closeRateCardCreateModal}
            createModalOpen={this.props.rateCardCreateModalOpen}
            canEdit={this.props.canEdit}
          />
        </SettingItem>
        <SettingItem
          title="Breaks"
          description="Breaks policy is used in timesheets to govern the breaks taken on a shift and what is paid. Set it here to keep everyone under the same rules."
          onExpand={() => this.onExpand('breaks')}
          open={this.state.isOpen.breaks}
        >
          {!this.state.breaks || (!this.state.breaks.breakIntervalInMinutes && !this.state.breaks.breakDurationInMinutes) ?
            <p>
No breaks policy currently set for this
              {this.props.type}
, set one below
            </p>
            : null
          }
          <p>
            Every
            <input
              type="number"
              className="inlineNumber"
              value={convertToHours(this.state.breaks.breakIntervalInMinutes)}
              onChange={this.onChangeBreaksInterval}
            />
            hour
            {convertToHours(this.state.breaks.breakIntervalInMinutes) > 1 ? 's' : ''}
            {' '}
staff can take a
            <input
              type="number"
              className="inlineNumber"
              value={this.state.breaks.breakDurationInMinutes}
              onChange={this.onChangeBreakMinutes}
            />
            minute break.
          </p>
          {this.renderButtons('breaks')}
        </SettingItem>
        {this.renderEnabledAndMinutes(
          'Early Starts',
          'Set how early starts are paid to keep spend under control.',
          'earlyStarts',
          'start',
        )}
        {this.renderEnabledAndMinutes(
          'Late Finishes',
          'It\'s common for shifts to last longer than planned. Keep spend under control by setting your policy on how much extra time will be paid.',
          'lateFinishes',
          'finish',
        )}
        <SettingItem
          title="Timesheet Rounding"
          description="Shifts don't always begin or end exactly when expected. Set how you want to round up/down to the nearest period."
          onExpand={() => this.onExpand('nearest')}
          open={this.state.isOpen.nearest}
        >
          <div className="toggleWrapper">
            Enabled
            <Switch
              onClick={() => this.enable('timesheetRounding')}
              on={this.state.timesheetRounding.permitted}
            />
          </div>
          {this.state.timesheetRounding.permitted ?
            <p>
              Timesheets will be paid to the nearest
              {' '}
              <input
                type="number"
                className="inlineNumber"
                value={this.state.timesheetRounding.timesheetsPaidToNearestMinutes}
                onChange={event => this.onChange('timesheetRounding', 'timesheetsPaidToNearestMinutes', event)}
              />
              {' '}
minute
              {this.state.timesheetRounding.timesheetsPaidToNearestMinutes > 1 ? 's' : ''}
.
            </p>
            : null
          }
          {this.renderButtons('timesheetRounding')}
        </SettingItem>
        { Object.keys(this.state.messagesToShow).length > 0 ?
          <AuditTrailModal
            isOpen={this.state.auditModalOpen}
            onRequestClose={() => this.setState({ auditModalOpen: false })}
            contentLabel="Audit trail"
            messages={convertToSortedArray(this.state.messagesToShow)}
          />
          : null
        }
      </div>
    );
  }
}
