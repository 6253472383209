import { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uniq, uniqBy, keyBy } from 'lodash-es';
import { convertToSelectArray, convertToSelect } from '../lib/helpers';
import { v2SetSelectedItems } from '../reducers/filter';

const defaultEnabledFilters = [
  { key: 'sites', isMulti: true },
  { key: 'specialities', isMulti: true },
  { key: 'roles', isMulti: true },
  { key: 'grades', isMulti: true },
];


function validateAndInitialiseFilterItems(filterConfig, allSelectedItems, setSelectedItems) {
  filterConfig.forEach(({ key: filterKey, options }) => {
    const selectedItems = allSelectedItems?.[filterKey] ?? [];
    const validFilterItems = options.map(option => option.value);
    const validSelectedItems = selectedItems.filter(key => validFilterItems.includes(key)) ?? [];

    // Filter out invalid selected items
    if (validSelectedItems.length !== selectedItems.length) {
      setSelectedItems(filterKey, validSelectedItems);
    }

    // If there is only 1 filter option, then set options as selected
    if (selectedItems.length === 0 && validFilterItems.length === 1) {
      setSelectedItems(filterKey, validFilterItems);
    }
  });
}


function computeFilterConfig({ enabledFilters, selectedRoles, selectedServiceKeys, selectedSites, banks, adminSites, roles, rgsMetadata, shiftReasons, services }) {

  const keyedEnabledFilters = keyBy(enabledFilters, 'key');

  // Compute selected roles (or fallback to all roles)
  let selectedRoleKeys;
  if (keyedEnabledFilters.roles && selectedRoles?.length) selectedRoleKeys = selectedRoles;
  else selectedRoleKeys = roles.map(role => role.value);

  // Compute selected services (or fallback to all services)
  let selectedServices;
  if (keyedEnabledFilters.services && selectedServiceKeys?.length) selectedServices = services.filter(service => selectedServiceKeys.includes(service.key));
  else selectedServices = services;

  let roleKeyAllowList;
  let gradeKeyAllowList;
  let specialityKeyAllowList;
  if (!selectedServices.length || selectedServices.some(service => !service.hasRestrictedRgs)) {
    roleKeyAllowList = null;
    gradeKeyAllowList = null;
    specialityKeyAllowList = null;
  } else {
    roleKeyAllowList = uniq(selectedServices.flatMap(service => selectedRoleKeys.filter(roleKey => service?.rgs?.[roleKey])));
    gradeKeyAllowList = uniq(selectedServices.flatMap(service => selectedRoleKeys.flatMap(roleKey => service?.rgs?.[roleKey]?.gradeKeys ?? [])));
    specialityKeyAllowList = uniq(selectedServices.flatMap(service => selectedRoleKeys.flatMap(roleKey => service?.rgs?.[roleKey]?.specialityKeys ?? [])));
  }

  const roleFilterOptions = keyedEnabledFilters.roles && roles.filter(role => !roleKeyAllowList || roleKeyAllowList.includes(role.value));

  // Compute available speciality options (depends on selected role)
  const specialityFilterOptions = keyedEnabledFilters.specialities && uniqBy(
    selectedRoleKeys
      .flatMap(key => convertToSelectArray(rgsMetadata[key].specialities, 'name'))
      .filter(spec => !specialityKeyAllowList || specialityKeyAllowList.includes(spec.value)),
    'value',
  );

  // Compute available grade options (depends on selected role)
  const gradeFilterOptions = keyedEnabledFilters.grades && selectedRoleKeys
    .filter(roleKey => !!rgsMetadata[roleKey])
    .flatMap(roleKey => {
      const role = rgsMetadata[roleKey];
      return Object.entries(role.grades)
        .filter(([gradeKey]) => !gradeKeyAllowList || gradeKeyAllowList.includes(gradeKey))
        .map(([gradeKey, grade]) => ({
          value: gradeKey,
          label: grade.name,
          order: grade.rank,
          group: role.name,
        }));
    });

  // Compute selected sites (or fallback to all sites)
  let selectedSiteKeys;
  if (keyedEnabledFilters.sites && selectedSites?.length) selectedSiteKeys = selectedSites;
  else selectedSiteKeys = Object.keys(adminSites);

  // Compute area options (depends on selected site)
  const areaFilterOptions = keyedEnabledFilters.areas && selectedSiteKeys
    .map(siteKey => adminSites[siteKey])
    .filter(site => !!site?.areas?.length)
    .flatMap((site) => {
      return site.areas
        .map((area) => ({
          value: area.key,
          label: area.name,
          group: site.name,
        }));
    });

  // Create object of all filter options for lookup by key
  const filterOptions = {
    services: services.map(convertToSelect),
    banks: banks.map(bank => ({ value: bank.bankKey, label: bank.name })),
    sites: convertToSelectArray(adminSites, 'name', true),
    roles: roleFilterOptions,
    specialities: specialityFilterOptions,
    grades: gradeFilterOptions,
    reasons: shiftReasons.map(convertToSelect),
    areas: areaFilterOptions,
  };

  // Map enabledFilters to mix in the filter options
  return enabledFilters.map((filterConfig) => ({ ...filterConfig, options: filterOptions[filterConfig.key] }));
}

export function useFilters(namespace, configKey) {

  // Create callback to setSelectedItems
  const dispatch = useDispatch();
  const setSelectedItems = useCallback((ns, filterKey, items) => dispatch(v2SetSelectedItems(ns, filterKey, items)));

  // Get enabled filters and selected filter values
  const enabledFilters = useSelector(state => state.global.orgConfig?.[configKey] ?? defaultEnabledFilters);
  const selectedFilterItems = useSelector(state => state.filter.v2SelectedItems[namespace]);

  // Get Site and RGS metadata
  const adminBanks = useSelector(state => state.global.adminBanks);
  const bankOptions = useMemo(() => adminBanks.filter(bank => bank.canViewStaff), [adminBanks]);
  const adminSites = useSelector(state => state.user.sites || {});
  const roles = useSelector(state => state.rgs.roles);
  const rgsMetadata = useSelector(state => state.rgs.rgsMetadata);
  const shiftReasons = useSelector(state => state.global.shiftReasons);
  const services = useSelector(state => state.global.services);

  // Compute filter config
  const filterConfig = useMemo(
    () => computeFilterConfig({
      enabledFilters,
      selectedRoles: selectedFilterItems?.roles,
      selectedServiceKeys: selectedFilterItems?.services,
      selectedSites: selectedFilterItems?.sites,
      banks: bankOptions,
      adminSites,
      roles,
      rgsMetadata,
      shiftReasons,
      services,
    }),
    [enabledFilters, selectedFilterItems?.roles, selectedFilterItems?.services, selectedFilterItems?.sites, bankOptions, adminSites, roles, rgsMetadata, shiftReasons, services],
  );

  // Remove invalid selected items and select default items
  useEffect(() => {
    if (filterConfig) validateAndInitialiseFilterItems(filterConfig, selectedFilterItems, (filterKey, items) => setSelectedItems(namespace, filterKey, items));
  }, [filterConfig]);


  return [filterConfig, selectedFilterItems, setSelectedItems];
}
