// NOTE: all validators should return null if field is valid, or text to show if input is invalid

export const requiredField = (value) => {
  // trap error where valid input of 0 is falsy and wrongly shows validation error
  const val = value.toString();
  return !!val || (Array.isArray(val) && value.length > 0) ? null : 'Required';
};

export const emailValidator = (value) => {
  const val = value.toString();
  const rgx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return !!val && rgx.test(val) ? null : 'This email address doesn\'t look valid';
};
