import React, { useState, CSSProperties } from 'react';
import { firstBy } from 'thenby';

import { FilterBarV2 } from 'components/FilterBar';
import Modal from 'components/Modal';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { StaffList } from 'types/Messaging';

interface Styles {
  [Key: string]: CSSProperties;
}

const styles: Styles = {
  listLink: {
    paddingLeft: 12,
    color: colors.positive,
  },
  staffList: {
    flex: '1 1 auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  staffName: {
    color: colors.text,
    lineHeight: 1.4,
    fontSize: '0.9rem',
  },
  staffRole: {
    color: '#999',
    fontSize: '0.85rem',
    lineHeight: 1.4,
  },
};


interface IndividualToInputProps {
  staffList: StaffList[] | null,
  fetchStaffListError: string | null,
  retryFetchSendListStaff: () => void,
}

export default function IndividualToInput(props: IndividualToInputProps) : React.ReactElement {

  const [staffListModalIsOpen, setStaffListModalIsOpen] = useState(false);

  const staffNumber = (props.staffList ?? []).length;
  const showLinkToStaff = staffNumber > 0;

  return (
    <div className="messagingFromToContainer" style={{ paddingBottom: 6 }}>
      <p style={{ fontSize: '1rem', fontWeight: 600 }}>To:</p>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <FilterBarV2 namespace="composeBatchMessage" filterConfigKey="enabledMessagingFilters" showLabel={false} />
        <p style={{ fontSize: '0.9rem', lineHeight: 1, color: colors.text, fontWeight: 500, paddingLeft: 6 }}>
          {staffNumber} staff
          {showLinkToStaff && (
            <span
              role="button"
              tabIndex={0}
              className="messagingStaffListLink"
              style={styles.listLink}
              onClick={() => setStaffListModalIsOpen(true)}
            >
              See full list
            </span>
          )}
          {props.fetchStaffListError && (
            <span style={{ paddingLeft: spacing.base, color: colors.red }}>
              {props.fetchStaffListError}
              <span
                onClick={() => props.retryFetchSendListStaff()}
                style={{ paddingLeft: 3, textDecoration: 'underline' }}
                className="messagingStaffListLink"
              >
                Retry
              </span>
            </span>
          )}
        </p>
      </div>
      {staffListModalIsOpen && <StaffListModal onClose={() => setStaffListModalIsOpen(false)} staffList={props.staffList} />}
    </div>
  );
}

interface StaffListModalProps {
  staffList: StaffList[] | null,
  onClose: () => void,
}

function StaffListModal(props: StaffListModalProps) : React.ReactElement {
  return (
    <Modal
      isOpen
      onRequestClose={props.onClose}
      header="Staff List Matching Filters"
      contentStyle={{ minWidth: 400, maxHeight: '80vh' }}
      bodyStyle={{ padding: spacing.base }}
      vflex
    >
      <div style={styles.staffList}>
        {(props.staffList ?? []).sort(firstBy('candidateName')).map(staff => (
          <div style={{ marginBottom: 6 }}>
            <p key={staff.candidateKey} style={styles.staffName}>{staff.candidateName}</p>
            <p key={staff.candidateKey} style={styles.staffRole}>{staff.role}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
}

