import moment from 'moment-timezone';
import { mapValues, groupBy } from 'lodash-es';
import { firstBy } from 'thenby';

import { PublishedShiftSummary, TemplateShift, BookedCandidate } from 'types/ShiftTypes';

interface GsMetadata {
  gradesMetadata: { [key:string]: any };
  specialitiesMetadata: { [key:string]: any };
}

type ShiftSummaryGroupByKey = Exclude<keyof PublishedShiftSummary, 'candidateBanks' | 'releasedBanks' | 'suppliers'>;

export interface Grouper<Meta> {
  groupBy: ShiftSummaryGroupByKey | ((shift: PublishedShiftSummary, metadata: Meta) => string | number | boolean | undefined | null);
  getLabel?: (shift: PublishedShiftSummary, metadata: Meta) => string;
  sortBy?: (shift: PublishedShiftSummary, metadata: Meta) => string | number;
}

export interface Group {
  key: string | number | boolean | undefined | null | BookedCandidate[],
  label: string | number | boolean | undefined | null | BookedCandidate[],
  shifts: Array<PublishedShiftSummary>,
}

function getAMorPM(shift: PublishedShiftSummary) : string {
  return moment(shift.startTime).tz(shift.timezone).format('A');
}

function getAMorPMorLate(shift: PublishedShiftSummary) : string {
  const hour = moment(shift.startTime).tz(shift.timezone).hour();
  if (hour < 12) return 'Morning';
  if (hour < 17) return 'Afternoon';
  return 'Late';
}


export function smartGroup<Meta = undefined>(shiftList: Array<PublishedShiftSummary | TemplateShift>, grouper: Grouper<Meta>, metadata: Meta) : Array<Group> {

  const grouperFunction = typeof grouper.groupBy === 'function' ? grouper.groupBy : (shift: PublishedShiftSummary) => shift[grouper.groupBy as ShiftSummaryGroupByKey];
  const sortFunction = grouper.sortBy ?
    firstBy(((group: Group) => grouper.sortBy?.(group.shifts[0], metadata)))
    :
    firstBy((group: Group) => group.label);

  return Object.values(
    mapValues(
      groupBy(shiftList, grouperFunction) as { [key:string]: Array<PublishedShiftSummary> },
      (shifts: Array<PublishedShiftSummary>) : Group => ({
        key: grouperFunction(shifts[0], metadata),
        label: (grouper.getLabel ?? grouperFunction)(shifts[0], metadata),
        shifts,
      }),
    ),
  ).sort(sortFunction);
}

export const SHIFT_GROUPING : { [name:string]: Grouper<GsMetadata> } = {
  date: {
    groupBy: (shift: PublishedShiftSummary) => moment(shift.startTime).tz(shift.timezone).format('YYYY-MM-DD'),
    getLabel: (shift: PublishedShiftSummary) => moment(shift.startTime).tz(shift.timezone).format('ddd Do MMM YYYY'),
    sortBy: (shift: PublishedShiftSummary) => shift.startTime.slice(0, 10),
  },
  timeWithLates: {
    groupBy: getAMorPMorLate,
    getLabel: getAMorPMorLate,
    sortBy: (shift: PublishedShiftSummary) => shift.startTime.slice(11),
  },
  site: {
    groupBy: (shift: PublishedShiftSummary) => shift.siteKey,
    getLabel: (shift: PublishedShiftSummary) => shift.siteName as string,
  },
  siteAndTime: {
    groupBy: (shift: PublishedShiftSummary) => shift.siteKey + getAMorPM(shift),
    getLabel: (shift: PublishedShiftSummary) => `${shift.siteName} (${getAMorPM(shift)})`,
  },
  siteAndArea: {
    groupBy: (shift: PublishedShiftSummary) => shift.siteKey as string + shift.areaKey as string,
    getLabel: (shift: PublishedShiftSummary) => `${shift.siteName} / ${shift.areaName ?? 'No Area'}`,
  },
  area: {
    groupBy: (shift: PublishedShiftSummary) => shift.areaKey ?? '__NONE__',
    getLabel: (shift: PublishedShiftSummary) => shift.areaName ?? 'No Area',
  },
  role: {
    groupBy: (shift: PublishedShiftSummary) => shift.roleKey,
    getLabel: (shift: PublishedShiftSummary) => shift.roleName as string,
  },
  roleAndTime: {
    groupBy: (shift: PublishedShiftSummary) => shift.roleKey as string + getAMorPM,
    getLabel: (shift: PublishedShiftSummary) => `${shift.roleName} | ${getAMorPM(shift)}`,
  },
  grade: {
    groupBy: 'gradeKey',
    getLabel: (shift: PublishedShiftSummary) => shift.gradeName as string,
    sortBy: (shift: PublishedShiftSummary, metadata: GsMetadata) => (shift.gradeKey && metadata.gradesMetadata[shift.gradeKey]?.rank) ?? 0,
  },
  speciality: {
    groupBy: 'specialityKey',
    getLabel: (shift: PublishedShiftSummary) => shift.specialityName as string,
    sortBy: (shift: PublishedShiftSummary, metadata: GsMetadata) => (shift.specialityKey && metadata.specialitiesMetadata[shift.specialityKey]?.order) ?? 0,
  },
  candidate: {
    groupBy: (shift: PublishedShiftSummary) => shift.candidateKey ?? '__NONE__',
    getLabel: (shift: PublishedShiftSummary) => shift.candidateName ?? 'Unbooked',
  },
};

