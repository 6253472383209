const FETCH_INVITE = 'FETCH_INVITE';
const FETCH_INVITE_SUCCESS = 'FETCH_INVITE_SUCCESS';
const FETCH_INVITE_ERROR = 'FETCH_INVITE_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';
const CLEAR_INVITE_DATA = 'CLEAR_INVITE_DATA';

export function fetchInvite() {
  return {
    type: FETCH_INVITE,
  };
}
export function fetchInviteSuccess(data) {
  return {
    type: FETCH_INVITE_SUCCESS,
    data,
  };
}
export function fetchInviteError(error) {
  return {
    type: FETCH_INVITE_ERROR,
    error,
  };
}
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}
export function clearInviteData() {
  return {
    type: CLEAR_INVITE_DATA,
  };
}
const initialState = {
  email: null,
  firstName: null,
  surname: null,
  permissions: {},
  sites: {},
  isFetchingInvite: false,
  empOrgKey: null,
  error: null,
};

export default function registration(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVITE:
      return {
        ...state,
        isFetchingInvite: true,
      };
    case FETCH_INVITE_SUCCESS:
      return {
        ...state,
        isFetchingInvite: false,
        email: action.data.email,
        firstName: action.data.firstName,
        surname: action.data.surname,
        permissions: action.data.permissions,
        sites: action.data.sites,
        empOrgKey: action.data.empOrgKey,
        error: null,
      };
    case FETCH_INVITE_ERROR:
      return {
        ...state,
        isFetchingInvite: false,
        error: true,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_INVITE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
