import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import colors from 'config/colors';

import './FilterBox.css';

const { string, func, object } = PropTypes;

const filterBoxStyles = {
  container: {
    position: 'relative',
  },
  input: {
    position: 'relative',
    width: 200,
    padding: '6px 34px 6px 15px',
    borderRadius: '2px',
    border: '1px solid #CCC',
    outline: 'none',
    fontSize: 'smaller',
    marginRight: 6,
  },
  clearButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: colors.text,
    outline: 'none',
  },
};

const FilterBox = ({ filterString, setFilterString, placeholder, style }) => {
  return (
    <div style={filterBoxStyles.container}>
      <input
        className="bankSearchBoxInput"
        style={{ ...filterBoxStyles.input, ...style }}
        placeholder={placeholder}
        value={filterString}
        onChange={(event) => { setFilterString(event.target.value); }}
      />
      <span
        className="bankSearchBoxClearButton rise"
        style={{ ...filterBoxStyles.clearButton, visibility: filterString ? 'visible' : 'hidden' }}
        role="button"
        onKeyPress={event => event.keyCode === 13 && setFilterString('')}
        tabIndex={0}
        onClick={() => setFilterString('')}
      >
        <MdClose size={16} />
      </span>
    </div>
  );
};
FilterBox.propTypes = {
  filterString: string,
  setFilterString: func,
  placeholder: string,
  style: object,
};
FilterBox.defaultProps = {
  filterString: '',
  setFilterString: () => {},
  placeholder: '',
  style: {},
};
export default FilterBox;
