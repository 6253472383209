import React from 'react';
import PropTypes, { arrayOf, bool } from 'prop-types';
import DropDown from 'components/DropDown';

import colors from 'config/colors';

const styles = {
  filterFont: {
    color: colors.text,
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
    paddingBottom: 3,
  },
};

export default function DropDownFilter(props) {
  return (
    <>
      <p style={styles.filterFont}>{props.title}</p>
      <DropDown
        placeholder={props.placeholder}
        options={props.options}
        defaultValue={props.defaultValue ?? props.options?.[0]}
        value={props.value}
        onChange={inputValue => props.onChange(inputValue)}
        isLoading={props.loading}
        customStyles={props.customStyles}
        menuPosition="fixed"
        isDisabled={props.isDisabled}
        height={props.height}
      />
    </>
  );
}

const { shape, string, number, objectOf, oneOfType, object, func } = PropTypes;

DropDownFilter.propTypes = {
  title: string.isRequired,
  placeholder: string.isRequired,
  options: arrayOf(object).isRequired,
  value: object,
  loading: bool,
  customStyles: shape({
    control: objectOf(oneOfType([number, string])),
    valueContainer: objectOf(oneOfType([number, string])),
    menuList: objectOf(oneOfType([number, string])),
    option: objectOf(oneOfType([number, string])),
  }),
  onChange: func.isRequired,
};

DropDownFilter.defaultProps = {
  loading: false,
  customStyles: {
    control: {},
    valueContainer: {},
    menuList: {},
    option: {},
    menu: {},
  },
};
