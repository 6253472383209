import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import { Moment } from 'moment';

import colors from 'config/colors';

import { parseTime } from './parse-time';

const styles = {
  input: {
    boxSizing: 'border-box' as CSSProperties['boxSizing'],
    width: '50%',
    fontSize: '0.9rem',
    color: colors.text,
    border: `1px solid ${colors.cavalry.line}`,
    padding: '9px 12px',
    outline: 'none',
    // height: 30,
  },
};

interface SingleTimePickerProps {
  time: Moment;
  onTimeChange: (hours: number | null, minutes: number | null) => void;
  nextAfter?: Moment;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean
}

const TIME_FORMAT_AM_PM = 'h:mm a';
const TIME_FORMAT_24_HOUR = 'HH:mm';

function SingleTimePicker(props: SingleTimePickerProps) : JSX.Element {

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(props.time?.format(TIME_FORMAT_24_HOUR));

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => setInputValue(props.time?.format(TIME_FORMAT_24_HOUR)), [props.time, isEditing]);

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder={props.placeholder}
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      onFocus={e => { inputRef.current?.select(); setTimeout(() => setIsEditing(true), 300); }}
      onMouseUp={e => { if (!isEditing) inputRef.current?.select(); }}
      onBlur={(e) => {
        setIsEditing(false);
        const time = parseTime(e.target.value, props.nextAfter);
        props.onTimeChange(time?.hours ?? props.time.hours(), time?.minutes ?? props.time.minutes());
      }}
      style={{ ...styles.input, ...props.style }}
      disabled={props.disabled}
    />
  );
}

SingleTimePicker.defaultProps = {
  nextAfter: undefined,
  placeholder: undefined,
  style: undefined,
  disabled: false,
};

export default SingleTimePicker;
