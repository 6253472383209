import React from 'react';

const FieldLabel = (props: { label: string, subLabel?: string, children: React.ReactNode }) : React.ReactElement => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 6 }}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: '0 0 90px' }}>
        <p style={{ color: '#999', fontSize: '0.9rem', fontWeight: 500, lineHeight: 1.3 }}>
          {props.label}
        </p>
        {props.subLabel && (
          <p style={{ color: '#999', fontSize: '0.9rem', fontWeight: 500, lineHeight: 1.3 }}>
            {props.subLabel}
          </p>
        )}
      </div>
      <div style={{ flex: 1 }}>
        {props.children}
      </div>
    </div>
  );
};

FieldLabel.defaultProps = { subLabel: undefined };

export default FieldLabel;
