import { useMemo, useCallback, useEffect, useState } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';
import moment from 'moment-timezone';
import { keyBy } from 'lodash-es';

import { shiftFieldMappings, ShiftMappings } from 'config/shiftFieldMappings';
import { findApplicableRateCards } from 'lib/helpers-metadata';
import { convertFromSelect } from 'lib/helpers';
import * as globalThunks from 'thunks/global';
import * as createShiftThunks from 'thunks/createShifts';
import { fetchTemplateList, fetchTemplate as fetchTemplateDetails } from 'thunks/templates';
import * as createShiftReducer from 'reducers/createShifts';
import * as templateReducer from 'reducers/templates';
import * as jobsReducer from 'reducers/jobs';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { getTemplateMandatoryFields } from 'routes/Jobs/helpers';

import { TemplateDraftShift, TemplateShift } from 'types/ShiftTypes';
import { Sites } from 'types/Sites';

export function mapDraftShapeToTemplateShiftShape(key: string, draft: TemplateDraftShift, adminSites: Sites, orgTimezone: string): TemplateShift {

  const timezone = draft.site?.id && adminSites[draft.site?.id]?.timezone ? adminSites[draft.site.id].timezone : orgTimezone;
  const bookedCandidates = draft.candidates.map(candidate => ({ candidateKey: candidate.id, candidateName: candidate.name }));

  return {
    key,
    serviceKey: draft.service?.id ?? null,
    serviceName: draft.service?.name ?? null,
    siteKey: draft.site?.id ?? null,
    siteName: draft.site?.name ?? null,
    areaKey: draft.area?.id ?? null,
    areaName: draft.area?.name ?? null,
    roleKey: draft.role?.id ?? null,
    roleName: draft.role?.name ?? null,
    gradeKey: draft.grade?.id ?? null,
    gradeName: draft.grade?.name ?? null,
    specialityKey: draft.speciality?.id ?? null,
    specialityName: draft.speciality?.name ?? null,
    reasonKey: draft.reason?.id ?? null,
    reasonName: draft.reason?.name ?? null,
    subReasonKey: draft.reason2?.id ?? null,
    subReasonName: draft.reason2?.name ?? null,
    costCentreKey: draft.costCentre?.code ?? null,
    preferredGenderKey: draft.preferredGender?.id ?? null,
    publicDescription: draft.publicDescription ?? null,
    customHourlyRate: draft.customHourlyRate ?? null,
    privateNotes: draft.privateNotes ?? null,
    releasedBanks: draft.bankKeys,
    startTimeOfDay: draft.startTimeOfDay,
    endTimeOfDay: draft.endTimeOfDay,
    dayOfWeek: draft.dayOfWeek?.id ?? null,
    slotsRequired: draft.slotsRequired,
    bookedCandidates,
    isValid: draft.isValid,
    statusError: draft.status ?? null,
    response: draft.response ?? null,
    isDraft: true,
    timezone,
  };
}

interface CurrentTemplate {
  [keyt: string]: TemplateDraftShift,
}

export function useTemplateShifts() {

  const dispatch = useAppDispatch();

  const templateShifts: CurrentTemplate = useSelector(state => state.createShifts.templateShifts);
  const adminSites = useSelector(state => state.user.sites);
  const orgTimezone = useSelector(state => state.global.orgConfig?.timezone);

  // Clear selected shifts on first render
  useEffect(() => {
    dispatch(jobsReducer.unselectAllShifts());
  }, []);

  return useMemo(() => {

    const shifts = Object.entries(templateShifts ?? []).map(([key, shift]) => mapDraftShapeToTemplateShiftShape(key, shift, adminSites, orgTimezone));

    return {
      shifts,
      keyedShifts: keyBy(shifts, 'key'),
    };
  }, [templateShifts, adminSites, orgTimezone]);
}

function computeISOStringsFromTimesOfDay(startTimeOfDay: string, endTimeOfDay: string) {
  const [startHour, startMinute] = startTimeOfDay.split(':').map(time => parseInt(time, 10));
  const [endHour, endMinute] = endTimeOfDay.split(':').map(time => parseInt(time, 10));

  // Set date to 1st Jan (non daylight saving time)
  const currentYear = moment.utc().year();
  const startMoment = moment.utc().year(currentYear).month(0).startOf('month')
    .hours(startHour)
    .minutes(startMinute);
  const endMoment = moment.utc().year(currentYear).month(0).startOf('month')
    .hours(endHour)
    .minutes(endMinute);

  const startTimeEndTimeDiffInMinutes = endMoment.diff(startMoment, 'minutes');
  if (startTimeEndTimeDiffInMinutes <= 0) endMoment.add(1, 'days');

  return [startMoment.toISOString(), endMoment.toISOString()];
}

function checkForMissingFields(mandatoryFields: string[], templateShiftFields: TemplateDraftShift, mappings: ShiftMappings) {
  return mandatoryFields.filter((field: string) => {
    // Return null if costCentre. This is calculated under the hood and not manually entered by the user
    if (field === 'costCentre') return false;

    const shiftFieldMapping = shiftFieldMappings[field];
    const fieldValue = templateShiftFields[shiftFieldMapping?.key ?? field];

    return Array.isArray(fieldValue) ? !fieldValue.length : !fieldValue;
  });
}

export function useTemplateShiftFields(templateShiftKey: string) {

  const dispatch = useAppDispatch();

  // Get metadata from Redux
  const shiftMandatoryFields = useSelector(({ global }) => global.orgConfig?.shiftMandatoryFields);
  const shiftCreateMetadata = useSelector(({ global }) => global.shiftCreateMetadata);
  const orgRateCards = useSelector(({ global }) => global.shiftCreateMetadata?.orgRateCards ?? []);
  const currentOrgKey = useSelector(({ global }) => global.currentOrgKey);
  const sites = useSelector(({ user }) => user.sites);
  const template = useSelector(({ templates }) => templates.template);
  const templateEdited = useSelector(({ templates }) => templates.templateEdited);

  // Template shift fields from Redux
  const templateShifts: CurrentTemplate = useSelector(state => state.createShifts.templateShifts);
  const templateShiftFields = templateShifts[templateShiftKey] ?? {};
  const templateMandatoryFields = getTemplateMandatoryFields(shiftMandatoryFields, template?.periodType);

  const { startTimeOfDay, endTimeOfDay, site, area, role, grade, speciality, reason, reason2, service } = templateShiftFields;

  // Populate form fields
  useEffect(() => {
    // Fetch shiftCreateMetadata if it doesn't already exist
    if (!shiftCreateMetadata) dispatch(globalThunks.fetchShiftCreateMetadata());

    dispatch(createShiftThunks.populateShiftFormData(templateShiftKey, 'templateShifts'));
  }, [templateShiftKey]);

  // Find rate cards that match shift specification
  const applicableRateCards = useMemo(() => {
    return findApplicableRateCards(orgRateCards, role?.id ?? null, speciality?.id ?? null, grade?.id ?? null, site?.id ?? null, area?.id ?? null, reason?.id ?? null, reason2?.id ?? null, service?.id ?? null, currentOrgKey);
  }, [orgRateCards, templateShiftKey, role?.id, grade?.id, speciality?.id, site?.id, area?.id]);

  const missingMandatoryFields: string[] = useMemo(() => checkForMissingFields(templateMandatoryFields, templateShiftFields, shiftFieldMappings), [templateShiftFields, templateMandatoryFields]);

  const [startTimeAsISOString, endTimeAsISOString] = useMemo(() => {
    return computeISOStringsFromTimesOfDay(startTimeOfDay ?? '00:00', endTimeOfDay ?? '00:00');
  }, [templateShiftKey, startTimeOfDay, endTimeOfDay]);

  const timezone = sites[site?.id]?.timezone ?? 'Europe/London';

  const updateTemplateShiftField = useCallback((field: string, value) => {
    dispatch(createShiftThunks.updateShiftProp(templateShiftKey, field, value, 'templateShifts'));
    if (!templateEdited) dispatch(templateReducer.setTemplateEdited());
  }, [templateShiftKey]);

  // Form field component callbacks
  const onChangeSite = useCallback(data => updateTemplateShiftField('site', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeService = useCallback(data => updateTemplateShiftField('service', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeArea = useCallback(data => updateTemplateShiftField('area', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeStartTimeOfDay = useCallback((hours, minutes) => {
    if (hours !== null && minutes !== null) updateTemplateShiftField('startTimeOfDay', { hours, minutes });
  }, [templateShiftKey, updateTemplateShiftField]);
  const onChangeEndTimeOfDay = useCallback((hours, minutes) => {
    if (hours !== null && minutes !== null) updateTemplateShiftField('endTimeOfDay', { hours, minutes });
  }, [templateShiftKey, updateTemplateShiftField]);
  const onChangeDayOfWeek = useCallback(data => updateTemplateShiftField('dayOfWeek', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeRole = useCallback(data => updateTemplateShiftField('role', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeGrade = useCallback(data => updateTemplateShiftField('grade', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeSpeciality = useCallback(data => updateTemplateShiftField('speciality', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeGender = useCallback(data => updateTemplateShiftField('preferredGender', data), [templateShiftKey, updateTemplateShiftField]);
  const onChangeReason = useCallback(data => updateTemplateShiftField('reason', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangeSubReason = useCallback(data => updateTemplateShiftField('reason2', convertFromSelect(data)), [templateShiftKey, updateTemplateShiftField]);
  const onChangePublicDescription = useCallback(text => updateTemplateShiftField('publicDescription', text.target?.value ?? null), [templateShiftKey, updateTemplateShiftField]);
  const onChangePrivateNotes = useCallback(text => updateTemplateShiftField('privateNotes', text.target?.value ?? null), [templateShiftKey, updateTemplateShiftField]);
  const onChangeRateCard = useCallback(data => updateTemplateShiftField('rateCard', applicableRateCards.find(rc => rc.key === data.value)), [templateShiftKey, applicableRateCards, updateTemplateShiftField]);
  const onChangeHourlyRate = useCallback(data => updateTemplateShiftField('customHourlyRate', data), [templateShiftKey, updateTemplateShiftField]);

  const onChangeBanks = useCallback((bankKeys) => {
    updateTemplateShiftField('bankKeys', bankKeys);
  }, [templateShiftKey, templateShiftFields?.bankKeys, updateTemplateShiftField]);

  const onChangeSlotsRequired = useCallback(slotsRequired => updateTemplateShiftField('slotsRequired', slotsRequired), [templateShiftKey, templateShiftFields.slotsRequired, updateTemplateShiftField]);
  const onChangeCandidates = useCallback(candidates => updateTemplateShiftField('candidates', candidates), [templateShiftKey, updateTemplateShiftField]);

  return {
    fields: templateShiftFields,
    startTimeAsISOString,
    endTimeAsISOString,
    timezone,
    missingMandatoryFields,
    applicableRateCards,
    onChangeSite,
    onChangeArea,
    onChangeStartTimeOfDay,
    onChangeEndTimeOfDay,
    onChangeDayOfWeek,
    onChangeSlotsRequired,
    onChangeRole,
    onChangeGrade,
    onChangeSpeciality,
    onChangeGender,
    onChangeReason,
    onChangeSubReason,
    onChangePublicDescription,
    onChangePrivateNotes,
    onChangeRateCard,
    onChangeCandidates,
    onChangeBanks,
    onChangeService,
    onChangeHourlyRate,
  };
}

function snapDateToStartOfPeriod(date: moment.Moment, periodType: 'day' | 'week' | 'month') : moment.Moment {
  if (periodType === 'week') return date.clone().startOf('isoWeek');
  if (periodType === 'month') return date.clone().startOf('month');
  return date.clone();
}

export function useApplyTemplateFields(defaultTemplateKey: string | undefined, defaultDate: moment.Moment, goToPeriodView: (date: moment.Moment, duration: string) => void, periodDuration: string) {

  const dispatch = useAppDispatch();
  const shiftCreateMetadata = useSelector(({ global }) => global.shiftCreateMetadata);
  const templateList = useSelector((state) => state.templates.templateList);
  const template = useSelector((state) => state.templates.template);
  const selectedDate = useSelector((state) => state.createShifts.applyTemplateToDate);
  const timezone = useSelector(state => state.global.orgConfig?.timezone);

  const [isFetchingTemplatesMetadata, setIsFetchingTemplatesMetadata] = useState(false);


  useEffect(() => {
    return () => {
      dispatch(createShiftReducer.clearApplyTemplate());
      dispatch(templateReducer.clearTemplate());
      dispatch(createShiftReducer.clearTemplateShifts());
    };
  }, []);

  const fetchTemplatesData = async () => {

    setIsFetchingTemplatesMetadata(true);
    // Fetch template list if not already in Redux
    if (!templateList) await dispatch(fetchTemplateList());

    // If no template exists -
    if (!template) {

      // Use defaultTemplateKey (from URL)
      if (defaultTemplateKey) {
        await fetchTemplate(defaultTemplateKey);

      // Or if there is template list, pick first from list
      } else if (templateList?.length) {
        const defaultTemplateKeyFromList = templateList[0].key;
        await fetchTemplate(defaultTemplateKeyFromList);
      }
    }
    setIsFetchingTemplatesMetadata(false);
  };

  useEffect(() => {
    if (!templateList || !template) fetchTemplatesData();
  }, [templateList, template?.key]);

  useEffect(() => {
    // Set initial applyTemplateToDate if it doesn't exist
    if (!selectedDate && template?.periodType) {
      const dateSnappedToStartOfPeriod = snapDateToStartOfPeriod(defaultDate, template.periodType);
      dispatch(createShiftReducer.applyTemplateToDate(dateSnappedToStartOfPeriod));
    }
  }, [template?.key, defaultDate]);

  const fetchTemplate = async (templateKey: string) => {
    if (!shiftCreateMetadata) await dispatch(globalThunks.fetchShiftCreateMetadata());
    dispatch(fetchTemplateDetails(templateKey));
  };

  // Update period view if selectedDate changes
  useEffect(() => {
    if (selectedDate && template) goToPeriodView(selectedDate, template.key);
  }, [selectedDate?.valueOf(), template?.key]);

  const onChangeTemplate = useCallback((data: { value: string, label: string }) => dispatch(fetchTemplateDetails(data.value)), []);

  const onChangeDate = useCallback((date: moment.Moment | null) => {

    if (date && template?.periodType && selectedDate) {

      let tzDate = convertFromUtcToTimezone(date.toISOString(), timezone);
      const newAndPreviousDateDiff = tzDate.diff(selectedDate, 'days');

      // If new date has increased by 1 day, add a week or month depending on periodType
      if ((template.periodType === 'month' || template.periodType === 'week') && newAndPreviousDateDiff === 1) {
        tzDate = tzDate.clone().add(1, template.periodType);
      }

      // Snap new date to start of period
      const dateSnappedToStartOfPeriod = snapDateToStartOfPeriod(tzDate, template.periodType);
      dispatch(createShiftReducer.applyTemplateToDate(dateSnappedToStartOfPeriod));
    }
  }, [timezone, template?.periodType, selectedDate?.valueOf()]);

  const isOutsideRange = useCallback((date: moment.Moment) => {

    if (!template?.periodType || template?.periodType === 'day') return false;

    // Compare date against start of period
    const tzDate = convertFromUtcToTimezone(date.toISOString(), timezone);
    const dateSnappedToStartOfPeriod = snapDateToStartOfPeriod(tzDate, template.periodType);
    return !tzDate.isSame(dateSnappedToStartOfPeriod, 'day');

  }, [template?.periodType]);

  return useMemo(() => ({
    isFetchingTemplatesMetadata,
    templateList,
    template: template ? ({ id: template.key, name: template.name }) : null,
    templatePeriod: template?.periodType ?? null,
    date: selectedDate ? selectedDate.toISOString() : null,
    timezone,
    onChangeTemplate,
    onChangeDate,
    isOutsideRange,
  }), [template?.key, selectedDate?.valueOf(), templateList, timezone, onChangeTemplate, onChangeDate, isOutsideRange, isFetchingTemplatesMetadata]);
}
