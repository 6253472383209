import React, { CSSProperties } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import Button from 'components/Button';

import fonts from 'config/fonts';
import colors from 'config/colors';

interface Styles {
  [Key: string]: CSSProperties;
}


const styles: Styles = {
  screenContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    borderColor: colors.cavalry.line,
    borderWidth: 1,
    borderStyle: 'solid',
    width: '700px',
    borderRadius: 6,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    padding: 24,
  },
  textContainer: {
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: fonts.headerMain.size,
    color: colors.text,
    fontWeight: fonts.headerMain.weight,
  },
  message: {
    color: colors.text,
    lineHeight: 1.4,
    fontSize: '0.9rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTopColor: colors.accent,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    padding: 12,
  },
};

interface ErrorScreenProps {
  title?: string,
  message?: string,
  dismiss?: () => void,
  retry?: () => void,
}

export default function ErrorScreen(props: ErrorScreenProps) : React.ReactElement {
  return (
    <div style={styles.screenContainer}>
      <div style={styles.errorContainer}>
        <div style={styles.contentContainer}>
          <div style={{ padding: 12 }}>
            <MdErrorOutline color={colors.cavalry.error} size={140} />
          </div>
          <div className="space-children-12--bottom" style={styles.textContainer}>
            <h1 style={styles.title}>{props.title}</h1>
            <p style={styles.message}>{props.message}</p>
          </div>
        </div>
        <div style={styles.buttonContainer}>
          {!!props.dismiss && <Button white outline onClick={props.dismiss}>Dismiss</Button>}
          {!!props.retry && <Button style={{ marginLeft: 12, paddingLeft: 24, paddingRight: 24 }} onClick={props.retry}>Retry</Button>}
        </div>
      </div>
    </div>
  );
}

ErrorScreen.defaultProps = {
  title: 'Oops! Something went wrong.',
  message: 'Check your network connectivity and try again. If the problem persists, please contact technical support.',
};
