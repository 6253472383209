import React from 'react';
import DropDown from 'components/DropDown';
import { CWFilterSelect } from 'components/CWSelect';

import colors from 'config/colors';

import { CustomFieldMetadata } from '../../types/CustomFields';

const styles = {
  input: {
    flex: '1 1 0px',
    fontSize: 16,
    color: colors.textInput,
    border: '1px solid #CCC',
    borderRadius: 2,
    padding: '3px 6px',
    outline: 'none',
  },
};

interface EditCustomFieldProps {
  fieldIsValid: boolean,
  field: CustomFieldMetadata,
  onChange: ((value: string) => void) | ((value: string[]) => void),
  setDefaultValue: boolean,
  value: string | string[],
  isDisabled: boolean
}

const EditCustomField : React.FC<EditCustomFieldProps> = (props: EditCustomFieldProps) => {
  const { field } = props;

  switch (field.type) {
    case 'text': {
      const onChange = props.onChange as ((value: string) => void);
      return (
        <input
          style={{ ...styles.input, border: `1px solid ${props.fieldIsValid ? '#CCC' : colors.red}` }}
          value={props.value as string}
          onChange={event => onChange(event.target.value)}
          type="text"
        />
      );
    }
    case 'single-select': {
      const onChange = props.onChange as ((value: string) => void);
      const options = field.parameters?.options?.map(({ name: label, value }) => ({ value, label })) ?? [];

      return (
        <div style={{ flex: '1 1 0px' }}>
          <DropDown
            defaultValue={props.setDefaultValue ? options?.[0] : null}
            options={options}
            customStyles={{ menuPortal: { zIndex: 1000000 }, option: { height: null } }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            portalPlacement="bottom"
            onChange={option => onChange(option.value)}
            isDisabled={props.isDisabled}
          />
        </div>
      );
    }
    case 'multi-select': {
      const onChange = props.onChange as ((value: string[]) => void);
      const options = field.parameters?.options?.map(({ name: label, value }) => ({ value, label })) ?? [];

      return (
        <div style={{ flex: '1 1 0px' }}>
          <CWFilterSelect
            noneSelectedLabel="None Selected"
            items={options}
            selectedItems={props.value as string[]}
            setSelectedItems={onChange}
          />
        </div>
      );
    }
    default:
      return null;
  }
};

export default EditCustomField;

