import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';

import { convertToSelectArray } from 'lib/helpers';

import { Reason } from 'types/Metadata';

import FieldLabel from './FieldLabel';
import JobsSelectBox from '../Adhoc/JobsSelectBox';

interface SubReasonFieldProps {
  onChange: (val: { value: string, label: string }) => void,
  subReason: { id: string, name: string } | null,
  reasonKey?: string,
  mandatory?: boolean,
}

function SubReasonField(props: SubReasonFieldProps) {

  const reasons = useSelector(({ global }) => global.shiftCreateMetadata?.orgShiftReasons ?? []);
  const selectedReason = reasons.find((reason: Reason) => reason.id === props.reasonKey);

  const subReasons = useMemo(() => {
    const selectedReasonSubReasons = selectedReason?.subreasons ?? {};
    return convertToSelectArray(selectedReasonSubReasons, null, true).sort(firstBy('label'));
  }, [selectedReason]);

  return (
    <FieldLabel label="Subreason">
      <JobsSelectBox
        name="reason2"
        value={props.subReason}
        placeholder="Sub-reason (optional)"
        data={subReasons}
        onChange={props.onChange}
        clearable
        mandatory={props.mandatory}
        isDisabled={!subReasons || subReasons.length === 0}
      />
    </FieldLabel>
  );
}

SubReasonField.defaultProps = { reasonKey: undefined, mandatory: false };

export default React.memo(SubReasonField);
