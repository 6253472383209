import { subscribe, gql } from './apollo';
import { Api } from '.';

const reporting = new Api('reports');

export function watchReport({ reportKey, callback, error }) {
  return subscribe({
    query: gql`
      subscription watchGeneratedReport($reportKey: String!) {
        reporting_generated_reports(where: {key: {_eq: $reportKey}}) {
          status,
          error
        }
      }
    `,
    variables: { reportKey },
    map: res => res?.data?.reporting_generated_reports?.[0], // eslint-disable-line camelcase
    callback,
    error,
  });
}

export async function listExportableReports() {
  const response = await reporting.get('export/list');
  if (response.status !== 200) throw new Error('Error fetching reports');
  return response.body.exportableReports;
}
