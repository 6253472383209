import React, { useCallback, CSSProperties } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { isTruthy } from 'lib/helpers-ts';
import { useFeatureFlag, usePermission } from 'hooks/feature';
import PageHeader from 'components/PageHeader';
import { TabBar } from 'components/Tabs';
import colors from 'config/colors';

const styles : { [key:string]: CSSProperties} = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: 12,
    marginBottom: 6,
    paddingRight: 0,
    position: 'relative',
  },
};

type SettingsTabKey = 'admins' | 'suppliers' | 'sites' | 'rgs' | 'payment-rates' | 'admin-groups';
interface SettingsHeaderProps {
  selectedTabKey: SettingsTabKey;
}

const SettingsHeader = (props: SettingsHeaderProps) : JSX.Element => {

  const history = useHistory();
  const isMobile = useSelector(state => state.screen.isMobile);

  const showOrgDetails = useFeatureFlag('orgDetails', 'orgCanEditDetails');
  const showSuppliers = useFeatureFlag('vms');
  const showPaymentRates = useFeatureFlag('paymentRuns', 'payRunCanView');
  const showAdminGroups = usePermission('usersCanView');

  const tabs = [
    { key: 'admins', title: 'Admins', route: '/settings/admins' },
    showAdminGroups && { key: 'admin-groups', title: 'Admin Groups', route: '/settings/admin-groups' },
    showSuppliers && { key: 'suppliers', title: 'Suppliers', route: '/settings/suppliers' },
    showOrgDetails && { key: 'sites', title: 'Sites', route: '/settings/sites' },
    showOrgDetails && { key: 'rgs', title: isMobile ? 'RGS' : 'Roles, Grades & Specialities', route: '/settings/rgs' },
    showPaymentRates && { key: 'payment-rates', title: 'Payment Rates', route: '/settings/payment-rates' },
  ].filter(isTruthy);

  const goToTab = useCallback((tabKey: string) => {
    const tab = tabs.find(t => t.key === tabKey);
    if (tab?.route) history.push(tab?.route);
  }, [tabs, history]);

  return (
    <div style={{ flex: '0 0 auto', backgroundColor: colors.cavalry.backgroundLight6 }}>
      <div style={styles.headerContainer} className="m-hide">
        <PageHeader style={{ marginLeft: 0 }} title="Settings" />
      </div>
      <TabBar tabs={tabs} selectedTabKey={props.selectedTabKey} onTabClick={goToTab} />
    </div>
  );
};

export default SettingsHeader;
