const REFERENCE_FETCH = 'REFERENCE_FETCH';
const REFERENCE_SUCCESS = 'REFERENCE_SUCCESS';
const REFERENCE_INVALID = 'REFERENCE_INVALID';
const REFERENCE_ALREADY_SUBMITTED = 'REFERENCE_ALREADY_SUBMITTED';
const REFERENCE_SET_VALUES = 'REFERENCE_SET_VALUES';
const REFERENCE_RESET_STATE = 'REFERENCE_RESET_STATE';

export function fetchReference() {
  return {
    type: REFERENCE_FETCH,
  };
}

export function fetchSuccess(reference) {
  return {
    type: REFERENCE_SUCCESS,
    reference,
  };
}

export function fetchInvalid() {
  return {
    type: REFERENCE_INVALID,
  };
}

export function fetchAlreadySubmitted() {
  return {
    type: REFERENCE_ALREADY_SUBMITTED,
  };
}

export function setValues(dict) {
  return {
    type: REFERENCE_SET_VALUES,
    dict,
  };
}

export function resetState() {
  return {
    type: REFERENCE_RESET_STATE,
  };
}

const initialState = {
  fetching: true,
};

export default function screen(state = initialState, action) {
  switch (action.type) {
    case REFERENCE_FETCH:
      return {
        fetching: true,
      };

    case REFERENCE_SUCCESS:
      return {
        fetching: false,
        reference: action.reference,
      };

    case REFERENCE_INVALID:
      return {
        fetching: false,
        invalid: true,
      };

    case REFERENCE_ALREADY_SUBMITTED:
      return {
        fetching: false,
        alreadySubmitted: true,
      };

    case REFERENCE_SET_VALUES:
      return {
        ...state,
        reference: {
          ...state.reference,
          ...action.dict,
        },
      };

    case REFERENCE_RESET_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
