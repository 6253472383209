import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import * as complianceThunks from 'thunks/compliance';

import { EngineKey, Submission } from 'types/ComplianceTypes';
import SubmissionDetails from './SubmissionDetails';
import SubmissionBubble from './SubmissionBubble';
import './ComplianceTab.scss';

function calculateExpiresAt(expiryTimeDays: number | null, expiresAt?: string, validFrom: string = moment().toISOString()): string | undefined {

  let date;

  if (expiryTimeDays) {
    date = expiresAt ? moment(expiresAt).toISOString() : moment(validFrom).add(expiryTimeDays, 'days').toISOString();
  }
  return date;
}

function adjustTime(date: string): string {
  return moment.utc(date)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString();
}

interface ApproveSubmissionPayload {
  submissionKey: string,
  validFrom: string | undefined,
  expiresAt: string | undefined,
}

interface ComplianceSubmissionProps {
  itemKey: string,
  candidateKey: string,
  engineKey: EngineKey,
  submission: Submission,
  adminHasWriteAccess: boolean,
  expiryTimeDays: number | null,
  fetchItemsAndUpdateListView: () => void,
}

export default function ComplianceSubmission(props: ComplianceSubmissionProps): React.ReactElement {

  const dispatch = useDispatch();

  const { submission, engineKey, itemKey, candidateKey, adminHasWriteAccess, expiryTimeDays } = props;

  const [validFromDate, setValidFromDate] = useState<string | undefined>(submission.validFrom);
  const [expiresAt, setExpiresAt] = useState<string | undefined>(calculateExpiresAt(expiryTimeDays, submission.expiresAt, validFromDate));
  const [validFromHasBeenManuallyUpdated, setValidFromHasBeenManuallyUpdated] = useState(false);
  const [expiresAtHasBeenManuallyUpdated, setExpiresAtHasBeenManuallyUpdated] = useState(!!submission.expiresAt);
  const [submissionRejectModalIsOpen, toggleSubmissionRejectModal] = useState(false as boolean);

  useEffect(() => {
    setValidFromDate(submission.validFrom);
  }, [submission.validFrom]);

  useEffect(() => {
    // If certificate has a configured expiry date, and it has not been manually updated, then update expiresAt based on new validFrom date
    if (expiryTimeDays && !expiresAtHasBeenManuallyUpdated) {
      const updatedExpiresAt = adjustTime(moment.utc(validFromDate).add(expiryTimeDays, 'days').toISOString());
      setExpiresAt(updatedExpiresAt);
    }
  }, [validFromDate]);

  const submissionWithLocalChanges = useMemo(() => {
    return { ...submission, validFrom: validFromDate, expiresAt };
  }, [props.submission, validFromDate, expiresAt]);

  const setValidFromDateCb = useCallback((date: string) => {
    const adjustedDate = adjustTime(date);
    setValidFromDate(adjustedDate);
    if (!validFromHasBeenManuallyUpdated) {
      setValidFromHasBeenManuallyUpdated(true);
    }
  }, []);

  const setExpiresAtDateCb = useCallback((date: string) => {
    const adjustedDate = adjustTime(date);
    setExpiresAt(adjustedDate);
    if (!expiresAtHasBeenManuallyUpdated) {
      setExpiresAtHasBeenManuallyUpdated(true);
    }
  }, [expiresAtHasBeenManuallyUpdated]);

  const approveSubmissionCb = useCallback(async () => {

    const submissionPayload: ApproveSubmissionPayload = {
      submissionKey: submission.submissionKey,
      validFrom: validFromHasBeenManuallyUpdated ? validFromDate : undefined,
      expiresAt: expiresAtHasBeenManuallyUpdated ? expiresAt : undefined,
    };

    await dispatch(complianceThunks.approveComplianceSubmission({
      submission: submissionPayload,
      engineKey,
      itemKey,
      candidateKey,
    }));
    props.fetchItemsAndUpdateListView();
  }, [submissionWithLocalChanges, engineKey, itemKey, candidateKey]);

  const rejectSubmissionCb = useCallback(async (rejectedReason: string) => {
    await dispatch(complianceThunks.rejectComplianceSubmission({
      submissionKey: submissionWithLocalChanges.submissionKey,
      rejectedReason,
      engineKey,
      itemKey,
      candidateKey,
    }));
    props.fetchItemsAndUpdateListView();
  }, [submissionWithLocalChanges, engineKey, itemKey, candidateKey]);

  return (
    <SubmissionBubble
      submission={submissionWithLocalChanges}
      adminHasWriteAccess={adminHasWriteAccess}
      submissionRejectModalIsOpen={submissionRejectModalIsOpen}
      toggleSubmissionRejectModal={toggleSubmissionRejectModal}
      approveSubmission={approveSubmissionCb}
      rejectSubmission={rejectSubmissionCb}
    >
      <SubmissionDetails submission={submissionWithLocalChanges} setExpiresAt={setExpiresAtDateCb} setValidFromDate={setValidFromDateCb} />
    </SubmissionBubble>
  );
}
