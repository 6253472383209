import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import spacing from '../../config/spacing';
import colors from '../../config/colors';
import fonts from '../../config/fonts';
import ButtonContainer from '../../components/ButtonContainer';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import SupplierBasicInformation from './SupplierBasicInformation';
import SupplierBasicInformationForm from './SupplierBasicInformationForm';
import {
  createSupplier,
  updateSupplier,
  deleteSupplier,
} from '../../thunks/suppliers';

const styles = {
  button: {
    marginLeft: spacing.small,
    paddingRight: spacing.small,
    paddingLeft: spacing.small,
    color: colors.white,
    width: 150,
  },
  edit: {
    backgroundColor: colors.positive,
  },
  delete: {
    backgroundColor: colors.red,
  },
  cancel: {
    backgroundColor: colors.grays.light,
    color: colors.white,
  },
  save: {
    backgroundColor: colors.positive,
  },
  mainContainer: {
    boxSizing: 'border-box',
    padding: '6px 12px',
  },
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    margin: spacing.base,
  },
};

const { object, string, bool, func } = PropTypes;

class SupplierBasicInformationContainer extends Component {

  static propTypes = {
    supplierData: object,
    userId: string,
    orgKey: string,
    supplierKey: string,
    dispatch: func.isRequired,
    isLoading: bool,
    closeSupplierModal: func.isRequired,
  }

  static defaultProps = {
    supplierData: {},
    userId: null,
    orgKey: null,
    supplierKey: null,
    isLoading: false,
  }

  constructor(props) {
    super(props);
    this.button = {
      cancel: {
        buttonText: 'Cancel',
        extraStyles: styles.cancel,
        handleButtonClicked: this.cancel,
      },
      cancelEdit: {
        buttonText: 'Cancel',
        extraStyles: styles.cancel,
        handleButtonClicked: this.cancelEdit,
      },
      cancelNew: {
        buttonText: 'Cancel',
        extraStyles: styles.cancel,
        handleButtonClicked: this.cancelNew,
      },
      save: {
        buttonText: 'Save',
        extraStyles: styles.edit,
        handleButtonClicked: this.save,
      },
      edit: {
        buttonText: 'Edit',
        extraStyles: styles.edit,
        handleButtonClicked: this.edit,
      },
      delete: {
        buttonText: 'Disable',
        extraStyles: styles.delete,
        handleButtonClicked: this.requestDelete,
      },
    };
    this.buttonStates = {
      newSupplier: {
        alignButtons: 'right',
        buttons: [this.button.cancelNew, this.button.save],
      },
      viewSupplier: {
        alignButtons: 'right',
        buttons: [this.button.edit],
      },
      editSupplier: {
        alignButtons: 'right',
        buttons: [this.button.delete, this.button.cancelEdit, this.button.save],
      },
    };
    this.state = {
      submitting: false,
      unlockedForEditing: false,
      confirmationModalIsOpen: false,
      infoModalIsOpen: false,
      infoModalText: '',
    };
  }

  requestDelete = () => {
    this.setState({ confirmationModalIsOpen: true });
  }

  edit = () => {
    this.setState({ unlockedForEditing: true });
  }

  cancelEdit = () => {
    this.setState({ unlockedForEditing: false });
  }

  cancelNew = () => {
    this.props.closeSupplierModal();
  }

  save = () => {
    this.setState({ submitting: true });
  }

  closeConfirmationModal = () => {
    this.setState({ confirmationModalIsOpen: false });
  }

  delete = async () => {
    await this.props.dispatch(deleteSupplier(this.props.supplierKey, 'disable only'));
    this.props.closeSupplierModal();
  }

  validate = (data) => {
    return data.companyName && data.address1 && data.address2 && data.city && data.county && data.postcode && data.logoUrl && data.mainTelephone && data.companyNumber && data.vatNumber && data.tier;
  }

  openInfoModal = (infoModalText) => {
    this.setState({
      infoModalIsOpen: true,
      infoModalText,
    });
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
      infoModalText: '',
    });
  }

  saveData = async (supplierData) => {
    this.setState({
      unlockedForEditing: false,
      submitting: false,
    });
    if (this.validate(supplierData)) {
      const { supplierKey, userId, orgKey } = this.props;
      if (supplierKey) {
        await this.props.dispatch(updateSupplier(supplierData, orgKey, userId, supplierKey));
      } else {
        await this.props.dispatch(createSupplier(supplierData, orgKey));
      }
    } else {
      this.openInfoModal('All fields are required');
    }
  }

  render() {
    const { supplierKey, supplierData } = this.props;
    let buttonState;
    if (!supplierKey) {
      buttonState = 'newSupplier';
    } else if (this.state.unlockedForEditing) {
      buttonState = 'editSupplier';
    } else {
      buttonState = 'viewSupplier';
    }
    return (
      <div style={styles.mainContainer}>
        {this.props.isLoading ?
          <div style={{ textAlign: 'center' }}>
            <Loading />
          </div>
          :
          <div>
            <ButtonContainer
              buttonStyles={styles.button}
              currentState={buttonState}
              states={this.buttonStates}
            />
            {!supplierKey || this.state.unlockedForEditing ?
              <SupplierBasicInformationForm
                initialValues={supplierData}
                onSubmit={this.saveData}
                triggerSubmit={this.state.submitting}
              /> :
              <SupplierBasicInformation
                supplierData={supplierData}
              />
            }
            <Modal
              isOpen={this.state.confirmationModalIsOpen}
              contentLabel="Confirm Disable Supplier"
              onRequestClose={this.closeConfirmationModal}
            >
              <div>
                <h2 style={styles.header}>Disable supplier?</h2>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Button onClick={this.closeConfirmationModal}>Cancel</Button>
                <Button onClick={this.delete} style={{ marginLeft: 10 }}>Disable</Button>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.infoModalIsOpen}
              contentLabel="Info"
              onRequestClose={this.closeInfoModal}
            >
              <div>
                <h2 style={styles.header}>{this.state.infoModalText}</h2>
              </div>
            </Modal>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps({ suppliers, user }) {
  const { suppliersData, orgKey, supplierKey, isLoading } = suppliers;
  const supplierData = suppliersData && suppliersData[supplierKey] ? suppliers.suppliersData[supplierKey] : {};
  const { userId } = user;
  return {
    orgKey,
    supplierKey,
    supplierData,
    userId,
    isLoading,
  };
}

export default connect(mapStateToProps)(SupplierBasicInformationContainer);
