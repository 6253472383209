const fonts = {};

//----------------------------------------------------------------
// STATIC SITE
//----------------------------------------------------------------

fonts.logo = {
  family: 'Inter, sans-serif', // 'Poppins, sans-serif',
  size: 22, // 18,
  weight: 400,
};

fonts.nav = {
  family: 'Inter, sans-serif',
  size: 16,
  weight: 200,
};

fonts.strap = {
  family: 'Inter, sans-serif',
  size: 28,
  weight: 500,
};

fonts.strapLarge = {
  family: 'Inter, sans-serif',
  size: 42,
  weight: 500,
};

fonts.title = {
  family: 'Inter, sans-serif',
  size: 28,
  weight: 500,
};

fonts.copy = {
  family: 'Inter, sans-serif',
  size: 18,
  weight: 300,
};

//----------------------------------------------------------------
// WEB APP
//----------------------------------------------------------------

fonts.large = {
  family: 'Inter, sans-serif',
  size: 28,
  weight: 800,
};

fonts.header = {
  family: 'Inter, sans-serif',
  size: 20,
  weight: 800,
};

fonts.headerNav = {
  family: 'Inter, sans-serif',
  size: 20,
  weight: 400,
};

fonts.main = {
  family: 'Inter, sans-serif',
  size: '0.9rem',
  weight: 500,
};

fonts.sub = {
  family: 'Inter, sans-serif',
  size: 16,
  weight: 300,
};

fonts.button = {
  family: 'Inter, sans-serif',
  size: 16,
  weight: 500,
};

fonts.tab = {
  family: 'Inter, sans-serif',
  size: 16,
  weight: 500,
};

// Dagny fonts

fonts.headerMain = {
  family: 'Inter, sans-serif',
  size: '1.5rem',
  weight: 600,
};

fonts.headerSub = {
  family: 'Inter, sans-serif',
  size: '1.3rem',
  weight: 600,
};

fonts.textMain = {
  family: 'Inter, sans-serif',
  size: '0.9rem',
  weight: 500,
};

fonts.textSub = {
  family: 'Inter, sans-serif',
  size: '0.85rem',
  weight: 500,
};

fonts.label = {
  family: 'Inter, sans-serif',
  size: '0.9rem',
  weight: 500,
};

export default fonts;
