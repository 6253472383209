import { watch, findAll } from '../lib/orm';
import * as api from '../lib/api';
import * as suppliersReducer from '../reducers/suppliers';
import safeExec from '../lib/safeExec';

let unwatch = null;
let unwatchContacts = null;

export function startWatchingSuppliers(orgKey) {
  return async (dispatch) => {
    dispatch(suppliersReducer.setOrgKey(orgKey));
    dispatch(suppliersReducer.startWatching());
    unwatch = await watch('suppliers', [
      ['orgKey', '==', orgKey],
      ['enabled', '==', true],
    ], (suppliersData) => {
      dispatch(suppliersReducer.didChange(suppliersData));
    }, true, 'startWatchingSuppliers in suppliers.js');
  };
}

export function stopWatchingSuppliers() {
  return async (dispatch) => {
    dispatch(suppliersReducer.resetState());
    safeExec(unwatch);
  };
}

export function startWatchingSupplierContacts(supplierKey) {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    unwatchContacts = await watch('supplierContacts', [
      ['supplierKey', '==', supplierKey],
      ['enabled', '==', true],
    ], (contactsData) => {
      dispatch(suppliersReducer.contactsDidChange(contactsData));
      dispatch(suppliersReducer.watchingContacts());
    }, true, 'startWatchingSupplierContacts in suppliers.js');
    dispatch(suppliersReducer.stopLoadingSuppliers());
  };
}

export function stopWatchingSupplierContacts() {
  return async (dispatch) => {
    dispatch(suppliersReducer.resetContactState());
    safeExec(unwatchContacts);
  };
}

export function createSupplier(supplierData, orgKey) {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    const response = await api.post('suppliers/create', { supplierData, orgKey });
    dispatch(suppliersReducer.stopLoadingSuppliers());
    if (response.status === 200 && response.body) {
      const { supplierKey } = response.body;
      dispatch(suppliersReducer.setSupplierKey(supplierKey));
    }
  };
}

export function updateSupplier(supplierData, orgKey, userId, supplierKey) {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    await api.post('suppliers/update', { supplierData, orgKey, userId, supplierKey });
    dispatch(suppliersReducer.stopLoadingSuppliers());
  };
}

export function deleteSupplier(supplierKey, mode = 'disable') {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    await api.post('suppliers/delete', { supplierKey, mode });
    dispatch(suppliersReducer.stopLoadingSuppliers());
  };
}

export function createSupplierContact(supplierKey, contactData, userId) {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    await api.post('supplierContacts/create', { supplierKey, contactData, userId });
    dispatch(suppliersReducer.stopLoadingSuppliers());
  };
}

export function updateSupplierContact(supplierKey, contactKey, contactData, userId) {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    await api.post('supplierContacts/update', { supplierKey, contactKey, contactData, userId });
    dispatch(suppliersReducer.stopLoadingSuppliers());
  };
}

export function deleteSupplierContact(contactKey, supplierKey, mode) {
  return async (dispatch) => {
    dispatch(suppliersReducer.startLoadingSuppliers());
    await api.post('supplierContacts/delete', { contactKey, supplierKey, mode });
    dispatch(suppliersReducer.stopLoadingSuppliers());
  };
}

export function fetchAgencySubmittedCandidates(supplierKey, jobRole) {
  const candidates = [];
  return async (dispatch) => {
    const response = await findAll('agencySubmittedCandidates', [
      ['supplierKey', '==', supplierKey],
      ['role.id', '==', jobRole],
    ], true, 'fetchAgencySubmittedCandidates in suppliers.js');
    const sortFunc = (a, b) => a.name.localeCompare(b.name);
    if (response && Object.keys(response).length > 0) {
      Object.keys(response).forEach(rec => candidates.push(response[rec]));
    }
    candidates.sort(sortFunc);
    dispatch(suppliersReducer.addSupplierCandidates(candidates));
  };
}
