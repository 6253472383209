import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export default function Toggle(props) {
  return (
    <div
      className="toggleContainer"
      onClick={props.toggle}
    >
      <div
        className={`
          option
          ${props.selectedOption === 1 ? 'selected' : null}
          test-id-toggle-option-1
        `}
      >
        <p>{props.option1}</p>
      </div>
      <div
        className={`
          option
          ${props.selectedOption === 2 ? 'selected' : null}
          test-id-toggle-option-2
        `}
      >
        <p>{props.option2}</p>
      </div>
    </div>
  );
}

const { string, number, func } = PropTypes;
Toggle.propTypes = {
  option1: string.isRequired,
  option2: string.isRequired,
  selectedOption: number.isRequired,
  toggle: func.isRequired,
};
