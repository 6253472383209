import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import spacing from 'config/spacing';
import colors from 'config/colors';
import fonts from 'config/fonts';
import { INTERCOM_ENABLED } from 'config/settings';
import { useFeatureFlag } from 'hooks/feature';

import { openSideNav, closeSideNav } from 'reducers/userInterface';

import MainMenu from '../MainMenu';
import { useMenuItems } from '../MainMenu/menu-items';
import NotificationBell from '../NotificationBell';
import CustomerSupportButton from '../CustomerSupportButton';

import Logo from './Logo';
import UserBar from './UserBar';
import OrgLogo from './OrgLogo';
import SandboxLabel from './SandboxLabel';

const sideMenuWidth = 200;
const styles = {
  left: {
    // flex: '0 0 80px',
    position: 'relative',
    width: sideMenuWidth,
    height: '100%',
    backgroundColor: colors.cavalry.backgroundLight1,
    display: 'flex',
    flexDirection: 'column',
    // paddingLeft: 18,
    // paddingRight: 18,
    borderRight: `1px solid ${colors.cavalry.line}`,
    // justifyContent: 'space-between',
  },
  leftClosed: {
    flex: '0 0 75px',
    position: 'relative',
    width: sideMenuWidth,
    height: '100%',
    backgroundColor: colors.cavalry.backgroundLight1,
    transition: 'all 0.3s ease 0s',
    display: 'flex',
    flexDirection: 'column',
  },
};


const SideBar = (props) => {

  const sandbox = useFeatureFlag('sandbox');
  const sideNavOpen = useSelector(state => state.userInterface.sideNavOpen);

  const dispatch = useDispatch();
  const toggleMenu = () => dispatch(sideNavOpen ? closeSideNav() : openSideNav());

  return (
    <div className="pageSidebar p-hide" style={styles.left}>

        {/* CW logo */}
        <div style={{ paddingTop: 12, paddingLeft: 12 }}>
          <Logo />
        </div>

        {/* Side Menu */}
        <MainMenu isOpen />

        {/* Sandbox label & Intercom */}
        {!!sandbox && <SandboxLabel />}
        {!INTERCOM_ENABLED && !sandbox ? <CustomerSupportButton /> : null}

        <div style={{ width: '74%', height: 1, backgroundColor: colors.cavalry.line, alignSelf: 'center' }} />

        <div style={{ paddingTop: 24 }}>
          {/* Notification Bell, My Profile and Org Logo */}
          <NotificationBell showText onClick={props.openNotificationCentre} />
          <UserBar />
          {/* <OrgLogo isOpen /> */}
        </div>

    </div>
  );
};

export default SideBar;

