import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firstBy } from 'thenby';

import * as reportingThunks from 'thunks/reporting';
import DropDownFilter from 'components/DropDownFilter';


const { objectOf, object, func } = PropTypes;

const customSelectBoxStyles = {
  valueContainer: { fontSize: '14px' },
  menuList: { width: '260px' },
  menu: { width: '260px' },
  option: { fontSize: '14px' },
};

class SiteFilter extends Component {

  static propTypes = {
    sites: objectOf(object).isRequired,
    setSelectedSite: func.isRequired,
  }

getOptionsForSiteFilters = () => { // eslint-disable-line
  const options = Object.entries(this.props.sites || {})
    .map(site => ({ label: site[1].name, value: site[0] }))
    .sort(firstBy('label'));
  return [{ label: 'All sites', value: null }, ...options];
}

render() {
  const options = this.getOptionsForSiteFilters();

  return (
    <DropDownFilter
      // title="Site:"
      placeholder="Filter by site..."
      onChange={inputValue => this.props.setSelectedSite({ key: inputValue.value, name: inputValue.label })}
      options={options}
      clearable={false}
      customStyles={customSelectBoxStyles}
    />
  );
}
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedSite: site => dispatch(reportingThunks.setSelectedSite(site)),
  };
}

function mapStateToProps({ user, reporting }) {
  return {
    sites: user.sites,
    selectedSite: reporting.selectedSite,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteFilter);
