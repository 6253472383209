import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../config/colors';
// import { capitalize } from '../../lib/helpers';

const styles = {
  color: colors.white,
  textAlign: 'center',
  fontSize: '0.9rem',
  marginRight: 2,
  borderRadius: 3,
  lineHeight: 1,
  padding: '3px 6px',
};


const notificationTypeColorMap = {
  application: colors.cavalry.active,
  timesheet: colors.cavalry.active,
  cancellation: colors.cavalry.error,
  'status-change': colors.cavalry.pending,
  'status-change-withdrawl': colors.cavalry.error,
};

function changeTitle(type) {
  if (type === 'application') {
    return 'NEW APPLICANT';
  } else if (type === 'timesheet') {
    return 'TIMESHEET SUBMITTED';
  } else if (type === 'cancellation') {
    return 'BOOKING CANCELLED';
  } else if (type === 'status-change-withdrawl') {
    return 'APPLICANT WITHDREW';
  } else if (type === 'status-change') {
    return 'STATUS CHANGE';
  } return null;
}

export default function NotificationType(props) {
  const parsedType = changeTitle(props.type);
  // parseType(props.type);
  return (
    <div
      style={{
        ...styles,
        backgroundColor: notificationTypeColorMap[props.type] || colors.white,
      }}
    >
      <p>{parsedType}</p>
    </div>
  );
}

const { string } = PropTypes;
NotificationType.propTypes = {
  type: string.isRequired,
};
