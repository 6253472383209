// NOTE: change these constants to cause app-wide change
// NOTE: name (e.g. phoneMedium) corresponds to anything EQUAL TO OR BELOW corresponding screen width

const config = {
  phoneTiny: 240,
  phoneSmall: 320,
  phoneMedium: 375,
  phoneLarge: 540,
  tabletSmall: 768,
  tabletLarge: 1024,
  desktop: 3000,
};

//----------------------------------------------------------------
// PRIVATE
//----------------------------------------------------------------

const SIZE_NAMES = Object.keys(config);
const BREAKPOINTS = Object.keys(config).map((name) => {
  return config[name];
});

//----------------------------------------------------------------
// EXPORTS
//----------------------------------------------------------------

// NOTE: very unreliable!
export function getOrientation(dimensions) {
  return dimensions.width < dimensions.height ? 'portrait' : 'landscape';
}

export function getScreenSize(dimensions) {
  const scrWidth = dimensions.width;
  let size;
  for (let i = 0, len = BREAKPOINTS.length; i < len; i += 1) {
    if (scrWidth <= BREAKPOINTS[i]) {
      size = SIZE_NAMES[i];
      break;
    }
  }
  size = size || SIZE_NAMES[SIZE_NAMES.length - 1];

  return { size, isMobile: !!size?.includes('phone') };
}
