import React from 'react';
import DateFormatter from './DateFormatter';

export default function XAxisAtAngle(props) {
  const { x, y, payload, groupedBy } = props;

  const newX = x - 14;
  const newY = y + 4;

  return (
    <g transform={`translate(${newX},${newY})`}>
      <text x={0} y={0} dy={16} transform="rotate(-90)" textAnchor="end" fill="#999" className="xAxis">{groupedBy === 'date' ? DateFormatter(payload.value) : payload.value}</text>
    </g>
  );
}
