
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { firstBy } from 'thenby';
import { groupBy } from 'lodash-es';

import { isTruthy } from 'lib/helpers-ts';
import colors from 'config/colors';

import { PaymentRate } from 'types/Metadata';
import { fetchPaymentRates } from 'lib/api/accounts';

import Page from 'components/Page';
import Loading from 'components/Loading';
import Checkbox from 'components/Checkbox';
import DataTable from 'components/DataTable';

import SettingsHeader from '../SettingsHeader';

function usePaymentRates() {

  const [error, setError] = useState(null as null | string);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null as null | Array<PaymentRate>);

  const load = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const result: { paymentRates: Array<PaymentRate> } = await fetchPaymentRates();
      setData(result.paymentRates);
    } catch (e: any) {
      setError(e.message || 'Error fetching payment rates');
    }

    setLoading(false);
  }, []);

  useEffect(() => { load(); }, []);

  return { data, error, loading, load };
}

const AnyNode = <span style={{ color: '#999' }}>(any)</span>;
const NANode = <span style={{ color: '#999' }}>N/A</span>;
const NoneNode = <span style={{ color: '#999' }}>None</span>;

const sortCriteria = firstBy(() => 0)
  .thenBy('roleName')
  .thenBy('gradeSort')
  .thenBy('gradeName')
  .thenBy('specialitySort')
  .thenBy('specialityName')
  .thenBy('siteName')
  .thenBy('reasonName')
  .thenBy('subReasonName')
  .thenBy('rateModifierName')
  .thenBy('timeType')
  .thenBy('mon')
  .thenBy('sat')
  .thenBy('sun')
  .thenBy('weekend')
  .thenBy('bh')
  .thenBy('bankHoliday')
  .thenBy('fromTime');

const PaymentRatesContainerProps = () : JSX.Element => {

  const { data: paymentRates, loading: isFetchingPaymentRates, error: errorFetchingPaymentRates } = usePaymentRates();

  const activePaymentRates = useMemo(() => {
    const now = moment.utc().toISOString();
    return paymentRates
      ?.filter(rate => !rate.effectiveFrom || rate.effectiveFrom < now)
      ?.filter(rate => !rate.effectiveTo || rate.effectiveTo > now)
      ?.sort(sortCriteria);
  }, [paymentRates]);

  const groupedPaymentRates = useMemo(() => {
    if (activePaymentRates?.some(rate => rate.groupName)) {
      // return Object.entries(groupBy(activePaymentRates ?? [], 'groupName'))
      //   .map(([groupName, rates]) => ({ groupName, order: rates[0].groupOrder, rates }))
      //   .sort(firstBy('order'));
      return Object.entries(groupBy(activePaymentRates ?? [], 'groupName'))
        .sort(firstBy(([groupName, rates]) => rates?.[0]?.groupOrder))
        .flatMap(([groupName, rates]) => [
          { type: 'header', title: groupName },
          ...rates,
        ]);
    }
    return [
      { type: 'header', title: 'Payment Rates' },
      ...(activePaymentRates ?? []),
    ];
  }, [activePaymentRates]);

  const activeColumns = useMemo(() => {

    const orgUsesRole = paymentRates?.some(rate => !!rate.roleKey);
    const orgUsesGrade = paymentRates?.some(rate => !!rate.gradeKey);
    const orgUsesSpeciality = paymentRates?.some(rate => !!rate.specialityKey);
    const orgUsesSite = paymentRates?.some(rate => !!rate.siteKey);
    const orgUsesReason = paymentRates?.some(rate => !!rate.reasonKey);
    const orgUsesSubReason = paymentRates?.some(rate => !!rate.subReasonKey);
    const orgUsesRateModifier = paymentRates?.some(rate => !!rate.rateModifierKey);
    const orgUsesTimeType = paymentRates?.some(rate => !!rate.timeTypeKey);
    const orgUsesIntersectsTime = paymentRates?.some(rate => !!rate.intersectsTime);
    const orgUsesDaysOfWeek = paymentRates?.some(rate => !!(!rate.mon || !rate.tue || !rate.wed || !rate.thu || !rate.fri || !rate.sat || !rate.sun || !rate.bh || rate.weekend || rate.bankHoliday));
    const orgUsesTimeOfDay = paymentRates?.some(rate => !!((rate.fromTime && rate.fromTime !== '00:00') || (rate.toTime && rate.toTime !== '00:00' && rate.toTime !== '23:59')));

    return [
      // { name: 'key', header: 'Key' },
      { name: 'name', header: 'Name', width: 200 },
      orgUsesRole && { name: 'roleName', header: 'Role', formatter: (name: string, row: PaymentRate) => (row.roleName ?? AnyNode) },
      orgUsesGrade && { name: 'gradeName', header: 'Grade', formatter: (name: string, row: PaymentRate) => (row.gradeName ?? AnyNode) },
      orgUsesSpeciality && { name: 'specialityName', header: 'Speciality', formatter: (name: string, row: PaymentRate) => (row.specialityName ?? AnyNode) },
      orgUsesSite && { name: 'siteName', header: 'Site', formatter: (name: string, row: PaymentRate) => (row.siteName ?? AnyNode) },
      orgUsesReason && { name: 'reasonName', header: 'Reason', formatter: (name: string, row: PaymentRate) => (row.reasonName ?? AnyNode) },
      orgUsesSubReason && { name: 'subReasonName', header: 'Sub-Reason', formatter: (name: string, row: PaymentRate) => (row.subReasonName ?? AnyNode) },
      orgUsesRateModifier && { name: 'rateModifierName', header: 'Special Rate', formatter: (name: string, row: PaymentRate) => (row.rateModifierName ?? NoneNode) },
      orgUsesDaysOfWeek && { name: 'mon', header: 'M', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled && !!row.fromTime && !!row.toTime && !row.bankHoliday && !row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'tue', header: 'T', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled && !!row.fromTime && !!row.toTime && !row.bankHoliday && !row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'wed', header: 'W', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled && !!row.fromTime && !!row.toTime && !row.bankHoliday && !row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'thu', header: 'T', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled && !!row.fromTime && !!row.toTime && !row.bankHoliday && !row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'fri', header: 'F', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled && !!row.fromTime && !!row.toTime && !row.bankHoliday && !row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'sat', header: 'S', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled || row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'sun', header: 'S', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled || row.weekend} /> },
      orgUsesDaysOfWeek && { name: 'bh', header: 'BH', width: 24, formatter: (isEnabled: boolean, row: PaymentRate) => <Checkbox size={16} checked={isEnabled || row.bankHoliday} /> },
      orgUsesTimeType && { name: 'timeTypeName', header: 'Time Type', formatter: (name: string, row: PaymentRate) => (row.timeTypeName ?? NANode) },
      orgUsesTimeOfDay && { name: 'fromTime', header: 'From', formatter: (time: string, row: PaymentRate) => (row.fromTime ?? NANode) },
      orgUsesTimeOfDay && { name: 'toTime', header: 'Until', formatter: (time: string, row: PaymentRate) => (row.toTime ?? NANode) },
      orgUsesIntersectsTime && { name: 'intersectsTime', header: 'Intersects', formatter: (time: string, row: PaymentRate) => (row.intersectsTime ?? NANode) },
      { name: 'rate',
        header: 'Rate',
        width: 100,
        formatter: (row: PaymentRate) => {
          if (row.hourlyRate) return `£${row.hourlyRate}/h`;
          if (row.wholeShiftRate) return `£${row.wholeShiftRate}/shift`;
          return NANode;
        },
      },
    ].filter(isTruthy);

  }, [paymentRates]);

  return (
    <Page vflex disableScroll title="Settings: Payment Rates">
      <SettingsHeader selectedTabKey="payment-rates" />

      {/* Loading and No-Content */}
      {isFetchingPaymentRates && <Loading flex />}
      {!isFetchingPaymentRates && !activePaymentRates?.length && (
        <div style={{ flex: 1, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: colors.text }}>No Payment Rates Configured</p>
        </div>
      )}

      {/* Payment rates table */}
      {!!activePaymentRates?.length && (
        <div style={{ flex: 1, overflow: 'auto' }}>
          <DataTable<PaymentRate, undefined>
            multiHeader
            multiHeaderRepeatColumnHeaders
            showRowsOnly
            multiHeaderStyle={{
              padding: '18px 12px 12px 12px',
              color: colors.text,
              fontWeight: 600,
              fontSize: '1.2rem',
              backgroundColor: colors.white,
              textTransform: 'none',
            }}
            overrideHeadingStyles={{
              color: colors.text,
              textTransform: 'none',
              fontSize: '0.9rem',
            }}
            columns={activeColumns as any}
            rows={groupedPaymentRates as any}
          />
        </div>
      )}
    </Page>
  );
};

interface PaymentRateGroupProps {
  rates: PaymentRate[],
  groupName: string,
  activeColumns: any,
}
const PaymentRateGroup = (props: PaymentRateGroupProps) => {

  return (
    <>
      <h2 style={{ padding: '12px 12px 0 12px', color: colors.brand, fontWeight: 'normal' }}>{props.groupName}</h2>
      <DataTable<PaymentRate, undefined>
        multiHeader
        columns={props.activeColumns as any}
        rows={props.rates}
      />
    </>
  );
};


export default PaymentRatesContainerProps;
