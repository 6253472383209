import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import colors from 'config/colors';

const ErrorModal = props => (
  <Modal
    isOpen={!!props.errorMessage}
    onRequestClose={props.onCancel}
    header={props.header}
  >
    <div style={{ padding: '32px', textAlign: 'center' }}>
      <p style={{ color: colors.text, fontSize: '0.9rem' }}>{props.errorMessage}</p>
    </div>
  </Modal>
);

ErrorModal.propTypes = {
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};

ErrorModal.defaultProps = { errorMessage: null };

export default ErrorModal;
