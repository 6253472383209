import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextAreaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import Modal from 'components/Modal';
import CloseButton from 'components/CloseButton';
import Button from 'components/Button';
import colors from 'config/colors';
import spacing from 'config/spacing';
import fonts from 'config/fonts';
import * as candidatePropTypes from 'models/candidate';

const styles = {
  header: {
    color: colors.positive,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    margin: spacing.base,
    textAlign: 'center',
  },
  textarea: {
    backgroundColor: 'transparent',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    padding: 0,
    resize: 'none',
    overflowX: 'scroll',
    overflowY: 'none',
    outline: 'none',
    minHeight: 100,
    width: '96%',
    fontSize: '1.0em',
    color: colors.grays.medium,
  },
  label: {
    fontSize: 16,
    color: colors.positive,
    fontWeight: 500,
    paddingBottom: '10px',
  },
  cancel: {
    backgroundColor: colors.negative,
    marginRight: spacing.small,
    marginTop: spacing.base,
  },
  confirm: {
    marginLeft: spacing.small,
    marginTop: spacing.base,
  },
  message: {
    fontSize: 14,
    color: colors.warning,
    fontWeight: 500,
  },
  suspensionModal: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    paddingBottom: '20px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  contentContainer: {
    padding: '90px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100px',
  },
};

const { bool, func, objectOf, object, string, number, oneOfType } = PropTypes;
export default class SuspendModal extends Component {
  static propTypes = {
    suspendCandidate: func.isRequired,
    accountIsSuspended: bool,
    closeSuspensionModal: func.isRequired,
    candidateProfileData: candidatePropTypes.candidateProfileData,
    suspensionModalIsOpen: bool,
    orgConfig: objectOf(
      oneOfType([
        string,
        object,
        number,
        bool,
      ]),
    ),
  }

  static defaultProps = {
    accountIsSuspended: false,
    candidateProfileData: {},
    suspensionModalIsOpen: false,
    orgConfig: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      showReasonRequiredMessage: false,
    };
  }

  closeSuspensionModal = () => {
    this.setState({
      reason: '',
      showReasonRequiredMessage: false,
    });
    this.props.closeSuspensionModal();
  }

  requestSuspendCandidate = async () => {
    const { accountIsSuspended, suspendCandidate, orgConfig } = this.props;
    const candidateSuspensionReasonTypeIsList = orgConfig.candidateSuspensionReasonType && orgConfig.candidateSuspensionReasonType.type === 'list';
    if (this.state.reason || accountIsSuspended) {
      this.closeSuspensionModal();
      await suspendCandidate(!accountIsSuspended, candidateSuspensionReasonTypeIsList ? this.state.reason.value : this.state.reason);
    } else {
      this.setState({ showReasonRequiredMessage: true });
    }
  }

  changeReason = (data) => {
    const reason = data.target ? data.target.value : '';
    this.setState({ reason });
  }

  buildTitle = () => {
    const { accountIsSuspended, candidateProfileData } = this.props;
    const candidateName = candidateProfileData ? (candidateProfileData.name || `${candidateProfileData.firstName} ${candidateProfileData.surname}`) : null;
    if (candidateName) {
      return accountIsSuspended ? `Remove ${candidateName}'s suspension?` : `Suspend ${candidateName}'s account?`;
    }
    return accountIsSuspended ? 'Remove suspension?' : 'Suspend account?';
  }

  setSuspensionSelectListReason = (reason) => {
    this.setState({ reason });
  }

  render() {
    const { accountIsSuspended, suspensionModalIsOpen, orgConfig } = this.props;
    const { reason, showReasonRequiredMessage } = this.state;
    const candidateSuspensionReasonTypeIsList = orgConfig.candidateSuspensionReasonType && orgConfig.candidateSuspensionReasonType.type === 'list';
    return (
      <Modal isOpen={suspensionModalIsOpen} contentLabel="Candidate Suspension" contentStyle={styles.suspensionModal} onRequestClose={this.closeSuspensionModal}>
        <CloseButton top={15} right={15} handleClose={this.closeSuspensionModal} className="test-id-cp-modal-close-button" />
        <div className="header">
          <h3 style={styles.header}>{this.buildTitle()}</h3>
        </div>
        {showReasonRequiredMessage ?
          <div>
            <p style={styles.message}>Please give reason for suspension</p>
          </div>
          : null
        }
        {accountIsSuspended ?
          null :
          <div style={styles.contentContainer}>
            <p style={styles.label}>Suspension can be reversed at any time by a user with the required permissions.</p>
            {candidateSuspensionReasonTypeIsList ?
              <Select
                name="label"
                value={this.state.reason}
                options={(orgConfig.candidateSuspensionReasonType.options || []).map(option => ({ label: option, value: option }))}
                onChange={this.setSuspensionSelectListReason}
              />
              :
              <TextAreaAutosize
                style={styles.textarea}
                value={reason || ''}
                onChange={val => this.changeReason(val)}
                placeholder="Reason for suspension (required)"
              />
            }
          </div>
        }
        <div style={{ textAlign: 'center' }}>
          <Button onClick={this.closeSuspensionModal} style={styles.cancel}>Cancel</Button>
          {accountIsSuspended ?
            <Button onClick={() => this.requestSuspendCandidate()} style={{ ...styles.confirm, backgroundColor: colors.positive }}>Confirm</Button>
            :
            <Button onClick={() => this.requestSuspendCandidate()} style={{ ...styles.confirm, backgroundColor: colors.red }} disabled={!this.state.reason}>Suspend</Button>
          }
        </div>
      </Modal>
    );
  }
}
