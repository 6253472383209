import { fetchCandidates } from 'thunks/bank';
import { updateCandidateComplianceStatus } from 'thunks/candidate';
import * as complianceApi from 'lib/api/compliance';
import * as api from '../lib/api';
import * as complianceActions from '../reducers/compliance';
import * as candidateActions from '../reducers/candidate';


export function suspendCandidate(candidateKey, suspend = true, reason) {
  return async (dispatch, getState) => {
    dispatch(complianceActions.complianceSuspensionStartLoading());

    const { global, user } = getState();
    const response = await api.post('account/candidate/suspend', { orgKey: global.currentOrgKey, userId: user.userId, candidateKey, suspend, reason });
    if (response.body?.candidate) dispatch(candidateActions.fetchCandidateProfileSuccess(candidateKey, response.body.candidate));

    dispatch(complianceActions.complianceSuspensionStopLoading());

    // Update list view
    dispatch(fetchCandidates());
  };
}

export function removeCandidateFromBank(candidateKey, removalReason, removalDate) {
  return async (dispatch) => {
    dispatch(complianceActions.removingCandidateFromBank());
    const response = await api.post('account/candidate/remove', { candidateKey, removalReason, removalDate });
    if (response.status === 200) {
      dispatch(complianceActions.candidateRemovedFromBank());
      if (response.body?.candidate) dispatch(candidateActions.fetchCandidateProfileSuccess(candidateKey, response.body.candidate));

      // Update list view
      dispatch(fetchCandidates());
    } else {
      dispatch(complianceActions.candidateRemovalError());
    }
  };
}

export function cancelRemoveCandidateFromBank(candidateKey, cancellationReason) {
  return async (dispatch) => {
    dispatch(complianceActions.cancellingRemoveFromBank());

    const response = await api.post('candidate/cancelRemoval', { candidateKey, cancellationReason });

    if (response.status === 200) {
      dispatch(complianceActions.cancelledRemoveFromBank());
      if (response.body?.candidate) dispatch(candidateActions.fetchCandidateProfileSuccess(candidateKey, response.body.candidate));

      // Update list view
      dispatch(fetchCandidates());
    }
  };
}

export function reinstateCandidate(candidateKey) {
  return async (dispatch) => {
    dispatch(complianceActions.cancellingRemoveFromBank());

    const response = await api.post('candidate/reinstate', { candidateKey });

    if (response.status === 200) {
      dispatch(complianceActions.cancelledRemoveFromBank());
      if (response.body?.candidate) dispatch(candidateActions.fetchCandidateProfileSuccess(candidateKey, response.body.candidate));

      // Update list view
      dispatch(fetchCandidates());
    }
  };
}

export function fetchComplianceItem(itemKey, candidateKey) {
  return async (dispatch, getState) => {
    dispatch(complianceActions.fetchComplianceItem());

    try {

      const response = await complianceApi.fetchComplianceItem(itemKey, candidateKey);
      dispatch(complianceActions.fetchComplianceItemSuccess({
        itemKey: response.itemKey,
        itemName: response.itemName,
        itemStatus: response.itemStatus,
        description: response.description,
        evidenceCategories: response.evidenceCategories,
      }));

    } catch (error) {
      dispatch(complianceActions.fetchComplianceItemError(error.message));
    }
  };
}

export function approveComplianceSubmission({ submission, engineKey, itemKey, candidateKey }) {
  return async (dispatch, getState) => {

    const { submissionKey, validFrom, expiresAt } = submission;

    dispatch(complianceActions.isApprovingOrRejectingSubmission(submissionKey));

    try {

      let response;

      // Call engine specific helper
      if (engineKey === 'certificate') {
        response = await complianceApi.approveCertificate({ submissionKey, validFrom, expiresAt });
      } else if (engineKey === 'dbs-update') {
        response = await complianceApi.approveDbsUpdateSubmission({ submissionKey });
      } else if (engineKey === 'gmc' || engineKey === 'nmc') {
        response = await complianceApi.approveProfRegSubmission({ submissionKey, engineKey });
      } else {
        throw new Error(`Unknown engineKey: ${engineKey}`);
      }

      // Fetch compliance item to get latest changes and update overall candidate compliance status
      await dispatch(fetchComplianceItem(itemKey, candidateKey));
      dispatch(updateCandidateComplianceStatus(candidateKey, response.adminComplianceStatusColor, response.adminComplianceStatusLabel));

    } catch (error) {
      dispatch(complianceActions.approveOrRejectSubmissionError(error.message));
    }
  };
}

export function rejectComplianceSubmission({ submissionKey, rejectedReason, engineKey, itemKey, candidateKey }) {
  return async (dispatch, getState) => {

    dispatch(complianceActions.isApprovingOrRejectingSubmission(submissionKey));

    try {

      let response;

      // Call engine specific helper
      if (engineKey === 'certificate') {
        response = await complianceApi.rejectCertificate({ submissionKey, rejectedReason });
      } else if (engineKey === 'dbs-update') {
        response = await complianceApi.rejectDbsUpdateSubmission({ submissionKey, rejectedReason });
      } else if (engineKey === 'gmc' || engineKey === 'nmc') {
        response = await complianceApi.rejectProfRegSubmission({ submissionKey, engineKey, rejectedReason });
      } else {
        throw new Error(`Unknown engineKey: ${engineKey}`);
      }

      // Fetch compliance item to get latest changes and update overall candidate compliance status
      await dispatch(fetchComplianceItem(itemKey, candidateKey));
      dispatch(updateCandidateComplianceStatus(candidateKey, response.adminComplianceStatusColor, response.adminComplianceStatusLabel));

    } catch (error) {
      dispatch(complianceActions.approveOrRejectSubmissionError(error.message));
    }
  };
}

