import React from 'react';
import { MdClose } from 'react-icons/md';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { capitalize } from 'lib/helpers';
import colors from 'config/colors';
import Pill from 'components/Pill';
import './style.scss';
import { formatHourlyRate } from '../Jobs/helpers';

const styles = {
  listItemLabel: {
    width: '30%',
    // opacity: '0.7',
    fontWeight: 600,
  },
  listItemValue: {
    width: '70%',
  },
};

export const TimesheetDetailsHeader = (props) => {

  let timesheetStatus = props.booking.timesheetStatus ? capitalize(props.booking.timesheetStatus).replace('-', ' ') : 'Awaiting Submission';
  if (props.booking.timesheetStatus !== 'approved' && props.booking.timesheetIsLocked) timesheetStatus = 'Locked';

  return (
    <div className="timesheet-header">
      <div className="timesheet-header__bar">
        <h3 className="timesheet-header__title">
          {`Timesheet${props.ratingsFeatureIsOn ? ' & Rating' : ''}`}
        </h3>
        <div className="timesheet-header__close-button" onClick={() => props.onRequestClose()}><MdClose size={24} /></div>
      </div>
      <div className="timesheet-header__details">
        <div className="timesheet-header__column">
          <div className="timesheet-header__column--left space-children-6--bottom">
            <p className="timesheet-header__text" style={{ fontWeight: 600, color: colors.text }}>Shift Ref</p>
            <p className="timesheet-header__text" style={{ fontWeight: 600, color: colors.text }}>Candidate</p>
            <p className="timesheet-header__text" style={{ fontWeight: 600, color: colors.text }}>Status</p>
            {props.shift.customHourlyRate && <p className="timesheet-header__text">Hourly Rate</p>}
          </div>
          <div className="timesheet-header__column--right space-children-6--bottom">
            <p className="timesheet-header__text">{props.shift.shiftNumber}</p>
            <p className="timesheet-header__text">{props.booking.candidateName}</p>
            <p className="timesheet-header__text">{timesheetStatus}</p>
            {props.shift.customHourlyRate && <p className="timesheet-header__text">{`£${formatHourlyRate(props.shift.customHourlyRate)}`}</p>}
          </div>
        </div>
        <div className="timesheet-header__column" style={{ flexDirection: 'column' }}>
          <div style={{ display: 'flex', flex: 1 }} className="space-children-6--bottom">
            <p className="timesheet-header__text" style={styles.listItemLabel}>Site</p>
            <p className="timesheet-header__text" style={styles.listItemValue}>{props.shift.siteName}</p>
          </div>
          {props.shift.areaName && (
            <div style={{ display: 'flex', flex: 1 }} className="space-children-6--bottom">
              <p className="timesheet-header__text" style={styles.listItemLabel}>Area</p>
              <p className="timesheet-header__text" style={styles.listItemValue}>{props.shift.areaName}</p>
            </div>
          )}
          <div style={{ display: 'flex', flex: 1 }} className="space-children-6--bottom">
            <p className="timesheet-header__text" style={styles.listItemLabel}>Date</p>
            <p className="timesheet-header__text" style={styles.listItemValue}>{convertFromUtcToTimezone(props.shift.startTime, props.shift.timezone).format('DD MMMM YYYY')}</p>
          </div>

          <div style={{ display: 'flex', flex: 1 }} className="space-children-6--bottom">
            <p className="timesheet-header__text" style={styles.listItemLabel}>Time</p>
            <p className="timesheet-header__text" style={styles.listItemValue}>{`${convertFromUtcToTimezone(props.shift.startTime, props.shift.timezone).format('HH:mm')} - ${convertFromUtcToTimezone(props.shift.endTime, props.shift.timezone).format('HH:mm')}`}</p>
          </div>

        </div>
        <div className="timesheet-header__column">
          <div className="timesheet-header__column--left space-children-6--bottom">
            <p className="timesheet-header__text">Role</p>
            <p className="timesheet-header__text">Grade</p>
            <p className="timesheet-header__text">Speciality</p>
            {props.servicesFeatureFlag && <p className="timesheet-header__text">Service</p>}
          </div>
          <div className="timesheet-header__column--right space-children-6--bottom">
            <p className="timesheet-header__text">{props.booking.roleName}</p>
            <p className="timesheet-header__text">{props.booking.gradeName}</p>
            <p className="timesheet-header__text">{props.shift.specialityName || 'Any'}</p>
            {props.servicesFeatureFlag && <p className="timesheet-header__text">{props.shift.serviceName}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
