import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdSearch, MdClose } from 'react-icons/md';
import Loading from '../Loading';
import Button from '../Button';
import './SearchBox.css';

const { func, string, bool, object } = PropTypes;


class SearchBox extends Component {

  static propTypes = {
    noResultMessage: string,
    placeholder: string,
    search: func.isRequired,
    expand: bool,
    style: object,
    fullWidth: bool,
  }

  static defaultProps = {
    noResultMessage: 'No results found!',
    placeholder: 'Search...',
    expand: null,
    style: null,
    fullWidth: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      text: '',
      noResult: false,
    };
  }

  search = async () => {
    this.setState({ searching: true });
    const result = await this.props.search(this.state.text);
    this.setState({ searching: false, text: '', noResult: !result, expand: !result });
  }

  handleKeyPress = async (event) => {
    if (this.state.text && event.key === 'Enter') this.search();
  }

  handleClick = () => {
    if (this.state.expand && this.state.text) {
      this.search();
    } else if (this.state.noResult) {
      this.setState({ noResult: false });
    } else {
      this.setState(prevState => ({ expand: !prevState.expand }));
    }
  }

  render() {

    const fullWidth = !!this.props.fullWidth;
    const isExpanded = this.props.expand ?? this.state.expand;

    return (
      <div className="search-box__container" style={{ width: fullWidth ? '100%' : 'fit-content', ...this.props.style }}>
        <span className={`search-box__text-container--${isExpanded ? 'open' : 'closed'}`} style={{ width: fullWidth ? '100%' : 'fit-content' }}>
          <input
            className="search-box__text-input"
            type="text"
            onChange={event => this.setState({ text: event.target.value })}
            placeholder={this.props.placeholder}
            value={this.state.text}
            onKeyPress={this.handleKeyPress}
          />
          {this.state.searching && (<span className="search-box__loading-container"><Loading size={30} /></span>)}
          {this.state.noResult && (
          <div
            className="search-box__no-result"
            onClick={() => this.setState({ noResult: false })}
          >
            <p style={{ marginLeft: '6px' }}>{this.props.noResultMessage}</p>
            <MdClose
              className="search-box__close-button"
              style={{ marginBottom: '2px', marginRight: '6px' }}
              size="18"
            />
          </div>)}
        </span>
        <span className="search-box__button-container">
          <Button
            style={{ padding: '5.5px 5px 5.5px 10px' }}
            onClick={() => this.handleClick()}
            disabled={this.state.searching}
            black
          >
            <MdSearch size="20" />
          </Button>
        </span>
      </div>
    );
  }
}

export default SearchBox;
