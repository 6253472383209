import React from 'react';
import propTypes from 'prop-types';
import './SectionSeparator.scss';

const SectionSeparator = (props) => {
  return <div className="section-separator">{props.label}</div>;
};

SectionSeparator.propTypes = {
  label: propTypes.string.isRequired,
};

export default SectionSeparator;
