import React, { useState } from 'react';
import { MdFileDownload } from 'react-icons/md';

import { downloadCertificateImage } from 'lib/api/compliance';
import downloadFile from 'lib/helpers-download-file';

import colors from 'config/colors';
import FullSizeModal from 'components/FullSizeModal';
import CloseButton from 'components/CloseButton';
import Button from 'components/Button';
import Loading from 'components/Loading';

interface FullSizeImageModalProps {
  onRequestClose: () => void,
  image: string,
  fileKey: string,
  submissionKey: string,
}

const FullSizeImageModal : React.FC<FullSizeImageModalProps> = (props: FullSizeImageModalProps): React.ReactElement => {

  const [imageIsDownloading, setImageIsDownloading] = useState(false);
  const [imagedDownloadError, setImagedDownloadError] = useState<string | null>(null);

  const downloadImage = async (submissionKey: string, fileKey: string) => {
    try {
      setImageIsDownloading(true);
      const response = await downloadCertificateImage(submissionKey, fileKey);

      downloadFile(response.filename, response.mimeType, response.body);
      props.onRequestClose();
    } catch (error) {
      if (error instanceof Error) setImagedDownloadError(error.message);
      setImageIsDownloading(false);
    }
  };

  if (imagedDownloadError) {
    return (
      <FullSizeModal isOpen onRequestClose={props.onRequestClose}>
        <CloseButton inverted handleClose={props.onRequestClose} top={30} right={30} size={32} />

        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ color: colors.red, marginBottom: 6 }}>{imagedDownloadError}</p>
          <Button
            outline
            icon={<MdFileDownload style={{ marginRight: 6 }} />}
            onClick={() => {
              downloadImage(props.submissionKey, props.fileKey);
            }}
          >
            Retry
          </Button>
        </div>
      </FullSizeModal>
    );
  }

  return (
    <FullSizeModal isOpen onRequestClose={props.onRequestClose}>
      <CloseButton inverted handleClose={props.onRequestClose} top={12} right={12} size={24} />
      {imageIsDownloading ?
        <Loading flex />
        :
        <>
          <div className="submission-full-size" style={{ backgroundImage: `url(${props.image})` }} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 12 }}>
            <Button
              outline
              icon={<MdFileDownload style={{ marginRight: 6 }} />}
              onClick={() => downloadImage(props.submissionKey, props.fileKey)}
            >
              Download
            </Button>
          </div>
        </>
      }
    </FullSizeModal>
  );
};

export default FullSizeImageModal;
