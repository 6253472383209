import React, { useState } from 'react';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { convertFromUtcToTimezone } from 'lib/helpers-time';

import FieldLabel from './FieldLabel';
import '../CreateShift/createShift.scss';

const styles = {
  datePickerContainer: {
    display: 'flex',
    alignItems: 'stretch',
  },
  datePickerBumperButton: {
    padding: '0 9px',
    color: '#333',
    fontSize: '1em',
    cursor: 'pointer',
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #CCC',
  },
};

interface DateFieldProps {
  onChange: (val: moment.Moment | null) => void,
  isOutsideRange?: (date: moment.Moment) => boolean,
  timezone: string,
  date: string,
  disabled?: boolean
  label?: string
}

function DateField(props: DateFieldProps) {

  const [datePickerIsFocused, setDatePickerIsFocused] = useState(false);
  const timezoneDate = convertFromUtcToTimezone(props.date, props.timezone);

  return (
    <FieldLabel label={props.label ?? 'Date'}>
      <div style={styles.datePickerContainer}>

        <div className="createShiftDatePicker">
          <SingleDatePicker
            displayFormat="ddd Do MMM YYYY"
            placeholder="Select Date"
            date={timezoneDate}
            onDateChange={props.onChange}
            focused={datePickerIsFocused}
            onFocusChange={({ focused }) => setDatePickerIsFocused(focused)}
            openDirection="down"
            numberOfMonths={1}
            firstDayOfWeek={1}
            showDefaultInputIcon
            inputIconPosition="after"
            isOutsideRange={props.isOutsideRange}
            id="id"
            small
            disabled={props.disabled}
          />
        </div>

        <div
          role="button"
          tabIndex={0}
          style={{ ...styles.datePickerBumperButton, borderLeft: 'none', userSelect: 'none' }}
          onClick={() => props.onChange(timezoneDate.clone().subtract(1, 'day'))}
        >
          <MdChevronLeft size={16} />
        </div>

        <div
          role="button"
          tabIndex={0}
          style={{ ...styles.datePickerBumperButton, borderLeft: 'none', borderTopRightRadius: 2, borderBottomRightRadius: 2, userSelect: 'none' }}
          onClick={() => props.onChange(timezoneDate.clone().add(1, 'day'))}
        >
          <MdChevronRight size={16} />
        </div>
      </div>
    </FieldLabel>
  );
}

DateField.defaultProps = { disabled: false, isOutsideRange: () => false, label: 'Date' };

export default React.memo(DateField);
