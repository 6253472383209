import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { permissions } from 'config/permissions';
import { startWatchingSuppliers, stopWatchingSuppliers } from 'thunks/suppliers';
import { setShiftDetails } from 'reducers/jobs';
import { requestAgency, approveAgency, releaseToAgency as releaseShiftToAgency, retractAgencyRequest } from 'lib/api/jobs';

import Button from 'components/Button';
import Loading from 'components/Loading';

import AgencyReleaseModal from './AgencyReleaseModal';
import WarningModal from '../WarningModal';
import '../styles.scss';

const { bool, string, func, object, objectOf } = PropTypes;
class AgencyRelease extends Component {
  static propTypes = {
    jobId: string.isRequired,
    job: objectOf(object).isRequired,
    orgKey: string.isRequired,
    setShiftDetails: func.isRequired,
    showBookingsButtons: bool,
    onShiftChange: func.isRequired,
    canViewAgencyRequests: bool.isRequired,
    canSendAgencyRequests: bool.isRequired,
    canApproveAgencyRequests: bool.isRequired,
  }

  static defaultProps = {
    showBookingsButtons: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      agencyReleaseModalOpen: false,
      warningModalText: null,
      isChangingAgencyStatus: false,
    };
  }

  releaseToAgency = async (supplierKeys) => {
    this.setState({ isChangingAgencyStatus: true });

    try {
      // Call api
      const response = await releaseShiftToAgency(this.props.jobId, supplierKeys);
      // Update shift details for side panel
      this.props.setShiftDetails(this.props.jobId, response.updatedShiftFromSql);
      // Update shift list
      await this.props.onShiftChange();
      this.setState({ isChangingAgencyStatus: false, agencyReleaseModalOpen: false });

    } catch (error) {
      this.setState({
        agencyReleaseError: error.message,
        isChangingAgencyStatus: false,
      });
    }
  }

  retractAgencyRequest = async () => {
    const { job, jobId } = this.props;
    if (job && job.agencySlotsFilled) {
      this.setState({ warningModalText: 'Please cancel all agency bookings before retracting agency request.' });
    } else {
      this.updateAgency(this.props.jobId, () => retractAgencyRequest(jobId));
    }
  }

  getShiftStatus = (job) => {
    if (!job.agencyRequested && !job.autoVended) {
      return 'Not released to agency';
    } else if (!job.agencyRequested && job.autoVended) {
      return 'Auto released to agency';
    } else if (job.agencyRequested && !job.agencyApproved) {
      return 'Agency requested';
    } else if (job.agencyApproved && !job.suppliers) {
      return 'Agency Approved';
    } else if (job.suppliers) {
      return 'Released to agency';
    }
    return null;
  }

  async updateAgency(jobId, agencyFunction) {
    this.setState({ isChangingAgencyStatus: true });
    const response = await agencyFunction();
    const { updatedShiftFromSql } = response.body;
    this.props.setShiftDetails(jobId, updatedShiftFromSql);
    await this.props.onShiftChange();
    this.setState({ isChangingAgencyStatus: false });
  }

  render() {
    const job = this.props.job;
    const { showBookingsButtons, jobId } = this.props;
    const supplierNames = job.suppliers && Object.values(job.suppliers);
    return (
      <div className="shiftDetailsBookingsButtonsContainer">
        <div className="manage-agency-section">
          <p className="manage-agency-section__status-label">Status</p>
          <p className="manage-agency-section__status-value">{this.getShiftStatus(job)}</p>
        </div>

        <>
          {supplierNames &&
          <div className="shiftDetailsSubSectionContent">
            <div className="shiftDetailsBoldHeadings">
              <p className="detail-list-item">Released to</p>
            </div>
            <div className="shiftDetailsValues">
              <p className="detail-list-item">{supplierNames.map((name) => { return <p>{name}</p>; })}</p>
            </div>
          </div>
}
          <div style={{ alignSelf: 'flex-end', marginTop: 12 }}>
            {this.state.isChangingAgencyStatus ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loading size={23} />
              </div>
              :
              <>
                {!job.agencyRequested && (this.props.canSendAgencyRequests && !this.props.canApproveAgencyRequests) ?
                  <Button outline white shadow={false} onClick={() => this.updateAgency(jobId, () => requestAgency(jobId, this.props.orgKey))} disabled={!!job.cancelledAt || !!job.booked}>
                    Request Agency
                  </Button>
                  :
                  null
                }
                {job.agencyRequested && !job.agencyApproved && this.props.canApproveAgencyRequests ?
                  <Button outline white shadow={false} onClick={() => this.updateAgency(jobId, () => approveAgency(jobId))} disabled={!!job.cancelledAt}>
                    Approve Agency
                  </Button>
                  :
                  null
                }
                {job.suppliers && showBookingsButtons && this.props.canViewAgencyRequests && (
                  <Button outline white shadow={false} style={{ marginRight: 12 }} onClick={this.retractAgencyRequest} disabled={!!job.cancelledAt}>
                    Retract agency
                  </Button>
                )}
                {(!job.agencyRequested || job.agencyApproved) && showBookingsButtons && this.props.canApproveAgencyRequests ?
                  <Button outline white shadow={false} onClick={() => this.setState({ agencyReleaseModalOpen: true })} disabled={!!job.cancelledAt}>
                    {job.suppliers ? 'Release to more agencies' : 'Release to agency'}
                  </Button>
                  :
                  null
                }
              </>
              }
          </div>
        </>
        {this.state.agencyReleaseModalOpen &&
          <AgencyReleaseModal
            apiError={this.state.agencyReleaseError}
            isLoading={this.state.isChangingAgencyStatus}
            shifts={[this.props.job]}
            onClose={() => this.setState({ agencyReleaseModalOpen: false })}
            releaseToAgency={this.releaseToAgency}
          />
        }

        {this.state.warningModalText && <WarningModal isOpen contentLabel="Warning" warningModalText={this.state.warningModalText} onRequestClose={() => this.setState({ warningModalText: null })} />}

      </div>
    );
  }
}

function mapStateToProps({ global }) {
  return {
    orgKey: global.employerAuthorisedOrgs ? Object.keys(global.employerAuthorisedOrgs)[0] : null,
    canViewAgencyRequests: global.employerAuthorisedOrgs[global?.currentOrgKey]?.permissions[permissions.VMS_CAN_VIEW_AGENCY_REQUESTS],
    canSendAgencyRequests: global.employerAuthorisedOrgs[global?.currentOrgKey]?.permissions[permissions.VMS_CAN_SEND_AGENCY_REQUESTS],
    canApproveAgencyRequests: global.employerAuthorisedOrgs[global?.currentOrgKey]?.permissions[permissions.VMS_CAN_APPROVE_AGENCY_REQUESTS],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startWatchingSuppliers: orgKey => dispatch(startWatchingSuppliers(orgKey)),
    stopWatchingSuppliers: () => dispatch(stopWatchingSuppliers()),
    setShiftDetails: (shiftKey, shiftDetails) => dispatch(setShiftDetails(shiftKey, shiftDetails)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(AgencyRelease);
