import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import colors from 'config/colors';
import spacing from 'config/spacing';
import { getUserInvite, registerUser } from 'lib/userInvite';
import * as userThunks from 'thunks/user';

import { checkPasswordRequirements } from './password-requirements';
import Registration from './Registration';
import Login from './ui/LoginForm';
import AuthPage from './ui/AuthPage';
import AcceptanceDocumentModal from './ui/AcceptanceDocumentModal';

const styles = {
  messageContainer: {
    borderRadius: 2,
    marginTop: spacing.base,
    padding: spacing.base,
    textAlign: 'center',
    color: colors.positive,
  },
};

const { func, shape, string } = PropTypes;
class RegistrationContainer extends Component {

  static propTypes = {
    dispatch: func.isRequired,
    formValues: shape({
      email: string,
      password: string,
      confirmPassword: string,
      firstName: string,
      surname: string,
    }),
  }

  static defaultProps = {
    formValues: null,
  }

  constructor() {
    super();
    this.state = {
      isFetchingInvite: true,
      attemptingLogin: false,
      showForm: true,
      showAcceptanceDocumentModal: false,
      selectedAcceptanceDocumentKey: null,
      formValues: {},
    };
  }

  async componentDidMount() {
    const response = await getUserInvite(this.props.userId, this.props.match.params.inviteCode);
    const invite = response.userInvite;

    if (response && response.action === 'showRegisterPage') {
      try {
        this.props.dispatch(userThunks.logout(false));
      } catch (e) {
        console.log('Logout failed', e);
      }
    }

    if (invite && response.acceptanceDocuments) {

      const acceptedDocumentsFormValues = {};
      response.acceptanceDocuments.forEach((doc) => { acceptedDocumentsFormValues[doc.key] = false; });

      this.setState({
        isFetchingInvite: false,
        formValues: {
          email: invite.email || '',
          firstName: invite.firstName || '',
          middleNames: invite.middleNames || '',
          surname: invite.surname || '',
          password: '',
          confirmPassword: '',
          acceptedDocuments: acceptedDocumentsFormValues,
        },
        regError: '',
        response,
        showForm: true,
        invite,
      });
    } else {
      this.setState({
        isFetchingInvite: false,
        response,
        regError: 'Invite code not recognised. Please contact your employer.',
        showForm: false,
      });
    }
  }

  setFormValue = (key, value) => {
    this.setState(state => ({
      ...state,
      formValues: { ...state.formValues, [key]: value },
    }));
  }

  register = async () => {
    this.setState({ regError: null });

    const isCandidate = !!this.state.response.isCandidate;
    const vals = this.state.formValues || {};

    // Create acceptedDocuments array for api payload
    const acceptedDocumentsPayload = Object.entries(vals.acceptedDocuments).map(([documentKey, isAccepted]) => {
      const documentMetadata = this.state.response.acceptanceDocuments.find(doc => doc.key === documentKey);
      return { key: documentKey, isAccepted, version: documentMetadata.version };
    });
    const options = isCandidate ? {
      inviteCode: this.props.match.params.inviteCode,
      email: vals.email,
      password: vals.password,
      acceptedDocuments: acceptedDocumentsPayload,
    } : {
      inviteCode: this.props.match.params.inviteCode,
      firstName: this.trimAndTitleCase(vals.firstName),
      middleNames: this.trimAndTitleCase(vals.middleNames),
      surname: this.trimAndTitleCase(vals.surname),
      email: vals.email,
      password: vals.password,
      confirmPassword: vals.confirmPassword,
      acceptedDocuments: acceptedDocumentsPayload,
    };

    if (!isCandidate) {
      // Validate form fields
      const errors = { ...this.validateNames(options), ...this.validatePasswords(options) };
      const hasErrors = Object.values(errors).some(errs => errs.length);
      if (hasErrors) {
        this.setState({ regError: Object.values(errors).flat().join('\n') });
        return;
      }

      const passwordRequirementsWithStatus = checkPasswordRequirements(this.state.response.passwordRequirements, options.password);
      const hasPasswordErrors = passwordRequirementsWithStatus.some(req => !req.isPassing);
      if (hasPasswordErrors) {
        return;
      }
    }


    this.setState({ attemptingLogin: true, showForm: true });

    try {
      await registerUser(options);
      let loginSuccess;
      try {
        loginSuccess = await this.props.dispatch(userThunks.signIn(vals.email, vals.password));
      } catch (e) {
        loginSuccess = false;
      }

      if (loginSuccess) {
        const { history, location } = this.props;
        history.push(location?.state?.redirectUrl?.state?.redirectUrl ?? '/');
        this.setState({ attemptingLogin: false });
      } else {
        this.failToSignUp();
      }
    } catch (e) {
      this.setState({
        attemptingLogin: false,
        regError: e.humanReadableErrorMessage ?? 'Error creating account, please try again. If the error persists, then contact customer support',
      });
    }
  }

  failToSignUp = () => {
    this.setState({
      attemptingLogin: false,
      regError: 'Error creating account, please try again. If the error persists, then contact customer support',
    });
  }

  trimAndTitleCase = (str) => {
    if (!str) return '';

    // If name does not contain a mix of lowercase and uppercase letters then recapitalize it.
    if (!/[a-z]/.test(str) || !/[A-Z]/.test(str)) {
      const res = str.trim();
      return res.charAt(0).toUpperCase() + res.slice(1);
    }

    return str.trim();
  }

  validateNames = (options) => {
    const errors = {
      firstName: [],
      middleNames: [],
      surname: [],
    };

    if (!options.firstName) errors.firstName.push('First name is required');
    if (!options.firstName?.length > 50) errors.firstName.push('First name must at most 50 characters');
    if (!options.middleNames?.length > 50) errors.middleNames.push('Middle names must at most 50 characters');
    if (!options.surname) errors.surname.push('Surname is required');
    if (!options.surname?.length > 50) errors.surname.push('Surname must at most 50 characters');

    return errors;
  }

  validatePasswords = (options) => {
    const errors = {
      password: [],
      confirmPassword: [],
    };

    if (!options.password) errors.password.push('A password is required.');
    if (options.confirmPassword !== options.password) errors.confirmPassword.push('Passwords do not match.');

    return errors;
  }

  acceptDocument = (documentKey) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showAcceptanceDocumentModal: false,
        selectedAcceptanceDocumentKey: null,
        formValues: {
          ...prevState.formValues,
          acceptedDocuments: {
            ...prevState.formValues.acceptedDocuments,
            [documentKey]: true,
          },
        },
      };
    });
  }

  render() {

    if (this.state.isFetchingInvite || this.state.attemptingLogin) {
      return (
        <AuthPage loading />
      );
    }

    const { action, email, isCandidate, passwordRequirements, acceptanceDocuments } = this.state.response;

    const acceptanceDocumentsWithStatus = (acceptanceDocuments ?? []).map(doc => ({ ...doc, isAccepted: this.state.formValues.acceptedDocuments[doc.key] }));

    switch (action) {

      case 'redirectToRoot':
        return <Redirect to="/" />;

      case 'redirectToLoginWithMessage':
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { loginMessage: `This account (${email}) is already registered, please login.` },
            }}
          />
        );

      default: {

        if (isCandidate) {
          return (
            <AuthPage header="Register as admin">
              <Login
                emailDisabled
                message="As you already have a staff account, simply login with your existing password to enable administrator access."
                formValues={this.state.formValues}
                setFormValue={this.setFormValue}
                login={this.register}
                acceptanceDocuments={acceptanceDocumentsWithStatus}
                displayAcceptanceDocument={key => this.setState({ showAcceptanceDocumentModal: true, selectedAcceptanceDocumentKey: key })}
              />
              {!!this.state.regError && (
                <div style={styles.messageContainer}>
                  <p>{this.state.regError}</p>
                </div>
              )}
              {this.state.showAcceptanceDocumentModal && (
                <AcceptanceDocumentModal
                  document={acceptanceDocuments.find(doc => doc.key === this.state.selectedAcceptanceDocumentKey)}
                  onClose={() => this.setState({ showAcceptanceDocumentModal: false, selectedAcceptanceDocumentKey: null })}
                  onAccept={this.acceptDocument}
                />
              )}
            </AuthPage>
          );
        }

        return (
          <>
            <Registration
              passwordRequirements={passwordRequirements}
              email={this.state.email}
              register={this.register}
              regError={this.state.regError}
              showForm={this.state.showForm}
              invite={this.state.invite}
              formValues={this.state.formValues}
              setFormValue={this.setFormValue}
              acceptanceDocuments={acceptanceDocumentsWithStatus}
              displayAcceptanceDocument={key => this.setState({ showAcceptanceDocumentModal: true, selectedAcceptanceDocumentKey: key })}
            />
            {this.state.showAcceptanceDocumentModal && (
              <AcceptanceDocumentModal
                document={acceptanceDocuments.find(doc => doc.key === this.state.selectedAcceptanceDocumentKey)}
                onClose={() => this.setState({ showAcceptanceDocumentModal: false, selectedAcceptanceDocumentKey: null })}
                onAccept={this.acceptDocument}
              />
            )}
          </>
        );
      }
    }
  }
}

function mapStateToProps({ user }) {
  return {
    userId: user.userId,
  };
}

export default connect(mapStateToProps)(withRouter(RegistrationContainer));
