import React from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import Loading from 'components/Loading';

import colors from 'config/colors';

import { Submission } from 'types/ComplianceTypes';

import { SubmissionRejectModal } from './SubmissionRejectModal';
import './ComplianceTab.scss';

const styles = {
  statusCircle: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    marginRight: 3,
  },
};

interface SubmissonBubbleProps {
  submission: Submission,
  adminHasWriteAccess: boolean,
  submissionRejectModalIsOpen: boolean,
  toggleSubmissionRejectModal: (toggle: boolean) => void,
  approveSubmission: () => void,
  rejectSubmission: (reason: string) => void,
  children: React.ReactNode,
}

export default function SubmissonBubble(props: SubmissonBubbleProps): React.ReactElement {

  const selectedSubmissionKey = useSelector(state => state.compliance.selectedSubmissionKey);
  const isApprovingOrRejectingSubmission = useSelector(state => state.compliance.isApprovingOrRejectingSubmission);
  const approveOrRejectSubmissionError = useSelector(state => state.compliance.approveOrRejectSubmissionError);

  const { submissionKey, title, status, submittedAt, adminCanApprove, adminCanReject } = props.submission;

  return (
    <div className="submission">
      <div className="submission-header">
        <div className="submission-header-top">
          <div className="submission-header-top__details">
            <div className="submission-header-title">
              <h4 className="submission-header-title__title-text">{title}</h4>
              <SubmissionStatus status={status} />
            </div>
            <p className="submission-header-sub-title__date">
              {submittedAt}
            </p>
          </div>
          {props.adminHasWriteAccess && (
            <div className="submission-header-top__buttons space-children-12">
              {isApprovingOrRejectingSubmission && selectedSubmissionKey === submissionKey && <Loading size={40} />}
              <Button
                style={{ backgroundColor: colors.cavalry.error }}
                large
                shadow={false}
                onClick={() => props.toggleSubmissionRejectModal(true)}
                disabled={!adminCanReject || isApprovingOrRejectingSubmission}
              >
                Reject
              </Button>
              <Button
                large
                black
                shadow={false}
                onClick={() => props.approveSubmission()}
                disabled={!adminCanApprove || isApprovingOrRejectingSubmission}
              >
                Approve
              </Button>
            </div>
          )}
        </div>
        {approveOrRejectSubmissionError && (
          <ErrorMessage errorMessage="Oops! Something went wrong. Please try again. If the problem persists, please contact customer support." />
        )}
        {props.submissionRejectModalIsOpen && (
          <SubmissionRejectModal
            modalOpen={props.submissionRejectModalIsOpen}
            rejectSubmission={(reason: string) => props.rejectSubmission(reason)}
            onClose={() => props.toggleSubmissionRejectModal(false)}
          />
        )}
      </div>
      {props.children}
    </div>
  );
}

interface SubmissionStatusProps {
  status: {
    label: string,
    color: string,
  },
}

function SubmissionStatus({ status }: SubmissionStatusProps) : React.ReactElement {
  return (
    <div className="submission-header-title__submission-status">
      <div style={{ ...styles.statusCircle, backgroundColor: status.color ?? colors.grays.light }} />
      <p style={{ color: status.color ?? colors.grays.light, fontWeight: 500 }}>
        {status.label}
      </p>
    </div>
  );
}

function ErrorMessage(props: { errorMessage: string }): React.ReactElement {
  return (
    <div className="submission-header-bottom">
      <p className="submission-header-bottom__permission-message">{props.errorMessage}</p>
    </div>
  );
}
