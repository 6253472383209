import React from 'react';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';
import AuditTrail from '../../../components/AuditTrail';
import Loading from '../../../components/Loading';
import colors from '../../../config/colors';

const AuditTab = props => (
  <div className="shiftDetailsViewContainer">
    {props.auditTrailMessages.length ?
      <AuditTrail
        items={props.auditTrailMessages.sort(firstBy('createdAt')).map((message) => {
          return {
            title: props.userId === message.fromUserKey ? 'You' : message.fromUserName,
            message: message.text,
            when: message.createdAt,
            imageUrl: message.imageUrl || message.profileImageUrl,
          };
        })}
      />
      : null
    }
    {!props.auditTrailMessages.length && !props.fetchingAuditTrail && (
      <div style={{ color: colors.positive, textAlign: 'center', marginTop: 20 }}>
        <p>No shift details</p>
      </div>
    )}
    {props.fetchingAuditTrail && (
      <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Loading />
      </div>
    )}
  </div>
);


const { arrayOf, shape, bool, string } = PropTypes;

AuditTab.propTypes = {
  auditTrailMessages: arrayOf(shape({
    fromUserKey: string.isRequired,
    fromUserName: string.isRequired,
    text: string.isRequired,
    createdAt: string.isRequired,
  })),
  fetchingShift: bool.isRequired,
  userId: string,
};

AuditTab.defaultProps = {
  auditTrailMessages: [],
  userId: null,
};

export default AuditTab;
