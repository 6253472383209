import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../../../components/Button';
import colors from '../../../config/colors';
import spacing from '../../../config/spacing';
import fonts from '../../../config/fonts';

import Modal from '../../../components/Modal';
import CloseButton from '../../../components/CloseButton';

import downloadFile from '../../../lib/helpers-download-file';
import convertToCSV from './csv-export';


const styles = {
  header: {
    color: colors.text,
    fontSize: fonts.header.size,
    fontWeight: fonts.header.weight,
    // marginTop: spacing.base,
    width: '100%',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.cavalry.line}`,
    padding: 12,
  },
  cancelButton: {
    width: '100%',
    backgroundColor: colors.negative,
  },
  exportButton: {
    width: '100%',
  },
};


class ExportCSVModal extends React.Component {

  convertToCSV() {
    return convertToCSV(this.props.candidates, this.props.globalMetadata, this.props.customFieldsMetadata, this.props.global, this.props.user);
  }

  downloadCSV() {
    const filename = 'candidate-list.csv';
    const mimeType = 'text/csv;charset=utf-8;';
    const csvData = this.convertToCSV();
    downloadFile(filename, mimeType, csvData);
  }

  render() {
    return (
      <Modal
        header="Export candidate data"
        isOpen
        onRequestClose={this.props.onClose}
        contentStyle={{ maxWidth: 500 }}
      >
        <CloseButton handleClose={this.props.onClose} top={15} right={15} />
        <div style={{ padding: 12 }}>
          <p>
            Note that only candidates which match your currently selected filters will be exported.
          </p>
        </div>
        <div style={{ padding: 12 }}>
          <p>
            To export a different set of
            candidates, please adjust the filters at the top of the screen first.
          </p>
        </div>
        <div style={styles.buttonContainer} className="space-children-6">
          <Button white outline onClick={this.props.onClose} className="test-id-export-cancel">Cancel</Button>
          <Button onClick={() => { this.downloadCSV(); this.props.onClose(); }}>Confirm</Button>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps({ global, user }) {
  const candidateCustomFildsMetadata = global.customFieldsMetadata.filter(field => field.entity === 'candidate');

  return {
    globalMetadata: global.metadata,
    customFieldsMetadata: candidateCustomFildsMetadata,
    metadataFields: global.orgInviteMetadata,
    global,
    user,
  };
}

const { func, arrayOf, object, string, objectOf, oneOfType, shape, bool, number } = PropTypes;
ExportCSVModal.propTypes = {
  onClose: func.isRequired,
  candidates: arrayOf(object).isRequired,
  metadataFields: object.isRequired,
  globalMetadata: object.isRequired,
  global: object.isRequired,
  user: object.isRequired,
  customFieldsMetadata: arrayOf(shape({
    fieldKey: string.isRequired,
    entity: string.isRequired,
    fieldName: string.isRequired,
    description: string.isRequired,
    type: string.isRequired,
    parameters: objectOf(
      oneOfType([
        number, string, bool, arrayOf(
          shape({ name: string, value: string }),
        ),
      ]),
    ),
    adminCanEdit: bool.isRequired,
    candidateCanEdit: bool.isRequired,
    requiredAtInvite: bool.isRequired,
  })),
};

ExportCSVModal.defaultProps = {
  customFieldsMetadata: [],
};

export default connect(mapStateToProps)(ExportCSVModal);
