import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { firstBy } from 'thenby';
import * as api from 'lib/api';
import * as jobsApi from 'lib/api/jobs';
import { composeDistanceAndHoursText, checkIfBookedCandidateExceedsContractedHours } from 'routes/Jobs/helpers';
import Button from 'components/Button';
import Loading from 'components/Loading';
import CandidateUploadModal from 'components/CandidateUploadModal';
import { featureFlags } from 'config/featureFlags';
import { permissions } from 'config/permissions';
import Feature from 'components/Feature';
import { setShiftDetails, setShiftApplicationsAndBookings } from 'reducers/jobs';
import colors from 'config/colors';
import { capitalize } from 'lib/helpers';
import { ManageBanks } from './ManageBanks';
import WarningModal from '../WarningModal';
import ApplicantCard from './ApplicantCard';
import BookingCard from './BookingCard';
import CancelBookingModal from './CancelBookingModal';
import ClashBookingModal from './ClashBookingModal';
import ConfirmBookingModal from './ConfirmBookingModal';
import ContractedHoursWarningModal from './ContractedHoursWarningModal';
import TargetBankCandidateForm from './TargetBankCandidateForm';
import TargetSupplierCandidateForm from './TargetSupplierCandidateForm';
import RejectApplicantModal from './RejectApplicantModal';
import RejectedApplicationBookingModal from './RejectedApplicationBookingModal';
import ManageAgencies from './ManageAgencies';
import { isFeatureOn } from '../../../../lib/features';

import '../styles.scss';

const { func, string, objectOf, object, bool, shape, arrayOf } = PropTypes;
class BookingsTab extends Component {
  static propTypes = {
    metadata: object.isRequired,
    isSupplier: bool.isRequired,
    orgKey: string.isRequired,
    isFetchingSelectedJobApplicants: bool.isRequired,
    hideTargetCandidate: bool,
    userId: string.isRequired,
    job: objectOf(object).isRequired,
    jobId: string.isRequired,
    updateShift: func.isRequired,
    fetchShiftApplicants: func.isRequired,
    onShiftChange: func.isRequired,
    selectedJobApplicants: arrayOf(object),
    agencySubmittedApplicants: arrayOf(object),
    bookedCandidates: arrayOf(object).isRequired,
    canDisplayCandidateRating: bool.isRequired,
  }

  static defaultProps = {
    selectedJobApplicants: null,
    agencySubmittedApplicants: null,
    hideTargetCandidate: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      clashes: false,
      warnings: [],
      clashingShifts: {},
      showCancelModal: false,
      showWarningModal: false,
      warningsAccepted: false,
      warningModalText: '',
      applicantWithWarnings: null,
      showConfirmBookingPopup: false,
      bookingCandidate: false,
      autoBookingCandidate: false,
      cancellingBooking: false,
      bookingCandidateId: '',
      selectedCandidate: null,
      shiftError: null,
      contract: null,
      showContractedHoursWarningModal: false,
      showRejectApplicantModal: false,
      candidateToBook: null,
      rejectedApplication: null,
      showRejectedApplicationBookingModal: false,
      cancelBookingKey: null,
    };
  }

  cancelBooking = async () => {
    this.setState({ cancellingBooking: true, showCancelModal: false });
    const response = await api.post('jobs/cancel-booking', { jobId: this.props.jobId, bookingKeys: [this.state.cancelBookingKey], eventName: 'cancel-booking' });
    if (response.status > 200) {
      this.setState({ shiftError: 'An error has occured. Please try again', cancellingBooking: false });
    } else {
      // Save updated shift to state
      this.props.setShiftApplicationsAndBookings(this.props.jobId, response.body.shiftAppsAndBookingsFromSql);
      await this.props.onShiftChange();
      this.setState({ cancellingBooking: false });
    }
  }

  showFullyBookedWarning = () => {
    this.setState({
      showWarningModal: true,
      warningModalText: 'All slots have been filled. To book this candidate either cancel another booking or increase number of slots.',
    });
  }

  showSelfBookingWarning = () => {
    this.setState({
      showWarningModal: true,
      warningModalText: 'You are not permitted to book shifts that you will do yourself.',
    });
  }

  closeWarningModal = () => {
    this.setState({
      showWarningModal: false,
      warningModalText: '',
    });
  }

  cancel = (bookingKey) => {
    this.setState({
      showCancelModal: true,
      cancelBookingKey: bookingKey,
    });
  }

  closeCancel = () => {
    this.setState({
      showCancelModal: false,
      cancelBookingKey: null,
    });
  }

  showWarningPopUp = (warnings, applicant) => {
    this.setState({ showConfirmBookingPopup: true, warnings, applicantWithWarnings: applicant });
  }

  closeClashModal = () => this.setState({ clashes: false, clashingShifts: {} });

  orderGrades = (applicant) => {
    const grades = applicant.grades;
    const { metadata } = this.props;
    const role = applicant.role;
    let linkedGrades;
    if (role) {
      linkedGrades = metadata.roles[role.id].linkedGrades; // eslint-disable-line
    }
    if (grades) {
      return Object.keys(grades)
        .filter(gradeId => linkedGrades[gradeId])
        .map(gradeId => ({ gradeId, ...linkedGrades[gradeId] }))
        .sort((a, b) => a.order - b.order)[0].name;
    }
    return null;
  }

  checkIfAdminCanBookCandidate = (args) => {

    this.setState({ candidateToBook: args });

    const { job, contractedHoursFeatureIsOn } = this.props;
    const { contractedHoursPeriodType, contractedHours, minutesWorked, minutesPlanned, workingTimeDirectiveApplies } = args.candidate;

    // Check if contracted hours will be exceeded if booked into shift
    const { hoursExceedsContractedHours, hoursExceedWorkingTimeDirective, contract } = checkIfBookedCandidateExceedsContractedHours({ contractedHoursFeatureIsOn, contractedHoursPeriodType, contractedHours, minutesWorked, minutesPlanned, workingTimeDirectiveApplies, startTime: job.startTime, endTime: job.endTime });

    if (this.props.bookedCandidates > 0) {
      this.showFullyBookedWarning();
    } else if (this.props.userId === args.candidateId && !this.props.canSelfBook) {
      this.showSelfBookingWarning();
    } else if (hoursExceedsContractedHours || hoursExceedWorkingTimeDirective) {
      this.setState({ contract, showContractedHoursWarningModal: true });
    } else if (args.candidate.rejectedAt) {
      const { rejectedAt, rejectedBy, rejectedByName, publicRejectionReason, privateRejectionReason } = args.candidate;
      const rejectedApplication = { rejectedAt, rejectedBy, rejectedByName, publicRejectionReason, privateRejectionReason };
      this.setState({ rejectedApplication, showRejectedApplicationBookingModal: true });
    } else {
      this.bookCandidate(args);
    }
  }

  bookCandidate = async ({ candidate, candidateId, profRegStatus, isAgency, applicationId, autoBookedAfterReleaseToBank }) => {
    const payload = {
      jobId: this.props.jobId,
      candidateId,
      applicationId,
      orgKey: this.props.job.orgKey,
      hasAction: true,
      isAgency,
      autoBookedAfterReleaseToBank: autoBookedAfterReleaseToBank || null,
    };
    // If profReg warning, add to payload
    if (profRegStatus && profRegStatus.status === 'WARNING' && profRegStatus.warnings && profRegStatus.warnings.length > 0) {
      payload.warnings = profRegStatus.warnings;
    }
    this.setState({
      rejectedApplication: null,
      showRejectedApplicationBookingModal: false,
      showConfirmBookingPopup: false,
      bookingCandidate: !autoBookedAfterReleaseToBank,
      autoBookingCandidate: autoBookedAfterReleaseToBank,
      bookingCandidateId: candidateId });
    const response = await api.post('jobs/book', payload);
    if (response.status === 409 || response.body?.clashingShifts) {
      this.setState({
        clashes: true,
        clashingShifts: response.body.clashingShifts,
        bookingCandidateId: null,
        bookingCandidate: false,
        autoBookingCandidate: false,
      });
    } else if (response.status > 200) {
      this.setState({ shiftError: 'An error has occured. Please try again', bookingCandidate: false, autoBookingCandidate: false });
    } else {
      // Save updated shift to state
      this.props.setShiftApplicationsAndBookings(this.props.jobId, response.body.shiftAppsAndBookingsFromSql);
      await this.props.onShiftChange();
      this.setState({ bookingCandidate: false, autoBookingCandidate: false, bookingCandidateId: null, showContractedHoursWarningModal: false });
    }
  }

  rejectApplicant = async ({ candidateKey, publicRejectionReason, privateRejectionReason }) => {

    const payload = {
      shiftKey: this.props.jobId,
      candidateKey,
      publicRejectionReason: publicRejectionReason || null,
      privateRejectionReason: privateRejectionReason || null,
    };

    this.setState({ showRejectApplicantModal: false, isRejectingApplicant: true });

    try {
      const response = await jobsApi.rejectApplicant(payload);

      // Save updated shift to state
      this.props.setShiftApplicationsAndBookings(this.props.jobId, response.shiftAppsAndBookingsFromSql);
      await this.props.onShiftChange();
      this.setState({ isRejectingApplicant: false, bookingCandidateId: null, applicantWithWarnings: null });

    } catch (error) {
      this.setState({ isRejectingApplicant: false, shiftError: error.message });
    }
  }

  bookSupplierCandidate = async (supplierKey, candidateRef) => {
    const job = this.props.job;
    if (job.agencySlotsFilled + job.slotsFilled >= job.slotsTotal) {
      this.showFullyBookedWarning();
    } else {

      this.setState({ bookingSupplierCandidate: candidateRef });
      const response = await jobsApi.submitSupplierCandidate(this.props.jobId, supplierKey, { ref: candidateRef }, true);
      if (response.status === 409 || response.body?.clashingShifts) {
        this.setState({
          clashes: true,
          clashingShifts: response.body.clashingShifts,
          bookingSupplierCandidate: false,
        });
      } else if (response.status > 299) {
        this.setState({ shiftError: 'An error has occured. Please try again', bookingSupplierCandidate: false });
      } else {
        // Save updated shift to state
        this.props.setShiftApplicationsAndBookings(this.props.jobId, response.body.shiftAppsAndBookingsFromSql);
        await this.props.onShiftChange();
        this.setState({ bookingSupplierCandidate: false });
      }
    }
  }

  render() {
    const {
      job,
      jobId,
      isSupplier,
      orgKey,
      userId,
      isFetchingSelectedJobApplicants,
      selectedJobApplicants,
      bookedCandidates,
      orgHasSuppliers,
    } = this.props;

    const jobInPast = job && job.startTime && moment(job.startTime).isBefore(moment());
    const isTargeted = bookedCandidates.some(candidate => candidate.autoBooking);
    const noApplicants = (selectedJobApplicants.length < 1) && (Object.keys(this.props.agencySubmittedApplicants).length < 1);
    const jobBelongsToOrg = job.orgKey === orgKey;

    if (!job) {
      return (
        <div style={{ color: colors.positive, textAlign: 'center', marginTop: 20 }}>
          <p>No booking data</p>
        </div>
      );
    }

    // Booking an applicant: show loading spinner
    if (this.state.bookingCandidate && this.state.bookingCandidateId) {
      const candidate = selectedJobApplicants.find(applicant => applicant.candidateKey === this.state.bookingCandidateId);
      return <BookingLoading message={`Booking ${candidate ? capitalize(candidate.candidateName) : ''}...`} />;
    }

    // Auto-Booking a candidate: show loading spinner
    if (this.state.autoBookingCandidate && this.state.bookingCandidateId) {
      return <BookingLoading message={`Booking ${this.state?.selectedCandidate?.label ?? ''}...`} />;
    }

    // Cancelling a booking: show loading spinner
    if (this.state.cancellingBooking && this.state.cancelBookingKey) {
      const candidate = bookedCandidates.find(booking => booking.bookingKey === this.state.cancelBookingKey);
      return <BookingLoading message={`Cancelling ${candidate ? capitalize(candidate.candidateName) : ''}...`} />;
    }

    // Cancelling a booking: show loading spinner
    if (this.state.bookingSupplierCandidate) {
      return <BookingLoading message={`Booking ${this.state.bookingSupplierCandidate}`} />;
    }

    // Rejecting an applicant: show loading spinner
    if (this.state.isRejectingApplicant) {
      return <BookingLoading message={`Rejecting ${capitalize(this.state.applicantWithWarnings.candidateName)}`} />;
    }

    const filterByExactClashes = a => ((a.clashes?.some(clash => clash.exact) ? 1 : 0) ?? 0);
    const sortedJobApplicants = selectedJobApplicants.sort(firstBy('rejectedAt').thenBy(filterByExactClashes).thenBy('appliedAt'));
    const allSlotsFilled = bookedCandidates.length === job.slotsRequired;
    const shiftError = this.props.error || this.state.shiftError;
    return (
      <>
        <div className="sidePanelScrollViewBookingContainer">
          <>
            {bookedCandidates.length > 0 && (
              <div className="bookingsSection">
                <div className="bookingsHeading">
                  <p>Bookings</p>
                </div>
                {bookedCandidates
                  .filter(booking => !isSupplier || (booking.supplierKey === orgKey))
                  .map((booking, i) => {
                    return (
                      <>
                        <BookingCard
                          shiftKey={job.key}
                          booking={booking}
                          selectedJob={jobId}
                          hideButtonFunctions={isSupplier || (!job.canBookStaff) || (job.canBookStaff && !booking.candidateName && !booking.candidateRef)}
                          cancel={this.cancel}
                          job={job}
                          cancellingBooking={this.state.cancellingBooking}
                          goToCandidateProfile={booking.origin === 'bank' ? this.props.goToApplicantsModal : () => {}}
                          orgKey={orgKey}
                          canDisplayCandidateRating={this.props.canDisplayCandidateRating}
                          adminBanks={this.props.adminBanks}
                        />
                        <div style={{ padding: '0px 24px' }}>
                          {i < bookedCandidates.length - 1 && (
                            <div style={{ width: '100%', height: 1, backgroundColor: colors.cavalry.line }} />
                          )}
                        </div>
                      </>
                    );
                  })
            }
                  {shiftError ?
                      <div className="user-feedback-container">
                        <p className="auto-book-error-message">{shiftError}</p>
                      </div>
                    : null
                    }
              </div>
            )}
            <div className="applicationsSection">
              {!isFetchingSelectedJobApplicants ?
                <>
                  {isSupplier ?
                    <p className="applicantsHeading">Submitted Candidates</p>
                    :
                    <p className="applicantsHeading">{bookedCandidates.length ? 'Other Applicants' : 'Applicants'}</p>
                  }
                  {!isSupplier &&
                    <>
                      {sortedJobApplicants.map((applicant) => {
                        const distanceAndHoursText = composeDistanceAndHoursText({ candidate: applicant, site: this.props.site, showCandidateLocationsFeatureIsOn: this.props.showCandidateLocationsFeatureIsOn, contractedHoursFeatureIsOn: this.props.contractedHoursFeatureIsOn });
                        return (
                          <ApplicantCard
                            key={applicant.candidateKey}
                            applicant={applicant}
                            distanceAndHoursText={distanceAndHoursText}
                            grade={applicant.gradeName}
                            speciality={applicant.specialityName}
                            job={job}
                            userId={userId}
                            jobInPast={jobInPast}
                            jobId={jobId}
                            shiftHasBooking={bookedCandidates.length > 0}
                            showWarningPopUp={this.showWarningPopUp}
                            isTargeted={isTargeted}
                            bookCandidate={this.checkIfAdminCanBookCandidate}
                            bookingCandidate={this.state.bookingCandidate}
                            autoBookingCandidate={this.state.autoBookingCandidate}
                            bookingCandidateId={this.state.bookingCandidateId}
                            goToCandidateProfile={this.props.goToApplicantsModal}
                            orgKey={orgKey}
                            canDisplayCandidateRating={this.props.canDisplayCandidateRating}
                            adminBanks={this.props.adminBanks}
                            rejectApplicant={(app) => this.setState({ showRejectApplicantModal: true, applicantWithWarnings: app })}
                            shiftTimezone={job.timezone}
                            allSlotsFilled={allSlotsFilled}
                          />
                        );
                      })}
                    </>
                  }

                  <>
                    {this.props.agencySubmittedApplicants && Object.values(this.props.agencySubmittedApplicants).map((applicant) => {
                      return (
                        <ApplicantCard
                          key={applicant.candidateKey}
                          applicant={applicant}
                          grade={applicant.gradeName}
                          speciality={applicant.specialityName}
                          job={job}
                          profRegNumber={applicant.profRegNumber}
                          userId={userId}
                          agency
                          jobInPast={jobInPast}
                          jobId={jobId}
                          showWarningPopUp={this.showWarningPopUp}
                          isTargeted={isTargeted}
                          bookCandidate={this.checkIfAdminCanBookCandidate}
                          bookingCandidate={this.state.bookingCandidate}
                          autoBookingCandidate={this.state.autoBookingCandidate}
                          bookingCandidateId={this.state.bookingCandidateId}
                          allSlotsFilled={allSlotsFilled}
                        />
                      );
                    })}
                  </>

                  {noApplicants &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '12px 0px' }}>
                      <p style={{ color: colors.text, fontSize: '0.9rem' }}>{bookedCandidates.length > 0 ? 'No one else has applied to this shift' : 'No one has applied to this shift yet'}</p>
                    </div>
                  }

                  {isSupplier ?
                    <div className="shiftDetailsUploadCandidateButtonContainer">
                      <Button
                        className="shiftDetailsUploadCandidateButton"
                        onClick={() => this.setState({ candidateUploadModalOpen: true })}
                      >
                        Upload Candidate
                      </Button>
                    </div>
                    :
                    null
                  }
                </>
                :
                <>
                  {isFetchingSelectedJobApplicants ?
                    <div>
                      <Loading size={60} />
                    </div>
                    :
                    null
                }
                </>
              }
            </div>
            <Feature permissionRequired={permissions.JOBS_CAN_BOOK}>
              {(!this.props.adminIsSupplier && job.canBookStaff && !allSlotsFilled) && (
                <TargetBankCandidateForm
                  selectedJob={jobId}
                  job={job}
                  bookCandidate={this.checkIfAdminCanBookCandidate}
                  bookingCandidate={this.state.autoBookingCandidate}
                  shiftError={this.props.error || this.state.shiftError}
                  closeClashModal={this.closeClashModal}
                  showCancelModal={this.state.showCancelModal}
                  selectCandidate={candidate => this.setState({ selectedCandidate: candidate })}
                  selectedCandidate={this.state.selectedCandidate}
                />
              )}
            </Feature>
            <Feature permissionRequired={permissions.VMS_CAN_SUBMIT_AGENCY_CANDIDATES}>
              {(!this.props.adminIsSupplier && jobBelongsToOrg && !allSlotsFilled && orgHasSuppliers) && (
                <TargetSupplierCandidateForm
                  job={job}
                  bookSupplierCandidate={this.bookSupplierCandidate}
                  shiftError={this.props.error || this.state.shiftError}
                />
              )}
            </Feature>
            {!isSupplier && jobBelongsToOrg && (
              <>
                <div>
                  <div style={{ padding: '24px 12px' }}>
                    <h3 className="shiftDetailsSubHeading">Manage Banks</h3>
                    <ManageBanks
                      adminBanksMetadata={this.props.adminBanksMetadata}
                      shiftKey={jobId}
                      releasedBanks={job.banks || {}}
                      setShiftDetails={this.props.setShiftDetails}
                      onShiftChange={this.props.onShiftChange}
                      canReleaseToBank={this.props.canReleaseToBank}
                      canRetractBank={this.props.canRetractBank}
                    />
                  </div>
                  <div style={{ padding: '24px 12px' }}>
                    <h3 className="shiftDetailsSubHeading">Manage Agencies</h3>
                    <ManageAgencies
                      showBookingsButtons
                      job={job}
                      jobId={jobId}
                      setShiftDetails={this.props.setShiftDetails}
                      showWarningPopUp={this.showWarningPopUp}
                      onShiftChange={this.props.onShiftChange}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </div>
        <>
          <CancelBookingModal
            showCancelModal={this.state.showCancelModal}
            contentLabel="Cancel Slot"
            closeCancel={this.closeCancel}
            cancelBooking={this.cancelBooking}
          />
          <ConfirmBookingModal
            showConfirmBookingPopup={this.state.showConfirmBookingPopup}
            contentLabel="Confirm booking with warnings"
            contentStyle={{ width: 400 }}
            closeModal={() => this.setState({ showConfirmBookingPopup: false, applicantWithWarnings: null })}
            warnings={this.state.warnings}
            warningsAccepted={this.state.warningsAccepted}
            warningsAcceptanceChange={() => this.setState({ warningsAccepted: !this.state.warningsAccepted })}
            bookCandidate={this.checkIfAdminCanBookCandidate}
            applicant={this.state.applicantWithWarnings}
            jobId={jobId}
          />
          {this.state.showContractedHoursWarningModal && (
            <ContractedHoursWarningModal
              contract={this.state.contract}
              onClose={() => this.setState({ showContractedHoursWarningModal: false, contract: null })}
              book={() => this.bookCandidate(this.state.candidateToBook)}
            />
          )}
          {this.state.clashes ?
            <ClashBookingModal
              clashes={this.state.clashes}
              clashingShifts={this.state.clashingShifts}
              closeClashModal={this.closeClashModal}
              contentLabel="clashing bookings"
            />
            :
            null
          }
          <WarningModal
            isOpen={this.state.showWarningModal}
            contentLabel="Warning"
            onRequestClose={this.closeWarningModal}
            warningModalText={this.state.warningModalText}
          />
          {this.state.candidateUploadModalOpen &&
            <CandidateUploadModal isOpen jobId={jobId} onClose={() => this.setState({ candidateUploadModalOpen: false })} />
          }
          {this.state.showRejectApplicantModal && (
            <RejectApplicantModal
              onClose={() => this.setState({ showRejectApplicantModal: false, applicantWithWarnings: null })}
              applicant={this.state.applicantWithWarnings}
              rejectApplicant={this.rejectApplicant}
            />
          )}
          {this.state.showRejectedApplicationBookingModal && (
            <RejectedApplicationBookingModal
              onClose={() => this.setState({ showRejectedApplicationBookingModal: false, rejectedApplication: null })}
              rejectedApplication={this.state.rejectedApplication}
              book={() => this.bookCandidate(this.state.candidateToBook)}
              applicant={this.state.candidateToBook.candidate}
              timezone={job.timezone}
            />
          )}
        </>
      </>
    );
  }
}

const BookingLoading = (props) => {
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 80px)' }}>
      <Loading size={60} />
      <p style={{ fontSize: '16px', color: colors.positive, fontWeight: 500 }}>{props.message}</p>
    </div>
  );
};

BookingLoading.propTypes = {
  bookingCandidate: bool.isRequired,
  autoBookingCandidate: bool.isRequired,
  cancellingBooking: bool.isRequired,
  selectedJobApplicants: arrayOf(object),
  selectedCandidate: shape({
    value: string,
    label: string,
  }),
  bookingCandidateId: string,
  orgHasSuppliers: bool.isRequired,
};

BookingLoading.defaultProps = {
  selectedJobApplicants: [],
  selectedCandidate: null,
  bookingCandidateId: null,
};


function mapStateToProps({ global, user }, ownProps) {
  const canReleaseToBank = isFeatureOn(null, permissions.BANK_CAN_RELEASE, user, global);
  const canRetractBank = isFeatureOn(null, permissions.BANK_CAN_RETRACT, user, global);
  const canDisplayCandidateRating = isFeatureOn(featureFlags.RATINGS_DISPLAY_CANDIDATE, null, user, global);
  const canSelfBook = !isFeatureOn(featureFlags.SELF_BOOKING_PREVENTION, null, user, global);

  return {
    orgKey: global.currentOrgKey,
    userId: user.userId,
    isSupplier: global.orgConfig.features?.supplier,
    metadata: global.metadata,
    adminBanksMetadata: global.adminBanks,
    canReleaseToBank,
    canRetractBank,
    canDisplayCandidateRating,
    canSelfBook,
    adminBanks: global?.adminBanks?.map(bank => bank.bankKey) ?? [],
    orgHasSuppliers: global.orgSuppliers?.length > 0,
    showCandidateLocationsFeatureIsOn: isFeatureOn(featureFlags.SHOW_CANDIDATE_LOCATIONS_TO_ADMINS, null, user, global),
    contractedHoursFeatureIsOn: isFeatureOn(featureFlags.CONTRACTED_HOURS, null, user, global),
    site: user.sites?.[ownProps.job?.siteKey] ?? null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShiftApplicationsAndBookings: (shiftKey, appsAndBookings) => dispatch(setShiftApplicationsAndBookings(shiftKey, appsAndBookings)),
    setShiftDetails: (shiftKey, details) => dispatch(setShiftDetails(shiftKey, details)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingsTab);
