import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import colors from 'config/colors';
import * as userThunks from 'thunks/user';
import * as userReducer from 'reducers/user';

import AuthPage from './ui/AuthPage';
import { PasswordResetForm, PasswordResetConfirmation } from './PasswordReset';
import { checkPasswordRequirements } from './password-requirements';

const styles = {
  errorContainer: {
    padding: 24,
    textAlign: 'center',
    color: colors.red,
  },
};

const { string, func, bool } = PropTypes;
function parseUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);

  const token = urlParams.get('token'); // Dagny custom reset token
  const actionCode = urlParams.get('oobCode'); // Firebase auth reset token
  const continueUrl = urlParams.get('continueUrl'); // Firebase auth continueUrl

  return { token, actionCode, continueUrl };
}

class PasswordResetContainer extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    actionCode: string,
    email: string,
    resetError: string,
    passwordIsReset: bool.isRequired,
    landFromMobile: bool.isRequired,
  }

  static defaultProps = {
    resetError: null,
    actionCode: null,
    email: null,
  }

  constructor() {
    super();
    this.state = {
      formValues: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(userReducer.clearError());
    this.props.dispatch(userReducer.passwordIsReset(false));

    if (this.props.currentPassword) {
      this.props.dispatch(userThunks.fetchPasswordRequirements());
    } else if (this.props.token) {
      this.props.dispatch(userThunks.verifyCircularWavePasswordResetToken(this.props.token));
    } else if (this.props.actionCode) {
      this.props.dispatch(userThunks.verifyFirebasePasswordResetCode(this.props.actionCode));
    }

    // Detect mobile request
    if (this.props.continueUrl !== null) this.props.dispatch(userReducer.landFromMobile());
  }

  setFormValue = (key, value) => {
    this.setState(state => ({
      ...state,
      formValues: { ...state.formValues, [key]: value },
    }));
  }

  updatePassword = async () => {
    try {
      const newPassword = this.state.formValues.password;
      if (this.props.currentPassword) {
        await this.props.dispatch(userThunks.resetPasswordWithCurrentPassword(this.props.currentPassword, newPassword));
      } else if (this.props.token) {
        await this.props.dispatch(userThunks.resetPasswordWithToken(this.props.token, newPassword));
      } else {
        await this.props.dispatch(userThunks.confirmFirebasePasswordReset(this.props.actionCode, newPassword));
      }
    } catch (error) {
      this.props.dispatch(userReducer.fetchUserError(error.message));
      setTimeout(() => this.props.dispatch(userReducer.clearError()), 5000);
    }
  }

  login = async () => {
    try {
      await this.props.dispatch(userThunks.signIn(this.props.email, this.state.formValues.password));
      this.props.history.push(this.props.location?.state?.redirectUrl?.state?.redirectUrl ?? '/');
    } catch (e) {
      console.error(e);
    }
  }

  render() {

    if (this.props.verifyTokenError) {
      return (
        <AuthPage header="Reset Password">
          <p style={styles.errorContainer}>{this.props.verifyTokenError}</p>
        </AuthPage>
      );
    }

    const { password, confirmPassword } = this.state.formValues;
    const passwordRequirementsWithStatus = checkPasswordRequirements(this.props.passwordRequirements, password);
    const hasPasswordErrors = passwordRequirementsWithStatus.some(req => !req.isPassing);
    return (
      this.props.passwordIsReset ?
        <PasswordResetConfirmation login={this.login} landFromMobile={this.props.landFromMobile} />
        :
        <PasswordResetForm
          email={this.props.email}
          disabled={hasPasswordErrors || !password || password !== confirmPassword}
          updatePassword={this.updatePassword}
          resetError={this.props.resetError}
          passwordRequirementsWithStatus={passwordRequirementsWithStatus}
          formValues={this.state.formValues}
          setFormValue={this.setFormValue}
        />
    );
  }
}

function mapStateToProps({ user }) {
  const { token, actionCode, continueUrl } = parseUrlParams();

  return {
    verifyTokenError: user.verifyTokenError,
    resetError: user.error,
    email: user.passwordResetEmail,
    passwordRequirements: user.passwordResetRequirements,
    passwordIsReset: user.passwordIsReset,
    landFromMobile: user.landFromMobile,
    token,
    actionCode,
    continueUrl,
  };
}

const mergeProps = (mapProps, dispatchProps, ownProps) => {
  return {
    ...mapProps,
    ...dispatchProps,
    ...ownProps,
    email: ownProps.email ?? mapProps.email,
  };
};

export default withRouter(connect(mapStateToProps, (dispatch) => ({ dispatch }), mergeProps)(PasswordResetContainer));
