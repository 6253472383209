import { AdminProfile, Admin } from 'types/Admin';

type FetchAdminsInProgress = { type: 'FETCH_ADMINS_IN_PROGRESS' };
type FetchAdminsSuccess = { type: 'FETCH_ADMINS_SUCCESS', admins: Array<Admin> };
type FetchAdminsError = { type: 'FETCH_ADMINS_ERROR', error: string };

type FetchAdminProfileInProgress = { type: 'FETCH_ADMIN_PROFILE_IN_PROGRESS' };
type FetchAdminProfileSuccess = { type: 'FETCH_ADMIN_PROFILE_SUCCESS', adminProfile: AdminProfile };
type FetchAdminProfileError = { type: 'FETCH_ADMIN_PROFILE_ERROR', error: string };

type Action =
  | FetchAdminsInProgress
  | FetchAdminsSuccess
  | FetchAdminsError
  | FetchAdminProfileInProgress
  | FetchAdminProfileSuccess
  | FetchAdminProfileError;

export const fetchAdminsInProgress = () : FetchAdminsInProgress => ({ type: 'FETCH_ADMINS_IN_PROGRESS' });
export const fetchAdminsSuccess = (admins: Array<Admin>) : FetchAdminsSuccess => ({ type: 'FETCH_ADMINS_SUCCESS', admins });
export const fetchAdminsError = (error: string) : FetchAdminsError => ({ type: 'FETCH_ADMINS_ERROR', error });

export const fetchAdminProfileInProgress = () : FetchAdminProfileInProgress => ({ type: 'FETCH_ADMIN_PROFILE_IN_PROGRESS' });
export const fetchAdminProfileSuccess = (adminProfile: AdminProfile) : FetchAdminProfileSuccess => ({ type: 'FETCH_ADMIN_PROFILE_SUCCESS', adminProfile });
export const fetchAdminProfileError = (error: string) : FetchAdminProfileError => ({ type: 'FETCH_ADMIN_PROFILE_ERROR', error });

export interface AdminsState {
  isFetchingAdmins: boolean;
  admins: Array<Admin> | null;
  error: string | null;
  isFetchingAdminProfile: boolean;
  adminProfile: AdminProfile | null,
  adminProfileError: string | null,
}

const initialState : AdminsState = {
  isFetchingAdmins: false,
  isFetchingAdminProfile: false,
  admins: null,
  adminProfile: null,
  error: null,
  adminProfileError: null,
};

export function adminsReducer(state = initialState, action: Action): AdminsState {
  switch (action.type) {
    // Fetch admins
    case 'FETCH_ADMINS_IN_PROGRESS':
      return { ...state, isFetchingAdmins: true, error: null };
    case 'FETCH_ADMINS_SUCCESS':
      return { ...state, admins: action.admins, isFetchingAdmins: false };
    case 'FETCH_ADMINS_ERROR':
      return { ...state, isFetchingAdmins: false, error: action.error };
    case 'FETCH_ADMIN_PROFILE_IN_PROGRESS':
      return { ...state, isFetchingAdminProfile: true, adminProfileError: null };
    case 'FETCH_ADMIN_PROFILE_SUCCESS':
      return { ...state, isFetchingAdminProfile: false, adminProfile: action.adminProfile };
    case 'FETCH_ADMIN_PROFILE_ERROR':
      return { ...state, isFetchingAdminProfile: false, adminProfileError: action.error };
    default:
      return state;
  }
}
