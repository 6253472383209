const FETCH_CANDIDATES = 'FETCH_CANDIDATES';
const FETCH_CANDIDATES_SUCCESS = 'FETCH_CANDIDATES_SUCCESS';
const FETCH_TICKETS = 'FETCH_TICKETS';
const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
const FETCH_TICKETS_ERROR = 'FETCH_TICKETS_ERROR';
const FETCH_INVITE_METADATA = 'FETCH_INVITE_METADATA';
const ADD_INVITE_METADATA_FIELDS = 'ADD_INVITE_METADATA_FIELDS';
const IS_EDITING_INVITE_METADATA = 'IS_EDITING_INVITE_METADATA';

export const fetchCandidates = () => ({ type: FETCH_CANDIDATES });
export const fetchCandidatesSuccess = candidates => ({ type: FETCH_CANDIDATES_SUCCESS, candidates });

export const fetchTickets = () => ({ type: FETCH_TICKETS });
export const fetchTicketsSuccess = tickets => ({ type: FETCH_TICKETS_SUCCESS, tickets });
export const fetchTicketsError = error => ({ type: FETCH_TICKETS_ERROR, error });

export const isEditingInviteMetadata = bool => ({ type: IS_EDITING_INVITE_METADATA, bool });


const initialState = {
  candidates: null,
  isFetchingCandidates: true,
  fetched: false,

  tickets: null,
  isFetchingTickets: true,
  inProgress: null,

  inviteMetadataFields: null,
  isEditingInviteMetadata: false,

  error: null,
};

export default function calendar(state = initialState, action) {
  switch (action.type) {

    // Candidates list
    case FETCH_CANDIDATES: return { ...state, isFetchingCandidates: true };
    case FETCH_CANDIDATES_SUCCESS: return { ...state, isFetchingCandidates: false, candidates: action.candidates, fetched: true };

    // Tickets for review
    case FETCH_TICKETS: return { ...state, isFetchingTickets: true, tickets: [] };
    case FETCH_TICKETS_SUCCESS: return { ...state, tickets: action.tickets, isFetchingTickets: false };
    case FETCH_TICKETS_ERROR: return { ...state, error: action.error, isFetchingTickets: false };

    // Invite metadata
    case FETCH_INVITE_METADATA: return { ...state, isFetchingInviteMetadata: true };
    case ADD_INVITE_METADATA_FIELDS: return { ...state, inviteMetadataFields: action.metadata, isFetchingInviteMetadata: false };
    case IS_EDITING_INVITE_METADATA: return { ...state, isEditingInviteMetadata: action.bool };

    default:
      return state;
  }
}
