import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { isFeatureOn } from 'lib/features';
import { featureFlags } from 'config/featureFlags';
import { fetchShiftDetails, fetchAuditTrail } from 'thunks/jobs';
import { incrementShiftRefCount, decrementShiftRefCount, clearShiftDetailsError } from 'reducers/jobs';
import { setShiftDetailsIsPinned } from 'reducers/userInterface';

import Button from 'components/Button';
import Loading from 'components/Loading';
import SidePanel from 'components/SidePanel';
import SidePanelTabs from 'components/SidePanelTabs';
import InfoBanner from 'components/InfoBanner';

import CreateShift from '../CreateShift';
import EditShift from '../EditShift';
import DetailsTab from './DetailsTab';
import BookingsTab from './BookingsTab/BookingsTab';
import AuditTab from './AuditTab';
import { shiftMatchesFilters } from '../helpers';
import './styles.scss';

const MomentType = moment().constructor;
const { string, bool, func, objectOf, object, instanceOf } = PropTypes;
class ShiftDetails extends Component {

  static propTypes = {
    closeSidePanel: func.isRequired,
    onTabClick: func.isRequired,
    isSupplier: bool.isRequired,
    isDraft: bool,
    jobId: string.isRequired,
    sidePanelTab: string.isRequired,
    draftShifts: objectOf(object),
    hasPreferredGender: bool,
    openDraftShiftView: func.isRequired,
    onShiftChange: func,
    orgKey: string.isRequired,
    userId: string.isRequired,
    filters: object,
    side: string,
    orgCanEditShift: bool.isRequired,
  }

  static defaultProps = {
    isDraft: false,
    draftShifts: {},
    hasPreferredGender: false,
    onShiftChange: () => {},
    filters: {},
    side: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // If ShiftDetails panel is opened to view a draft shift, then don't pull shift data directly from Firebase
    this.props.incrementShiftRefCount(this.props.jobId);
    if (!this.props.isDraft) this.getShiftDetails();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isDraft && prevProps.jobId !== this.props.jobId) {
      this.props.decrementShiftRefCount(prevProps.jobId);
      this.props.incrementShiftRefCount(this.props.jobId);
      this.getShiftDetails();
      // If previously in edit mode, nav user to details tab for new shift
      if (prevProps.sidePanelTab === 'edit') this.props.onTabClick('details');
    }
  }

  componentWillUnmount() {
    this.props.decrementShiftRefCount(this.props.jobId);
    if (this.props.error) this.props.clearShiftDetailsError(this.props.jobId);
  }

  getShiftDetails = async () => {
    await this.props.fetchShiftDetails(this.props.jobId);

    if (this.props.showAuditTrail) this.props.fetchAuditTrail(this.props.jobId);
  }

  render() {
    const { onTabClick, filters, showWarningIfFiltersDontMatch, showWarningIfDateNotInPeriod } = this.props;

    // If supplier lands on audit tab, redirect to details tab
    if (this.props.sidePanelTab === 'audit' && this.props.isSupplier) onTabClick('details');

    const shiftNumber = this.props.shiftDetails?.shiftNumber ?? null;
    const shiftTabs = [{
      label: 'Details',
      id: 'details',
    }, {
      label: 'Bookings',
      id: 'bookings',
    }];

    if (this.props.showAuditTrail && !this.props.isDraft) {
      shiftTabs.push({ label: 'Audit', id: 'audit' });
    }

    const shift = this.props.isDraft ? this.props.draftShifts[this.props.jobId] : this.props.shiftDetails;
    const sites = this.props.sites;
    const canManageShift = this.props.orgKey === shift?.orgKey || shift?.canManageShifts;


    const shiftDateMoment = shift?.startTime && moment(shift.startTime);
    const showNotDisplayedWarning = (
      (shift && filters && showWarningIfFiltersDontMatch && !shiftMatchesFilters(shift, filters))
      || (shiftDateMoment && (showWarningIfDateNotInPeriod?.startDate?.isAfter(shiftDateMoment, 'day') || showWarningIfDateNotInPeriod?.endDate?.isBefore(shiftDateMoment, 'day')))
    );
    const numOfAgencyApplicants = this.props.agencySubmittedApplicants ? Object.values(this.props.agencySubmittedApplicants).length : 0;

    const showShiftDetails = this.props.shiftDetails && !this.props.error && !this.props.fetchingShiftDetails;
    let sidePanelTitle = '';

    if (!this.props.fetchingShiftDetails && this.props.isDraft) {
      sidePanelTitle = 'Edit Draft Shift';
    } else if (!this.props.fetchingShiftDetails && this.props.shiftDetails) {
      sidePanelTitle = `Shift Details${shiftNumber ? ':' : ''} ${shiftNumber}`;
    } else {
      sidePanelTitle = 'Shift Details';
    }

    if (this.props.fetchingShiftDetails) {
      return (
        <SidePanel
          vflex
          isOpen={!!this.props.jobId}
          side={this.props.side}
          closeSidePanel={this.props.closeSidePanel}
          title="Shift Details"
          isPinned={this.props.isPinned}
          setIsPinned={this.props.setIsPinned}
        >
          <div className="side-panel-loading"><Loading flex={1} /></div>
        </SidePanel>
      );
    }

    if (this.props.error) {
      return (
        <SidePanel
          vflex
          isOpen
          side={this.props.side}
          closeSidePanel={this.props.closeSidePanel}
          title="Shift Details"
          isPinned={this.props.isPinned}
          setIsPinned={this.props.setIsPinned}
        >
          <div style={{ padding: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'column', color: '#666' }}>
            <p style={{ marginBottom: 12 }}>{this.props.error}</p>
            <Button onClick={() => this.getShiftDetails()}>Retry</Button>
          </div>
        </SidePanel>
      );
    }

    if (this.props.sidePanelTab === 'edit' && !this.props.isDraft) {
      return (
        <SidePanel
          vflex
          isOpen={!!this.props.jobId}
          side={this.props.side}
          closeSidePanel={this.props.closeSidePanel}
          title={`Edit Shift: ${shiftNumber}`}
          isPinned={this.props.isPinned}
          setIsPinned={this.props.setIsPinned}
        >
          {showNotDisplayedWarning && (
            <InfoBanner warning message="The shift you are viewing doesn’t match your current filters, and won’t be visible in the list." />
          )}
          <EditShift onShiftChange={this.props.onShiftChange} goToDetailsTab={this.props.goToDetailsTab} fetchAuditTrail={this.props.fetchAuditTrail} shiftKey={this.props.jobId} />
        </SidePanel>
      );
    }


    return (
      <SidePanel
        vflex
        isOpen={!!this.props.jobId}
        side={this.props.side}
        closeSidePanel={this.props.closeSidePanel}
        title={sidePanelTitle}
        isPinned={this.props.isPinned}
        setIsPinned={this.props.setIsPinned}
      >
        {showNotDisplayedWarning && (
            <InfoBanner warning message="The shift you are viewing doesn’t match your current filters, and won’t be visible in the list." />
        )}
        {this.props.sidePanelTab === 'edit' ?
          <CreateShift
            closeSidePanel={this.props.closeSidePanel}
            jobId={this.props.jobId}
            goToCalendarView={this.props.goToCalendarView}
            openDraftShiftView={this.props.openDraftShiftView}
          />
          :
          <>
            {this.props.fetchingShiftDetails && <div className="side-panel-loading"><Loading flex={1} /></div>}
            {!this.props.fetchingShiftDetails && <SidePanelTabs selectedTab={this.props.sidePanelTab} onTabClick={onTabClick} tabs={shiftTabs} />}
            <div className="shiftDetailContentContainer">
              {this.props.sidePanelTab === 'details' && showShiftDetails &&
              <DetailsTab
                fetchingShift={this.state.fetchingShift}
                shift={shift}
                jobId={this.props.jobId}
                onTabClick={this.props.onTabClick}
                closeSidePanel={this.props.closeSidePanel}
                showCancelShiftButton={canManageShift && !this.props.isDraft && !this.props.isSupplier && !shift.cancelledAt}
                showBookingStatus={!this.props.isSupplier}
                hasPreferredGender={this.props.hasPreferredGender}
                costCentresOn={this.props.costCentresOn}
                isSupplier={this.props.isSupplier}
                orgKey={this.props.orgKey}
                onShiftChange={this.props.onShiftChange}
                numberOfApplications={this.props?.selectedJobApplicants?.length ?? 0 + numOfAgencyApplicants}
                bookedCandidates={this.props.bookedCandidates}
                orgCanEditShift={this.props.orgCanEditShift}
                goToEditShift={this.props.goToEditShift}
              />
              }
              {this.props.sidePanelTab === 'bookings' && showShiftDetails &&
              <BookingsTab
                getShiftDetails={this.getShiftDetails}
                job={shift}
                adminIsSupplier={this.props.isSupplier}
                jobId={this.props.jobId}
                goToApplicantsModal={this.props.goToApplicantsModal}
                onShiftChange={this.props.onShiftChange}
                selectedJobApplicants={this.props.selectedJobApplicants}
                isFetchingSelectedJobApplicants={this.props.isFetchingSelectedJobApplicants}
                agencySubmittedApplicants={this.props.agencySubmittedApplicants || []}
                bookedCandidates={this.props.bookedCandidates}
                fetchingShiftDetails={this.props.fetchingShiftDetails}
                error={this.props.error}
              />
              }
              {this.props.sidePanelTab === 'audit' && this.props.showAuditTrail &&
              <AuditTab
                auditTrailMessages={this.props.auditTrail || []}
                userId={this.props.userId}
                fetchingAuditTrail={this.props.fetchingAuditTrail}
              />
              }
            </div>
          </>
        }
      </SidePanel>
    );
  }
}


function mapStateToProps({ jobs, global, createShifts, user, filter, userInterface }, ownProps) {

  const shift = jobs.details[ownProps.jobId];
  const isSupplier = global.orgConfig?.features?.supplier;
  const showAuditTrail = !isSupplier && (shift?.details?.orgKey === global.currentOrgKey);

  return {
    userId: user.userId,
    orgKey: global.currentOrgKey,
    isSupplier,
    costCentresOn: isFeatureOn(featureFlags.COST_CENTRES, null, user, global),
    hasPreferredGender: !!global?.orgConfig?.preferredGender?.enabled,
    orgCanEditShift: !!global?.orgConfig?.shiftEditableFields,

    filters: filter.v2SelectedItems?.shifts,
    sites: user.sites,
    draftShifts: createShifts.draftShifts,

    fetchingShiftDetails: shift?.isFetchingShiftDetails,
    isFetchingSelectedJobApplicants: shift?.isFetchingApplicants,
    fetchingAuditTrail: shift?.isFetchingAuditTrail,

    shiftDetails: shift?.details,
    auditTrail: shift?.auditTrail,
    bookedCandidates: shift?.bookings,
    selectedJobApplicants: shift?.bankApplicants,
    agencySubmittedApplicants: shift?.supplierApplicants,
    error: shift?.error,
    isPinned: userInterface.shiftDetailsIsPinned,

    showAuditTrail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    incrementShiftRefCount: shiftKey => dispatch(incrementShiftRefCount(shiftKey)),
    decrementShiftRefCount: shiftKey => dispatch(decrementShiftRefCount(shiftKey)),
    fetchShiftDetails: shiftKey => dispatch(fetchShiftDetails(shiftKey)),
    fetchAuditTrail: shiftKey => dispatch(fetchAuditTrail(shiftKey)),
    setIsPinned: isPinned => dispatch(setShiftDetailsIsPinned(isPinned)),
    clearShiftDetailsError: shiftKey => dispatch(clearShiftDetailsError(shiftKey)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetails);
