import bugsnag from 'bugsnag-js';
import * as settings from 'config/settings';

// Overridden by setStore function
let store = null;

// Overridden by initializeBugsnag function
let bugsnagClient = null;
let reportErrorInner = (error, metadata) => console.log(error, metadata);

export function setStore(storeReference) {
  store = storeReference;
}

export function getBugsnagClient() {
  return bugsnagClient;
}

export function initializeBugsnag() {

  bugsnagClient = bugsnag({
    apiKey: 'b02b5913b11a4fc362347d129fd3d3d8',
    releaseStage: (process.env.REACT_APP_PRODUCTION === 'y') ? 'production' : 'development',
    beforeSend: (report) => {
      try {
        if (!store) return;
        const state = store.getState();

        report.updateMetaData('user', {
          projectId: settings?.firebaseConfig?.projectId,
          orgKey: state.global.currentOrgKey,
          orgName: state.global.orgConfig?.name,
          userId: state.user.userId,
          email: state.user.userData?.email,
          name: state.user.userData?.displayName,
        });

        if (state.global.orgConfig?.features) {
          report.updateMetaData('features', state.global.orgConfig.features);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  reportErrorInner = (error, metadata) => bugsnagClient.notify(error, metadata ? { metadata } : undefined);

  return bugsnagClient;
}

// Indirection through reportErrorInner function is to avoid possible bugs
// with live binding updating in ES6 module implementations.
export function reportError(...args) {
  reportErrorInner(...args);
}
