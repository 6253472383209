import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { permissions } from 'config/permissions';
import { useFeatureFlag } from 'hooks/feature';

import {
  BankApprovedContainer,
  BankRemovedContainer,
  BankAllContainer,
  BankNonCompliantContainer,
  BankForReviewContainer,
  BankNewStaffContainer,
} from './BankManagementContainer';
import BankInProgressContainer from './BankInProgressContainer';
import BulkUploadModal from './BulkUploadModal';
import InviteModal from './InviteModal';
import CandidateProfileModal from './CandidateProfileModal';


function getCandidateIdFromCrnUrl(location) {
  const urlParams = location.pathname.match(/crn=(.*)/);
  return urlParams && urlParams[1].length > 0 ? urlParams[1] : null;
}

const BankRoutes = ({ location, history, match, global, user, currentOrgKey }) => {

  const bankRootUrl = match.path;

  // Redirect legacy crn path to new path
  const crn = getCandidateIdFromCrnUrl(location);
  if (crn) history.replace(`${bankRootUrl}/all/all/candidate/${crn}`);

  const canViewRemoved = useFeatureFlag(null, permissions.BANK_CAN_VIEW_REMOVED);

  return (
    <Switch>
      <Route path={`${bankRootUrl}/candidate/:id`} render={routeProps => <CandidateProfileModal isOpen candidateId={routeProps.match.params.id} onRequestClose={() => history.push(`${bankRootUrl}/all`)} />} />
      <Route
        path={`${bankRootUrl}/:bankKey/:tabKey`}
        render={(routeProps) => {

          const { bankKey, tabKey } = routeProps.match.params;
          if (!bankKey || !tabKey) history.replace(`${bankRootUrl}/all/all`);

          const listViewUrl = routeProps.match.path.replace(':bankKey', bankKey).replace(':tabKey', tabKey);
          const goToListView = () => history.push(listViewUrl);
          const openCandidateProfile = (candidateKey, profileModalTabKey) => history.push(`${listViewUrl}/candidate/${candidateKey}${profileModalTabKey ? `/${profileModalTabKey}` : ''}`);
          const openInviteModal = () => history.push(`${listViewUrl}/invite`);
          const openBulkUploadModal = () => history.push(`${listViewUrl}/bulk-upload`);
          const openTicketModal = ticketId => history.push(`${listViewUrl}/tickets/${ticketId}`);
          const setSelectedBank = newBankKey => history.push(`${bankRootUrl}/${newBankKey}/${tabKey}`);
          const setSelectedTab = newTabKey => history.push(`${bankRootUrl}/${bankKey}/${newTabKey}`);
          const nav = { goToListView, openCandidateProfile, openInviteModal, openBulkUploadModal, setSelectedTab, setSelectedBank };

          return (
            <>
              {/* List views */}
              <Switch>
                <Route path={`${bankRootUrl}/${bankKey}/all`} render={() => <BankAllContainer nav={nav} selectedBankKey={bankKey} />} />
                <Route path={`${bankRootUrl}/${bankKey}/signing-up`} render={() => <BankInProgressContainer nav={nav} selectedBankKey={bankKey} />} />
                <Route path={`${bankRootUrl}/${bankKey}/non-compliant`} render={() => <BankNonCompliantContainer nav={nav} selectedBankKey={bankKey} onRowClick={openTicketModal} />} />
                <Route path={`${bankRootUrl}/${bankKey}/for-review`} render={() => <BankForReviewContainer nav={nav} selectedBankKey={bankKey} onRowClick={openTicketModal} />} />
                <Route path={`${bankRootUrl}/${bankKey}/approved`} render={() => <BankApprovedContainer nav={nav} selectedBankKey={bankKey} />} />
                <Route path={`${bankRootUrl}/${bankKey}/new`} render={() => <BankNewStaffContainer nav={nav} selectedBankKey={bankKey} />} />
                {canViewRemoved ? <Route path={`${bankRootUrl}/${bankKey}/removed`} render={() => <BankRemovedContainer nav={nav} selectedBankKey={bankKey} />} /> : null}
                <Redirect to={`${bankRootUrl}/all/all`} />
              </Switch>

              {/* Modals */}
              <Switch>
                <Route path={`${listViewUrl}/candidate/:id/:tabKey`} render={modalRouteProps => <CandidateProfileModal isOpen candidateId={modalRouteProps.match.params.id} candidateOrgKey={currentOrgKey} onRequestClose={goToListView} tabKey={modalRouteProps.match.params.tabKey} />} />
                <Route path={`${listViewUrl}/candidate/:id`} render={modalRouteProps => <CandidateProfileModal isOpen candidateId={modalRouteProps.match.params.id} candidateOrgKey={currentOrgKey} onRequestClose={goToListView} />} />
                <Route path={`${listViewUrl}/invite`} render={() => <InviteModal onClose={goToListView} />} />
                <Route path={`${listViewUrl}/bulk-upload`} render={() => <BulkUploadModal onClose={goToListView} />} />
              </Switch>
            </>
          );
        }}
      />
      <Redirect to={`${bankRootUrl}/all/all`} />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    global: state.global,
    currentOrgKey: state.global.currentOrgKey,
  };
};

export default connect(mapStateToProps)(BankRoutes);
