import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import { firebaseConfig, FIREBASE_DATABASE_EMULATOR_HOST } from 'config/settings';
import { connect } from './auth';

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

if (FIREBASE_DATABASE_EMULATOR_HOST) {
  firebase.database().useEmulator(...FIREBASE_DATABASE_EMULATOR_HOST.split(':'));
}

// firebase.database.enableLogging(true);
// firebase.firestore.setLogLevel('debug');

let reportError = (error, opts) => console.error(error, opts);
export const setErrorHandler = (errorHandler) => {
  reportError = errorHandler;
};

let logout = null;
export const setLogoutHandler = (logoutHandler) => {
  logout = logoutHandler;
};

export function storage() {
  return firebase.storage();
}

export function fs() {
  const firestore = firebase.firestore();
  return firestore;
}

export function ref() {
  return firebase.database().ref();
}

export function child(path) {
  return ref().child(path);
}

export function on(path, callback, error) {
  console.debug(`db.on(${path})`);
  return connect().then(() => {

    const start = Date.now();
    let duration = -1;

    return child(path).on('value', (snapshot) => {
      if (duration === -1) {
        duration = Date.now() - start;
        console.debug(`db.on(${path}) took ${duration}ms`);
      }

      if (callback) {
        const data = snapshot.exists() ? snapshot.val() : null;
        callback(data);

        if (!data) console.debug(`Data doesn't exist for ${path}`);
      }
    }, (err) => {
      reportError(new Error(`db.on failed (${path})`), {
        metaData: {
          query: { path },
          error: {
            code: err.code,
            message: err.message,
          },
        },
      });
      if (error) {
        error(err);
      } else if (err.code === 'PERMISSION_DENIED') {
        logout();
      } else {
        console.error(`db.on(${path})`, err);
      }
    });
  });
}

export function off(path, callback) {
  console.debug(`db.off(${path})`);
  child(path).off('value', callback);
}

export async function get(path) {
  try {
    await connect();
    const start = Date.now();
    const snapshot = await child(path).once('value');
    const duration = Date.now() - start;
    console.debug(`db.get(${path}) took ${duration}ms`);

    return snapshot.exists() ? snapshot.val() : null;
  } catch (err) {
    reportError(new Error(`db.get failed (${path})`), {
      metaData: {
        query: { path },
        error: {
          code: err.code,
          message: err.message,
        },
      },
    });
    console.error(`db.get(${path})`, err);
    return null;
  }
}
