import React from 'react';

export default function XAxisAtAngle(props) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} transform="rotate(-35)" textAnchor="end" fill="#999" className="xAxis">{payload.value}</text>
    </g>
  );
}
