import React, { useMemo } from 'react';
import { useSelector, useAppDispatch } from 'hooks/redux';

import colors from 'config/colors';
import { SHIFT_GROUPING, smartGroup } from 'lib/shift-grouping';
import { removeTemplateShift } from 'reducers/createShifts';
import { copyAndCreateTemplateShift } from 'thunks/createShifts';
import { setTemplateEdited } from 'reducers/templates';

import Button from 'components/Button';

import { ShiftSummary } from 'types/ShiftTypes';

import { daysOfWeek, groupByDayOfWeek } from 'components/Calendar';

import TemplatesPage from '../Templates/TemplatesPage';
import WeekCalendar from './WeekCalendar';
import { useTemplateShifts } from '../Templates/template-hooks';

interface TemplateWeekCalendarProps {
  selectedShift?: string,
  goToEditTemplateShift: (templateShiftKey: string) => void,
  goToApplyTemplatePeriodView: () => void,
  saveTemplate: () => void,
  templateKey: string,
}

const TemplateWeekCalendar: React.FC<TemplateWeekCalendarProps> = (props: TemplateWeekCalendarProps) => {

  // Retrieve data from redux
  const dispatch = useAppDispatch();
  const shiftRosterSettings = useSelector(state => state.global.orgConfig.shiftRosterView);
  const specialitiesMetadata = useSelector(state => state.global.metadata.specialities);
  const gradesMetadata = useSelector(state => state.global.metadata.grades);
  const saveTemplateError: string | null = useSelector(state => state.templates.saveTemplateError);
  const validationError: string | null = useSelector(state => state.templates.validationError);
  const templateEdited = useSelector(state => state.templates.templateEdited);

  const { shifts } = useTemplateShifts();
  const shiftSections = useMemo(() => {
    return smartGroup(shifts, SHIFT_GROUPING[shiftRosterSettings?.sectionBy ?? 'site'], { specialitiesMetadata, gradesMetadata });
  }, [shifts, shiftRosterSettings?.sectionBy]);

  const period = {
    days: daysOfWeek.map(day => ({ key: day.toLowerCase(), label: day, event: undefined, notInPeriod: false })),
    columns: daysOfWeek,
    groupByDay: groupByDayOfWeek,
    formatTime: (shift: ShiftSummary) => `${shift.startTimeOfDay} - ${shift.endTimeOfDay}`,
  };

  const copyShift = (shiftKey: string) => {
    dispatch(copyAndCreateTemplateShift(shiftKey));
    if (!templateEdited) dispatch(setTemplateEdited());
  };

  const removeShift = (shiftKey: string) => {
    dispatch(removeTemplateShift(shiftKey));
    if (!templateEdited) dispatch(setTemplateEdited());
  };

  return (
    <TemplatesPage {...props}>
      <WeekCalendar
        period={period}
        shiftSections={shiftSections}
        openShiftTab={props.goToEditTemplateShift}
        selectedShift={props.selectedShift}
        sortShiftsBy="dayOfWeek"
        copyShift={copyShift}
        removeShift={removeShift}
      />
      {!!(validationError || saveTemplateError) && (
        <div className="space-children-12" style={{ position: 'absolute', width: '100%', bottom: 0, padding: 12, backgroundColor: colors.red, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
          <p style={{ color: colors.white }}>{validationError || saveTemplateError}</p>
          {saveTemplateError && <Button onClick={() => props.saveTemplate()} outline>Retry</Button>}
        </div>
      )}
    </TemplatesPage>
  );
};

TemplateWeekCalendar.defaultProps = { selectedShift: undefined };

export default TemplateWeekCalendar;
