const FETCH_CANDIDATE_RATINGS = 'FETCH_CANDIDATE_RATINGS';
const FETCH_CANDIDATE_RATINGS_SUCCESS = 'FETCH_CANDIDATE_RATINGS_SUCCESS';
const FETCH_CANDIDATE_RATINGS_ERROR = 'FETCH_CANDIDATE_RATINGS_ERROR';
const CLEAR_CANDIDATE_RATINGS_DATA = 'CLEAR_CANDIDATE_RATINGS_DATA';
const UPDATE_RATINGS_OFFSET = 'UPDATE_RATINGS_OFFSET';
const FETCH_MORE_CANDIDATE_RATINGS = 'FETCH_MORE_CANDIDATE_RATINGS';
const FETCH_MORE_CANDIDATE_RATINGS_SUCCESS = 'FETCH_MORE_CANDIDATE_RATINGS_SUCCESS';

export const fetchCandidateRatings = () => ({ type: FETCH_CANDIDATE_RATINGS });
export const fetchCandidateRatingsSuccess = (summary, detail, count) => ({ type: FETCH_CANDIDATE_RATINGS_SUCCESS, summary, detail, count });
export const fetchCandidateRatingsError = error => ({ type: FETCH_CANDIDATE_RATINGS_ERROR, error });
export const clearCandidateRatingsData = () => ({ type: CLEAR_CANDIDATE_RATINGS_DATA });
export const updateRatingsOffset = () => ({ type: UPDATE_RATINGS_OFFSET });
export const fetchMoreCandidateRatings = () => ({ type: FETCH_MORE_CANDIDATE_RATINGS });
export const fetchMoreCandidateRatingsSuccess = detail => ({ type: FETCH_MORE_CANDIDATE_RATINGS_SUCCESS, detail });

const initialState = {
  fetchingCandidateRatings: false,
  fetchingMoreCandidateRatings: false,
  candidateRatingSummary: null,
  candidateRatingDetail: null,
  error: null,
  totalCount: 0,
  ratingsLimit: 10,
  ratingsOffset: 0,
};

export default function ratings(state = initialState, action) {
  switch (action.type) {
    case FETCH_CANDIDATE_RATINGS:
      return {
        ...state,
        fetchingCandidateRatings: true,
      };
    case FETCH_CANDIDATE_RATINGS_SUCCESS:
      return {
        ...state,
        fetchingCandidateRatings: false,
        candidateRatingSummary: action.summary,
        candidateRatingDetail: action.detail,
        totalCount: action.count,
      };
    case FETCH_CANDIDATE_RATINGS_ERROR:
      return {
        ...state,
        fetchingCandidateRatings: false,
        error: action.error,
      };
    case UPDATE_RATINGS_OFFSET:
      return {
        ...state,
        ratingsOffset: state.ratingsOffset + state.ratingsLimit,
      };
    case FETCH_MORE_CANDIDATE_RATINGS:
      return {
        ...state,
        fetchingMoreCandidateRatings: true,
      };
    case FETCH_MORE_CANDIDATE_RATINGS_SUCCESS:
      return {
        ...state,
        candidateRatingDetail: [...state.candidateRatingDetail, ...action.detail],
        fetchingMoreCandidateRatings: false,
      };
    case CLEAR_CANDIDATE_RATINGS_DATA:
      return initialState;
    default:
      return state;
  }
}
