import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'hooks/redux';

import colors from 'config/colors';
import fonts from 'config/fonts';

import { CWFilterSelect } from 'components/CWSelect';
import DropDown from '../../DropDown';
import './EditableField.scss';

const styles = {
  labelAndDescription: {
    display: 'inline-block',
    width: '240px',
    lineHeight: 1.2,
    minHeight: 30,
    paddingRight: 24,
  },
  labelText: {
    fontWeight: 500,
    fontSize: '1rem',
    color: '#999',
  },
  descriptionText: {
    fontSize: '0.85rem',
    color: colors.lightText,
  },
  valueText: {
    // width: '500px',
    color: colors.text,
    fontSize: '1rem',
    fontWeight: 500,
    // minHeight: 30,
  },
};

const { string, number, oneOfType, objectOf, bool, shape, arrayOf } = PropTypes;
function EditableField(props) {

  const isMobile = useSelector(state => state.screen.isMobile);

  const { value: originalValue, labelWidth, valueWidth, parameters } = props;

  const showError = !!(props.error && props.isInEditMode);

  const labelStyles = { ...styles.labelText, width: labelWidth };
  const valueStyles = {
    ...styles.valueText,
    color: props.disabled ? colors.accent : colors.black,
    borderBottom: `1px solid ${showError ? colors.cavalry.error : colors.cavalry.line}`,
    width: isMobile ? '100%' : valueWidth,
  };
  const containerStyles = {
    padding: '12px 0px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'start',
    position: 'relative',
  };

  let valueToDisplay = originalValue;
  if (props.fieldType === 'single-select') valueToDisplay = parameters?.options?.find(option => option.value === originalValue)?.name;
  if (props.fieldType === 'multi-select' && Array.isArray(parameters?.options)) {
    valueToDisplay = valueToDisplay.map((value) => {
      return parameters.options.find(option => option.value === value)?.name;
    }).join(', ');
  }

  return (
    <div style={containerStyles}>

      <div style={styles.labelAndDescription}>
        <div style={labelStyles}>{props.fieldName}</div>
        {props.description && <div style={styles.descriptionText}>{props.description}</div>}
      </div>

      {props.isInEditMode && props.fieldType === 'text' && (
        <input
          style={valueStyles}
          className="inputEditableField"
          type="text"
          value={props.value}
          onChange={event => props.onChange(event.target.value)}
          placeholder={props.placeholder}
          disabled={!!props.disabled}
        />
      )}

      {props.isInEditMode && props.fieldType === 'single-select' && (
        <div style={{ ...valueStyles, borderBottom: null, height: 36 }}>
          <FieldDropDown
            value={props.value}
            parameters={parameters}
            onChange={props.onChange}
            valueStyles={valueStyles}
            disabled={!!props.disabled}
          />
        </div>
      )}

      {props.isInEditMode && props.fieldType === 'multi-select' && (
        <div style={{ ...valueStyles, borderBottom: null, height: 36 }}>
          <CWFilterSelect
            noneSelectedLabel="None Selected"
            items={parameters?.options?.map(({ name: label, value, order }) => ({ value, label, order: order ?? null })) ?? []}
            selectedItems={props.value}
            setSelectedItems={props.onChange}
          />
        </div>
      )}

      <div style={{ position: 'absolute', bottom: -12, left: 207 }}>
        {showError && <p className="error-text">{props.error}</p>}
      </div>

      {!props.isInEditMode && <span style={valueStyles}>{valueToDisplay ?? ' '}</span>}
    </div>
  );

}

EditableField.propTypes = {
  fieldKey: string.isRequired,
  fieldName: string.isRequired,
  description: string,
  value: oneOfType([number, string]),
  candidateId: string,
  labelWidth: oneOfType([number, string]),
  valueWidth: oneOfType([number, string]),
  actionsWidth: oneOfType([number, string]),
  fieldType: string.isRequired,
  parameters: objectOf(
    oneOfType([
      number, string, bool, arrayOf(
        shape({ name: string, value: string }),
      ),
    ]),
  ),
};

EditableField.defaultProps = {
  value: null,
  candidateId: null,
  labelWidth: '200px',
  valueWidth: '30%',
  actionsWidth: '0px',
  parameters: null,
  description: null,
};

const FieldDropDown = (props) => {
  const options = props.parameters?.options?.map(({ name: label, value: paramValue }) => ({ value: paramValue, label }));
  const value = options.find(option => option.value === props.value);
  return (
    <DropDown
      value={value ?? null}
      options={options}
      onChange={option => props.onChange(option.value)}
      isDisabled={props.disabled}
    />
  );
};


export default React.memo(EditableField);
