import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import spacing from '../../../config/spacing';
import colors from '../../../config/colors';

const styles = {
  boldStyle: {
    fontWeight: 'bold', textAlign: 'center',
  },
};

export default function ClashesFound(props) {
  const multiple = Object.keys(props.clashingShifts).length > 1;
  return (
    <div>
      <div style={{ ...styles.boldStyle, color: colors.red }}>
        <p>{props.customMessage}</p>
        <p>{`as it clashes with ${multiple ? 'other' : 'another'} shift${multiple ? 's' : ''} ${props.candidateName ?? 'the targeted candidate'} is booked for:`}</p>
      </div>
      <div style={{ ...styles.boldStyle, marginBottom: spacing.base, color: colors.grays.text }}>
        {Object.keys(props.clashingShifts).map((shiftId) => {
          const clashingShift = props.clashingShifts[shiftId];
          const date = clashingShift.startTime;
          const parsedDate = moment(date).format('DD-MM-YYYY');
          const { shiftNumber, siteName, areaName, roleName, gradeName, specialityName, startTime, endTime, candidateName } = clashingShift;
          const start = startTime ? moment(startTime).format('HH:mm') : '';
          const end = endTime ? moment(endTime).format('HH:mm') : '';
          return (
            <div key={date}>
              <p>
                {candidateName && `${candidateName} is booked into`}
                {' '}
                on
                {' '}
                {parsedDate}
                {' '}
                at
                {' '}
                {start}
                {' '}
                -
                {' '}
                {end}
              </p>
              <p>
                {`${shiftNumber} - `}
                {siteName && `${siteName} `}
                {areaName && `(${areaName})  `}
                {`${roleName ?? 'Any'}, `}
                {`${gradeName ?? 'Any'}, `}
                {`${specialityName ?? 'Any'} `}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const { objectOf, object, string } = PropTypes;
ClashesFound.propTypes = {
  clashingShifts: objectOf(object).isRequired,
  customMessage: string,
};

ClashesFound.defaultProps = {
  customMessage: 'The following shift could not be created and booked',
};
