import moment from 'moment';
import { Api } from '.';
import { reportError } from '../error-reporting';

const compliance = new Api('compliance');

export async function get(candidateId, categoryKey, props) {
  let path = `get/${candidateId}/${categoryKey}/`;

  if (props) {
    path += Object.values(props).join('/');
  }

  const result = await compliance.get(path);
  if (result.status >= 400) throw new Error(result.body.error);

  return (result && result.body && result.body.fields) || null;
}

export async function download(fileName) {
  try {
    const result = await compliance.get(`download/${encodeURIComponent(fileName)}`);
    return (result && result.body && result.body.url) || null;
  } catch (e) {
    return null;
  }
}

export function updateStatus(categoryKey, status, candidateKey, rejectedReason, rejectedField, warning) {
  return compliance.post('status/update', { categoryKey, status, candidateKey, rejectedReason, rejectedField, warning });
}

export function deleteCategory(categoryKey, candidateId, fieldName) {
  return compliance.post('fields/delete', { categoryKey, candidateId, fieldName });
}

export function addToAuditTrail(candidateId, orgKey, message, title, category, subCategory) {
  return compliance.post('audit/add', { candidateId, orgKey, message, title, category, subCategory });
}

export async function setWorkType(data, candidateId, orgKey, relationship) {
  const title = 'Work type updated';
  const message = (data.expiry && data.expiry !== 'none') ? `${message}, Expiry: ${moment(data.expiry).format('DD/MM/YYYY')}` : `New work type: ${data.type}`;
  await updateStatus('workType', 'VERIFIED', candidateId, relationship);
  await addToAuditTrail(candidateId, orgKey, message, title);
}

export async function getCategories(orgKey, underVerification, candidateKey, withCandidateData) {
  let path = `categories/get/${orgKey}`;
  if (candidateKey) path = `categories/get-with-candidateKey/${orgKey}/${candidateKey}`;
  if (underVerification) path += '/underVerification';
  if (withCandidateData) path += '/withCandidateData';

  const result = await compliance.get(path);
  return result.body.categories;
}

export async function fetchComplianceItems(candidateKey) {
  const response = await compliance.get('items/list', { candidateKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching compliance items failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function fetchComplianceItem(itemKey, candidateKey) {

  const response = await compliance.get('items/get', { itemKey, candidateKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Fetching compliance item failed';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function approveCertificate({ submissionKey, validFrom, expiresAt }) {

  const response = await compliance.post('certificate/approve', { submissionKey, validFrom, expiresAt });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Approving certificate failed.';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function rejectCertificate({ submissionKey, rejectedReason }) {

  const response = await compliance.post('certificate/reject', { submissionKey, rejectedReason });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Rejecting certificate failed.';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function approveProfRegSubmission({ submissionKey, engineKey }) {

  const response = await compliance.post(`${engineKey}/approve`, { submissionKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Approving profReg submission failed.';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function rejectProfRegSubmission({ submissionKey, engineKey, rejectedReason }) {

  const response = await compliance.post(`${engineKey}/reject`, { submissionKey, rejectedReason });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Rejecting profReg submission failed.';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function approveDbsUpdateSubmission({ submissionKey }) {

  const response = await compliance.post('dbs-update/approve', { submissionKey });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Approving DBS Update Service submission failed.';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function rejectDbsUpdateSubmission({ submissionKey, rejectedReason }) {

  const response = await compliance.post('dbs-update/reject', { submissionKey, rejectedReason });

  if (response.status >= 400) {
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? 'Rejecting DBS Update Service submission failed.';
    throw (error instanceof Error ? error : new Error(error));
  }

  return response.body;
}

export async function downloadCertificateImage(submissionKey, fileKey) {

  const response = await compliance.get('certificate/download-image', { submissionKey, fileKey });

  if (response.status >= 400) {
    const fallbackErrorMessage = 'Failed to download certificate image';
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? fallbackErrorMessage;
    reportError(error instanceof Error ? error : new Error(error), {
      api: {
        path: response?.full?.path ?? 'certificate/download-image',
        status: response.status,
        error,
        details: response?.body?.details ?? null,
        stacktrace: response?.body?.stackTrace ?? null,
      },
      payload: { submissionKey, fileKey },
    });

    // Return human readable error message to component
    throw new Error(response?.body?.details?.humanReadableErrorMessage ?? fallbackErrorMessage);
  }
  return response;
}

export async function downloadAllCertificateFiles(submissionKey) {

  const response = await compliance.get('certificate/download-all-files', { submissionKey });

  if (response.status >= 400) {
    const fallbackErrorMessage = 'Failed to download all certificate files';
    const error = response.error ?? response.body?.error?.message ?? response.body?.error ?? fallbackErrorMessage;
    reportError(error instanceof Error ? error : new Error(error), {
      api: {
        path: response?.full?.path ?? 'certificate/download-all-files',
        status: response.status,
        error,
        details: response?.body?.details ?? null,
        stacktrace: response?.body?.stackTrace ?? null,
      },
      payload: { submissionKey },
    });

    // Return human readable error message to component
    throw new Error(response?.body?.details?.humanReadableErrorMessage ?? fallbackErrorMessage);
  }
  return response;
}

