import { adminsReducer, AdminsState } from './admins';
import bank from './bank';
import calendar from './calendar';
import candidate from './candidate';
import compliance from './compliance';
import createDraftShifts from './createDraftShifts';
import createShifts from './createShifts';
import employer from './employer';
import filter from './filter';
import global from './global';
import help from './help';
import invite from './invite';
import jobs from './jobs';
import loading from './loading';
import { messaging, MessagingState } from './messaging';
import notifications from './notifications';
import orgsAndSites from './orgsAndSites';
import policies from './policies';
import rateCards from './rateCards';
import ratings from './ratings';
import references from './references';
import reporting from './reporting';
import rgs from './rgs';
import screen from './screen';
import { settingsReducer, SettingsState } from './settings';
import suppliers from './suppliers';
import ticket from './ticket';
import timesheets from './timesheets';
import user from './user';
import templates from './templates';
import { userInterface, UserInterfaceState } from './userInterface';

export type ReduxState = {
  admins: AdminsState,
  bank: any,
  calendar: any,
  candidate: any,
  compliance: any,
  createDraftShifts: any,
  createShifts: any,
  employer: any,
  filter: any,
  form: any,
  global: any,
  help: any,
  invite: any,
  jobs: any,
  loading: any,
  messaging: MessagingState,
  notifications: any,
  orgsAndSites: any,
  policies: any,
  rateCards: any,
  ratings: any,
  references: any,
  reporting: any,
  rgs: any,
  screen: any,
  settings: SettingsState,
  suppliers: any,
  templates: any,
  ticket: any,
  timesheets: any,
  user: any,
  userInterface: UserInterfaceState,
}

const reducers = {
  admins: adminsReducer,
  bank,
  calendar,
  candidate,
  compliance,
  createDraftShifts,
  createShifts,
  employer,
  filter,
  global,
  help,
  invite,
  jobs,
  loading,
  messaging,
  notifications,
  orgsAndSites,
  policies,
  rateCards,
  ratings,
  references,
  reporting,
  rgs,
  screen,
  settings: settingsReducer,
  suppliers,
  templates,
  ticket,
  timesheets,
  user,
  userInterface,
};

export default reducers;
