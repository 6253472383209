import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CloseButton from '../CloseButton';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

const styles = {
  content: {
    border: 'none',
    borderRadius: 8,
    maxHeight: '90vh',
    maxWidth: '100%',
    padding: 0,
    boxSizing: 'border-box',
    overflow: 'hidden',
    inset: 'auto',
  },
  overlay: {
    zIndex: 1000000,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    flex: '0 0 auto',
    position: 'relative',
    backgroundColor: colors.cavalry.backgroundLight2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    height: '50px',
  },
  footerContainer: {
    flex: '0 0 auto',
    position: 'relative',
  },
  bodyContainer: {
    flex: '1 1 auto',
  },
  header: {
    color: colors.black,
    fontSize: '1.5rem',
    width: '100%',
    textAlign: 'center',
    fontWeight: 500,
  },
  vflex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'auto',
  },
};

export default function CustomModal(props) {
  const modalStyles = {
    ...styles,
    content: {
      ...styles.content,
      ...((props.vflex || props.header) ? styles.vflex : {}),
      overflow: props.header ? 'hidden' : 'auto',
      ...props.contentStyle,
    },
    overlay: {
      ...styles.overlay,
      ...props.overlayStyle,
    },
  };

  if (props.header) {
    return (
      <Modal
        isOpen={props.isOpen}
        contentLabel={props.contentLabel}
        style={modalStyles}
        onRequestClose={props.onRequestClose}
        ariaHideApp={false /* Temp solution as working with class selectors won't work */}
      >
        <div style={styles.headerContainer}>
          <h2 style={styles.header}>{props.header}</h2>
          <CloseButton handleClose={props.onRequestClose} top={15} right={15} className="test-id-user-invite-close" />
        </div>
        <div style={{ ...styles.bodyContainer, ...(props.vflex ? styles.vflex : {}), ...props.bodyStyle }}>
          {props.children}
        </div>
      </Modal>
    );
  }

  modalStyles.content.padding = props.padding ?? props.contentStyle?.padding ?? 12;
  if (props.vflex) {
    modalStyles.content.display = 'flex';
    modalStyles.content.flexDirection = 'column';
  }

  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel={props.contentLabel}
      style={modalStyles}
      onRequestClose={props.onRequestClose}
      ariaHideApp={false /* Temp solution as working with class selectors won't work */}
    >
      {props.children}
    </Modal>
  );
}

const { oneOfType, arrayOf, node, func, bool, string, objectOf, number, object } = PropTypes;
CustomModal.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  contentLabel: string,
  contentStyle: object,
  padding: number,
  bodyStyle: objectOf(oneOfType([
    string,
    number,
  ])),
  overlayStyle: objectOf(oneOfType([
    string,
    number,
  ])),
  header: string,
  vflex: bool,
};

CustomModal.defaultProps = {
  contentLabel: 'Modal',
  contentStyle: {},
  bodyStyle: {},
  overlayStyle: {},
  header: '',
  vflex: false,
};
