import React, { useState, CSSProperties, useCallback, useMemo } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';

import colors from 'config/colors';
import { Contract } from 'types/Contract';

import Button from 'components/Button';
import DropDown from 'components/DropDown';
import Checkbox from 'components/Checkbox';
import CloseButton from 'components/CloseButton';
import Loading from 'components/Loading';
import { useFormFields, contractedHoursPeriodTypeOptions, EditContractState } from './ContractFormHooks';

const styles = {
  input: {
    boxSizing: 'border-box' as CSSProperties['boxSizing'],
    width: 48,
    color: '#666',
    border: '1px solid #CCC',
    padding: '6px',
    outline: 'none',
    borderRadius: 2,
  },
};

interface ContractFormProps {
  contract?: Contract,
  saveChanges: (fields: EditContractState) => void,
  isUpdating: boolean,
  error: string | null,
  cancel?: () => void,
  hasSavedContract: boolean,
}

function ContractForm(props: ContractFormProps): React.ReactElement {

  const {
    fields,
    onChangeStartDate,
    onChangeEndDate,
    onChangeContractedHours,
    onChangeContractedHoursPeriodType,
    onChangeWorkingTimeDirective,
    timezoneStartDate,
    timezoneEndDate,
    changesMade,
    resetState,
    startDateIsOutsideRange,
    endDateIsOutsideRange,
  } = useFormFields(props.contract);

  // A cancel function is supplied when creating a new contract (which closes the form). When updating an existing contract, the cancel button will reset to initial form state.
  const onCancelCb = useCallback(() => {
    return props.cancel ? props.cancel() : resetState();
  }, [props.cancel]);

  // Save button disabled if no changes have been made to an existing contract (props.contract supplied if creating new contract)
  const saveButtonDisabled = useMemo(() => props.isUpdating || (!props.cancel && !changesMade), [props.isUpdating, props.cancel, changesMade]);
  const cancelButtonDisabled = useMemo(() => props.isUpdating || (!props.cancel && !changesMade), [props.isUpdating, props.cancel, changesMade]);

  return (
    <>
      <TabSubHeaderBar
        header="Contract Details"
        showUnsavedChanges={changesMade && !props.isUpdating}
        saveChanges={useCallback(() => props.saveChanges(fields), [fields])}
        saveDisabled={saveButtonDisabled}
        cancelDisabled={cancelButtonDisabled}
        onCancel={onCancelCb}
        isUpdating={props.isUpdating}
        hasSavedContract={props.hasSavedContract}
      />

      {!!props.error && (
        <div className="contract-form__error-banner">
          <p>{props.error}</p>
        </div>
      )}

      <div className="contract-form">

        <div className="contract-form__row contract-form__date-fields space-children-24">
          <DateField
            date={timezoneStartDate}
            onDateChange={onChangeStartDate}
            label="Contract Start Date"
            clearValue={() => onChangeStartDate(null)}
            isOutsideRange={startDateIsOutsideRange}
            disabled={props.isUpdating}
          />
          <DateField
            date={timezoneEndDate}
            onDateChange={onChangeEndDate}
            label="Contract End Date"
            clearValue={() => onChangeEndDate(null)}
            isOutsideRange={endDateIsOutsideRange}
            disabled={props.isUpdating}
          />
        </div>

        <div className="contract-form__row">
          <div className="contract-form__hours-fields space-children-12">
            <ContractedHoursField contractedHours={fields.contractedHours} onChange={onChangeContractedHours} disabled={props.isUpdating} />
            <p className="contract-form__hours-per-text">hours per</p>
            <PeriodTypeField onChange={onChangeContractedHoursPeriodType} periodType={fields.contractedHoursPeriodType} disabled={props.isUpdating} />
          </div>
          <WorkingTimeDirective onChange={onChangeWorkingTimeDirective} workingTimeDirectiveApplies={fields.workingTimeDirectiveApplies} disabled={props.isUpdating} />
        </div>
      </div>
    </>
  );
}

ContractForm.defaultProps = { contract: undefined, cancel: undefined };

interface TabSubHeaderBarProps {
  header: string,
  saveDisabled: boolean,
  cancelDisabled: boolean
  showUnsavedChanges: boolean,
  saveChanges: () => void,
  onCancel: () => void,
  isUpdating: boolean,
  hasSavedContract: boolean,
}

function TabSubHeaderBar(props: TabSubHeaderBarProps) : React.ReactElement {
  return (
    <div className="tab-sub-header-bar" style={{ backgroundColor: props.showUnsavedChanges ? colors.greyBackground : colors.white }}>
      <h4 className="tab-sub-header-bar__title">{props.header}</h4>
      <div className="tab-sub-header-bar__buttons space-children-12">
        {props.showUnsavedChanges && (
          <p className="tab-sub-header-bar__changes-text">You have unsaved changes to this contract</p>
        )}
        {props.hasSavedContract && !props.showUnsavedChanges && (
          <p className="tab-sub-header-bar__changes-text" style={{ color: colors.positive }}>Contract changes saved</p>
        )}
        {props.isUpdating && <Loading size={33} />}
        <Button disabled={props.cancelDisabled} outline negative onClick={props.onCancel}>Cancel</Button>
        <Button disabled={props.saveDisabled} onClick={props.saveChanges}>Save Changes</Button>
      </div>
    </div>
  );
}

interface DateFieldProps {
  onDateChange: (date: moment.Moment | null) => void,
  clearValue: () => void,
  date: moment.Moment | null,
  label: string,
  isOutsideRange: (date: moment.Moment) => boolean,
  disabled: boolean
}

function DateField(props: DateFieldProps) {

  const [datePickerIsFocused, setDatePickerIsFocused] = useState(false);

  return (
    <div>
      <label className="contract-form__label">{props.label}</label>
      <div className="createShiftDatePicker contract-form__date-picker">
        <SingleDatePicker
          displayFormat="ll"
          date={props.date}
          onDateChange={props.onDateChange}
          focused={datePickerIsFocused}
          onFocusChange={({ focused } : { focused: boolean }) => setDatePickerIsFocused(focused)}
          openDirection="down"
          numberOfMonths={1}
          firstDayOfWeek={1}
          showDefaultInputIcon
          id="id"
          inputIconPosition="after"
          isOutsideRange={props.isOutsideRange}
          placeholder="None Set"
          disabled={props.disabled}
        />
        <div style={{ position: 'absolute', right: 36, top: 7 }}>
          {!!props.date && <CloseButton size={18} handleClose={props.clearValue} />}
        </div>
      </div>
    </div>
  );
}

interface ContractedHoursFieldProps {
  onChange: (hours: string) => void,
  contractedHours: number,
  disabled: boolean
}

function ContractedHoursField(props: ContractedHoursFieldProps): React.ReactElement {
  return (
    <div className="contract-form__field-container">
      <label className="contract-form__label">Contracted Hours</label>
      <input
        className="contract-form__hours-input"
        type="number"
        value={props.contractedHours}
        onChange={e => props.onChange(e.target.value)}
        style={styles.input}
        disabled={props.disabled}
      />
    </div>
  );
}

interface PeriodTypeFieldProps {
  periodType: { value: string, label: string },
  onChange: (type: {value: string, label: string}) => void,
  disabled: boolean
}

function PeriodTypeField(props: PeriodTypeFieldProps): React.ReactElement {
  return (
    <div style={{ alignSelf: 'flex-end', marginBottom: '-1px' }}>
      <DropDown
        options={Object.values(contractedHoursPeriodTypeOptions)}
        customStyles={{ control: { width: 100 } }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        portalPlacement="bottom"
        onChange={props.onChange}
        value={props.periodType}
        height={42}
        isDisabled={props.disabled}
      />
    </div>
  );
}

interface WorkingTimeDirectiveProps {
  workingTimeDirectiveApplies: boolean,
  onChange: (checked: boolean) => void,
  disabled: boolean
}

function WorkingTimeDirective(props: WorkingTimeDirectiveProps): React.ReactElement {
  return (
    <div className="contract-form__field-container">
      <label className="contract-form__label">Working Time Directive</label>
      <div className="space-children-12" style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
        <Checkbox
          checked={props.workingTimeDirectiveApplies}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <label style={{ fontSize: 16, color: '#999' }}>Apply working time directive</label>
      </div>
    </div>
  );
}

export default ContractForm;
