import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaAngleLeft } from 'react-icons/fa';
import Button from '../Button';
import ScrollView from '../ScrollView';
import colors from '../../config/colors';
import './wrapper.css';

const styles = {
  button: {
    backgroundColor: colors.positive,
  },
};

export default class Wrapper extends Component {
  updateScrollState() {
    if (this.scrollView) {
      this.scrollView.updateScrollState();
    }
  }

  mountScrollView = (domNode) => {
    if (domNode) {
      this.scrollView = domNode;
    }
  }

  render() {
    return (
      <div style={{ padding: this.props.padding }}>
        {this.props.onBack ?
          <div className="back">
            <Button
              small
              onClick={this.props.onBack}
              style={styles.button}
            >
              <FaAngleLeft />
              Back
            </Button>
            <h3>{this.props.title}</h3>
            <span className="right" />
          </div> : null}
        {this.props.noScroll ?
          this.props.children :
          <ScrollView
            className="contents"
            ref={this.mountScrollView}
            fadeAtEnd
          >
            {this.props.children || 'Loading...'}
          </ScrollView>}
        {this.props.editable && this.props.approve && this.props.cancel ?
          <div className="buttonContainer">
            <Button
              onClick={this.props.cancel}
              style={{ backgroundColor: colors.red }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.props.approve}
              style={{ backgroundColor: colors.green }}
              disabled={this.props.approveDisabled}
            >
              {this.props.loading ?
                'Loading...'
                :
                'Approve'
              }
            </Button>
          </div>
          :
          null
        }
      </div>
    );
  }
}

const { bool, string, func, number } = PropTypes;
Wrapper.propTypes = {
  editable: bool,
  noScroll: bool,
  title: string,
  onBack: func,
  approve: func,
  cancel: func,
  approveDisabled: bool,
  loading: bool.isRequired,
  padding: number,
};

Wrapper.defaultProps = {
  title: '',
  editable: false,
  noScroll: false,
  onBack: null,
  approve: null,
  cancel: null,
  approveDisabled: false,
  padding: null,
};
