import moment from 'moment-timezone';
import { BASE_URL } from '../config/settings';
import { getToken } from './auth';

const DEFAULT_LOGGING_COLLECTION = 'marketplace-website';

function serialize(message) {
  if (message instanceof Object) {
    const newMsg = message;
    if (!newMsg.collection) {
      newMsg.collection = DEFAULT_LOGGING_COLLECTION;
    }

    if (!newMsg.timestamp) {
      newMsg.timestamp = moment.utc().toISOString();
    }

    return JSON.stringify(newMsg);
  }

  const obj = {
    message,
    collection: DEFAULT_LOGGING_COLLECTION,
    timestamp: moment.utc().toISOString(),
  };

  return JSON.stringify(obj);
}

export function log(message) {
  const body = serialize(message);
  return logRaw(body);
}

export async function logRaw(body) {
  const token = await getToken();
  const options = {
    method: 'POST',
    body,
    headers: {
      'content-type': 'text/plain',
      Authorization: `Bearer: ${token}`,
    },
  };
  const url = `${BASE_URL}/api/log`;
  console.debug(`${url} hit with`, options);

  try {
    const start = Date.now();
    const response = await fetch(url, options);
    const duration = Date.now() - start;
    console.debug(`Took ${duration} ms`);
    const json = await response.json();
    console.debug('JSON response', json);
    return true;
  } catch (e) {
    console.debug('Failed to hit logging endpoint', e);
    return false;
  }
}
