import React, { useMemo } from 'react';

import { PermissionGrant, Scope, Permissions, Entity, PermissionsMetadata } from 'types/AdminGroup';

import colors from 'config/colors';

import Button from 'components/Button';
import DataTable from 'components/DataTable';
import MoreButton from 'components/MoreButton';
import DetailsHeaderBar from './DetailsHeaderBar';

const entityNames = {
  shift: 'Shifts',
  'payment-run': 'Payment Run',
  staff: 'Staff',
};
interface Props {
  permissionGrants: PermissionGrant[],
  permissionsMetadata: PermissionsMetadata,
}

export default function AdminGroupPermissions(props: Props): React.ReactElement {

  const moreButtonMenuItems = useMemo(() => [
    { key: 'edit-permissions', label: 'Edit Permissions', onClick: () => {} },
  ], []);

  const permissionGrantsColumns = useMemo(() => {
    return [
      { name: 'entity', header: 'Type', formatter: (entity: Entity) => entityNames[entity] },
      {
        name: 'scopes',
        header: 'Scope(s)',
        formatter: (scopes: Scope[]) => {
          return scopes.map(scope => <p>{scope.name}</p>);
        },
      },
      {
        name: 'permissions',
        header: 'Permissions',
        formatter: (permissions: Permissions) => {
          return Object.entries(permissions).filter(([, enabled]) => enabled).map(([permKey]) => <p>{props.permissionsMetadata[permKey]?.name ?? ''}</p>);
        },
      },
      // {
      //   name: 'actions',
      //   width: 60,
      //   header: 'Actions',
      //   formatter: () => {
      //     return (
      //       <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      //         <MoreButton menuItems={moreButtonMenuItems} openMenuDirection="right" />
      //       </div>
      //     );
      //   },
      // },
    ];
  }, [props.permissionGrants]);

  return (
    <>
      {/*
      <DetailsHeaderBar justifyContent="flex-end">
        <Button>+ Permissions Grant</Button>
      </DetailsHeaderBar>
      */}
      {props.permissionGrants.length ?
        <div style={{ flex: 1, overflow: 'auto' }}>
          <DataTable<PermissionGrant, undefined>
            columns={permissionGrantsColumns as any}
            rows={props.permissionGrants}
            onRowClick={() => {
            }}
            cellStyle={{ verticalAlign: 'initial', color: '#333' }}
            overrideHeadingStyles={{ color: '#333', fontWeight: 500, textTransform: 'none' }}
          />
        </div>
        :
        <div style={{ flex: 1, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: colors.text }}>This admin group has not been granted any permissions.</p>
        </div>
      }
    </>
  );
}
