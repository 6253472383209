import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MdDelete, MdContentCopy, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import classNames from 'classnames';
import { isNil } from 'lodash-es';

import { useSelector } from 'hooks/redux';
import { useFeatureFlag } from 'hooks/feature';

import { smartGroup } from 'lib/shift-grouping';

import { capitalize } from 'lib/helpers';
import colors from 'config/colors';

import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import CheckBox from 'components/Checkbox';
import StatusPill from 'components/StatusPill';
import { CandidateRatingStar } from '../CandidateRatingStar';

import { useSelectedShifts, useCollapsedSlots, flattenShiftsFromSlots } from '../shift-hooks';
import { shiftListColumns, defaultColumnsOrder, createColumnsSortFunction } from '../ShiftListHelpers';
import './styles.scss';

const styles = {
  actionButton: {
    display: 'inline-block',
    cursor: 'pointer',
    paddingRight: '6px',
  },
  scrollContainer: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
};

const getClassName = (props, shift) => {
  const rowIsSelected = props.selectedShift === shift.key;
  const isDraft = !!shift.isDraft;
  return classNames({
    'shifts-list__selected-row': rowIsSelected,
    'shifts-list__draft-row': isDraft,
  });
};

const getLocalShiftsRowsIsExpanded = () => {
  const localExpandableStatus = JSON.parse(window.localStorage.getItem('cw:shifts:dayview:expandable'));
  if (isNil(localExpandableStatus)) {
    setLocalShiftsRowsIsExpanded(false);
    return false;
  }
  return localExpandableStatus;
};

const setLocalShiftsRowsIsExpanded = (status) => {
  window.localStorage.setItem('cw:shifts:dayview:expandable', status);
};

const generateDataTableColumns = (props) => {

  const {
    siteAreasOn,
    shiftListViewColumnsOrder,
    selectSpecifiedShifts,
    unselectAllShifts,
    setShiftIsSelected,
    shiftList,
    selectedShiftKeys,
    allowedDataTableColumns,
    collapsedShiftKeys,
    setShiftIsCollapsed,
    collapseSpecifiedShifts,
    expandAllShifts,
  } = props;

  const allSelected = selectedShiftKeys.length && selectedShiftKeys.length === shiftList.length;
  const shiftKeys = shiftList.map(shift => shift.key).filter(Boolean);
  const columns = {
    ...shiftListColumns,
    collapseExpand: {
      name: 'collapseExpand',
      header: (
        <div style={{ position: 'relative', cursor: 'pointer' }}>
          <div style={{ position: 'absolute', top: -14, left: -3 }}>
            {collapsedShiftKeys.length > 0 ?
              <div onClick={() => {
                setLocalShiftsRowsIsExpanded(true);
                expandAllShifts();
              }}
              >
                <MdKeyboardArrowRight size={24} />
              </div>
              :
              <div onClick={() => {
                setLocalShiftsRowsIsExpanded(false);
                collapseSpecifiedShifts(shiftKeys);
              }}
              >
                <MdKeyboardArrowDown size={24} />
              </div>
            }
          </div>
        </div>
      ),
      width: 24,
      formatter: (shift) => {

        if (!shift.canControlCollapsibleRows) return null;

        const isCollapsed = collapsedShiftKeys.includes(shift.key);
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShiftIsCollapsed(shift.key, !isCollapsed);
            }}
            style={{ position: 'relative', cursor: 'pointer' }}
          >
            <div style={{ position: 'absolute', top: -14, left: -3, color: colors.text }}>
              {isCollapsed ? <MdKeyboardArrowRight size={24} /> : <MdKeyboardArrowDown size={24} /> }
            </div>
          </div>
        );
      },
    },
    checkbox: {
      name: 'checkbox',
      header: (
        <CheckBox
          size={18}
          checked={allSelected}
          onChange={() => (allSelected ? unselectAllShifts() : selectSpecifiedShifts(shiftKeys))}
        />
      ),
      width: 24,
      formatter: (shift) => {
        if (shift.isSlot) return null;
        const isSelected = selectedShiftKeys.includes(shift.key);
        return <CheckBox size={18} checked={isSelected} onChange={(checked) => setShiftIsSelected(shift.key, checked)} />;
      },
    },
    service: {
      name: 'service',
      header: 'Service',
      formatter: ({ serviceName }) => (serviceName ? <span>{serviceName}</span> : null),
    },
    location: {
      name: 'location',
      header: 'Location',
      formatter: (job) => {
        const fontColor = job.isSlot ? 'rgba(0, 0, 0, 0.3)' : colors.text;
        if (!siteAreasOn) return <span style={{ color: fontColor }}>{job.siteName}</span>;

        return (
          <div style={{ display: 'inline-block', lineHeight: 1, verticalAlign: 'middle' }}>
            <span style={{ fontWeight: 'bold', color: fontColor, fontSize: '0.85rem' }}>{job.siteName}</span>
            <br />
            <span style={{ color: fontColor, fontSize: '0.85rem' }}>{job.areaName}</span>
          </div>
        );
      },
    },
    bookedCandidate: {
      name: 'booked_candidate',
      header: 'Booked Staff',
      width: 280,
      formatter: (job, index, additionalProps) => {
        const shiftKey = job.isSlot ? job.slotShiftKey : job.key;
        const goToBookingsTab = () => additionalProps.goToShiftTab(shiftKey, 'bookings');

        // Booked job
        if (job.showBooking) {
          return (
            <div style={{ display: 'flex' }}>
              <CandidateLink
                goToShiftTab={() => {
                  if (job.isDraft) {
                    additionalProps.goToShiftTab(shiftKey);
                  } else {
                    goToBookingsTab();
                  }
                }}
                className={classNames({
                  'booked-candidate-link': true,
                  'booked-candidate-link--filled-by-bank': !job.candidateSupplierName,
                  'booked-candidate-link--filled-by-agency': !!job.candidateSupplierName,
                })}
              >
                {job.candidateName && capitalize(job.candidateName)}
                {job.candidatesTrucatedLabel}
              </CandidateLink>
              {job.candidateRating && (
                <CandidateRatingStar size={10} rating={job.candidateRating} />
              )}
              {job.candidateBanks ? <span style={{ fontWeight: 'normal', color: colors.text }}>&nbsp;{` (${Object.values(job.candidateBanks)[0]})`}</span> : ''}
              {job.candidateSupplierName ? <span style={{ fontWeight: 'normal', color: colors.text }}>&nbsp;{`(${capitalize(job.candidateSupplierName)})`}</span> : ''}
            </div>
          );
        }

        let agencyLabel = null;

        // Agency requested / approved
        if (job.agencyRequested || job.agencyApproved) {
          agencyLabel = job.agencyApproved ? 'Approved for agency' : 'Agency requested';
        }

        // Released to agency
        if (job.suppliers || job.releasedToSupplier) {
          agencyLabel = 'Released to agency';
        }

        // Applicants
        const numberOfApplicants = job.bankApplicantCount + job.supplierApplicantCount;
        const applicantsLabel = numberOfApplicants > 0 ? `${numberOfApplicants} applicant${numberOfApplicants > 1 ? 's' : ''}` : null;

        if (applicantsLabel || agencyLabel) {
          return (
            <span>
              {applicantsLabel && (
                <CandidateLink goToShiftTab={goToBookingsTab} className="booked-candidate-link">
                  <span style={{ color: '#999', fontWeight: 'normal' }}>{applicantsLabel}</span>
                </CandidateLink>
              )}
              {agencyLabel && <span style={{ color: colors.cavalry.error, fontWeight: 'normal' }}>{applicantsLabel ? ', ' : ''}{agencyLabel}</span>}
            </span>
          );
        }

        // Draft with targeted candidate
        if (job.isDraft && job.candidateName) {
          return <span style={{ color: '#999', fontWeight: 'bold' }}>{job.candidateName}</span>;
        }

        return null;
      },
    },
    status: {
      name: 'status',
      header: 'Status',
      width: 145,
      formatter: (job, index, { orgKey }) => (!job.isSlot ? <StatusPill job={{ ...job, orgKey, status: job.statusError }} /> : null),
    },
    actions: {
      name: 'actions',
      header: 'Actions',
      width: 50,
      className: () => 'p-hide',
      formatter: (job, index, additionalProps) => {
        if (!additionalProps.showCreateShifts || job.isSlot) return null;

        return (
          <>
            <div onClick={(e) => { additionalProps.copyShift(job.key); e.stopPropagation(); }} title="Copy Shift" className="rise" style={styles.actionButton}>
              <MdContentCopy size={16} />
            </div>
            {job.isDraft &&
            <div onClick={(e) => { additionalProps.removeShift(job.key); e.stopPropagation(); }} title="Delete Draft" className="rise" style={styles.actionButton}>
              <MdDelete size={18} />
            </div>
        }
          </>
        );
      },
    },
  };

  return ['collapseExpand', 'checkbox', ...(shiftListViewColumnsOrder ?? defaultColumnsOrder)].filter(column => allowedDataTableColumns.includes(column)).map(column => columns[column]);
};

export default function DayListView(props) {

  if (props.isLoading) {
    return <Loading flex size={60} />;
  }

  const { copyShift, removeShift, allowedDataTableColumns, shiftList } = props;

  const { selectedShiftKeys, setShiftIsSelected, selectSpecifiedShifts, unselectAllShifts } = useSelectedShifts(props.period);
  const { collapsedShiftKeys, keyedCollapsedShiftKeys, setShiftIsCollapsed, collapseSpecifiedShifts, expandAllShifts } = useCollapsedSlots(props.period);

  const siteAreasOn = useFeatureFlag('siteAreas');
  const isSupplier = useFeatureFlag('supplier');
  const orgKey = useSelector(state => state.global.currentOrgKey);
  const shiftListViewSortOrder = useSelector(state => state.global.orgConfig?.shiftListViewSortOrder);
  const shiftListViewColumnsOrder = useSelector(state => state.global.orgConfig?.shiftListViewColumnsOrder);
  const specialitiesMetadata = useSelector(state => state.global.metadata.specialities);
  const rolesMetadata = useSelector(state => state.global.metadata.roles);
  const gradesMetadata = useSelector(state => state.global.metadata.grades);

  const showCreateShifts = props.showCreateShifts || !isSupplier;

  const combinedProps = {
    ...props,
    orgKey,
    siteAreasOn,
    shiftListViewColumnsOrder,
    showCreateShifts,
    copyShift,
    removeShift,
    selectedShiftKeys,
    setShiftIsSelected,
    selectSpecifiedShifts,
    unselectAllShifts,
    rolesMetadata,
    specialitiesMetadata,
    gradesMetadata,
    goToShiftTab: props.goToShiftTab,
    allowedDataTableColumns,
    collapsedShiftKeys,
    setShiftIsCollapsed,
    collapseSpecifiedShifts,
    expandAllShifts,
  };

  const shiftKeys = shiftList.map(shift => shift.key).filter(Boolean);

  useEffect(() => {
    const isExpanded = getLocalShiftsRowsIsExpanded();
    if (!isExpanded) collapseSpecifiedShifts(shiftKeys);
  }, []);

  const flattenedShiftSlots = useMemo(() => {
    const columnsSortFunction = createColumnsSortFunction({ shiftListViewSortOrder, rolesMetadata, gradesMetadata, specialitiesMetadata });

    let sortedShifts;
    if (props.groupBy) {
      sortedShifts = smartGroup(props.shiftList, props.groupBy, combinedProps)
        .flatMap((group) => {

          const headerRow = { type: 'header', key: group.key, title: group.label, isHeaderRow: true };
          const groupRows = group.shifts
            .map(shift => ({ ...shift, isHeaderRow: false, className: getClassName(props, shift) }))
            .sort(columnsSortFunction);

          return [headerRow, ...groupRows];
        });
    } else {
      sortedShifts = props.shiftList
        .map(shift => ({ ...shift, className: getClassName(props, shift) }))
        .sort(columnsSortFunction);
    }

    return sortedShifts.flatMap((shift) => (shift.isHeaderRow ? shift : flattenShiftsFromSlots(shift, keyedCollapsedShiftKeys)));
  }, [shiftListViewSortOrder, rolesMetadata, gradesMetadata, specialitiesMetadata, props.shiftList, props.groupBy, combinedProps, keyedCollapsedShiftKeys]);

  return (
    <>
      <div style={styles.scrollContainer}>
        <div style={{ flex: 1, overflow: 'auto' }}>
          <DataTable
            multiHeader={!!props.groupBy}
            noTextWrap
            fixedLayout
            style={{ minWidth: '840px' }}
            rows={flattenedShiftSlots}
            columns={generateDataTableColumns(combinedProps)}
            overrideHeadingStyles={{
              color: colors.text,
              textTransform: 'none',
              fontSize: '0.9rem',
            }}
            onRowClick={(shiftKey, shift) => {
              const key = shift.isSlot ? shift.slotShiftKey : shift.key;
              const goToBookingsTab = () => props.goToShiftTab(key, 'bookings');
              if (shift.isDraft || !shift.isSlot) {
                props.goToShiftTab(key);
              } else {
                goToBookingsTab();
              }
            }}
            additionalProps={combinedProps}
          />
        </div>
      </div>
    </>
  );
}

const CandidateLink = props => (
  <a
    className={props.className}
    onClick={(e) => {
      e.stopPropagation();
      props.goToShiftTab();
    }}
    role="button"
    tabIndex={0}
  >
    {props.children}
  </a>
);

const { bool, objectOf, object, func, string, arrayOf } = PropTypes;
DayListView.propTypes = {
  copyShift: func.isRequired,
  allowedDataTableColumns: arrayOf(string).isRequired,
  selectedShift: string,
  shiftList: arrayOf(object).isRequired,
  keyedShifts: objectOf(object).isRequired,
  removeShift: func.isRequired,
  showCreateShifts: bool,
  goToShiftTab: func.isRequired,
};

DayListView.defaultProps = { showCreateShifts: false, selectedShift: undefined };

CandidateLink.propTypes = {
  className: string.isRequired,
  children: string.isRequired,
  goToShiftTab: func.isRequired,
};
