import { v4 as uuidv4 } from 'uuid';
import { firstBy } from 'thenby';
import moment from 'moment';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { findApplicableRateCards } from 'lib/helpers-metadata';
import { getDistanceInMiles } from 'lib/location-helpers';
import { getSpeciality, getSpecialityOptions } from 'routes/Jobs/rgs-helpers';
import { shiftFieldMappings } from 'config/shiftFieldMappings';
import colors from 'config/colors';
import { RateCard, Role, RgsMetadata, PreferredGender, Reason, CostCentre, ServiceMetadata } from 'types/Metadata';
import { PublishedShiftSummary, DraftShift, TemplateDraftShift, DayOfWeek, TemplateShiftFromApi, TemplatePeriod } from 'types/ShiftTypes';
import { Sites, Site } from 'types/Sites';
import { OrgBank } from 'types/Banks';

const dayOfWeekMapping: { [key: string]: string } = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

interface ShiftFilters {
  sites?: string[]
  roles?: string[]
  grades?: string[]
  specialities?: string[]
}

export function shiftMatchesFilters(shift: DraftShift, shiftFilters: ShiftFilters) {
  if (!shift || !shiftFilters) return true;

  const selectedSiteKeys = shiftFilters?.sites ?? [];
  const selectedRoleKeys = shiftFilters?.roles ?? [];
  const selectedSpecialityKeys = shiftFilters?.specialities ?? [];
  const selectedGradeKeys = shiftFilters?.grades ?? [];

  return (!shift.site || !selectedSiteKeys.length || selectedSiteKeys.includes(shift.site.id))
      && (!shift.role || !selectedRoleKeys.length || selectedRoleKeys.includes(shift.role.id))
      && (!shift.speciality || !selectedSpecialityKeys.length || selectedSpecialityKeys.includes(shift.speciality.id))
      && (!shift.grade || !selectedGradeKeys.length || selectedGradeKeys.includes(shift.grade.id));
}

interface CalculateStartAndEndTimesProps {
  date: string,
  startTimeOfDay: string,
  endTimeOfDay: string,
  periodType: TemplatePeriod,
  dayOfWeek: DayOfWeek | undefined,
  timezone: string,
}

export function calculateStartAndEndTimesFromTemplateShift({ date, startTimeOfDay, endTimeOfDay, periodType, dayOfWeek, timezone }: CalculateStartAndEndTimesProps): [string, string] {

  const dayOfWeekNumbers: { [key: string]: number } = { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };

  const newDate = periodType === 'week' ? convertFromUtcToTimezone(date, timezone).startOf('day').add(dayOfWeekNumbers[dayOfWeek ?? 0], 'days') : convertFromUtcToTimezone(date, timezone).startOf('day');

  // Pull start/end hour/minute values from strings
  const [startHour, startMinute] = startTimeOfDay.split(':').map((time: string) => parseInt(time, 10));
  const [endHour, endMinute] = endTimeOfDay.split(':').map((time: string) => parseInt(time, 10));

  // Create moment objects from start/end hour/minutes
  const startMoment = newDate.clone().hours(startHour).minutes(startMinute);
  let endMoment = newDate.clone().hours(endHour).minutes(endMinute);

  // If start time is after end time, add 1 day to end time
  const startTimeEndTimeDiffInMinutes = endMoment.diff(startMoment, 'minutes');
  if (startTimeEndTimeDiffInMinutes <= 0) endMoment = endMoment.clone().add(1, 'days');

  return [startMoment.toISOString(), endMoment.toISOString()];
}

const findShiftRateCard = (rateCards: RateCard[], shift: PublishedShiftSummary | TemplateShiftFromApi, orgKey: string) => {

  // const { rateCardKey } = shift;

  const applicableRateCards = findApplicableRateCards(rateCards, shift.roleKey, shift.specialityKey, shift.gradeKey, shift.siteKey, shift.areaKey, shift.reasonKey, shift.subReasonKey, shift.serviceKey, orgKey);
  const customHourlyRateCard = applicableRateCards.find((rateCard: RateCard) => rateCard.requireCustomHourlyRate && shift.customHourlyRate);
  const rateCardsWithoutCustomRate = applicableRateCards.filter(rateCard => !rateCard.requireCustomHourlyRate);
  const sameSettingsRateCard = applicableRateCards.find((rateCard: RateCard) => (shift?.timesheetTypeKey && rateCard.timesheetTypeKey === shift.timesheetTypeKey) && (shift?.rateModifierKey && rateCard.rateModifierKey === shift.rateModifierKey));
  return customHourlyRateCard ?? sameSettingsRateCard ?? rateCardsWithoutCustomRate[0];
};

export function getTemplateMandatoryFields(shiftMandatoryFields: string[], periodType: TemplatePeriod) : string[] {
  const templateMandatoryFields = [...shiftMandatoryFields.filter(field => field !== 'startTime' && field !== 'endTime'), 'startTimeOfDay', 'endTimeOfDay'];
  if (periodType !== 'day') templateMandatoryFields.push('dayOfWeek');
  return templateMandatoryFields;
}

interface CreateDraftFromPublishedShift {
  publishedShift: PublishedShiftSummary,
  preferredGender: PreferredGender,
  orgRateCards: RateCard[],
  adminBanks: OrgBank[],
  saveCandidate: boolean,
}

export function createDraftFromPublishedShift({ publishedShift, preferredGender, orgRateCards, adminBanks, saveCandidate }: CreateDraftFromPublishedShift): DraftShift {

  const {
    orgKey,
    startTime,
    endTime,
    serviceKey,
    serviceName,
    siteKey,
    siteName,
    areaKey,
    areaName,
    roleKey,
    roleName,
    gradeKey,
    gradeName,
    specialityKey,
    specialityName,
    preferredGenderKey,
    reasonKey,
    reasonName,
    subReasonKey,
    subReasonName,
    publicDescription,
    privateNotes,
    timesheetTypeKey,
    rateModifierKey,
    costCentreKey,
    costCentreName,
    releasedBanks,
    customHourlyRate,
    slotsRequired,
    bookedCandidates,
  } = publishedShift;

  // Find rate cards that match shift specification
  const applicableRateCards = findApplicableRateCards(orgRateCards || [], roleKey, specialityKey, gradeKey, siteKey, areaKey, reasonKey, subReasonKey, serviceKey, orgKey);
  const sameSettingsRateCard = applicableRateCards.find(rateCard => rateCard.timesheetTypeKey === timesheetTypeKey && rateCard.rateModifierKey === rateModifierKey);
  const rateCard = sameSettingsRateCard ?? applicableRateCards[0];

  let preferredGenderField = null;
  if (preferredGender?.enabled) {
    preferredGenderField = preferredGender.default;
    if (preferredGenderKey && preferredGenderKey !== preferredGender.default.id) {
      preferredGenderField = { id: preferredGenderKey, name: preferredGender.genders[preferredGenderKey].name };
    }
  }

  // Set bankKeys to published shift banks (if any)
  const bankKeys = releasedBanks?.length ? releasedBanks : null;

  return {
    key: uuidv4(),
    startTime,
    endTime,
    service: serviceKey && serviceName ? { id: serviceKey, name: serviceName } : null,
    site: { id: siteKey, name: siteName },
    area: areaKey && areaName ? { id: areaKey, name: areaName } : null,
    role: roleKey && roleName ? { id: roleKey, name: roleName } : null,
    grade: gradeKey && gradeName ? { id: gradeKey, name: gradeName } : null,
    speciality: specialityKey && specialityName ? { id: specialityKey, name: specialityName } : null,
    reason: reasonKey && reasonName ? { id: reasonKey, name: reasonName } : null,
    reason2: subReasonKey && subReasonName ? { id: subReasonKey, name: subReasonName } : null,
    preferredGender: preferredGenderField,
    publicDescription,
    privateNotes,
    costCentre: costCentreKey && costCentreName ? { code: costCentreKey, description: costCentreName, careGroup: costCentreName } : null,
    rateCard: rateCard || null,
    customHourlyRate: customHourlyRate || null,
    bankKeys,
    slotsRequired,
    candidates: bookedCandidates.map(candidate => ({ id: candidate.candidateKey, name: candidate.candidateName })),
    status: null,
    response: null,
    isValid: true,
  };
}

export function createTemplateShiftFromDraftShift(draftShift: DraftShift, timezone: string, periodType: TemplatePeriod): TemplateDraftShift {
  const { startTime, endTime, ...genericDraftProps } = draftShift;
  const startTimeOfDay = convertFromUtcToTimezone(startTime, timezone).format('HH:mm');
  const endTimeOfDay = convertFromUtcToTimezone(endTime, timezone).format('HH:mm');
  const dayOfWeek = (periodType !== 'day' ? convertFromUtcToTimezone(startTime, timezone).format('ddd').toLowerCase() : null) as DayOfWeek | null;
  const dayOfWeekField = dayOfWeek ? { id: dayOfWeek, name: dayOfWeekMapping[dayOfWeek] } : null;

  return { ...genericDraftProps, startTimeOfDay, endTimeOfDay, dayOfWeek: dayOfWeekField };
}

export function addResponseDataToTemplateDraftShift(templateDraftShift: TemplateDraftShift, error: string | null) : TemplateDraftShift {
  return {
    ...templateDraftShift,
    status: error ? 'Failed' : null,
    response: error ?? null,
    isValid: !error,
  };
}

export function addResponseDataToDraftShift(draftShift: DraftShift, error: string | null, status: string | null) : DraftShift {
  return {
    ...draftShift,
    status,
    response: error ?? null,
    isValid: !error,
  };
}

interface ConvertTemplateShiftsToDraftFormatProps {
  shift: TemplateShiftFromApi,
  sites: Sites,
  roles: Role[],
  rgsMetadata: RgsMetadata,
  preferredGender: PreferredGender,
  rateCards: RateCard[],
  shiftReasons: Reason[],
  costCentres: CostCentre[],
  mandatoryFields: string[],
  services: ServiceMetadata[],
  orgKey: string,
}

export function convertTemplateApiShiftToDraftFormat({ shift, orgKey, sites, roles, rgsMetadata, preferredGender, rateCards, shiftReasons, costCentres, mandatoryFields, services }: ConvertTemplateShiftsToDraftFormatProps): TemplateDraftShift {
  const {
    key,
    dayOfWeek,
    startTimeOfDay,
    endTimeOfDay,
    serviceKey,
    siteKey,
    areaKey,
    roleKey,
    gradeKey,
    specialityKey,
    preferredGenderKey,
    reasonKey,
    subReasonKey,
    publicDescription,
    privateNotes,
    costCentreKey,
    bankKeys,
    targetedCandidates,
    customHourlyRate,
    error,
    slotsRequired,
  } = shift;

  // Find service
  const service = services.find(s => s.key === serviceKey) ?? null;

  // Find site and area names
  const site = sites[siteKey];
  const area = site.areas?.find(a => a.key === areaKey) ?? null;

  // Find role and grade names
  const role = roles.find(r => r.value === roleKey);
  const roleField = roleKey && role ? { id: roleKey, name: role?.label } : null;
  const grade = roleKey && gradeKey ? rgsMetadata[roleKey].grades[gradeKey] : null;

  // Find speciality name from role metadata if it exists
  let speciality = null;
  if (roleKey && specialityKey) {
    speciality = rgsMetadata[roleKey].specialities[specialityKey];
  } else {
    // Otherwise find from specialities metadata
    const specialities = getSpecialityOptions(service, roleField, rgsMetadata);
    speciality = getSpeciality(specialityKey, specialities, mandatoryFields);
  }

  // Find reason names
  const reason = shiftReasons.find(r => r.id === reasonKey);
  const subReasonName = reason && subReasonKey ? reason.subreasons[subReasonKey] : null;

  // Lookup costCentre and rateCard
  const costCentre = costCentreKey ? costCentres.find(cc => cc.code === costCentreKey) : null;
  const rateCard = findShiftRateCard(rateCards, shift, orgKey);

  const draftShift: TemplateDraftShift = {
    key,
    dayOfWeek: dayOfWeek ? { id: dayOfWeek, name: dayOfWeekMapping[dayOfWeek] } : null,
    startTimeOfDay,
    endTimeOfDay,
    service: service ? { id: service.key, name: service.name } : null,
    site: { id: siteKey, name: site.name },
    area: areaKey && area ? { id: areaKey, name: area?.name } : null,
    role: roleField,
    grade: gradeKey && grade ? { id: gradeKey, name: grade?.name } : null,
    speciality: specialityKey && speciality ? { id: specialityKey, name: speciality?.name } : null,
    reason: reason ? { id: reasonKey, name: reason.name } : null,
    reason2: subReasonKey && subReasonName ? { id: subReasonKey, name: subReasonName } : null,
    preferredGender: preferredGender ? ((preferredGenderKey && preferredGender.genders[preferredGenderKey] && { id: preferredGenderKey, name: preferredGender.genders[preferredGenderKey].name }) || preferredGender.default) : null,
    publicDescription,
    privateNotes,
    costCentre: costCentreKey && costCentre ? { code: costCentre.code, careGroup: costCentre.careGroup, description: costCentre.description } : null,
    rateCard,
    customHourlyRate: typeof customHourlyRate === 'number' ? String(customHourlyRate) : null,
    bankKeys,
    slotsRequired,
    candidates: targetedCandidates.map(({ candidateKey, candidateName }) => ({ id: candidateKey, name: candidateName })),
    isValid: !error,
    status: error ? 'Failed' : null,
    response: error ?? null,
  };

  // Reference values against mandatory fields to check that shift is valid
  const shiftIsValid = mandatoryFields.every((field: string) => {
    const draftValue = draftShift[shiftFieldMappings[field]?.key ?? field];
    if (field === 'bank') return (Array.isArray(draftValue) && !!draftValue.length) || (Array.isArray(!!draftShift.candidates) && !!draftShift.candidates.length);
    return !!draftValue;
  });

  draftShift.isValid = !error && shiftIsValid;

  return draftShift;
}

interface ApplicableCandidate {
  key: string,
  name: string,
  latitude: string | null,
  longitude: string | null,
  minutesPlanned: string | null,
  minutesWorked: string | null,
  contractedHours: string | null,
  contractedHoursPeriodType: 'week' | 'month' | null,
  workingTimeDirectiveApplies: boolean | null,
  warning: boolean,
}

interface ApplicableCandidatesPayload {
  value: string,
  label: string,
  warning: boolean,
  optionStyles: { color: string } | undefined,
  subLabel: string | null,
}

function getCandidateDistanceToSite(site: Site, candidate: ApplicableCandidate): string {
  if (site.latitude && site.longitude && candidate.latitude && candidate.longitude) {
    const distance = getDistanceInMiles({
      latFrom: parseFloat(candidate.latitude),
      lonFrom: parseFloat(candidate.longitude),
      latTo: parseFloat(site.latitude),
      lonTo: parseFloat(site.longitude),
    });
    return `${distance} mi`;
  }
  return '-';
}

const minsToHours = (minutes: number) => Math.round((minutes / 60) * 100) / 100;

export function calculateMinutesWorkedAndMinutesPlanned(contractedHours: string, minutesPlanned: string | null, minutesWorked: string | null): { contractedHours: number, minutesWorkedAndPlanned: number } {

  const contractedHoursAsNumber = parseFloat(contractedHours);
  const minutesWorkedAsNumber = minutesWorked ? parseFloat(minutesWorked) : 0;
  const minutesPlannedAsNumber = minutesPlanned ? parseFloat(minutesPlanned) : 0;

  return { contractedHours: contractedHoursAsNumber, minutesWorkedAndPlanned: minutesWorkedAsNumber + minutesPlannedAsNumber };
}

interface ComposeDistanceAndHoursTextTypes {
  candidate: ApplicableCandidate,
  site: Site,
  showCandidateLocationsFeatureIsOn: boolean,
  contractedHoursFeatureIsOn: boolean,
}

export function composeDistanceAndHoursText({ candidate, site, showCandidateLocationsFeatureIsOn, contractedHoursFeatureIsOn }: ComposeDistanceAndHoursTextTypes): string | null {
  const { contractedHours, minutesPlanned, minutesWorked } = candidate;

  // Compose distance string if feature is on
  const distance = showCandidateLocationsFeatureIsOn ? getCandidateDistanceToSite(site, candidate) : null;

  // Calculate contracted hours if data available and feature enabled
  const hours = contractedHoursFeatureIsOn && contractedHours !== null && (minutesPlanned !== null || minutesWorked !== null) ? calculateMinutesWorkedAndMinutesPlanned(contractedHours, minutesPlanned, minutesWorked) : null;

  const hoursWorked = hours ? minsToHours(hours.minutesWorkedAndPlanned) : null;
  const hoursText = hours !== null ? `${hoursWorked}/${hours.contractedHours} hours` : null;

  return distance || hoursText ? `${distance ?? ''}${hoursText ? `\u00a0\u00a0\u00a0\u00a0${hoursText}` : ''}` : null;
}

export function createApplicableCandidatesSelectList(candidates: ApplicableCandidate[], site: Site, showCandidateLocationsFeatureIsOn: boolean, contractedHoursFeatureIsOn: boolean): ApplicableCandidatesPayload[] {
  return candidates
    .map((candidate) => {
      return {
        value: candidate.key,
        label: candidate.name,
        warning: candidate.warning,
        optionStyles: candidate.warning ? { color: `${colors.warning}` } : undefined,
        subLabel: composeDistanceAndHoursText({ candidate, site, showCandidateLocationsFeatureIsOn, contractedHoursFeatureIsOn }),
      };
    }).sort(firstBy('label'));
}

interface CheckIfBookedCandidateExceedsContractedHoursProps {
  contractedHoursFeatureIsOn: boolean,
  contractedHoursPeriodType: 'week' | 'month' | null,
  contractedHours: string | null,
  minutesWorked: string | null,
  minutesPlanned: string | null,
  workingTimeDirectiveApplies: boolean | null,
  startTime: string,
  endTime: string,
}

interface CheckIfBookedCandidateExceedsContractedHoursReturnTypes {
  hoursExceedsContractedHours: boolean,
  hoursExceedWorkingTimeDirective: boolean,
  contract: {
    contractedHours: number,
    hoursPlusShiftDuration: number,
    contractedHoursPeriodType: 'week' | 'month',
    hoursExceedsContractedHours: boolean,
    hoursExceedWorkingTimeDirective: boolean,
  } | null
}

export function checkIfBookedCandidateExceedsContractedHours({
  contractedHoursFeatureIsOn,
  contractedHoursPeriodType,
  contractedHours,
  minutesWorked,
  minutesPlanned,
  workingTimeDirectiveApplies,
  startTime,
  endTime,
}: CheckIfBookedCandidateExceedsContractedHoursProps): CheckIfBookedCandidateExceedsContractedHoursReturnTypes {

  // If feature is off, or there is no contract data, return early
  if (!contractedHoursFeatureIsOn) return { hoursExceedsContractedHours: false, hoursExceedWorkingTimeDirective: false, contract: null };
  if (!contractedHoursPeriodType) return { hoursExceedsContractedHours: false, hoursExceedWorkingTimeDirective: false, contract: null };
  if (!contractedHours) return { hoursExceedsContractedHours: false, hoursExceedWorkingTimeDirective: false, contract: null };

  // Get contracted hours, minutes worked and add shift duration. Then convert total minutes to hours.
  const { contractedHours: contractHoursAsNumber, minutesWorkedAndPlanned } = calculateMinutesWorkedAndMinutesPlanned(contractedHours, minutesWorked, minutesPlanned);
  const shiftDurationAsMinutes = moment.duration(moment(endTime).diff(moment(startTime))).asMinutes();
  const minutesPlusShiftDuration = minutesWorkedAndPlanned + shiftDurationAsMinutes;
  const totalMinutesAsHours = minsToHours(minutesPlusShiftDuration);

  // Check if total exceeds contracted hours or working time directive
  const hoursExceedsContractedHours = totalMinutesAsHours > contractHoursAsNumber;
  const hoursExceedWorkingTimeDirective = !!workingTimeDirectiveApplies && ((contractedHoursPeriodType === 'week' && totalMinutesAsHours > 48) || (contractedHoursPeriodType === 'month' && totalMinutesAsHours > 192));

  return {
    hoursExceedsContractedHours,
    hoursExceedWorkingTimeDirective,
    contract: {
      contractedHours: contractHoursAsNumber,
      hoursPlusShiftDuration: totalMinutesAsHours,
      contractedHoursPeriodType,
      hoursExceedsContractedHours,
      hoursExceedWorkingTimeDirective,
    },
  };
}

export function formatHourlyRate(input: string): string {
  if (!input) return '';
  let value = input;

  // check for decimal
  if (value.includes('.')) {

    // get position of first decimal. This prevents multiple decimals from being entered
    const decimalPosition = value.indexOf('.');

    // split number by decimal point
    const leftSide = value.substring(0, decimalPosition);

    let rightSide = value.substring(decimalPosition);

    // validate right side
    rightSide = rightSide.replace(/\D/g, '');

    // On blur make sure 2 numbers after decimal
    rightSide += '00';

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, 2);

    // join number by .
    value = `${leftSide}.${rightSide}`;

  } else {
    value += '.00';
  }
  return value;
}

export function validateHourlyRate(customHourlyRate: string | null, customHourlyRateCriteria: Record<string, number>): { success: boolean, message: string | null } {

  if (!customHourlyRate) return { success: false, message: 'Hourly rate is required' };

  const customHourlyRateConverted = parseFloat(customHourlyRate);
  if (Number.isNaN(customHourlyRateConverted)) return { success: false, message: 'Hourly rate is not valid' };

  if ('minHourlyRate' in customHourlyRateCriteria) {
    if (customHourlyRateConverted < customHourlyRateCriteria.minHourlyRate) {
      return { success: false, message: 'Hourly rate is under the minimum value' };
    }
  }

  if ('maxHourlyRate' in customHourlyRateCriteria) {
    if (customHourlyRateConverted > customHourlyRateCriteria.maxHourlyRate) {
      return { success: false, message: 'Hourly rate is over the maximum value' };
    }
  }

  return { success: true, message: null };
}

export function calculateShiftLength(startTime: string, endTime: string, timezone: string): number {
  const startTimeTimezoneConverted = convertFromUtcToTimezone(startTime, timezone);
  const endTimeTimezoneConverted = convertFromUtcToTimezone(endTime, timezone);
  const diff = endTimeTimezoneConverted.diff(startTimeTimezoneConverted, 'minutes');
  const hours = diff / 60;
  return hours;
}
