
import React from 'react';
import { MdCheck, MdClose } from 'react-icons/md';

import colors from 'config/colors';
import spacing from 'config/spacing';

import { fieldStyles } from './field-styles';

import AcceptanceDocuments from './AcceptanceDocuments';

function NewPasswordForm(props) {

  return (
    <>
      {!!props.passwordRequirementsWithStatus?.length && (
        <>
          <p style={{ fontSize: '0.9rem' }}>Password requirements:</p>
          <ul style={{ listStyle: 'none', marginBottom: spacing.base }}>
            {props.passwordRequirementsWithStatus.map((req) => {
              return (
                <li style={{ color: req.isPassing ? colors.cavalry.active : colors.cavalry.error, fontSize: '0.9rem', fontWeight: 600 }}>
                  {req.description}
                  {req.isPassing ? <MdCheck /> : <MdClose />}
                </li>
              );
            })}
          </ul>
        </>
      )}
      <div>
        <label htmlFor="reset-password" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
          Password
        </label>
        <input
          id="reset-password"
          type="password"
          autoComplete="new-password"
          style={{ ...fieldStyles.input, marginTop: 6, marginBottom: 24 }}
          placeholder="Choose a password"
          value={props.formValues?.password}
          onChange={event => props.setFormValue?.('password', event.target.value)}
        />
        <label htmlFor="reset-confirm-password" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
          Confirm password
        </label>
        <input
          id="reset-confirm-password"
          type="password"
          autoComplete="new-password"
          style={{ ...fieldStyles.input, marginTop: 6, marginBottom: 24 }}
          placeholder="Please re-enter password"
          value={props.formValues?.confirmPassword}
          onChange={event => props.setFormValue?.('confirmPassword', event.target.value)}
        />
      </div>

      {!!props.acceptanceDocuments?.length && (
        <div style={{ paddingTop: 6, paddingBottom: 6 }}>
          <AcceptanceDocuments acceptanceDocuments={props.acceptanceDocuments} displayAcceptanceDocument={props.displayAcceptanceDocument} />
        </div>
      )}
    </>
  );
}

export default NewPasswordForm;
